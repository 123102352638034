import { Typography, Grid } from "@mui/material";
import React from "react";

const policies = [
  {
    title: "1. บทนำ",
    message:
      "ข้อกำหนดการใช้งานเว็บไซต์นี้ ('ข้อกำหนด') กำหนดกฎเกณฑ์และข้อบังคับสำหรับการใช้งานเว็บไซต์ www.srivichaigold.com ('เว็บไซต์') ซึ่งเป็นเว็บไซต์ที่ดำเนินการโดย หจก.ศรีวิชัย ('ผู้ดำเนินการ')",
  },
  {
    title: "2. การยอมรับข้อกำหนด",
    message:
      "การเข้าถึงและใช้งานเว็บไซต์นี้ถือว่าผู้ใช้ยอมรับและปฏิบัติตามข้อกำหนดเหล่านี้ทั้งหมด หากผู้ใช้ไม่ยอมรับข้อกำหนดเหล่านี้ ผู้ใช้จะไม่มีสิทธิ์เข้าถึงหรือใช้งานเว็บไซต์",
  },
  {
    title: "3. การแก้ไขข้อกำหนด",
    message:
      "ผู้ดำเนินการอาจแก้ไขข้อกำหนดเหล่านี้ได้ทุกเมื่อโดยไม่ต้องแจ้งให้ทราบล่วงหน้า ผู้ใช้ควรตรวจสอบข้อกำหนดเหล่านี้เป็นประจำเพื่อดูว่ามีการเปลี่ยนแปลงใดๆ หรือไม่ การใช้งานเว็บไซต์ต่อเนื่องหลังจากการแก้ไขข้อกำหนดถือว่าผู้ใช้ยอมรับข้อกำหนดที่แก้ไขแล้ว",
  },
  {
    title: "4. การสมัครสมาชิก",
    message:
      "การเข้าถึงบางส่วนของเว็บไซต์อาจต้องสมัครสมาชิก ผู้ใช้ที่สมัครสมาชิกจะต้องสร้างบัญชีผู้ใช้และให้ข้อมูลที่ถูกต้องและครบถ้วน ผู้ใช้มีหน้าที่รับผิดชอบในการรักษาความปลอดภัยของบัญชีผู้ใช้และรหัสผ่านของตน และจะต้องรับผิดชอบต่อกิจกรรมใดๆ ที่เกิดขึ้นภายใต้บัญชีผู้ใช้ของตน",
  },
  {
    title: "5. การใช้งานเว็บไซต์",
    message: `ผู้ใช้ตกลงที่จะใช้เว็บไซต์สำหรับวัตถุประสงค์ที่ถูกต้องตามกฎหมายเท่านั้น ผู้ใช้ห้ามมิให้ใช้เว็บไซต์เพื่อ:
            •	ส่งจดหมายขยะหรือสแปม
            •	ส่งเสริมหรือมีส่วนร่วมในกิจกรรมที่ผิดกฎหมายหรือเป็นอันตราย
            •	ละเมิดสิทธิ์ในทรัพย์สินทางปัญญาของผู้อื่น
            •	อัปโหลดหรือเผยแพร่เนื้อหาที่เป็นอันตรายหรือหมิ่นประมาท
            •	รบกวนหรือขัดขวางการทำงานของเว็บไซต์
        `,
  },
  {
    title: "6. การลิงก์ไปยังเว็บไซต์อื่นๆ",
    message: `เว็บไซต์นี้อาจมีลิงก์ไปยังเว็บไซต์อื่นๆ ผู้ดำเนินการไม่รับผิดชอบต่อเนื้อหาหรือการปฏิบัติของเว็บไซต์ที่เชื่อมโยง ผู้ใช้ควรใช้ดุลยพินิจของตนเองเมื่อเข้าถึงและใช้งานเว็บไซต์ที่เชื่อมโยง`,
  },
  {
    title: "7. การยกเว้นความรับผิด",
    message: `เว็บไซต์นี้ให้บริการ "ตามสภาพ" และ "ตามที่มี" ผู้ดำเนินการไม่รับประกันใดๆ เกี่ยวกับเว็บไซต์ เนื้อหา หรือบริการที่นำเสนอบนเว็บไซต์ ผู้ดำเนินการไม่รับผิดชอบต่อความเสียหายใดๆ ที่เกิดขึ้นจากการใช้งานเว็บไซต์ เนื้อหา หรือบริการที่นำเสนอบนเว็บไซต์`,
  },
  {
    title: "8. การจำกัดความรับผิด",
    message: `ผู้ดำเนินการจะไม่รับผิดชอบต่อความเสียหายโดยตรง ความเสียหายทางอ้อม ความเสียหายที่เกิดขึ้นโดยบังเอิญ ความเสียหายพิเศษ หรือผลสืบเนื่องใดๆ รวมถึง แต่ไม่จำกัดเพียง การสูญเสียกำไร การสูญเสียการใช้งาน การสูญเสียข้อมูล หรือการหยุดชะงักทางธุรกิจ ซึ่งเกิดขึ้นจากหรือเกี่ยวข้องกับการใช้งานเว็บไซต์ เนื้อหา หรือบริการที่นำเสนอบนเว็บไซต์ แม้ว่าผู้ดำเนินการจะได้รับแจ้งถึงความเป็นไปได้ของความเสียหายดังกล่าว`,
  },
  {
    title: "9. การชดเชย",
    message: `ผู้ใช้ตกลงที่จะชดเชยและปกป้องผู้ดำเนินการและเจ้าหน้าที่ ตัวแทน และพนักงานของผู้ดำเนินการที่ไม่เป็นอันตรายจากและต่อข้อเรียกร้อง ความรับผิดชอบ ความเสียหาย การสูญเสีย ค่าใช้จ่าย และค่าใช้จ่ายใดๆ รวมถึงค่าธรรมเนียมทางกฎหมายที่เกิดขึ้นจากหรือเกี่ยวข้องกับการใช้งานเว็บไซต์ เนื้อหา หรือบริการที่นำเสนอบนเว็บไซต์ โดยผู้ใช้`,
  },
];

const PolicyPage = () => {
  return (
    <Grid container justifyContent={"flex-start"} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">ข้อกำหนดการใช้งานเว็บไซต์</Typography>
      </Grid>
      {policies.map((policie) => {
        return (
          <Grid item xs={12} container justifyContent={"flex-start"}>
            <Grid item container justifyContent={"flex-start"}>
              <Typography variant="h6">{policie.title}</Typography>
            </Grid>
            <Grid item container justifyContent={"flex-start"}>
              <Typography textAlign={"start"}>{policie.message}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PolicyPage;
