import {
  Card,
  Grid,
  Typography,
  Box,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import axios from "../utils/axios";
import { toast } from "react-toastify";
import ProductZoomDialog from "./ProductZoomDialog";
import ReactLoading from "react-loading";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useAppState from "../hooks/useAppState";
import useWindowDimensions from "../hooks/useWindowDimension";
import formatNumber from "../utils/formatNumber";
import { AddShoppingCart } from "@mui/icons-material";
import { useAuth } from "../hooks/useAuth";
import Swal from "sweetalert2";

const itemWidth = 420;

const ProductList = () => {
  const { isLoggedIn, isChecking } = useAuth();

  const { code, weightId, model } = useParams();
  const { width } = useWindowDimensions();
  const smallScreen = useMemo(() => width < 900, [width]);

  const navigate = useNavigate();

  const { items, setItems, products } = useAppState();
  const product = products.find((p) => p.product_code == code);
  const weight = product?.weights.find((w) => w.id == weightId);

  const [loading, setLoading] = useState(false);

  const [displayItems, setDisplayItems] = useState([...items]);

  const customWidth = useMemo(
    () =>
      smallScreen
        ? { minWidth: itemWidth, maxWidth: itemWidth }
        : { width: "100%" },
    [smallScreen]
  );

  useEffect(() => {
    if (product && weight) {
      setLoading(() => true);
      setItems(() => []);

      const url =
        product.product === "ทองหุ้ม"
          ? `/api/models?product=${product.product}&name=${weight.name}`
          : `/api/models?product=${product.product}&start=${weight.start}&end=${weight.end}`;

      axios
        .get(url)
        .then(({ data }) => {
          setItems(() => data);
          setLoading(() => false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, { position: "top-center" });
          setItems(() => []);
          setLoading(() => false);
        });
    }
  }, [code, weight, product]);

  const [openZoom, setOpenZoom] = useState(false);
  const [zoomProduct, setZoomProduct] = useState(null);
  const [zoomUrl, setZoomUrl] = useState("");

  useEffect(() => {
    if (model) {
      setDisplayItems((prev) => {
        const clone = [...prev];
        return clone.filter((c) => c.model === model);
      });
    } else {
      setDisplayItems(items);
    }
  }, [model, items]);

  const currentPath = useLocation();

  const getPrice = useCallback(
    (productItem) => {
      if (isLoggedIn) return formatNumber(productItem.price, 0) + " บาท*";
      return "เข้าสู่ระบบเพื่อดูราคา";
    },
    [isLoggedIn]
  );

  if (loading || isChecking) {
    return (
      <div
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type={"spinningBubbles"} color="red" />
      </div>
    );
  }

  if (!product || !weight) return null;

  const imageHeight = 300;

  const getSize = (productItem) => {
    return "Size: " + (+productItem.size > 0 ? productItem.size : "-");
  };

  const renderItem = (productItem) => {
    return (
      <Card
        variant="elevation"
        sx={{
          minHeight: 450,
          maxHeight: 450,
          padding: 3,
          ...customWidth,
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6">
              {productItem.id_product} - {productItem.model}
            </Typography>
          </Grid>

          <Grid item>
            <Chip
              size="medium"
              sx={{ cursor: "pointer" }}
              color="primary"
              label={getPrice(productItem)}
              title="ราคาโดยประมาณ อาจมีการเปลี่ยนแปลงได้ก่อนชำระเงิน"
              onClick={() => {
                if (!isLoggedIn) {
                  navigate("/login");
                }
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid item>
          <Box
            sx={{
              width: "100%",
              p: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
              gap: 1,
              overflow: "hidden",
              ":hover": {
                opacity: 1.9,
              },
              position: "relative",
            }}
          >
            <img
              src={`/api/images?idProduct=${productItem.id_product}`}
              alt={productItem.model}
              height={imageHeight}
              width={"100%"}
              style={{
                transition: ".2s ease",
                backfaceVisibility: "hidden",
              }}
            />
            <Box
              sx={{
                height: "100%",
                width: "100%",
                transition: ".2s ease",
                opacity: 0,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                msTransform: "translate(-50%, -50%)",
                textAlign: "center",
                ":hover": {
                  opacity: 1,
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenZoom(() => true);
                setZoomProduct(() => ({
                  ...productItem,
                  more: false,
                  buy: false,
                }));
                setZoomUrl(
                  () => `/api/images?idProduct=${productItem.id_product}`
                );
              }}
            >
              <Stack spacing={2} direction="row">
                <Button
                  color="error"
                  variant="outlined"
                  startIcon={<AddShoppingCart />}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isLoggedIn) {
                      setOpenZoom(() => true);
                      setZoomProduct(() => ({
                        ...productItem,
                        more: false,
                        buy: true,
                      }));
                      setZoomUrl(
                        () => `/api/images?idProduct=${productItem.id_product}`
                      );
                    } else {
                      void Swal.fire({
                        title: "กรุณาเข้าสู่ระบบเพื่อสั่งซื้อ",
                        text: "เรากำลังพาท่านไปหน้าเข้าสู่ระบบ",
                        timerProgressBar: true,
                        timer: 2000,
                        icon: "warning",
                      });

                      setTimeout(
                        () =>
                          navigate(
                            "/login?callbackUrl=" + currentPath.pathname
                          ),
                        2000
                      );
                    }
                  }}
                >
                  ซื้อทันที
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Box mt={2} />
        <Typography>
          <b>({getSize(productItem)})</b> {productItem.description || "-"}
        </Typography>
      </Card>
    );
  };

  return (
    <div style={{ paddingTop: 40 }}>
      <ProductZoomDialog
        open={openZoom}
        product={zoomProduct}
        onClose={() => setOpenZoom(() => false)}
        imageUrl={zoomUrl}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {product.product}
            {model || ""}
          </Typography>
        </Grid>
        {displayItems.map((p, index) => {
          // มีสินค้าในกลุ่มเดียวกันมากกว่า 1 รายการ
          if (p.items.length > 1) {
            if (!model) {
              const firstItem = p.items[0];
              return (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  container
                  justifyContent="center"
                >
                  <Card
                    variant="elevation"
                    sx={{
                      minHeight: 450,
                      maxHeight: 450,
                      padding: 3,
                      ...customWidth,
                    }}
                  >
                    <Grid container justifyContent={"space-between"}>
                      <Grid item>
                        <Typography variant="h5">{p.model}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          onClick={() => {
                            navigate("more/" + firstItem.model);
                          }}
                          size="medium"
                          color="success"
                          label={
                            "ดูเพิ่มอีก " +
                            p.items.length.toLocaleString() +
                            " รายการ"
                          }
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2} />
                    <Grid item>
                      <div
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          onClick={() => {
                            setOpenZoom(() => true);
                            setZoomProduct(() => ({
                              ...firstItem,
                              more: true,
                            }));
                            setZoomUrl(
                              () =>
                                `/api/images?idProduct=${firstItem.id_product}`
                            );
                          }}
                          src={`/api/images?idProduct=${firstItem.id_product}`}
                          alt={p.model}
                          height={imageHeight}
                          width={"100%"}
                        />
                      </div>
                    </Grid>
                    <Box mt={2} />
                    <Typography>{firstItem.description || "-"}</Typography>
                  </Card>
                </Grid>
              );
            } else {
              return p.items.map((i) => (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={i.id_product}
                  justifyContent="center"
                  container
                >
                  {renderItem(i)}
                </Grid>
              ));
            }
          } else {
            // ถ้ามีแค่ 1 ตัว
            const firstItem = p.items[0];
            return (
              <Grid
                container
                justifyContent="center"
                item
                xl={3}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                key={firstItem.id_product}
              >
                {renderItem(firstItem)}
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};

export default ProductList;
