import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";
import { Card, Grid, Typography } from "@mui/material";

const ActivityPage = (props) => {
  const { id } = useParams();

  const [activity, setActivity] = useState({ loading: false, data: [] });

  useEffect(() => {
    axios
      .get("/api/activities/detail/" + id)
      .then(({ data }) => {
        setActivity((prev) => {
          return { ...prev, loading: false, data };
        });
      })
      .catch(() => {
        setActivity((prev) => {
          return { ...prev, loading: false, data: [] };
        });
      });
  }, [id]);

  const renderText = (text) => {
    return text.split("\r\n").map((p, index) => {
      return <Typography key={index}>{p}</Typography>;
    });
  };

  return (
    <div>
      <Typography>Activity Page</Typography>
      <Grid container spacing={2}>
        {activity.data.map((a, index) => {
          return (
            <Grid item lg={3} sx={12} key={index}>
              <Card
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <img
                  src={a.url}
                  alt={a.description}
                  width={"100%"}
                  height="100%"
                />
                {renderText(a.description)}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {/* <Carousel cols={4} rows={10} gap={10} loop>
        {activity.data.map((a, index) => {
          return (
            <Carousel.Item key={index}>
              <Card width="100%" sx={{ maxWidth: 400 }}>
                <img src={a.url} alt={a.description} width={"100%"} />
                <Typography noWrap>{a.description}</Typography>
              </Card>
            </Carousel.Item>
          );
        })}
      </Carousel> */}
      {/* <Grid container spacing={4}>
        {activity.data.map((a) => {
          return (
            <Grid item xs={4}>
              <Card sx={{ minHeight: cardHeight, maxHeight: cardHeight }}>
                <img
                  //   style={{ width: 50, height: 50 }}
                  src={a.url}
                  alt={a.url}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid> */}
    </div>
  );
};

export default ActivityPage;
