import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { provinces } from "../utils/thaiProvince";
import Autocomplete from "@mui/material/Autocomplete";

import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const [gender, setGender] = useState("นาย");
  const [id_card, setIdCard] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [village, setVillage] = useState("");
  const [province, setProvince] = useState({});
  const [amphure, setAmphure] = useState({});
  const [tambon, setTambon] = useState({});
  const [file, setFile] = useState(null);
  const [tel, setTel] = useState("");

  const navigator = useNavigate();

  const [errors, setErrors] = useState({
    gender: false,
    id_card: false,
    name: false,
    address: false,
    village: false,
    province: false,
    amphure: false,
    tambon: false,
    file: false,
    tel: false,
  });

  const submit = () => {
    setErrors({
      gender: gender === "",
      id_card: id_card === "",
      name: name === "",
      address: address === "",
      village: village === "",
      province: Object.keys(province).length === 0,
      amphure: Object.keys(amphure).length === 0,
      tambon: Object.keys(tambon).length === 0,
      file: file === null,
      tel: tel.length !== 10,
    });

    const form = new FormData();
    form.append("gender", gender);
    form.append("id_card", id_card);
    form.append("name", name);
    form.append("address", address);
    form.append("village", village);
    form.append("province", province.name_th);
    form.append("district", amphure.name_th);
    form.append("subdistrict", tambon.name_th);
    form.append("file", file);
    form.append("phone", tel);

    axios
      .post("/api/customers", form, {
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      })
      .then((response) => {
        toast.success("ลงทะเบียนสำเร็จ กรุณารอการอนุมัติ");
        navigator("/");
      })
      .catch((err) => {
        console.log({ err });
        toast.error("ไม่สามารถลงทะเบียนได้ : " + err.response.data);
      });
  };

  const { acceptedFiles, getRootProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setImage(URL.createObjectURL(acceptedFiles[0]));
      setFile(acceptedFiles[0]);
      setErrors((error) => ({ ...error, file: false }));
    }
  }, [acceptedFiles]);

  return (
    <Container>
      <Grid container mt={4} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">ลงทะเบียน</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors.id_card}
            fullWidth
            id="outlined-basic"
            label="รหัสบัตรประชาชน"
            placeholder="รหัสบัตรประชาชน"
            inputProps={{ maxLength: 13 }}
            variant="outlined"
            value={id_card}
            onChange={(e) => {
              setIdCard(e.target.value);
              setErrors((error) => ({ ...error, id_card: false }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
            <Select
              error={errors.gender}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={gender}
              label="เพศ"
              onChange={(e) => {
                setGender(e.target.value);
                setErrors((error) => ({ ...error, gender: false }));
              }}
            >
              <MenuItem value={"นาย"}>นาย</MenuItem>
              <MenuItem value={"นาง"}>นาง</MenuItem>
              <MenuItem value={"นางสาว"}>นางสาว</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            error={errors.name}
            fullWidth
            id="outlined-basic"
            label="ชื่อ-สกุล"
            placeholder="ชื่อ-สกุล"
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors((error) => ({ ...error, name: false }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors.address}
            fullWidth
            id="outlined-basic"
            label="ที่อยู่"
            placeholder="ที่อยู่"
            variant="outlined"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setErrors((error) => ({ ...error, address: false }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            error={errors.village}
            id="outlined-basic"
            label="หมู่ที่"
            placeholder="หมู่ที่"
            variant="outlined"
            value={village}
            onChange={(e) => {
              setVillage(e.target.value);
              setErrors((error) => ({ ...error, village: false }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            disablePortal
            options={provinces}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="จังหวัด" error={errors.province} />
            )}
            getOptionLabel={(p) => p.name_th || ""}
            value={province}
            onChange={(e, value) => {
              setProvince(value);
              setAmphure({});
              setTambon({});
              setErrors((error) => ({ ...error, province: false }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            disablePortal
            options={province?.amphure || []}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="อำเภอ" error={errors.amphure} />
            )}
            getOptionLabel={(p) => p.name_th || ""}
            value={amphure}
            onChange={(e, value) => {
              setAmphure(value);
              setTambon({});
              setErrors((error) => ({ ...error, amphure: false }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            disablePortal
            options={amphure?.tambon || []}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="ตำบล" error={errors.tambon} />
            )}
            getOptionLabel={(p) => p.name_th || ""}
            value={tambon}
            onChange={(e, value) => {
              setTambon(value);
              setErrors((error) => ({ ...error, tambon: false }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors.tel}
            label="เบอร์โทร"
            fullWidth
            placeholder="เบอร์โทร"
            inputProps={{ maxLength: 10 }}
            value={tel}
            onChange={(e) => {
              setTel(e.target.value);
              setErrors((error) => ({ ...error, tel: false }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            {...getRootProps({ className: "dropzone" })}
            width={"100%"}
            minHeight={200}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              border: `dashed 3px ${errors.file ? "red" : "#2196F3"}`,
              background: "#FAFAFA",
            }}
          >
            {image === null ? (
              <Typography variant="h6">
                ลากไฟล์ภาพบัตรประชาชน หรือ คลิกเพื่อเลือกไฟล์
              </Typography>
            ) : (
              <img src={image} alt="id_card" />
            )}
          </Box>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={() => submit()}>
              ลงทะเบียน
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => navigator("/login")}
            >
              เข้าสู่ระบบ
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={10} />
    </Container>
  );
};

export default RegisterPage;
