import React from "react";
import {
  Grid,
  Typography,
  Alert,
  Box,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useState } from "react";
import axios from "../utils/axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import formatNumber from "../utils/formatNumber";

const PointPage = () => {
  const [pointData, setPointData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [exchange, setExchange] = useState({ data: [], loading: false });

  const fetchExchange = () => {
    setExchange((prev) => setExchange({ ...prev, loading: true }));
    axios
      .get("/api/gold/exPoint")
      .then((res) => {
        const data = res.data || [];
        setExchange((prev) => ({ ...prev, data }));
      })
      .finally(() => {
        setExchange((prev) => ({ ...prev, loading: false }));
      });
  };

  useEffect(() => {
    fetchExchange();
  }, []);

  const fetchPoint = () => {
    axios
      .get("/api/customers/myPoint")
      .then((res) => {
        const usePoints = res.data;
        setPointData(usePoints);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setErrorMessage(err.response.data.message);
      });
  };

  const renderUsePointHistory = () => {
    if (errorMessage || !pointData) {
      return <Typography>ไม่มีประวัติการใช้แต้มสะสมในระบบ</Typography>;
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">
            คุณมีคะแนนคงเหลือล่าสุด{" "}
            <b>{formatNumber(pointData?.point?.total_point, 0)}</b> คะแนน
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"flex-start"}>
          <Typography variant="h6">
            ประวัติการใช้แต้ม {pointData.histories?.length ?? 0} ครั้ง
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">วันที่</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">รายการ</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">แต้มที่ใช้</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pointData?.histories?.map((h, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6">{h.date}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">{h.memo}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {formatNumber(h.use_point, 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  const clearForm = () => {
    setErrorMessage(null);
    setPointData(null);
  };

  useEffect(() => {
    fetchPoint();
    return clearForm();
  }, []);

  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  return (
    <Grid container spacing={3} direction="column" marginTop={2}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Typography variant="h4">เช็คแต้มสะสม</Typography>
      </Grid>

      <Grid item xs={12}>
        {renderUsePointHistory()}
      </Grid>
      <Grid item xs={12} display="flex" justifyContent={"flex-start"}>
        <img
          src="new_point.png"
          alt="new_point"
          width={"100%"}
          height={"100%"}
          style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent={"flex-start"}>
        <img
          src="Home10.png"
          alt="new_point"
          width={"100%"}
          height={"100%"}
          style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent={"flex-start"}>
        <img
          src="Home11.png"
          alt="new_point"
          width={"100%"}
          height={"100%"}
          style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display={{ xs: "none", lg: "block" }} height={200} />
      </Grid>
      <Grid item xs={12}>
        <TableContainer sx={{ my: 10 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">น้ำหนัก</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">รายการ</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">แต้มที่ใช้</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exchange?.data.map((ex) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">{ex.weight}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6"> {ex.label}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6">
                        {new Intl.NumberFormat().format(ex.point)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PointPage;
