import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import {
  CircularProgress,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Button,
  Chip,
  Box,
} from "@mui/material";

import { ArrowDownward } from "@mui/icons-material";
import formatNumber from "../utils/formatNumber";

import moment from "moment";
import SavingPaymentDialog from "./SavingPaymentDialog";

moment.locale("th");

const ShowStatus = (status, cancel) => {
  if (status === "OK") {
    return <Chip color="success" label="ปกติ" size="small" />;
  } else if (status === "WAITING") {
    return <Chip color="error" label="รอการยืนยัน" size="small" />;
  } else if (status === "CANCEL") {
    return (
      <Box display="flex" gap={2}>
        <Chip color="warning" label="ยกเลิก" size="small" />
        {/* <Typography>{props.row.cancel_reason}</Typography> */}
      </Box>
    );
  }
};

const SavingPage = () => {
  const [saving, setSaving] = useState({
    loading: false,
    error: null,
    data: [],
  });

  useEffect(() => {
    fetchSaving();
  }, []);

  const fetchSaving = () => {
    setSaving((saving) => ({ ...saving, loading: true }));
    axios
      .get("/api/saving")
      .then((response) => {
        setSaving((saving) => ({ ...saving, data: response.data }));
      })
      .catch((err) => {
        setSaving((saving) => ({
          ...saving,
          error: err.response.data.message,
        }));
      })
      .finally(() => {
        setSaving((saving) => ({
          ...saving,
          loading: false,
        }));
      });
  };

  const [idSaving, setIdSaving] = useState(null);
  const [paymentMonths, setPaymentMonth] = useState(0);

  if (saving.loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <SavingPaymentDialog
        paymentMonths={paymentMonths}
        idSaving={idSaving}
        open={Boolean(idSaving)}
        onClose={() => {
          setIdSaving(null);
          fetchSaving();
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">ชำระออมทองรายเดือน</Typography>
          <Typography>คุณมีสัญญาออมทอง {saving.data.length} รายการ</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"} spacing={2}>
          {saving.data.map((saving) => {
            return (
              <Grid item xs={12}>
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ArrowDownward />}
                    id="panel1-header"
                  >
                    <Stack direction={"row"} gap={4}>
                      <Stack
                        direction={"column"}
                        gap={2}
                        alignItems={"flex-start"}
                      >
                        <Typography>
                          สัญญาเลขที่ : <b>{saving.id_saving}</b> | ประเภท{" "}
                          <b>{saving.cost_price}</b> | ชำระวันทำสัญญา{" "}
                          {formatNumber(saving.advance)}
                        </Typography>
                        <Typography>
                          วันที่ครบกำหนดชำระ{" "}
                          <b style={{ color: "red" }}>
                            {moment(saving.date_maturity).format(
                              "DD MMMM YYYY"
                            )}
                          </b>{" "}
                          | ยอดชำระตามสัญญา{" "}
                          <b style={{ color: "red" }}>
                            {formatNumber(saving.payments_month)}
                          </b>
                        </Typography>
                      </Stack>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIdSaving(saving.id_saving);
                          setPaymentMonth(saving.payments_month);
                        }}
                      >
                        ชำระรายเดือนออมทอง
                      </Button>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>งวดที่</TableCell>
                            <TableCell>วันที่</TableCell>
                            <TableCell>ยอดชำระ</TableCell>
                            <TableCell>ดอกเบี้ยที่ได้รับ</TableCell>
                            <TableCell>สถานะ</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {saving.payments.map((payment, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{payment.Installments}</TableCell>
                                <TableCell>
                                  {moment(payment.date).format("DD MMMM YYYY")}
                                </TableCell>
                                <TableCell>
                                  {formatNumber(payment.Payment, 0)}
                                </TableCell>
                                <TableCell>
                                  {formatNumber(payment.Interest, 0)}
                                </TableCell>
                                <TableCell>
                                  {ShowStatus(payment.status, payment.canceln)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default SavingPage;
