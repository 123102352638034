import {
  Button,
  Grid,
  Typography,
  Box,
  Chip,
  Alert,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../utils/axios";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import "moment/locale/th";
import PawnExtensionDialog from "./PawnExtensionDialog";
import { useEffect } from "react";
import { useMemo } from "react";
import { handleResponseError } from "../utils/axios";
import formatNumber from "../utils/formatNumber";

moment.locale("th");

const columns = [
  { field: "id", headerName: "ที่", width: 50 },
  {
    field: "date_pay",
    headerName: "วันที่ต่อดอก",
    width: 100,
  },
  {
    field: "extended_days",
    headerName: "ต่อดอก (วัน)",
    width: 100,
  },
  {
    field: "interest_value",
    headerName: "จำนวนเงิน",
    width: 100,
  },
  {
    field: "type",
    headerName: "สถานะ",
    width: 250,
    renderCell: (v) => {
      if (v.value === "OK") {
        return <Chip color="success" label="ปกติ" size="small" />;
      } else if (v.value === "CANCEL") {
        return (
          <Box display="flex" gap={2}>
            <Chip color="warning" label="ยกเลิก" size="small" />
            <Typography>{v.row.cancel_reason}</Typography>
          </Box>
        );
      } else {
        return <Chip color="error" label="รอการยืนยัน" size="small" />;
      }
    },
  },
];

const PawnPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [pawnRows, setPawnRows] = useState([]); // ประวัติการต่อดอก
  const [pawns, setPawns] = useState([]); // รายการขายฝากทั้งหมด
  const [loadingPawns, setLoadingPawns] = useState(false); // สถานะโหลดข้อมูล
  const [errorMessage, setErrorMessage] = useState(""); // ข้อความ error
  const [selectedPawn, setSelectedPawn] = useState(null); // รายการขายฝากที่เลือก
  const [pawnItems, setPawnItems] = useState([]); // รายการสินค้าขายฝาก ที่ถูกคลิกเคลือก pawn_id

  useEffect(() => {
    if (selectedPawn && selectedPawn.ID_Pawn) {
      axios
        .get(`/api/pawns/histories/${selectedPawn.ID_Pawn}`)
        .then((response) => {
          setPawnRows(response.data);
        })
        .catch(handleResponseError);
    }
  }, [selectedPawn]);

  const firstPawnRow = pawnRows?.[0];

  const cannotCreateNew = useMemo(() => {
    return !!pawnRows.find((p) => p.type === "ONLINE");
  }, [pawnRows]);

  const filteredPawnRows = useMemo(() => {
    return pawnRows
      .filter((pw) => !!pw.date_pay)
      .map((pw, index) => ({ ...pw, id: index + 1 }));
  }, [pawnRows]);

  useEffect(() => {
    setLoadingPawns(true);
    setErrorMessage("");
    axios
      .get("/api/pawns/list")
      .then((response) => {
        setPawns(response.data.data);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setLoadingPawns(false);
      });
  }, []);

  useEffect(() => {
    if (selectedPawn) {
      setLoadingPawns(true);
      setErrorMessage("");
      axios
        .get("/api/pawns/items/" + selectedPawn.ID_Pawn)
        .then((response) => {
          setPawnItems(response.data.data);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
        })
        .finally(() => {
          setLoadingPawns(false);
        });
    }
  }, [selectedPawn]);

  if (loadingPawns) {
    return <CircularProgress />;
  }

  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  return (
    <>
      <PawnExtensionDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        daysGone={+firstPawnRow?.days_gone}
        value={+firstPawnRow?.value}
        startDate={firstPawnRow?.start_date}
        endDate={firstPawnRow?.end_date}
        idPawn={selectedPawn?.ID_Pawn}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">ขยายระยะเวลาขายฝาก</Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography variant="h6">รายการขายฝากทั้งหมด</Typography>
          <Typography color="red" variant="caption">
            (หากไม่พบรายการให้ติดต่อทางร้าน)
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">วันที่ขายฝาก</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">เลขที่</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">ยอดฝาก</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">ต่อดอก</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pawns?.map((pawn, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      selected={selectedPawn?.ID_Pawn === pawn.ID_Pawn}
                      onClick={() => setSelectedPawn(pawn)}
                    >
                      <TableCell>
                        <Typography variant="h6">{pawn.Date}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">{pawn.ID_Pawn}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {formatNumber(pawn.Value, 0)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          onClick={() => {
                            if (cannotCreateNew) {
                              toast.error(
                                "ใบฝากเลขที่นี้ได้ทำการขยายระยะเวลาออนไลน์ (ต่อดอก) รอการอนุมัติ"
                              );
                            } else {
                              setOpenDialog(true);
                            }
                          }}
                        >
                          คลิก
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {pawnItems?.length > 0 && (
          <Grid item sm={12} md={6}>
            <Typography variant="h6">รายละเอียสินค้าขายฝาก</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">สินค้า</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">รุ่นแบบ/ลาย</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">น้ำหนัก</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pawnItems?.map((pi, index) => {
                    return (
                      <TableRow key={index} hover>
                        <TableCell>
                          <Typography variant="h6">{pi.Product}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">{pi.Model}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">
                            {formatNumber(pi.Weight, 3)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} container justifyContent={"flex-start"}>
                <Typography variant="h6" color="primary">
                  วันที่รับฝาก:{" "}
                  {moment(pawnRows?.[0]?.date_pawn)
                    .add(543, "year")
                    .format("DD MMMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent={"flex-start"}>
                <Typography variant="h6" color="primary">
                  วันที่เริ่มตามกำหนด:{" "}
                  {moment(pawnRows?.[0]?.start_date)
                    .add(543, "year")
                    .format("DD MMMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent={"flex-start"}>
                <Typography variant="h6" color="primary">
                  วันที่ครบตามกำหนด:{" "}
                  {moment(pawnRows?.[0]?.last_date)
                    .add(543, "year")
                    .format("DD MMMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent={"flex-start"}>
                <Typography variant="h6" color="error">
                  จำนวนวันที่ฝาก {firstPawnRow?.days_gone} วัน
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {filteredPawnRows.length > 0 && (
        <Grid item xs={12}>
          <div style={{ width: "100%" }}>
            <DataGrid
              sx={{ minHeight: 500 }}
              rows={filteredPawnRows}
              columns={columns}
              hideFooter
              scr
              autoHeight
            />
          </div>
        </Grid>
      )}
    </>
  );
};

export default PawnPage;
