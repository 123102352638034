import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";

const AddToHomeScreenButton = () => {
  const [prompt, setPrompt] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      setPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const handleAddToHomeScreenClick = () => {
    console.log({ prompt });
    if (prompt) {
      prompt.prompt();

      prompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("The app was added to the home screen");
        } else {
          console.log("The app was not added to the home screen");
        }
      });
    }
  };

  return <Button onClick={handleAddToHomeScreenClick}>Install</Button>;
};

export default AddToHomeScreenButton;
