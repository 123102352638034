import React, { useCallback } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import useAppState from "../hooks/useAppState";

const AppBreadcrumbs = () => {
  const { products } = useAppState();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const renderPath = useCallback(() => {
    const indexOfProductCode = pathnames.indexOf("products") + 1;

    if (indexOfProductCode !== 0 && indexOfProductCode <= pathnames.length) {
      const productCode = pathnames[indexOfProductCode];
      pathnames[indexOfProductCode - 1] = undefined;
      const product = products.find((p) => p.product_code == productCode);
      pathnames[indexOfProductCode] = {
        name: product?.product ?? "",
        to: "products/" + product?.product_code,
      };

      const indexOfWeight = pathnames.indexOf("weights") + 1;
      if (indexOfWeight !== 0 && indexOfWeight <= pathnames.length) {
        pathnames[indexOfWeight - 1] = undefined;
        const weightId = pathnames[indexOfWeight];
        const weight = products
          .find((p) => p.product_code == productCode)
          ?.weights.find((w) => w.id == weightId);
        pathnames[indexOfWeight] = {
          name: weight?.name,
          to: "products/" + product?.product_code + "/weights/" + weight?.id,
        };

        if (!weight) {
          return null;
        }
      }
    }

    const pathFilters = [
      ...pathnames.filter(Boolean).filter((p) => p !== "more"),
    ];

    return pathFilters.map((path, index) => {
      if (index === pathFilters.length - 1) {
        return (
          <Typography color="text.primary" key={index}>
            {path.name ?? path}
          </Typography>
        );
      }
      return (
        <LinkRouter
          underline="hover"
          color="inherit"
          to={path.to ?? "/"}
          key={index}
          replace
        >
          {path.name ?? path}
        </LinkRouter>
      );
    });
  }, [pathnames]);

  return (
    <Breadcrumbs>
      <LinkRouter underline="hover" color="inherit" to="/" replace>
        Home
      </LinkRouter>
      {renderPath()}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
