import React from "react";

const AboutPage = () => {
  return (
    <iframe
      title="Srivichai Map"
      style={{ width: "100%", height: "80vh" }}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.7280582526423!2d99.04895021560861!3d18.586294972010027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30dbd3e897c85f3d%3A0xe186660011c10132!2z4Lir4LmJ4Liy4LiH4Lir4Li44LmJ4LiZ4Liq4LmI4Lin4LiZ4LiI4Liz4LiB4Lix4LiUIOC4q-C5ieC4suC4h-C4l-C4reC4h-C4qOC4o-C4teC4p-C4tOC4iuC4seC4og!5e0!3m2!1sth!2sth!4v1665315884969!5m2!1sth!2sth"
    />
  );
};

export default AboutPage;
