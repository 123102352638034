import React from "react";
import { useState } from "react";
import { createContext } from "react";

export const initState = {};

export const AuthState = createContext(initState);

export default function AuthStateProvider(props) {
  const { children } = props;
  const [name, setName] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checking, setChecking] = useState(true);

  const store = {
    name,
    setName,
    isLoggedIn,
    setIsLoggedIn,
    checking,
    setChecking,
  };

  return <AuthState.Provider value={store}>{children}</AuthState.Provider>;
}
