import { Close } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  Stack,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import formatNumber from "../utils/formatNumber";
import axios from "../utils/axios";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import Swal from "sweetalert2";

const ProductZoomDialog = (props) => {
  const { open, onClose, product, imageUrl } = props;
  const { isLoggedIn } = useAuth();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const [otherName, setOtherName] = useState("");
  const [otherAddress, setOtherAddress] = useState("");
  const [otherPhone, setOtherPhone] = useState("");

  useEffect(() => {
    axios.get("/api/customers/address").then((response) => {
      const {
        gender,
        name,
        address,
        village,
        subdistrict,
        district,
        province,
        phone,
      } = response.data;

      setName(gender + name);
      setAddress(
        address +
          " " +
          village +
          " " +
          subdistrict +
          " " +
          district +
          " " +
          province
      );
      setPhone(phone);
    });

    axios.get("/api/customers/otherAddress").then((response) => {
      const { name, address, phone } = response.data;

      setOtherName(name);
      setOtherAddress(address);
      setOtherPhone(phone);
    });
  }, []);

  const showSize = product && !product.more;

  const [shipOption, setShipOption] = useState("รับเอง");

  const handleChange = (event) => {
    setShipOption(event.target.value);
  };

  const [shipOptions, setShipOptions] = useState([]);

  // const [shipDate, setShipDate] = useState("-");

  const shipOptionDetail = useMemo(() => {
    return shipOptions.find((so) => so.name === shipOption);
  }, [shipOption, shipOptions]);

  const [generateQR, setGenerateQR] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const [shipTo, setShipTo] = useState("primary");
  const [slip, setSlip] = useState(null);
  const imagePreview = slip && URL.createObjectURL(slip);
  const onChangeSlip = (e) => {
    if (e.target.files.length === 1) {
      setSlip(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (open && isLoggedIn) {
      setGenerateQR(true);
      axios
        .get(
          encodeURI(
            "/api/gold/purchase-qr-code?idProduct=" +
              product.id_product +
              "&shipOption=" +
              shipOption
          ),
          { responseType: "blob" }
        )
        .then((resp) => {
          const blob = resp.data;
          setQrCode(URL.createObjectURL(blob));
        })
        .finally(() => {
          setGenerateQR(false);
        });
    }
  }, [isLoggedIn, open, product?.id_product, shipOption]);

  useEffect(() => {
    if (open && isLoggedIn && product?.id_product) {
      axios
        .get(
          encodeURI(
            "/api/gold/buy?idProduct=" +
              product.id_product +
              "&shipOption=" +
              shipOption
          )
        )
        .then((response) => {
          const {
            data: { options },
          } = response;
          setShipOptions(options);
        })
        .catch(() => {
          // void Swal.fire(
          //   "ไม่สามารถโหลดวันที่รับสินค้าได้",
          //   "กรุณาติดต่อร้าน",
          //   "error"
          // );
        })
        .finally(() => {});
    }
  }, [open, isLoggedIn, shipOption, product?.id_product]);

  if (!open) return null;

  const getLabel = () => {
    if (product.price) {
      return formatNumber(product.price, 0) + " บาท";
    }
    return "Size: " + (+product.size > 0 ? product.size : "-");
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            {product?.id_product} - {product.model}
          </Grid>
          <Grid item>
            {showSize ? (
              <Chip size="small" color="primary" label={getLabel()} />
            ) : null}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={product.buy ? 7 : 12}>
            <img
              style={{ width: "100%" }}
              src={imageUrl + "&preview=false"}
              alt={product?.id_product}
            />
            <RadioGroup
              value={shipTo}
              onChange={(e) => setShipTo(e.target.value)}
            >
              <Stack spacing={2} mt={2}>
                <FormControlLabel
                  value="primary"
                  control={<Radio />}
                  label="ที่อยู่จัดส่งหลัก"
                />
                {shipTo === "primary" ? (
                  <>
                    <TextField
                      sx={{ background: "lightgray" }}
                      onChange={(e) => setName}
                      value={name}
                      label="ชื่อ-สกุล ผู้รับ"
                      size="small"
                    />
                    <TextField
                      sx={{ background: "lightgray" }}
                      size="small"
                      value={address}
                      label="ที่อยู่ผู้รับ"
                      multiline={true}
                      minRows={3}
                    />
                    <TextField
                      sx={{ background: "lightgray" }}
                      size="small"
                      value={phone}
                      label="เบอร์โทร"
                    />
                  </>
                ) : null}
              </Stack>
              <Stack spacing={2} mt={2}>
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="ที่อยู่จัดส่งอื่น"
                />
                {shipTo === "other" ? (
                  <>
                    <TextField
                      value={otherName}
                      label="ชื่อ-สกุล ผู้รับ"
                      size="small"
                      onChange={(e) => setOtherName(e.target.value)}
                    />
                    <TextField
                      size="small"
                      value={otherAddress}
                      label="ที่อยู่ผู้รับ"
                      multiline={true}
                      minRows={3}
                      onChange={(e) => setOtherAddress(e.target.value)}
                    />
                    <TextField
                      size="small"
                      value={otherPhone}
                      label="เบอร์โทร"
                      onChange={(e) => setOtherPhone(e.target.value)}
                    />
                  </>
                ) : null}
              </Stack>
            </RadioGroup>
          </Grid>
          {product?.buy ? (
            <Grid item xs={12} md={5}>
              <Box p={2} width={"100%"}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    ตัวเลือกการจัดส่ง
                  </FormLabel>
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={shipOption}
                    onChange={handleChange}
                  >
                    {shipOptions.map((option) => {
                      return (
                        <FormControlLabel
                          value={option.name}
                          control={<Radio />}
                          label={
                            <Stack direction={"row"} spacing={1}>
                              <Typography>{option.description}</Typography>
                              {option.price > 0 ? (
                                <Typography fontWeight={"bold"} color="error">
                                  (+{option.price}฿)
                                </Typography>
                              ) : null}
                            </Stack>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <Stack
                  mt={2}
                  direction={"row"}
                  spacing={1}
                  justifyContent={"center"}
                >
                  <Typography fontWeight={"bold"}>
                    คุณจะได้รับสินค้าภายในวันที่
                  </Typography>
                  <Typography fontWeight={"bold"} color="error">
                    {moment(shipOptionDetail?.shipDate)
                      .add(543, "year")
                      .format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
                <Stack
                  mb={1}
                  direction="row"
                  spacing={1}
                  justifyContent={"center"}
                >
                  <Typography variant={"h6"}>ราคาสุทธิ</Typography>
                  <Typography variant="h5" color="error">
                    {formatNumber(shipOptionDetail?.salePrice, 0)}
                  </Typography>
                  <Typography variant={"h6"}>บาท</Typography>
                </Stack>
                <Box
                  width={"100%"}
                  sx={{
                    border: "solid black 1px",
                    minHeight: 350,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="caption">
                    สำหรับ {moment().format("DD/MM/YYYY HH:mm")}
                  </Typography>
                  {generateQR ? (
                    <CircularProgress />
                  ) : (
                    <img width={"100%"} src={qrCode} alt=""></img>
                  )}
                </Box>

                <Box
                  sx={{
                    border: "1px solid black",
                    minHeight: 300,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {imagePreview && (
                    <img
                      width={"100%"}
                      src={imagePreview}
                      alt=""
                      onClick={() => {
                        Swal.fire({
                          title: "คุณรต้องการเคลียร์สลิปไหม",
                          icon: "question",
                          showDenyButton: true,
                        });
                      }}
                    ></img>
                  )}
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={onChangeSlip}
                  />
                  {!imagePreview && (
                    <label htmlFor="raised-button-file">
                      <Button variant="contained" component="span">
                        แนบสลิป
                      </Button>
                    </label>
                  )}
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
        <Button color="error" onClick={() => onClose()} startIcon={<Close />}>
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductZoomDialog;
