import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button, CircularProgress, Collapse, Popover } from "@mui/material";

import { useLocation } from "react-router-dom";

import {
  MoreTime,
  QuestionAnswer as QuestionAnswerIcon,
  AccountCircle,
} from "@mui/icons-material";
import {
  CssBaseline,
  List,
  Box,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fab,
  Menu,
  MenuItem,
} from "@mui/material";

import Logo from "../assets/logo.jpg";
import axios from "../utils/axios";
import Chat from "./Chat";
import { useAuth } from "../hooks/useAuth";
import {
  ExpandLess,
  ExpandMore,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
  ScaleOutlined,
} from "@mui/icons-material";

import { ToastContainer } from "react-toastify";
import { useNavigate, Outlet, Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import AppBreadcrumbs from "./AppBreadcrumb";
import useAppState from "../hooks/useAppState";
import AddToHomeScreenButton from "./AddToHomeScreenButton";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "white",
  color: "black",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const { isLoggedIn, isChecking, name } = useAuth();

  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tooltipForMenu, setTooltipForMenu] = useState(null);

  const handlePopoverOpen = (menu) => {
    return (event) => {
      setTooltipForMenu(menu);
      setAnchorEl(event.currentTarget);
    };
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openToolTip = !openDrawer && Boolean(anchorEl);

  const { products, setProducts, setLoading, activity, setActivity } =
    useAppState();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    setLoading(true);
    axios.get("/api/products/detail").then(({ data }) => {
      setProducts(() => data);
      setLoading(false);
    });
  }, [setLoading, setProducts]);

  useEffect(() => {
    setActivity((prev) => ({ ...prev, loading: true }));
    axios
      .get("/api/activities")
      .then(({ data }) => {
        setActivity((prev) => ({ ...prev, loading: false, data }));
      })
      .catch(() =>
        setActivity((prev) => ({ ...prev, loading: false, data: [] }))
      );
  }, [setActivity]);

  const [openMenus, setOpenMenus] = useState([]);

  const toggleMenu = (menuName) => {
    if (!openDrawer) {
      return null;
    }
    // คลิกเพื่อหุบ
    if (openMenus.includes(menuName)) {
      setOpenMenus((prev) => {
        return [...prev].filter((m) => m !== menuName);
      });
    } else {
      setOpenMenus((prev) => [...prev, menuName]);
    }
  };

  const isMenuOpen = (menuName) => {
    return openMenus.includes(menuName);
  };

  useEffect(() => {
    if (!openDrawer) {
      setOpenMenus(() => []);
    }
  }, [openDrawer]);

  const showRightMenuIcon = (menuName) => {
    if (!openDrawer) {
      return null;
    }
    return isMenuOpen(menuName) ? <ExpandLess /> : <ExpandMore />;
  };

  const [selected, setSelected] = useState({});

  const navigate = useNavigate();

  const [anchorChat, setAnchorChat] = useState(null);

  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  const openMenu = Boolean(anchorUserMenu);

  const renderActivityMenu = () => {
    if (activity.loading || activity.data.length === 0)
      return (
        <ListItem
          disabled
          disablePadding
          sx={{ display: "block" }}
          onMouseEnter={handlePopoverOpen("ภาพกิจกรรมต่าง ๆ")}
          onMouseLeave={handlePopoverClose}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: openDrawer ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: openDrawer ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: 50, height: 50 }}
                src={process.env.PUBLIC_URL + "/Actitity.png"}
                alt={"Actitity menu"}
              />
            </ListItemIcon>
            <ListItemText
              primary="ภาพกิจกรรม"
              sx={{ opacity: openDrawer ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      );

    return (
      <div>
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onMouseEnter={handlePopoverOpen("ภาพกิจกรรมต่าง ๆ")}
          onMouseLeave={handlePopoverClose}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: openDrawer ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              toggleMenu("activity");

              // คลิกเมนู ตอนที่ถูกหุบ drawer เอาไว้
              if (!openDrawer) {
                navigate("activities", {
                  replace: true,
                });
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: openDrawer ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: 50, height: 50 }}
                src={process.env.PUBLIC_URL + "/Activity.png"}
                alt={"Activity menu"}
              />
            </ListItemIcon>
            <ListItemText
              primary={"ภาพกิจกรรม"}
              sx={{ opacity: openDrawer ? 1 : 0 }}
            />

            {showRightMenuIcon("activity")}
          </ListItemButton>
        </ListItem>
        <Collapse in={isMenuOpen("activity")} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {activity.data.map((a) => {
              return (
                <ListItemButton
                  auto
                  component={Link}
                  to={"activities/" + a.id}
                  key={a.id}
                  sx={{
                    pl: 6,
                    background:
                      selected.product === "activity" &&
                      selected.weight.name === a.name
                        ? "rgb(255, 199, 199)"
                        : "white",
                  }}
                  onClick={() => {
                    setSelected(() => {
                      return {
                        product: "activity",
                        weight: { name: a.name },
                      };
                    });
                  }}
                >
                  <ListItemIcon>
                    <ScaleOutlined />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ marginLeft: -2 }}
                    primary={a.name}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  };

  if (isChecking) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ToastContainer />

      <AppBar position="fixed" open={openDrawer}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src={Logo} width={50} height={50} alt="logo" />
          </Link>
          <Box display={{ xs: "none", sm: "block" }}>
            <Typography
              onClick={() => {
                navigate("/");
              }}
              sx={{ cursor: "pointer" }}
              variant="h6"
              noWrap
              component="div"
            >
              หจก.ห้างทองศรีวิชัย
            </Typography>
          </Box>

          {/* ปุ่ม version แสดงบนมือถือ */}
          <Box display={{ xs: "block", sm: "none" }} ml={2}>
            <Button
              startIcon={<AccountCircle />}
              variant="text"
              onClick={(e) => {
                if (name) {
                  setAnchorUserMenu(e.currentTarget);
                } else {
                  navigate("/login");
                }
              }}
              size="small"
            >
              {name ? name : "เข้าสู่ระบบ"}
            </Button>
            <Button
              startIcon={<MoreTime />}
              variant="text"
              onClick={() => navigate("/pawn")}
              size="small"
            >
              ต่อดอกออนไลน์
            </Button>
          </Box>

          {/* ปุ่ม version desktop */}
          <Box
            sx={{ position: "absolute", right: 10 }}
            display={{ xs: "none", sm: "block" }}
          >
            <AddToHomeScreenButton />
            <Button
              startIcon={<AccountCircle />}
              variant="text"
              onClick={(e) => {
                if (name) {
                  setAnchorUserMenu(e.currentTarget);
                } else {
                  navigate("/login");
                }
              }}
              sx={{ mr: 2 }}
            >
              {name ? name : "เข้าสู่ระบบ"}
            </Button>

            <Menu
              onClose={() => {
                setAnchorUserMenu(null);
              }}
              anchorEl={anchorUserMenu}
              open={openMenu}
            >
              <MenuItem
                onClick={() => {
                  setAnchorUserMenu(null);
                  localStorage.removeItem("token");
                  navigate(0);
                }}
              >
                ออกจากระบบ
              </MenuItem>
            </Menu>

            <Button
              startIcon={<MoreTime />}
              variant="contained"
              onClick={() => navigate("/pawn")}
            >
              ต่อดอกออนไลน์
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {products.map((p, index) => (
            <div key={index}>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onMouseEnter={handlePopoverOpen(p.product)}
                onMouseLeave={handlePopoverClose}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: openDrawer ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    toggleMenu(p.product);

                    // คลิกเมนู ตอนที่ถูกหุบ drawer เอาไว้
                    if (!openDrawer) {
                      navigate("products/" + p.product_code, {
                        replace: true,
                      });
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: openDrawer ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: 50, height: 50 }}
                      src={
                        process.env.PUBLIC_URL + "/" + p.product_code + ".jpg"
                      }
                      alt={p.product}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={p.product}
                    sx={{ opacity: openDrawer ? 1 : 0 }}
                  />

                  {showRightMenuIcon(p.product)}
                </ListItemButton>
              </ListItem>
              <Collapse in={isMenuOpen(p.product)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {p.weights.map((w, index) => {
                    return (
                      <ListItemButton
                        component={Link}
                        to={"products/" + p.product_code + "/weights/" + w.id}
                        key={index}
                        sx={{
                          pl: 6,
                          background:
                            selected.product === p.product &&
                            selected.weight.name === w.name
                              ? "rgb(255, 199, 199)"
                              : "white",
                        }}
                        onClick={() => {
                          setSelected(() => {
                            return {
                              product: p.product,
                              weight: w,
                            };
                          });
                        }}
                      >
                        <ListItemIcon>
                          <ScaleOutlined />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ marginLeft: -2 }}
                          primary={w.name}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          ))}
          <Divider />
          <List>
            {/* สมาชิก
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onMouseEnter={handlePopoverOpen("สมัครสมาชิก")}
              onMouseLeave={handlePopoverClose}
            >
              <ListItemButton
                onClick={() => navigate("/register")}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: 50, height: 50 }}
                    src={process.env.PUBLIC_URL + "/pawn_icon.png"}
                    alt={"สมัครสมาชิก"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="สมัครสมาชิก"
                  sx={{ opacity: openDrawer ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem> */}

            {renderActivityMenu()}

            {/* ต่อดอก */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onMouseEnter={handlePopoverOpen(
                "ตรวจเช็คขยายระยะเวลาขายฝาก (ต่อดอกออนไลน์)"
              )}
              onMouseLeave={handlePopoverClose}
            >
              <ListItemButton
                onClick={() => navigate("/pawn")}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: 50, height: 50 }}
                    src={process.env.PUBLIC_URL + "/pawn_icon.png"}
                    alt={"ต่อดอกออนไลน์"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="ต่อดอกออนไลน์"
                  sx={{ opacity: openDrawer ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {/* ชำระรายเดือนออมทอง */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onMouseEnter={handlePopoverOpen("ชำระรายเดือนออมทอง")}
              onMouseLeave={handlePopoverClose}
            >
              <ListItemButton
                onClick={() => navigate("/saving")}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: 50, height: 50 }}
                    src={process.env.PUBLIC_URL + "/saving.png"}
                    alt={"ชำระรายเดือนออมทอง"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="ชำระออมทอง"
                  sx={{ opacity: openDrawer ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {/* แต้มสะสม */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onMouseEnter={handlePopoverOpen("แต้มสะสมคะแนน")}
              onMouseLeave={handlePopoverClose}
            >
              <ListItemButton
                onClick={() => navigate("point")}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: 50, height: 50 }}
                    src={process.env.PUBLIC_URL + "point.png"}
                    alt={"Point"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="แต้มสะสม"
                  sx={{ opacity: openDrawer ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {/* ติดต่อ */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onMouseEnter={handlePopoverOpen("ข้อมูลติดต่อ")}
              onMouseLeave={handlePopoverClose}
            >
              <ListItemButton
                onClick={() => navigate("about")}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: 50, height: 50 }}
                    src={process.env.PUBLIC_URL + "/Map.png"}
                    alt={"Map menu"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="ข้อมูลติดต่อ"
                  sx={{ opacity: openDrawer ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <AppBreadcrumbs />
        <Outlet />

        <Chat anchorChat={anchorChat} onClose={() => setAnchorChat(null)} />

        <Fab
          variant="extended"
          color="primary"
          sx={{ position: "fixed", right: 10, bottom: 10 }}
          onClick={(e) => setAnchorChat(e.currentTarget)}
        >
          <QuestionAnswerIcon sx={{ mr: 1 }} />
          ติดต่อสอบถาม
        </Fab>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          ml: 8,
          mt: -8,
        }}
        open={openToolTip}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <Typography variant="h5" sx={{ p: 2 }}>
          {tooltipForMenu}
        </Typography>
      </Popover>
    </Box>
  );
}
