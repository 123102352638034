import {
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "../utils/axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/auth/me")
      .then((response) => {
        navigate("/");
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [idCard, setIdCard] = useState("");
  const [phone, setPhone] = useState("");

  const [errors, setErrors] = useState({});

  const [params] = useSearchParams();

  const callbackUrl = params.get("callbackUrl") ?? "/";
  const navigate = useNavigate();

  const submit = () => {
    if (!idCard || !phone) {
      setErrors((errors) => {
        return {
          idCard: !idCard,
          phone: !phone,
        };
      });
    } else {
      axios
        .post("/api/auth/login", {
          idCard,
          phone,
        })
        .then((response) => {
          const token = response.data.token;
          localStorage.setItem("token", token);
          toast.success("เข้าสู่ระบบเรียบร้อย");
          if (callbackUrl === "/") {
            navigate(0);
          } else {
            navigate(callbackUrl);
          }
        })
        .catch(() => {
          toast.error(
            "ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบรหัสบัตรประชาชนและเบอร์โทร"
          );
        });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container justifyContent={"center"}>
      <Grid container item sm={12} lg={3} spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <Typography variant="h6">เข้าสู่ระบบ</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={errors.idCard}
            label="หมายเลขบัตรประชาชน"
            placeholder="หมายเลขบัตรประชาชน"
            value={idCard}
            onChange={(e) => {
              setIdCard(e.target.value);
              setErrors((errors) => ({ ...errors, idCard: null }));
            }}
            helperText={errors.idCard && "กรุณากรอกรหมายเลขบัตรประชาชน"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            fullWidth
            error={errors.phone}
            label="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setErrors((errors) => ({ ...errors, phone: null }));
            }}
            helperText={errors.phone && "กรุณากรอกเบอร์มือถือ"}
          />
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={() => submit()}>
              เข้าสู่ระบบ
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => navigate("/register")}
            >
              ลงทะเบียน
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // return (
  //   <Box
  //     display={"flex"}
  //     justifyContent={"center"}
  //     width={"100%"}
  //     height={"85vh"}
  //   >
  //     <Box display={"flex"} flexDirection={"column"} gap={3} width={"30%"}>
  //       <Typography variant="h6">เข้าสู่ระบบ</Typography>
  //       <TextField
  //         error={errors.idCard}
  //         label="หมายเลขบัตรประชาชน"
  //         placeholder="หมายเลขบัตรประชาชน"
  //         value={idCard}
  //         onChange={(e) => {
  //           setIdCard(e.target.value);
  //           setErrors((errors) => ({ ...errors, idCard: null }));
  //         }}
  //         helperText={errors.idCard && "กรุณากรอกรหัสผ่าน"}
  //       />
  //       <TextField
  //         error={errors.phone}
  //         label="เบอร์โทรศัพท์"
  //         placeholder="เบอร์โทรศัพท์"
  //         value={phone}
  //         onChange={(e) => {
  //           setPhone(e.target.value);
  //           setErrors((errors) => ({ ...errors, phone: null }));
  //         }}
  //         helperText={errors.phone && "กรุณากรอกรหัสผ่าน"}
  //       />
  //       <Box display="flex" gap={2}>
  //         <Button fullWidth variant="contained" onClick={() => submit()}>
  //           เข้าสู่ระบบ
  //         </Button>
  //         <Button fullWidth variant="contained">
  //           ลงทะเบียน
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Box>
  // );
};

export default LoginPage;
