import { Grid, Typography, Card } from "@mui/material";

import React from "react";

import useAppState from "../hooks/useAppState";

import { useNavigate } from "react-router-dom";

const ActivityListPage = () => {
  const navigate = useNavigate();
  const { activity } = useAppState();
  return (
    <Grid container spacing={4}>
      {activity.data.map((act, index) => {
        return (
          <Grid item xs={12} lg={4} key={index}>
            <Card
              onClick={() => {
                navigate(act.id, { replace: true });
              }}
              sx={{
                minHeight: 140,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Typography>{act.name}</Typography>
              <Typography>{act.description}</Typography>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ActivityListPage;
