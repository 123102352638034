import axios from "axios";
import { toast } from "react-toastify";
import { v4 } from "uuid";

const instance = axios.create({
  headers: {
    common: {
      guid: sessionStorage.getItem("guid"),
    },
  },
});

instance.interceptors.request.use(
  (config) => {
    const guid = v4();
    if (!sessionStorage.getItem("guid")) {
      sessionStorage.setItem("guid", guid);
      config.headers.common.guid = guid;
    } else {
      config.headers.common.guid = sessionStorage.getItem("guid");
    }

    if (localStorage.getItem("token")) {
      config.headers.common.authorization =
        "Bearer " + localStorage.getItem("token");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

export const handleResponseError = (err) => {
  toast.error(err.response.data.message);
};
