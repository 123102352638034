export default function formatNumber(number, decimal = 2, comma = true) {
  if (isNaN(number)) {
    return 0;
  }
  return number.toLocaleString("en-US", {
    useGrouping: comma,
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
}
