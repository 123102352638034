import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef } from "react";

import { useState } from "react";
import { toast } from "react-toastify";
import useAppState from "../hooks/useAppState";
import axios from "../utils/axios";
import { handleResponseError } from "../utils/axios";
import useWindowDimension from "../hooks/useWindowDimension";
import { useMemo } from "react";

const PawnExtensionDialog = (props) => {
  const { open, onClose, daysGone, value, startDate, endDate, idPawn } = props;

  const [slip, setSlip] = useState(null);
  const imagePreview = slip && URL.createObjectURL(slip);
  const [extensionDays, setExtensionDays] = useState(daysGone > 30 ? 60 : 30);

  useEffect(() => {
    setExtensionDays(daysGone > 30 ? 60 : 30);
  }, [daysGone]);

  const { setTriggerRefreshPawnList } = useAppState();
  const handleChange = (event, newExtensionDay) => {
    setExtensionDays((prevExtensionDay) => {
      if (!newExtensionDay) {
        return prevExtensionDay;
      }
      return newExtensionDay;
    });
  };

  const [qrcode, setQrcode] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const { width } = useWindowDimension();
  const onlySixtyExtensionDay = useMemo(() => daysGone > 30, [daysGone]);

  const calculateInterest = () => {
    console.log({ value, extensionDays });
    // ต้องแก้ กรณีลูกค้าต่อดอกเร็วกว่า 10 วัน จะผิด
    return (value * (extensionDays * 0.001)).toFixed(0);
  };

  const onChangeSlip = (e) => {
    if (e.target.files.length === 1) {
      setSlip(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (open) {
      axios
        .get(
          "/api/pawns/qrcode?idPawn=" +
            idPawn +
            "&extensionDays=" +
            extensionDays,
          { responseType: "blob" }
        )
        .then((response) => {
          const blob = response.data;

          // Create a URL object for the blob
          setQrcode(URL.createObjectURL(blob));
          // const blobObj = new Blob([atob(response.data)], {
          //   type: "image/png",
          // });
          // setQrcode(URL.createObjectURL(blobObj));
        });
    }
  }, [extensionDays, idPawn, open]);

  const submit = () => {
    if (![30, 60].includes(extensionDays)) {
      toast.error("กรุณาเลือกวันที่ต้องการขยายระยะเวลา (ต่อดอก)");
      return;
    }
    if (!slip) {
      toast.error("กรุณาแนบสลิปการโอนเงิน");
      return;
    }

    setSubmitting(true);
    const form = new FormData();
    form.append("idPawn", idPawn);
    form.append("slip", slip);
    form.append("extensionDays", extensionDays);

    axios
      .post("/api/pawns", form, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
        },
      })
      .then(() => {
        toast.success("ส่งข้อมูลเรียบร้อยแล้ว");
        onClose();
        setTriggerRefreshPawnList((prev) => !prev);
      })
      .catch(handleResponseError)
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setSlip(null);
  }, [open]);

  const smallScreen = width <= 420;

  if (!idPawn) return null;

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>รายละเอียด</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Typography variant={"h6"}>
              วันที่ครบตามกำหนด{" "}
              {moment(endDate).add(543, "year").format("DD MMMM YYYY")}
            </Typography>
            <Typography variant={"h6"}>
              จำนวนวันที่ฝาก {daysGone} วัน
            </Typography>

            <Box mt={2} display="flex" alignItems={"center"} gap={2}>
              <Typography variant="h6">ขยายระยะเวลาต่อดอก</Typography>
              <ToggleButtonGroup
                color="primary"
                value={extensionDays}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value={30} disabled={onlySixtyExtensionDay}>
                  30 วัน
                </ToggleButton>
                <ToggleButton value={60}>60 วัน</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Typography variant="h6">
              ยอดชำระ {calculateInterest()} บาท
            </Typography>
            <Box mt={2} />
            <Typography variant="h6">
              วันที่เริ่มสัญญา{" "}
              {moment(startDate)
                .add(extensionDays, "day")
                .add(543, "year")
                .format("DD MMMM YYYY")}
            </Typography>
            <Typography variant="h6">
              วันที่ครบกำหนด{" "}
              {moment(endDate)
                .add(extensionDays, "day")
                .add(543, "year")
                .format("DD MMMM YYYY")}
            </Typography>
            <Box mt={2} />
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={onChangeSlip}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                แนบสลิป
              </Button>
            </label>

            <Box mt={2} />

            {imagePreview && (
              <img src={imagePreview} alt="slip" width={100} height={140} />
            )}
          </Grid>
          <Grid item md={6} sm={12} container justifyContent={"center"}>
            <Box
              display={"flex"}
              gap={0}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
            >
              <Typography variant="h6" maxWidth={256}>
                ธนาคารกรุงเทพ
              </Typography>
              <Typography variant="h6">ชื่อบัญชี หจ.ห้างทองศรีวิชัย</Typography>
              {/* <img
                src="/qrcode_receipt.jpg"
                alt="qrcode"
                width={smallScreen ? 320 : 400}
                height={smallScreen ? 320 : 400}
              /> */}
              {/* <div>{qrcode}</div>*/}
              <img
                src={qrcode}
                alt="qrcode"
                width={smallScreen ? 320 : 400}
                height={smallScreen ? 320 : 400}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          mt={2}
          mb={2}
          display={"flex"}
          justifyContent={"center"}
          gap={2}
          width="100%"
        >
          <Button
            startIcon={submitting && <CircularProgress color="warning" />}
            variant="contained"
            disabled={submitting}
            onClick={() => submit()}
          >
            ยืนยัน
          </Button>
          <Button onClick={() => onClose()}>ยกเลิก</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PawnExtensionDialog;
