export const provinces = [
  {
    id: 1,
    name_th: "กรุงเทพมหานคร",
    name_en: "Bangkok",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1001,
        name_th: "เขตพระนคร",
        name_en: "Khet Phra Nakhon",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100101,
            zip_code: 10200,
            name_th: "พระบรมมหาราชวัง",
            name_en: "Phra Borom Maha Ratchawang",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100102,
            zip_code: 10200,
            name_th: "วังบูรพาภิรมย์",
            name_en: "Wang Burapha Phirom",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100103,
            zip_code: 10200,
            name_th: "วัดราชบพิธ",
            name_en: "Wat Ratchabophit",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100104,
            zip_code: 10200,
            name_th: "สำราญราษฎร์",
            name_en: "Samran Rat",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100105,
            zip_code: 10200,
            name_th: "ศาลเจ้าพ่อเสือ",
            name_en: "San Chao Pho Suea",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100106,
            zip_code: 10200,
            name_th: "เสาชิงช้า",
            name_en: "Sao Chingcha",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100107,
            zip_code: 10200,
            name_th: "บวรนิเวศ",
            name_en: "Bowon Niwet",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100108,
            zip_code: 10200,
            name_th: "ตลาดยอด",
            name_en: "Talat Yot",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100109,
            zip_code: 10200,
            name_th: "ชนะสงคราม",
            name_en: "Chana Songkhram",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100110,
            zip_code: 10200,
            name_th: "บ้านพานถม",
            name_en: "Ban Phan Thom",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100111,
            zip_code: 10200,
            name_th: "บางขุนพรหม",
            name_en: "Bang Khun Phrom",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100112,
            zip_code: 10200,
            name_th: "วัดสามพระยา",
            name_en: "Wat Sam Phraya",
            amphure_id: 1001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1002,
        name_th: "เขตดุสิต",
        name_en: "Khet Dusit",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100201,
            zip_code: 10300,
            name_th: "ดุสิต",
            name_en: "Dusit",
            amphure_id: 1002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100202,
            zip_code: 10300,
            name_th: "วชิรพยาบาล",
            name_en: "Wachiraphayaban",
            amphure_id: 1002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100203,
            zip_code: 10300,
            name_th: "สวนจิตรลดา",
            name_en: "Suan Chit Lada",
            amphure_id: 1002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100204,
            zip_code: 10300,
            name_th: "สี่แยกมหานาค",
            name_en: "Si Yaek Maha Nak",
            amphure_id: 1002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100206,
            zip_code: 10300,
            name_th: "ถนนนครไชยศรี",
            name_en: "Thanon Nakhon Chai Si",
            amphure_id: 1002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1003,
        name_th: "เขตหนองจอก",
        name_en: "Khet Nong Chok",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100301,
            zip_code: 10530,
            name_th: "กระทุ่มราย",
            name_en: "Krathum Rai",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100302,
            zip_code: 10530,
            name_th: "หนองจอก",
            name_en: "Nong Chok",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100303,
            zip_code: 10530,
            name_th: "คลองสิบ",
            name_en: "Khlong Sip",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100304,
            zip_code: 10530,
            name_th: "คลองสิบสอง",
            name_en: "Khlong Sip Song",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100305,
            zip_code: 10530,
            name_th: "โคกแฝด",
            name_en: "Khok Faet",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100306,
            zip_code: 10530,
            name_th: "คู้ฝั่งเหนือ",
            name_en: "Khu Fang Nuea",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100307,
            zip_code: 10530,
            name_th: "ลำผักชี",
            name_en: "Lam Phak Chi",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100308,
            zip_code: 10530,
            name_th: "ลำต้อยติ่ง",
            name_en: "Lam Toiting",
            amphure_id: 1003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1004,
        name_th: "เขตบางรัก",
        name_en: "Khet Bang Rak",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100401,
            zip_code: 10500,
            name_th: "มหาพฤฒาราม",
            name_en: "Maha Phruettharam",
            amphure_id: 1004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100402,
            zip_code: 10500,
            name_th: "สีลม",
            name_en: "Si Lom",
            amphure_id: 1004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100403,
            zip_code: 10500,
            name_th: "สุริยวงศ์",
            name_en: "Suriyawong",
            amphure_id: 1004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100404,
            zip_code: 10500,
            name_th: "บางรัก",
            name_en: "Bang Rak",
            amphure_id: 1004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100405,
            zip_code: 10500,
            name_th: "สี่พระยา",
            name_en: "Si Phraya",
            amphure_id: 1004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1005,
        name_th: "เขตบางเขน",
        name_en: "Khet Bang Khen",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100502,
            zip_code: 10220,
            name_th: "อนุสาวรีย์",
            name_en: "Anusawari",
            amphure_id: 1005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100508,
            zip_code: 10220,
            name_th: "ท่าแร้ง",
            name_en: "Tha Raeng",
            amphure_id: 1005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1006,
        name_th: "เขตบางกะปิ",
        name_en: "Khet Bang Kapi",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100601,
            zip_code: 10240,
            name_th: "คลองจั่น",
            name_en: "Khlong Chan",
            amphure_id: 1006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100608,
            zip_code: 10240,
            name_th: "หัวหมาก",
            name_en: "Hua Mak",
            amphure_id: 1006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1007,
        name_th: "เขตปทุมวัน",
        name_en: "Khet Pathum Wan",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100701,
            zip_code: 10330,
            name_th: "รองเมือง",
            name_en: "Rong Mueang",
            amphure_id: 1007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100702,
            zip_code: 10330,
            name_th: "วังใหม่",
            name_en: "Wang Mai",
            amphure_id: 1007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100703,
            zip_code: 10330,
            name_th: "ปทุมวัน",
            name_en: "Pathum Wan",
            amphure_id: 1007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100704,
            zip_code: 10330,
            name_th: "ลุมพินี",
            name_en: "Lumphini",
            amphure_id: 1007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1008,
        name_th: "เขตป้อมปราบศัตรูพ่าย",
        name_en: "Khet Pom Prap Sattru Phai",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100801,
            zip_code: 10100,
            name_th: "ป้อมปราบ",
            name_en: "Pom Prap",
            amphure_id: 1008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100802,
            zip_code: 10100,
            name_th: "วัดเทพศิรินทร์",
            name_en: "Wat Thep Sirin",
            amphure_id: 1008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100803,
            zip_code: 10100,
            name_th: "คลองมหานาค",
            name_en: "Khlong Maha Nak",
            amphure_id: 1008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100804,
            zip_code: 10100,
            name_th: "บ้านบาตร",
            name_en: "Ban Bat",
            amphure_id: 1008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 100805,
            zip_code: 10100,
            name_th: "วัดโสมนัส",
            name_en: "Wat Sommanat",
            amphure_id: 1008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1009,
        name_th: "เขตพระโขนง",
        name_en: "Khet Phra Khanong",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 100905,
            zip_code: 10260,
            name_th: "บางจาก",
            name_en: "Bang Chak",
            amphure_id: 1009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1010,
        name_th: "เขตมีนบุรี",
        name_en: "Khet Min Buri",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101001,
            zip_code: 10510,
            name_th: "มีนบุรี",
            name_en: "Min Buri",
            amphure_id: 1010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101002,
            zip_code: 10510,
            name_th: "แสนแสบ",
            name_en: "Saen Saep",
            amphure_id: 1010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1011,
        name_th: "เขตลาดกระบัง",
        name_en: "Khet Lat Krabang",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101101,
            zip_code: 10520,
            name_th: "ลาดกระบัง",
            name_en: "Lat Krabang",
            amphure_id: 1011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101102,
            zip_code: 10520,
            name_th: "คลองสองต้นนุ่น",
            name_en: "Khlong Song Ton Nun",
            amphure_id: 1011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101103,
            zip_code: 10520,
            name_th: "คลองสามประเวศ",
            name_en: "Khlong Sam Prawet",
            amphure_id: 1011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101104,
            zip_code: 10520,
            name_th: "ลำปลาทิว",
            name_en: "Lam Pla Thio",
            amphure_id: 1011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101105,
            zip_code: 10520,
            name_th: "ทับยาว",
            name_en: "Thap Yao",
            amphure_id: 1011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101106,
            zip_code: 10520,
            name_th: "ขุมทอง",
            name_en: "Khum Thong",
            amphure_id: 1011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1012,
        name_th: "เขตยานนาวา",
        name_en: "Khet Yan Nawa",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101203,
            zip_code: 10120,
            name_th: "ช่องนนทรี",
            name_en: "Chong Nonsi",
            amphure_id: 1012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101204,
            zip_code: 10120,
            name_th: "บางโพงพาง",
            name_en: "Bang Phongphang",
            amphure_id: 1012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1013,
        name_th: "เขตสัมพันธวงศ์",
        name_en: "Khet Samphanthawong",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101301,
            zip_code: 10100,
            name_th: "จักรวรรดิ",
            name_en: "Chakkrawat",
            amphure_id: 1013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101302,
            zip_code: 10100,
            name_th: "สัมพันธวงศ์",
            name_en: "Samphanthawong",
            amphure_id: 1013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101303,
            zip_code: 10100,
            name_th: "ตลาดน้อย",
            name_en: "Talat Noi",
            amphure_id: 1013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1014,
        name_th: "เขตพญาไท",
        name_en: "Khet Phaya Thai",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101401,
            zip_code: 10400,
            name_th: "สามเสนใน",
            name_en: "Samsen Nai",
            amphure_id: 1014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1015,
        name_th: "เขตธนบุรี",
        name_en: "Khet Thon Buri",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101501,
            zip_code: 10600,
            name_th: "วัดกัลยาณ์",
            name_en: "Wat Kanlaya",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101502,
            zip_code: 10600,
            name_th: "หิรัญรูจี",
            name_en: "Hiran Ruchi",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101503,
            zip_code: 10600,
            name_th: "บางยี่เรือ",
            name_en: "Bang Yi Ruea",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101504,
            zip_code: 10600,
            name_th: "บุคคโล",
            name_en: "Bukkhalo",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101505,
            zip_code: 10600,
            name_th: "ตลาดพลู",
            name_en: "Talat Phlu",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101506,
            zip_code: 10600,
            name_th: "ดาวคะนอง",
            name_en: "Dao Khanong",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101507,
            zip_code: 10600,
            name_th: "สำเหร่",
            name_en: "Samre",
            amphure_id: 1015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1016,
        name_th: "เขตบางกอกใหญ่",
        name_en: "Khet Bangkok Yai",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101601,
            zip_code: 10600,
            name_th: "วัดอรุณ",
            name_en: "Wat Arun",
            amphure_id: 1016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101602,
            zip_code: 10600,
            name_th: "วัดท่าพระ",
            name_en: "Wat Tha Phra",
            amphure_id: 1016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1017,
        name_th: "เขตห้วยขวาง",
        name_en: "Khet Huai Khwang",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101701,
            zip_code: 10310,
            name_th: "ห้วยขวาง",
            name_en: "Huai Khwang",
            amphure_id: 1017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101702,
            zip_code: 10310,
            name_th: "บางกะปิ",
            name_en: "Bang Kapi",
            amphure_id: 1017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101704,
            zip_code: 10310,
            name_th: "สามเสนนอก",
            name_en: "Samsen Nok",
            amphure_id: 1017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1018,
        name_th: "เขตคลองสาน",
        name_en: "Khet Khlong San",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101801,
            zip_code: 10600,
            name_th: "สมเด็จเจ้าพระยา",
            name_en: "Somdet Chao Phraya",
            amphure_id: 1018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101802,
            zip_code: 10600,
            name_th: "คลองสาน",
            name_en: "Khlong San",
            amphure_id: 1018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101803,
            zip_code: 10600,
            name_th: "บางลำภูล่าง",
            name_en: "Bang Lamphu Lang",
            amphure_id: 1018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101804,
            zip_code: 10600,
            name_th: "คลองต้นไทร",
            name_en: "Khlong Ton Sai",
            amphure_id: 1018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1019,
        name_th: "เขตตลิ่งชัน",
        name_en: "Khet Taling Chan",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 101901,
            zip_code: 10170,
            name_th: "คลองชักพระ",
            name_en: "Khlong Chak Phra",
            amphure_id: 1019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101902,
            zip_code: 10170,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 1019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101903,
            zip_code: 10170,
            name_th: "ฉิมพลี",
            name_en: "Chimphli",
            amphure_id: 1019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101904,
            zip_code: 10170,
            name_th: "บางพรม",
            name_en: "Bang Phrom",
            amphure_id: 1019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101905,
            zip_code: 10170,
            name_th: "บางระมาด",
            name_en: "Bang Ramat",
            amphure_id: 1019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 101907,
            zip_code: 10170,
            name_th: "บางเชือกหนัง",
            name_en: "Bang Chueak Nang",
            amphure_id: 1019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1020,
        name_th: "เขตบางกอกน้อย",
        name_en: "Khet Bangkok Noi",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102004,
            zip_code: 10700,
            name_th: "ศิริราช",
            name_en: "Siri Rat",
            amphure_id: 1020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102005,
            zip_code: 10700,
            name_th: "บ้านช่างหล่อ",
            name_en: "Ban Chang Lo",
            amphure_id: 1020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102006,
            zip_code: 10700,
            name_th: "บางขุนนนท์",
            name_en: "Bang Khun Non",
            amphure_id: 1020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102007,
            zip_code: 10700,
            name_th: "บางขุนศรี",
            name_en: "Bang Khun Si",
            amphure_id: 1020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102009,
            zip_code: 10700,
            name_th: "อรุณอมรินทร์",
            name_en: "Arun Ammarin",
            amphure_id: 1020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1021,
        name_th: "เขตบางขุนเทียน",
        name_en: "Khet Bang Khun Thian",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102105,
            zip_code: 10150,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 1021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102107,
            zip_code: 10150,
            name_th: "แสมดำ",
            name_en: "Samae Dam",
            amphure_id: 1021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1022,
        name_th: "เขตภาษีเจริญ",
        name_en: "Khet Phasi Charoen",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102201,
            zip_code: 10160,
            name_th: "บางหว้า",
            name_en: "Bang Wa",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102202,
            zip_code: 10160,
            name_th: "บางด้วน",
            name_en: "Bang Duan",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102203,
            zip_code: 10160,
            name_th: "บางแค",
            name_en: "Bang Kae",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102204,
            zip_code: 10160,
            name_th: "บางแคเหนือ",
            name_en: "Bang Kae Nua",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102205,
            zip_code: 10160,
            name_th: "บางไผ่",
            name_en: "Bang Phai",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102206,
            zip_code: 10160,
            name_th: "บางจาก",
            name_en: "Bang Chak",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102207,
            zip_code: 10160,
            name_th: "บางแวก",
            name_en: "Bang Waek",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102208,
            zip_code: 10160,
            name_th: "คลองขวาง",
            name_en: "Khlong Khwang",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102209,
            zip_code: 10160,
            name_th: "ปากคลองภาษีเจริญ",
            name_en: "Pak Khlong Phasi Charoen",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102210,
            zip_code: 10160,
            name_th: "คูหาสวรรค์",
            name_en: "Khuha Sawan",
            amphure_id: 1022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1023,
        name_th: "เขตหนองแขม",
        name_en: "Khet Nong Khaem",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102302,
            zip_code: 10160,
            name_th: "หนองแขม",
            name_en: "Nong Khaem",
            amphure_id: 1023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102303,
            zip_code: 10160,
            name_th: "หนองค้างพลู",
            name_en: "Nong Khang Phlu",
            amphure_id: 1023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1024,
        name_th: "เขตราษฎร์บูรณะ",
        name_en: "Khet Rat Burana",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102401,
            zip_code: 10140,
            name_th: "ราษฎร์บูรณะ",
            name_en: "Rat Burana",
            amphure_id: 1024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102402,
            zip_code: 10140,
            name_th: "บางปะกอก",
            name_en: "Bang Pakok",
            amphure_id: 1024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1025,
        name_th: "เขตบางพลัด",
        name_en: "Khet Bang Phlat",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102501,
            zip_code: 10700,
            name_th: "บางพลัด",
            name_en: "Bang Phlat",
            amphure_id: 1025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102502,
            zip_code: 10700,
            name_th: "บางอ้อ",
            name_en: "Bang O",
            amphure_id: 1025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102503,
            zip_code: 10700,
            name_th: "บางบำหรุ",
            name_en: "Bang Bamru",
            amphure_id: 1025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102504,
            zip_code: 10700,
            name_th: "บางยี่ขัน",
            name_en: "Bang Yi Khan",
            amphure_id: 1025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1026,
        name_th: "เขตดินแดง",
        name_en: "Khet Din Daeng",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102601,
            zip_code: 10400,
            name_th: "ดินแดง",
            name_en: "Din Daeng",
            amphure_id: 1026,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1027,
        name_th: "เขตบึงกุ่ม",
        name_en: "Khet Bueng Kum",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102701,
            zip_code: 10240,
            name_th: "คลองกุ่ม",
            name_en: "Khlong Kum",
            amphure_id: 1027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102702,
            zip_code: 10240,
            name_th: "สะพานสูง",
            name_en: "Saphan Sung",
            amphure_id: 1027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102703,
            zip_code: 10240,
            name_th: "คันนายาว",
            name_en: "Khan Na Yao",
            amphure_id: 1027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1028,
        name_th: "เขตสาทร",
        name_en: "Khet Sathon",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102801,
            zip_code: 10120,
            name_th: "ทุ่งวัดดอน",
            name_en: "Thung Wat Don",
            amphure_id: 1028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102802,
            zip_code: 10120,
            name_th: "ยานนาวา",
            name_en: "Yan Nawa",
            amphure_id: 1028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 102803,
            zip_code: 10120,
            name_th: "ทุ่งมหาเมฆ",
            name_en: "Thung Maha Mek",
            amphure_id: 1028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1029,
        name_th: "เขตบางซื่อ",
        name_en: "Khet Bang Sue",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 102901,
            zip_code: 10800,
            name_th: "บางซื่อ",
            name_en: "Bang Sue",
            amphure_id: 1029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1030,
        name_th: "เขตจตุจักร",
        name_en: "Khet Chatuchak",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103001,
            zip_code: 10900,
            name_th: "ลาดยาว",
            name_en: "Lat Yao",
            amphure_id: 1030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103002,
            zip_code: 10900,
            name_th: "เสนานิคม",
            name_en: "Sena Nikhom",
            amphure_id: 1030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103003,
            zip_code: 10900,
            name_th: "จันทรเกษม",
            name_en: "Chan Kasem",
            amphure_id: 1030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103004,
            zip_code: 10900,
            name_th: "จอมพล",
            name_en: "Chom Phon",
            amphure_id: 1030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103005,
            zip_code: 10900,
            name_th: "จตุจักร",
            name_en: "Chatuchak",
            amphure_id: 1030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1031,
        name_th: "เขตบางคอแหลม",
        name_en: "Khet Bang Kho Laem",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103101,
            zip_code: 10120,
            name_th: "บางคอแหลม",
            name_en: "Bang Kho Laem",
            amphure_id: 1031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103102,
            zip_code: 10120,
            name_th: "วัดพระยาไกร",
            name_en: "Wat Phraya Krai",
            amphure_id: 1031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103103,
            zip_code: 10120,
            name_th: "บางโคล่",
            name_en: "Bang Khlo",
            amphure_id: 1031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1032,
        name_th: "เขตประเวศ",
        name_en: "Khet Prawet",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103201,
            zip_code: 10250,
            name_th: "ประเวศ",
            name_en: "Prawet",
            amphure_id: 1032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103202,
            zip_code: 10250,
            name_th: "หนองบอน",
            name_en: "Nong Bon",
            amphure_id: 1032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103203,
            zip_code: 10250,
            name_th: "ดอกไม้",
            name_en: "Dokmai",
            amphure_id: 1032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103204,
            zip_code: 10250,
            name_th: "สวนหลวง",
            name_en: "Suan Luang",
            amphure_id: 1032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1033,
        name_th: "เขตคลองเตย",
        name_en: "Khet Khlong Toei",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103301,
            zip_code: 10110,
            name_th: "คลองเตย",
            name_en: "Khlong Toei",
            amphure_id: 1033,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103302,
            zip_code: 10110,
            name_th: "คลองตัน",
            name_en: "Khlong Tan",
            amphure_id: 1033,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103303,
            zip_code: 10110,
            name_th: "พระโขนง",
            name_en: "Phra Khanong",
            amphure_id: 1033,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103304,
            zip_code: 10110,
            name_th: "คลองเตยเหนือ",
            name_en: "Khlong Toei Nua",
            amphure_id: 1033,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103305,
            zip_code: 10110,
            name_th: "คลองตันเหนือ",
            name_en: "Khlong Tan Nua",
            amphure_id: 1033,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103306,
            zip_code: 10110,
            name_th: "พระโขนงเหนือ",
            name_en: "Phra Khanong Nua",
            amphure_id: 1033,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1034,
        name_th: "เขตสวนหลวง",
        name_en: "Khet Suan Luang",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103401,
            zip_code: 10250,
            name_th: "สวนหลวง",
            name_en: "Suan Luang",
            amphure_id: 1034,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1035,
        name_th: "เขตจอมทอง",
        name_en: "Khet Chom Thong",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103501,
            zip_code: 10150,
            name_th: "บางขุนเทียน",
            name_en: "Bang Khun Thian",
            amphure_id: 1035,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103502,
            zip_code: 10150,
            name_th: "บางค้อ",
            name_en: "Bang Kho",
            amphure_id: 1035,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103503,
            zip_code: 10150,
            name_th: "บางมด",
            name_en: "Bang Mot",
            amphure_id: 1035,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103504,
            zip_code: 10150,
            name_th: "จอมทอง",
            name_en: "Chom Thong",
            amphure_id: 1035,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1036,
        name_th: "เขตดอนเมือง",
        name_en: "Khet Don Mueang",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103602,
            zip_code: 10210,
            name_th: "สีกัน",
            name_en: "Si Kan",
            amphure_id: 1036,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1037,
        name_th: "เขตราชเทวี",
        name_en: "Khet Ratchathewi",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103701,
            zip_code: 10400,
            name_th: "ทุ่งพญาไท",
            name_en: "Thung Phaya Thai",
            amphure_id: 1037,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103702,
            zip_code: 10400,
            name_th: "ถนนพญาไท",
            name_en: "Thanon Phaya Thai",
            amphure_id: 1037,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103703,
            zip_code: 10400,
            name_th: "ถนนเพชรบุรี",
            name_en: "Thanon Phetchaburi",
            amphure_id: 1037,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103704,
            zip_code: 10400,
            name_th: "มักกะสัน",
            name_en: "Makkasan",
            amphure_id: 1037,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1038,
        name_th: "เขตลาดพร้าว",
        name_en: "Khet Lat Phrao",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103801,
            zip_code: 10230,
            name_th: "ลาดพร้าว",
            name_en: "Lat Phrao",
            amphure_id: 1038,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103802,
            zip_code: 10230,
            name_th: "จรเข้บัว",
            name_en: "Chorakhe Bua",
            amphure_id: 1038,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1039,
        name_th: "เขตวัฒนา",
        name_en: "Khet Watthana",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 103901,
            zip_code: 10110,
            name_th: "คลองเตยเหนือ",
            name_en: "Khlong Toei Nuea",
            amphure_id: 1039,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103902,
            zip_code: 10110,
            name_th: "คลองตันเหนือ",
            name_en: "Khlong Tan Nuea",
            amphure_id: 1039,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 103903,
            zip_code: 10110,
            name_th: "พระโขนงเหนือ",
            name_en: "Phra Khanong Nuea",
            amphure_id: 1039,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1040,
        name_th: "เขตบางแค",
        name_en: "Khet Bang Khae",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104001,
            zip_code: 10160,
            name_th: "บางแค",
            name_en: "Bang Khae",
            amphure_id: 1040,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104002,
            zip_code: 10160,
            name_th: "บางแคเหนือ",
            name_en: "Bang Khae Nuea",
            amphure_id: 1040,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104003,
            zip_code: 10160,
            name_th: "บางไผ่",
            name_en: "Bang Phai",
            amphure_id: 1040,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104004,
            zip_code: 10160,
            name_th: "หลักสอง",
            name_en: "Lak Song",
            amphure_id: 1040,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1041,
        name_th: "เขตหลักสี่",
        name_en: "Khet Lak Si",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104101,
            zip_code: 10210,
            name_th: "ทุ่งสองห้อง",
            name_en: "Thung Song Hong",
            amphure_id: 1041,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104102,
            zip_code: 10210,
            name_th: "ตลาดบางเขน",
            name_en: "Talat Bang Khen",
            amphure_id: 1041,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1042,
        name_th: "เขตสายไหม",
        name_en: "Khet Sai Mai",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104201,
            zip_code: 10220,
            name_th: "สายไหม",
            name_en: "Sai Mai",
            amphure_id: 1042,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104202,
            zip_code: 10220,
            name_th: "ออเงิน",
            name_en: "O Ngoen",
            amphure_id: 1042,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104203,
            zip_code: 10220,
            name_th: "คลองถนน",
            name_en: "Khlong Thanon",
            amphure_id: 1042,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1043,
        name_th: "เขตคันนายาว",
        name_en: "Khet Khan Na Yao",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104301,
            zip_code: 10230,
            name_th: "คันนายาว",
            name_en: "Khan Na Yao",
            amphure_id: 1043,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1044,
        name_th: "เขตสะพานสูง",
        name_en: "Khet Saphan Sung",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104401,
            zip_code: 10240,
            name_th: "สะพานสูง",
            name_en: "Sapan Sung",
            amphure_id: 1044,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1045,
        name_th: "เขตวังทองหลาง",
        name_en: "Khet Wang Thonglang",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104501,
            zip_code: 10310,
            name_th: "วังทองหลาง",
            name_en: "Wang Thonglang",
            amphure_id: 1045,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1046,
        name_th: "เขตคลองสามวา",
        name_en: "Khet Khlong Sam Wa",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104601,
            zip_code: 10510,
            name_th: "สามวาตะวันตก",
            name_en: "Sam Wa Tawantok",
            amphure_id: 1046,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104602,
            zip_code: 10510,
            name_th: "สามวาตะวันออก",
            name_en: "Sam Wa Tawan-ok",
            amphure_id: 1046,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104603,
            zip_code: 10510,
            name_th: "บางชัน",
            name_en: "Bang Chan",
            amphure_id: 1046,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104604,
            zip_code: 10510,
            name_th: "ทรายกองดิน",
            name_en: "Sai Kong Din",
            amphure_id: 1046,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104605,
            zip_code: 10510,
            name_th: "ทรายกองดินใต้",
            name_en: "Sai Kong Din Tai",
            amphure_id: 1046,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1047,
        name_th: "เขตบางนา",
        name_en: "Khet Bang Na",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104701,
            zip_code: 10260,
            name_th: "บางนา",
            name_en: "Bang Na",
            amphure_id: 1047,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1048,
        name_th: "เขตทวีวัฒนา",
        name_en: "Khet Thawi Watthana",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104801,
            zip_code: 10170,
            name_th: "ทวีวัฒนา",
            name_en: "Thawi Watthana",
            amphure_id: 1048,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104802,
            zip_code: 10170,
            name_th: "ศาลาธรรมสพน์",
            name_en: "Sala Thammasop",
            amphure_id: 1048,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1049,
        name_th: "เขตทุ่งครุ",
        name_en: "Khet Thung Khru",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 104901,
            zip_code: 10140,
            name_th: "บางมด",
            name_en: "Bang Mot",
            amphure_id: 1049,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 104902,
            zip_code: 10140,
            name_th: "ทุ่งครุ",
            name_en: "Thung Khru",
            amphure_id: 1049,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1050,
        name_th: "เขตบางบอน",
        name_en: "Khet Bang Bon",
        province_id: 1,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 105001,
            zip_code: 10150,
            name_th: "บางบอน",
            name_en: "Bang Bon",
            amphure_id: 1050,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name_th: "สมุทรปราการ",
    name_en: "Samut Prakan",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1101,
        name_th: "เมืองสมุทรปราการ",
        name_en: "Mueang Samut Prakan",
        province_id: 2,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 110101,
            zip_code: 10270,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110102,
            zip_code: 10270,
            name_th: "สำโรงเหนือ",
            name_en: "Samrong Nuea",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110103,
            zip_code: 10270,
            name_th: "บางเมือง",
            name_en: "Bang Mueang",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110104,
            zip_code: 10280,
            name_th: "ท้ายบ้าน",
            name_en: "Thai Ban",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110108,
            zip_code: 10280,
            name_th: "บางปูใหม่",
            name_en: "Bang Pu Mai",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110110,
            zip_code: 10280,
            name_th: "แพรกษา",
            name_en: "Phraek Sa",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110111,
            zip_code: 10270,
            name_th: "บางโปรง",
            name_en: "Bang Prong",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110112,
            zip_code: 10270,
            name_th: "บางปู",
            name_en: "Bang Pu",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110113,
            zip_code: 10270,
            name_th: "บางด้วน",
            name_en: "Bang Duan",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110114,
            zip_code: 10270,
            name_th: "บางเมืองใหม่",
            name_en: "Bang Mueang Mai",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110115,
            zip_code: 10270,
            name_th: "เทพารักษ์",
            name_en: "Thepharak",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110116,
            zip_code: 10280,
            name_th: "ท้ายบ้านใหม่",
            name_en: "Thai Ban Mai",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110117,
            zip_code: 10280,
            name_th: "แพรกษาใหม่",
            name_en: "Phraek Sa Mai",
            amphure_id: 1101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1102,
        name_th: "บางบ่อ",
        name_en: "Bang Bo",
        province_id: 2,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 110201,
            zip_code: 10560,
            name_th: "บางบ่อ",
            name_en: "Bang Bo",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110202,
            zip_code: 10560,
            name_th: "บ้านระกาศ",
            name_en: "Ban Rakat",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110203,
            zip_code: 10560,
            name_th: "บางพลีน้อย",
            name_en: "Bang Phli Noi",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110204,
            zip_code: 10560,
            name_th: "บางเพรียง",
            name_en: "Bang Phriang",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110205,
            zip_code: 10550,
            name_th: "คลองด่าน",
            name_en: "Khlong Dan",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110206,
            zip_code: 10560,
            name_th: "คลองสวน",
            name_en: "Khlong Suan",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110207,
            zip_code: 10560,
            name_th: "เปร็ง",
            name_en: "Preng",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110208,
            zip_code: 10560,
            name_th: "คลองนิยมยาตรา",
            name_en: "Khlong Niyom Yattra",
            amphure_id: 1102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1103,
        name_th: "บางพลี",
        name_en: "Bang Phli",
        province_id: 2,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 110301,
            zip_code: 10540,
            name_th: "บางพลีใหญ่",
            name_en: "Bang Phli Yai",
            amphure_id: 1103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110302,
            zip_code: 10540,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 1103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110303,
            zip_code: 10540,
            name_th: "บางปลา",
            name_en: "Bang Pla",
            amphure_id: 1103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110304,
            zip_code: 10540,
            name_th: "บางโฉลง",
            name_en: "Bang Chalong",
            amphure_id: 1103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110308,
            zip_code: 10540,
            name_th: "ราชาเทวะ",
            name_en: "Racha Thewa",
            amphure_id: 1103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110309,
            zip_code: 10540,
            name_th: "หนองปรือ",
            name_en: "Nong Prue",
            amphure_id: 1103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1104,
        name_th: "พระประแดง",
        name_en: "Phra Pradaeng",
        province_id: 2,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 110401,
            zip_code: 10130,
            name_th: "ตลาด",
            name_en: "Talat",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110402,
            zip_code: 10130,
            name_th: "บางพึ่ง",
            name_en: "Bang Phueng",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110403,
            zip_code: 10130,
            name_th: "บางจาก",
            name_en: "Bang Chak",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110404,
            zip_code: 10130,
            name_th: "บางครุ",
            name_en: "Bang Khru",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110405,
            zip_code: 10130,
            name_th: "บางหญ้าแพรก",
            name_en: "Bang Ya Phraek",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110406,
            zip_code: 10130,
            name_th: "บางหัวเสือ",
            name_en: "Bang Hua Suea",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110407,
            zip_code: 10130,
            name_th: "สำโรงใต้",
            name_en: "Samrong Tai",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110408,
            zip_code: 10130,
            name_th: "บางยอ",
            name_en: "Bang Yo",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110409,
            zip_code: 10130,
            name_th: "บางกะเจ้า",
            name_en: "Bang Kachao",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110410,
            zip_code: 10130,
            name_th: "บางน้ำผึ้ง",
            name_en: "Bang Namphueng",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110411,
            zip_code: 10130,
            name_th: "บางกระสอบ",
            name_en: "Bang Krasop",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110412,
            zip_code: 10130,
            name_th: "บางกอบัว",
            name_en: "Bang Ko Bua",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110413,
            zip_code: 10130,
            name_th: "ทรงคนอง",
            name_en: "Song Khanong",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110414,
            zip_code: 10130,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110415,
            zip_code: 10130,
            name_th: "สำโรงกลาง",
            name_en: "Samrong Klang",
            amphure_id: 1104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1105,
        name_th: "พระสมุทรเจดีย์",
        name_en: "Phra Samut Chedi",
        province_id: 2,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 110501,
            zip_code: 10290,
            name_th: "นาเกลือ",
            name_en: "Na Kluea",
            amphure_id: 1105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110502,
            zip_code: 10290,
            name_th: "บ้านคลองสวน",
            name_en: "Ban Khlong Suan",
            amphure_id: 1105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110503,
            zip_code: 10290,
            name_th: "แหลมฟ้าผ่า",
            name_en: "Laem Fa Pha",
            amphure_id: 1105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110504,
            zip_code: 10290,
            name_th: "ปากคลองบางปลากด",
            name_en: "Pak Klong Bang Pla Kot",
            amphure_id: 1105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110505,
            zip_code: 10290,
            name_th: "ในคลองบางปลากด",
            name_en: "Nai Khlong Bang Pla Kot",
            amphure_id: 1105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1106,
        name_th: "บางเสาธง",
        name_en: "Bang Sao Thong",
        province_id: 2,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 110601,
            zip_code: 10540,
            name_th: "บางเสาธง",
            name_en: "Bang Sao Thong",
            amphure_id: 1106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110602,
            zip_code: 10540,
            name_th: "ศีรษะจรเข้น้อย",
            name_en: "Sisa Chorakhe Noi",
            amphure_id: 1106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 110603,
            zip_code: 10540,
            name_th: "ศีรษะจรเข้ใหญ่",
            name_en: "Sisa Chorakhe Yai",
            amphure_id: 1106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name_th: "นนทบุรี",
    name_en: "Nonthaburi",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1201,
        name_th: "เมืองนนทบุรี",
        name_en: "Mueang Nonthaburi",
        province_id: 3,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 120101,
            zip_code: 11000,
            name_th: "สวนใหญ่",
            name_en: "Suan Yai",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120102,
            zip_code: 11000,
            name_th: "ตลาดขวัญ",
            name_en: "Talat Khwan",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120103,
            zip_code: 11000,
            name_th: "บางเขน",
            name_en: "Bang Khen",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120104,
            zip_code: 11000,
            name_th: "บางกระสอ",
            name_en: "Bang Kraso",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120105,
            zip_code: 11000,
            name_th: "ท่าทราย",
            name_en: "Tha Sai",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120106,
            zip_code: 11000,
            name_th: "บางไผ่",
            name_en: "Bang Phai",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120107,
            zip_code: 11000,
            name_th: "บางศรีเมือง",
            name_en: "Bang Si Mueang",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120108,
            zip_code: 11000,
            name_th: "บางกร่าง",
            name_en: "Bang Krang",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120109,
            zip_code: 11000,
            name_th: "ไทรม้า",
            name_en: "Sai Ma",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120110,
            zip_code: 11000,
            name_th: "บางรักน้อย",
            name_en: "Bang Rak Noi",
            amphure_id: 1201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1202,
        name_th: "บางกรวย",
        name_en: "Bang Kruai",
        province_id: 3,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 120201,
            zip_code: 11130,
            name_th: "วัดชลอ",
            name_en: "Wat Chalo",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120202,
            zip_code: 11130,
            name_th: "บางกรวย",
            name_en: "Bang Kruai",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120203,
            zip_code: 11130,
            name_th: "บางสีทอง",
            name_en: "Bang Si Thong",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120204,
            zip_code: 11130,
            name_th: "บางขนุน",
            name_en: "Bang Khanun",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120205,
            zip_code: 11130,
            name_th: "บางขุนกอง",
            name_en: "Bang Khun Kong",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120206,
            zip_code: 11130,
            name_th: "บางคูเวียง",
            name_en: "Bang Khu Wiang",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120207,
            zip_code: 11130,
            name_th: "มหาสวัสดิ์",
            name_en: "Maha Sawat",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120208,
            zip_code: 11130,
            name_th: "ปลายบาง",
            name_en: "Plai Bang",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120209,
            zip_code: 11130,
            name_th: "ศาลากลาง",
            name_en: "Sala Klang",
            amphure_id: 1202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1203,
        name_th: "บางใหญ่",
        name_en: "Bang Yai",
        province_id: 3,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 120301,
            zip_code: 11140,
            name_th: "บางม่วง",
            name_en: "Bang Muang",
            amphure_id: 1203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120302,
            zip_code: 11140,
            name_th: "บางแม่นาง",
            name_en: "Bang Mae Nang",
            amphure_id: 1203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120303,
            zip_code: 11140,
            name_th: "บางเลน",
            name_en: "Bang Len",
            amphure_id: 1203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120304,
            zip_code: 11140,
            name_th: "เสาธงหิน",
            name_en: "Sao Thong Hin",
            amphure_id: 1203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120305,
            zip_code: 11140,
            name_th: "บางใหญ่",
            name_en: "Bang Yai",
            amphure_id: 1203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120306,
            zip_code: 11140,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 1203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1204,
        name_th: "บางบัวทอง",
        name_en: "Bang Bua Thong",
        province_id: 3,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 120401,
            zip_code: 11110,
            name_th: "โสนลอย",
            name_en: "Sano Loi",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120402,
            zip_code: 11110,
            name_th: "บางบัวทอง",
            name_en: "Bang Bua Thong",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120403,
            zip_code: 11110,
            name_th: "บางรักใหญ่",
            name_en: "Bang Rak Yai",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120404,
            zip_code: 11110,
            name_th: "บางคูรัด",
            name_en: "Bang Khu Rat",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120405,
            zip_code: 11110,
            name_th: "ละหาร",
            name_en: "Lahan",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120406,
            zip_code: 11110,
            name_th: "ลำโพ",
            name_en: "Lam Pho",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120407,
            zip_code: 11110,
            name_th: "พิมลราช",
            name_en: "Phimon Rat",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120408,
            zip_code: 11110,
            name_th: "บางรักพัฒนา",
            name_en: "Bang Rak Phatthana",
            amphure_id: 1204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1205,
        name_th: "ไทรน้อย",
        name_en: "Sai Noi",
        province_id: 3,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 120501,
            zip_code: 11150,
            name_th: "ไทรน้อย",
            name_en: "Sai Noi",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120502,
            zip_code: 11150,
            name_th: "ราษฎร์นิยม",
            name_en: "Rat Niyom",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120503,
            zip_code: 11150,
            name_th: "หนองเพรางาย",
            name_en: "Nong Phrao Ngai",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120504,
            zip_code: 11150,
            name_th: "ไทรใหญ่",
            name_en: "Sai Yai",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120505,
            zip_code: 11150,
            name_th: "ขุนศรี",
            name_en: "Khun Si",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120506,
            zip_code: 11150,
            name_th: "คลองขวาง",
            name_en: "Khlong Khwang",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120507,
            zip_code: 11150,
            name_th: "ทวีวัฒนา",
            name_en: "Thawi Watthana",
            amphure_id: 1205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1206,
        name_th: "ปากเกร็ด",
        name_en: "Pak Kret",
        province_id: 3,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 120601,
            zip_code: 11120,
            name_th: "ปากเกร็ด",
            name_en: "Pak Kret",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120602,
            zip_code: 11120,
            name_th: "บางตลาด",
            name_en: "Bang Talat",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120603,
            zip_code: 11120,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120604,
            zip_code: 11120,
            name_th: "บางพูด",
            name_en: "Bang Phut",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120605,
            zip_code: 11120,
            name_th: "บางตะไนย์",
            name_en: "Bang Tanai",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120606,
            zip_code: 11120,
            name_th: "คลองพระอุดม",
            name_en: "Khlong Phra Udom",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120607,
            zip_code: 11120,
            name_th: "ท่าอิฐ",
            name_en: "Tha It",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120608,
            zip_code: 11120,
            name_th: "เกาะเกร็ด",
            name_en: "Ko Kret",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120609,
            zip_code: 11120,
            name_th: "อ้อมเกร็ด",
            name_en: "Om Kret",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120610,
            zip_code: 11120,
            name_th: "คลองข่อย",
            name_en: "Khlong Khoi",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120611,
            zip_code: 11120,
            name_th: "บางพลับ",
            name_en: "Bang Phlap",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 120612,
            zip_code: 11120,
            name_th: "คลองเกลือ",
            name_en: "Khlong Kluea",
            amphure_id: 1206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name_th: "ปทุมธานี",
    name_en: "Pathum Thani",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1301,
        name_th: "เมืองปทุมธานี",
        name_en: "Mueang Pathum Thani",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130101,
            zip_code: 12000,
            name_th: "บางปรอก",
            name_en: "Bang Parok",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130102,
            zip_code: 12000,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130103,
            zip_code: 12000,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130104,
            zip_code: 12000,
            name_th: "บ้านฉาง",
            name_en: "Ban Chang",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130105,
            zip_code: 12000,
            name_th: "บ้านกระแชง",
            name_en: "Ban Krachaeng",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130106,
            zip_code: 12000,
            name_th: "บางขะแยง",
            name_en: "Bang Khayaeng",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130107,
            zip_code: 12000,
            name_th: "บางคูวัด",
            name_en: "Bang Khu Wat",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130108,
            zip_code: 12000,
            name_th: "บางหลวง",
            name_en: "Bang Luang",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130109,
            zip_code: 12000,
            name_th: "บางเดื่อ",
            name_en: "Bang Duea",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130110,
            zip_code: 12000,
            name_th: "บางพูด",
            name_en: "Bang Phut",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130111,
            zip_code: 12000,
            name_th: "บางพูน",
            name_en: "Bang Phun",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130112,
            zip_code: 12000,
            name_th: "บางกะดี",
            name_en: "Bang Kadi",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130113,
            zip_code: 12000,
            name_th: "สวนพริกไทย",
            name_en: "Suan Phrikthai",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130114,
            zip_code: 12000,
            name_th: "หลักหก",
            name_en: "Lak Hok",
            amphure_id: 1301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1302,
        name_th: "คลองหลวง",
        name_en: "Khlong Luang",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130201,
            zip_code: 12120,
            name_th: "คลองหนึ่ง",
            name_en: "Khlong Nueng",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130202,
            zip_code: 12120,
            name_th: "คลองสอง",
            name_en: "Khlong Song",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130203,
            zip_code: 12120,
            name_th: "คลองสาม",
            name_en: "Khlong Sam",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130204,
            zip_code: 12120,
            name_th: "คลองสี่",
            name_en: "Khlong Si",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130205,
            zip_code: 12120,
            name_th: "คลองห้า",
            name_en: "Khlong Ha",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130206,
            zip_code: 12120,
            name_th: "คลองหก",
            name_en: "Khlong Hok",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130207,
            zip_code: 12120,
            name_th: "คลองเจ็ด",
            name_en: "Khlong Chet",
            amphure_id: 1302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1303,
        name_th: "ธัญบุรี",
        name_en: "Thanyaburi",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130301,
            zip_code: 12130,
            name_th: "ประชาธิปัตย์",
            name_en: "Prachathipat",
            amphure_id: 1303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130302,
            zip_code: 12130,
            name_th: "บึงยี่โถ",
            name_en: "Bueng Yitho",
            amphure_id: 1303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130303,
            zip_code: 12110,
            name_th: "รังสิต",
            name_en: "Rangsit",
            amphure_id: 1303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130304,
            zip_code: 12110,
            name_th: "ลำผักกูด",
            name_en: "Lam Phak Kut",
            amphure_id: 1303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130305,
            zip_code: 12110,
            name_th: "บึงสนั่น",
            name_en: "Bueng Sanan",
            amphure_id: 1303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130306,
            zip_code: 12110,
            name_th: "บึงน้ำรักษ์",
            name_en: "Bueng Nam Rak",
            amphure_id: 1303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1304,
        name_th: "หนองเสือ",
        name_en: "Nong Suea",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130401,
            zip_code: 12170,
            name_th: "บึงบา",
            name_en: "Bueng Ba",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130402,
            zip_code: 12170,
            name_th: "บึงบอน",
            name_en: "Bueng Bon",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130403,
            zip_code: 12170,
            name_th: "บึงกาสาม",
            name_en: "Bueng Ka Sam",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130404,
            zip_code: 12170,
            name_th: "บึงชำอ้อ",
            name_en: "Bueng Cham O",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130405,
            zip_code: 12170,
            name_th: "หนองสามวัง",
            name_en: "Nong Sam Wang",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130406,
            zip_code: 12170,
            name_th: "ศาลาครุ",
            name_en: "Sala Khru",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130407,
            zip_code: 12170,
            name_th: "นพรัตน์",
            name_en: "Noppharat",
            amphure_id: 1304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1305,
        name_th: "ลาดหลุมแก้ว",
        name_en: "Lat Lum Kaeo",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130501,
            zip_code: 12140,
            name_th: "ระแหง",
            name_en: "Rahaeng",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130502,
            zip_code: 12140,
            name_th: "ลาดหลุมแก้ว",
            name_en: "Lat Lum Kaeo",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130503,
            zip_code: 12140,
            name_th: "คูบางหลวง",
            name_en: "Khu Bang Luang",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130504,
            zip_code: 12140,
            name_th: "คูขวาง",
            name_en: "Khu Khwang",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130505,
            zip_code: 12140,
            name_th: "คลองพระอุดม",
            name_en: "Khlong Phra Udom",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130506,
            zip_code: 12140,
            name_th: "บ่อเงิน",
            name_en: "Bo Ngoen",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130507,
            zip_code: 12140,
            name_th: "หน้าไม้",
            name_en: "Na Mai",
            amphure_id: 1305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1306,
        name_th: "ลำลูกกา",
        name_en: "Lam Luk Ka",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130601,
            zip_code: 12130,
            name_th: "คูคต",
            name_en: "Khu Khot",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130602,
            zip_code: 12150,
            name_th: "ลาดสวาย",
            name_en: "Lat Sawai",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130603,
            zip_code: 12150,
            name_th: "บึงคำพร้อย",
            name_en: "Bueng Kham Phroi",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130604,
            zip_code: 12150,
            name_th: "ลำลูกกา",
            name_en: "Lam Luk Ka",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130605,
            zip_code: 12150,
            name_th: "บึงทองหลาง",
            name_en: "Bueng Thonglang",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130606,
            zip_code: 12150,
            name_th: "ลำไทร",
            name_en: "Lam Sai",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130607,
            zip_code: 12150,
            name_th: "บึงคอไห",
            name_en: "Bueng Kho Hai",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130608,
            zip_code: 12150,
            name_th: "พืชอุดม",
            name_en: "Phuet Udom",
            amphure_id: 1306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1307,
        name_th: "สามโคก",
        name_en: "Sam Khok",
        province_id: 4,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 130701,
            zip_code: 12160,
            name_th: "บางเตย",
            name_en: "Bang Toei",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130702,
            zip_code: 12160,
            name_th: "คลองควาย",
            name_en: "Khlong Khwai",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130703,
            zip_code: 12160,
            name_th: "สามโคก",
            name_en: "Sam Khok",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130704,
            zip_code: 12160,
            name_th: "กระแชง",
            name_en: "Krachaeng",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130705,
            zip_code: 12160,
            name_th: "บางโพธิ์เหนือ",
            name_en: "Bang Pho Nuea",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130706,
            zip_code: 12160,
            name_th: "เชียงรากใหญ่",
            name_en: "Chiang Rak Yai",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130707,
            zip_code: 12160,
            name_th: "บ้านปทุม",
            name_en: "Ban Pathum",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130708,
            zip_code: 12160,
            name_th: "บ้านงิ้ว",
            name_en: "Ban Ngio",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130709,
            zip_code: 12160,
            name_th: "เชียงรากน้อย",
            name_en: "Chiang Rak Noi",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130710,
            zip_code: 12160,
            name_th: "บางกระบือ",
            name_en: "Bang Krabue",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 130711,
            zip_code: 12160,
            name_th: "ท้ายเกาะ",
            name_en: "Thai Ko",
            amphure_id: 1307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name_th: "พระนครศรีอยุธยา",
    name_en: "Phra Nakhon Si Ayutthaya",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1401,
        name_th: "พระนครศรีอยุธยา",
        name_en: "Phra Nakhon Si Ayutthaya",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140101,
            zip_code: 13000,
            name_th: "ประตูชัย",
            name_en: "Pratu Chai",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140102,
            zip_code: 13000,
            name_th: "กะมัง",
            name_en: "Kamang",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140103,
            zip_code: 13000,
            name_th: "หอรัตนไชย",
            name_en: "Ho Rattanachai",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140104,
            zip_code: 13000,
            name_th: "หัวรอ",
            name_en: "Hua Ro",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140105,
            zip_code: 13000,
            name_th: "ท่าวาสุกรี",
            name_en: "Tha Wasukri",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140106,
            zip_code: 13000,
            name_th: "ไผ่ลิง",
            name_en: "Phai Ling",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140107,
            zip_code: 13000,
            name_th: "ปากกราน",
            name_en: "Pak Kran",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140108,
            zip_code: 13000,
            name_th: "ภูเขาทอง",
            name_en: "Phukhao Thong",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140109,
            zip_code: 13000,
            name_th: "สำเภาล่ม",
            name_en: "Samphao Lom",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140110,
            zip_code: 13000,
            name_th: "สวนพริก",
            name_en: "Suan Phrik",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140111,
            zip_code: 13000,
            name_th: "คลองตะเคียน",
            name_en: "Khlong Takhian",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140112,
            zip_code: 13000,
            name_th: "วัดตูม",
            name_en: "Wat Tum",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140113,
            zip_code: 13000,
            name_th: "หันตรา",
            name_en: "Hantra",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140114,
            zip_code: 13000,
            name_th: "ลุมพลี",
            name_en: "Lumphli",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140115,
            zip_code: 13000,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140116,
            zip_code: 13000,
            name_th: "บ้านเกาะ",
            name_en: "Ban Ko",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140117,
            zip_code: 13000,
            name_th: "คลองสวนพลู",
            name_en: "Khlong Suan Phlu",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140118,
            zip_code: 13000,
            name_th: "คลองสระบัว",
            name_en: "Khlong Sa Bua",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140119,
            zip_code: 13000,
            name_th: "เกาะเรียน",
            name_en: "Ko Rian",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140120,
            zip_code: 13000,
            name_th: "บ้านป้อม",
            name_en: "Ban Pom",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140121,
            zip_code: 13000,
            name_th: "บ้านรุน",
            name_en: "Ban Run",
            amphure_id: 1401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1402,
        name_th: "ท่าเรือ",
        name_en: "Tha Ruea",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140201,
            zip_code: 13130,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140202,
            zip_code: 13130,
            name_th: "จำปา",
            name_en: "Champa",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140203,
            zip_code: 13130,
            name_th: "ท่าหลวง",
            name_en: "Tha Luang",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140204,
            zip_code: 13130,
            name_th: "บ้านร่อม",
            name_en: "Ban Rom",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140205,
            zip_code: 13130,
            name_th: "ศาลาลอย",
            name_en: "Sala Loi",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140206,
            zip_code: 13130,
            name_th: "วังแดง",
            name_en: "Wang Daeng",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140207,
            zip_code: 13130,
            name_th: "โพธิ์เอน",
            name_en: "Pho En",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140208,
            zip_code: 13130,
            name_th: "ปากท่า",
            name_en: "Pak Tha",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140209,
            zip_code: 13130,
            name_th: "หนองขนาก",
            name_en: "Nong Khanak",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140210,
            zip_code: 13130,
            name_th: "ท่าเจ้าสนุก",
            name_en: "Tha Chao Sanuk",
            amphure_id: 1402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1403,
        name_th: "นครหลวง",
        name_en: "Nakhon Luang",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140301,
            zip_code: 13260,
            name_th: "นครหลวง",
            name_en: "Nakhon Luang",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140302,
            zip_code: 13260,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140303,
            zip_code: 13260,
            name_th: "บ่อโพง",
            name_en: "Bo Phong",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140304,
            zip_code: 13260,
            name_th: "บ้านชุ้ง",
            name_en: "Ban Chung",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140305,
            zip_code: 13260,
            name_th: "ปากจั่น",
            name_en: "Pak Chan",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140306,
            zip_code: 13260,
            name_th: "บางระกำ",
            name_en: "Bang Rakam",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140307,
            zip_code: 13260,
            name_th: "บางพระครู",
            name_en: "Bang Phra Khru",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140308,
            zip_code: 13260,
            name_th: "แม่ลา",
            name_en: "Mae La",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140309,
            zip_code: 13260,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140310,
            zip_code: 13260,
            name_th: "คลองสะแก",
            name_en: "Khlong Sakae",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140311,
            zip_code: 13260,
            name_th: "สามไถ",
            name_en: "Sam Thai",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140312,
            zip_code: 13260,
            name_th: "พระนอน",
            name_en: "Phra Non",
            amphure_id: 1403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1404,
        name_th: "บางไทร",
        name_en: "Bang Sai",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140401,
            zip_code: 13190,
            name_th: "บางไทร",
            name_en: "Bang Sai",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140402,
            zip_code: 13190,
            name_th: "บางพลี",
            name_en: "Bang Phli",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140403,
            zip_code: 13190,
            name_th: "สนามชัย",
            name_en: "Sanam Chai",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140404,
            zip_code: 13190,
            name_th: "บ้านแป้ง",
            name_en: "Ban Paeng",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140405,
            zip_code: 13190,
            name_th: "หน้าไม้",
            name_en: "Na Mai",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140406,
            zip_code: 13190,
            name_th: "บางยี่โท",
            name_en: "Bang Yi Tho",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140407,
            zip_code: 13190,
            name_th: "แคออก",
            name_en: "Khae Ok",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140408,
            zip_code: 13190,
            name_th: "แคตก",
            name_en: "Khae Tok",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140409,
            zip_code: 13190,
            name_th: "ช่างเหล็ก",
            name_en: "Chang Lek",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140410,
            zip_code: 13190,
            name_th: "กระแชง",
            name_en: "Krachaeng",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140411,
            zip_code: 13190,
            name_th: "บ้านกลึง",
            name_en: "Ban Klueng",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140412,
            zip_code: 13190,
            name_th: "ช้างน้อย",
            name_en: "Chang Noi",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140413,
            zip_code: 13190,
            name_th: "ห่อหมก",
            name_en: "Homok",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140414,
            zip_code: 13190,
            name_th: "ไผ่พระ",
            name_en: "Phai Phra",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140415,
            zip_code: 13190,
            name_th: "กกแก้วบูรพา",
            name_en: "Kok Kaeo Burapha",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140416,
            zip_code: 13190,
            name_th: "ไม้ตรา",
            name_en: "Mai Tra",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140417,
            zip_code: 13190,
            name_th: "บ้านม้า",
            name_en: "Ban Ma",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140418,
            zip_code: 13190,
            name_th: "บ้านเกาะ",
            name_en: "Ban Ko",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140419,
            zip_code: 13290,
            name_th: "ราชคราม",
            name_en: "Ratchakhram",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140420,
            zip_code: 13290,
            name_th: "ช้างใหญ่",
            name_en: "Chang Yai",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140421,
            zip_code: 13290,
            name_th: "โพแตง",
            name_en: "Pho Taeng",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140422,
            zip_code: 13290,
            name_th: "เชียงรากน้อย",
            name_en: "Chiang Rak Noi",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140423,
            zip_code: 13190,
            name_th: "โคกช้าง",
            name_en: "Khok Chang",
            amphure_id: 1404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1405,
        name_th: "บางบาล",
        name_en: "Bang Ban",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140501,
            zip_code: 13250,
            name_th: "บางบาล",
            name_en: "Bang Ban",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140502,
            zip_code: 13250,
            name_th: "วัดยม",
            name_en: "Wat Yom",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140503,
            zip_code: 13250,
            name_th: "ไทรน้อย",
            name_en: "Sai Noi",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140504,
            zip_code: 13250,
            name_th: "สะพานไทย",
            name_en: "Saphan Thai",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140505,
            zip_code: 13250,
            name_th: "มหาพราหมณ์",
            name_en: "Maha Phram",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140506,
            zip_code: 13250,
            name_th: "กบเจา",
            name_en: "Kop Chao",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140507,
            zip_code: 13250,
            name_th: "บ้านคลัง",
            name_en: "Ban Khlang",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140508,
            zip_code: 13250,
            name_th: "พระขาว",
            name_en: "Phra Khao",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140509,
            zip_code: 13250,
            name_th: "น้ำเต้า",
            name_en: "Namtao",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140510,
            zip_code: 13250,
            name_th: "ทางช้าง",
            name_en: "Thang Chang",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140511,
            zip_code: 13250,
            name_th: "วัดตะกู",
            name_en: "Wat Taku",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140512,
            zip_code: 13250,
            name_th: "บางหลวง",
            name_en: "Bang Luang",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140513,
            zip_code: 13250,
            name_th: "บางหลวงโดด",
            name_en: "Bang Luang Dot",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140514,
            zip_code: 13250,
            name_th: "บางหัก",
            name_en: "Bang Hak",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140515,
            zip_code: 13250,
            name_th: "บางชะนี",
            name_en: "Bang Chani",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140516,
            zip_code: 13250,
            name_th: "บ้านกุ่ม",
            name_en: "Ban Kum",
            amphure_id: 1405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1406,
        name_th: "บางปะอิน",
        name_en: "Bang Pa-in",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140601,
            zip_code: 13160,
            name_th: "บ้านเลน",
            name_en: "Ban Len",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140602,
            zip_code: 13180,
            name_th: "เชียงรากน้อย",
            name_en: "Chiang Rak Noi",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140603,
            zip_code: 13160,
            name_th: "บ้านโพ",
            name_en: "Ban Pho",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140604,
            zip_code: 13160,
            name_th: "บ้านกรด",
            name_en: "Ban Krot",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140605,
            zip_code: 13160,
            name_th: "บางกระสั้น",
            name_en: "Bang Krasan",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140606,
            zip_code: 13160,
            name_th: "คลองจิก",
            name_en: "Khlong Chik",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140607,
            zip_code: 13160,
            name_th: "บ้านหว้า",
            name_en: "Ban Wa",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140608,
            zip_code: 13160,
            name_th: "วัดยม",
            name_en: "Wat Yom",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140609,
            zip_code: 13160,
            name_th: "บางประแดง",
            name_en: "Bang Pradaeng",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140610,
            zip_code: 13160,
            name_th: "สามเรือน",
            name_en: "Sam Ruean",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140611,
            zip_code: 13160,
            name_th: "เกาะเกิด",
            name_en: "Ko Koet",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140612,
            zip_code: 13160,
            name_th: "บ้านพลับ",
            name_en: "Ban Phlap",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140613,
            zip_code: 13160,
            name_th: "บ้านแป้ง",
            name_en: "Ban Paeng",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140614,
            zip_code: 13160,
            name_th: "คุ้งลาน",
            name_en: "Khung Lan",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140615,
            zip_code: 13160,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140616,
            zip_code: 13170,
            name_th: "บ้านสร้าง",
            name_en: "Ban Sang",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140617,
            zip_code: 13160,
            name_th: "ตลาดเกรียบ",
            name_en: "Talat Kriap",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140618,
            zip_code: 13160,
            name_th: "ขนอนหลวง",
            name_en: "Khanon Luang",
            amphure_id: 1406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1407,
        name_th: "บางปะหัน",
        name_en: "Bang Pahan",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140701,
            zip_code: 13220,
            name_th: "บางปะหัน",
            name_en: "Bang Pahan",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140702,
            zip_code: 13220,
            name_th: "ขยาย",
            name_en: "Khayai",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140703,
            zip_code: 13220,
            name_th: "บางเดื่อ",
            name_en: "Bang Duea",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140704,
            zip_code: 13220,
            name_th: "เสาธง",
            name_en: "Sao Thong",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140705,
            zip_code: 13220,
            name_th: "ทางกลาง",
            name_en: "Thang Klang",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140706,
            zip_code: 13220,
            name_th: "บางเพลิง",
            name_en: "Bang Phloeng",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140707,
            zip_code: 13220,
            name_th: "หันสัง",
            name_en: "Hansang",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140708,
            zip_code: 13220,
            name_th: "บางนางร้า",
            name_en: "Bang Nang Ra",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140709,
            zip_code: 13220,
            name_th: "ตานิม",
            name_en: "Ta Nim",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140710,
            zip_code: 13220,
            name_th: "ทับน้ำ",
            name_en: "Thap Nam",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140711,
            zip_code: 13220,
            name_th: "บ้านม้า",
            name_en: "Ban Ma",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140712,
            zip_code: 13220,
            name_th: "ขวัญเมือง",
            name_en: "Khwan Mueang",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140713,
            zip_code: 13220,
            name_th: "บ้านลี่",
            name_en: "Ban Li",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140714,
            zip_code: 13220,
            name_th: "โพธิ์สามต้น",
            name_en: "Pho Sam Ton",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140715,
            zip_code: 13220,
            name_th: "พุทเลา",
            name_en: "Phutlao",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140716,
            zip_code: 13220,
            name_th: "ตาลเอน",
            name_en: "Tan En",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140717,
            zip_code: 13220,
            name_th: "บ้านขล้อ",
            name_en: "Ban Khlo",
            amphure_id: 1407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1408,
        name_th: "ผักไห่",
        name_en: "Phak Hai",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140801,
            zip_code: 13120,
            name_th: "ผักไห่",
            name_en: "Phak Hai",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140802,
            zip_code: 13120,
            name_th: "อมฤต",
            name_en: "Ammarit",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140803,
            zip_code: 13120,
            name_th: "บ้านแค",
            name_en: "Ban Khae",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140804,
            zip_code: 13120,
            name_th: "ลาดน้ำเค็ม",
            name_en: "Lat Nam Khem",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140805,
            zip_code: 13120,
            name_th: "ตาลาน",
            name_en: "Ta Lan",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140806,
            zip_code: 13120,
            name_th: "ท่าดินแดง",
            name_en: "Tha Din Daeng",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140807,
            zip_code: 13280,
            name_th: "ดอนลาน",
            name_en: "Don Lan",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140808,
            zip_code: 13280,
            name_th: "นาคู",
            name_en: "Na Khu",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140809,
            zip_code: 13120,
            name_th: "กุฎี",
            name_en: "Kudi",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140810,
            zip_code: 13280,
            name_th: "ลำตะเคียน",
            name_en: "Lam Takhian",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140811,
            zip_code: 13120,
            name_th: "โคกช้าง",
            name_en: "Khok Chang",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140812,
            zip_code: 13280,
            name_th: "จักราช",
            name_en: "Chakkarat",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140813,
            zip_code: 13280,
            name_th: "หนองน้ำใหญ่",
            name_en: "Nong Nam Yai",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140814,
            zip_code: 13120,
            name_th: "ลาดชิด",
            name_en: "Lat Chit",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140815,
            zip_code: 13120,
            name_th: "หน้าโคก",
            name_en: "Na Khok",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140816,
            zip_code: 13120,
            name_th: "บ้านใหญ่",
            name_en: "Ban Yai",
            amphure_id: 1408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1409,
        name_th: "ภาชี",
        name_en: "Phachi",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 140901,
            zip_code: 13140,
            name_th: "ภาชี",
            name_en: "Phachi",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140902,
            zip_code: 13140,
            name_th: "โคกม่วง",
            name_en: "Khok Muang",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140903,
            zip_code: 13140,
            name_th: "ระโสม",
            name_en: "Rasom",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140904,
            zip_code: 13140,
            name_th: "หนองน้ำใส",
            name_en: "Nong Nam Sai",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140905,
            zip_code: 13140,
            name_th: "ดอนหญ้านาง",
            name_en: "Don Ya Nang",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140906,
            zip_code: 13140,
            name_th: "ไผ่ล้อม",
            name_en: "Phai Lom",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140907,
            zip_code: 13140,
            name_th: "กระจิว",
            name_en: "Krachio",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 140908,
            zip_code: 13140,
            name_th: "พระแก้ว",
            name_en: "Phra Kaeo",
            amphure_id: 1409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1410,
        name_th: "ลาดบัวหลวง",
        name_en: "Lat Bua Luang",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141001,
            zip_code: 13230,
            name_th: "ลาดบัวหลวง",
            name_en: "Lat Bua Luang",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141002,
            zip_code: 13230,
            name_th: "หลักชัย",
            name_en: "Lak Chai",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141003,
            zip_code: 13230,
            name_th: "สามเมือง",
            name_en: "Sam Mueang",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141004,
            zip_code: 13230,
            name_th: "พระยาบันลือ",
            name_en: "Phraya Banlue",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141005,
            zip_code: 13230,
            name_th: "สิงหนาท",
            name_en: "Singhanat",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141006,
            zip_code: 13230,
            name_th: "คู้สลอด",
            name_en: "Khu Salot",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141007,
            zip_code: 13230,
            name_th: "คลองพระยาบันลือ",
            name_en: "Khlong Phraya Banlue",
            amphure_id: 1410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1411,
        name_th: "วังน้อย",
        name_en: "Wang Noi",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141101,
            zip_code: 13170,
            name_th: "ลำตาเสา",
            name_en: "Lam Ta Sao",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141102,
            zip_code: 13170,
            name_th: "บ่อตาโล่",
            name_en: "Bo Ta Lo",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141103,
            zip_code: 13170,
            name_th: "วังน้อย",
            name_en: "Wang Noi",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141104,
            zip_code: 13170,
            name_th: "ลำไทร",
            name_en: "Lam Sai",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141105,
            zip_code: 13170,
            name_th: "สนับทึบ",
            name_en: "Sanap Thuep",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141106,
            zip_code: 13170,
            name_th: "พยอม",
            name_en: "Phayom",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141107,
            zip_code: 13170,
            name_th: "หันตะเภา",
            name_en: "Han Taphao",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141108,
            zip_code: 13170,
            name_th: "วังจุฬา",
            name_en: "Wang Chula",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141109,
            zip_code: 13170,
            name_th: "ข้าวงาม",
            name_en: "Khao Ngam",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141110,
            zip_code: 13170,
            name_th: "ชะแมบ",
            name_en: "Chamaep",
            amphure_id: 1411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1412,
        name_th: "เสนา",
        name_en: "Sena",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141201,
            zip_code: 13110,
            name_th: "เสนา",
            name_en: "Sena",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141202,
            zip_code: 13110,
            name_th: "บ้านแพน",
            name_en: "Ban Phaen",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141203,
            zip_code: 13110,
            name_th: "เจ้าเจ็ด",
            name_en: "Chao Chet",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141204,
            zip_code: 13110,
            name_th: "สามกอ",
            name_en: "Sam Ko",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141205,
            zip_code: 13110,
            name_th: "บางนมโค",
            name_en: "Bang Nom Kho",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141206,
            zip_code: 13110,
            name_th: "หัวเวียง",
            name_en: "Hua Wiang",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141207,
            zip_code: 13110,
            name_th: "มารวิชัย",
            name_en: "Manrawichai",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141208,
            zip_code: 13110,
            name_th: "บ้านโพธิ์",
            name_en: "Ban Pho",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141209,
            zip_code: 13110,
            name_th: "รางจรเข้",
            name_en: "Rang Chorakhe",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141210,
            zip_code: 13110,
            name_th: "บ้านกระทุ่ม",
            name_en: "Ban Krathum",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141211,
            zip_code: 13110,
            name_th: "บ้านแถว",
            name_en: "Ban Thaeo",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141212,
            zip_code: 13110,
            name_th: "ชายนา",
            name_en: "Chai Na",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141213,
            zip_code: 13110,
            name_th: "สามตุ่ม",
            name_en: "Sam Tum",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141214,
            zip_code: 13110,
            name_th: "ลาดงา",
            name_en: "Lat Nga",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141215,
            zip_code: 13110,
            name_th: "ดอนทอง",
            name_en: "Don Thong",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141216,
            zip_code: 13110,
            name_th: "บ้านหลวง",
            name_en: "Ban Luang",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141217,
            zip_code: 13110,
            name_th: "เจ้าเสด็จ",
            name_en: "Chao Sadet",
            amphure_id: 1412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1413,
        name_th: "บางซ้าย",
        name_en: "Bang Sai",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141301,
            zip_code: 13270,
            name_th: "บางซ้าย",
            name_en: "Bang Sai",
            amphure_id: 1413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141302,
            zip_code: 13270,
            name_th: "แก้วฟ้า",
            name_en: "Kaeo Fa",
            amphure_id: 1413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141303,
            zip_code: 13270,
            name_th: "เต่าเล่า",
            name_en: "Tao Lao",
            amphure_id: 1413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141304,
            zip_code: 13270,
            name_th: "ปลายกลัด",
            name_en: "Plai Klat",
            amphure_id: 1413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141305,
            zip_code: 13270,
            name_th: "เทพมงคล",
            name_en: "Thep Mongkhon",
            amphure_id: 1413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141306,
            zip_code: 13270,
            name_th: "วังพัฒนา",
            name_en: "Wang Phatthana",
            amphure_id: 1413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1414,
        name_th: "อุทัย",
        name_en: "Uthai",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141401,
            zip_code: 13210,
            name_th: "คานหาม",
            name_en: "Khan Ham",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141402,
            zip_code: 13210,
            name_th: "บ้านช้าง",
            name_en: "Ban Chang",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141403,
            zip_code: 13210,
            name_th: "สามบัณฑิต",
            name_en: "Sam Bandit",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141404,
            zip_code: 13210,
            name_th: "บ้านหีบ",
            name_en: "Ban Hip",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141405,
            zip_code: 13210,
            name_th: "หนองไม้ซุง",
            name_en: "Nong Mai Sung",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141406,
            zip_code: 13210,
            name_th: "อุทัย",
            name_en: "Uthai",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141407,
            zip_code: 13210,
            name_th: "เสนา",
            name_en: "Sena",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141408,
            zip_code: 13210,
            name_th: "หนองน้ำส้ม",
            name_en: "Nong Nam Som",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141409,
            zip_code: 13210,
            name_th: "โพสาวหาญ",
            name_en: "Pho Sao Han",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141410,
            zip_code: 13210,
            name_th: "ธนู",
            name_en: "Thanu",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141411,
            zip_code: 13210,
            name_th: "ข้าวเม่า",
            name_en: "Khao Mao",
            amphure_id: 1414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1415,
        name_th: "มหาราช",
        name_en: "Maha Rat",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141501,
            zip_code: 13150,
            name_th: "หัวไผ่",
            name_en: "Hua Phai",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141502,
            zip_code: 13150,
            name_th: "กะทุ่ม",
            name_en: "Kathum",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141503,
            zip_code: 13150,
            name_th: "มหาราช",
            name_en: "Maha Rat",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141504,
            zip_code: 13150,
            name_th: "น้ำเต้า",
            name_en: "Namtao",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141505,
            zip_code: 13150,
            name_th: "บางนา",
            name_en: "Bang Na",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141506,
            zip_code: 13150,
            name_th: "โรงช้าง",
            name_en: "Rong Chang",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141507,
            zip_code: 13150,
            name_th: "เจ้าปลุก",
            name_en: "Chao Pluk",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141508,
            zip_code: 13150,
            name_th: "พิตเพียน",
            name_en: "Phitphian",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141509,
            zip_code: 13150,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141510,
            zip_code: 13150,
            name_th: "บ้านขวาง",
            name_en: "Ban Khwang",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141511,
            zip_code: 13150,
            name_th: "ท่าตอ",
            name_en: "Tha To",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141512,
            zip_code: 13150,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 1415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1416,
        name_th: "บ้านแพรก",
        name_en: "Ban Phraek",
        province_id: 5,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 141601,
            zip_code: 13240,
            name_th: "บ้านแพรก",
            name_en: "Ban Phraek",
            amphure_id: 1416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141602,
            zip_code: 13240,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 1416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141603,
            zip_code: 13240,
            name_th: "สำพะเนียง",
            name_en: "Sam Phaniang",
            amphure_id: 1416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141604,
            zip_code: 13240,
            name_th: "คลองน้อย",
            name_en: "Khlong Noi",
            amphure_id: 1416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 141605,
            zip_code: 13240,
            name_th: "สองห้อง",
            name_en: "Song Hong",
            amphure_id: 1416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    name_th: "อ่างทอง",
    name_en: "Ang Thong",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1501,
        name_th: "เมืองอ่างทอง",
        name_en: "Mueang Ang Thong",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150101,
            zip_code: 14000,
            name_th: "ตลาดหลวง",
            name_en: "Talat Luang",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150102,
            zip_code: 14000,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150103,
            zip_code: 14000,
            name_th: "ศาลาแดง",
            name_en: "Sala Daeng",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150104,
            zip_code: 14000,
            name_th: "ป่างิ้ว",
            name_en: "Pa Ngio",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150105,
            zip_code: 14000,
            name_th: "บ้านแห",
            name_en: "Ban Hae",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150106,
            zip_code: 14000,
            name_th: "ตลาดกรวด",
            name_en: "Talat Kruat",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150107,
            zip_code: 14000,
            name_th: "มหาดไทย",
            name_en: "Mahatthai",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150108,
            zip_code: 14000,
            name_th: "บ้านอิฐ",
            name_en: "Ban It",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150109,
            zip_code: 14000,
            name_th: "หัวไผ่",
            name_en: "Hua Phai",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150110,
            zip_code: 14000,
            name_th: "จำปาหล่อ",
            name_en: "Champa Lo",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150111,
            zip_code: 14000,
            name_th: "โพสะ",
            name_en: "Phosa",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150112,
            zip_code: 14000,
            name_th: "บ้านรี",
            name_en: "Ban Ri",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150113,
            zip_code: 14000,
            name_th: "คลองวัว",
            name_en: "Khlong Wua",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150114,
            zip_code: 14000,
            name_th: "ย่านซื่อ",
            name_en: "Yan Sue",
            amphure_id: 1501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1502,
        name_th: "ไชโย",
        name_en: "Chaiyo",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150201,
            zip_code: 14140,
            name_th: "จรเข้ร้อง",
            name_en: "Chorakhe Rong",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150202,
            zip_code: 14140,
            name_th: "ไชยภูมิ",
            name_en: "Chaiyaphum",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150203,
            zip_code: 14140,
            name_th: "ชัยฤทธิ์",
            name_en: "Chaiyarit",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150204,
            zip_code: 14140,
            name_th: "เทวราช",
            name_en: "Thewarat",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150205,
            zip_code: 14140,
            name_th: "ราชสถิตย์",
            name_en: "Ratchasathit",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150206,
            zip_code: 14140,
            name_th: "ไชโย",
            name_en: "Chaiyo",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150207,
            zip_code: 14140,
            name_th: "หลักฟ้า",
            name_en: "Lak Fa",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150208,
            zip_code: 14140,
            name_th: "ชะไว",
            name_en: "Chawai",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150209,
            zip_code: 14140,
            name_th: "ตรีณรงค์",
            name_en: "Tri Narong",
            amphure_id: 1502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1503,
        name_th: "ป่าโมก",
        name_en: "Pa Mok",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150301,
            zip_code: 14130,
            name_th: "บางปลากด",
            name_en: "Bang Pla Kot",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150302,
            zip_code: 14130,
            name_th: "ป่าโมก",
            name_en: "Pa Mok",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150303,
            zip_code: 14130,
            name_th: "สายทอง",
            name_en: "Sai Thong",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150304,
            zip_code: 14130,
            name_th: "โรงช้าง",
            name_en: "Rong Chang",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150305,
            zip_code: 14130,
            name_th: "บางเสด็จ",
            name_en: "Bang Sadet",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150306,
            zip_code: 14130,
            name_th: "นรสิงห์",
            name_en: "Norasing",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150307,
            zip_code: 14130,
            name_th: "เอกราช",
            name_en: "Ekkarat",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150308,
            zip_code: 14130,
            name_th: "โผงเผง",
            name_en: "Phong Pheng",
            amphure_id: 1503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1504,
        name_th: "โพธิ์ทอง",
        name_en: "Pho Thong",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150401,
            zip_code: 14120,
            name_th: "อ่างแก้ว",
            name_en: "Ang Kaeo",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150402,
            zip_code: 14120,
            name_th: "อินทประมูล",
            name_en: "Inthapramun",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150403,
            zip_code: 14120,
            name_th: "บางพลับ",
            name_en: "Bang Phlap",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150404,
            zip_code: 14120,
            name_th: "หนองแม่ไก่",
            name_en: "Nong Mae Kai",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150405,
            zip_code: 14120,
            name_th: "รำมะสัก",
            name_en: "Ram Ma Sak",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150406,
            zip_code: 14120,
            name_th: "บางระกำ",
            name_en: "Bang Rakam",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150407,
            zip_code: 14120,
            name_th: "โพธิ์รังนก",
            name_en: "Pho Rang Nok",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150408,
            zip_code: 14120,
            name_th: "องครักษ์",
            name_en: "Ongkharak",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150409,
            zip_code: 14120,
            name_th: "โคกพุทรา",
            name_en: "Khok Phutsa",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150410,
            zip_code: 14120,
            name_th: "ยางช้าย",
            name_en: "Yang Chai",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150411,
            zip_code: 14120,
            name_th: "บ่อแร่",
            name_en: "Bo Rae",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150412,
            zip_code: 14120,
            name_th: "ทางพระ",
            name_en: "Thang Phra",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150413,
            zip_code: 14120,
            name_th: "สามง่าม",
            name_en: "Sam Ngam",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150414,
            zip_code: 14120,
            name_th: "บางเจ้าฉ่า",
            name_en: "Bang Chao Cha",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150415,
            zip_code: 14120,
            name_th: "คำหยาด",
            name_en: "Kham Yat",
            amphure_id: 1504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1505,
        name_th: "แสวงหา",
        name_en: "Sawaeng Ha",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150501,
            zip_code: 14150,
            name_th: "แสวงหา",
            name_en: "Sawaeng Ha",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150502,
            zip_code: 14150,
            name_th: "ศรีพราน",
            name_en: "Si Phran",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150503,
            zip_code: 14150,
            name_th: "บ้านพราน",
            name_en: "Ban Phran",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150504,
            zip_code: 14150,
            name_th: "วังน้ำเย็น",
            name_en: "Wang Nam Yen",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150505,
            zip_code: 14150,
            name_th: "สีบัวทอง",
            name_en: "Si Bua Thong",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150506,
            zip_code: 14150,
            name_th: "ห้วยไผ่",
            name_en: "Huai Phai",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150507,
            zip_code: 14150,
            name_th: "จำลอง",
            name_en: "Chamlong",
            amphure_id: 1505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1506,
        name_th: "วิเศษชัยชาญ",
        name_en: "Wiset Chai Chan",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150601,
            zip_code: 14110,
            name_th: "ไผ่จำศิล",
            name_en: "Phai Cham Sin",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150602,
            zip_code: 14110,
            name_th: "ศาลเจ้าโรงทอง",
            name_en: "San Chao Rong Thong",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150603,
            zip_code: 14110,
            name_th: "ไผ่ดำพัฒนา",
            name_en: "Phai Dam Phatthana",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150604,
            zip_code: 14110,
            name_th: "สาวร้องไห้",
            name_en: "Sao Rong Hai",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150605,
            zip_code: 14110,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150606,
            zip_code: 14110,
            name_th: "ยี่ล้น",
            name_en: "Yi Lon",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150607,
            zip_code: 14110,
            name_th: "บางจัก",
            name_en: "Bang Chak",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150608,
            zip_code: 14110,
            name_th: "ห้วยคันแหลน",
            name_en: "Huai Khan Laen",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150609,
            zip_code: 14110,
            name_th: "คลองขนาก",
            name_en: "Khlong Khanak",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150610,
            zip_code: 14110,
            name_th: "ไผ่วง",
            name_en: "Phai Wong",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150611,
            zip_code: 14110,
            name_th: "สี่ร้อย",
            name_en: "Si Roi",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150612,
            zip_code: 14110,
            name_th: "ม่วงเตี้ย",
            name_en: "Muang Tia",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150613,
            zip_code: 14110,
            name_th: "หัวตะพาน",
            name_en: "Hua Taphan",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150614,
            zip_code: 14110,
            name_th: "หลักแก้ว",
            name_en: "Lak Kaeo",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150615,
            zip_code: 14110,
            name_th: "ตลาดใหม่",
            name_en: "Talat Mai",
            amphure_id: 1506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1507,
        name_th: "สามโก้",
        name_en: "Samko",
        province_id: 6,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 150701,
            zip_code: 14160,
            name_th: "สามโก้",
            name_en: "Samko",
            amphure_id: 1507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150702,
            zip_code: 14160,
            name_th: "ราษฎรพัฒนา",
            name_en: "Ratsadon Phatthana",
            amphure_id: 1507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150703,
            zip_code: 14160,
            name_th: "อบทม",
            name_en: "Op Thom",
            amphure_id: 1507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150704,
            zip_code: 14160,
            name_th: "โพธิ์ม่วงพันธ์",
            name_en: "Pho Muang Phan",
            amphure_id: 1507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 150705,
            zip_code: 14160,
            name_th: "มงคลธรรมนิมิต",
            name_en: "Mongkhon Tham Nimit",
            amphure_id: 1507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name_th: "ลพบุรี",
    name_en: "Loburi",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1601,
        name_th: "เมืองลพบุรี",
        name_en: "Mueang Lop Buri",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160101,
            zip_code: 15000,
            name_th: "ทะเลชุบศร",
            name_en: "Thale Chup Son",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160102,
            zip_code: 15000,
            name_th: "ท่าหิน",
            name_en: "Tha Hin",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160103,
            zip_code: 15000,
            name_th: "กกโก",
            name_en: "Kok Ko",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160104,
            zip_code: 13240,
            name_th: "โก่งธนู",
            name_en: "Kong Thanu",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160105,
            zip_code: 15000,
            name_th: "เขาพระงาม",
            name_en: "Khao Phra Ngam",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160106,
            zip_code: 15000,
            name_th: "เขาสามยอด",
            name_en: "Khao Sam Yot",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160107,
            zip_code: 15000,
            name_th: "โคกกะเทียม",
            name_en: "Khok Kathiam",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160108,
            zip_code: 15000,
            name_th: "โคกลำพาน",
            name_en: "Khok Lam Phan",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160109,
            zip_code: 15210,
            name_th: "โคกตูม",
            name_en: "Khok Tum",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160110,
            zip_code: 15000,
            name_th: "งิ้วราย",
            name_en: "Ngio Rai",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160111,
            zip_code: 15000,
            name_th: "ดอนโพธิ์",
            name_en: "Don Pho",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160112,
            zip_code: 15000,
            name_th: "ตะลุง",
            name_en: "Talung",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160114,
            zip_code: 15000,
            name_th: "ท่าแค",
            name_en: "Tha Khae",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160115,
            zip_code: 15000,
            name_th: "ท่าศาลา",
            name_en: "Tha Sala",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160116,
            zip_code: 15000,
            name_th: "นิคมสร้างตนเอง",
            name_en: "Nikhom Sang Ton-eng",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160117,
            zip_code: 15000,
            name_th: "บางขันหมาก",
            name_en: "Bang Khan Mak",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160118,
            zip_code: 15000,
            name_th: "บ้านข่อย",
            name_en: "Ban Khoi",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160119,
            zip_code: 15000,
            name_th: "ท้ายตลาด",
            name_en: "Thai Talat",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160120,
            zip_code: 15000,
            name_th: "ป่าตาล",
            name_en: "Pa Tan",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160121,
            zip_code: 15000,
            name_th: "พรหมมาสตร์",
            name_en: "Phrommat",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160122,
            zip_code: 15000,
            name_th: "โพธิ์เก้าต้น",
            name_en: "Pho Kao Ton",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160123,
            zip_code: 15000,
            name_th: "โพธิ์ตรุ",
            name_en: "Pho Tru",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160124,
            zip_code: 15000,
            name_th: "สี่คลอง",
            name_en: "Si Khlong",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160125,
            zip_code: 15000,
            name_th: "ถนนใหญ่",
            name_en: "Thanon Yai",
            amphure_id: 1601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1602,
        name_th: "พัฒนานิคม",
        name_en: "Phatthana Nikhom",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160201,
            zip_code: 15140,
            name_th: "พัฒนานิคม",
            name_en: "Phatthana Nikhom",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160202,
            zip_code: 15220,
            name_th: "ช่องสาริกา",
            name_en: "Chong Sarika",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160203,
            zip_code: 15140,
            name_th: "มะนาวหวาน",
            name_en: "Manao Wan",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160204,
            zip_code: 15220,
            name_th: "ดีลัง",
            name_en: "Di Lang",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160205,
            zip_code: 15140,
            name_th: "โคกสลุง",
            name_en: "Khok Salung",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160206,
            zip_code: 15140,
            name_th: "ชอนน้อย",
            name_en: "Chon Noi",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160207,
            zip_code: 15140,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160208,
            zip_code: 18220,
            name_th: "ห้วยขุนราม",
            name_en: "Huai Khun Ram",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160209,
            zip_code: 15140,
            name_th: "น้ำสุด",
            name_en: "Nam Sut",
            amphure_id: 1602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1603,
        name_th: "โคกสำโรง",
        name_en: "Khok Samrong",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160301,
            zip_code: 15120,
            name_th: "โคกสำโรง",
            name_en: "Khok Samrong",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160302,
            zip_code: 15120,
            name_th: "เกาะแก้ว",
            name_en: "Ko Kaeo",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160303,
            zip_code: 15120,
            name_th: "ถลุงเหล็ก",
            name_en: "Thalung Lek",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160304,
            zip_code: 15120,
            name_th: "หลุมข้าว",
            name_en: "Lum Khao",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160305,
            zip_code: 15120,
            name_th: "ห้วยโป่ง",
            name_en: "Huai Pong",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160306,
            zip_code: 15120,
            name_th: "คลองเกตุ",
            name_en: "Khlong Ket",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160307,
            zip_code: 15120,
            name_th: "สะแกราบ",
            name_en: "Sakae Rap",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160308,
            zip_code: 15120,
            name_th: "เพนียด",
            name_en: "Phaniat",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160309,
            zip_code: 15120,
            name_th: "วังเพลิง",
            name_en: "Wang Phloeng",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160310,
            zip_code: 15120,
            name_th: "ดงมะรุม",
            name_en: "Dong Marum",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160318,
            zip_code: 15120,
            name_th: "วังขอนขว้าง",
            name_en: "Wang Khon Khwang",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160320,
            zip_code: 15120,
            name_th: "วังจั่น",
            name_en: "Wang Chan",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160322,
            zip_code: 15120,
            name_th: "หนองแขม",
            name_en: "Nong Khaem",
            amphure_id: 1603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1604,
        name_th: "ชัยบาดาล",
        name_en: "Chai Badan",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160401,
            zip_code: 15130,
            name_th: "ลำนารายณ์",
            name_en: "Lam Narai",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160402,
            zip_code: 15130,
            name_th: "ชัยนารายณ์",
            name_en: "Chai Narai",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160403,
            zip_code: 15130,
            name_th: "ศิลาทิพย์",
            name_en: "Sila Thip",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160404,
            zip_code: 15130,
            name_th: "ห้วยหิน",
            name_en: "Huai Hin",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160405,
            zip_code: 15230,
            name_th: "ม่วงค่อม",
            name_en: "Muang Khom",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160406,
            zip_code: 15130,
            name_th: "บัวชุม",
            name_en: "Bua Chum",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160407,
            zip_code: 15130,
            name_th: "ท่าดินดำ",
            name_en: "Tha Din Dam",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160408,
            zip_code: 15230,
            name_th: "มะกอกหวาน",
            name_en: "Makok Wan",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160409,
            zip_code: 15130,
            name_th: "ซับตะเคียน",
            name_en: "Sap Takhian",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160410,
            zip_code: 15190,
            name_th: "นาโสม",
            name_en: "Na Som",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160411,
            zip_code: 15130,
            name_th: "หนองยายโต๊ะ",
            name_en: "Nong Yai To",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160412,
            zip_code: 15130,
            name_th: "เกาะรัง",
            name_en: "Ko Rang",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160414,
            zip_code: 15130,
            name_th: "ท่ามะนาว",
            name_en: "Tha Manao",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160417,
            zip_code: 15130,
            name_th: "นิคมลำนารายณ์",
            name_en: "Nikhom Lam Narai",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160418,
            zip_code: 15230,
            name_th: "ชัยบาดาล",
            name_en: "Chai Badan",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160419,
            zip_code: 15130,
            name_th: "บ้านใหม่สามัคคี",
            name_en: "Ban Mai Samakkhi",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160422,
            zip_code: 15130,
            name_th: "เขาแหลม",
            name_en: "Khao Laem",
            amphure_id: 1604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1605,
        name_th: "ท่าวุ้ง",
        name_en: "Tha Wung",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160501,
            zip_code: 15150,
            name_th: "ท่าวุ้ง",
            name_en: "Tha Wung",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160502,
            zip_code: 15150,
            name_th: "บางคู้",
            name_en: "Bang Khu",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160503,
            zip_code: 15150,
            name_th: "โพตลาดแก้ว",
            name_en: "Pho Talat Kaeo",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160504,
            zip_code: 15150,
            name_th: "บางลี่",
            name_en: "Bang Li",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160505,
            zip_code: 15150,
            name_th: "บางงา",
            name_en: "Bang Nga",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160506,
            zip_code: 15150,
            name_th: "โคกสลุด",
            name_en: "Khok Salut",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160507,
            zip_code: 15180,
            name_th: "เขาสมอคอน",
            name_en: "Khao Samo Khon",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160508,
            zip_code: 15150,
            name_th: "หัวสำโรง",
            name_en: "Hua Samrong",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160509,
            zip_code: 15150,
            name_th: "ลาดสาลี่",
            name_en: "Lat Sali",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160510,
            zip_code: 15150,
            name_th: "บ้านเบิก",
            name_en: "Ban Boek",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160511,
            zip_code: 15150,
            name_th: "มุจลินท์",
            name_en: "Mutchalin",
            amphure_id: 1605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1606,
        name_th: "บ้านหมี่",
        name_en: "Ban Mi",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160601,
            zip_code: 15110,
            name_th: "ไผ่ใหญ่",
            name_en: "Phai Yai",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160602,
            zip_code: 15110,
            name_th: "บ้านทราย",
            name_en: "Ban Sai",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160603,
            zip_code: 15110,
            name_th: "บ้านกล้วย",
            name_en: "Ban Kluai",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160604,
            zip_code: 15110,
            name_th: "ดงพลับ",
            name_en: "Dong Phlap",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160605,
            zip_code: 15180,
            name_th: "บ้านชี",
            name_en: "Ban Chi",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160606,
            zip_code: 15110,
            name_th: "พุคา",
            name_en: "Phu Kha",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160607,
            zip_code: 15110,
            name_th: "หินปัก",
            name_en: "Hin Pak",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160608,
            zip_code: 15110,
            name_th: "บางพึ่ง",
            name_en: "Bang Phueng",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160609,
            zip_code: 15110,
            name_th: "หนองทรายขาว",
            name_en: "Nong Sai Khao",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160610,
            zip_code: 15110,
            name_th: "บางกะพี้",
            name_en: "Bang Kaphi",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160611,
            zip_code: 15110,
            name_th: "หนองเต่า",
            name_en: "Nong Tao",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160612,
            zip_code: 15110,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160613,
            zip_code: 15180,
            name_th: "บางขาม",
            name_en: "Bang Kham",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160614,
            zip_code: 15110,
            name_th: "ดอนดึง",
            name_en: "Don Dueng",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160615,
            zip_code: 15110,
            name_th: "ชอนม่วง",
            name_en: "Chon Muang",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160616,
            zip_code: 15110,
            name_th: "หนองกระเบียน",
            name_en: "Nong Krabian",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160617,
            zip_code: 15110,
            name_th: "สายห้วยแก้ว",
            name_en: "Sai Huai Kaeo",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160618,
            zip_code: 15110,
            name_th: "มหาสอน",
            name_en: "Maha Son",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160619,
            zip_code: 15110,
            name_th: "บ้านหมี่",
            name_en: "Ban Mi",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160620,
            zip_code: 15110,
            name_th: "เชียงงา",
            name_en: "Chiang Nga",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160621,
            zip_code: 15110,
            name_th: "หนองเมือง",
            name_en: "Nong Mueang",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160622,
            zip_code: 15110,
            name_th: "สนามแจง",
            name_en: "Sanam Chaeng",
            amphure_id: 1606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1607,
        name_th: "ท่าหลวง",
        name_en: "Tha Luang",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160701,
            zip_code: 15230,
            name_th: "ท่าหลวง",
            name_en: "Tha Luang",
            amphure_id: 1607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160702,
            zip_code: 15230,
            name_th: "แก่งผักกูด",
            name_en: "Kaeng Phak Kut",
            amphure_id: 1607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160703,
            zip_code: 15230,
            name_th: "ซับจำปา",
            name_en: "Sap Champa",
            amphure_id: 1607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160704,
            zip_code: 15230,
            name_th: "หนองผักแว่น",
            name_en: "Nong Phak Waen",
            amphure_id: 1607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160705,
            zip_code: 15230,
            name_th: "ทะเลวังวัด",
            name_en: "Thale Wang Wat",
            amphure_id: 1607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160706,
            zip_code: 15230,
            name_th: "หัวลำ",
            name_en: "Hua Lam",
            amphure_id: 1607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1608,
        name_th: "สระโบสถ์",
        name_en: "Sa Bot",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160801,
            zip_code: 15240,
            name_th: "สระโบสถ์",
            name_en: "Sa Bot",
            amphure_id: 1608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160802,
            zip_code: 15240,
            name_th: "มหาโพธิ",
            name_en: "Maha Phot",
            amphure_id: 1608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160803,
            zip_code: 15240,
            name_th: "ทุ่งท่าช้าง",
            name_en: "Thung Tha Chang",
            amphure_id: 1608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160804,
            zip_code: 15240,
            name_th: "ห้วยใหญ่",
            name_en: "Huai Yai",
            amphure_id: 1608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160805,
            zip_code: 15240,
            name_th: "นิยมชัย",
            name_en: "Niyom Chai",
            amphure_id: 1608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1609,
        name_th: "โคกเจริญ",
        name_en: "Khok Charoen",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 160901,
            zip_code: 15250,
            name_th: "โคกเจริญ",
            name_en: "Khok Charoen",
            amphure_id: 1609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160902,
            zip_code: 15250,
            name_th: "ยางราก",
            name_en: "Yang Rak",
            amphure_id: 1609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160903,
            zip_code: 15250,
            name_th: "หนองมะค่า",
            name_en: "Nong Makha",
            amphure_id: 1609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160904,
            zip_code: 15250,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 1609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 160905,
            zip_code: 15250,
            name_th: "โคกแสมสาร",
            name_en: "Khok Samae San",
            amphure_id: 1609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1610,
        name_th: "ลำสนธิ",
        name_en: "Lam Sonthi",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 161001,
            zip_code: 15190,
            name_th: "ลำสนธิ",
            name_en: "Lam Sonthi",
            amphure_id: 1610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161002,
            zip_code: 15190,
            name_th: "ซับสมบูรณ์",
            name_en: "Sap Sombun",
            amphure_id: 1610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161003,
            zip_code: 15190,
            name_th: "หนองรี",
            name_en: "Nong Ri",
            amphure_id: 1610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161004,
            zip_code: 15190,
            name_th: "กุดตาเพชร",
            name_en: "Kut Ta Phet",
            amphure_id: 1610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161005,
            zip_code: 15190,
            name_th: "เขารวก",
            name_en: "Khao Ruak",
            amphure_id: 1610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161006,
            zip_code: 15130,
            name_th: "เขาน้อย",
            name_en: "Khao Noi",
            amphure_id: 1610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1611,
        name_th: "หนองม่วง",
        name_en: "Nong Muang",
        province_id: 7,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 161101,
            zip_code: 15170,
            name_th: "หนองม่วง",
            name_en: "Nong Muang",
            amphure_id: 1611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161102,
            zip_code: 15170,
            name_th: "บ่อทอง",
            name_en: "Bo Thong",
            amphure_id: 1611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161103,
            zip_code: 15170,
            name_th: "ดงดินแดง",
            name_en: "Dong Din Daeng",
            amphure_id: 1611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161104,
            zip_code: 15170,
            name_th: "ชอนสมบูรณ์",
            name_en: "Chon Sombun",
            amphure_id: 1611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161105,
            zip_code: 15170,
            name_th: "ยางโทน",
            name_en: "Yang Thon",
            amphure_id: 1611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 161106,
            zip_code: 15170,
            name_th: "ชอนสารเดช",
            name_en: "Chon Saradet",
            amphure_id: 1611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name_th: "สิงห์บุรี",
    name_en: "Sing Buri",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1701,
        name_th: "เมืองสิงห์บุรี",
        name_en: "Mueang Sing Buri",
        province_id: 8,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 170101,
            zip_code: 16000,
            name_th: "บางพุทรา",
            name_en: "Bang Phutsa",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170102,
            zip_code: 16000,
            name_th: "บางมัญ",
            name_en: "Bang Man",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170103,
            zip_code: 16000,
            name_th: "โพกรวม",
            name_en: "Phok Ruam",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170104,
            zip_code: 16000,
            name_th: "ม่วงหมู่",
            name_en: "Muang Mu",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170105,
            zip_code: 16000,
            name_th: "หัวไผ่",
            name_en: "Hua Phai",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170106,
            zip_code: 16000,
            name_th: "ต้นโพธิ์",
            name_en: "Ton Pho",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170107,
            zip_code: 16000,
            name_th: "จักรสีห์",
            name_en: "Chaksi",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170108,
            zip_code: 16000,
            name_th: "บางกระบือ",
            name_en: "Bang Krabue",
            amphure_id: 1701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1702,
        name_th: "บางระจัน",
        name_en: "Bang Rachan",
        province_id: 8,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 170201,
            zip_code: 16130,
            name_th: "สิงห์",
            name_en: "Sing",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170202,
            zip_code: 16130,
            name_th: "ไม้ดัด",
            name_en: "Mai Dat",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170203,
            zip_code: 16130,
            name_th: "เชิงกลัด",
            name_en: "Choeng Klat",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170204,
            zip_code: 16130,
            name_th: "โพชนไก่",
            name_en: "Pho Chon Kai",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170205,
            zip_code: 16130,
            name_th: "แม่ลา",
            name_en: "Mae La",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170206,
            zip_code: 16130,
            name_th: "บ้านจ่า",
            name_en: "Ban Cha",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170207,
            zip_code: 16130,
            name_th: "พักทัน",
            name_en: "Phak Than",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170208,
            zip_code: 16130,
            name_th: "สระแจง",
            name_en: "Sa Chaeng",
            amphure_id: 1702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1703,
        name_th: "ค่ายบางระจัน",
        name_en: "Khai Bang Rachan",
        province_id: 8,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 170301,
            zip_code: 16150,
            name_th: "โพทะเล",
            name_en: "Pho Thale",
            amphure_id: 1703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170302,
            zip_code: 16150,
            name_th: "บางระจัน",
            name_en: "Bang Rachan",
            amphure_id: 1703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170303,
            zip_code: 16150,
            name_th: "โพสังโฆ",
            name_en: "Pho Sangkho",
            amphure_id: 1703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170304,
            zip_code: 16150,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 1703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170305,
            zip_code: 16150,
            name_th: "คอทราย",
            name_en: "Kho Sai",
            amphure_id: 1703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170306,
            zip_code: 16150,
            name_th: "หนองกระทุ่ม",
            name_en: "Nong Krathum",
            amphure_id: 1703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1704,
        name_th: "พรหมบุรี",
        name_en: "Phrom Buri",
        province_id: 8,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 170401,
            zip_code: 16120,
            name_th: "พระงาม",
            name_en: "Phra Ngam",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170402,
            zip_code: 16160,
            name_th: "พรหมบุรี",
            name_en: "Phrom Buri",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170403,
            zip_code: 16120,
            name_th: "บางน้ำเชี่ยว",
            name_en: "Bang Nam Chiao",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170404,
            zip_code: 16120,
            name_th: "บ้านหม้อ",
            name_en: "Ban Mo",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170405,
            zip_code: 16120,
            name_th: "บ้านแป้ง",
            name_en: "Ban Paeng",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170406,
            zip_code: 16120,
            name_th: "หัวป่า",
            name_en: "Hua Pa",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170407,
            zip_code: 16120,
            name_th: "โรงช้าง",
            name_en: "Rong Chang",
            amphure_id: 1704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1705,
        name_th: "ท่าช้าง",
        name_en: "Tha Chang",
        province_id: 8,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 170501,
            zip_code: 16140,
            name_th: "ถอนสมอ",
            name_en: "Thon Samo",
            amphure_id: 1705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170502,
            zip_code: 16140,
            name_th: "โพประจักษ์",
            name_en: "Pho Prachak",
            amphure_id: 1705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170503,
            zip_code: 16140,
            name_th: "วิหารขาว",
            name_en: "Wihan Khao",
            amphure_id: 1705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170504,
            zip_code: 16140,
            name_th: "พิกุลทอง",
            name_en: "Phikun Thong",
            amphure_id: 1705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1706,
        name_th: "อินทร์บุรี",
        name_en: "In Buri",
        province_id: 8,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 170601,
            zip_code: 16110,
            name_th: "อินทร์บุรี",
            name_en: "In Buri",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170602,
            zip_code: 16110,
            name_th: "ประศุก",
            name_en: "Prasuk",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170603,
            zip_code: 16110,
            name_th: "ทับยา",
            name_en: "Thap Ya",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170604,
            zip_code: 16110,
            name_th: "งิ้วราย",
            name_en: "Ngio Rai",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170605,
            zip_code: 16110,
            name_th: "ชีน้ำร้าย",
            name_en: "Chi Nam Rai",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170606,
            zip_code: 16110,
            name_th: "ท่างาม",
            name_en: "Tha Ngam",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170607,
            zip_code: 16110,
            name_th: "น้ำตาล",
            name_en: "Namtan",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170608,
            zip_code: 16110,
            name_th: "ทองเอน",
            name_en: "Thong En",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170609,
            zip_code: 16110,
            name_th: "ห้วยชัน",
            name_en: "Huai Chan",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 170610,
            zip_code: 16110,
            name_th: "โพธิ์ชัย",
            name_en: "Pho Chai",
            amphure_id: 1706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 9,
    name_th: "ชัยนาท",
    name_en: "Chai Nat",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1801,
        name_th: "เมืองชัยนาท",
        name_en: "Mueang Chai Nat",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180101,
            zip_code: 17000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180102,
            zip_code: 17000,
            name_th: "บ้านกล้วย",
            name_en: "Ban Kluai",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180103,
            zip_code: 17000,
            name_th: "ท่าชัย",
            name_en: "Tha Chai",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180104,
            zip_code: 17000,
            name_th: "ชัยนาท",
            name_en: "Chai Nat",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180105,
            zip_code: 17000,
            name_th: "เขาท่าพระ",
            name_en: "Khao Tha Phra",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180106,
            zip_code: 17000,
            name_th: "หาดท่าเสา",
            name_en: "Hat Tha Sao",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180107,
            zip_code: 17000,
            name_th: "ธรรมามูล",
            name_en: "Thammamun",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180108,
            zip_code: 17000,
            name_th: "เสือโฮก",
            name_en: "Suea Hok",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180109,
            zip_code: 17000,
            name_th: "นางลือ",
            name_en: "Nang Lue",
            amphure_id: 1801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1802,
        name_th: "มโนรมย์",
        name_en: "Manorom",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180201,
            zip_code: 17110,
            name_th: "คุ้งสำเภา",
            name_en: "Khung Samphao",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180202,
            zip_code: 17110,
            name_th: "วัดโคก",
            name_en: "Wat Khok",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180203,
            zip_code: 17110,
            name_th: "ศิลาดาน",
            name_en: "Sila Dan",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180204,
            zip_code: 17110,
            name_th: "ท่าฉนวน",
            name_en: "Tha Chanuan",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180205,
            zip_code: 17170,
            name_th: "หางน้ำสาคร",
            name_en: "Hang Nam Sakhon",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180206,
            zip_code: 17170,
            name_th: "ไร่พัฒนา",
            name_en: "Rai Phatthana",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180207,
            zip_code: 17170,
            name_th: "อู่ตะเภา",
            name_en: "U Taphao",
            amphure_id: 1802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1803,
        name_th: "วัดสิงห์",
        name_en: "Wat Sing",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180301,
            zip_code: 17120,
            name_th: "วัดสิงห์",
            name_en: "Wat Sing",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180302,
            zip_code: 17120,
            name_th: "มะขามเฒ่า",
            name_en: "Makham Thao",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180303,
            zip_code: 17120,
            name_th: "หนองน้อย",
            name_en: "Nong Noi",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180304,
            zip_code: 17120,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180306,
            zip_code: 17120,
            name_th: "หนองขุ่น",
            name_en: "Bo Rae",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180307,
            zip_code: 17120,
            name_th: "บ่อแร่",
            name_en: "Wang Man",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180311,
            zip_code: 17120,
            name_th: "วังหมัน",
            name_en: "Wang Man",
            amphure_id: 1803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1804,
        name_th: "สรรพยา",
        name_en: "Sapphaya",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180401,
            zip_code: 17150,
            name_th: "สรรพยา",
            name_en: "Sapphaya",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180402,
            zip_code: 17150,
            name_th: "ตลุก",
            name_en: "Taluk",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180403,
            zip_code: 17150,
            name_th: "เขาแก้ว",
            name_en: "Khao Kaeo",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180404,
            zip_code: 17150,
            name_th: "โพนางดำตก",
            name_en: "Pho Nang Dam Tok",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180405,
            zip_code: 17150,
            name_th: "โพนางดำออก",
            name_en: "Pho Nang Dam Ok",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180406,
            zip_code: 17150,
            name_th: "บางหลวง",
            name_en: "Bang Luang",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180407,
            zip_code: 17150,
            name_th: "หาดอาษา",
            name_en: "Hat Asa",
            amphure_id: 1804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1805,
        name_th: "สรรคบุรี",
        name_en: "Sankhaburi",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180501,
            zip_code: 17140,
            name_th: "แพรกศรีราชา",
            name_en: "Phraek Si Racha",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180502,
            zip_code: 17140,
            name_th: "เที่ยงแท้",
            name_en: "Thiang Thae",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180503,
            zip_code: 17140,
            name_th: "ห้วยกรด",
            name_en: "Huai Krot",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180504,
            zip_code: 17140,
            name_th: "โพงาม",
            name_en: "Pho Ngam",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180505,
            zip_code: 17140,
            name_th: "บางขุด",
            name_en: "Bang Khut",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180506,
            zip_code: 17140,
            name_th: "ดงคอน",
            name_en: "Dong Khon",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180507,
            zip_code: 17140,
            name_th: "ดอนกำ",
            name_en: "Don Kam",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180508,
            zip_code: 17140,
            name_th: "ห้วยกรดพัฒนา",
            name_en: "Huai Krot Phatthana",
            amphure_id: 1805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1806,
        name_th: "หันคา",
        name_en: "Hankha",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180601,
            zip_code: 17130,
            name_th: "หันคา",
            name_en: "Hankha",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180602,
            zip_code: 17130,
            name_th: "บ้านเชี่ยน",
            name_en: "Ban Chian",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180605,
            zip_code: 17130,
            name_th: "ไพรนกยูง",
            name_en: "Phrai Nok Yung",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180606,
            zip_code: 17160,
            name_th: "หนองแซง",
            name_en: "Nong Saeng",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180607,
            zip_code: 17160,
            name_th: "ห้วยงู",
            name_en: "Huai Ngu",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180608,
            zip_code: 17130,
            name_th: "วังไก่เถื่อน",
            name_en: "Wang Kai Thuean",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180609,
            zip_code: 17130,
            name_th: "เด่นใหญ่",
            name_en: "Den Yai",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180611,
            zip_code: 17160,
            name_th: "สามง่ามท่าโบสถ์",
            name_en: "Sam Ngam Tha Bot",
            amphure_id: 1806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1807,
        name_th: "หนองมะโมง",
        name_en: "Nong Mamong",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180701,
            zip_code: 17120,
            name_th: "หนองมะโมง",
            name_en: "Nong Mamong",
            amphure_id: 1807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180702,
            zip_code: 17120,
            name_th: "วังตะเคียน",
            name_en: "Wang Takhian",
            amphure_id: 1807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180703,
            zip_code: 17120,
            name_th: "สะพานหิน",
            name_en: "Saphan Hin",
            amphure_id: 1807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180704,
            zip_code: 17120,
            name_th: "กุดจอก",
            name_en: "Kut Chok",
            amphure_id: 1807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1808,
        name_th: "เนินขาม",
        name_en: "Noen Kham",
        province_id: 9,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 180801,
            zip_code: 17130,
            name_th: "เนินขาม",
            name_en: "Noen Kham",
            amphure_id: 1808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180802,
            zip_code: 17130,
            name_th: "กะบกเตี้ย",
            name_en: "Kabok Tia",
            amphure_id: 1808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 180803,
            zip_code: 17130,
            name_th: "สุขเดือนห้า",
            name_en: "Suk Duean Ha",
            amphure_id: 1808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 10,
    name_th: "สระบุรี",
    name_en: "Saraburi",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 1901,
        name_th: "เมืองสระบุรี",
        name_en: "Mueang Saraburi",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190101,
            zip_code: 18000,
            name_th: "ปากเพรียว",
            name_en: "Pak Phriao",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190105,
            zip_code: 18000,
            name_th: "ดาวเรือง",
            name_en: "Dao Rueang",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190106,
            zip_code: 18000,
            name_th: "นาโฉง",
            name_en: "Na Chong",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190107,
            zip_code: 18000,
            name_th: "โคกสว่าง",
            name_en: "Khok Sawang",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190108,
            zip_code: 18000,
            name_th: "หนองโน",
            name_en: "Nong No",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190109,
            zip_code: 18000,
            name_th: "หนองยาว",
            name_en: "Nong Yao",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190110,
            zip_code: 18000,
            name_th: "ปากข้าวสาร",
            name_en: "Pak Khao San",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190111,
            zip_code: 18000,
            name_th: "หนองปลาไหล",
            name_en: "Nong Pla Lai",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190112,
            zip_code: 18000,
            name_th: "กุดนกเปล้า",
            name_en: "Kut Nok Plao",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190113,
            zip_code: 18000,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190114,
            zip_code: 18000,
            name_th: "ตะกุด",
            name_en: "Takut",
            amphure_id: 1901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1902,
        name_th: "แก่งคอย",
        name_en: "Kaeng Khoi",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190201,
            zip_code: 18110,
            name_th: "แก่งคอย",
            name_en: "Kaeng Khoi",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190202,
            zip_code: 18260,
            name_th: "ทับกวาง",
            name_en: "Thap Kwang",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190203,
            zip_code: 18110,
            name_th: "ตาลเดี่ยว",
            name_en: "Tan Diao",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190204,
            zip_code: 18110,
            name_th: "ห้วยแห้ง",
            name_en: "Huai Haeng",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190205,
            zip_code: 18110,
            name_th: "ท่าคล้อ",
            name_en: "Tha Khlo",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190206,
            zip_code: 18110,
            name_th: "หินซ้อน",
            name_en: "Hin Son",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190207,
            zip_code: 18110,
            name_th: "บ้านธาตุ",
            name_en: "Ban That",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190208,
            zip_code: 18110,
            name_th: "บ้านป่า",
            name_en: "Ban Pa",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190209,
            zip_code: 18110,
            name_th: "ท่าตูม",
            name_en: "Tha Tum",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190210,
            zip_code: 18110,
            name_th: "ชะอม",
            name_en: "Cha-om",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190211,
            zip_code: 18110,
            name_th: "สองคอน",
            name_en: "Song Khon",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190212,
            zip_code: 18110,
            name_th: "เตาปูน",
            name_en: "Tao Pun",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190213,
            zip_code: 18110,
            name_th: "ชำผักแพว",
            name_en: "Cham Phak Phaeo",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190215,
            zip_code: 18110,
            name_th: "ท่ามะปราง",
            name_en: "Tha Maprang",
            amphure_id: 1902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1903,
        name_th: "หนองแค",
        name_en: "Nong Khae",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190301,
            zip_code: 18140,
            name_th: "หนองแค",
            name_en: "Nong Khae",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190302,
            zip_code: 18140,
            name_th: "กุ่มหัก",
            name_en: "Kum Hak",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190303,
            zip_code: 18250,
            name_th: "คชสิทธิ์",
            name_en: "Khotchasit",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190304,
            zip_code: 18250,
            name_th: "โคกตูม",
            name_en: "Khok Tum",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190305,
            zip_code: 18230,
            name_th: "โคกแย้",
            name_en: "Khok Yae",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190306,
            zip_code: 18230,
            name_th: "บัวลอย",
            name_en: "Bua Loi",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190307,
            zip_code: 18140,
            name_th: "ไผ่ต่ำ",
            name_en: "Phai Tam",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190308,
            zip_code: 18250,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190309,
            zip_code: 18230,
            name_th: "ห้วยขมิ้น",
            name_en: "Huai Khamin",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190310,
            zip_code: 18230,
            name_th: "ห้วยทราย",
            name_en: "Huai Sai",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190311,
            zip_code: 18140,
            name_th: "หนองไข่น้ำ",
            name_en: "Nong Khai Nam",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190312,
            zip_code: 18140,
            name_th: "หนองแขม",
            name_en: "Nong Khaem",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190313,
            zip_code: 18230,
            name_th: "หนองจิก",
            name_en: "Nong Chik",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190314,
            zip_code: 18140,
            name_th: "หนองจรเข้",
            name_en: "Nong Chorakhe",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190315,
            zip_code: 18230,
            name_th: "หนองนาก",
            name_en: "Nong Nak",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190316,
            zip_code: 18140,
            name_th: "หนองปลาหมอ",
            name_en: "Nong Pla Mo",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190317,
            zip_code: 18140,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190318,
            zip_code: 18140,
            name_th: "หนองโรง",
            name_en: "Nong Rong",
            amphure_id: 1903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1904,
        name_th: "วิหารแดง",
        name_en: "Wihan Daeng",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190401,
            zip_code: 18150,
            name_th: "หนองหมู",
            name_en: "Nong Mu",
            amphure_id: 1904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190402,
            zip_code: 18150,
            name_th: "บ้านลำ",
            name_en: "Ban Lam",
            amphure_id: 1904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190403,
            zip_code: 18150,
            name_th: "คลองเรือ",
            name_en: "Khlong Ruea",
            amphure_id: 1904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190404,
            zip_code: 18150,
            name_th: "วิหารแดง",
            name_en: "Wihan Daeng",
            amphure_id: 1904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190405,
            zip_code: 18150,
            name_th: "หนองสรวง",
            name_en: "Nong Suang",
            amphure_id: 1904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190406,
            zip_code: 18150,
            name_th: "เจริญธรรม",
            name_en: "Charoen Tham",
            amphure_id: 1904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1905,
        name_th: "หนองแซง",
        name_en: "Nong Saeng",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190501,
            zip_code: 18170,
            name_th: "หนองแซง",
            name_en: "Nong Saeng",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190502,
            zip_code: 18170,
            name_th: "หนองควายโซ",
            name_en: "Nong Khwai So",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190503,
            zip_code: 18170,
            name_th: "หนองหัวโพ",
            name_en: "Nong Hua Pho",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190504,
            zip_code: 18170,
            name_th: "หนองสีดา",
            name_en: "Nong Sida",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190505,
            zip_code: 18170,
            name_th: "หนองกบ",
            name_en: "Nong Kop",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190506,
            zip_code: 18170,
            name_th: "ไก่เส่า",
            name_en: "Kai Sao",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190507,
            zip_code: 18170,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190508,
            zip_code: 18170,
            name_th: "ม่วงหวาน",
            name_en: "Muang Wan",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190509,
            zip_code: 18170,
            name_th: "เขาดิน",
            name_en: "Khao Din",
            amphure_id: 1905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1906,
        name_th: "บ้านหมอ",
        name_en: "Ban Mo",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190601,
            zip_code: 18130,
            name_th: "บ้านหมอ",
            name_en: "Ban Mo",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190602,
            zip_code: 18130,
            name_th: "บางโขมด",
            name_en: "Bang Khamot",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190603,
            zip_code: 18130,
            name_th: "สร่างโศก",
            name_en: "Sang Sok",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190604,
            zip_code: 18130,
            name_th: "ตลาดน้อย",
            name_en: "Talat Noi",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190605,
            zip_code: 18130,
            name_th: "หรเทพ",
            name_en: "Horathep",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190606,
            zip_code: 18130,
            name_th: "โคกใหญ่",
            name_en: "Khok Yai",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190607,
            zip_code: 18130,
            name_th: "ไผ่ขวาง",
            name_en: "Phai Khwang",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190608,
            zip_code: 18270,
            name_th: "บ้านครัว",
            name_en: "Ban Khrua",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190609,
            zip_code: 18130,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 1906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1907,
        name_th: "ดอนพุด",
        name_en: "Don Phut",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190701,
            zip_code: 18210,
            name_th: "ดอนพุด",
            name_en: "Don Phut",
            amphure_id: 1907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190702,
            zip_code: 18210,
            name_th: "ไผ่หลิ่ว",
            name_en: "Phai Lio",
            amphure_id: 1907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190703,
            zip_code: 18210,
            name_th: "บ้านหลวง",
            name_en: "Ban Luang",
            amphure_id: 1907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190704,
            zip_code: 18210,
            name_th: "ดงตะงาว",
            name_en: "Dong Ta-ngao",
            amphure_id: 1907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1908,
        name_th: "หนองโดน",
        name_en: "Nong Don",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190801,
            zip_code: 18190,
            name_th: "หนองโดน",
            name_en: "Nong Don",
            amphure_id: 1908,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190802,
            zip_code: 18190,
            name_th: "บ้านกลับ",
            name_en: "Ban Klap",
            amphure_id: 1908,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190803,
            zip_code: 18190,
            name_th: "ดอนทอง",
            name_en: "Don Thong",
            amphure_id: 1908,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190804,
            zip_code: 18190,
            name_th: "บ้านโปร่ง",
            name_en: "Ban Prong",
            amphure_id: 1908,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1909,
        name_th: "พระพุทธบาท",
        name_en: "Phra Phutthabat",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 190901,
            zip_code: 18120,
            name_th: "พระพุทธบาท",
            name_en: "Phra Phutthabat",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190902,
            zip_code: 18120,
            name_th: "ขุนโขลน",
            name_en: "Khun Khlon",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190903,
            zip_code: 18120,
            name_th: "ธารเกษม",
            name_en: "Than Kasem",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190904,
            zip_code: 18120,
            name_th: "นายาว",
            name_en: "Na Yao",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190905,
            zip_code: 18120,
            name_th: "พุคำจาน",
            name_en: "Phu Kham Chan",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190906,
            zip_code: 18120,
            name_th: "เขาวง",
            name_en: "Khao Wong",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190907,
            zip_code: 18120,
            name_th: "ห้วยป่าหวาย",
            name_en: "Huai Pa Wai",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190908,
            zip_code: 18120,
            name_th: "พุกร่าง",
            name_en: "Phu Krang",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 190909,
            zip_code: 18120,
            name_th: "หนองแก",
            name_en: "Nong Kae",
            amphure_id: 1909,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1910,
        name_th: "เสาไห้",
        name_en: "Sao Hai",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 191001,
            zip_code: 18160,
            name_th: "เสาไห้",
            name_en: "Sao Hai",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191002,
            zip_code: 18160,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191003,
            zip_code: 18160,
            name_th: "หัวปลวก",
            name_en: "Hua Pluak",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191004,
            zip_code: 18160,
            name_th: "งิ้วงาม",
            name_en: "Ngio Ngam",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191005,
            zip_code: 18160,
            name_th: "ศาลารีไทย",
            name_en: "Sala Ri Thai",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191006,
            zip_code: 18160,
            name_th: "ต้นตาล",
            name_en: "Ton Tan",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191007,
            zip_code: 18160,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191008,
            zip_code: 18160,
            name_th: "พระยาทด",
            name_en: "Phraya Thot",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191009,
            zip_code: 18160,
            name_th: "ม่วงงาม",
            name_en: "Muang Ngam",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191010,
            zip_code: 18160,
            name_th: "เริงราง",
            name_en: "Roeng Rang",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191011,
            zip_code: 18160,
            name_th: "เมืองเก่า",
            name_en: "Mueang Kao",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191012,
            zip_code: 18160,
            name_th: "สวนดอกไม้",
            name_en: "Suan Dok Mai",
            amphure_id: 1910,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1911,
        name_th: "มวกเหล็ก",
        name_en: "Muak Lek",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 191101,
            zip_code: 18180,
            name_th: "มวกเหล็ก",
            name_en: "Muak Lek",
            amphure_id: 1911,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191102,
            zip_code: 18180,
            name_th: "มิตรภาพ",
            name_en: "Mittraphap",
            amphure_id: 1911,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191104,
            zip_code: 18180,
            name_th: "หนองย่างเสือ",
            name_en: "Nong Yang Suea",
            amphure_id: 1911,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191105,
            zip_code: 18180,
            name_th: "ลำสมพุง",
            name_en: "Lam Somphung",
            amphure_id: 1911,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191107,
            zip_code: 18180,
            name_th: "ลำพญากลาง",
            name_en: "Lam Phaya Klang",
            amphure_id: 1911,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191109,
            zip_code: 18220,
            name_th: "ซับสนุ่น",
            name_en: "Sap Sanun",
            amphure_id: 1911,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1912,
        name_th: "วังม่วง",
        name_en: "Wang Muang",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 191201,
            zip_code: 18220,
            name_th: "แสลงพัน",
            name_en: "Salaeng Phan",
            amphure_id: 1912,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191202,
            zip_code: 18220,
            name_th: "คำพราน",
            name_en: "Kham Phran",
            amphure_id: 1912,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191203,
            zip_code: 18220,
            name_th: "วังม่วง",
            name_en: "Wang Muang",
            amphure_id: 1912,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 1913,
        name_th: "เฉลิมพระเกียรติ",
        name_en: "Chaloem Phra Kiat",
        province_id: 10,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 191301,
            zip_code: 18000,
            name_th: "เขาดินพัฒนา",
            name_en: "Khao Din Phatthana",
            amphure_id: 1913,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191302,
            zip_code: 18000,
            name_th: "บ้านแก้ง",
            name_en: "Ban Kaeng",
            amphure_id: 1913,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191303,
            zip_code: 18000,
            name_th: "ผึ้งรวง",
            name_en: "Phueng Ruang",
            amphure_id: 1913,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191304,
            zip_code: 18240,
            name_th: "พุแค",
            name_en: "Phu Khae",
            amphure_id: 1913,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191305,
            zip_code: 18000,
            name_th: "ห้วยบง",
            name_en: "Huai Bong",
            amphure_id: 1913,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 191306,
            zip_code: 18240,
            name_th: "หน้าพระลาน",
            name_en: "Na Phra Lan",
            amphure_id: 1913,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 11,
    name_th: "ชลบุรี",
    name_en: "Chon Buri",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2001,
        name_th: "เมืองชลบุรี",
        name_en: "Mueang Chon Buri",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200101,
            zip_code: 20000,
            name_th: "บางปลาสร้อย",
            name_en: "Bang Pla Soi",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200102,
            zip_code: 20000,
            name_th: "มะขามหย่ง",
            name_en: "Makham Yong",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200103,
            zip_code: 20000,
            name_th: "บ้านโขด",
            name_en: "Ban Khot",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200104,
            zip_code: 20000,
            name_th: "แสนสุข",
            name_en: "Saen Suk",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200105,
            zip_code: 20000,
            name_th: "บ้านสวน",
            name_en: "Ban Suan",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200106,
            zip_code: 20000,
            name_th: "หนองรี",
            name_en: "Nong Ri",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200107,
            zip_code: 20000,
            name_th: "นาป่า",
            name_en: "Na Pa",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200108,
            zip_code: 20000,
            name_th: "หนองข้างคอก",
            name_en: "Nong Khang Khok",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200109,
            zip_code: 20000,
            name_th: "ดอนหัวฬ่อ",
            name_en: "Don Hua Lo",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200110,
            zip_code: 20000,
            name_th: "หนองไม้แดง",
            name_en: "Nong Mai Daeng",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200111,
            zip_code: 20000,
            name_th: "บางทราย",
            name_en: "Bang Sai",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200112,
            zip_code: 20000,
            name_th: "คลองตำหรุ",
            name_en: "Khlong Tamru",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200113,
            zip_code: 20130,
            name_th: "เหมือง",
            name_en: "Mueang",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200114,
            zip_code: 20130,
            name_th: "บ้านปึก",
            name_en: "Ban Puek",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200115,
            zip_code: 20000,
            name_th: "ห้วยกะปิ",
            name_en: "Huai Kapi",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200116,
            zip_code: 20130,
            name_th: "เสม็ด",
            name_en: "Samet",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200117,
            zip_code: 20000,
            name_th: "อ่างศิลา",
            name_en: "Ang Sila",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200118,
            zip_code: 20000,
            name_th: "สำนักบก",
            name_en: "Samnak Bok",
            amphure_id: 2001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2002,
        name_th: "บ้านบึง",
        name_en: "Ban Bueng",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200201,
            zip_code: 20170,
            name_th: "บ้านบึง",
            name_en: "Ban Bueng",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200202,
            zip_code: 20220,
            name_th: "คลองกิ่ว",
            name_en: "Khlong Kio",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200203,
            zip_code: 20170,
            name_th: "มาบไผ่",
            name_en: "Map Phai",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200204,
            zip_code: 20170,
            name_th: "หนองซ้ำซาก",
            name_en: "Nong Samsak",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200205,
            zip_code: 20170,
            name_th: "หนองบอนแดง",
            name_en: "Nong Bon Daeng",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200206,
            zip_code: 20170,
            name_th: "หนองชาก",
            name_en: "Nong Chak",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200207,
            zip_code: 20220,
            name_th: "หนองอิรุณ",
            name_en: "Nong Irun",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200208,
            zip_code: 20220,
            name_th: "หนองไผ่แก้ว",
            name_en: "Nong Phai Kaeo",
            amphure_id: 2002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2003,
        name_th: "หนองใหญ่",
        name_en: "Nong Yai",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200301,
            zip_code: 20190,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 2003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200302,
            zip_code: 20190,
            name_th: "คลองพลู",
            name_en: "Khlong Phlu",
            amphure_id: 2003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200303,
            zip_code: 20190,
            name_th: "หนองเสือช้าง",
            name_en: "Nong Suea Chang",
            amphure_id: 2003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200304,
            zip_code: 20190,
            name_th: "ห้างสูง",
            name_en: "Hang Sung",
            amphure_id: 2003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200305,
            zip_code: 20190,
            name_th: "เขาซก",
            name_en: "Khao Sok",
            amphure_id: 2003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2004,
        name_th: "บางละมุง",
        name_en: "Bang Lamung",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200401,
            zip_code: 20150,
            name_th: "บางละมุง",
            name_en: "Bang Lamung",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200402,
            zip_code: 20150,
            name_th: "หนองปรือ",
            name_en: "Nong Prue",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200403,
            zip_code: 20150,
            name_th: "หนองปลาไหล",
            name_en: "Nong Pla Lai",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200404,
            zip_code: 20150,
            name_th: "โป่ง",
            name_en: "Pong",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200405,
            zip_code: 20150,
            name_th: "เขาไม้แก้ว",
            name_en: "Khao Mai Kaeo",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200406,
            zip_code: 20150,
            name_th: "ห้วยใหญ่",
            name_en: "Huai Yai",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200407,
            zip_code: 20150,
            name_th: "ตะเคียนเตี้ย",
            name_en: "Takhian Tia",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200408,
            zip_code: 20150,
            name_th: "นาเกลือ",
            name_en: "Na Kluea",
            amphure_id: 2004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2005,
        name_th: "พานทอง",
        name_en: "Phan Thong",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200501,
            zip_code: 20160,
            name_th: "พานทอง",
            name_en: "Phan Thong",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200502,
            zip_code: 20160,
            name_th: "หนองตำลึง",
            name_en: "Nong Tamlueng",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200503,
            zip_code: 20160,
            name_th: "มาบโป่ง",
            name_en: "Map Pong",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200504,
            zip_code: 20160,
            name_th: "หนองกะขะ",
            name_en: "Nong Kakha",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200505,
            zip_code: 20160,
            name_th: "หนองหงษ์",
            name_en: "Nong Hong",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200506,
            zip_code: 20160,
            name_th: "โคกขี้หนอน",
            name_en: "Khok Khi Non",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200507,
            zip_code: 20160,
            name_th: "บ้านเก่า",
            name_en: "Ban Kao",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200508,
            zip_code: 20160,
            name_th: "หน้าประดู่",
            name_en: "Na Pradu",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200509,
            zip_code: 20160,
            name_th: "บางนาง",
            name_en: "Bang Nang",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200510,
            zip_code: 20160,
            name_th: "เกาะลอย",
            name_en: "Ko Loi",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200511,
            zip_code: 20160,
            name_th: "บางหัก",
            name_en: "Bang Hak",
            amphure_id: 2005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2006,
        name_th: "พนัสนิคม",
        name_en: "Phanat Nikhom",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200601,
            zip_code: 20140,
            name_th: "พนัสนิคม",
            name_en: "Phanat Nikhom",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200602,
            zip_code: 20140,
            name_th: "หน้าพระธาตุ",
            name_en: "Na Phra That",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200603,
            zip_code: 20140,
            name_th: "วัดหลวง",
            name_en: "Wat Luang",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200604,
            zip_code: 20140,
            name_th: "บ้านเซิด",
            name_en: "Ban Soet",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200605,
            zip_code: 20140,
            name_th: "นาเริก",
            name_en: "Na Roek",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200606,
            zip_code: 20140,
            name_th: "หมอนนาง",
            name_en: "Mon Nang",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200607,
            zip_code: 20140,
            name_th: "สระสี่เหลี่ยม",
            name_en: "Sa Si Liam",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200608,
            zip_code: 20140,
            name_th: "วัดโบสถ์",
            name_en: "Wat Bot",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200609,
            zip_code: 20140,
            name_th: "กุฎโง้ง",
            name_en: "Kut Ngong",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200610,
            zip_code: 20140,
            name_th: "หัวถนน",
            name_en: "Hua Thanon",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200611,
            zip_code: 20140,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200613,
            zip_code: 20140,
            name_th: "หนองปรือ",
            name_en: "Nong Prue",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200614,
            zip_code: 20140,
            name_th: "หนองขยาด",
            name_en: "Nong Khayat",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200615,
            zip_code: 20140,
            name_th: "ทุ่งขวาง",
            name_en: "Thung Khwang",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200616,
            zip_code: 20140,
            name_th: "หนองเหียง",
            name_en: "Nong Hiang",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200617,
            zip_code: 20140,
            name_th: "นาวังหิน",
            name_en: "Na Wang Hin",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200618,
            zip_code: 20140,
            name_th: "บ้านช้าง",
            name_en: "Ban Chang",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200620,
            zip_code: 20140,
            name_th: "โคกเพลาะ",
            name_en: "Khok Phlo",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200621,
            zip_code: 20140,
            name_th: "ไร่หลักทอง",
            name_en: "Rai Lak Thong",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200622,
            zip_code: 20140,
            name_th: "นามะตูม",
            name_en: "Na Matum",
            amphure_id: 2006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2007,
        name_th: "ศรีราชา",
        name_en: "Si Racha",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200701,
            zip_code: 20110,
            name_th: "ศรีราชา",
            name_en: "Si Racha",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200702,
            zip_code: 20110,
            name_th: "สุรศักดิ์",
            name_en: "Surasak",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200703,
            zip_code: 20230,
            name_th: "ทุ่งสุขลา",
            name_en: "Thung Sukhla",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200704,
            zip_code: 20230,
            name_th: "บึง",
            name_en: "Bueng",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200705,
            zip_code: 20110,
            name_th: "หนองขาม",
            name_en: "Nong Kham",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200706,
            zip_code: 20110,
            name_th: "เขาคันทรง",
            name_en: "Khao Khansong",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200707,
            zip_code: 20110,
            name_th: "บางพระ",
            name_en: "Bang Phra",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200708,
            zip_code: 20230,
            name_th: "บ่อวิน",
            name_en: "Bo Win",
            amphure_id: 2007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2008,
        name_th: "เกาะสีชัง",
        name_en: "Ko Sichang",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200801,
            zip_code: 20120,
            name_th: "ท่าเทววงษ์",
            name_en: "Tha Tewatong",
            amphure_id: 2008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2009,
        name_th: "สัตหีบ",
        name_en: "Sattahip",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 200901,
            zip_code: 20180,
            name_th: "สัตหีบ",
            name_en: "Sattahip",
            amphure_id: 2009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200902,
            zip_code: 20250,
            name_th: "นาจอมเทียน",
            name_en: "Na Chom Thian",
            amphure_id: 2009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200903,
            zip_code: 20180,
            name_th: "พลูตาหลวง",
            name_en: "Phlu Ta Luang",
            amphure_id: 2009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200904,
            zip_code: 20250,
            name_th: "บางเสร่",
            name_en: "Bang Sare",
            amphure_id: 2009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 200905,
            zip_code: 20180,
            name_th: "แสมสาร",
            name_en: "Samaesan",
            amphure_id: 2009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2010,
        name_th: "บ่อทอง",
        name_en: "Bo Thong",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 201001,
            zip_code: 20270,
            name_th: "บ่อทอง",
            name_en: "Bo Thong",
            amphure_id: 2010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 201002,
            zip_code: 20270,
            name_th: "วัดสุวรรณ",
            name_en: "Wat Suwan",
            amphure_id: 2010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 201003,
            zip_code: 20270,
            name_th: "บ่อกวางทอง",
            name_en: "Bo Kwang Thong",
            amphure_id: 2010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 201004,
            zip_code: 20270,
            name_th: "ธาตุทอง",
            name_en: "That Thong",
            amphure_id: 2010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 201005,
            zip_code: 20270,
            name_th: "เกษตรสุวรรณ",
            name_en: "Kaset Suwan",
            amphure_id: 2010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 201006,
            zip_code: 20270,
            name_th: "พลวงทอง",
            name_en: "Phluang Thong",
            amphure_id: 2010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2011,
        name_th: "เกาะจันทร์",
        name_en: "Ko Chan",
        province_id: 11,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 201101,
            zip_code: 20240,
            name_th: "เกาะจันทร์",
            name_en: "Ko Chan",
            amphure_id: 2011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 201102,
            zip_code: 20240,
            name_th: "ท่าบุญมี",
            name_en: "Tha Bun Mi",
            amphure_id: 2011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name_th: "ระยอง",
    name_en: "Rayong",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2101,
        name_th: "เมืองระยอง",
        name_en: "Mueang Rayong",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210101,
            zip_code: 21000,
            name_th: "ท่าประดู่",
            name_en: "Tha Pradu",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210102,
            zip_code: 21000,
            name_th: "เชิงเนิน",
            name_en: "Choeng Noen",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210103,
            zip_code: 21000,
            name_th: "ตะพง",
            name_en: "Taphong",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210104,
            zip_code: 21000,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210105,
            zip_code: 21160,
            name_th: "เพ",
            name_en: "Phe",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210106,
            zip_code: 21160,
            name_th: "แกลง",
            name_en: "Klaeng",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210107,
            zip_code: 21000,
            name_th: "บ้านแลง",
            name_en: "Ban Laeng",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210108,
            zip_code: 21000,
            name_th: "นาตาขวัญ",
            name_en: "Na Ta Khwan",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210109,
            zip_code: 21000,
            name_th: "เนินพระ",
            name_en: "Noen Phra",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210110,
            zip_code: 21100,
            name_th: "กะเฉด",
            name_en: "Kachet",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210111,
            zip_code: 21000,
            name_th: "ทับมา",
            name_en: "Thap Ma",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210112,
            zip_code: 21000,
            name_th: "น้ำคอก",
            name_en: "Nam Khok",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210113,
            zip_code: 21150,
            name_th: "ห้วยโป่ง",
            name_en: "Huai Pong",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210114,
            zip_code: 21150,
            name_th: "มาบตาพุด",
            name_en: "Map Ta Phut",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210115,
            zip_code: 21100,
            name_th: "สำนักทอง",
            name_en: "Samnak Thong",
            amphure_id: 2101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2102,
        name_th: "บ้านฉาง",
        name_en: "Ban Chang",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210201,
            zip_code: 21130,
            name_th: "สำนักท้อน",
            name_en: "Samnak Thon",
            amphure_id: 2102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210202,
            zip_code: 21130,
            name_th: "พลา",
            name_en: "Phla",
            amphure_id: 2102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210203,
            zip_code: 21130,
            name_th: "บ้านฉาง",
            name_en: "Ban Chang",
            amphure_id: 2102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2103,
        name_th: "แกลง",
        name_en: "Klaeng",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210301,
            zip_code: 21110,
            name_th: "ทางเกวียน",
            name_en: "Thang Kwian",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210302,
            zip_code: 21110,
            name_th: "วังหว้า",
            name_en: "Wang Wa",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210303,
            zip_code: 21110,
            name_th: "ชากโดน",
            name_en: "Chak Don",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210304,
            zip_code: 21110,
            name_th: "เนินฆ้อ",
            name_en: "Noen Kho",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210305,
            zip_code: 21190,
            name_th: "กร่ำ",
            name_en: "Kram",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210306,
            zip_code: 21190,
            name_th: "ชากพง",
            name_en: "Chak Phong",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210307,
            zip_code: 21110,
            name_th: "กระแสบน",
            name_en: "Krasae Bon",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210308,
            zip_code: 21110,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210309,
            zip_code: 21110,
            name_th: "ทุ่งควายกิน",
            name_en: "Thung Khwai Kin",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210310,
            zip_code: 22160,
            name_th: "กองดิน",
            name_en: "Kong Din",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210311,
            zip_code: 21170,
            name_th: "คลองปูน",
            name_en: "Khlong Pun",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210312,
            zip_code: 21110,
            name_th: "พังราด",
            name_en: "Phang Rat",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210313,
            zip_code: 21170,
            name_th: "ปากน้ำกระแส",
            name_en: "Pak Nam Krasae",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210317,
            zip_code: 21110,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210318,
            zip_code: 21110,
            name_th: "สองสลึง",
            name_en: "Song Salueng",
            amphure_id: 2103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2104,
        name_th: "วังจันทร์",
        name_en: "Wang Chan",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210401,
            zip_code: 21210,
            name_th: "วังจันทร์",
            name_en: "Wang Chan",
            amphure_id: 2104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210402,
            zip_code: 21210,
            name_th: "ชุมแสง",
            name_en: "Chum Saeng",
            amphure_id: 2104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210403,
            zip_code: 21210,
            name_th: "ป่ายุบใน",
            name_en: "Pa Yup Nai",
            amphure_id: 2104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210404,
            zip_code: 21210,
            name_th: "พลงตาเอี่ยม",
            name_en: "Phlong Ta Iam",
            amphure_id: 2104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2105,
        name_th: "บ้านค่าย",
        name_en: "Ban Khai",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210501,
            zip_code: 21120,
            name_th: "บ้านค่าย",
            name_en: "Ban Khai",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210502,
            zip_code: 21120,
            name_th: "หนองละลอก",
            name_en: "Nong Lalok",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210503,
            zip_code: 21120,
            name_th: "หนองตะพาน",
            name_en: "Nong Taphan",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210504,
            zip_code: 21120,
            name_th: "ตาขัน",
            name_en: "Ta Khan",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210505,
            zip_code: 21120,
            name_th: "บางบุตร",
            name_en: "Bang But",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210506,
            zip_code: 21120,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210507,
            zip_code: 21120,
            name_th: "ชากบก",
            name_en: "Chak Bok",
            amphure_id: 2105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2106,
        name_th: "ปลวกแดง",
        name_en: "Pluak Daeng",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210601,
            zip_code: 21140,
            name_th: "ปลวกแดง",
            name_en: "Pluak Daeng",
            amphure_id: 2106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210602,
            zip_code: 21140,
            name_th: "ตาสิทธิ์",
            name_en: "Ta Sit",
            amphure_id: 2106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210603,
            zip_code: 21140,
            name_th: "ละหาร",
            name_en: "Lahan",
            amphure_id: 2106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210604,
            zip_code: 21140,
            name_th: "แม่น้ำคู้",
            name_en: "Maenam Khu",
            amphure_id: 2106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210605,
            zip_code: 21140,
            name_th: "มาบยางพร",
            name_en: "Map Yang Phon",
            amphure_id: 2106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210606,
            zip_code: 21140,
            name_th: "หนองไร่",
            name_en: "Nong Rai",
            amphure_id: 2106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2107,
        name_th: "เขาชะเมา",
        name_en: "Khao Chamao",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210701,
            zip_code: 21110,
            name_th: "น้ำเป็น",
            name_en: "Nam Pen",
            amphure_id: 2107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210702,
            zip_code: 21110,
            name_th: "ห้วยทับมอญ",
            name_en: "Huai Thap Mon",
            amphure_id: 2107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210703,
            zip_code: 21110,
            name_th: "ชำฆ้อ",
            name_en: "Cham Kho",
            amphure_id: 2107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210704,
            zip_code: 21110,
            name_th: "เขาน้อย",
            name_en: "Khao Noy",
            amphure_id: 2107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2108,
        name_th: "นิคมพัฒนา",
        name_en: "Nikhom Phatthana",
        province_id: 12,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 210801,
            zip_code: 21180,
            name_th: "นิคมพัฒนา",
            name_en: "Nikhom Phatthana",
            amphure_id: 2108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210802,
            zip_code: 21180,
            name_th: "มาบข่า",
            name_en: "Map Kha",
            amphure_id: 2108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210803,
            zip_code: 21180,
            name_th: "พนานิคม",
            name_en: "Phana Nikhom",
            amphure_id: 2108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 210804,
            zip_code: 21180,
            name_th: "มะขามคู่",
            name_en: "Makham Khu",
            amphure_id: 2108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 13,
    name_th: "จันทบุรี",
    name_en: "Chanthaburi",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2201,
        name_th: "เมืองจันทบุรี",
        name_en: "Mueang Chanthaburi",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220101,
            zip_code: 22000,
            name_th: "ตลาด",
            name_en: "Talat",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220102,
            zip_code: 22000,
            name_th: "วัดใหม่",
            name_en: "Wat Mai",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220103,
            zip_code: 22000,
            name_th: "คลองนารายณ์",
            name_en: "Khlong Narai",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220104,
            zip_code: 22000,
            name_th: "เกาะขวาง",
            name_en: "Ko Khwang",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220105,
            zip_code: 22000,
            name_th: "คมบาง",
            name_en: "Khom Bang",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220106,
            zip_code: 22000,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220107,
            zip_code: 22000,
            name_th: "จันทนิมิต",
            name_en: "Chanthanimit",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220108,
            zip_code: 22000,
            name_th: "บางกะจะ",
            name_en: "Bang Kacha",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220109,
            zip_code: 22000,
            name_th: "แสลง",
            name_en: "Salaeng",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220110,
            zip_code: 22000,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220111,
            zip_code: 22000,
            name_th: "พลับพลา",
            name_en: "Phlapphla",
            amphure_id: 2201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2202,
        name_th: "ขลุง",
        name_en: "Khlung",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220201,
            zip_code: 22110,
            name_th: "ขลุง",
            name_en: "Khlung",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220202,
            zip_code: 22110,
            name_th: "บ่อ",
            name_en: "Bo",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220203,
            zip_code: 22110,
            name_th: "เกวียนหัก",
            name_en: "Kwian Hak",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220204,
            zip_code: 22110,
            name_th: "ตะปอน",
            name_en: "Tapon",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220205,
            zip_code: 22110,
            name_th: "บางชัน",
            name_en: "Bang Chan",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220206,
            zip_code: 22110,
            name_th: "วันยาว",
            name_en: "Wan Yao",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220207,
            zip_code: 22110,
            name_th: "ซึ้ง",
            name_en: "Sueng",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220208,
            zip_code: 22110,
            name_th: "มาบไพ",
            name_en: "Map Phai",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220209,
            zip_code: 22110,
            name_th: "วังสรรพรส",
            name_en: "Wang Sappharot",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220210,
            zip_code: 22110,
            name_th: "ตรอกนอง",
            name_en: "Trok Nong",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220211,
            zip_code: 22110,
            name_th: "ตกพรม",
            name_en: "Tok Phrom",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220212,
            zip_code: 22150,
            name_th: "บ่อเวฬุ",
            name_en: "Bo Welu",
            amphure_id: 2202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2203,
        name_th: "ท่าใหม่",
        name_en: "Tha Mai",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220301,
            zip_code: 22120,
            name_th: "ท่าใหม่",
            name_en: "Tha Mai",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220302,
            zip_code: 22120,
            name_th: "ยายร้า",
            name_en: "Yai Ra",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220303,
            zip_code: 22120,
            name_th: "สีพยา",
            name_en: "Si Phaya",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220304,
            zip_code: 22120,
            name_th: "บ่อพุ",
            name_en: "Bo Phu",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220305,
            zip_code: 22120,
            name_th: "พลอยแหวน",
            name_en: "Phloi Waen",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220306,
            zip_code: 22120,
            name_th: "เขาวัว",
            name_en: "Khao Wua",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220307,
            zip_code: 22120,
            name_th: "เขาบายศรี",
            name_en: "Khao Baisi",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220308,
            zip_code: 22120,
            name_th: "สองพี่น้อง",
            name_en: "Song Phi Nong",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220309,
            zip_code: 22170,
            name_th: "ทุ่งเบญจา",
            name_en: "Ramphan",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220311,
            zip_code: 22170,
            name_th: "รำพัน",
            name_en: "Ramphan",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220312,
            zip_code: 22170,
            name_th: "โขมง",
            name_en: "Khamong",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220313,
            zip_code: 22120,
            name_th: "ตะกาดเง้า",
            name_en: "Takat Ngao",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220314,
            zip_code: 22120,
            name_th: "คลองขุด",
            name_en: "Khlong Khut",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220324,
            zip_code: 22170,
            name_th: "เขาแก้ว",
            name_en: "Khao Kaeo",
            amphure_id: 2203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2204,
        name_th: "โป่งน้ำร้อน",
        name_en: "Pong Nam Ron",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220401,
            zip_code: 22140,
            name_th: "ทับไทร",
            name_en: "Thap Sai",
            amphure_id: 2204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220402,
            zip_code: 22140,
            name_th: "โป่งน้ำร้อน",
            name_en: "Pong Nam Ron",
            amphure_id: 2204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220404,
            zip_code: 22140,
            name_th: "หนองตาคง",
            name_en: "Nong Ta Khong",
            amphure_id: 2204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220409,
            zip_code: 22140,
            name_th: "เทพนิมิต",
            name_en: "Thep Nimit",
            amphure_id: 2204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220410,
            zip_code: 22140,
            name_th: "คลองใหญ่",
            name_en: "Khlong Yai",
            amphure_id: 2204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2205,
        name_th: "มะขาม",
        name_en: "Makham",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220501,
            zip_code: 22150,
            name_th: "มะขาม",
            name_en: "Makham",
            amphure_id: 2205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220502,
            zip_code: 22150,
            name_th: "ท่าหลวง",
            name_en: "Tha Luang",
            amphure_id: 2205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220503,
            zip_code: 22150,
            name_th: "ปัถวี",
            name_en: "Patthawi",
            amphure_id: 2205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220504,
            zip_code: 22150,
            name_th: "วังแซ้ม",
            name_en: "Wang Saem",
            amphure_id: 2205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220506,
            zip_code: 22150,
            name_th: "ฉมัน",
            name_en: "Chaman",
            amphure_id: 2205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220508,
            zip_code: 22150,
            name_th: "อ่างคีรี",
            name_en: "Ang Khiri",
            amphure_id: 2205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2206,
        name_th: "แหลมสิงห์",
        name_en: "Laem Sing",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220601,
            zip_code: 22130,
            name_th: "ปากน้ำแหลมสิงห์",
            name_en: "Pak Nam Laem Sing",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220602,
            zip_code: 22130,
            name_th: "เกาะเปริด",
            name_en: "Ko Proet",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220603,
            zip_code: 22130,
            name_th: "หนองชิ่ม",
            name_en: "Nong Chim",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220604,
            zip_code: 22190,
            name_th: "พลิ้ว",
            name_en: "Phlio",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220605,
            zip_code: 22190,
            name_th: "คลองน้ำเค็ม",
            name_en: "Khlong Nam Khem",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220606,
            zip_code: 22190,
            name_th: "บางสระเก้า",
            name_en: "Bang Sa Kao",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220607,
            zip_code: 22120,
            name_th: "บางกะไชย",
            name_en: "Bang Kachai",
            amphure_id: 2206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2207,
        name_th: "สอยดาว",
        name_en: "Soi Dao",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220701,
            zip_code: 22180,
            name_th: "ปะตง",
            name_en: "Patong",
            amphure_id: 2207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220702,
            zip_code: 22180,
            name_th: "ทุ่งขนาน",
            name_en: "Thung Khanan",
            amphure_id: 2207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220703,
            zip_code: 22180,
            name_th: "ทับช้าง",
            name_en: "Thap Chang",
            amphure_id: 2207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220704,
            zip_code: 22180,
            name_th: "ทรายขาว",
            name_en: "Sai Khao",
            amphure_id: 2207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220705,
            zip_code: 22180,
            name_th: "สะตอน",
            name_en: "Saton",
            amphure_id: 2207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2208,
        name_th: "แก่งหางแมว",
        name_en: "Kaeng Hang Maeo",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220801,
            zip_code: 22160,
            name_th: "แก่งหางแมว",
            name_en: "Kaeng Hang Maeo",
            amphure_id: 2208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220802,
            zip_code: 22160,
            name_th: "ขุนซ่อง",
            name_en: "Khun Song",
            amphure_id: 2208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220803,
            zip_code: 22160,
            name_th: "สามพี่น้อง",
            name_en: "Sam Phi Nong",
            amphure_id: 2208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220804,
            zip_code: 22160,
            name_th: "พวา",
            name_en: "Phawa",
            amphure_id: 2208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220805,
            zip_code: 22160,
            name_th: "เขาวงกต",
            name_en: "Khao Wongkot",
            amphure_id: 2208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2209,
        name_th: "นายายอาม",
        name_en: "Na Yai Am",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 220901,
            zip_code: 22160,
            name_th: "นายายอาม",
            name_en: "Na Yai Am",
            amphure_id: 2209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220902,
            zip_code: 22170,
            name_th: "วังโตนด",
            name_en: "Wang Tanot",
            amphure_id: 2209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220903,
            zip_code: 22170,
            name_th: "กระแจะ",
            name_en: "Krachae",
            amphure_id: 2209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220904,
            zip_code: 22170,
            name_th: "สนามไชย",
            name_en: "Sanam Chai",
            amphure_id: 2209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220905,
            zip_code: 22160,
            name_th: "ช้างข้าม",
            name_en: "Chang Kham",
            amphure_id: 2209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 220906,
            zip_code: 22170,
            name_th: "วังใหม่",
            name_en: "Wang Mai",
            amphure_id: 2209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2210,
        name_th: "เขาคิชฌกูฏ",
        name_en: "Khoa Khitchakut",
        province_id: 13,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 221001,
            zip_code: 22210,
            name_th: "ชากไทย",
            name_en: "Chak Thai",
            amphure_id: 2210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 221002,
            zip_code: 22210,
            name_th: "พลวง",
            name_en: "Phluang",
            amphure_id: 2210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 221003,
            zip_code: 22210,
            name_th: "ตะเคียนทอง",
            name_en: "Takhian Thong",
            amphure_id: 2210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 221004,
            zip_code: 22210,
            name_th: "คลองพลู",
            name_en: "Khlong Phlu",
            amphure_id: 2210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 221005,
            zip_code: 22210,
            name_th: "จันทเขลม",
            name_en: "Chanthakhlem",
            amphure_id: 2210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 14,
    name_th: "ตราด",
    name_en: "Trat",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2301,
        name_th: "เมืองตราด",
        name_en: "Mueang Trat",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230101,
            zip_code: 23000,
            name_th: "บางพระ",
            name_en: "Bang Phra",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230102,
            zip_code: 23000,
            name_th: "หนองเสม็ด",
            name_en: "Nong Samet",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230103,
            zip_code: 23000,
            name_th: "หนองโสน",
            name_en: "Nong Sano",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230104,
            zip_code: 23000,
            name_th: "หนองคันทรง",
            name_en: "Nong Khan Song",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230105,
            zip_code: 23000,
            name_th: "ห้วงน้ำขาว",
            name_en: "Huang Nam Khao",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230106,
            zip_code: 23000,
            name_th: "อ่าวใหญ่",
            name_en: "Ao Yai",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230107,
            zip_code: 23000,
            name_th: "วังกระแจะ",
            name_en: "Wang Krachae",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230108,
            zip_code: 23000,
            name_th: "ห้วยแร้ง",
            name_en: "Huai Raeng",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230109,
            zip_code: 23000,
            name_th: "เนินทราย",
            name_en: "Noen Sai",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230110,
            zip_code: 23000,
            name_th: "ท่าพริก",
            name_en: "Tha Phrik",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230111,
            zip_code: 23000,
            name_th: "ท่ากุ่ม",
            name_en: "Tha Kum",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230112,
            zip_code: 23000,
            name_th: "ตะกาง",
            name_en: "Takang",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230113,
            zip_code: 23000,
            name_th: "ชำราก",
            name_en: "Chamrak",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230114,
            zip_code: 23000,
            name_th: "แหลมกลัด",
            name_en: "Laem Klat",
            amphure_id: 2301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2302,
        name_th: "คลองใหญ่",
        name_en: "Khlong Yai",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230201,
            zip_code: 23110,
            name_th: "คลองใหญ่",
            name_en: "Khlong Yai",
            amphure_id: 2302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230202,
            zip_code: 23110,
            name_th: "ไม้รูด",
            name_en: "Mai Rut",
            amphure_id: 2302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230203,
            zip_code: 23110,
            name_th: "หาดเล็ก",
            name_en: "Hat Lek",
            amphure_id: 2302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2303,
        name_th: "เขาสมิง",
        name_en: "Khao Saming",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230301,
            zip_code: 23130,
            name_th: "เขาสมิง",
            name_en: "Khao Saming",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230302,
            zip_code: 23150,
            name_th: "แสนตุ้ง",
            name_en: "Saen Tung",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230303,
            zip_code: 23130,
            name_th: "วังตะเคียน",
            name_en: "Wang Takhian",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230304,
            zip_code: 23150,
            name_th: "ท่าโสม",
            name_en: "Tha Som",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230305,
            zip_code: 23150,
            name_th: "สะตอ",
            name_en: "Sato",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230306,
            zip_code: 23150,
            name_th: "ประณีต",
            name_en: "Pranit",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230307,
            zip_code: 23150,
            name_th: "เทพนิมิต",
            name_en: "Thep Nimit",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230308,
            zip_code: 23130,
            name_th: "ทุ่งนนทรี",
            name_en: "Thung Nonsi",
            amphure_id: 2303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2304,
        name_th: "บ่อไร่",
        name_en: "Bo Rai",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230401,
            zip_code: 23140,
            name_th: "บ่อพลอย",
            name_en: "Bo Phloi",
            amphure_id: 2304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230402,
            zip_code: 23140,
            name_th: "ช้างทูน",
            name_en: "Chang Thun",
            amphure_id: 2304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230403,
            zip_code: 23140,
            name_th: "ด่านชุมพล",
            name_en: "Dan Chumphon",
            amphure_id: 2304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230404,
            zip_code: 23140,
            name_th: "หนองบอน",
            name_en: "Nong Bon",
            amphure_id: 2304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230405,
            zip_code: 23140,
            name_th: "นนทรีย์",
            name_en: "Nonsi",
            amphure_id: 2304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2305,
        name_th: "แหลมงอบ",
        name_en: "Laem Ngop",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230501,
            zip_code: 23120,
            name_th: "แหลมงอบ",
            name_en: "Laem Ngop",
            amphure_id: 2305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230502,
            zip_code: 23120,
            name_th: "น้ำเชี่ยว",
            name_en: "Nam Chiao",
            amphure_id: 2305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230503,
            zip_code: 23120,
            name_th: "บางปิด",
            name_en: "Bang Pit",
            amphure_id: 2305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230507,
            zip_code: 23120,
            name_th: "คลองใหญ่",
            name_en: "Khlong Yai",
            amphure_id: 2305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2306,
        name_th: "เกาะกูด",
        name_en: "Ko Kut",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230601,
            zip_code: 23000,
            name_th: "เกาะหมาก",
            name_en: "Ko Mak",
            amphure_id: 2306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230602,
            zip_code: 23000,
            name_th: "เกาะกูด",
            name_en: "Ko Kut",
            amphure_id: 2306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2307,
        name_th: "เกาะช้าง",
        name_en: "Ko Chang",
        province_id: 14,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 230701,
            zip_code: 23170,
            name_th: "เกาะช้าง",
            name_en: "Ko Chang",
            amphure_id: 2307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 230702,
            zip_code: 23170,
            name_th: "เกาะช้างใต้",
            name_en: "Ko Chang Tai",
            amphure_id: 2307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 15,
    name_th: "ฉะเชิงเทรา",
    name_en: "Chachoengsao",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2401,
        name_th: "เมืองฉะเชิงเทรา",
        name_en: "Mueang Chachoengsao",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240101,
            zip_code: 24000,
            name_th: "หน้าเมือง",
            name_en: "Na Mueang",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240102,
            zip_code: 24000,
            name_th: "ท่าไข่",
            name_en: "Tha Khai",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240103,
            zip_code: 24000,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240104,
            zip_code: 24000,
            name_th: "คลองนา",
            name_en: "Khlong Na",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240105,
            zip_code: 24000,
            name_th: "บางตีนเป็ด",
            name_en: "Khlong Na",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240106,
            zip_code: 24000,
            name_th: "บางไผ่",
            name_en: "Bang Phai",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240107,
            zip_code: 24000,
            name_th: "คลองจุกกระเฌอ",
            name_en: "Khlong Chuk Krachoe",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240108,
            zip_code: 24000,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240109,
            zip_code: 24000,
            name_th: "บางขวัญ",
            name_en: "Bang Khwan",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240110,
            zip_code: 24000,
            name_th: "คลองนครเนื่องเขต",
            name_en: "Khlong Nakhon Nueang Khet",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240111,
            zip_code: 24000,
            name_th: "วังตะเคียน",
            name_en: "Wang Takhian",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240112,
            zip_code: 24000,
            name_th: "โสธร",
            name_en: "Sothon",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240113,
            zip_code: 24000,
            name_th: "บางพระ",
            name_en: "Bang Phra",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240114,
            zip_code: 24000,
            name_th: "บางกะไห",
            name_en: "Bang Kahai",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240115,
            zip_code: 24000,
            name_th: "หนามแดง",
            name_en: "Nam Daeng",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240116,
            zip_code: 24000,
            name_th: "คลองเปรง",
            name_en: "Khlong Preng",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240117,
            zip_code: 24000,
            name_th: "คลองอุดมชลจร",
            name_en: "Khlong Udom Chonlachon",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240118,
            zip_code: 24000,
            name_th: "คลองหลวงแพ่ง",
            name_en: "Khlong Luang Phaeng",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240119,
            zip_code: 24000,
            name_th: "บางเตย",
            name_en: "Bang Toei",
            amphure_id: 2401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2402,
        name_th: "บางคล้า",
        name_en: "Bang Khla",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240201,
            zip_code: 24110,
            name_th: "บางคล้า",
            name_en: "Bang Khla",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240204,
            zip_code: 24110,
            name_th: "บางสวน",
            name_en: "Bang Suan",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240208,
            zip_code: 24110,
            name_th: "บางกระเจ็ด",
            name_en: "Bang Krachet",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240209,
            zip_code: 24110,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240210,
            zip_code: 24110,
            name_th: "ท่าทองหลาง",
            name_en: "Tha Thonglang",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240211,
            zip_code: 24110,
            name_th: "สาวชะโงก",
            name_en: "Sao Cha-ngok",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240212,
            zip_code: 24110,
            name_th: "เสม็ดเหนือ",
            name_en: "Samet Nuea",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240213,
            zip_code: 24110,
            name_th: "เสม็ดใต้",
            name_en: "Samet Tai",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240214,
            zip_code: 24110,
            name_th: "หัวไทร",
            name_en: "Hua Sai",
            amphure_id: 2402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2403,
        name_th: "บางน้ำเปรี้ยว",
        name_en: "Bang Nam Priao",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240301,
            zip_code: 24150,
            name_th: "บางน้ำเปรี้ยว",
            name_en: "Bang Nam Priao",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240302,
            zip_code: 24150,
            name_th: "บางขนาก",
            name_en: "Bang Khanak",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240303,
            zip_code: 24150,
            name_th: "สิงโตทอง",
            name_en: "Singto Thong",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240304,
            zip_code: 24150,
            name_th: "หมอนทอง",
            name_en: "Mon Thong",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240305,
            zip_code: 24170,
            name_th: "บึงน้ำรักษ์",
            name_en: "Bueng Nam Rak",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240306,
            zip_code: 24170,
            name_th: "ดอนเกาะกา",
            name_en: "Don Ko Ka",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240307,
            zip_code: 24150,
            name_th: "โยธะกา",
            name_en: "Yothaka",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240308,
            zip_code: 24170,
            name_th: "ดอนฉิมพลี",
            name_en: "Don Chimphli",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240309,
            zip_code: 24000,
            name_th: "ศาลาแดง",
            name_en: "Sala Daeng",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240310,
            zip_code: 24150,
            name_th: "โพรงอากาศ",
            name_en: "Phrong Akat",
            amphure_id: 2403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2404,
        name_th: "บางปะกง",
        name_en: "Bang Pakong",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240401,
            zip_code: 24130,
            name_th: "บางปะกง",
            name_en: "Bang Pakong",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240402,
            zip_code: 24130,
            name_th: "ท่าสะอ้าน",
            name_en: "Tha Sa-an",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240403,
            zip_code: 24180,
            name_th: "บางวัว",
            name_en: "Bang Wua",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240404,
            zip_code: 24180,
            name_th: "บางสมัคร",
            name_en: "Bang Samak",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240405,
            zip_code: 24130,
            name_th: "บางผึ้ง",
            name_en: "Bang Phueng",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240406,
            zip_code: 24180,
            name_th: "บางเกลือ",
            name_en: "Bang Kluea",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240407,
            zip_code: 24130,
            name_th: "สองคลอง",
            name_en: "Song Khlong",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240408,
            zip_code: 24130,
            name_th: "หนองจอก",
            name_en: "Nong Chok",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240409,
            zip_code: 24130,
            name_th: "พิมพา",
            name_en: "Phimpha",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240410,
            zip_code: 24130,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240411,
            zip_code: 24180,
            name_th: "หอมศีล",
            name_en: "Hom Sin",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240412,
            zip_code: 24130,
            name_th: "เขาดิน",
            name_en: "Khao Din",
            amphure_id: 2404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2405,
        name_th: "บ้านโพธิ์",
        name_en: "Ban Pho",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240501,
            zip_code: 24140,
            name_th: "บ้านโพธิ์",
            name_en: "Ban Pho",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240502,
            zip_code: 24140,
            name_th: "เกาะไร่",
            name_en: "Ko Rai",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240503,
            zip_code: 24140,
            name_th: "คลองขุด",
            name_en: "Khlong Khut",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240504,
            zip_code: 24140,
            name_th: "คลองบ้านโพธิ์",
            name_en: "Khlong Ban Pho",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240505,
            zip_code: 24140,
            name_th: "คลองประเวศ",
            name_en: "Khlong Prawet",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240506,
            zip_code: 24140,
            name_th: "ดอนทราย",
            name_en: "Don Sai",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240507,
            zip_code: 24140,
            name_th: "เทพราช",
            name_en: "Theppharat",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240508,
            zip_code: 24140,
            name_th: "ท่าพลับ",
            name_en: "Tha Phlap",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240509,
            zip_code: 24140,
            name_th: "หนองตีนนก",
            name_en: "Nong Tin Nok",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240510,
            zip_code: 24140,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240511,
            zip_code: 24140,
            name_th: "บางซ่อน",
            name_en: "Bang Son",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240512,
            zip_code: 24140,
            name_th: "บางกรูด",
            name_en: "Bang Krut",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240513,
            zip_code: 24140,
            name_th: "แหลมประดู่",
            name_en: "Laem Pradu",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240514,
            zip_code: 24140,
            name_th: "ลาดขวาง",
            name_en: "Lat Khwang",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240515,
            zip_code: 24140,
            name_th: "สนามจันทร์",
            name_en: "Sanam Chan",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240516,
            zip_code: 24140,
            name_th: "แสนภูดาษ",
            name_en: "Saen Phu Dat",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240517,
            zip_code: 24140,
            name_th: "สิบเอ็ดศอก",
            name_en: "Sip Et Sok",
            amphure_id: 2405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2406,
        name_th: "พนมสารคาม",
        name_en: "Phanom Sarakham",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240601,
            zip_code: 24120,
            name_th: "เกาะขนุน",
            name_en: "Ko Khanun",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240602,
            zip_code: 24120,
            name_th: "บ้านซ่อง",
            name_en: "Ban Song",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240603,
            zip_code: 24120,
            name_th: "พนมสารคาม",
            name_en: "Phanom Sarakham",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240604,
            zip_code: 24120,
            name_th: "เมืองเก่า",
            name_en: "Mueang Kao",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240605,
            zip_code: 24120,
            name_th: "หนองยาว",
            name_en: "Nong Yao",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240606,
            zip_code: 24120,
            name_th: "ท่าถ่าน",
            name_en: "Tha Than",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240607,
            zip_code: 24120,
            name_th: "หนองแหน",
            name_en: "Nong Nae",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240608,
            zip_code: 24120,
            name_th: "เขาหินซ้อน",
            name_en: "Khao Hin Son",
            amphure_id: 2406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2407,
        name_th: "ราชสาส์น",
        name_en: "Ratchasan",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240701,
            zip_code: 24120,
            name_th: "บางคา",
            name_en: "Bang Kha",
            amphure_id: 2407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240702,
            zip_code: 24120,
            name_th: "เมืองใหม่",
            name_en: "Mueang Mai",
            amphure_id: 2407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240703,
            zip_code: 24120,
            name_th: "ดงน้อย",
            name_en: "Dong Noi",
            amphure_id: 2407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2408,
        name_th: "สนามชัยเขต",
        name_en: "Sanam Chai Khet",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240801,
            zip_code: 24160,
            name_th: "คู้ยายหมี",
            name_en: "Khu Yai Mi",
            amphure_id: 2408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240802,
            zip_code: 24160,
            name_th: "ท่ากระดาน",
            name_en: "Tha Kradan",
            amphure_id: 2408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240803,
            zip_code: 24160,
            name_th: "ทุ่งพระยา",
            name_en: "Thung Phraya",
            amphure_id: 2408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240805,
            zip_code: 24160,
            name_th: "ลาดกระทิง",
            name_en: "Lat Krathing",
            amphure_id: 2408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2409,
        name_th: "แปลงยาว",
        name_en: "Plaeng Yao",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 240901,
            zip_code: 24190,
            name_th: "แปลงยาว",
            name_en: "Plaeng Yao",
            amphure_id: 2409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240902,
            zip_code: 24190,
            name_th: "วังเย็น",
            name_en: "Wang Yen",
            amphure_id: 2409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240903,
            zip_code: 24190,
            name_th: "หัวสำโรง",
            name_en: "Hua Samrong",
            amphure_id: 2409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 240904,
            zip_code: 24190,
            name_th: "หนองไม้แก่น",
            name_en: "Nong Mai Kaen",
            amphure_id: 2409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2410,
        name_th: "ท่าตะเกียบ",
        name_en: "Tha Takiap",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 241001,
            zip_code: 24160,
            name_th: "ท่าตะเกียบ",
            name_en: "Tha Takiap",
            amphure_id: 2410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 241002,
            zip_code: 24160,
            name_th: "คลองตะเกรา",
            name_en: "Khlong Takrao",
            amphure_id: 2410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2411,
        name_th: "คลองเขื่อน",
        name_en: "Khlong Khuean",
        province_id: 15,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 241101,
            zip_code: 24000,
            name_th: "ก้อนแก้ว",
            name_en: "Kon Kaeo",
            amphure_id: 2411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 241102,
            zip_code: 24000,
            name_th: "คลองเขื่อน",
            name_en: "Khlong Khuean",
            amphure_id: 2411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 241103,
            zip_code: 24000,
            name_th: "บางเล่า",
            name_en: "Bang Lao",
            amphure_id: 2411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 241104,
            zip_code: 24000,
            name_th: "บางโรง",
            name_en: "Bang Rong",
            amphure_id: 2411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 241105,
            zip_code: 24110,
            name_th: "บางตลาด",
            name_en: "Bang Talat",
            amphure_id: 2411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 16,
    name_th: "ปราจีนบุรี",
    name_en: "Prachin Buri",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2501,
        name_th: "เมืองปราจีนบุรี",
        name_en: "Mueang Prachin Buri",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250101,
            zip_code: 25000,
            name_th: "หน้าเมือง",
            name_en: "Na Mueang",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250102,
            zip_code: 25000,
            name_th: "รอบเมือง",
            name_en: "Na Mueang",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250103,
            zip_code: 25000,
            name_th: "วัดโบสถ์",
            name_en: "Wat Bot",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250104,
            zip_code: 25000,
            name_th: "บางเดชะ",
            name_en: "Bang Decha",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250105,
            zip_code: 25000,
            name_th: "ท่างาม",
            name_en: "Tha Ngam",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250106,
            zip_code: 25000,
            name_th: "บางบริบูรณ์",
            name_en: "Bang Boribun",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250107,
            zip_code: 25000,
            name_th: "ดงพระราม",
            name_en: "Dong Phra Ram",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250108,
            zip_code: 25230,
            name_th: "บ้านพระ",
            name_en: "Ban Phra",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250109,
            zip_code: 25230,
            name_th: "โคกไม้ลาย",
            name_en: "Khok Mai Lai",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250110,
            zip_code: 25230,
            name_th: "ไม้เค็ด",
            name_en: "Mai Khet",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250111,
            zip_code: 25000,
            name_th: "ดงขี้เหล็ก",
            name_en: "Dong Khilek",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250112,
            zip_code: 25230,
            name_th: "เนินหอม",
            name_en: "Noen Hom",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250113,
            zip_code: 25000,
            name_th: "โนนห้อม",
            name_en: "Non Hom",
            amphure_id: 2501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2502,
        name_th: "กบินทร์บุรี",
        name_en: "Kabin Buri",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250201,
            zip_code: 25110,
            name_th: "กบินทร์",
            name_en: "Kabin",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250202,
            zip_code: 25240,
            name_th: "เมืองเก่า",
            name_en: "Mueang Kao",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250203,
            zip_code: 25110,
            name_th: "วังดาล",
            name_en: "Wang Dan",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250204,
            zip_code: 25110,
            name_th: "นนทรี",
            name_en: "Nonsi",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250205,
            zip_code: 25110,
            name_th: "ย่านรี",
            name_en: "Yan Ri",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250206,
            zip_code: 25110,
            name_th: "วังตะเคียน",
            name_en: "Wang Takhian",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250207,
            zip_code: 25110,
            name_th: "หาดนางแก้ว",
            name_en: "Hat Nang Kaeo",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250208,
            zip_code: 25110,
            name_th: "ลาดตะเคียน",
            name_en: "Lat Takhian",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250209,
            zip_code: 25110,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250210,
            zip_code: 25110,
            name_th: "บ่อทอง",
            name_en: "Bo Thong",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250211,
            zip_code: 25110,
            name_th: "หนองกี่",
            name_en: "Nong Ki",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250212,
            zip_code: 25110,
            name_th: "นาแขม",
            name_en: "Na Khaem",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250213,
            zip_code: 25110,
            name_th: "เขาไม้แก้ว",
            name_en: "Khao Mai Kaeo",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250214,
            zip_code: 25110,
            name_th: "วังท่าช้าง",
            name_en: "Wang Tha Chang",
            amphure_id: 2502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2503,
        name_th: "นาดี",
        name_en: "Na Di",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250301,
            zip_code: 25220,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 2503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250302,
            zip_code: 25220,
            name_th: "สำพันตา",
            name_en: "Samphan Ta",
            amphure_id: 2503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250303,
            zip_code: 25220,
            name_th: "สะพานหิน",
            name_en: "Saphan Hin",
            amphure_id: 2503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250304,
            zip_code: 25220,
            name_th: "ทุ่งโพธิ์",
            name_en: "Thung Pho",
            amphure_id: 2503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250305,
            zip_code: 25220,
            name_th: "แก่งดินสอ",
            name_en: "Kaeng Dinso",
            amphure_id: 2503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250306,
            zip_code: 25220,
            name_th: "บุพราหมณ์",
            name_en: "Bu Phram",
            amphure_id: 2503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2506,
        name_th: "บ้านสร้าง",
        name_en: "Ban Sang",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250601,
            zip_code: 25150,
            name_th: "บ้านสร้าง",
            name_en: "Ban Sang",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250602,
            zip_code: 25150,
            name_th: "บางกระเบา",
            name_en: "Bang Krabao",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250603,
            zip_code: 25150,
            name_th: "บางเตย",
            name_en: "Bang Toei",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250604,
            zip_code: 25150,
            name_th: "บางยาง",
            name_en: "Bang Yang",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250605,
            zip_code: 25150,
            name_th: "บางแตน",
            name_en: "Bang Taen",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250606,
            zip_code: 25150,
            name_th: "บางพลวง",
            name_en: "Bang Phluang",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250607,
            zip_code: 25150,
            name_th: "บางปลาร้า",
            name_en: "Bang Pla Ra",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250608,
            zip_code: 25150,
            name_th: "บางขาม",
            name_en: "Bang Kham",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250609,
            zip_code: 25150,
            name_th: "กระทุ่มแพ้ว",
            name_en: "Krathum Phaeo)",
            amphure_id: 2506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2507,
        name_th: "ประจันตคาม",
        name_en: "Prachantakham",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250701,
            zip_code: 25130,
            name_th: "ประจันตคาม",
            name_en: "Prachantakham",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250702,
            zip_code: 25130,
            name_th: "เกาะลอย",
            name_en: "Ko Loi",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250703,
            zip_code: 25130,
            name_th: "บ้านหอย",
            name_en: "Ban Hoi",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250704,
            zip_code: 25130,
            name_th: "หนองแสง",
            name_en: "Nong Saeng",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250705,
            zip_code: 25130,
            name_th: "ดงบัง",
            name_en: "Dong Bang",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250706,
            zip_code: 25130,
            name_th: "คำโตนด",
            name_en: "Kham Tanot",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250707,
            zip_code: 25130,
            name_th: "บุฝ้าย",
            name_en: "Bu Fai",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250708,
            zip_code: 25130,
            name_th: "หนองแก้ว",
            name_en: "Nong Kaeo",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250709,
            zip_code: 25130,
            name_th: "โพธิ์งาม",
            name_en: "Pho Ngam",
            amphure_id: 2507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2508,
        name_th: "ศรีมหาโพธิ",
        name_en: "Si Maha Phot",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250801,
            zip_code: 25140,
            name_th: "ศรีมหาโพธิ",
            name_en: "Si Maha Phot",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250802,
            zip_code: 25140,
            name_th: "สัมพันธ์",
            name_en: "Samphan",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250803,
            zip_code: 25140,
            name_th: "บ้านทาม",
            name_en: "Ban Tham",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250804,
            zip_code: 25140,
            name_th: "ท่าตูม",
            name_en: "Tha Tum",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250805,
            zip_code: 25140,
            name_th: "บางกุ้ง",
            name_en: "Bang Kung",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250806,
            zip_code: 25140,
            name_th: "ดงกระทงยาม",
            name_en: "Dong Krathong Yam",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250807,
            zip_code: 25140,
            name_th: "หนองโพรง",
            name_en: "Nong Phrong",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250808,
            zip_code: 25140,
            name_th: "หัวหว้า",
            name_en: "Hua Wa",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250809,
            zip_code: 25140,
            name_th: "หาดยาง",
            name_en: "Hat Yang",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250810,
            zip_code: 25140,
            name_th: "กรอกสมบูรณ์",
            name_en: "Krok Sombun",
            amphure_id: 2508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2509,
        name_th: "ศรีมโหสถ",
        name_en: "Si Mahosot",
        province_id: 16,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 250901,
            zip_code: 25190,
            name_th: "โคกปีบ",
            name_en: "Khok Pip",
            amphure_id: 2509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250902,
            zip_code: 25190,
            name_th: "โคกไทย",
            name_en: "Khok Thai",
            amphure_id: 2509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250903,
            zip_code: 25190,
            name_th: "คู้ลำพัน",
            name_en: "Khu Lam Phan",
            amphure_id: 2509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 250904,
            zip_code: 25190,
            name_th: "ไผ่ชะเลือด",
            name_en: "Phai Cha Lueat",
            amphure_id: 2509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 17,
    name_th: "นครนายก",
    name_en: "Nakhon Nayok",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2601,
        name_th: "เมืองนครนายก",
        name_en: "Mueang Nakhon Nayok",
        province_id: 17,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 260101,
            zip_code: 26000,
            name_th: "นครนายก",
            name_en: "Nakhon Nayok",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260102,
            zip_code: 26000,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260103,
            zip_code: 26000,
            name_th: "บ้านใหญ่",
            name_en: "Ban Yai",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260104,
            zip_code: 26000,
            name_th: "วังกระโจม",
            name_en: "Wang Krachom",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260105,
            zip_code: 26000,
            name_th: "ท่าทราย",
            name_en: "Tha Sai",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260106,
            zip_code: 26000,
            name_th: "ดอนยอ",
            name_en: "Don Yo",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260107,
            zip_code: 26000,
            name_th: "ศรีจุฬา",
            name_en: "Si Chula",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260108,
            zip_code: 26000,
            name_th: "ดงละคร",
            name_en: "Dong Lakhon",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260109,
            zip_code: 26000,
            name_th: "ศรีนาวา",
            name_en: "Si Nawa",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260110,
            zip_code: 26000,
            name_th: "สาริกา",
            name_en: "Sarika",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260111,
            zip_code: 26000,
            name_th: "หินตั้ง",
            name_en: "Hin Tang",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260112,
            zip_code: 26000,
            name_th: "เขาพระ",
            name_en: "Khao Phra",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260113,
            zip_code: 26000,
            name_th: "พรหมณี",
            name_en: "Phrommani",
            amphure_id: 2601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2602,
        name_th: "ปากพลี",
        name_en: "Pak Phli",
        province_id: 17,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 260201,
            zip_code: 26130,
            name_th: "เกาะหวาย",
            name_en: "Ko Wai",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260202,
            zip_code: 26130,
            name_th: "เกาะโพธิ์",
            name_en: "Ko Pho",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260203,
            zip_code: 26130,
            name_th: "ปากพลี",
            name_en: "Pak Phli",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260204,
            zip_code: 26130,
            name_th: "โคกกรวด",
            name_en: "Khok Kruat",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260205,
            zip_code: 26130,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260206,
            zip_code: 26130,
            name_th: "หนองแสง",
            name_en: "Nong Saeng",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260207,
            zip_code: 26130,
            name_th: "นาหินลาด",
            name_en: "Na Hin Lat",
            amphure_id: 2602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2603,
        name_th: "บ้านนา",
        name_en: "Ban Na",
        province_id: 17,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 260301,
            zip_code: 26110,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260302,
            zip_code: 26110,
            name_th: "บ้านพร้าว",
            name_en: "Ban Phrao",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260303,
            zip_code: 26110,
            name_th: "บ้านพริก",
            name_en: "Ban Phrik",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260304,
            zip_code: 26110,
            name_th: "อาษา",
            name_en: "Asa",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260305,
            zip_code: 26110,
            name_th: "ทองหลาง",
            name_en: "Thonglang",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260306,
            zip_code: 26110,
            name_th: "บางอ้อ",
            name_en: "Bang O",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260307,
            zip_code: 26110,
            name_th: "พิกุลออก",
            name_en: "Phikun Ok",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260308,
            zip_code: 26110,
            name_th: "ป่าขะ",
            name_en: "Pa Kha",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260309,
            zip_code: 26110,
            name_th: "เขาเพิ่ม",
            name_en: "Khao Phoem",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260310,
            zip_code: 26110,
            name_th: "ศรีกะอาง",
            name_en: "Si Ka-ang",
            amphure_id: 2603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2604,
        name_th: "องครักษ์",
        name_en: "Ongkharak",
        province_id: 17,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 260401,
            zip_code: 26120,
            name_th: "พระอาจารย์",
            name_en: "Phra Achan",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260402,
            zip_code: 26120,
            name_th: "บึงศาล",
            name_en: "Bueng San",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260403,
            zip_code: 26120,
            name_th: "ศีรษะกระบือ",
            name_en: "Sisa Krabue",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260404,
            zip_code: 26120,
            name_th: "โพธิ์แทน",
            name_en: "Pho Thaen",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260405,
            zip_code: 26120,
            name_th: "บางสมบูรณ์",
            name_en: "Bang Sombun",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260406,
            zip_code: 26120,
            name_th: "ทรายมูล",
            name_en: "Sai Mun",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260407,
            zip_code: 26120,
            name_th: "บางปลากด",
            name_en: "Bang Pla Kot",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260408,
            zip_code: 26120,
            name_th: "บางลูกเสือ",
            name_en: "Bang Luk Suea",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260409,
            zip_code: 26120,
            name_th: "องครักษ์",
            name_en: "Ongkharak",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260410,
            zip_code: 26120,
            name_th: "ชุมพล",
            name_en: "Chumphon",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 260411,
            zip_code: 26120,
            name_th: "คลองใหญ่",
            name_en: "Khlong Yai",
            amphure_id: 2604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 18,
    name_th: "สระแก้ว",
    name_en: "Sa Kaeo",
    geography_id: 5,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 2701,
        name_th: "เมืองสระแก้ว",
        name_en: "Mueang Sa Kaeo",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270101,
            zip_code: 27000,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270102,
            zip_code: 27000,
            name_th: "บ้านแก้ง",
            name_en: "Ban Kaeng",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270103,
            zip_code: 27000,
            name_th: "ศาลาลำดวน",
            name_en: "Sala Lamduan",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270104,
            zip_code: 27000,
            name_th: "โคกปี่ฆ้อง",
            name_en: "Khok Pi Khong",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270105,
            zip_code: 27000,
            name_th: "ท่าแยก",
            name_en: "Tha Yaek",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270106,
            zip_code: 27000,
            name_th: "ท่าเกษม",
            name_en: "Tha Kasem",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270108,
            zip_code: 27000,
            name_th: "สระขวัญ",
            name_en: "Sa Khwan",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270111,
            zip_code: 27000,
            name_th: "หนองบอน",
            name_en: "Nong Bon",
            amphure_id: 2701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2702,
        name_th: "คลองหาด",
        name_en: "Khlong Hat",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270201,
            zip_code: 27260,
            name_th: "คลองหาด",
            name_en: "Khlong Hat",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270202,
            zip_code: 27260,
            name_th: "ไทยอุดม",
            name_en: "Thai Udom",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270203,
            zip_code: 27260,
            name_th: "ซับมะกรูด",
            name_en: "Sap Makrut",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270204,
            zip_code: 27260,
            name_th: "ไทรเดี่ยว",
            name_en: "Sai Diao",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270205,
            zip_code: 27260,
            name_th: "คลองไก่เถื่อน",
            name_en: "Khlong Kai Thuean",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270206,
            zip_code: 27260,
            name_th: "เบญจขร",
            name_en: "Benchakhon",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270207,
            zip_code: 27260,
            name_th: "ไทรทอง",
            name_en: "Sai Thong",
            amphure_id: 2702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2703,
        name_th: "ตาพระยา",
        name_en: "Ta Phraya",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270301,
            zip_code: 27180,
            name_th: "ตาพระยา",
            name_en: "Ta Phraya",
            amphure_id: 2703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270302,
            zip_code: 27180,
            name_th: "ทัพเสด็จ",
            name_en: "Thap Sadet",
            amphure_id: 2703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270306,
            zip_code: 27180,
            name_th: "ทัพราช",
            name_en: "Thap Rat",
            amphure_id: 2703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270307,
            zip_code: 27180,
            name_th: "ทัพไทย",
            name_en: "Thap Thai",
            amphure_id: 2703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270309,
            zip_code: 27180,
            name_th: "โคคลาน",
            name_en: "Kho Khlan",
            amphure_id: 2703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2704,
        name_th: "วังน้ำเย็น",
        name_en: "Wang Nam Yen",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270401,
            zip_code: 27210,
            name_th: "วังน้ำเย็น",
            name_en: "Wang Nam Yen",
            amphure_id: 2704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270403,
            zip_code: 27210,
            name_th: "ตาหลังใน",
            name_en: "Ta Lang Nai",
            amphure_id: 2704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270405,
            zip_code: 27210,
            name_th: "คลองหินปูน",
            name_en: "Khlong Hin Pun",
            amphure_id: 2704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270406,
            zip_code: 27210,
            name_th: "ทุ่งมหาเจริญ",
            name_en: "Thung Maha Charoen",
            amphure_id: 2704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2705,
        name_th: "วัฒนานคร",
        name_en: "Watthana Nakhon",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270501,
            zip_code: 27160,
            name_th: "วัฒนานคร",
            name_en: "Watthana Nakhon",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270502,
            zip_code: 27160,
            name_th: "ท่าเกวียน",
            name_en: "Tha Kwian",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270503,
            zip_code: 27160,
            name_th: "ผักขะ",
            name_en: "Phak Kha",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270504,
            zip_code: 27160,
            name_th: "โนนหมากเค็ง",
            name_en: "Non Mak Kheng",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270505,
            zip_code: 27160,
            name_th: "หนองน้ำใส",
            name_en: "Nong Nam Sai",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270506,
            zip_code: 27160,
            name_th: "ช่องกุ่ม",
            name_en: "Chong Kum",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270507,
            zip_code: 27160,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270508,
            zip_code: 27160,
            name_th: "แซร์ออ",
            name_en: "Sae-o",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270509,
            zip_code: 27160,
            name_th: "หนองหมากฝ้าย",
            name_en: "Nong Mak Fai",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270510,
            zip_code: 27160,
            name_th: "หนองตะเคียนบอน",
            name_en: "Nong Takhian Bon",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270511,
            zip_code: 27160,
            name_th: "ห้วยโจด",
            name_en: "Huai Chot",
            amphure_id: 2705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2706,
        name_th: "อรัญประเทศ",
        name_en: "Aranyaprathet",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270601,
            zip_code: 27120,
            name_th: "อรัญประเทศ",
            name_en: "Aranprathet",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270602,
            zip_code: 27120,
            name_th: "เมืองไผ่",
            name_en: "Mueang Phai",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270603,
            zip_code: 27120,
            name_th: "หันทราย",
            name_en: "Han Sai",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270604,
            zip_code: 27120,
            name_th: "คลองน้ำใส",
            name_en: "Khlong Nam Sai",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270605,
            zip_code: 27120,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270606,
            zip_code: 27120,
            name_th: "ป่าไร่",
            name_en: "Pa Rai",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270607,
            zip_code: 27120,
            name_th: "ทับพริก",
            name_en: "Thap Phrik",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270608,
            zip_code: 27120,
            name_th: "บ้านใหม่หนองไทร",
            name_en: "Ban Mai Nong Sai",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270609,
            zip_code: 27120,
            name_th: "ผ่านศึก",
            name_en: "Phan Suek",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270610,
            zip_code: 27120,
            name_th: "หนองสังข์",
            name_en: "Nong Sang",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270611,
            zip_code: 27120,
            name_th: "คลองทับจันทร์",
            name_en: "Khlong Thap Chan",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270612,
            zip_code: 27120,
            name_th: "ฟากห้วย",
            name_en: "Fak Huai",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270613,
            zip_code: 27120,
            name_th: "บ้านด่าน",
            name_en: "Ban Dan",
            amphure_id: 2706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2707,
        name_th: "เขาฉกรรจ์",
        name_en: "Khao Chakan",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270701,
            zip_code: 27000,
            name_th: "เขาฉกรรจ์",
            name_en: "Khao Chakan",
            amphure_id: 2707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270702,
            zip_code: 27000,
            name_th: "หนองหว้า",
            name_en: "Nong Wa",
            amphure_id: 2707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270703,
            zip_code: 27000,
            name_th: "พระเพลิง",
            name_en: "Phra Phloeng",
            amphure_id: 2707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270704,
            zip_code: 27000,
            name_th: "เขาสามสิบ",
            name_en: "Khao Sam Sip",
            amphure_id: 2707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2708,
        name_th: "โคกสูง",
        name_en: "Khok Sung",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270801,
            zip_code: 27120,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 2708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270802,
            zip_code: 27180,
            name_th: "หนองม่วง",
            name_en: "Nong Muang",
            amphure_id: 2708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270803,
            zip_code: 27180,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 2708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270804,
            zip_code: 27120,
            name_th: "โนนหมากมุ่น",
            name_en: "Non Mak Mun",
            amphure_id: 2708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 2709,
        name_th: "วังสมบูรณ์",
        name_en: "Wang Sombun",
        province_id: 18,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 270901,
            zip_code: 27250,
            name_th: "วังสมบูรณ์",
            name_en: "Wang Sombun",
            amphure_id: 2709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270902,
            zip_code: 27250,
            name_th: "วังใหม่",
            name_en: "Wang Mai",
            amphure_id: 2709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 270903,
            zip_code: 27250,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 2709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 19,
    name_th: "นครราชสีมา",
    name_en: "Nakhon Ratchasima",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3001,
        name_th: "เมืองนครราชสีมา",
        name_en: "Mueang Nakhon Ratchasima",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300101,
            zip_code: 30000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300102,
            zip_code: 30000,
            name_th: "โพธิ์กลาง",
            name_en: "Pho Klang",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300103,
            zip_code: 30000,
            name_th: "หนองจะบก",
            name_en: "Nong Chabok",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300104,
            zip_code: 30310,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300105,
            zip_code: 30000,
            name_th: "มะเริง",
            name_en: "Maroeng",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300106,
            zip_code: 30000,
            name_th: "หนองระเวียง",
            name_en: "Nong Rawiang",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300107,
            zip_code: 30000,
            name_th: "ปรุใหญ่",
            name_en: "Pru Yai",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300108,
            zip_code: 30000,
            name_th: "หมื่นไวย",
            name_en: "Muen Wai",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300109,
            zip_code: 30000,
            name_th: "พลกรัง",
            name_en: "Phon Krang",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300110,
            zip_code: 30000,
            name_th: "หนองไผ่ล้อม",
            name_en: "Nong Phai Lom",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300111,
            zip_code: 30000,
            name_th: "หัวทะเล",
            name_en: "Hua Thale",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300112,
            zip_code: 30000,
            name_th: "บ้านเกาะ",
            name_en: "Ban Ko",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300113,
            zip_code: 30000,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300114,
            zip_code: 30000,
            name_th: "พุดซา",
            name_en: "Phutsa",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300115,
            zip_code: 30310,
            name_th: "บ้านโพธิ์",
            name_en: "Ban Pho",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300116,
            zip_code: 30310,
            name_th: "จอหอ",
            name_en: "Cho Ho",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300117,
            zip_code: 30280,
            name_th: "โคกกรวด",
            name_en: "Khok Kruat",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300118,
            zip_code: 30000,
            name_th: "ไชยมงคล",
            name_en: "Chai Mongkhon",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300119,
            zip_code: 30000,
            name_th: "หนองบัวศาลา",
            name_en: "Nong Bua Sala",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300120,
            zip_code: 30000,
            name_th: "สุรนารี",
            name_en: "Suranari",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300121,
            zip_code: 30000,
            name_th: "สีมุม",
            name_en: "Si Mum",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300122,
            zip_code: 30310,
            name_th: "ตลาด",
            name_en: "Talat",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300123,
            zip_code: 30000,
            name_th: "พะเนา",
            name_en: "Phanao",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300124,
            zip_code: 30000,
            name_th: "หนองกระทุ่ม",
            name_en: "Nong Krathum",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300125,
            zip_code: 30310,
            name_th: "หนองไข่น้ำ",
            name_en: "Nong Khai Nam",
            amphure_id: 3001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3002,
        name_th: "ครบุรี",
        name_en: "Khon Buri",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300201,
            zip_code: 30250,
            name_th: "แชะ",
            name_en: "Chae",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300202,
            zip_code: 30250,
            name_th: "เฉลียง",
            name_en: "Chaliang",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300203,
            zip_code: 30250,
            name_th: "ครบุรี",
            name_en: "Khon Buri",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300204,
            zip_code: 30250,
            name_th: "โคกกระชาย",
            name_en: "Khok Krachai",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300205,
            zip_code: 30250,
            name_th: "จระเข้หิน",
            name_en: "Chorakhe Hin",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300206,
            zip_code: 30250,
            name_th: "มาบตะโกเอน",
            name_en: "Map Tako En",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300207,
            zip_code: 30250,
            name_th: "อรพิมพ์",
            name_en: "Oraphim",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300208,
            zip_code: 30250,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300209,
            zip_code: 30250,
            name_th: "ลำเพียก",
            name_en: "Lam Phiak",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300210,
            zip_code: 30250,
            name_th: "ครบุรีใต้",
            name_en: "Khon Buri Tai",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300211,
            zip_code: 30250,
            name_th: "ตะแบกบาน",
            name_en: "Tabaek Ban",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300212,
            zip_code: 30250,
            name_th: "สระว่านพระยา",
            name_en: "Sa Wan Phraya",
            amphure_id: 3002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3003,
        name_th: "เสิงสาง",
        name_en: "Soeng Sang",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300301,
            zip_code: 30330,
            name_th: "เสิงสาง",
            name_en: "Soeng Sang",
            amphure_id: 3003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300302,
            zip_code: 30330,
            name_th: "สระตะเคียน",
            name_en: "Sa Takhian",
            amphure_id: 3003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300303,
            zip_code: 30330,
            name_th: "โนนสมบูรณ์",
            name_en: "Non Sombun",
            amphure_id: 3003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300304,
            zip_code: 30330,
            name_th: "กุดโบสถ์",
            name_en: "Kut Bot",
            amphure_id: 3003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300305,
            zip_code: 30330,
            name_th: "สุขไพบูลย์",
            name_en: "Suk Phaibun",
            amphure_id: 3003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300306,
            zip_code: 30330,
            name_th: "บ้านราษฎร์",
            name_en: "Ban Rat",
            amphure_id: 3003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3004,
        name_th: "คง",
        name_en: "Khong",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300401,
            zip_code: 30260,
            name_th: "เมืองคง",
            name_en: "Mueang Khong",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300402,
            zip_code: 30260,
            name_th: "คูขาด",
            name_en: "Khu Khat",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300403,
            zip_code: 30260,
            name_th: "เทพาลัย",
            name_en: "Thephalai",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300404,
            zip_code: 30260,
            name_th: "ตาจั่น",
            name_en: "Ta Chan",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300405,
            zip_code: 30260,
            name_th: "บ้านปรางค์",
            name_en: "Ban Prang",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300406,
            zip_code: 30260,
            name_th: "หนองมะนาว",
            name_en: "Nong Manao",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300407,
            zip_code: 30260,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300408,
            zip_code: 30260,
            name_th: "โนนเต็ง",
            name_en: "Non Teng",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300409,
            zip_code: 30260,
            name_th: "ดอนใหญ่",
            name_en: "Don Yai",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300410,
            zip_code: 30260,
            name_th: "ขามสมบูรณ์",
            name_en: "Kham Sombun",
            amphure_id: 3004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3005,
        name_th: "บ้านเหลื่อม",
        name_en: "Ban Lueam",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300501,
            zip_code: 30350,
            name_th: "บ้านเหลื่อม",
            name_en: "Ban Lueam",
            amphure_id: 3005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300502,
            zip_code: 30350,
            name_th: "วังโพธิ์",
            name_en: "Wang Pho",
            amphure_id: 3005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300503,
            zip_code: 30350,
            name_th: "โคกกระเบื้อง",
            name_en: "Khok Krabueang",
            amphure_id: 3005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300504,
            zip_code: 30350,
            name_th: "ช่อระกา",
            name_en: "Cho Raka",
            amphure_id: 3005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3006,
        name_th: "จักราช",
        name_en: "Chakkarat",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300601,
            zip_code: 30230,
            name_th: "จักราช",
            name_en: "Chakkarat",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300602,
            zip_code: 30230,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300603,
            zip_code: 30230,
            name_th: "ทองหลาง",
            name_en: "Thonglang",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300604,
            zip_code: 30230,
            name_th: "สีสุก",
            name_en: "Si Suk",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300605,
            zip_code: 30230,
            name_th: "หนองขาม",
            name_en: "Nong Kham",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300606,
            zip_code: 30230,
            name_th: "หนองงูเหลือม",
            name_en: "Nong Ngu Luam",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300607,
            zip_code: 30230,
            name_th: "หนองพลวง",
            name_en: "Nong Phluang",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300608,
            zip_code: 30230,
            name_th: "หนองยาง",
            name_en: "Nong Yang",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300609,
            zip_code: 30230,
            name_th: "พระพุทธ",
            name_en: "Phra Phut",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300610,
            zip_code: 30230,
            name_th: "ศรีละกอ",
            name_en: "Si Lako",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300611,
            zip_code: 30230,
            name_th: "คลองเมือง",
            name_en: "Khlong Mueang",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300612,
            zip_code: 30230,
            name_th: "ช้างทอง",
            name_en: "Chang Thong",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300613,
            zip_code: 30230,
            name_th: "หินโคน",
            name_en: "Hin Khon",
            amphure_id: 3006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3007,
        name_th: "โชคชัย",
        name_en: "Chok Chai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300701,
            zip_code: 30190,
            name_th: "กระโทก",
            name_en: "Krathok",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300702,
            zip_code: 30190,
            name_th: "พลับพลา",
            name_en: "Phlapphla",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300703,
            zip_code: 30190,
            name_th: "ท่าอ่าง",
            name_en: "Tha Ang",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300704,
            zip_code: 30190,
            name_th: "ทุ่งอรุณ",
            name_en: "Thung Arun",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300705,
            zip_code: 30190,
            name_th: "ท่าลาดขาว",
            name_en: "Tha Lat Khao",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300706,
            zip_code: 30190,
            name_th: "ท่าจะหลุง",
            name_en: "Tha Chalung",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300707,
            zip_code: 30190,
            name_th: "ท่าเยี่ยม",
            name_en: "Tha Yiam",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300708,
            zip_code: 30190,
            name_th: "โชคชัย",
            name_en: "Chok Chai",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300709,
            zip_code: 30190,
            name_th: "ละลมใหม่พัฒนา",
            name_en: "Lalom Mai Phatthana",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300710,
            zip_code: 30190,
            name_th: "ด่านเกวียน",
            name_en: "Dan Kwian",
            amphure_id: 3007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3008,
        name_th: "ด่านขุนทด",
        name_en: "Dan Khun Thot",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300801,
            zip_code: 30210,
            name_th: "กุดพิมาน",
            name_en: "Kut Phiman",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300802,
            zip_code: 30210,
            name_th: "ด่านขุนทด",
            name_en: "Dan Khun Thot",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300803,
            zip_code: 30210,
            name_th: "ด่านนอก",
            name_en: "Dan Nok",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300804,
            zip_code: 30210,
            name_th: "ด่านใน",
            name_en: "Dan Nai",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300805,
            zip_code: 30210,
            name_th: "ตะเคียน",
            name_en: "Takhian",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300806,
            zip_code: 30210,
            name_th: "บ้านเก่า",
            name_en: "Ban Kao",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300807,
            zip_code: 36220,
            name_th: "บ้านแปรง",
            name_en: "Ban Praeng",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300808,
            zip_code: 30210,
            name_th: "พันชนะ",
            name_en: "Phan Chana",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300809,
            zip_code: 30210,
            name_th: "สระจรเข้",
            name_en: "Sa Chorakhe",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300810,
            zip_code: 30210,
            name_th: "หนองกราด",
            name_en: "Nong Krat",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300811,
            zip_code: 30210,
            name_th: "หนองบัวตะเกียด",
            name_en: "Nong Bua Takiat",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300812,
            zip_code: 30210,
            name_th: "หนองบัวละคร",
            name_en: "Nong Bua Lakhon",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300813,
            zip_code: 30210,
            name_th: "หินดาด",
            name_en: "Hin Dat",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300815,
            zip_code: 30210,
            name_th: "ห้วยบง",
            name_en: "Huai Bong",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300817,
            zip_code: 30210,
            name_th: "โนนเมืองพัฒนา",
            name_en: "Non Mueang Phatthana",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300818,
            zip_code: 36220,
            name_th: "หนองไทร",
            name_en: "Nong Sai",
            amphure_id: 3008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3009,
        name_th: "โนนไทย",
        name_en: "Non Thai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 300901,
            zip_code: 30220,
            name_th: "โนนไทย",
            name_en: "Non Thai",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300902,
            zip_code: 30220,
            name_th: "ด่านจาก",
            name_en: "Dan Chak",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300903,
            zip_code: 30220,
            name_th: "กำปัง",
            name_en: "Kampang",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300904,
            zip_code: 30220,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300905,
            zip_code: 30220,
            name_th: "ค้างพลู",
            name_en: "Khang Phlu",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300906,
            zip_code: 30220,
            name_th: "บ้านวัง",
            name_en: "Ban Wang",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300907,
            zip_code: 30220,
            name_th: "บัลลังก์",
            name_en: "Banlang",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300908,
            zip_code: 30220,
            name_th: "สายออ",
            name_en: "Sai O",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300909,
            zip_code: 30220,
            name_th: "ถนนโพธิ์",
            name_en: "Thanon Pho",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300910,
            zip_code: 30220,
            name_th: "พังเทียม",
            name_en: "Phung Theam",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300911,
            zip_code: 30220,
            name_th: "สระพระ",
            name_en: "Sra Pra",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300912,
            zip_code: 30220,
            name_th: "ทัพรั้ง",
            name_en: "Tup Rang",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300913,
            zip_code: 30220,
            name_th: "หนองหอย",
            name_en: "Nong Hoi",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300914,
            zip_code: 30220,
            name_th: "มะค่า",
            name_en: "Makha",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 300915,
            zip_code: 30220,
            name_th: "มาบกราด",
            name_en: "Mab Krad",
            amphure_id: 3009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3010,
        name_th: "โนนสูง",
        name_en: "Non Sung",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301001,
            zip_code: 30160,
            name_th: "โนนสูง",
            name_en: "Non Sung",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301002,
            zip_code: 30160,
            name_th: "ใหม่",
            name_en: "Mai",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301003,
            zip_code: 30160,
            name_th: "โตนด",
            name_en: "Tanot",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301004,
            zip_code: 30160,
            name_th: "บิง",
            name_en: "Bing",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301005,
            zip_code: 30160,
            name_th: "ดอนชมพู",
            name_en: "Don Chomphu",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301006,
            zip_code: 30240,
            name_th: "ธารปราสาท",
            name_en: "Than Prasat",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301007,
            zip_code: 30160,
            name_th: "หลุมข้าว",
            name_en: "Lum Khao",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301008,
            zip_code: 30160,
            name_th: "มะค่า",
            name_en: "Makha",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301009,
            zip_code: 30160,
            name_th: "พลสงคราม",
            name_en: "Phon Songkhram",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301010,
            zip_code: 30160,
            name_th: "จันอัด",
            name_en: "Chan-at",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301011,
            zip_code: 30160,
            name_th: "ขามเฒ่า",
            name_en: "Kham Thao",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301012,
            zip_code: 30160,
            name_th: "ด่านคล้า",
            name_en: "Dan Khla",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301013,
            zip_code: 30160,
            name_th: "ลำคอหงษ์",
            name_en: "Lam Kho Hong",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301014,
            zip_code: 30160,
            name_th: "เมืองปราสาท",
            name_en: "Mueang Prasat",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301015,
            zip_code: 30160,
            name_th: "ดอนหวาย",
            name_en: "Don Wai",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301016,
            zip_code: 30160,
            name_th: "ลำมูล",
            name_en: "Lam Mun",
            amphure_id: 3010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3011,
        name_th: "ขามสะแกแสง",
        name_en: "Kham Sakaesaeng",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301101,
            zip_code: 30290,
            name_th: "ขามสะแกแสง",
            name_en: "Kham Sakaesaeng",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301102,
            zip_code: 30290,
            name_th: "โนนเมือง",
            name_en: "Non Mueang",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301103,
            zip_code: 30290,
            name_th: "เมืองนาท",
            name_en: "Mueang Nat",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301104,
            zip_code: 30290,
            name_th: "ชีวึก",
            name_en: "Chiwuek",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301105,
            zip_code: 30290,
            name_th: "พะงาด",
            name_en: "Pha-ngat",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301106,
            zip_code: 30290,
            name_th: "หนองหัวฟาน",
            name_en: "Nong Hua Fan",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301107,
            zip_code: 30290,
            name_th: "เมืองเกษตร",
            name_en: "Mueang Kaset",
            amphure_id: 3011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3012,
        name_th: "บัวใหญ่",
        name_en: "Bua Yai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301201,
            zip_code: 30120,
            name_th: "บัวใหญ่",
            name_en: "Bua Yai",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301203,
            zip_code: 30120,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301204,
            zip_code: 30120,
            name_th: "เสมาใหญ่",
            name_en: "Sema Yai",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301206,
            zip_code: 30120,
            name_th: "ดอนตะหนิน",
            name_en: "Don Tanin",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301207,
            zip_code: 30120,
            name_th: "หนองบัวสะอาด",
            name_en: "Nong Bua Sa-at",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301208,
            zip_code: 30120,
            name_th: "โนนทองหลาง",
            name_en: "Non Thonglang",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301209,
            zip_code: 30120,
            name_th: "หนองหว้า",
            name_en: "Nong Wha",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301210,
            zip_code: 30120,
            name_th: "บัวลาย",
            name_en: "Bua Lai",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301211,
            zip_code: 30120,
            name_th: "สีดา",
            name_en: "Sri Da",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301212,
            zip_code: 30120,
            name_th: "โพนทอง",
            name_en: "Pon Thong",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301214,
            zip_code: 30120,
            name_th: "กุดจอก",
            name_en: "Kut Chok",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301215,
            zip_code: 30120,
            name_th: "ด่านช้าง",
            name_en: "Dan Chang",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301216,
            zip_code: 30120,
            name_th: "โนนจาน",
            name_en: "Non Jan",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301218,
            zip_code: 30120,
            name_th: "สามเมือง",
            name_en: "Sam Muang",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301220,
            zip_code: 30120,
            name_th: "ขุนทอง",
            name_en: "Khun Thong",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301221,
            zip_code: 30120,
            name_th: "หนองตาดใหญ่",
            name_en: "Nong Tad Yai",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301222,
            zip_code: 30120,
            name_th: "เมืองพะไล",
            name_en: "Mueang Pa Lai",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301223,
            zip_code: 30120,
            name_th: "โนนประดู่",
            name_en: "Non Pradoo",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301224,
            zip_code: 30120,
            name_th: "หนองแจ้งใหญ่",
            name_en: "Nong Chaeng Yai",
            amphure_id: 3012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3013,
        name_th: "ประทาย",
        name_en: "Prathai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301301,
            zip_code: 30180,
            name_th: "ประทาย",
            name_en: "Prathai",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301303,
            zip_code: 30180,
            name_th: "กระทุ่มราย",
            name_en: "Krathum Rai",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301304,
            zip_code: 30180,
            name_th: "วังไม้แดง",
            name_en: "Wang Mai Daeng",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301306,
            zip_code: 30180,
            name_th: "ตลาดไทร",
            name_en: "Talat Sai",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301307,
            zip_code: 30180,
            name_th: "หนองพลวง",
            name_en: "Nong Phluang",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301308,
            zip_code: 30180,
            name_th: "หนองค่าย",
            name_en: "Nong Khai",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301309,
            zip_code: 30180,
            name_th: "หันห้วยทราย",
            name_en: "Han Huai Sai",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301310,
            zip_code: 30180,
            name_th: "ดอนมัน",
            name_en: "Don Man",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301313,
            zip_code: 30180,
            name_th: "นางรำ",
            name_en: "Nang Ram",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301314,
            zip_code: 30180,
            name_th: "โนนเพ็ด",
            name_en: "Non Phet",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301315,
            zip_code: 30180,
            name_th: "ทุ่งสว่าง",
            name_en: "Thung Sawang",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301317,
            zip_code: 30180,
            name_th: "โคกกลาง",
            name_en: "Khok Klang",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301318,
            zip_code: 30180,
            name_th: "เมืองโดน",
            name_en: "Mueang Don",
            amphure_id: 3013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3014,
        name_th: "ปักธงชัย",
        name_en: "Pak Thong Chai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301401,
            zip_code: 30150,
            name_th: "เมืองปัก",
            name_en: "Mueang Pak",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301402,
            zip_code: 30150,
            name_th: "ตะคุ",
            name_en: "Takhu",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301403,
            zip_code: 30150,
            name_th: "โคกไทย",
            name_en: "Khok Thai",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301404,
            zip_code: 30150,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301405,
            zip_code: 30150,
            name_th: "ตะขบ",
            name_en: "Takhop",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301406,
            zip_code: 30150,
            name_th: "นกออก",
            name_en: "Nok Ok",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301407,
            zip_code: 30150,
            name_th: "ดอน",
            name_en: "Don",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301409,
            zip_code: 30150,
            name_th: "ตูม",
            name_en: "Tum",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301410,
            zip_code: 30150,
            name_th: "งิ้ว",
            name_en: "Ngio",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301411,
            zip_code: 30150,
            name_th: "สะแกราช",
            name_en: "Sakae Rat",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301412,
            zip_code: 30150,
            name_th: "ลำนางแก้ว",
            name_en: "Lam Nang Kaeo",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301416,
            zip_code: 30150,
            name_th: "ภูหลวง",
            name_en: "Phu Luang",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301417,
            zip_code: 30150,
            name_th: "ธงชัยเหนือ",
            name_en: "Thong Chai Nuea",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301418,
            zip_code: 30150,
            name_th: "สุขเกษม",
            name_en: "Suk Kasem",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301419,
            zip_code: 30150,
            name_th: "เกษมทรัพย์",
            name_en: "Kasem Sap",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301420,
            zip_code: 30150,
            name_th: "บ่อปลาทอง",
            name_en: "Bo Pla Thong",
            amphure_id: 3014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3015,
        name_th: "พิมาย",
        name_en: "Phimai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301501,
            zip_code: 30110,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301502,
            zip_code: 30110,
            name_th: "สัมฤทธิ์",
            name_en: "Samrit",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301503,
            zip_code: 30110,
            name_th: "โบสถ์",
            name_en: "Bot",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301504,
            zip_code: 30110,
            name_th: "กระเบื้องใหญ่",
            name_en: "Krabueang Yai",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301505,
            zip_code: 30110,
            name_th: "ท่าหลวง",
            name_en: "Tha Luang",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301506,
            zip_code: 30110,
            name_th: "รังกาใหญ่",
            name_en: "Rang Ka Yai",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301507,
            zip_code: 30110,
            name_th: "ชีวาน",
            name_en: "Chiwan",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301508,
            zip_code: 30110,
            name_th: "นิคมสร้างตนเอง",
            name_en: "Nikhom Sang Ton-eng",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301509,
            zip_code: 30110,
            name_th: "กระชอน",
            name_en: "Krachon",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301510,
            zip_code: 30110,
            name_th: "ดงใหญ่",
            name_en: "Dong Yai",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301511,
            zip_code: 30110,
            name_th: "ธารละหลอด",
            name_en: "Than Lalot",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301512,
            zip_code: 30110,
            name_th: "หนองระเวียง",
            name_en: "Nong Rawiang",
            amphure_id: 3015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3016,
        name_th: "ห้วยแถลง",
        name_en: "Huai Thalaeng",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301601,
            zip_code: 30240,
            name_th: "ห้วยแถลง",
            name_en: "Huai Thalaeng",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301602,
            zip_code: 30240,
            name_th: "ทับสวาย",
            name_en: "Thap Sawai",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301603,
            zip_code: 30240,
            name_th: "เมืองพลับพลา",
            name_en: "Mueang Phlapphla",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301604,
            zip_code: 30240,
            name_th: "หลุ่งตะเคียน",
            name_en: "Lung Takhian",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301605,
            zip_code: 30240,
            name_th: "หินดาด",
            name_en: "Hin Dat",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301606,
            zip_code: 30240,
            name_th: "งิ้ว",
            name_en: "Ngio",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301607,
            zip_code: 30240,
            name_th: "กงรถ",
            name_en: "Kong Rot",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301608,
            zip_code: 30240,
            name_th: "หลุ่งประดู่",
            name_en: "Lung Pradu",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301609,
            zip_code: 30240,
            name_th: "ตะโก",
            name_en: "Tako",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301610,
            zip_code: 30240,
            name_th: "ห้วยแคน",
            name_en: "Huai Khaen",
            amphure_id: 3016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3017,
        name_th: "ชุมพวง",
        name_en: "Chum Phuang",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301701,
            zip_code: 30270,
            name_th: "ชุมพวง",
            name_en: "Chum Phuang",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301702,
            zip_code: 30270,
            name_th: "ประสุข",
            name_en: "Prasuk",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301703,
            zip_code: 30270,
            name_th: "ท่าลาด",
            name_en: "Tha Lat",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301704,
            zip_code: 30270,
            name_th: "สาหร่าย",
            name_en: "Sarai",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301705,
            zip_code: 30270,
            name_th: "ตลาดไทร",
            name_en: "Talat Sai",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301706,
            zip_code: 30270,
            name_th: "ช่องแมว",
            name_en: "Chong Maew",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301707,
            zip_code: 30270,
            name_th: "ขุย",
            name_en: "Kui",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301710,
            zip_code: 30270,
            name_th: "โนนรัง",
            name_en: "Non Rang",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301711,
            zip_code: 30270,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301714,
            zip_code: 30270,
            name_th: "หนองหลัก",
            name_en: "Nong Lak",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301715,
            zip_code: 30270,
            name_th: "ไพล",
            name_en: "Plai",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301716,
            zip_code: 30270,
            name_th: "โนนตูม",
            name_en: "Non Tum",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301717,
            zip_code: 30270,
            name_th: "โนนยอ",
            name_en: "Non Yo",
            amphure_id: 3017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3018,
        name_th: "สูงเนิน",
        name_en: "Sung Noen",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301801,
            zip_code: 30170,
            name_th: "สูงเนิน",
            name_en: "Sung Noen",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301802,
            zip_code: 30170,
            name_th: "เสมา",
            name_en: "Sema",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301803,
            zip_code: 30170,
            name_th: "โคราช",
            name_en: "Khorat",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301804,
            zip_code: 30170,
            name_th: "บุ่งขี้เหล็ก",
            name_en: "Bung Khilek",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301805,
            zip_code: 30170,
            name_th: "โนนค่า",
            name_en: "Non Kha",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301806,
            zip_code: 30170,
            name_th: "โค้งยาง",
            name_en: "Khong Yang",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301807,
            zip_code: 30170,
            name_th: "มะเกลือเก่า",
            name_en: "Makluea Kao",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301808,
            zip_code: 30170,
            name_th: "มะเกลือใหม่",
            name_en: "Makluea Mai",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301809,
            zip_code: 30380,
            name_th: "นากลาง",
            name_en: "Na Klang",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301810,
            zip_code: 30380,
            name_th: "หนองตะไก้",
            name_en: "Nong Takai",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301811,
            zip_code: 30380,
            name_th: "กุดจิก",
            name_en: "Kut Chik",
            amphure_id: 3018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3019,
        name_th: "ขามทะเลสอ",
        name_en: "Kham Thale So",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 301901,
            zip_code: 30280,
            name_th: "ขามทะเลสอ",
            name_en: "Kham Thale So",
            amphure_id: 3019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301902,
            zip_code: 30280,
            name_th: "โป่งแดง",
            name_en: "Pong Daeng",
            amphure_id: 3019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301903,
            zip_code: 30280,
            name_th: "พันดุง",
            name_en: "Phan Dung",
            amphure_id: 3019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301904,
            zip_code: 30280,
            name_th: "หนองสรวง",
            name_en: "Nong Suang",
            amphure_id: 3019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 301905,
            zip_code: 30280,
            name_th: "บึงอ้อ",
            name_en: "Bueng O",
            amphure_id: 3019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3020,
        name_th: "สีคิ้ว",
        name_en: "Sikhio",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302001,
            zip_code: 30140,
            name_th: "สีคิ้ว",
            name_en: "Sikhio",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302002,
            zip_code: 30140,
            name_th: "บ้านหัน",
            name_en: "Ban Han",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302003,
            zip_code: 30140,
            name_th: "กฤษณา",
            name_en: "Kritsana",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302004,
            zip_code: 30340,
            name_th: "ลาดบัวขาว",
            name_en: "Lat Bua Khao",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302005,
            zip_code: 30140,
            name_th: "หนองหญ้าขาว",
            name_en: "Nong Ya Khao",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302006,
            zip_code: 30140,
            name_th: "กุดน้อย",
            name_en: "Kut Noi",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302007,
            zip_code: 30140,
            name_th: "หนองน้ำใส",
            name_en: "Nong Nam Sai",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302008,
            zip_code: 30140,
            name_th: "วังโรงใหญ่",
            name_en: "Wang Rong Yai",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302009,
            zip_code: 30140,
            name_th: "มิตรภาพ",
            name_en: "Mittraphap",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302010,
            zip_code: 30340,
            name_th: "คลองไผ่",
            name_en: "Khlong Phai",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302011,
            zip_code: 30140,
            name_th: "ดอนเมือง",
            name_en: "Don Mueang",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302012,
            zip_code: 30140,
            name_th: "หนองบัวน้อย",
            name_en: "Nong Bua Noi",
            amphure_id: 3020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3021,
        name_th: "ปากช่อง",
        name_en: "Pak Chong",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302101,
            zip_code: 30130,
            name_th: "ปากช่อง",
            name_en: "Pak Chong",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302102,
            zip_code: 30320,
            name_th: "กลางดง",
            name_en: "Klang Dong",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302103,
            zip_code: 30130,
            name_th: "จันทึก",
            name_en: "Chanthuek",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302104,
            zip_code: 30130,
            name_th: "วังกะทะ",
            name_en: "Wang Katha",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302105,
            zip_code: 30130,
            name_th: "หมูสี",
            name_en: "Mu Si",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302106,
            zip_code: 30130,
            name_th: "หนองสาหร่าย",
            name_en: "Nong Sarai",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302107,
            zip_code: 30130,
            name_th: "ขนงพระ",
            name_en: "Khanong Phra",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302108,
            zip_code: 30130,
            name_th: "โป่งตาลอง",
            name_en: "Pong Talong",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302109,
            zip_code: 30130,
            name_th: "คลองม่วง",
            name_en: "Khlong Muang",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302110,
            zip_code: 30130,
            name_th: "หนองน้ำแดง",
            name_en: "Nong Nam Daeng",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302111,
            zip_code: 30130,
            name_th: "วังไทร",
            name_en: "Wang Sai",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302112,
            zip_code: 30320,
            name_th: "พญาเย็น",
            name_en: "Phaya Yen",
            amphure_id: 3021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3022,
        name_th: "หนองบุญมาก",
        name_en: "Nong Bunnak",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302201,
            zip_code: 30410,
            name_th: "หนองบุนนาก",
            name_en: "Nong Bunnak",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302202,
            zip_code: 30410,
            name_th: "สารภี",
            name_en: "Saraphi",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302203,
            zip_code: 30410,
            name_th: "ไทยเจริญ",
            name_en: "Thai Charoen",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302204,
            zip_code: 30410,
            name_th: "หนองหัวแรต",
            name_en: "Nong Hua Raet",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302205,
            zip_code: 30410,
            name_th: "แหลมทอง",
            name_en: "Laem Thong",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302206,
            zip_code: 30410,
            name_th: "หนองตะไก้",
            name_en: "Nong Takai",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302207,
            zip_code: 30410,
            name_th: "ลุงเขว้า",
            name_en: "Lung Khwao",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302208,
            zip_code: 30410,
            name_th: "หนองไม้ไผ่",
            name_en: "Nong Mai Phai",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302209,
            zip_code: 30410,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 3022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3023,
        name_th: "แก้งสนามนาง",
        name_en: "Kaeng Sanam Nang",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302301,
            zip_code: 30440,
            name_th: "แก้งสนามนาง",
            name_en: "Kaeng Sanam Nang",
            amphure_id: 3023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302302,
            zip_code: 30440,
            name_th: "โนนสำราญ",
            name_en: "Non Samran",
            amphure_id: 3023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302303,
            zip_code: 30440,
            name_th: "บึงพะไล",
            name_en: "Bueng Phalai",
            amphure_id: 3023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302304,
            zip_code: 30440,
            name_th: "สีสุก",
            name_en: "Si Suk",
            amphure_id: 3023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302305,
            zip_code: 30440,
            name_th: "บึงสำโรง",
            name_en: "Bueng Samrong",
            amphure_id: 3023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3024,
        name_th: "โนนแดง",
        name_en: "Non Daeng",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302401,
            zip_code: 30360,
            name_th: "โนนแดง",
            name_en: "Non Daeng",
            amphure_id: 3024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302402,
            zip_code: 30360,
            name_th: "โนนตาเถร",
            name_en: "Non Ta Then",
            amphure_id: 3024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302403,
            zip_code: 30360,
            name_th: "สำพะเนียง",
            name_en: "Samphaniang",
            amphure_id: 3024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302404,
            zip_code: 30360,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 3024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302405,
            zip_code: 30360,
            name_th: "ดอนยาวใหญ่",
            name_en: "Don Yao Yai",
            amphure_id: 3024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3025,
        name_th: "วังน้ำเขียว",
        name_en: "Wang Nam Khiao",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302501,
            zip_code: 30370,
            name_th: "วังน้ำเขียว",
            name_en: "Wang Nam Khiao",
            amphure_id: 3025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302502,
            zip_code: 30370,
            name_th: "วังหมี",
            name_en: "Wang Mi",
            amphure_id: 3025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302503,
            zip_code: 30150,
            name_th: "ระเริง",
            name_en: "Raroeng",
            amphure_id: 3025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302504,
            zip_code: 30370,
            name_th: "อุดมทรัพย์",
            name_en: "Udom Sap",
            amphure_id: 3025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302505,
            zip_code: 30370,
            name_th: "ไทยสามัคคี",
            name_en: "Thai Samakkhi",
            amphure_id: 3025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3026,
        name_th: "เทพารักษ์",
        name_en: "Thepharak",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302601,
            zip_code: 30210,
            name_th: "สำนักตะคร้อ",
            name_en: "Samnak Takhro",
            amphure_id: 3026,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302602,
            zip_code: 30210,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 3026,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302603,
            zip_code: 30210,
            name_th: "บึงปรือ",
            name_en: "Bueng Prue",
            amphure_id: 3026,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302604,
            zip_code: 30210,
            name_th: "วังยายทอง",
            name_en: "Wang Yai Thong",
            amphure_id: 3026,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3027,
        name_th: "เมืองยาง",
        name_en: "Mueang Yang",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302701,
            zip_code: 30270,
            name_th: "เมืองยาง",
            name_en: "Mueang Yang",
            amphure_id: 3027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302702,
            zip_code: 30270,
            name_th: "กระเบื้องนอก",
            name_en: "Krabueang Nok",
            amphure_id: 3027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302703,
            zip_code: 30270,
            name_th: "ละหานปลาค้าว",
            name_en: "Lahan Pla Khao",
            amphure_id: 3027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302704,
            zip_code: 30270,
            name_th: "โนนอุดม",
            name_en: "Non Udom",
            amphure_id: 3027,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3028,
        name_th: "พระทองคำ",
        name_en: "Phra Thong Kham",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302801,
            zip_code: 30220,
            name_th: "สระพระ",
            name_en: "Sa Phra",
            amphure_id: 3028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302802,
            zip_code: 30220,
            name_th: "มาบกราด",
            name_en: "Map Krat",
            amphure_id: 3028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302803,
            zip_code: 30220,
            name_th: "พังเทียม",
            name_en: "Phang Thiam",
            amphure_id: 3028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302804,
            zip_code: 30220,
            name_th: "ทัพรั้ง",
            name_en: "Thap Rang",
            amphure_id: 3028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302805,
            zip_code: 30220,
            name_th: "หนองหอย",
            name_en: "Nong Hoi",
            amphure_id: 3028,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3029,
        name_th: "ลำทะเมนชัย",
        name_en: "Lam Thamenchai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 302901,
            zip_code: 30270,
            name_th: "ขุย",
            name_en: "Khui",
            amphure_id: 3029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302902,
            zip_code: 30270,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 3029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302903,
            zip_code: 30270,
            name_th: "ช่องแมว",
            name_en: "Chong Maeo",
            amphure_id: 3029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 302904,
            zip_code: 30270,
            name_th: "ไพล",
            name_en: "Phlai",
            amphure_id: 3029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3030,
        name_th: "บัวลาย",
        name_en: "Bua Lai",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 303001,
            zip_code: 30120,
            name_th: "เมืองพะไล",
            name_en: "Mueang Phalai",
            amphure_id: 3030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303002,
            zip_code: 30120,
            name_th: "โนนจาน",
            name_en: "Non Chan",
            amphure_id: 3030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303003,
            zip_code: 30120,
            name_th: "บัวลาย",
            name_en: "Bua Lai",
            amphure_id: 3030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303004,
            zip_code: 30120,
            name_th: "หนองหว้า",
            name_en: "Nong Wa",
            amphure_id: 3030,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3031,
        name_th: "สีดา",
        name_en: "Sida",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 303101,
            zip_code: 30430,
            name_th: "สีดา",
            name_en: "Sida",
            amphure_id: 3031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303102,
            zip_code: 30430,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 3031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303103,
            zip_code: 30430,
            name_th: "โนนประดู่",
            name_en: "Non Pradu",
            amphure_id: 3031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303104,
            zip_code: 30430,
            name_th: "สามเมือง",
            name_en: "Sam Mueang",
            amphure_id: 3031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303105,
            zip_code: 30430,
            name_th: "หนองตาดใหญ่",
            name_en: "Nong Tat Yai",
            amphure_id: 3031,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3032,
        name_th: "เฉลิมพระเกียรติ",
        name_en: "Chaloem Phra Kiat",
        province_id: 19,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 303201,
            zip_code: 30230,
            name_th: "ช้างทอง",
            name_en: "Chang Thong",
            amphure_id: 3032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303202,
            zip_code: 30230,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 3032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303203,
            zip_code: 30230,
            name_th: "พระพุทธ",
            name_en: "Phra Phut",
            amphure_id: 3032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303204,
            zip_code: 30000,
            name_th: "หนองงูเหลือม",
            name_en: "Nong Ngu Lueam",
            amphure_id: 3032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 303205,
            zip_code: 30230,
            name_th: "หนองยาง",
            name_en: "Nong Yang",
            amphure_id: 3032,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 20,
    name_th: "บุรีรัมย์",
    name_en: "Buri Ram",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3101,
        name_th: "เมืองบุรีรัมย์",
        name_en: "Mueang Buri Ram",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310101,
            zip_code: 31000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310102,
            zip_code: 31000,
            name_th: "อิสาณ",
            name_en: "Isan",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310103,
            zip_code: 31000,
            name_th: "เสม็ด",
            name_en: "Samet",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310104,
            zip_code: 31000,
            name_th: "บ้านบัว",
            name_en: "Ban Bua",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310105,
            zip_code: 31000,
            name_th: "สะแกโพรง",
            name_en: "Sakae Phrong",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310106,
            zip_code: 31000,
            name_th: "สวายจีก",
            name_en: "Sawai Chik",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310108,
            zip_code: 31000,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310112,
            zip_code: 31000,
            name_th: "พระครู",
            name_en: "Phra Khru",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310113,
            zip_code: 31000,
            name_th: "ถลุงเหล็ก",
            name_en: "Thalung Lek",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310114,
            zip_code: 31000,
            name_th: "หนองตาด",
            name_en: "Nong Tat",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310117,
            zip_code: 31000,
            name_th: "ลุมปุ๊ก",
            name_en: "Lumpuk",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310118,
            zip_code: 31000,
            name_th: "สองห้อง",
            name_en: "Song Hong",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310119,
            zip_code: 31000,
            name_th: "บัวทอง",
            name_en: "Bua Thong",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310120,
            zip_code: 31000,
            name_th: "ชุมเห็ด",
            name_en: "Chum Het",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310122,
            zip_code: 31000,
            name_th: "หลักเขต",
            name_en: "Lak Khet",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310125,
            zip_code: 31000,
            name_th: "สะแกซำ",
            name_en: "Sakae Sam",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310126,
            zip_code: 31000,
            name_th: "กลันทา",
            name_en: "Kalantha",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310127,
            zip_code: 31000,
            name_th: "กระสัง",
            name_en: "Krasang",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310128,
            zip_code: 31000,
            name_th: "เมืองฝาง",
            name_en: "Mueang Fang",
            amphure_id: 3101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3102,
        name_th: "คูเมือง",
        name_en: "Khu Mueang",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310201,
            zip_code: 31190,
            name_th: "คูเมือง",
            name_en: "Khu Mueang",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310202,
            zip_code: 31190,
            name_th: "ปะเคียบ",
            name_en: "Pakhiap",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310203,
            zip_code: 31190,
            name_th: "บ้านแพ",
            name_en: "Ban Phae",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310204,
            zip_code: 31190,
            name_th: "พรสำราญ",
            name_en: "Phon Samran",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310205,
            zip_code: 31190,
            name_th: "หินเหล็กไฟ",
            name_en: "Hin Lek Fai",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310206,
            zip_code: 31190,
            name_th: "ตูมใหญ่",
            name_en: "Tum Yai",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310207,
            zip_code: 31190,
            name_th: "หนองขมาร",
            name_en: "Nong Khaman",
            amphure_id: 3102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3103,
        name_th: "กระสัง",
        name_en: "Krasang",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310301,
            zip_code: 31160,
            name_th: "กระสัง",
            name_en: "Krasang",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310302,
            zip_code: 31160,
            name_th: "ลำดวน",
            name_en: "Lamduan",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310303,
            zip_code: 31160,
            name_th: "สองชั้น",
            name_en: "Song Chan",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310304,
            zip_code: 31160,
            name_th: "สูงเนิน",
            name_en: "Sung Noen",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310305,
            zip_code: 31160,
            name_th: "หนองเต็ง",
            name_en: "Nong Teng",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310306,
            zip_code: 31160,
            name_th: "เมืองไผ่",
            name_en: "Mueang Phai",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310307,
            zip_code: 31160,
            name_th: "ชุมแสง",
            name_en: "Chum Saeng",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310308,
            zip_code: 31160,
            name_th: "บ้านปรือ",
            name_en: "Ban Prue",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310309,
            zip_code: 31160,
            name_th: "ห้วยสำราญ",
            name_en: "Huai Samran",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310310,
            zip_code: 31160,
            name_th: "กันทรารมย์",
            name_en: "Kanthararom",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310311,
            zip_code: 31160,
            name_th: "ศรีภูมิ",
            name_en: "Si Phum",
            amphure_id: 3103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3104,
        name_th: "นางรอง",
        name_en: "Nang Rong",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310401,
            zip_code: 31110,
            name_th: "นางรอง",
            name_en: "Nang Rong",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310403,
            zip_code: 31110,
            name_th: "สะเดา",
            name_en: "Sadao",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310405,
            zip_code: 31110,
            name_th: "ชุมแสง",
            name_en: "Chum Saeng",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310406,
            zip_code: 31110,
            name_th: "หนองโบสถ์",
            name_en: "Nong Bot",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310408,
            zip_code: 31110,
            name_th: "หนองกง",
            name_en: "Nong Kong",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310413,
            zip_code: 31110,
            name_th: "ถนนหัก",
            name_en: "Thanon Hak",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310414,
            zip_code: 31110,
            name_th: "หนองไทร",
            name_en: "Nong Sai",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310415,
            zip_code: 31110,
            name_th: "ก้านเหลือง",
            name_en: "Kan Lueang",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310416,
            zip_code: 31110,
            name_th: "บ้านสิงห์",
            name_en: "Ban Sing",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310417,
            zip_code: 31110,
            name_th: "ลำไทรโยง",
            name_en: "Lam Sai Yong",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310418,
            zip_code: 31110,
            name_th: "ทรัพย์พระยา",
            name_en: "Sap Phraya",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310424,
            zip_code: 31110,
            name_th: "หนองยายพิมพ์",
            name_en: "Nong Yai Phim",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310425,
            zip_code: 31110,
            name_th: "หัวถนน",
            name_en: "Hua Thanon",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310426,
            zip_code: 31110,
            name_th: "ทุ่งแสงทอง",
            name_en: "Thung Saeng Thong",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310427,
            zip_code: 31110,
            name_th: "หนองโสน",
            name_en: "Nong Sano",
            amphure_id: 3104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3105,
        name_th: "หนองกี่",
        name_en: "Nong Ki",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310501,
            zip_code: 31210,
            name_th: "หนองกี่",
            name_en: "Nong Ki",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310502,
            zip_code: 31210,
            name_th: "เย้ยปราสาท",
            name_en: "Yoei Prasat",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310503,
            zip_code: 31210,
            name_th: "เมืองไผ่",
            name_en: "Mueang Phai",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310504,
            zip_code: 31210,
            name_th: "ดอนอะราง",
            name_en: "Don Arang",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310505,
            zip_code: 31210,
            name_th: "โคกสว่าง",
            name_en: "Khok Sawang",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310506,
            zip_code: 31210,
            name_th: "ทุ่งกระตาดพัฒนา",
            name_en: "Thung Kratat Phatthana",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310507,
            zip_code: 31210,
            name_th: "ทุ่งกระเต็น",
            name_en: "Thung Kraten",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310508,
            zip_code: 31210,
            name_th: "ท่าโพธิ์ชัย",
            name_en: "Tha Pho Chai",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310509,
            zip_code: 31210,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310510,
            zip_code: 31210,
            name_th: "บุกระสัง",
            name_en: "Bu Krasang",
            amphure_id: 3105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3106,
        name_th: "ละหานทราย",
        name_en: "Lahan Sai",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310601,
            zip_code: 31170,
            name_th: "ละหานทราย",
            name_en: "Lahan Sai",
            amphure_id: 3106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310603,
            zip_code: 31170,
            name_th: "ตาจง",
            name_en: "Ta Chong",
            amphure_id: 3106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310604,
            zip_code: 31170,
            name_th: "สำโรงใหม่",
            name_en: "Samrong Mai",
            amphure_id: 3106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310607,
            zip_code: 31170,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 3106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310610,
            zip_code: 31170,
            name_th: "หนองตะครอง",
            name_en: "Nong Trakhrong",
            amphure_id: 3106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310611,
            zip_code: 31170,
            name_th: "โคกว่าน",
            name_en: "Khok Wan",
            amphure_id: 3106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3107,
        name_th: "ประโคนชัย",
        name_en: "Prakhon Chai",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310701,
            zip_code: 31140,
            name_th: "ประโคนชัย",
            name_en: "Prakhon Chai",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310702,
            zip_code: 31140,
            name_th: "แสลงโทน",
            name_en: "Salaeng Thon",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310703,
            zip_code: 31140,
            name_th: "บ้านไทร",
            name_en: "Ban Sai",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310705,
            zip_code: 31140,
            name_th: "ละเวี้ย",
            name_en: "Lawia",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310706,
            zip_code: 31140,
            name_th: "จรเข้มาก",
            name_en: "Chorakhe Mak",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310707,
            zip_code: 31140,
            name_th: "ปังกู",
            name_en: "Pang Ku",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310708,
            zip_code: 31140,
            name_th: "โคกย่าง",
            name_en: "Khok Yang",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310710,
            zip_code: 31140,
            name_th: "โคกม้า",
            name_en: "Khok Ma",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310713,
            zip_code: 31140,
            name_th: "ไพศาล",
            name_en: "Phaisan",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310714,
            zip_code: 31140,
            name_th: "ตะโกตาพิ",
            name_en: "Tako Taphi",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310715,
            zip_code: 31140,
            name_th: "เขาคอก",
            name_en: "Khao Khok",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310716,
            zip_code: 31140,
            name_th: "หนองบอน",
            name_en: "Nong Bon",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310718,
            zip_code: 31140,
            name_th: "โคกมะขาม",
            name_en: "Khok Makham",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310719,
            zip_code: 31140,
            name_th: "โคกตูม",
            name_en: "Khok Tum",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310720,
            zip_code: 31140,
            name_th: "ประทัดบุ",
            name_en: "Prathat Bu",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310721,
            zip_code: 31140,
            name_th: "สี่เหลี่ยม",
            name_en: "Si Liam",
            amphure_id: 3107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3108,
        name_th: "บ้านกรวด",
        name_en: "Ban Kruat",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310801,
            zip_code: 31180,
            name_th: "บ้านกรวด",
            name_en: "Ban Kruat",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310802,
            zip_code: 31180,
            name_th: "โนนเจริญ",
            name_en: "Non Charoen",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310803,
            zip_code: 31180,
            name_th: "หนองไม้งาม",
            name_en: "Nong Mai Ngam",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310804,
            zip_code: 31180,
            name_th: "ปราสาท",
            name_en: "Prasat",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310805,
            zip_code: 31180,
            name_th: "สายตะกู",
            name_en: "Sai Taku",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310806,
            zip_code: 31180,
            name_th: "หินลาด",
            name_en: "Hin Lat",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310807,
            zip_code: 31180,
            name_th: "บึงเจริญ",
            name_en: "Bueng Charoen",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310808,
            zip_code: 31180,
            name_th: "จันทบเพชร",
            name_en: "Chanthop Phet",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310809,
            zip_code: 31180,
            name_th: "เขาดินเหนือ",
            name_en: "Khao Din Nuea",
            amphure_id: 3108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3109,
        name_th: "พุทไธสง",
        name_en: "Phutthaisong",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 310901,
            zip_code: 31120,
            name_th: "พุทไธสง",
            name_en: "Phutthaisong",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310902,
            zip_code: 31120,
            name_th: "มะเฟือง",
            name_en: "Mafueang",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310903,
            zip_code: 31120,
            name_th: "บ้านจาน",
            name_en: "Ban Chan",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310906,
            zip_code: 31120,
            name_th: "บ้านเป้า",
            name_en: "Ban Pao",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310907,
            zip_code: 31120,
            name_th: "บ้านแวง",
            name_en: "Ban Waeng",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310909,
            zip_code: 31120,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 310910,
            zip_code: 31120,
            name_th: "หายโศก",
            name_en: "Hai Sok",
            amphure_id: 3109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3110,
        name_th: "ลำปลายมาศ",
        name_en: "Lam Plai Mat",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311001,
            zip_code: 31130,
            name_th: "ลำปลายมาศ",
            name_en: "Lam Plai Mat",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311002,
            zip_code: 31130,
            name_th: "หนองคู",
            name_en: "Nong Khu",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311003,
            zip_code: 31130,
            name_th: "แสลงพัน",
            name_en: "Salaeng Phan",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311004,
            zip_code: 31130,
            name_th: "ทะเมนชัย",
            name_en: "Thamen Chai",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311005,
            zip_code: 31130,
            name_th: "ตลาดโพธิ์",
            name_en: "Talat Pho",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311006,
            zip_code: 31130,
            name_th: "หนองกะทิง",
            name_en: "Nong Kathing",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311007,
            zip_code: 31130,
            name_th: "โคกกลาง",
            name_en: "Khok Klang",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311008,
            zip_code: 31130,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311009,
            zip_code: 31130,
            name_th: "เมืองแฝก",
            name_en: "Mueang Faek",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311010,
            zip_code: 31130,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311011,
            zip_code: 31130,
            name_th: "ผไทรินทร์",
            name_en: "Phathairin",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311012,
            zip_code: 31130,
            name_th: "โคกล่าม",
            name_en: "Khok Lam",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311013,
            zip_code: 31130,
            name_th: "หินโคน",
            name_en: "Hin Khon",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311014,
            zip_code: 31130,
            name_th: "หนองบัวโคก",
            name_en: "Nong Bua Khok",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311015,
            zip_code: 31130,
            name_th: "บุโพธิ์",
            name_en: "Bu Pho",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311016,
            zip_code: 31130,
            name_th: "หนองโดน",
            name_en: "Nong Don",
            amphure_id: 3110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3111,
        name_th: "สตึก",
        name_en: "Satuek",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311101,
            zip_code: 31150,
            name_th: "สตึก",
            name_en: "Satuek",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311102,
            zip_code: 31150,
            name_th: "นิคม",
            name_en: "Nikhom",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311103,
            zip_code: 31150,
            name_th: "ทุ่งวัง",
            name_en: "Thung Wang",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311104,
            zip_code: 31150,
            name_th: "เมืองแก",
            name_en: "Mueang Kae",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311105,
            zip_code: 31150,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311106,
            zip_code: 31150,
            name_th: "ร่อนทอง",
            name_en: "Ron Thong",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311109,
            zip_code: 31150,
            name_th: "ดอนมนต์",
            name_en: "Don Mon",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311110,
            zip_code: 31150,
            name_th: "ชุมแสง",
            name_en: "Chum Saeng",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311111,
            zip_code: 31150,
            name_th: "ท่าม่วง",
            name_en: "Tha Muang",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311112,
            zip_code: 31150,
            name_th: "สะแก",
            name_en: "Sakae",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311114,
            zip_code: 31150,
            name_th: "สนามชัย",
            name_en: "Sanam Chai",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311115,
            zip_code: 31150,
            name_th: "กระสัง",
            name_en: "Krasang",
            amphure_id: 3111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3112,
        name_th: "ปะคำ",
        name_en: "Pakham",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311201,
            zip_code: 31220,
            name_th: "ปะคำ",
            name_en: "Pakham",
            amphure_id: 3112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311202,
            zip_code: 31220,
            name_th: "ไทยเจริญ",
            name_en: "Thai Charoen",
            amphure_id: 3112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311203,
            zip_code: 31220,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 3112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311204,
            zip_code: 31220,
            name_th: "โคกมะม่วง",
            name_en: "Khok Mamuang",
            amphure_id: 3112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311205,
            zip_code: 31220,
            name_th: "หูทำนบ",
            name_en: "Hu Thamnop",
            amphure_id: 3112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3113,
        name_th: "นาโพธิ์",
        name_en: "Na Pho",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311301,
            zip_code: 31230,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 3113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311302,
            zip_code: 31230,
            name_th: "บ้านคู",
            name_en: "Ban Khu",
            amphure_id: 3113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311303,
            zip_code: 31230,
            name_th: "บ้านดู่",
            name_en: "Ban Du",
            amphure_id: 3113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311304,
            zip_code: 31230,
            name_th: "ดอนกอก",
            name_en: "Don Kok",
            amphure_id: 3113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311305,
            zip_code: 31230,
            name_th: "ศรีสว่าง",
            name_en: "Si Sawang",
            amphure_id: 3113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3114,
        name_th: "หนองหงส์",
        name_en: "Nong Hong",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311401,
            zip_code: 31240,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311402,
            zip_code: 31240,
            name_th: "ห้วยหิน",
            name_en: "Huai Hin",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311403,
            zip_code: 31240,
            name_th: "ไทยสามัคคี",
            name_en: "Thai Samakkhi",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311404,
            zip_code: 31240,
            name_th: "หนองชัยศรี",
            name_en: "Nong Chai Si",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311405,
            zip_code: 31240,
            name_th: "เสาเดียว",
            name_en: "Sao Diao",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311406,
            zip_code: 31240,
            name_th: "เมืองฝ้าย",
            name_en: "Mueang Fai",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311407,
            zip_code: 31240,
            name_th: "สระทอง",
            name_en: "Sa Thong",
            amphure_id: 3114,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3115,
        name_th: "พลับพลาชัย",
        name_en: "Phlapphla Chai",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311501,
            zip_code: 31250,
            name_th: "จันดุม",
            name_en: "Chan Dum",
            amphure_id: 3115,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311502,
            zip_code: 31250,
            name_th: "โคกขมิ้น",
            name_en: "Khok Khamin",
            amphure_id: 3115,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311503,
            zip_code: 31250,
            name_th: "ป่าชัน",
            name_en: "Pa Chan",
            amphure_id: 3115,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311504,
            zip_code: 31250,
            name_th: "สะเดา",
            name_en: "Sadao",
            amphure_id: 3115,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311505,
            zip_code: 31250,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3115,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3116,
        name_th: "ห้วยราช",
        name_en: "Huai Rat",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311601,
            zip_code: 31000,
            name_th: "ห้วยราช",
            name_en: "Huai Rat",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311602,
            zip_code: 31000,
            name_th: "สามแวง",
            name_en: "Sam Waeng",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311603,
            zip_code: 31000,
            name_th: "ตาเสา",
            name_en: "Ta Sao",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311604,
            zip_code: 31000,
            name_th: "บ้านตะโก",
            name_en: "Ban Tako",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311605,
            zip_code: 31000,
            name_th: "สนวน",
            name_en: "Sanuan",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311606,
            zip_code: 31000,
            name_th: "โคกเหล็ก",
            name_en: "Khok Lek",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311607,
            zip_code: 31000,
            name_th: "เมืองโพธิ์",
            name_en: "Mueang Pho",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311608,
            zip_code: 31000,
            name_th: "ห้วยราชา",
            name_en: "Huai Racha",
            amphure_id: 3116,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3117,
        name_th: "โนนสุวรรณ",
        name_en: "Non Suwan",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311701,
            zip_code: 31110,
            name_th: "โนนสุวรรณ",
            name_en: "Non Suwan",
            amphure_id: 3117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311702,
            zip_code: 31110,
            name_th: "ทุ่งจังหัน",
            name_en: "Thung Changhan",
            amphure_id: 3117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311703,
            zip_code: 31110,
            name_th: "โกรกแก้ว",
            name_en: "Krok Kaeo",
            amphure_id: 3117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311704,
            zip_code: 31110,
            name_th: "ดงอีจาน",
            name_en: "Dong I Chan",
            amphure_id: 3117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3118,
        name_th: "ชำนิ",
        name_en: "Chamni",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311801,
            zip_code: 31110,
            name_th: "ชำนิ",
            name_en: "Chamni",
            amphure_id: 3118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311802,
            zip_code: 31110,
            name_th: "หนองปล่อง",
            name_en: "Nong Plong",
            amphure_id: 3118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311803,
            zip_code: 31110,
            name_th: "เมืองยาง",
            name_en: "Mueang Yang",
            amphure_id: 3118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311804,
            zip_code: 31110,
            name_th: "ช่อผกา",
            name_en: "Cho Phaka",
            amphure_id: 3118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311805,
            zip_code: 31110,
            name_th: "ละลวด",
            name_en: "Laluat",
            amphure_id: 3118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311806,
            zip_code: 31110,
            name_th: "โคกสนวน",
            name_en: "Khok Sanuan",
            amphure_id: 3118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3119,
        name_th: "บ้านใหม่ไชยพจน์",
        name_en: "Ban Mai Chaiyaphot",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 311901,
            zip_code: 31120,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 3119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311902,
            zip_code: 31120,
            name_th: "ทองหลาง",
            name_en: "Thonglang",
            amphure_id: 3119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311903,
            zip_code: 31120,
            name_th: "แดงใหญ่",
            name_en: "Daeng Yai",
            amphure_id: 3119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311904,
            zip_code: 31120,
            name_th: "กู่สวนแตง",
            name_en: "Ku Suan Taeng",
            amphure_id: 3119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 311905,
            zip_code: 31120,
            name_th: "หนองเยือง",
            name_en: "Nong Yueang",
            amphure_id: 3119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3120,
        name_th: "โนนดินแดง",
        name_en: "Din Daeng",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 312001,
            zip_code: 31260,
            name_th: "โนนดินแดง",
            name_en: "Non Din Daeng",
            amphure_id: 3120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312002,
            zip_code: 31260,
            name_th: "ส้มป่อย",
            name_en: "Som Poi",
            amphure_id: 3120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312003,
            zip_code: 31260,
            name_th: "ลำนางรอง",
            name_en: "Lam Nang Rong",
            amphure_id: 3120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3121,
        name_th: "บ้านด่าน",
        name_en: "Ban Dan",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 312101,
            zip_code: 31000,
            name_th: "บ้านด่าน",
            name_en: "Ban Dan",
            amphure_id: 3121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312102,
            zip_code: 31000,
            name_th: "ปราสาท",
            name_en: "Prasat",
            amphure_id: 3121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312103,
            zip_code: 31000,
            name_th: "วังเหนือ",
            name_en: "Wang Nuea",
            amphure_id: 3121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312104,
            zip_code: 31000,
            name_th: "โนนขวาง",
            name_en: "Non Khwang",
            amphure_id: 3121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3122,
        name_th: "แคนดง",
        name_en: "Khaen Dong",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 312201,
            zip_code: 31150,
            name_th: "แคนดง",
            name_en: "Khaen Dong",
            amphure_id: 3122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312202,
            zip_code: 31150,
            name_th: "ดงพลอง",
            name_en: "Dong Phlong",
            amphure_id: 3122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312203,
            zip_code: 31150,
            name_th: "สระบัว",
            name_en: "Sa Bua",
            amphure_id: 3122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312204,
            zip_code: 31150,
            name_th: "หัวฝาย",
            name_en: "Hua Fai",
            amphure_id: 3122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3123,
        name_th: "เฉลิมพระเกียรติ",
        name_en: "Chaloem Phra Kiat",
        province_id: 20,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 312301,
            zip_code: 31110,
            name_th: "เจริญสุข",
            name_en: "Charoen Suk",
            amphure_id: 3123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312302,
            zip_code: 31110,
            name_th: "ตาเป๊ก",
            name_en: "Ta Pek",
            amphure_id: 3123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312303,
            zip_code: 31110,
            name_th: "อีสานเขต",
            name_en: "Isan Khet",
            amphure_id: 3123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312304,
            zip_code: 31170,
            name_th: "ถาวร",
            name_en: "Thawon",
            amphure_id: 3123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 312305,
            zip_code: 31170,
            name_th: "ยายแย้มวัฒนา",
            name_en: "Yai Yaem Watthana",
            amphure_id: 3123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 21,
    name_th: "สุรินทร์",
    name_en: "Surin",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3201,
        name_th: "เมืองสุรินทร์",
        name_en: "Mueang Surin",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320101,
            zip_code: 32000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320102,
            zip_code: 32000,
            name_th: "ตั้งใจ",
            name_en: "Tang Chai",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320103,
            zip_code: 32000,
            name_th: "เพี้ยราม",
            name_en: "Phia Ram",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320104,
            zip_code: 32000,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320105,
            zip_code: 32000,
            name_th: "ท่าสว่าง",
            name_en: "Tha Sawang",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320106,
            zip_code: 32000,
            name_th: "สลักได",
            name_en: "Salakdai",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320107,
            zip_code: 32000,
            name_th: "ตาอ็อง",
            name_en: "Ta Ong",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320109,
            zip_code: 32000,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320110,
            zip_code: 32000,
            name_th: "แกใหญ่",
            name_en: "Kae Yai",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320111,
            zip_code: 32000,
            name_th: "นอกเมือง",
            name_en: "Nok Mueang",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320112,
            zip_code: 32000,
            name_th: "คอโค",
            name_en: "Kho Kho",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320113,
            zip_code: 32000,
            name_th: "สวาย",
            name_en: "Sawai",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320114,
            zip_code: 32000,
            name_th: "เฉนียง",
            name_en: "Chaniang",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320116,
            zip_code: 32000,
            name_th: "เทนมีย์",
            name_en: "Thenmi",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320118,
            zip_code: 32000,
            name_th: "นาบัว",
            name_en: "Na Bua",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320119,
            zip_code: 32000,
            name_th: "เมืองที",
            name_en: "Mueang Thi",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320120,
            zip_code: 32000,
            name_th: "ราม",
            name_en: "Ram",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320121,
            zip_code: 32000,
            name_th: "บุฤาษี",
            name_en: "Bu Ruesi",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320122,
            zip_code: 32000,
            name_th: "ตระแสง",
            name_en: "Trasaeng",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320125,
            zip_code: 32000,
            name_th: "แสลงพันธ์",
            name_en: "Salaeng Phan",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320126,
            zip_code: 32000,
            name_th: "กาเกาะ",
            name_en: "Ka Ko",
            amphure_id: 3201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3202,
        name_th: "ชุมพลบุรี",
        name_en: "Chumphon Buri",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320201,
            zip_code: 32190,
            name_th: "ชุมพลบุรี",
            name_en: "Chumphon Buri",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320202,
            zip_code: 32190,
            name_th: "นาหนองไผ่",
            name_en: "Na Nong Phai",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320203,
            zip_code: 32190,
            name_th: "ไพรขลา",
            name_en: "Phrai Khla",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320204,
            zip_code: 32190,
            name_th: "ศรีณรงค์",
            name_en: "Si Narong",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320205,
            zip_code: 32190,
            name_th: "ยะวึก",
            name_en: "Yawuek",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320206,
            zip_code: 32190,
            name_th: "เมืองบัว",
            name_en: "Mueang Bua",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320207,
            zip_code: 32190,
            name_th: "สระขุด",
            name_en: "Sa Khut",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320208,
            zip_code: 32190,
            name_th: "กระเบื้อง",
            name_en: "Krabueang",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320209,
            zip_code: 32190,
            name_th: "หนองเรือ",
            name_en: "Nong Ruea",
            amphure_id: 3202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3203,
        name_th: "ท่าตูม",
        name_en: "Tha Tum",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320301,
            zip_code: 32120,
            name_th: "ท่าตูม",
            name_en: "Tha Tum",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320302,
            zip_code: 32120,
            name_th: "กระโพ",
            name_en: "Krapho",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320303,
            zip_code: 32120,
            name_th: "พรมเทพ",
            name_en: "Phrom Thep",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320304,
            zip_code: 32120,
            name_th: "โพนครก",
            name_en: "Phon Khrok",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320305,
            zip_code: 32120,
            name_th: "เมืองแก",
            name_en: "Mueang Kae",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320306,
            zip_code: 32120,
            name_th: "บะ",
            name_en: "Ba",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320307,
            zip_code: 32120,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320308,
            zip_code: 32120,
            name_th: "บัวโคก",
            name_en: "Bua Khok",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320309,
            zip_code: 32120,
            name_th: "หนองเมธี",
            name_en: "Nong Methi",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320310,
            zip_code: 32120,
            name_th: "ทุ่งกุลา",
            name_en: "Thung Kula",
            amphure_id: 3203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3204,
        name_th: "จอมพระ",
        name_en: "Chom Phra",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320401,
            zip_code: 32180,
            name_th: "จอมพระ",
            name_en: "Chom Phra",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320402,
            zip_code: 32180,
            name_th: "เมืองลีง",
            name_en: "Mueang Ling",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320403,
            zip_code: 32180,
            name_th: "กระหาด",
            name_en: "Krahat",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320404,
            zip_code: 32180,
            name_th: "บุแกรง",
            name_en: "Bu Kraeng",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320405,
            zip_code: 32180,
            name_th: "หนองสนิท",
            name_en: "Nong Sanit",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320406,
            zip_code: 32180,
            name_th: "บ้านผือ",
            name_en: "Ban Phue",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320407,
            zip_code: 32180,
            name_th: "ลุ่มระวี",
            name_en: "Lum Rawi",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320408,
            zip_code: 32180,
            name_th: "ชุมแสง",
            name_en: "Chum Saeng",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320409,
            zip_code: 32180,
            name_th: "เป็นสุข",
            name_en: "Pen Suk",
            amphure_id: 3204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3205,
        name_th: "ปราสาท",
        name_en: "Prasat",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320501,
            zip_code: 32140,
            name_th: "กังแอน",
            name_en: "Kang-aen",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320502,
            zip_code: 32140,
            name_th: "ทมอ",
            name_en: "Thamo",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320503,
            zip_code: 32140,
            name_th: "ไพล",
            name_en: "Phlai",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320504,
            zip_code: 32140,
            name_th: "ปรือ",
            name_en: "Prue",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320505,
            zip_code: 32140,
            name_th: "ทุ่งมน",
            name_en: "Thung Mon",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320506,
            zip_code: 32140,
            name_th: "ตาเบา",
            name_en: "Ta Bao",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320507,
            zip_code: 32140,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320508,
            zip_code: 32140,
            name_th: "โคกยาง",
            name_en: "Khok Yang",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320509,
            zip_code: 32140,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320510,
            zip_code: 32140,
            name_th: "บ้านไทร",
            name_en: "Ban Sai",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320511,
            zip_code: 32140,
            name_th: "โชคนาสาม",
            name_en: "Chok Na Sam",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320512,
            zip_code: 32140,
            name_th: "เชื้อเพลิง",
            name_en: "Chuea Phloeng",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320513,
            zip_code: 32140,
            name_th: "ปราสาททนง",
            name_en: "Prasat Thanong",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320514,
            zip_code: 32140,
            name_th: "ตานี",
            name_en: "Tani",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320515,
            zip_code: 32140,
            name_th: "บ้านพลวง",
            name_en: "Ban Phluang",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320516,
            zip_code: 32140,
            name_th: "กันตวจระมวล",
            name_en: "Kantuat Ramuan",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320517,
            zip_code: 32140,
            name_th: "สมุด",
            name_en: "Samut",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320518,
            zip_code: 32140,
            name_th: "ประทัดบุ",
            name_en: "Prathat Bu",
            amphure_id: 3205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3206,
        name_th: "กาบเชิง",
        name_en: "Kap Choeng",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320601,
            zip_code: 32210,
            name_th: "กาบเชิง",
            name_en: "Kap Choeng",
            amphure_id: 3206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320604,
            zip_code: 32210,
            name_th: "คูตัน",
            name_en: "Khu Tan",
            amphure_id: 3206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320605,
            zip_code: 32210,
            name_th: "ด่าน",
            name_en: "Dan",
            amphure_id: 3206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320606,
            zip_code: 32210,
            name_th: "แนงมุด",
            name_en: "Naeng Mut",
            amphure_id: 3206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320607,
            zip_code: 32210,
            name_th: "โคกตะเคียน",
            name_en: "Khok Takhian",
            amphure_id: 3206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320610,
            zip_code: 32210,
            name_th: "ตะเคียน",
            name_en: "Takhian",
            amphure_id: 3206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3207,
        name_th: "รัตนบุรี",
        name_en: "Rattanaburi",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320701,
            zip_code: 32130,
            name_th: "รัตนบุรี",
            name_en: "Rattanaburi",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320702,
            zip_code: 32130,
            name_th: "ธาตุ",
            name_en: "That",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320703,
            zip_code: 32130,
            name_th: "แก",
            name_en: "Kae",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320704,
            zip_code: 32130,
            name_th: "ดอนแรด",
            name_en: "Don Raet",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320705,
            zip_code: 32130,
            name_th: "หนองบัวทอง",
            name_en: "Nong Bua Thong",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320706,
            zip_code: 32130,
            name_th: "หนองบัวบาน",
            name_en: "Nong Bua Ban",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320709,
            zip_code: 32130,
            name_th: "ไผ่",
            name_en: "Phai",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320711,
            zip_code: 32130,
            name_th: "เบิด",
            name_en: "Boet",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320713,
            zip_code: 32130,
            name_th: "น้ำเขียว",
            name_en: "Nam Khiao",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320714,
            zip_code: 32130,
            name_th: "กุดขาคีม",
            name_en: "Kut Kha Khim",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320715,
            zip_code: 32130,
            name_th: "ยางสว่าง",
            name_en: "Yang Sawang",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320716,
            zip_code: 32130,
            name_th: "ทับใหญ่",
            name_en: "Thap Ya",
            amphure_id: 3207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3208,
        name_th: "สนม",
        name_en: "Sanom",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320801,
            zip_code: 32160,
            name_th: "สนม",
            name_en: "Sanom",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320802,
            zip_code: 32160,
            name_th: "โพนโก",
            name_en: "Phon Ko",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320803,
            zip_code: 32160,
            name_th: "หนองระฆัง",
            name_en: "Nong Rakhang",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320804,
            zip_code: 32160,
            name_th: "นานวน",
            name_en: "Na Nuan",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320805,
            zip_code: 32160,
            name_th: "แคน",
            name_en: "Khaen",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320806,
            zip_code: 32160,
            name_th: "หัวงัว",
            name_en: "Hua Ngua",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320807,
            zip_code: 32160,
            name_th: "หนองอียอ",
            name_en: "Nong I Yo)",
            amphure_id: 3208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3209,
        name_th: "ศีขรภูมิ",
        name_en: "Sikhoraphum",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 320901,
            zip_code: 32110,
            name_th: "ระแงง",
            name_en: "Ra-ngaeng",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320902,
            zip_code: 32110,
            name_th: "ตรึม",
            name_en: "Truem",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320903,
            zip_code: 32110,
            name_th: "จารพัต",
            name_en: "Charaphat",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320904,
            zip_code: 32110,
            name_th: "ยาง",
            name_en: "Yang",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320905,
            zip_code: 32110,
            name_th: "แตล",
            name_en: "Taen",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320906,
            zip_code: 32110,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320907,
            zip_code: 32110,
            name_th: "คาละแมะ",
            name_en: "Khalamae",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320908,
            zip_code: 32110,
            name_th: "หนองเหล็ก",
            name_en: "Nong Lek",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320909,
            zip_code: 32110,
            name_th: "หนองขวาว",
            name_en: "Nong Khwao",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320910,
            zip_code: 32110,
            name_th: "ช่างปี่",
            name_en: "Chang Pi",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320911,
            zip_code: 32110,
            name_th: "กุดหวาย",
            name_en: "Kut Wai",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320912,
            zip_code: 32110,
            name_th: "ขวาวใหญ่",
            name_en: "Khwao Yai",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320913,
            zip_code: 32110,
            name_th: "นารุ่ง",
            name_en: "Na Rung",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320914,
            zip_code: 32110,
            name_th: "ตรมไพร",
            name_en: "Trom Phrai",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 320915,
            zip_code: 32110,
            name_th: "ผักไหม",
            name_en: "Phak Mai",
            amphure_id: 3209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3210,
        name_th: "สังขะ",
        name_en: "Sangkha",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321001,
            zip_code: 32150,
            name_th: "สังขะ",
            name_en: "Sangkha",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321002,
            zip_code: 32150,
            name_th: "ขอนแตก",
            name_en: "Khon Taek",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321006,
            zip_code: 32150,
            name_th: "ดม",
            name_en: "Dom",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321007,
            zip_code: 32150,
            name_th: "พระแก้ว",
            name_en: "Phra Kaeo",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321008,
            zip_code: 32150,
            name_th: "บ้านจารย์",
            name_en: "Ban Chan",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321009,
            zip_code: 32150,
            name_th: "กระเทียม",
            name_en: "Krathiam",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321010,
            zip_code: 32150,
            name_th: "สะกาด",
            name_en: "Sakat",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321011,
            zip_code: 32150,
            name_th: "ตาตุม",
            name_en: "Ta Tum",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321012,
            zip_code: 32150,
            name_th: "ทับทัน",
            name_en: "Thap Than",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321013,
            zip_code: 32150,
            name_th: "ตาคง",
            name_en: "Ta Khong",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321015,
            zip_code: 32150,
            name_th: "บ้านชบ",
            name_en: "Ban Chop",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321017,
            zip_code: 32150,
            name_th: "เทพรักษา",
            name_en: "Thep Raksa",
            amphure_id: 3210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3211,
        name_th: "ลำดวน",
        name_en: "Lamduan",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321101,
            zip_code: 32220,
            name_th: "ลำดวน",
            name_en: "Lamduan",
            amphure_id: 3211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321102,
            zip_code: 32220,
            name_th: "โชคเหนือ",
            name_en: "Chok Nuea",
            amphure_id: 3211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321103,
            zip_code: 32220,
            name_th: "อู่โลก",
            name_en: "U Lok",
            amphure_id: 3211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321104,
            zip_code: 32220,
            name_th: "ตรำดม",
            name_en: "Tram Dom",
            amphure_id: 3211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321105,
            zip_code: 32220,
            name_th: "ตระเปียงเตีย",
            name_en: "Trapiang Tia",
            amphure_id: 3211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3212,
        name_th: "สำโรงทาบ",
        name_en: "Samrong Thap",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321201,
            zip_code: 32170,
            name_th: "สำโรงทาบ",
            name_en: "Samrong Thap",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321202,
            zip_code: 32170,
            name_th: "หนองไผ่ล้อม",
            name_en: "Nong Phai Lom",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321203,
            zip_code: 32170,
            name_th: "กระออม",
            name_en: "Kra-om",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321204,
            zip_code: 32170,
            name_th: "หนองฮะ",
            name_en: "Nong Ha",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321205,
            zip_code: 32170,
            name_th: "ศรีสุข",
            name_en: "Si Suk",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321206,
            zip_code: 32170,
            name_th: "เกาะแก้ว",
            name_en: "Ko Kaeo",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321207,
            zip_code: 32170,
            name_th: "หมื่นศรี",
            name_en: "Muen Si",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321208,
            zip_code: 32170,
            name_th: "เสม็จ",
            name_en: "Samet",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321209,
            zip_code: 32170,
            name_th: "สะโน",
            name_en: "Sano",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321210,
            zip_code: 32170,
            name_th: "ประดู่",
            name_en: "Pradu",
            amphure_id: 3212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3213,
        name_th: "บัวเชด",
        name_en: "Buachet",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321301,
            zip_code: 32230,
            name_th: "บัวเชด",
            name_en: "Buachet",
            amphure_id: 3213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321302,
            zip_code: 32230,
            name_th: "สะเดา",
            name_en: "Sadao",
            amphure_id: 3213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321303,
            zip_code: 32230,
            name_th: "จรัส",
            name_en: "Charat",
            amphure_id: 3213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321304,
            zip_code: 32230,
            name_th: "ตาวัง",
            name_en: "Ta Wang",
            amphure_id: 3213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321305,
            zip_code: 32230,
            name_th: "อาโพน",
            name_en: "A Phon",
            amphure_id: 3213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321306,
            zip_code: 32230,
            name_th: "สำเภาลูน",
            name_en: "Samphao Lun",
            amphure_id: 3213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3214,
        name_th: "พนมดงรัก",
        name_en: "Phanom Dong Rak",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321401,
            zip_code: 32140,
            name_th: "บักได",
            name_en: "Bakdai",
            amphure_id: 3214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321402,
            zip_code: 32140,
            name_th: "โคกกลาง",
            name_en: "Khok Klang",
            amphure_id: 3214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321403,
            zip_code: 32140,
            name_th: "จีกแดก",
            name_en: "Chik Daek",
            amphure_id: 3214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321404,
            zip_code: 32140,
            name_th: "ตาเมียง",
            name_en: "Ta Miang",
            amphure_id: 3214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3215,
        name_th: "ศรีณรงค์",
        name_en: "Si Narong",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321501,
            zip_code: 32150,
            name_th: "ณรงค์",
            name_en: "Narong",
            amphure_id: 3215,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321502,
            zip_code: 32150,
            name_th: "แจนแวน",
            name_en: "Chaenwaen",
            amphure_id: 3215,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321503,
            zip_code: 32150,
            name_th: "ตรวจ",
            name_en: "Truat",
            amphure_id: 3215,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321504,
            zip_code: 32150,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 3215,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321505,
            zip_code: 32150,
            name_th: "ศรีสุข",
            name_en: "Si Suk",
            amphure_id: 3215,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3216,
        name_th: "เขวาสินรินทร์",
        name_en: "Khwao Sinarin",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321601,
            zip_code: 32000,
            name_th: "เขวาสินรินทร์",
            name_en: "Khwao Sinarin",
            amphure_id: 3216,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321602,
            zip_code: 32000,
            name_th: "บึง",
            name_en: "Bueng",
            amphure_id: 3216,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321603,
            zip_code: 32000,
            name_th: "ตากูก",
            name_en: "Ta Kuk",
            amphure_id: 3216,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321604,
            zip_code: 32000,
            name_th: "ปราสาททอง",
            name_en: "Prasat Thong",
            amphure_id: 3216,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321605,
            zip_code: 32000,
            name_th: "บ้านแร่",
            name_en: "Ban Rae",
            amphure_id: 3216,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3217,
        name_th: "โนนนารายณ์",
        name_en: "Non Narai",
        province_id: 21,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 321701,
            zip_code: 32130,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 3217,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321702,
            zip_code: 32130,
            name_th: "คำผง",
            name_en: "Kham Phong",
            amphure_id: 3217,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321703,
            zip_code: 32130,
            name_th: "โนน",
            name_en: "Non",
            amphure_id: 3217,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321704,
            zip_code: 32130,
            name_th: "ระเวียง",
            name_en: "Rawiang",
            amphure_id: 3217,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 321705,
            zip_code: 32130,
            name_th: "หนองเทพ",
            name_en: "Nong Thep",
            amphure_id: 3217,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 22,
    name_th: "ศรีสะเกษ",
    name_en: "Si Sa Ket",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3301,
        name_th: "เมืองศรีสะเกษ",
        name_en: "Mueang Si Sa Ket",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330101,
            zip_code: 33000,
            name_th: "เมืองเหนือ",
            name_en: "Mueang Nuea",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330102,
            zip_code: 33000,
            name_th: "เมืองใต้",
            name_en: "Mueang Tai",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330103,
            zip_code: 33000,
            name_th: "คูซอด",
            name_en: "Khu Sot",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330104,
            zip_code: 33000,
            name_th: "ซำ",
            name_en: "Sam",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330105,
            zip_code: 33000,
            name_th: "จาน",
            name_en: "Chan",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330106,
            zip_code: 33000,
            name_th: "ตะดอบ",
            name_en: "Tadop",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330107,
            zip_code: 33000,
            name_th: "หนองครก",
            name_en: "Nong Khrok",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330111,
            zip_code: 33000,
            name_th: "โพนข่า",
            name_en: "Phon Kha",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330112,
            zip_code: 33000,
            name_th: "โพนค้อ",
            name_en: "Phon Kho",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330115,
            zip_code: 33000,
            name_th: "โพนเขวา",
            name_en: "Phon Khwao",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330116,
            zip_code: 33000,
            name_th: "หญ้าปล้อง",
            name_en: "Ya Plong",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330118,
            zip_code: 33000,
            name_th: "ทุ่ม",
            name_en: "Thum",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330119,
            zip_code: 33000,
            name_th: "หนองไฮ",
            name_en: "Nong Hai",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330121,
            zip_code: 33000,
            name_th: "หนองแก้ว",
            name_en: "Nong Kaeo",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330122,
            zip_code: 33000,
            name_th: "น้ำคำ",
            name_en: "Nam Kham",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330123,
            zip_code: 33000,
            name_th: "โพธิ์",
            name_en: "Pho",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330124,
            zip_code: 33000,
            name_th: "หมากเขียบ",
            name_en: "Mak Khiap",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330127,
            zip_code: 33000,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 3301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3302,
        name_th: "ยางชุมน้อย",
        name_en: "Yang Chum Noi",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330201,
            zip_code: 33190,
            name_th: "ยางชุมน้อย",
            name_en: "Yang Chum Noi",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330202,
            zip_code: 33190,
            name_th: "ลิ้นฟ้า",
            name_en: "Lin Fa",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330203,
            zip_code: 33190,
            name_th: "คอนกาม",
            name_en: "Khon Kam",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330204,
            zip_code: 33190,
            name_th: "โนนคูณ",
            name_en: "Non Khun",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330205,
            zip_code: 33190,
            name_th: "กุดเมืองฮาม",
            name_en: "Kut Mueang Ham",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330206,
            zip_code: 33190,
            name_th: "บึงบอน",
            name_en: "Bueng Bon",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330207,
            zip_code: 33190,
            name_th: "ยางชุมใหญ่",
            name_en: "Yang Chum Yai",
            amphure_id: 3302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3303,
        name_th: "กันทรารมย์",
        name_en: "Kanthararom",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330301,
            zip_code: 33130,
            name_th: "ดูน",
            name_en: "Dun",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330302,
            zip_code: 33130,
            name_th: "โนนสัง",
            name_en: "Non Sang",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330303,
            zip_code: 33130,
            name_th: "หนองหัวช้าง",
            name_en: "Nong Hua Chang",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330304,
            zip_code: 33130,
            name_th: "ยาง",
            name_en: "Yang",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330305,
            zip_code: 33130,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330306,
            zip_code: 33130,
            name_th: "หนองแก้ว",
            name_en: "Nong Kaeo",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330307,
            zip_code: 33130,
            name_th: "ทาม",
            name_en: "Tham",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330308,
            zip_code: 33130,
            name_th: "ละทาย",
            name_en: "Lathai",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330309,
            zip_code: 33130,
            name_th: "เมืองน้อย",
            name_en: "Mueang Noi",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330310,
            zip_code: 33130,
            name_th: "อีปาด",
            name_en: "I Pat",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330311,
            zip_code: 33130,
            name_th: "บัวน้อย",
            name_en: "Bua Noi",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330312,
            zip_code: 33130,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330313,
            zip_code: 33130,
            name_th: "ดู่",
            name_en: "Du",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330314,
            zip_code: 33130,
            name_th: "ผักแพว",
            name_en: "Phak Phaeo",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330315,
            zip_code: 33130,
            name_th: "จาน",
            name_en: "Chan",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330320,
            zip_code: 33130,
            name_th: "คำเนียม",
            name_en: "Kham Niam",
            amphure_id: 3303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3304,
        name_th: "กันทรลักษ์",
        name_en: "Kantharalak",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330401,
            zip_code: 33110,
            name_th: "บึงมะลู",
            name_en: "Bueng Malu",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330402,
            zip_code: 33110,
            name_th: "กุดเสลา",
            name_en: "Kut Salao",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330403,
            zip_code: 33110,
            name_th: "เมือง",
            name_en: "Mueang",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330405,
            zip_code: 33110,
            name_th: "สังเม็ก",
            name_en: "Sang Mek",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330406,
            zip_code: 33110,
            name_th: "น้ำอ้อม",
            name_en: "Nam Om",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330407,
            zip_code: 33110,
            name_th: "ละลาย",
            name_en: "Lalai",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330408,
            zip_code: 33110,
            name_th: "รุง",
            name_en: "Rung",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330409,
            zip_code: 33110,
            name_th: "ตระกาจ",
            name_en: "Trakat",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330411,
            zip_code: 33110,
            name_th: "จานใหญ่",
            name_en: "Chan Yai)",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330412,
            zip_code: 33110,
            name_th: "ภูเงิน",
            name_en: "Phu Ngoen",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330413,
            zip_code: 33110,
            name_th: "ชำ",
            name_en: "Cham",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330414,
            zip_code: 33110,
            name_th: "กระแชง",
            name_en: "Krachaeng",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330415,
            zip_code: 33110,
            name_th: "โนนสำราญ",
            name_en: "Non Samran",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330416,
            zip_code: 33110,
            name_th: "หนองหญ้าลาด",
            name_en: "Nong Ya Lat",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330419,
            zip_code: 33110,
            name_th: "เสาธงชัย",
            name_en: "Sao Thong Chai",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330420,
            zip_code: 33110,
            name_th: "ขนุน",
            name_en: "Khanun",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330421,
            zip_code: 33110,
            name_th: "สวนกล้วย",
            name_en: "Suan Kluai",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330423,
            zip_code: 33110,
            name_th: "เวียงเหนือ",
            name_en: "Wiang Nuea",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330424,
            zip_code: 33110,
            name_th: "ทุ่งใหญ่",
            name_en: "Thung Yai",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330425,
            zip_code: 33110,
            name_th: "ภูผาหมอก",
            name_en: "Phu Pha Mok",
            amphure_id: 3304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3305,
        name_th: "ขุขันธ์",
        name_en: "Khukhan",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330501,
            zip_code: 33140,
            name_th: "กันทรารมย์",
            name_en: "Kanthararom",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330502,
            zip_code: 33140,
            name_th: "จะกง",
            name_en: "Chakong",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330503,
            zip_code: 33140,
            name_th: "ใจดี",
            name_en: "Chai Di",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330504,
            zip_code: 33140,
            name_th: "ดองกำเม็ด",
            name_en: "Dong Kammet",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330505,
            zip_code: 33140,
            name_th: "โสน",
            name_en: "Sano",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330506,
            zip_code: 33140,
            name_th: "ปรือใหญ่",
            name_en: "Prue Yai",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330507,
            zip_code: 33140,
            name_th: "สะเดาใหญ่",
            name_en: "Sadao Yai",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330508,
            zip_code: 33140,
            name_th: "ตาอุด",
            name_en: "Ta Ut",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330509,
            zip_code: 33140,
            name_th: "ห้วยเหนือ",
            name_en: "Huai Nuea",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330510,
            zip_code: 33140,
            name_th: "ห้วยใต้",
            name_en: "Huai Tai",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330511,
            zip_code: 33140,
            name_th: "หัวเสือ",
            name_en: "Hua Suea",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330513,
            zip_code: 33140,
            name_th: "ตะเคียน",
            name_en: "Takhian",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330515,
            zip_code: 33140,
            name_th: "นิคมพัฒนา",
            name_en: "Nikhom Phatthana",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330517,
            zip_code: 33140,
            name_th: "โคกเพชร",
            name_en: "Khok Phet",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330518,
            zip_code: 33140,
            name_th: "ปราสาท",
            name_en: "Prasat",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330521,
            zip_code: 33140,
            name_th: "สำโรงตาเจ็น",
            name_en: "Samrong Ta Chen",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330522,
            zip_code: 33140,
            name_th: "ห้วยสำราญ",
            name_en: "Huai Samran",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330524,
            zip_code: 33140,
            name_th: "กฤษณา",
            name_en: "Kritsana",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330525,
            zip_code: 33140,
            name_th: "ลมศักดิ์",
            name_en: "Lom Sak",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330526,
            zip_code: 33140,
            name_th: "หนองฉลอง",
            name_en: "Nong Chalong",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330527,
            zip_code: 33140,
            name_th: "ศรีตระกูล",
            name_en: "Si Trakun",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330528,
            zip_code: 33140,
            name_th: "ศรีสะอาด",
            name_en: "Si Sa-at",
            amphure_id: 3305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3306,
        name_th: "ไพรบึง",
        name_en: "Phrai Bueng",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330601,
            zip_code: 33180,
            name_th: "ไพรบึง",
            name_en: "Phrai Bueng",
            amphure_id: 3306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330602,
            zip_code: 33180,
            name_th: "ดินแดง",
            name_en: "Din Daeng",
            amphure_id: 3306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330603,
            zip_code: 33180,
            name_th: "ปราสาทเยอ",
            name_en: "Prasat Yoe",
            amphure_id: 3306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330604,
            zip_code: 33180,
            name_th: "สำโรงพลัน",
            name_en: "Samrong Phlan",
            amphure_id: 3306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330605,
            zip_code: 33180,
            name_th: "สุขสวัสดิ์",
            name_en: "Suk Sawat",
            amphure_id: 3306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330606,
            zip_code: 33180,
            name_th: "โนนปูน",
            name_en: "Non Pun",
            amphure_id: 3306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3307,
        name_th: "ปรางค์กู่",
        name_en: "Prang Ku",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330701,
            zip_code: 33170,
            name_th: "พิมาย",
            name_en: "Phimai",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330702,
            zip_code: 33170,
            name_th: "กู่",
            name_en: "Ku",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330703,
            zip_code: 33170,
            name_th: "หนองเชียงทูน",
            name_en: "Nong Chiang Thun",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330704,
            zip_code: 33170,
            name_th: "ตูม",
            name_en: "Tum",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330705,
            zip_code: 33170,
            name_th: "สมอ",
            name_en: "Samo",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330706,
            zip_code: 33170,
            name_th: "โพธิ์ศรี",
            name_en: "Pho Si",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330707,
            zip_code: 33170,
            name_th: "สำโรงปราสาท",
            name_en: "Samrong Prasat",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330708,
            zip_code: 33170,
            name_th: "ดู่",
            name_en: "Du",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330709,
            zip_code: 33170,
            name_th: "สวาย",
            name_en: "Sawai",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330710,
            zip_code: 33170,
            name_th: "พิมายเหนือ",
            name_en: "Phimai Nuea",
            amphure_id: 3307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3308,
        name_th: "ขุนหาญ",
        name_en: "Khun Han",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330801,
            zip_code: 33150,
            name_th: "สิ",
            name_en: "Si",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330802,
            zip_code: 33150,
            name_th: "บักดอง",
            name_en: "Bak Dong",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330803,
            zip_code: 33150,
            name_th: "พราน",
            name_en: "Phran",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330804,
            zip_code: 33150,
            name_th: "โพธิ์วงศ์",
            name_en: "Pho Wong",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330805,
            zip_code: 33150,
            name_th: "ไพร",
            name_en: "Phrai",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330806,
            zip_code: 33150,
            name_th: "กระหวัน",
            name_en: "Krawan",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330807,
            zip_code: 33150,
            name_th: "ขุนหาญ",
            name_en: "Khun Han",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330808,
            zip_code: 33150,
            name_th: "โนนสูง",
            name_en: "Non Sung",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330809,
            zip_code: 33150,
            name_th: "กันทรอม",
            name_en: "Kanthrom",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330810,
            zip_code: 33150,
            name_th: "ภูฝ้าย",
            name_en: "Phu Fai",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330811,
            zip_code: 33150,
            name_th: "โพธิ์กระสังข์",
            name_en: "Pho Krasang",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330812,
            zip_code: 33150,
            name_th: "ห้วยจันทร์",
            name_en: "Huai Chan",
            amphure_id: 3308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3309,
        name_th: "ราษีไศล",
        name_en: "Rasi Salai",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 330901,
            zip_code: 33160,
            name_th: "เมืองคง",
            name_en: "Mueang Khong",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330902,
            zip_code: 33160,
            name_th: "เมืองแคน",
            name_en: "Muang Khaen",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330903,
            zip_code: 33160,
            name_th: "หนองแค",
            name_en: "Nong Khae",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330906,
            zip_code: 33160,
            name_th: "จิกสังข์ทอง",
            name_en: "Chik Sang Thong",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330907,
            zip_code: 33160,
            name_th: "ด่าน",
            name_en: "Dan",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330908,
            zip_code: 33160,
            name_th: "ดู่",
            name_en: "Du",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330909,
            zip_code: 33160,
            name_th: "หนองอึ่ง",
            name_en: "Nong Ueng",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330910,
            zip_code: 33160,
            name_th: "บัวหุ่ง",
            name_en: "Bua Hung",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330911,
            zip_code: 33160,
            name_th: "ไผ่",
            name_en: "Phai",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330912,
            zip_code: 33160,
            name_th: "ส้มป่อย",
            name_en: "Som Poi",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330913,
            zip_code: 33160,
            name_th: "หนองหมี",
            name_en: "Nong Mi",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330914,
            zip_code: 33160,
            name_th: "หว้านคำ",
            name_en: "Wan Kham",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 330915,
            zip_code: 33160,
            name_th: "สร้างปี่",
            name_en: "Sang Pi",
            amphure_id: 3309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3310,
        name_th: "อุทุมพรพิสัย",
        name_en: "Uthumphon Phisai",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331001,
            zip_code: 33120,
            name_th: "กำแพง",
            name_en: "Kamphaeng",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331002,
            zip_code: 33120,
            name_th: "อี่หล่ำ",
            name_en: "I Lam",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331003,
            zip_code: 33120,
            name_th: "ก้านเหลือง",
            name_en: "Kan Lueang",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331004,
            zip_code: 33120,
            name_th: "ทุ่งไชย",
            name_en: "Thung Chai",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331005,
            zip_code: 33120,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331006,
            zip_code: 33120,
            name_th: "แขม",
            name_en: "Khaem",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331007,
            zip_code: 33120,
            name_th: "หนองไฮ",
            name_en: "Nong Hai",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331008,
            zip_code: 33120,
            name_th: "ขะยูง",
            name_en: "Khayung",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331010,
            zip_code: 33120,
            name_th: "ตาเกษ",
            name_en: "Ta Ket",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331011,
            zip_code: 33120,
            name_th: "หัวช้าง",
            name_en: "Hua Chang",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331012,
            zip_code: 33120,
            name_th: "รังแร้ง",
            name_en: "Rang Raeng",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331014,
            zip_code: 33120,
            name_th: "แต้",
            name_en: "Tae",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331015,
            zip_code: 33120,
            name_th: "แข้",
            name_en: "Khae",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331016,
            zip_code: 33120,
            name_th: "โพธิ์ชัย",
            name_en: "Pho Chai",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331017,
            zip_code: 33120,
            name_th: "ปะอาว",
            name_en: "Pa Ao",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331018,
            zip_code: 33120,
            name_th: "หนองห้าง",
            name_en: "Nong Hang",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331022,
            zip_code: 33120,
            name_th: "สระกำแพงใหญ่",
            name_en: "Sa Kamphaeng Yai",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331024,
            zip_code: 33120,
            name_th: "โคกหล่าม",
            name_en: "Khok Lam",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331025,
            zip_code: 33120,
            name_th: "โคกจาน",
            name_en: "Khok Chan",
            amphure_id: 3310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3311,
        name_th: "บึงบูรพ์",
        name_en: "Bueng Bun",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331101,
            zip_code: 33220,
            name_th: "เป๊าะ",
            name_en: "Po",
            amphure_id: 3311,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331102,
            zip_code: 33220,
            name_th: "บึงบูรพ์",
            name_en: "Bueng Bun",
            amphure_id: 3311,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3312,
        name_th: "ห้วยทับทัน",
        name_en: "Huai Thap Than",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331201,
            zip_code: 33210,
            name_th: "ห้วยทับทัน",
            name_en: "Huai Thap Than",
            amphure_id: 3312,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331202,
            zip_code: 33210,
            name_th: "เมืองหลวง",
            name_en: "Mueang Luang",
            amphure_id: 3312,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331203,
            zip_code: 33210,
            name_th: "กล้วยกว้าง",
            name_en: "Kluai Kwang",
            amphure_id: 3312,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331204,
            zip_code: 33210,
            name_th: "ผักไหม",
            name_en: "Phak Mai",
            amphure_id: 3312,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331205,
            zip_code: 33210,
            name_th: "จานแสนไชย",
            name_en: "Chan Saen Chai",
            amphure_id: 3312,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331206,
            zip_code: 33210,
            name_th: "ปราสาท",
            name_en: "Prasat",
            amphure_id: 3312,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3313,
        name_th: "โนนคูณ",
        name_en: "Non Khun",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331301,
            zip_code: 33250,
            name_th: "โนนค้อ",
            name_en: "Non Kho",
            amphure_id: 3313,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331302,
            zip_code: 33250,
            name_th: "บก",
            name_en: "Bok",
            amphure_id: 3313,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331303,
            zip_code: 33250,
            name_th: "โพธิ์",
            name_en: "Pho",
            amphure_id: 3313,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331304,
            zip_code: 33250,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 3313,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331305,
            zip_code: 33250,
            name_th: "เหล่ากวาง",
            name_en: "Lao Kwang",
            amphure_id: 3313,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3314,
        name_th: "ศรีรัตนะ",
        name_en: "Si Rattana",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331401,
            zip_code: 33240,
            name_th: "ศรีแก้ว",
            name_en: "Si Kaeo",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331402,
            zip_code: 33240,
            name_th: "พิงพวย",
            name_en: "Phing Phuai",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331403,
            zip_code: 33240,
            name_th: "สระเยาว์",
            name_en: "Sa Yao",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331404,
            zip_code: 33240,
            name_th: "ตูม",
            name_en: "Tum",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331405,
            zip_code: 33240,
            name_th: "เสื่องข้าว",
            name_en: "Sueang Khao",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331406,
            zip_code: 33240,
            name_th: "ศรีโนนงาม",
            name_en: "Si Non Ngam",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331407,
            zip_code: 33240,
            name_th: "สะพุง",
            name_en: "Saphung",
            amphure_id: 3314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3315,
        name_th: "น้ำเกลี้ยง",
        name_en: "Si Rattana",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331501,
            zip_code: 33130,
            name_th: "น้ำเกลี้ยง",
            name_en: "Nam Kliang",
            amphure_id: 3315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331502,
            zip_code: 33130,
            name_th: "ละเอาะ",
            name_en: "La-o",
            amphure_id: 3315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331503,
            zip_code: 33130,
            name_th: "ตองปิด",
            name_en: "Tong Pit",
            amphure_id: 3315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331504,
            zip_code: 33130,
            name_th: "เขิน",
            name_en: "Khoen",
            amphure_id: 3315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331505,
            zip_code: 33130,
            name_th: "รุ่งระวี",
            name_en: "Rung Rawi",
            amphure_id: 3315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331506,
            zip_code: 33130,
            name_th: "คูบ",
            name_en: "Khup",
            amphure_id: 3315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3316,
        name_th: "วังหิน",
        name_en: "Wang Hin",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331601,
            zip_code: 33270,
            name_th: "บุสูง",
            name_en: "Bu Sung",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331602,
            zip_code: 33270,
            name_th: "ธาตุ",
            name_en: "That",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331603,
            zip_code: 33270,
            name_th: "ดวนใหญ่",
            name_en: "Duan Yai",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331604,
            zip_code: 33270,
            name_th: "บ่อแก้ว",
            name_en: "Bo Kaeo",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331605,
            zip_code: 33270,
            name_th: "ศรีสำราญ",
            name_en: "Si Samran",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331606,
            zip_code: 33270,
            name_th: "ทุ่งสว่าง",
            name_en: "Thung Sawang",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331607,
            zip_code: 33270,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331608,
            zip_code: 33270,
            name_th: "โพนยาง",
            name_en: "Phon Yang",
            amphure_id: 3316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3317,
        name_th: "ภูสิงห์",
        name_en: "Phu Sing",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331701,
            zip_code: 33140,
            name_th: "โคกตาล",
            name_en: "Khok Tan",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331702,
            zip_code: 33140,
            name_th: "ห้วยตามอญ",
            name_en: "Huai Ta Mon",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331703,
            zip_code: 33140,
            name_th: "ห้วยตึ๊กชู",
            name_en: "Huai Tuekchu",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331704,
            zip_code: 33140,
            name_th: "ละลม",
            name_en: "Lalom",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331705,
            zip_code: 33140,
            name_th: "ตะเคียนราม",
            name_en: "Takhian Ram",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331706,
            zip_code: 33140,
            name_th: "ดงรัก",
            name_en: "Dong Rak",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331707,
            zip_code: 33140,
            name_th: "ไพรพัฒนา",
            name_en: "Phrai Phatthana",
            amphure_id: 3317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3318,
        name_th: "เมืองจันทร์",
        name_en: "Mueang Chan",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331801,
            zip_code: 33120,
            name_th: "เมืองจันทร์",
            name_en: "Mueang Chan",
            amphure_id: 3318,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331802,
            zip_code: 33120,
            name_th: "ตาโกน",
            name_en: "Takon",
            amphure_id: 3318,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331803,
            zip_code: 33120,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 3318,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3319,
        name_th: "เบญจลักษ์",
        name_en: "Benchalak",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 331901,
            zip_code: 33110,
            name_th: "เสียว",
            name_en: "Siao",
            amphure_id: 3319,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331902,
            zip_code: 33110,
            name_th: "หนองหว้า",
            name_en: "Nong Wa",
            amphure_id: 3319,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331903,
            zip_code: 33110,
            name_th: "หนองงูเหลือม",
            name_en: "Nong Ngu Lueam",
            amphure_id: 3319,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331904,
            zip_code: 33110,
            name_th: "หนองฮาง",
            name_en: "Nong Hang",
            amphure_id: 3319,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 331905,
            zip_code: 33110,
            name_th: "ท่าคล้อ",
            name_en: "Tha Khlo",
            amphure_id: 3319,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3320,
        name_th: "พยุห์",
        name_en: "Phayu",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 332001,
            zip_code: 33230,
            name_th: "พยุห์",
            name_en: "Phayu",
            amphure_id: 3320,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332002,
            zip_code: 33230,
            name_th: "พรหมสวัสดิ์",
            name_en: "Phrom Sawat",
            amphure_id: 3320,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332003,
            zip_code: 33230,
            name_th: "ตำแย",
            name_en: "Tamyae",
            amphure_id: 3320,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332004,
            zip_code: 33230,
            name_th: "โนนเพ็ก",
            name_en: "Non Phek",
            amphure_id: 3320,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332005,
            zip_code: 33230,
            name_th: "หนองค้า",
            name_en: "Nong Kha",
            amphure_id: 3320,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3321,
        name_th: "โพธิ์ศรีสุวรรณ",
        name_en: "Pho Si Suwan",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 332101,
            zip_code: 33120,
            name_th: "โดด",
            name_en: "Dot",
            amphure_id: 3321,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332102,
            zip_code: 33120,
            name_th: "เสียว",
            name_en: "Siao",
            amphure_id: 3321,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332103,
            zip_code: 33120,
            name_th: "หนองม้า",
            name_en: "Nong Ma",
            amphure_id: 3321,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332104,
            zip_code: 33120,
            name_th: "ผือใหญ่",
            name_en: "Phue Yai",
            amphure_id: 3321,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332105,
            zip_code: 33120,
            name_th: "อีเซ",
            name_en: "I Se",
            amphure_id: 3321,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3322,
        name_th: "ศิลาลาด",
        name_en: "Sila Lat",
        province_id: 22,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 332201,
            zip_code: 33160,
            name_th: "กุง",
            name_en: "Kung",
            amphure_id: 3322,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332202,
            zip_code: 33160,
            name_th: "คลีกลิ้ง",
            name_en: "Kleek Ling",
            amphure_id: 3322,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332203,
            zip_code: 33160,
            name_th: "หนองบัวดง",
            name_en: "Nong Bua Dong",
            amphure_id: 3322,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 332204,
            zip_code: 33160,
            name_th: "โจดม่วง",
            name_en: "Jod Maung",
            amphure_id: 3322,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 23,
    name_th: "อุบลราชธานี",
    name_en: "Ubon Ratchathani",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3401,
        name_th: "เมืองอุบลราชธานี",
        name_en: "Mueang Ubon Ratchathani",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340101,
            zip_code: 34000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340104,
            zip_code: 34000,
            name_th: "หัวเรือ",
            name_en: "Hua Ruea",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340105,
            zip_code: 34000,
            name_th: "หนองขอน",
            name_en: "Nong Khon",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340107,
            zip_code: 34000,
            name_th: "ปทุม",
            name_en: "Pathum",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340108,
            zip_code: 34000,
            name_th: "ขามใหญ่",
            name_en: "Kham Yai",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340109,
            zip_code: 34000,
            name_th: "แจระแม",
            name_en: "Chaeramae",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340111,
            zip_code: 34000,
            name_th: "หนองบ่อ",
            name_en: "Nong Bo",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340112,
            zip_code: 34000,
            name_th: "ไร่น้อย",
            name_en: "Rai Noi",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340113,
            zip_code: 34000,
            name_th: "กระโสบ",
            name_en: "Krasop",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340116,
            zip_code: 34000,
            name_th: "กุดลาด",
            name_en: "Kut Lat",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340119,
            zip_code: 34000,
            name_th: "ขี้เหล็ก",
            name_en: "Khilek",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340120,
            zip_code: 34000,
            name_th: "ปะอาว",
            name_en: "Pa-ao",
            amphure_id: 3401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3402,
        name_th: "ศรีเมืองใหม่",
        name_en: "Si Mueang Mai",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340201,
            zip_code: 34250,
            name_th: "นาคำ",
            name_en: "Na Kham",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340202,
            zip_code: 34250,
            name_th: "แก้งกอก",
            name_en: "Kaeng Kok",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340203,
            zip_code: 34250,
            name_th: "เอือดใหญ่",
            name_en: "Ueat Yai",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340204,
            zip_code: 34250,
            name_th: "วาริน",
            name_en: "Warin",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340205,
            zip_code: 34250,
            name_th: "ลาดควาย",
            name_en: "Lat Khwai",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340206,
            zip_code: 34250,
            name_th: "สงยาง",
            name_en: "Song Yang",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340207,
            zip_code: 34250,
            name_th: "ตะบ่าย",
            name_en: "Ta Bai",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340208,
            zip_code: 34250,
            name_th: "คำไหล",
            name_en: "Kham Lai",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340209,
            zip_code: 34250,
            name_th: "หนามแท่ง",
            name_en: "Nam Thaeng",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340210,
            zip_code: 34250,
            name_th: "นาเลิน",
            name_en: "Na Loen",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340211,
            zip_code: 34250,
            name_th: "ดอนใหญ่",
            name_en: "Don Yai",
            amphure_id: 3402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3403,
        name_th: "โขงเจียม",
        name_en: "Khong Chiam",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340301,
            zip_code: 34220,
            name_th: "โขงเจียม",
            name_en: "Khong Chiam",
            amphure_id: 3403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340302,
            zip_code: 34220,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 3403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340303,
            zip_code: 34220,
            name_th: "นาโพธิ์กลาง",
            name_en: "Na Pho Klang",
            amphure_id: 3403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340304,
            zip_code: 34220,
            name_th: "หนองแสงใหญ่",
            name_en: "Nong Saeng Yai",
            amphure_id: 3403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340305,
            zip_code: 34220,
            name_th: "ห้วยไผ่",
            name_en: "Huai Phai",
            amphure_id: 3403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340306,
            zip_code: 34220,
            name_th: "คำเขื่อนแก้ว",
            name_en: "Kham Khuen Kaew",
            amphure_id: 3403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3404,
        name_th: "เขื่องใน",
        name_en: "Khueang Nai",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340401,
            zip_code: 34150,
            name_th: "เขื่องใน",
            name_en: "Khueang Nai",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340402,
            zip_code: 34150,
            name_th: "สร้างถ่อ",
            name_en: "Sang Tho",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340403,
            zip_code: 34150,
            name_th: "ค้อทอง",
            name_en: "Kho Thong",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340404,
            zip_code: 34150,
            name_th: "ก่อเอ้",
            name_en: "Ko E",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340405,
            zip_code: 34150,
            name_th: "หัวดอน",
            name_en: "Hua Don",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340406,
            zip_code: 34150,
            name_th: "ชีทวน",
            name_en: "Chi Thuan",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340407,
            zip_code: 34150,
            name_th: "ท่าไห",
            name_en: "Tha Hai",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340408,
            zip_code: 34150,
            name_th: "นาคำใหญ่",
            name_en: "Na Kham Yai",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340409,
            zip_code: 34150,
            name_th: "แดงหม้อ",
            name_en: "Daeng Mo",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340410,
            zip_code: 34150,
            name_th: "ธาตุน้อย",
            name_en: "That Noi",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340411,
            zip_code: 34320,
            name_th: "บ้านไทย",
            name_en: "Ban Thai",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340412,
            zip_code: 34320,
            name_th: "บ้านกอก",
            name_en: "Ban Kok",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340413,
            zip_code: 34320,
            name_th: "กลางใหญ่",
            name_en: "Klang Yai",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340414,
            zip_code: 34320,
            name_th: "โนนรัง",
            name_en: "Non Rang",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340415,
            zip_code: 34150,
            name_th: "ยางขี้นก",
            name_en: "Yang Khi Nok",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340416,
            zip_code: 34150,
            name_th: "ศรีสุข",
            name_en: "Si Suk",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340417,
            zip_code: 34150,
            name_th: "สหธาตุ",
            name_en: "Sahathat",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340418,
            zip_code: 34150,
            name_th: "หนองเหล่า",
            name_en: "Nong Lao",
            amphure_id: 3404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3405,
        name_th: "เขมราฐ",
        name_en: "Khemarat",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340501,
            zip_code: 34170,
            name_th: "เขมราฐ",
            name_en: "Khemarat",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340503,
            zip_code: 34170,
            name_th: "ขามป้อม",
            name_en: "Kham Pom",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340504,
            zip_code: 34170,
            name_th: "เจียด",
            name_en: "Chiat",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340507,
            zip_code: 34170,
            name_th: "หนองผือ",
            name_en: "Nong Phue",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340508,
            zip_code: 34170,
            name_th: "นาแวง",
            name_en: "Na Waeng",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340510,
            zip_code: 34170,
            name_th: "แก้งเหนือ",
            name_en: "Kaeng Nuea",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340511,
            zip_code: 34170,
            name_th: "หนองนกทา",
            name_en: "Nong Nok Tha",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340512,
            zip_code: 34170,
            name_th: "หนองสิม",
            name_en: "Nong Sim",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340513,
            zip_code: 34170,
            name_th: "หัวนา",
            name_en: "Hua Na",
            amphure_id: 3405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3407,
        name_th: "เดชอุดม",
        name_en: "Det Udom",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340701,
            zip_code: 34160,
            name_th: "เมืองเดช",
            name_en: "Mueang Det",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340702,
            zip_code: 34160,
            name_th: "นาส่วง",
            name_en: "Na Suang",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340704,
            zip_code: 34160,
            name_th: "นาเจริญ",
            name_en: "Na Charoen",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340706,
            zip_code: 34160,
            name_th: "ทุ่งเทิง",
            name_en: "Thung Thoeng",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340708,
            zip_code: 34160,
            name_th: "สมสะอาด",
            name_en: "Som Sa-at",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340709,
            zip_code: 34160,
            name_th: "กุดประทาย",
            name_en: "Kut Prathai",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340710,
            zip_code: 34160,
            name_th: "ตบหู",
            name_en: "Top Hu",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340711,
            zip_code: 34160,
            name_th: "กลาง",
            name_en: "Klang",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340712,
            zip_code: 34160,
            name_th: "แก้ง",
            name_en: "Kaeng",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340713,
            zip_code: 34160,
            name_th: "ท่าโพธิ์ศรี",
            name_en: "Tha Pho Si",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340715,
            zip_code: 34160,
            name_th: "บัวงาม",
            name_en: "Bua Ngam",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340716,
            zip_code: 34160,
            name_th: "คำครั่ง",
            name_en: "Kham Khrang",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340717,
            zip_code: 34160,
            name_th: "นากระแซง",
            name_en: "Na Krasaeng",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340720,
            zip_code: 34160,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340721,
            zip_code: 34160,
            name_th: "ป่าโมง",
            name_en: "Pa Mong",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340723,
            zip_code: 34160,
            name_th: "โนนสมบูรณ์",
            name_en: "Non Sombun",
            amphure_id: 3407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3408,
        name_th: "นาจะหลวย",
        name_en: "Na Chaluai",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340801,
            zip_code: 34280,
            name_th: "นาจะหลวย",
            name_en: "Na Chaluai",
            amphure_id: 3408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340802,
            zip_code: 34280,
            name_th: "โนนสมบูรณ์",
            name_en: "Non Sombun",
            amphure_id: 3408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340803,
            zip_code: 34280,
            name_th: "พรสวรรค์",
            name_en: "Phon Sawan",
            amphure_id: 3408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340804,
            zip_code: 34280,
            name_th: "บ้านตูม",
            name_en: "Ban Tum",
            amphure_id: 3408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340805,
            zip_code: 34280,
            name_th: "โสกแสง",
            name_en: "Sok Saeng",
            amphure_id: 3408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340806,
            zip_code: 34280,
            name_th: "โนนสวรรค์",
            name_en: "Non Sawan",
            amphure_id: 3408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3409,
        name_th: "น้ำยืน",
        name_en: "Nam Yuen",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 340901,
            zip_code: 34260,
            name_th: "โซง",
            name_en: "Song",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340903,
            zip_code: 34260,
            name_th: "ยาง",
            name_en: "Yang",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340904,
            zip_code: 34260,
            name_th: "โดมประดิษฐ์",
            name_en: "Dom Pradit",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340906,
            zip_code: 34260,
            name_th: "บุเปือย",
            name_en: "Bu Pueai",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340907,
            zip_code: 34260,
            name_th: "สีวิเชียร",
            name_en: "Si Wichian",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340909,
            zip_code: 34260,
            name_th: "ยางใหญ่",
            name_en: "Yang Yai",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 340911,
            zip_code: 34260,
            name_th: "เก่าขาม",
            name_en: "Kao Kham",
            amphure_id: 3409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3410,
        name_th: "บุณฑริก",
        name_en: "Buntharik",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 341001,
            zip_code: 34230,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341002,
            zip_code: 34230,
            name_th: "ห้วยข่า",
            name_en: "Huai Kha",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341003,
            zip_code: 34230,
            name_th: "คอแลน",
            name_en: "Kho Laen",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341004,
            zip_code: 34230,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341005,
            zip_code: 34230,
            name_th: "หนองสะโน",
            name_en: "Nong Sano",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341006,
            zip_code: 34230,
            name_th: "โนนค้อ",
            name_en: "Non Kho",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341007,
            zip_code: 34230,
            name_th: "บัวงาม",
            name_en: "Bua Ngam",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341008,
            zip_code: 34230,
            name_th: "บ้านแมด",
            name_en: "Ban Maet",
            amphure_id: 3410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3411,
        name_th: "ตระการพืชผล",
        name_en: "Trakan Phuet Phon",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 341101,
            zip_code: 34130,
            name_th: "ขุหลุ",
            name_en: "Khulu",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341102,
            zip_code: 34130,
            name_th: "กระเดียน",
            name_en: "Kradian",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341103,
            zip_code: 34130,
            name_th: "เกษม",
            name_en: "Kasem",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341104,
            zip_code: 34130,
            name_th: "กุศกร",
            name_en: "Kutsakon",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341105,
            zip_code: 34130,
            name_th: "ขามเปี้ย",
            name_en: "Kham Pia",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341106,
            zip_code: 34130,
            name_th: "คอนสาย",
            name_en: "Khon Sai",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341107,
            zip_code: 34130,
            name_th: "โคกจาน",
            name_en: "Khok Chan",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341108,
            zip_code: 34130,
            name_th: "นาพิน",
            name_en: "Na Phin",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341109,
            zip_code: 34130,
            name_th: "นาสะไม",
            name_en: "Na Samai",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341110,
            zip_code: 34130,
            name_th: "โนนกุง",
            name_en: "Non Kung",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341111,
            zip_code: 34130,
            name_th: "ตระการ",
            name_en: "Trakan",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341112,
            zip_code: 34130,
            name_th: "ตากแดด",
            name_en: "Tak Daet",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341113,
            zip_code: 34130,
            name_th: "ไหล่ทุ่ง",
            name_en: "Lai Thung",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341114,
            zip_code: 34130,
            name_th: "เป้า",
            name_en: "Pao",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341115,
            zip_code: 34130,
            name_th: "เซเป็ด",
            name_en: "Se Pet",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341116,
            zip_code: 34130,
            name_th: "สะพือ",
            name_en: "Saphue",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341117,
            zip_code: 34130,
            name_th: "หนองเต่า",
            name_en: "Nong Tao",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341118,
            zip_code: 34130,
            name_th: "ถ้ำแข้",
            name_en: "Tham Khae",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341119,
            zip_code: 34130,
            name_th: "ท่าหลวง",
            name_en: "Tha Luang",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341120,
            zip_code: 34130,
            name_th: "ห้วยฝ้ายพัฒนา",
            name_en: "Huai Fai Phatthana",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341121,
            zip_code: 34130,
            name_th: "กุดยาลวน",
            name_en: "Kut Ya Luan",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341122,
            zip_code: 34130,
            name_th: "บ้านแดง",
            name_en: "Ban Daeng",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341123,
            zip_code: 34130,
            name_th: "คำเจริญ",
            name_en: "Kham Charoen",
            amphure_id: 3411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3412,
        name_th: "กุดข้าวปุ้น",
        name_en: "Kut Khaopun",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 341201,
            zip_code: 34270,
            name_th: "ข้าวปุ้น",
            name_en: "Khaopun",
            amphure_id: 3412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341202,
            zip_code: 34270,
            name_th: "โนนสวาง",
            name_en: "Non Sawang",
            amphure_id: 3412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341203,
            zip_code: 34270,
            name_th: "แก่งเค็ง",
            name_en: "Kaeng Kheng",
            amphure_id: 3412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341204,
            zip_code: 34270,
            name_th: "กาบิน",
            name_en: "Ka Bin",
            amphure_id: 3412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341205,
            zip_code: 34270,
            name_th: "หนองทันน้ำ",
            name_en: "Nong Than Nam",
            amphure_id: 3412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3414,
        name_th: "ม่วงสามสิบ",
        name_en: "Muang Sam Sip",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 341401,
            zip_code: 34140,
            name_th: "ม่วงสามสิบ",
            name_en: "Muang Sam Sip",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341402,
            zip_code: 34140,
            name_th: "เหล่าบก",
            name_en: "Lao Bok",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341403,
            zip_code: 34140,
            name_th: "ดุมใหญ่",
            name_en: "Dum Yai",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341404,
            zip_code: 34140,
            name_th: "หนองช้างใหญ่",
            name_en: "Non Chang Yai",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341405,
            zip_code: 34140,
            name_th: "หนองเมือง",
            name_en: "Nong Mueang",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341406,
            zip_code: 34140,
            name_th: "เตย",
            name_en: "Toei",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341407,
            zip_code: 34140,
            name_th: "ยางสักกระโพหลุ่ม",
            name_en: "Yang Sak Krapho Lum",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341408,
            zip_code: 34140,
            name_th: "หนองไข่นก",
            name_en: "Nong Khai Nok",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341409,
            zip_code: 34140,
            name_th: "หนองเหล่า",
            name_en: "Nong Lao",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341410,
            zip_code: 34140,
            name_th: "หนองฮาง",
            name_en: "Nong Hang",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341411,
            zip_code: 34140,
            name_th: "ยางโยภาพ",
            name_en: "Yang Yo Phap",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341412,
            zip_code: 34140,
            name_th: "ไผ่ใหญ่",
            name_en: "Phai Yai",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341413,
            zip_code: 34140,
            name_th: "นาเลิง",
            name_en: "Na Loeng",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341414,
            zip_code: 34140,
            name_th: "โพนแพง",
            name_en: "Phon Phaeng",
            amphure_id: 3414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3415,
        name_th: "วารินชำราบ",
        name_en: "Warin Chamrap",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 341501,
            zip_code: 34190,
            name_th: "วารินชำราบ",
            name_en: "Warin Chamrap",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341502,
            zip_code: 34190,
            name_th: "ธาตุ",
            name_en: "That",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341504,
            zip_code: 34310,
            name_th: "ท่าลาด",
            name_en: "Tha Lat",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341505,
            zip_code: 34190,
            name_th: "โนนโหนน",
            name_en: "Non Non",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341507,
            zip_code: 34190,
            name_th: "คูเมือง",
            name_en: "Khu Mueang",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341508,
            zip_code: 34190,
            name_th: "สระสมิง",
            name_en: "Sa Saming",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341510,
            zip_code: 34190,
            name_th: "คำน้ำแซบ",
            name_en: "Kham Nam Saep",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341511,
            zip_code: 34310,
            name_th: "บุ่งหวาย",
            name_en: "Bung Wai",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341515,
            zip_code: 34190,
            name_th: "คำขวาง",
            name_en: "Kham Khwang",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341516,
            zip_code: 34190,
            name_th: "โพธิ์ใหญ่",
            name_en: "Pho Yai",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341518,
            zip_code: 34190,
            name_th: "แสนสุข",
            name_en: "Saen Suk",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341520,
            zip_code: 34190,
            name_th: "หนองกินเพล",
            name_en: "Nong Kin Phen",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341521,
            zip_code: 34190,
            name_th: "โนนผึ้ง",
            name_en: "Non Phueng",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341522,
            zip_code: 34190,
            name_th: "เมืองศรีไค",
            name_en: "Mueang Si Khai",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341524,
            zip_code: 34310,
            name_th: "ห้วยขะยูง",
            name_en: "Huai Khayung",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341526,
            zip_code: 34190,
            name_th: "บุ่งไหม",
            name_en: "Bung Mai",
            amphure_id: 3415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3419,
        name_th: "พิบูลมังสาหาร",
        name_en: "Phibun Mangsahan",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 341901,
            zip_code: 34110,
            name_th: "พิบูล",
            name_en: "Phibun",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341902,
            zip_code: 34110,
            name_th: "กุดชมภู",
            name_en: "Kut Chom Phu",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341904,
            zip_code: 34110,
            name_th: "ดอนจิก",
            name_en: "Don Chik",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341905,
            zip_code: 34110,
            name_th: "ทรายมูล",
            name_en: "Sai Mun",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341906,
            zip_code: 34110,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341907,
            zip_code: 34110,
            name_th: "โนนกลาง",
            name_en: "Non Klang",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341909,
            zip_code: 34110,
            name_th: "โพธิ์ไทร",
            name_en: "Pho Sai",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341910,
            zip_code: 34110,
            name_th: "โพธิ์ศรี",
            name_en: "Pho Si",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341911,
            zip_code: 34110,
            name_th: "ระเว",
            name_en: "Rawe",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341912,
            zip_code: 34110,
            name_th: "ไร่ใต้",
            name_en: "Rai Tai",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341913,
            zip_code: 34110,
            name_th: "หนองบัวฮี",
            name_en: "Nong Bua Hi",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341914,
            zip_code: 34110,
            name_th: "อ่างศิลา",
            name_en: "Ang Sila",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341918,
            zip_code: 34110,
            name_th: "โนนกาหลง",
            name_en: "Non Kalong",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 341919,
            zip_code: 34110,
            name_th: "บ้านแขม",
            name_en: "Ban Khaem",
            amphure_id: 3419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3420,
        name_th: "ตาลสุม",
        name_en: "Tan Sum",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342001,
            zip_code: 34330,
            name_th: "ตาลสุม",
            name_en: "Tan Sum",
            amphure_id: 3420,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342002,
            zip_code: 34330,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3420,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342003,
            zip_code: 34330,
            name_th: "จิกเทิง",
            name_en: "Chik Thoeng",
            amphure_id: 3420,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342004,
            zip_code: 34330,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 3420,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342005,
            zip_code: 34330,
            name_th: "นาคาย",
            name_en: "Na Khai",
            amphure_id: 3420,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342006,
            zip_code: 34330,
            name_th: "คำหว้า",
            name_en: "Kham Wa",
            amphure_id: 3420,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3421,
        name_th: "โพธิ์ไทร",
        name_en: "Pho Sai",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342101,
            zip_code: 34340,
            name_th: "โพธิ์ไทร",
            name_en: "Pho Sai",
            amphure_id: 3421,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342102,
            zip_code: 34340,
            name_th: "ม่วงใหญ่",
            name_en: "Muang Yai",
            amphure_id: 3421,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342103,
            zip_code: 34340,
            name_th: "สำโรง",
            name_en: "Sam Rong",
            amphure_id: 3421,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342104,
            zip_code: 34340,
            name_th: "สองคอน",
            name_en: "Song Khon",
            amphure_id: 3421,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342105,
            zip_code: 34340,
            name_th: "สารภี",
            name_en: "Saraphi",
            amphure_id: 3421,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342106,
            zip_code: 34340,
            name_th: "เหล่างาม",
            name_en: "Lao Ngam",
            amphure_id: 3421,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3422,
        name_th: "สำโรง",
        name_en: "Samrong",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342201,
            zip_code: 34360,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342202,
            zip_code: 34360,
            name_th: "โคกก่อง",
            name_en: "Khok Kong",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342203,
            zip_code: 34360,
            name_th: "หนองไฮ",
            name_en: "Nong Hai",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342204,
            zip_code: 34360,
            name_th: "ค้อน้อย",
            name_en: "Kho Noi",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342205,
            zip_code: 34360,
            name_th: "โนนกาเล็น",
            name_en: "Non Ka Len",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342206,
            zip_code: 34360,
            name_th: "โคกสว่าง",
            name_en: "Khok Sawang",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342207,
            zip_code: 34360,
            name_th: "โนนกลาง",
            name_en: "Non Klang",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342208,
            zip_code: 34360,
            name_th: "บอน",
            name_en: "Bon",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342209,
            zip_code: 34360,
            name_th: "ขามป้อม",
            name_en: "Kham Pom",
            amphure_id: 3422,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3424,
        name_th: "ดอนมดแดง",
        name_en: "Don Mot Daeng",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342401,
            zip_code: 34000,
            name_th: "ดอนมดแดง",
            name_en: "Don Mot Daeng",
            amphure_id: 3424,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342402,
            zip_code: 34000,
            name_th: "เหล่าแดง",
            name_en: "Lao Daeng",
            amphure_id: 3424,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342403,
            zip_code: 34000,
            name_th: "ท่าเมือง",
            name_en: "Tha Mueang",
            amphure_id: 3424,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342404,
            zip_code: 34000,
            name_th: "คำไฮใหญ่",
            name_en: "Kham Hai Yai",
            amphure_id: 3424,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3425,
        name_th: "สิรินธร",
        name_en: "Sirindhorn",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342501,
            zip_code: 34350,
            name_th: "คันไร่",
            name_en: "Khan Rai",
            amphure_id: 3425,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342502,
            zip_code: 34350,
            name_th: "ช่องเม็ก",
            name_en: "Chong Mek",
            amphure_id: 3425,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342503,
            zip_code: 34350,
            name_th: "โนนก่อ",
            name_en: "Non Ko",
            amphure_id: 3425,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342504,
            zip_code: 34350,
            name_th: "นิคมสร้างตนเองลำโดมน้อย",
            name_en: "Nikhom Sang Ton Eng Lam Dom Noi",
            amphure_id: 3425,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342505,
            zip_code: 34350,
            name_th: "ฝางคำ",
            name_en: "Fang Kham",
            amphure_id: 3425,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342506,
            zip_code: 34350,
            name_th: "คำเขื่อนแก้ว",
            name_en: "Kham Khuean Kaeo",
            amphure_id: 3425,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3426,
        name_th: "ทุ่งศรีอุดม",
        name_en: "Thung Si Udom",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342602,
            zip_code: 34160,
            name_th: "หนองอ้ม",
            name_en: "Nong Om",
            amphure_id: 3426,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342603,
            zip_code: 34160,
            name_th: "นาเกษม",
            name_en: "Na Kasem",
            amphure_id: 3426,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342604,
            zip_code: 34160,
            name_th: "กุดเรือ",
            name_en: "Kut Ruea",
            amphure_id: 3426,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342605,
            zip_code: 34160,
            name_th: "โคกชำแระ",
            name_en: "Khok Chamrae",
            amphure_id: 3426,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342606,
            zip_code: 34160,
            name_th: "นาห่อม",
            name_en: "Na Hom",
            amphure_id: 3426,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3429,
        name_th: "นาเยีย",
        name_en: "Na Yia",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 342901,
            zip_code: 34160,
            name_th: "นาเยีย",
            name_en: "Na Yia",
            amphure_id: 3429,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342902,
            zip_code: 34160,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 3429,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 342903,
            zip_code: 34160,
            name_th: "นาเรือง",
            name_en: "Na Rueang",
            amphure_id: 3429,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3430,
        name_th: "นาตาล",
        name_en: "Na Tan",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 343001,
            zip_code: 34170,
            name_th: "นาตาล",
            name_en: "Na Tan",
            amphure_id: 3430,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343002,
            zip_code: 34170,
            name_th: "พะลาน",
            name_en: "Phalan",
            amphure_id: 3430,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343003,
            zip_code: 34170,
            name_th: "กองโพน",
            name_en: "Kong Phon",
            amphure_id: 3430,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343004,
            zip_code: 34170,
            name_th: "พังเคน",
            name_en: "Phang Khen",
            amphure_id: 3430,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3431,
        name_th: "เหล่าเสือโก้ก",
        name_en: "Lao Suea Kok",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 343101,
            zip_code: 34000,
            name_th: "เหล่าเสือโก้ก",
            name_en: "Lao Suea Kok",
            amphure_id: 3431,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343102,
            zip_code: 34000,
            name_th: "โพนเมือง",
            name_en: "Phon Mueang",
            amphure_id: 3431,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343103,
            zip_code: 34000,
            name_th: "แพงใหญ่",
            name_en: "Phaeng Yai",
            amphure_id: 3431,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343104,
            zip_code: 34000,
            name_th: "หนองบก",
            name_en: "Nong Bok",
            amphure_id: 3431,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3432,
        name_th: "สว่างวีระวงศ์",
        name_en: "Sawang Wirawong",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 343201,
            zip_code: 34190,
            name_th: "แก่งโดม",
            name_en: "Kaeng Dom",
            amphure_id: 3432,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343202,
            zip_code: 34190,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 3432,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343203,
            zip_code: 34190,
            name_th: "บุ่งมะแลง",
            name_en: "Bung Malaeng",
            amphure_id: 3432,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343204,
            zip_code: 34190,
            name_th: "สว่าง",
            name_en: "Sawang",
            amphure_id: 3432,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3433,
        name_th: "น้ำขุ่น",
        name_en: "Nam Khun",
        province_id: 23,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 343301,
            zip_code: 34260,
            name_th: "ตาเกา",
            name_en: "Ta Kao",
            amphure_id: 3433,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343302,
            zip_code: 34260,
            name_th: "ไพบูลย์",
            name_en: "Phaibun",
            amphure_id: 3433,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343303,
            zip_code: 34260,
            name_th: "ขี้เหล็ก",
            name_en: "Khilek",
            amphure_id: 3433,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 343304,
            zip_code: 34260,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 3433,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 24,
    name_th: "ยโสธร",
    name_en: "Yasothon",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3501,
        name_th: "เมืองยโสธร",
        name_en: "Mueang Yasothon",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350101,
            zip_code: 35000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350102,
            zip_code: 35000,
            name_th: "น้ำคำใหญ่",
            name_en: "Nam Kham Yai",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350103,
            zip_code: 35000,
            name_th: "ตาดทอง",
            name_en: "Tat Thong",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350104,
            zip_code: 35000,
            name_th: "สำราญ",
            name_en: "Samran",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350105,
            zip_code: 35000,
            name_th: "ค้อเหนือ",
            name_en: "Kho Nuea",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350106,
            zip_code: 35000,
            name_th: "ดู่ทุ่ง",
            name_en: "Du Thung",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350107,
            zip_code: 35000,
            name_th: "เดิด",
            name_en: "Doet",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350108,
            zip_code: 35000,
            name_th: "ขั้นไดใหญ่",
            name_en: "Khandai Yai",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350109,
            zip_code: 35000,
            name_th: "ทุ่งแต้",
            name_en: "Thung Tae",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350110,
            zip_code: 35000,
            name_th: "สิงห์",
            name_en: "Sing",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350111,
            zip_code: 35000,
            name_th: "นาสะไมย์",
            name_en: "Na Samai",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350112,
            zip_code: 35000,
            name_th: "เขื่องคำ",
            name_en: "Khueang Kham",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350113,
            zip_code: 35000,
            name_th: "หนองหิน",
            name_en: "Nong Hin",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350114,
            zip_code: 35000,
            name_th: "หนองคู",
            name_en: "Nong Khu",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350115,
            zip_code: 35000,
            name_th: "ขุมเงิน",
            name_en: "Khum Ngoen",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350116,
            zip_code: 35000,
            name_th: "ทุ่งนางโอก",
            name_en: "Thung Nang Ok",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350117,
            zip_code: 35000,
            name_th: "หนองเรือ",
            name_en: "Nong Ruea",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350118,
            zip_code: 35000,
            name_th: "หนองเป็ด",
            name_en: "Nong Pet",
            amphure_id: 3501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3502,
        name_th: "ทรายมูล",
        name_en: "Sai Mun",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350201,
            zip_code: 35170,
            name_th: "ทรายมูล",
            name_en: "Sai Mun",
            amphure_id: 3502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350202,
            zip_code: 35170,
            name_th: "ดู่ลาด",
            name_en: "Du Lat",
            amphure_id: 3502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350203,
            zip_code: 35170,
            name_th: "ดงมะไฟ",
            name_en: "Dong Mafai",
            amphure_id: 3502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350204,
            zip_code: 35170,
            name_th: "นาเวียง",
            name_en: "Na Wiang",
            amphure_id: 3502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350205,
            zip_code: 35170,
            name_th: "ไผ่",
            name_en: "Phai",
            amphure_id: 3502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3503,
        name_th: "กุดชุม",
        name_en: "Kut Chum",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350301,
            zip_code: 35140,
            name_th: "กุดชุม",
            name_en: "Kut Chum",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350302,
            zip_code: 35140,
            name_th: "โนนเปือย",
            name_en: "Non Pueai",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350303,
            zip_code: 35140,
            name_th: "กำแมด",
            name_en: "Kammaet",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350304,
            zip_code: 35140,
            name_th: "นาโส่",
            name_en: "Na So",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350305,
            zip_code: 35140,
            name_th: "ห้วยแก้ง",
            name_en: "Huai Kaeng",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350306,
            zip_code: 35140,
            name_th: "หนองหมี",
            name_en: "Nong Mi",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350307,
            zip_code: 35140,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350308,
            zip_code: 35140,
            name_th: "คำน้ำสร้าง",
            name_en: "Kham Nam Sang",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350309,
            zip_code: 35140,
            name_th: "หนองแหน",
            name_en: "Nong Nae",
            amphure_id: 3503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3504,
        name_th: "คำเขื่อนแก้ว",
        name_en: "Kham Khuean Kaeo",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350401,
            zip_code: 35110,
            name_th: "ลุมพุก",
            name_en: "Lumphuk",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350402,
            zip_code: 35110,
            name_th: "ย่อ",
            name_en: "Yo",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350403,
            zip_code: 35110,
            name_th: "สงเปือย",
            name_en: "Song Pueai",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350404,
            zip_code: 35110,
            name_th: "โพนทัน",
            name_en: "Phon Than",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350405,
            zip_code: 35110,
            name_th: "ทุ่งมน",
            name_en: "Thung Mon",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350406,
            zip_code: 35180,
            name_th: "นาคำ",
            name_en: "Na Kham",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350407,
            zip_code: 35180,
            name_th: "ดงแคนใหญ่",
            name_en: "Dong Khaen Yai",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350408,
            zip_code: 35110,
            name_th: "กู่จาน",
            name_en: "Ku Chan",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350409,
            zip_code: 35180,
            name_th: "นาแก",
            name_en: "Na Kae",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350410,
            zip_code: 35110,
            name_th: "กุดกุง",
            name_en: "Kut Kung",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350411,
            zip_code: 35110,
            name_th: "เหล่าไฮ",
            name_en: "Lao Hai",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350412,
            zip_code: 35180,
            name_th: "แคนน้อย",
            name_en: "Khaen Noi",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350413,
            zip_code: 35110,
            name_th: "ดงเจริญ",
            name_en: "Dong Charoen",
            amphure_id: 3504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3505,
        name_th: "ป่าติ้ว",
        name_en: "Pa Tio",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350501,
            zip_code: 35150,
            name_th: "โพธิ์ไทร",
            name_en: "Pho Sai",
            amphure_id: 3505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350502,
            zip_code: 35150,
            name_th: "กระจาย",
            name_en: "Krachai",
            amphure_id: 3505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350503,
            zip_code: 35150,
            name_th: "โคกนาโก",
            name_en: "Khok Na Ko",
            amphure_id: 3505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350504,
            zip_code: 35150,
            name_th: "เชียงเพ็ง",
            name_en: "Chiang Pheng",
            amphure_id: 3505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350505,
            zip_code: 35150,
            name_th: "ศรีฐาน",
            name_en: "Si Than",
            amphure_id: 3505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3506,
        name_th: "มหาชนะชัย",
        name_en: "Maha Chana Chai",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350601,
            zip_code: 35130,
            name_th: "ฟ้าหยาด",
            name_en: "Fa Yat",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350602,
            zip_code: 35130,
            name_th: "หัวเมือง",
            name_en: "Hua Mueang",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350603,
            zip_code: 35130,
            name_th: "คูเมือง",
            name_en: "Khu Mueang",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350604,
            zip_code: 35130,
            name_th: "ผือฮี",
            name_en: "Phue Hi",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350605,
            zip_code: 35130,
            name_th: "บากเรือ",
            name_en: "Bak Ruea",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350606,
            zip_code: 35130,
            name_th: "ม่วง",
            name_en: "Muang",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350607,
            zip_code: 35130,
            name_th: "โนนทราย",
            name_en: "Non Sai",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350608,
            zip_code: 35130,
            name_th: "บึงแก",
            name_en: "Bueng Kae",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350609,
            zip_code: 35130,
            name_th: "พระเสาร์",
            name_en: "Phra Sao",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350610,
            zip_code: 35130,
            name_th: "สงยาง",
            name_en: "Song Yang",
            amphure_id: 3506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3507,
        name_th: "ค้อวัง",
        name_en: "Kho Wang",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350701,
            zip_code: 35160,
            name_th: "ฟ้าห่วน",
            name_en: "Fa Huan",
            amphure_id: 3507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350702,
            zip_code: 35160,
            name_th: "กุดน้ำใส",
            name_en: "Kut Nam Sai",
            amphure_id: 3507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350703,
            zip_code: 35160,
            name_th: "น้ำอ้อม",
            name_en: "Nam Om",
            amphure_id: 3507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350704,
            zip_code: 35160,
            name_th: "ค้อวัง",
            name_en: "Kho Wang",
            amphure_id: 3507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3508,
        name_th: "เลิงนกทา",
        name_en: "Loeng Nok Tha",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350802,
            zip_code: 35120,
            name_th: "บุ่งค้า",
            name_en: "Bung Kha",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350803,
            zip_code: 35120,
            name_th: "สวาท",
            name_en: "Sawat",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350805,
            zip_code: 35120,
            name_th: "ห้องแซง",
            name_en: "Hong Saeng",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350806,
            zip_code: 35120,
            name_th: "สามัคคี",
            name_en: "Samakkhi",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350807,
            zip_code: 35120,
            name_th: "กุดเชียงหมี",
            name_en: "Kut Chiang Mi",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350810,
            zip_code: 35120,
            name_th: "สามแยก",
            name_en: "Sam Yaek",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350811,
            zip_code: 35120,
            name_th: "กุดแห่",
            name_en: "Kut Hae",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350812,
            zip_code: 35120,
            name_th: "โคกสำราญ",
            name_en: "Khok Samran",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350813,
            zip_code: 35120,
            name_th: "สร้างมิ่ง",
            name_en: "Sang Ming",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350814,
            zip_code: 35120,
            name_th: "ศรีแก้ว",
            name_en: "Si Kaeo",
            amphure_id: 3508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3509,
        name_th: "ไทยเจริญ",
        name_en: "Thai Charoen",
        province_id: 24,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 350901,
            zip_code: 35120,
            name_th: "ไทยเจริญ",
            name_en: "Thai Charoen",
            amphure_id: 3509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350902,
            zip_code: 35120,
            name_th: "น้ำคำ",
            name_en: "Nam Kham",
            amphure_id: 3509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350903,
            zip_code: 35120,
            name_th: "ส้มผ่อ",
            name_en: "Som Pho",
            amphure_id: 3509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350904,
            zip_code: 35120,
            name_th: "คำเตย",
            name_en: "Kham Toei",
            amphure_id: 3509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 350905,
            zip_code: 35120,
            name_th: "คำไผ่",
            name_en: "Kham Phai",
            amphure_id: 3509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 25,
    name_th: "ชัยภูมิ",
    name_en: "Chaiyaphum",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3601,
        name_th: "เมืองชัยภูมิ",
        name_en: "Mueang Chaiyaphum",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360101,
            zip_code: 36000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360102,
            zip_code: 36000,
            name_th: "รอบเมือง",
            name_en: "Rop Mueang",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360103,
            zip_code: 36000,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360104,
            zip_code: 36000,
            name_th: "นาฝาย",
            name_en: "Na Fai",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360105,
            zip_code: 36240,
            name_th: "บ้านค่าย",
            name_en: "Ban Khai",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360106,
            zip_code: 36000,
            name_th: "กุดตุ้ม",
            name_en: "Kut Tum",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360107,
            zip_code: 36000,
            name_th: "ชีลอง",
            name_en: "Chi Long",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360108,
            zip_code: 36000,
            name_th: "บ้านเล่า",
            name_en: "Ban Lao",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360109,
            zip_code: 36000,
            name_th: "นาเสียว",
            name_en: "Na Siao",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360110,
            zip_code: 36000,
            name_th: "หนองนาแซง",
            name_en: "Nong Na Saeng",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360111,
            zip_code: 36000,
            name_th: "ลาดใหญ่",
            name_en: "Lat Yai",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360112,
            zip_code: 36240,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360113,
            zip_code: 36000,
            name_th: "ท่าหินโงม",
            name_en: "Tha Hin Ngom",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360114,
            zip_code: 36000,
            name_th: "ห้วยต้อน",
            name_en: "Huai Ton",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360115,
            zip_code: 36000,
            name_th: "ห้วยบง",
            name_en: "Huai Bong",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360116,
            zip_code: 36240,
            name_th: "โนนสำราญ",
            name_en: "Non Samran",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360117,
            zip_code: 36000,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360118,
            zip_code: 36000,
            name_th: "บุ่งคล้า",
            name_en: "Bung Khla",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360119,
            zip_code: 36000,
            name_th: "ซับสีทอง",
            name_en: "Sap Si Thong",
            amphure_id: 3601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3602,
        name_th: "บ้านเขว้า",
        name_en: "Ban Khwao",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360201,
            zip_code: 36170,
            name_th: "บ้านเขว้า",
            name_en: "Ban Khwao",
            amphure_id: 3602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360202,
            zip_code: 36170,
            name_th: "ตลาดแร้ง",
            name_en: "Talat Raeng",
            amphure_id: 3602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360203,
            zip_code: 36170,
            name_th: "ลุ่มลำชี",
            name_en: "Lum Lam Chi",
            amphure_id: 3602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360204,
            zip_code: 36170,
            name_th: "ชีบน",
            name_en: "Chi Bon",
            amphure_id: 3602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360205,
            zip_code: 36170,
            name_th: "ภูแลนคา",
            name_en: "Phu Laen Kha",
            amphure_id: 3602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360206,
            zip_code: 36170,
            name_th: "โนนแดง",
            name_en: "Non Dang",
            amphure_id: 3602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3603,
        name_th: "คอนสวรรค์",
        name_en: "Khon Sawan",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360301,
            zip_code: 36140,
            name_th: "คอนสวรรค์",
            name_en: "Khon Sawan",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360302,
            zip_code: 36140,
            name_th: "ยางหวาย",
            name_en: "Yang Wai",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360303,
            zip_code: 36140,
            name_th: "ช่องสามหมอ",
            name_en: "Chong Sam Mo)",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360304,
            zip_code: 36140,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360305,
            zip_code: 36140,
            name_th: "ห้วยไร่",
            name_en: "Huai Rai",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360306,
            zip_code: 36140,
            name_th: "บ้านโสก",
            name_en: "Ban Sok",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360307,
            zip_code: 36140,
            name_th: "โคกมั่งงอย",
            name_en: "Khok Mang Ngoi",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360308,
            zip_code: 36140,
            name_th: "หนองขาม",
            name_en: "Nong Kham",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360309,
            zip_code: 36140,
            name_th: "ศรีสำราญ",
            name_en: "Si Samran",
            amphure_id: 3603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3604,
        name_th: "เกษตรสมบูรณ์",
        name_en: "Kaset Sombun",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360401,
            zip_code: 36120,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360402,
            zip_code: 36120,
            name_th: "บ้านหัน",
            name_en: "Ban Han",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360403,
            zip_code: 36120,
            name_th: "บ้านเดื่อ",
            name_en: "Ban Duea",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360404,
            zip_code: 36120,
            name_th: "บ้านเป้า",
            name_en: "Ban Pao",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360405,
            zip_code: 36120,
            name_th: "กุดเลาะ",
            name_en: "Kut Lo",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360406,
            zip_code: 36120,
            name_th: "โนนกอก",
            name_en: "Non Kok",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360407,
            zip_code: 36120,
            name_th: "สระโพนทอง",
            name_en: "Sa Phon Thong",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360408,
            zip_code: 36120,
            name_th: "หนองข่า",
            name_en: "Nong Kha",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360409,
            zip_code: 36120,
            name_th: "หนองโพนงาม",
            name_en: "Nong Phon Ngam",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360410,
            zip_code: 36120,
            name_th: "บ้านบัว",
            name_en: "Ban Bua",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360412,
            zip_code: 36120,
            name_th: "โนนทอง",
            name_en: "Non Thong",
            amphure_id: 3604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3605,
        name_th: "หนองบัวแดง",
        name_en: "Nong Bua Daeng",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360501,
            zip_code: 36210,
            name_th: "หนองบัวแดง",
            name_en: "Nong Bua Daeng",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360502,
            zip_code: 36210,
            name_th: "กุดชุมแสง",
            name_en: "Kut Chum Saeng",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360503,
            zip_code: 36210,
            name_th: "ถ้ำวัวแดง",
            name_en: "Tham Wua Daeng",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360504,
            zip_code: 36210,
            name_th: "นางแดด",
            name_en: "Nang Daet",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360507,
            zip_code: 36210,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360508,
            zip_code: 36210,
            name_th: "คูเมือง",
            name_en: "Khu Mueang",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360509,
            zip_code: 36210,
            name_th: "ท่าใหญ่",
            name_en: "Tha Yai",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360511,
            zip_code: 36210,
            name_th: "วังชมภู",
            name_en: "Wang Chomphu",
            amphure_id: 3605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3606,
        name_th: "จัตุรัส",
        name_en: "Chatturat",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360601,
            zip_code: 36130,
            name_th: "บ้านกอก",
            name_en: "Ban Kok",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360602,
            zip_code: 36130,
            name_th: "หนองบัวบาน",
            name_en: "Nong Bua Ban",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360603,
            zip_code: 36130,
            name_th: "บ้านขาม",
            name_en: "Ban Kham",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360605,
            zip_code: 36130,
            name_th: "กุดน้ำใส",
            name_en: "Kut Nam Sai",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360606,
            zip_code: 36130,
            name_th: "หนองโดน",
            name_en: "Nong Don",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360607,
            zip_code: 36130,
            name_th: "ละหาน",
            name_en: "Lahan",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360610,
            zip_code: 36130,
            name_th: "หนองบัวใหญ่",
            name_en: "Nong Bua Yai",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360611,
            zip_code: 36220,
            name_th: "หนองบัวโคก",
            name_en: "Nong Bua Khok",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360613,
            zip_code: 36130,
            name_th: "ส้มป่อย",
            name_en: "Sompoi",
            amphure_id: 3606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3607,
        name_th: "บำเหน็จณรงค์",
        name_en: "Bamnet Narong",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360701,
            zip_code: 36160,
            name_th: "บ้านชวน",
            name_en: "Ban Chuan",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360702,
            zip_code: 36160,
            name_th: "บ้านเพชร",
            name_en: "Ban Phet",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360703,
            zip_code: 36220,
            name_th: "บ้านตาล",
            name_en: "Ban Tan",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360704,
            zip_code: 36220,
            name_th: "หัวทะเล",
            name_en: "Hua Thale",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360705,
            zip_code: 36160,
            name_th: "โคกเริงรมย์",
            name_en: "Khok Roeng Rom",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360706,
            zip_code: 36160,
            name_th: "เกาะมะนาว",
            name_en: "Ko Manao",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360707,
            zip_code: 36160,
            name_th: "โคกเพชรพัฒนา",
            name_en: "Khok Phet Phatthana",
            amphure_id: 3607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3608,
        name_th: "หนองบัวระเหว",
        name_en: "Nong Bua Rawe",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360801,
            zip_code: 36250,
            name_th: "หนองบัวระเหว",
            name_en: "Nong Bua Rawe",
            amphure_id: 3608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360802,
            zip_code: 36250,
            name_th: "วังตะเฆ่",
            name_en: "Wang Takhe",
            amphure_id: 3608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360803,
            zip_code: 36250,
            name_th: "ห้วยแย้",
            name_en: "Huai Yae",
            amphure_id: 3608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360804,
            zip_code: 36250,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 3608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360805,
            zip_code: 36250,
            name_th: "โสกปลาดุก",
            name_en: "Sok Pla Duk",
            amphure_id: 3608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3609,
        name_th: "เทพสถิต",
        name_en: "Thep Sathit",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 360901,
            zip_code: 36230,
            name_th: "วะตะแบก",
            name_en: "Wa Tabaek",
            amphure_id: 3609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360902,
            zip_code: 36230,
            name_th: "ห้วยยายจิ๋ว",
            name_en: "Huai Yai Chio",
            amphure_id: 3609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360903,
            zip_code: 36230,
            name_th: "นายางกลัก",
            name_en: "Na Yang Klak",
            amphure_id: 3609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360904,
            zip_code: 36230,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 3609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 360905,
            zip_code: 36230,
            name_th: "โป่งนก",
            name_en: "Pong Nok",
            amphure_id: 3609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3610,
        name_th: "ภูเขียว",
        name_en: "Phu Khiao",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361001,
            zip_code: 36110,
            name_th: "ผักปัง",
            name_en: "Phak Pang",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361002,
            zip_code: 36110,
            name_th: "กวางโจน",
            name_en: "Kwang Chon",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361003,
            zip_code: 36110,
            name_th: "หนองคอนไทย",
            name_en: "Nong Khon Thai",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361004,
            zip_code: 36110,
            name_th: "บ้านแก้ง",
            name_en: "Ban Kaeng",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361005,
            zip_code: 36110,
            name_th: "กุดยม",
            name_en: "Kut Yom",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361006,
            zip_code: 36110,
            name_th: "บ้านเพชร",
            name_en: "Ban Phet",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361007,
            zip_code: 36110,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361008,
            zip_code: 36110,
            name_th: "หนองตูม",
            name_en: "Nong Tum",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361009,
            zip_code: 36110,
            name_th: "โอโล",
            name_en: "Olo",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361010,
            zip_code: 36110,
            name_th: "ธาตุทอง",
            name_en: "That Thong",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361011,
            zip_code: 36110,
            name_th: "บ้านดอน",
            name_en: "Ban Don",
            amphure_id: 3610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3611,
        name_th: "บ้านแท่น",
        name_en: "Ban Thaen",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361101,
            zip_code: 36190,
            name_th: "บ้านแท่น",
            name_en: "Ban Thaen",
            amphure_id: 3611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361102,
            zip_code: 36190,
            name_th: "สามสวน",
            name_en: "Sam Suan",
            amphure_id: 3611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361103,
            zip_code: 36190,
            name_th: "สระพัง",
            name_en: "Sa Phang",
            amphure_id: 3611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361104,
            zip_code: 36190,
            name_th: "บ้านเต่า",
            name_en: "Ban Tao",
            amphure_id: 3611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361105,
            zip_code: 36190,
            name_th: "หนองคู",
            name_en: "Nong Khu",
            amphure_id: 3611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3612,
        name_th: "แก้งคร้อ",
        name_en: "Kaeng Khro",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361201,
            zip_code: 36150,
            name_th: "ช่องสามหมอ",
            name_en: "Chong Sam Mo",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361202,
            zip_code: 36150,
            name_th: "หนองขาม",
            name_en: "Nong Kham",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361203,
            zip_code: 36150,
            name_th: "นาหนองทุ่ม",
            name_en: "Na Nong Thum",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361204,
            zip_code: 36150,
            name_th: "บ้านแก้ง",
            name_en: "Ban Kaeng",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361205,
            zip_code: 36150,
            name_th: "หนองสังข์",
            name_en: "Nong Sang",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361206,
            zip_code: 36150,
            name_th: "หลุบคา",
            name_en: "Lup Kha",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361207,
            zip_code: 36150,
            name_th: "โคกกุง",
            name_en: "Khok Kung",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361208,
            zip_code: 36150,
            name_th: "เก่าย่าดี",
            name_en: "Kao Ya Di",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361209,
            zip_code: 36150,
            name_th: "ท่ามะไฟหวาน",
            name_en: "Tha Mafai Wan",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361210,
            zip_code: 36150,
            name_th: "หนองไผ่",
            name_en: "Nong Phai)",
            amphure_id: 3612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3613,
        name_th: "คอนสาร",
        name_en: "Khon San",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361301,
            zip_code: 36180,
            name_th: "คอนสาร",
            name_en: "Khon San",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361302,
            zip_code: 36180,
            name_th: "ทุ่งพระ",
            name_en: "Thung Phra",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361303,
            zip_code: 36180,
            name_th: "โนนคูณ",
            name_en: "Non Khun",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361304,
            zip_code: 36180,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361305,
            zip_code: 36180,
            name_th: "ทุ่งลุยลาย",
            name_en: "Thung Luilai",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361306,
            zip_code: 36180,
            name_th: "ดงบัง",
            name_en: "Dong Bang",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361307,
            zip_code: 36180,
            name_th: "ทุ่งนาเลา",
            name_en: "Thung Na Lao",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361308,
            zip_code: 36180,
            name_th: "ดงกลาง",
            name_en: "Dong Klang",
            amphure_id: 3613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3614,
        name_th: "ภักดีชุมพล",
        name_en: "Phakdi Chumphon",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361401,
            zip_code: 36260,
            name_th: "บ้านเจียง",
            name_en: "Chao Thong",
            amphure_id: 3614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361402,
            zip_code: 36260,
            name_th: "เจาทอง",
            name_en: "Ban Chiang",
            amphure_id: 3614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361403,
            zip_code: 36260,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 3614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361404,
            zip_code: 36260,
            name_th: "แหลมทอง",
            name_en: "Laem Thong",
            amphure_id: 3614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3615,
        name_th: "เนินสง่า",
        name_en: "Noen Sa-nga",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361501,
            zip_code: 36130,
            name_th: "หนองฉิม",
            name_en: "Nong Chim",
            amphure_id: 3615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361502,
            zip_code: 36130,
            name_th: "ตาเนิน",
            name_en: "Ta Noen",
            amphure_id: 3615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361503,
            zip_code: 36130,
            name_th: "กะฮาด",
            name_en: "Kahat",
            amphure_id: 3615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361504,
            zip_code: 36130,
            name_th: "รังงาม",
            name_en: "Rang Ngam",
            amphure_id: 3615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3616,
        name_th: "ซับใหญ่",
        name_en: "Sap Yai",
        province_id: 25,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 361601,
            zip_code: 36130,
            name_th: "ซับใหญ่",
            name_en: "Sap Yai",
            amphure_id: 3616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361602,
            zip_code: 36130,
            name_th: "ท่ากูบ",
            name_en: "Tha Kup",
            amphure_id: 3616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 361603,
            zip_code: 36130,
            name_th: "ตะโกทอง",
            name_en: "Tako Thong",
            amphure_id: 3616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 26,
    name_th: "อำนาจเจริญ",
    name_en: "Amnat Charoen",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3701,
        name_th: "เมืองอำนาจเจริญ",
        name_en: "Mueang Amnat Charoen",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370101,
            zip_code: 37000,
            name_th: "บุ่ง",
            name_en: "Bung",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370102,
            zip_code: 37000,
            name_th: "ไก่คำ",
            name_en: "Kai Kham",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370103,
            zip_code: 37000,
            name_th: "นาจิก",
            name_en: "Na Chik",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370104,
            zip_code: 37000,
            name_th: "ปลาค้าว",
            name_en: "Pla Khao",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370105,
            zip_code: 37000,
            name_th: "เหล่าพรวน",
            name_en: "Lao Pruan",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370106,
            zip_code: 37000,
            name_th: "สร้างนกทา",
            name_en: "Sang Nok Tha",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370107,
            zip_code: 37000,
            name_th: "คึมใหญ่",
            name_en: "Khuem Yai",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370108,
            zip_code: 37000,
            name_th: "นาผือ",
            name_en: "Na Phue",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370109,
            zip_code: 37000,
            name_th: "น้ำปลีก",
            name_en: "Nam Plik",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370110,
            zip_code: 37000,
            name_th: "นาวัง",
            name_en: "Na Wang",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370111,
            zip_code: 37000,
            name_th: "นาหมอม้า",
            name_en: "Na Mo Ma",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370112,
            zip_code: 37000,
            name_th: "โนนโพธิ์",
            name_en: "Non Pho",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370113,
            zip_code: 37000,
            name_th: "โนนหนามแท่ง",
            name_en: "Non Nam Thaeng",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370114,
            zip_code: 37000,
            name_th: "ห้วยไร่",
            name_en: "Huai Rai",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370115,
            zip_code: 37000,
            name_th: "หนองมะแซว",
            name_en: "Nong Masaeo",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370116,
            zip_code: 37000,
            name_th: "กุดปลาดุก",
            name_en: "Kut Pla Duk",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370117,
            zip_code: 37000,
            name_th: "ดอนเมย",
            name_en: "Don Moei",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370118,
            zip_code: 37000,
            name_th: "นายม",
            name_en: "Na Yom",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370119,
            zip_code: 37000,
            name_th: "นาแต้",
            name_en: "Na Tae",
            amphure_id: 3701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3702,
        name_th: "ชานุมาน",
        name_en: "Chanuman",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370201,
            zip_code: 37210,
            name_th: "ชานุมาน",
            name_en: "Chanuman",
            amphure_id: 3702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370202,
            zip_code: 37210,
            name_th: "โคกสาร",
            name_en: "Khok San",
            amphure_id: 3702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370203,
            zip_code: 37210,
            name_th: "คำเขื่อนแก้ว",
            name_en: "Kham Khuean Kaeo",
            amphure_id: 3702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370204,
            zip_code: 37210,
            name_th: "โคกก่ง",
            name_en: "Khok Kong",
            amphure_id: 3702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370205,
            zip_code: 37210,
            name_th: "ป่าก่อ",
            name_en: "Pa Ko",
            amphure_id: 3702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3703,
        name_th: "ปทุมราชวงศา",
        name_en: "Pathum Ratchawongsa",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370301,
            zip_code: 37110,
            name_th: "หนองข่า",
            name_en: "Nong Kha",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370302,
            zip_code: 37110,
            name_th: "คำโพน",
            name_en: "Kham Phon",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370303,
            zip_code: 37110,
            name_th: "นาหว้า",
            name_en: "Na Wa",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370304,
            zip_code: 37110,
            name_th: "ลือ",
            name_en: "Lue",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370305,
            zip_code: 37110,
            name_th: "ห้วย",
            name_en: "Huai",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370306,
            zip_code: 37110,
            name_th: "โนนงาม",
            name_en: "Non Ngam",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370307,
            zip_code: 37110,
            name_th: "นาป่าแซง",
            name_en: "Na Pa Saeng",
            amphure_id: 3703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3704,
        name_th: "พนา",
        name_en: "Phana",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370401,
            zip_code: 37180,
            name_th: "พนา",
            name_en: "Phana",
            amphure_id: 3704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370402,
            zip_code: 37180,
            name_th: "จานลาน",
            name_en: "Chan Lan",
            amphure_id: 3704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370403,
            zip_code: 37180,
            name_th: "ไม้กลอน",
            name_en: "Mai Klon",
            amphure_id: 3704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370404,
            zip_code: 37180,
            name_th: "พระเหลา",
            name_en: "Phra Lao",
            amphure_id: 3704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3705,
        name_th: "เสนางคนิคม",
        name_en: "Senangkhanikhom",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370501,
            zip_code: 37290,
            name_th: "เสนางคนิคม",
            name_en: "Senangkhanikhom",
            amphure_id: 3705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370502,
            zip_code: 37290,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 3705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370503,
            zip_code: 37290,
            name_th: "ไร่สีสุก",
            name_en: "Rai Si Suk",
            amphure_id: 3705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370504,
            zip_code: 37290,
            name_th: "นาเวียง",
            name_en: "Na Wiang",
            amphure_id: 3705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370505,
            zip_code: 37290,
            name_th: "หนองไฮ",
            name_en: "Nong Hai",
            amphure_id: 3705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370506,
            zip_code: 37290,
            name_th: "หนองสามสี",
            name_en: "Nong Sam Si",
            amphure_id: 3705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3706,
        name_th: "หัวตะพาน",
        name_en: "Hua Taphan",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370601,
            zip_code: 37240,
            name_th: "หัวตะพาน",
            name_en: "Hua Taphan",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370602,
            zip_code: 37240,
            name_th: "คำพระ",
            name_en: "Kham Phra",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370603,
            zip_code: 37240,
            name_th: "เค็งใหญ่",
            name_en: "Kheng Yai",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370604,
            zip_code: 37240,
            name_th: "หนองแก้ว",
            name_en: "Nong Kaeo",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370605,
            zip_code: 37240,
            name_th: "โพนเมืองน้อย",
            name_en: "Phon Mueang Noi",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370606,
            zip_code: 37240,
            name_th: "สร้างถ่อน้อย",
            name_en: "Sang Tho Noi",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370607,
            zip_code: 37240,
            name_th: "จิกดู่",
            name_en: "Chik Du",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370608,
            zip_code: 37240,
            name_th: "รัตนวารี",
            name_en: "Rattanawari",
            amphure_id: 3706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3707,
        name_th: "ลืออำนาจ",
        name_en: "Lue Amnat",
        province_id: 26,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 370701,
            zip_code: 37000,
            name_th: "อำนาจ",
            name_en: "Amnat",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370702,
            zip_code: 37000,
            name_th: "ดงมะยาง",
            name_en: "Dong Mayang",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370703,
            zip_code: 37000,
            name_th: "เปือย",
            name_en: "Pueai",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370704,
            zip_code: 37000,
            name_th: "ดงบัง",
            name_en: "Dong Bang",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370705,
            zip_code: 37000,
            name_th: "ไร่ขี",
            name_en: "Rai Khi",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370706,
            zip_code: 37000,
            name_th: "แมด",
            name_en: "Maet",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 370707,
            zip_code: 37000,
            name_th: "โคกกลาง",
            name_en: "Khok Klang",
            amphure_id: 3707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 27,
    name_th: "หนองบัวลำภู",
    name_en: "Nong Bua Lam Phu",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3901,
        name_th: "เมืองหนองบัวลำภู",
        name_en: "Mueang Nong Bua Lam Phu",
        province_id: 27,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 390101,
            zip_code: 39000,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390102,
            zip_code: 39000,
            name_th: "หนองภัยศูนย์",
            name_en: "Nong Phai Sun",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390103,
            zip_code: 39000,
            name_th: "โพธิ์ชัย",
            name_en: "Pho Chai",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390104,
            zip_code: 39000,
            name_th: "หนองสวรรค์",
            name_en: "Nong Sawan",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390105,
            zip_code: 39000,
            name_th: "หัวนา",
            name_en: "Hua Na",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390106,
            zip_code: 39000,
            name_th: "บ้านขาม",
            name_en: "Ban Kham",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390107,
            zip_code: 39000,
            name_th: "นามะเฟือง",
            name_en: "Na Mafueang",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390108,
            zip_code: 39000,
            name_th: "บ้านพร้าว",
            name_en: "Ban Phrao",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390109,
            zip_code: 39000,
            name_th: "โนนขมิ้น",
            name_en: "Non Khamin",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390110,
            zip_code: 39000,
            name_th: "ลำภู",
            name_en: "Lam Phu",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390111,
            zip_code: 39000,
            name_th: "กุดจิก",
            name_en: "Kut Chik",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390112,
            zip_code: 39000,
            name_th: "โนนทัน",
            name_en: "Non Than",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390113,
            zip_code: 39000,
            name_th: "นาคำไฮ",
            name_en: "Na Kham Hai",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390114,
            zip_code: 39000,
            name_th: "ป่าไม้งาม",
            name_en: "Pa Mai Ngam",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390115,
            zip_code: 39000,
            name_th: "หนองหว้า",
            name_en: "Nong Wa",
            amphure_id: 3901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3902,
        name_th: "นากลาง",
        name_en: "Na Klang",
        province_id: 27,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 390201,
            zip_code: 39170,
            name_th: "นากลาง",
            name_en: "Na Klang",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390202,
            zip_code: 39170,
            name_th: "ด่านช้าง",
            name_en: "Dan Chang",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390205,
            zip_code: 39350,
            name_th: "กุดดินจี่",
            name_en: "Kut Din Chi",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390206,
            zip_code: 39170,
            name_th: "ฝั่งแดง",
            name_en: "Fang Daeng",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390207,
            zip_code: 39350,
            name_th: "เก่ากลอย",
            name_en: "Kao Kloi",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390209,
            zip_code: 39170,
            name_th: "โนนเมือง",
            name_en: "Non Mueang",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390210,
            zip_code: 39170,
            name_th: "อุทัยสวรรค์",
            name_en: "Uthai Sawan",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390211,
            zip_code: 39350,
            name_th: "ดงสวรรค์",
            name_en: "Dong Sawan",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390213,
            zip_code: 39170,
            name_th: "กุดแห่",
            name_en: "Kut Hae",
            amphure_id: 3902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3903,
        name_th: "โนนสัง",
        name_en: "Non Sang",
        province_id: 27,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 390301,
            zip_code: 39140,
            name_th: "โนนสัง",
            name_en: "Non Sang",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390302,
            zip_code: 39140,
            name_th: "บ้านถิ่น",
            name_en: "Ban Thin",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390303,
            zip_code: 39140,
            name_th: "หนองเรือ",
            name_en: "Nong Ruea",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390304,
            zip_code: 39140,
            name_th: "กุดดู่",
            name_en: "Kut Du",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390305,
            zip_code: 39140,
            name_th: "บ้านค้อ",
            name_en: "Ban Kho",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390306,
            zip_code: 39140,
            name_th: "โนนเมือง",
            name_en: "Non Mueang",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390307,
            zip_code: 39140,
            name_th: "โคกใหญ่",
            name_en: "Khok Yai",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390308,
            zip_code: 39140,
            name_th: "โคกม่วง",
            name_en: "Khok Muang",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390309,
            zip_code: 39140,
            name_th: "นิคมพัฒนา",
            name_en: "Nikhom Phatthana",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390310,
            zip_code: 39140,
            name_th: "ปางกู่",
            name_en: "Pang Ku",
            amphure_id: 3903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3904,
        name_th: "ศรีบุญเรือง",
        name_en: "Si Bun Rueang",
        province_id: 27,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 390401,
            zip_code: 39180,
            name_th: "เมืองใหม่",
            name_en: "Mueang Mai",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390402,
            zip_code: 39180,
            name_th: "ศรีบุญเรือง",
            name_en: "Si Bun Rueang",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390403,
            zip_code: 39180,
            name_th: "หนองบัวใต้",
            name_en: "Nong Bua Tai",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390404,
            zip_code: 39180,
            name_th: "กุดสะเทียน",
            name_en: "Kut Sathian",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390405,
            zip_code: 39180,
            name_th: "นากอก",
            name_en: "Na Kok",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390406,
            zip_code: 39180,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390407,
            zip_code: 39180,
            name_th: "ยางหล่อ",
            name_en: "Yang Lo",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390408,
            zip_code: 39180,
            name_th: "โนนม่วง",
            name_en: "Non Muang",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390409,
            zip_code: 39180,
            name_th: "หนองกุงแก้ว",
            name_en: "Nong Kung Kaeo",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390410,
            zip_code: 39180,
            name_th: "หนองแก",
            name_en: "Nong Kae",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390411,
            zip_code: 39180,
            name_th: "ทรายทอง",
            name_en: "Sai Thong",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390412,
            zip_code: 39180,
            name_th: "หันนางาม",
            name_en: "Han Na Ngam",
            amphure_id: 3904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3905,
        name_th: "สุวรรณคูหา",
        name_en: "Suwannakhuha",
        province_id: 27,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 390501,
            zip_code: 39270,
            name_th: "นาสี",
            name_en: "Nasi",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390502,
            zip_code: 39270,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390503,
            zip_code: 39270,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390504,
            zip_code: 39270,
            name_th: "นาด่าน",
            name_en: "Na Dan",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390505,
            zip_code: 39270,
            name_th: "ดงมะไฟ",
            name_en: "Dong Mafai",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390506,
            zip_code: 39270,
            name_th: "สุวรรณคูหา",
            name_en: "Suwannakhuha",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390507,
            zip_code: 39270,
            name_th: "บุญทัน",
            name_en: "Bun Than",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390508,
            zip_code: 39270,
            name_th: "กุดผึ้ง",
            name_en: "Kut Phueng",
            amphure_id: 3905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3906,
        name_th: "นาวัง",
        name_en: "Na Wang",
        province_id: 27,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 390601,
            zip_code: 39170,
            name_th: "นาเหล่า",
            name_en: "Na Lao",
            amphure_id: 3906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390602,
            zip_code: 39170,
            name_th: "นาแก",
            name_en: "Na Kae",
            amphure_id: 3906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390603,
            zip_code: 39170,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 3906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390604,
            zip_code: 39170,
            name_th: "วังปลาป้อม",
            name_en: "Wang Pla Pom",
            amphure_id: 3906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 390605,
            zip_code: 39170,
            name_th: "เทพคีรี",
            name_en: "Thep Khiri",
            amphure_id: 3906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 28,
    name_th: "ขอนแก่น",
    name_en: "Khon Kaen",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4001,
        name_th: "เมืองขอนแก่น",
        name_en: "Mueang Khon Kaen",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400101,
            zip_code: 40000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400102,
            zip_code: 40000,
            name_th: "สำราญ",
            name_en: "Samran",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400103,
            zip_code: 40000,
            name_th: "โคกสี",
            name_en: "Khok Si",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400104,
            zip_code: 40260,
            name_th: "ท่าพระ",
            name_en: "Tha Phra",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400105,
            zip_code: 40000,
            name_th: "บ้านทุ่ม",
            name_en: "Ban Thum",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400106,
            zip_code: 40000,
            name_th: "เมืองเก่า",
            name_en: "Mueang Kao",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400107,
            zip_code: 40000,
            name_th: "พระลับ",
            name_en: "Phra Lap",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400108,
            zip_code: 40000,
            name_th: "สาวะถี",
            name_en: "Sawathi",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400109,
            zip_code: 40000,
            name_th: "บ้านหว้า",
            name_en: "Ban Wa",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400110,
            zip_code: 40000,
            name_th: "บ้านค้อ",
            name_en: "Ban Kho",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400111,
            zip_code: 40000,
            name_th: "แดงใหญ่",
            name_en: "Daeng Yai",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400112,
            zip_code: 40000,
            name_th: "ดอนช้าง",
            name_en: "Don Chang",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400113,
            zip_code: 40260,
            name_th: "ดอนหัน",
            name_en: "Don Han",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400114,
            zip_code: 40000,
            name_th: "ศิลา",
            name_en: "Sila",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400115,
            zip_code: 40000,
            name_th: "บ้านเป็ด",
            name_en: "Ban Pet",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400116,
            zip_code: 40000,
            name_th: "หนองตูม",
            name_en: "Nong Tum",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400117,
            zip_code: 40000,
            name_th: "บึงเนียม",
            name_en: "Bueng Niam",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400118,
            zip_code: 40000,
            name_th: "โนนท่อน",
            name_en: "Non Thon",
            amphure_id: 4001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4002,
        name_th: "บ้านฝาง",
        name_en: "Ban Fang",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400201,
            zip_code: 40270,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400202,
            zip_code: 40270,
            name_th: "ป่าหวายนั่ง",
            name_en: "Pa Wai Nang",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400203,
            zip_code: 40270,
            name_th: "โนนฆ้อง",
            name_en: "Non Khong",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400204,
            zip_code: 40270,
            name_th: "บ้านเหล่า",
            name_en: "Ban Lao",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400205,
            zip_code: 40270,
            name_th: "ป่ามะนาว",
            name_en: "Pa Manao",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400206,
            zip_code: 40270,
            name_th: "บ้านฝาง",
            name_en: "Ban Fang",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400207,
            zip_code: 40270,
            name_th: "โคกงาม",
            name_en: "Khok Ngam",
            amphure_id: 4002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4003,
        name_th: "พระยืน",
        name_en: "Phra Yuen",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400301,
            zip_code: 40320,
            name_th: "พระยืน",
            name_en: "Phra Yuen",
            amphure_id: 4003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400302,
            zip_code: 40320,
            name_th: "พระบุ",
            name_en: "Phra Bu",
            amphure_id: 4003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400303,
            zip_code: 40320,
            name_th: "บ้านโต้น",
            name_en: "Ban Ton",
            amphure_id: 4003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400304,
            zip_code: 40320,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400305,
            zip_code: 40320,
            name_th: "ขามป้อม",
            name_en: "Kham Pom",
            amphure_id: 4003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4004,
        name_th: "หนองเรือ",
        name_en: "Nong Ruea",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400401,
            zip_code: 40210,
            name_th: "หนองเรือ",
            name_en: "Nong Ruea",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400402,
            zip_code: 40210,
            name_th: "บ้านเม็ง",
            name_en: "Ban Meng",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400403,
            zip_code: 40240,
            name_th: "บ้านกง",
            name_en: "Ban Kong",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400404,
            zip_code: 40240,
            name_th: "ยางคำ",
            name_en: "Yang Kham",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400405,
            zip_code: 40240,
            name_th: "จระเข้",
            name_en: "Chorakhe",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400406,
            zip_code: 40210,
            name_th: "โนนทอง",
            name_en: "Non Thong",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400407,
            zip_code: 40210,
            name_th: "กุดกว้าง",
            name_en: "Kut Kwang",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400408,
            zip_code: 40210,
            name_th: "โนนทัน",
            name_en: "Non Than",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400409,
            zip_code: 40210,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400410,
            zip_code: 40240,
            name_th: "บ้านผือ",
            name_en: "Ban Phue",
            amphure_id: 4004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4005,
        name_th: "ชุมแพ",
        name_en: "Chum Phae",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400501,
            zip_code: 40130,
            name_th: "ชุมแพ",
            name_en: "Chum Phae",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400502,
            zip_code: 40290,
            name_th: "โนนหัน",
            name_en: "Non Han",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400503,
            zip_code: 40290,
            name_th: "นาหนองทุ่ม",
            name_en: "Na Nong Thum",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400504,
            zip_code: 40130,
            name_th: "โนนอุดม",
            name_en: "Non Udom",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400505,
            zip_code: 40130,
            name_th: "ขัวเรียง",
            name_en: "Khua Riang",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400506,
            zip_code: 40130,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400507,
            zip_code: 40130,
            name_th: "ไชยสอ",
            name_en: "Chai So",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400508,
            zip_code: 40130,
            name_th: "วังหินลาด",
            name_en: "Wang Hin Lat",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400509,
            zip_code: 40130,
            name_th: "นาเพียง",
            name_en: "Na Phiang",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400510,
            zip_code: 40290,
            name_th: "หนองเขียด",
            name_en: "Nong Khiat",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400511,
            zip_code: 40130,
            name_th: "หนองเสาเล้า",
            name_en: "Nong Sao Lao",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400512,
            zip_code: 40290,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 4005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4006,
        name_th: "สีชมพู",
        name_en: "Si Chomphu",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400601,
            zip_code: 40220,
            name_th: "สีชมพู",
            name_en: "Si Chomphu",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400602,
            zip_code: 40220,
            name_th: "ศรีสุข",
            name_en: "Si Suk",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400603,
            zip_code: 40220,
            name_th: "นาจาน",
            name_en: "Na Chan",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400604,
            zip_code: 40220,
            name_th: "วังเพิ่ม",
            name_en: "Wang Phoem",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400605,
            zip_code: 40220,
            name_th: "ซำยาง",
            name_en: "Sam Yang",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400606,
            zip_code: 40220,
            name_th: "หนองแดง",
            name_en: "Nong Daeng",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400607,
            zip_code: 40220,
            name_th: "ดงลาน",
            name_en: "Dong Lan",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400608,
            zip_code: 40220,
            name_th: "บริบูรณ์",
            name_en: "Boribun",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400609,
            zip_code: 40220,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400610,
            zip_code: 40220,
            name_th: "ภูห่าน",
            name_en: "Phu Han",
            amphure_id: 4006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4007,
        name_th: "น้ำพอง",
        name_en: "Nam Phong",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400701,
            zip_code: 40140,
            name_th: "น้ำพอง",
            name_en: "Nam Phong",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400702,
            zip_code: 40140,
            name_th: "วังชัย",
            name_en: "Wang Chai",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400703,
            zip_code: 40140,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400704,
            zip_code: 40140,
            name_th: "บัวใหญ่",
            name_en: "Bua Yai",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400705,
            zip_code: 40310,
            name_th: "สะอาด",
            name_en: "Sa-at",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400706,
            zip_code: 40310,
            name_th: "ม่วงหวาน",
            name_en: "Muang Wan",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400707,
            zip_code: 40140,
            name_th: "บ้านขาม",
            name_en: "Ban Kham",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400708,
            zip_code: 40140,
            name_th: "บัวเงิน",
            name_en: "Bua Ngoen",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400709,
            zip_code: 40140,
            name_th: "ทรายมูล",
            name_en: "Sai Mun",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400710,
            zip_code: 40140,
            name_th: "ท่ากระเสริม",
            name_en: "Tha Krasoem",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400711,
            zip_code: 40140,
            name_th: "พังทุย",
            name_en: "Phang Thui",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400712,
            zip_code: 40140,
            name_th: "กุดน้ำใส",
            name_en: "Kut Nam Sai",
            amphure_id: 4007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4008,
        name_th: "อุบลรัตน์",
        name_en: "Ubolratana",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400801,
            zip_code: 40250,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 4008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400802,
            zip_code: 40250,
            name_th: "บ้านดง",
            name_en: "Ban Dong",
            amphure_id: 4008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400803,
            zip_code: 40250,
            name_th: "เขื่อนอุบลรัตน์",
            name_en: "Khuean Ubolratana",
            amphure_id: 4008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400804,
            zip_code: 40250,
            name_th: "นาคำ",
            name_en: "Na Kham",
            amphure_id: 4008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400805,
            zip_code: 40250,
            name_th: "ศรีสุขสำราญ",
            name_en: "Si Suk Samran",
            amphure_id: 4008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400806,
            zip_code: 40250,
            name_th: "ทุ่งโป่ง",
            name_en: "Thung Pong",
            amphure_id: 4008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4009,
        name_th: "กระนวน",
        name_en: "Kranuan",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 400901,
            zip_code: 40170,
            name_th: "หนองโก",
            name_en: "Nong Ko",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400902,
            zip_code: 40170,
            name_th: "หนองกุงใหญ่",
            name_en: "Nong Kung Yai",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400905,
            zip_code: 40170,
            name_th: "ห้วยโจด",
            name_en: "Huai Chot",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400906,
            zip_code: 40170,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400907,
            zip_code: 40170,
            name_th: "บ้านฝาง",
            name_en: "Ban Fang",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400909,
            zip_code: 40170,
            name_th: "ดูนสาด",
            name_en: "Dun Sat",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400910,
            zip_code: 40170,
            name_th: "หนองโน",
            name_en: "Nong No",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400911,
            zip_code: 40170,
            name_th: "น้ำอ้อม",
            name_en: "Nam Om",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 400912,
            zip_code: 40170,
            name_th: "หัวนาคำ",
            name_en: "Hua Na Kham",
            amphure_id: 4009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4010,
        name_th: "บ้านไผ่",
        name_en: "Ban Phai",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401001,
            zip_code: 40110,
            name_th: "บ้านไผ่",
            name_en: "Ban Phai",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401002,
            zip_code: 40110,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401005,
            zip_code: 40110,
            name_th: "เมืองเพีย",
            name_en: "Mueang Phia",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401009,
            zip_code: 40110,
            name_th: "บ้านลาน",
            name_en: "Ban Lan",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401010,
            zip_code: 40110,
            name_th: "แคนเหนือ",
            name_en: "Khaen Nuea",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401011,
            zip_code: 40110,
            name_th: "ภูเหล็ก",
            name_en: "Phu Lek",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401013,
            zip_code: 40110,
            name_th: "ป่าปอ",
            name_en: "Pa Po",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401014,
            zip_code: 40110,
            name_th: "หินตั้ง",
            name_en: "Hin Tang",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401016,
            zip_code: 40110,
            name_th: "หนองน้ำใส",
            name_en: "Nong Nam Sai",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401017,
            zip_code: 40110,
            name_th: "หัวหนอง",
            name_en: "Hua Nong",
            amphure_id: 4010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4011,
        name_th: "เปือยน้อย",
        name_en: "Pueai Noi",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401101,
            zip_code: 40340,
            name_th: "เปือยน้อย",
            name_en: "Pueai Noi",
            amphure_id: 4011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401102,
            zip_code: 40340,
            name_th: "วังม่วง",
            name_en: "Wang Muang",
            amphure_id: 4011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401103,
            zip_code: 40340,
            name_th: "ขามป้อม",
            name_en: "Kham Pom",
            amphure_id: 4011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401104,
            zip_code: 40340,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 4011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4012,
        name_th: "พล",
        name_en: "Phon",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401201,
            zip_code: 40120,
            name_th: "เมืองพล",
            name_en: "Mueang Phon",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401203,
            zip_code: 40120,
            name_th: "โจดหนองแก",
            name_en: "Chot Nong Kae",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401204,
            zip_code: 40120,
            name_th: "เก่างิ้ว",
            name_en: "Kao Ngio",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401205,
            zip_code: 40120,
            name_th: "หนองมะเขือ",
            name_en: "Nong Makhuea",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401206,
            zip_code: 40120,
            name_th: "หนองแวงโสกพระ",
            name_en: "Nong Waeng Sok Phra",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401207,
            zip_code: 40120,
            name_th: "เพ็กใหญ่",
            name_en: "Phek Yai",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401208,
            zip_code: 40120,
            name_th: "โคกสง่า",
            name_en: "Khok Sa-nga",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401209,
            zip_code: 40120,
            name_th: "หนองแวงนางเบ้า",
            name_en: "Nong Waeng Nang Bao",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401210,
            zip_code: 40120,
            name_th: "ลอมคอม",
            name_en: "Lom Khom",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401211,
            zip_code: 40120,
            name_th: "โนนข่า",
            name_en: "Non Kha",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401212,
            zip_code: 40120,
            name_th: "โสกนกเต็น",
            name_en: "Sok Nok Ten",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401213,
            zip_code: 40120,
            name_th: "หัวทุ่ง",
            name_en: "Hua Thung",
            amphure_id: 4012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4013,
        name_th: "แวงใหญ่",
        name_en: "Waeng Yai",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401301,
            zip_code: 40330,
            name_th: "คอนฉิม",
            name_en: "Khon Chim",
            amphure_id: 4013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401302,
            zip_code: 40330,
            name_th: "ใหม่นาเพียง",
            name_en: "Mai Na Phiang",
            amphure_id: 4013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401303,
            zip_code: 40330,
            name_th: "โนนทอง",
            name_en: "Non Thong",
            amphure_id: 4013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401304,
            zip_code: 40330,
            name_th: "แวงใหญ่",
            name_en: "Waeng Yai",
            amphure_id: 4013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401305,
            zip_code: 40330,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 4013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4014,
        name_th: "แวงน้อย",
        name_en: "Waeng Noi",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401401,
            zip_code: 40230,
            name_th: "แวงน้อย",
            name_en: "Waeng Noi",
            amphure_id: 4014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401402,
            zip_code: 40230,
            name_th: "ก้านเหลือง",
            name_en: "Kan Lueang",
            amphure_id: 4014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401403,
            zip_code: 40230,
            name_th: "ท่านางแนว",
            name_en: "Tha Nang Naeo",
            amphure_id: 4014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401404,
            zip_code: 40230,
            name_th: "ละหานนา",
            name_en: "Lahan Na",
            amphure_id: 4014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401405,
            zip_code: 40230,
            name_th: "ท่าวัด",
            name_en: "Tha Wat",
            amphure_id: 4014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401406,
            zip_code: 40230,
            name_th: "ทางขวาง",
            name_en: "Thang Khwang",
            amphure_id: 4014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4015,
        name_th: "หนองสองห้อง",
        name_en: "Nong Song Hong",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401501,
            zip_code: 40190,
            name_th: "หนองสองห้อง",
            name_en: "Nong Song Hong",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401502,
            zip_code: 40190,
            name_th: "คึมชาด",
            name_en: "Khuemchat",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401503,
            zip_code: 40190,
            name_th: "โนนธาตุ",
            name_en: "Non That",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401504,
            zip_code: 40190,
            name_th: "ตะกั่วป่า",
            name_en: "Takua Pa",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401505,
            zip_code: 40190,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401506,
            zip_code: 40190,
            name_th: "หนองเม็ก",
            name_en: "Nong Mek",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401507,
            zip_code: 40190,
            name_th: "ดอนดู่",
            name_en: "Don Du",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401508,
            zip_code: 40190,
            name_th: "ดงเค็ง",
            name_en: "Dong Kheng",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401509,
            zip_code: 40190,
            name_th: "หันโจด",
            name_en: "Han Chot",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401510,
            zip_code: 40190,
            name_th: "ดอนดั่ง",
            name_en: "Don Dang",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401511,
            zip_code: 40190,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401512,
            zip_code: 40190,
            name_th: "หนองไผ่ล้อม",
            name_en: "Nong Phai Lom",
            amphure_id: 4015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4016,
        name_th: "ภูเวียง",
        name_en: "Phu Wiang",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401601,
            zip_code: 40150,
            name_th: "บ้านเรือ",
            name_en: "Ban Ruea",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401604,
            zip_code: 40150,
            name_th: "หว้าทอง",
            name_en: "Wa Thong",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401605,
            zip_code: 40150,
            name_th: "กุดขอนแก่น",
            name_en: "Kut Khon Kaen",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401606,
            zip_code: 40150,
            name_th: "นาชุมแสง",
            name_en: "Na Chum Saeng",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401607,
            zip_code: 40150,
            name_th: "นาหว้า",
            name_en: "Na Wa",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401610,
            zip_code: 40150,
            name_th: "หนองกุงธนสาร",
            name_en: "Nong Kung Thanasan",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401612,
            zip_code: 40150,
            name_th: "หนองกุงเซิน",
            name_en: "Nong Kung Soen",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401613,
            zip_code: 40150,
            name_th: "สงเปือย",
            name_en: "Song Pueai",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401614,
            zip_code: 40150,
            name_th: "ทุ่งชมพู",
            name_en: "Thung Chomphu",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401616,
            zip_code: 40150,
            name_th: "ดินดำ",
            name_en: "Din Dam",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401617,
            zip_code: 40150,
            name_th: "ภูเวียง",
            name_en: "Phu Wiang",
            amphure_id: 4016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4017,
        name_th: "มัญจาคีรี",
        name_en: "Mancha Khiri",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401701,
            zip_code: 40160,
            name_th: "กุดเค้า",
            name_en: "Kut Khao",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401702,
            zip_code: 40160,
            name_th: "สวนหม่อน",
            name_en: "Suan Mon",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401703,
            zip_code: 40160,
            name_th: "หนองแปน",
            name_en: "Nong Paen",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401704,
            zip_code: 40160,
            name_th: "โพนเพ็ก",
            name_en: "Phon Phek",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401705,
            zip_code: 40160,
            name_th: "คำแคน",
            name_en: "Kham Khaen",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401706,
            zip_code: 40160,
            name_th: "นาข่า",
            name_en: "Na Kha",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401707,
            zip_code: 40160,
            name_th: "นางาม",
            name_en: "Na Ngam",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401710,
            zip_code: 40160,
            name_th: "ท่าศาลา",
            name_en: "Tha Sala",
            amphure_id: 4017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4018,
        name_th: "ชนบท",
        name_en: "Chonnabot",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401801,
            zip_code: 40180,
            name_th: "ชนบท",
            name_en: "Chonnabot",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401802,
            zip_code: 40180,
            name_th: "กุดเพียขอม",
            name_en: "Kut Phia Khom",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401803,
            zip_code: 40180,
            name_th: "วังแสง",
            name_en: "Wang Saeng",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401804,
            zip_code: 40180,
            name_th: "ห้วยแก",
            name_en: "Huai Kae",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401805,
            zip_code: 40180,
            name_th: "บ้านแท่น",
            name_en: "Ban Thaen",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401806,
            zip_code: 40180,
            name_th: "ศรีบุญเรือง",
            name_en: "Si Bun Rueang",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401807,
            zip_code: 40180,
            name_th: "โนนพะยอม",
            name_en: "Non Phayom",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401808,
            zip_code: 40180,
            name_th: "ปอแดง",
            name_en: "Po Daeng",
            amphure_id: 4018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4019,
        name_th: "เขาสวนกวาง",
        name_en: "Khao Suan Kwang",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 401901,
            zip_code: 40280,
            name_th: "เขาสวนกวาง",
            name_en: "Khao Suan Kwang",
            amphure_id: 4019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401902,
            zip_code: 40280,
            name_th: "ดงเมืองแอม",
            name_en: "Dong Mueang Aem",
            amphure_id: 4019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401903,
            zip_code: 40280,
            name_th: "นางิ้ว",
            name_en: "Na Ngio",
            amphure_id: 4019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401904,
            zip_code: 40280,
            name_th: "โนนสมบูรณ์",
            name_en: "Non Sombun",
            amphure_id: 4019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 401905,
            zip_code: 40280,
            name_th: "คำม่วง",
            name_en: "Kham Muang",
            amphure_id: 4019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4020,
        name_th: "ภูผาม่าน",
        name_en: "Phu Pha Man",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402001,
            zip_code: 40350,
            name_th: "โนนคอม",
            name_en: "Non Khom",
            amphure_id: 4020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402002,
            zip_code: 40350,
            name_th: "นาฝาย",
            name_en: "Na Fai",
            amphure_id: 4020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402003,
            zip_code: 40350,
            name_th: "ภูผาม่าน",
            name_en: "Phu Pha Man",
            amphure_id: 4020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402004,
            zip_code: 40350,
            name_th: "วังสวาบ",
            name_en: "Wang Sawap",
            amphure_id: 4020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402005,
            zip_code: 40350,
            name_th: "ห้วยม่วง",
            name_en: "Huai Muang",
            amphure_id: 4020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4021,
        name_th: "ซำสูง",
        name_en: "Sam Sung",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402101,
            zip_code: 40170,
            name_th: "กระนวน",
            name_en: "Kranuan",
            amphure_id: 4021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402102,
            zip_code: 40170,
            name_th: "คำแมด",
            name_en: "Kham Maet",
            amphure_id: 4021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402103,
            zip_code: 40170,
            name_th: "บ้านโนน",
            name_en: "Ban Non",
            amphure_id: 4021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402104,
            zip_code: 40170,
            name_th: "คูคำ",
            name_en: "Khu Kham",
            amphure_id: 4021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402105,
            zip_code: 40170,
            name_th: "ห้วยเตย",
            name_en: "Huai Toei",
            amphure_id: 4021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4022,
        name_th: "โคกโพธิ์ไชย",
        name_en: "Khok Pho Chai",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402201,
            zip_code: 40160,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 4022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402202,
            zip_code: 40160,
            name_th: "โพธิ์ไชย",
            name_en: "Pho Chai",
            amphure_id: 4022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402203,
            zip_code: 40160,
            name_th: "ซับสมบูรณ์",
            name_en: "Sap Sombun",
            amphure_id: 4022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402204,
            zip_code: 40160,
            name_th: "นาแพง",
            name_en: "Na Phaeng",
            amphure_id: 4022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4023,
        name_th: "หนองนาคำ",
        name_en: "Nong Na Kham",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402301,
            zip_code: 40150,
            name_th: "กุดธาตุ",
            name_en: "Kut That",
            amphure_id: 4023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402302,
            zip_code: 40150,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 4023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402303,
            zip_code: 40150,
            name_th: "ขนวน",
            name_en: "Khanuan",
            amphure_id: 4023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4024,
        name_th: "บ้านแฮด",
        name_en: "Ban Haet",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402401,
            zip_code: 40110,
            name_th: "บ้านแฮด",
            name_en: "Ban Haet",
            amphure_id: 4024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402402,
            zip_code: 40110,
            name_th: "โคกสำราญ",
            name_en: "Khok Samran",
            amphure_id: 4024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402403,
            zip_code: 40110,
            name_th: "โนนสมบูรณ์",
            name_en: "Non Sombun",
            amphure_id: 4024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402404,
            zip_code: 40110,
            name_th: "หนองแซง",
            name_en: "Nong Saeng",
            amphure_id: 4024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4025,
        name_th: "โนนศิลา",
        name_en: "Non Sila",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402501,
            zip_code: 40110,
            name_th: "โนนศิลา",
            name_en: "Non Sila",
            amphure_id: 4025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402502,
            zip_code: 40110,
            name_th: "หนองปลาหมอ",
            name_en: "Nong Pla Mo",
            amphure_id: 4025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402503,
            zip_code: 40110,
            name_th: "บ้านหัน",
            name_en: "Ban Han",
            amphure_id: 4025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402504,
            zip_code: 40110,
            name_th: "เปือยใหญ่",
            name_en: "Pueai Yai",
            amphure_id: 4025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402505,
            zip_code: 40110,
            name_th: "โนนแดง",
            name_en: "Non Daeng",
            amphure_id: 4025,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4029,
        name_th: "เวียงเก่า",
        name_en: "Wiang Kao",
        province_id: 28,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 402901,
            zip_code: 0,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 4029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402902,
            zip_code: 0,
            name_th: "เมืองเก่าพัฒนา",
            name_en: "Mueang Kao Phatthana",
            amphure_id: 4029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 402903,
            zip_code: 0,
            name_th: "เขาน้อย",
            name_en: "Khao Noi",
            amphure_id: 4029,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 29,
    name_th: "อุดรธานี",
    name_en: "Udon Thani",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4101,
        name_th: "เมืองอุดรธานี",
        name_en: "Mueang Udon Thani",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410101,
            zip_code: 41000,
            name_th: "หมากแข้ง",
            name_en: "Mak Khaeng",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410102,
            zip_code: 41000,
            name_th: "นิคมสงเคราะห์",
            name_en: "Nikhom Songkhro",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410103,
            zip_code: 41000,
            name_th: "บ้านขาว",
            name_en: "Ban Khao",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410104,
            zip_code: 41000,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410105,
            zip_code: 41000,
            name_th: "บ้านตาด",
            name_en: "Ban Tat",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410106,
            zip_code: 41330,
            name_th: "โนนสูง",
            name_en: "Non Sung",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410107,
            zip_code: 41000,
            name_th: "หมูม่น",
            name_en: "Mu Mon",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410108,
            zip_code: 41000,
            name_th: "เชียงยืน",
            name_en: "Chiang Yuen",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410109,
            zip_code: 41000,
            name_th: "หนองนาคำ",
            name_en: "Nong Na Kham",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410110,
            zip_code: 41000,
            name_th: "กุดสระ",
            name_en: "Kut Sa",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410111,
            zip_code: 41000,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410112,
            zip_code: 41000,
            name_th: "บ้านเลื่อม",
            name_en: "Ban Lueam",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410113,
            zip_code: 41000,
            name_th: "เชียงพิณ",
            name_en: "Chiang Phin",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410114,
            zip_code: 41000,
            name_th: "สามพร้าว",
            name_en: "Sam Phrao",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410115,
            zip_code: 41000,
            name_th: "หนองไฮ",
            name_en: "Nong Hai",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410116,
            zip_code: 41000,
            name_th: "นาข่า",
            name_en: "Na Kha",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410117,
            zip_code: 41000,
            name_th: "บ้านจั่น",
            name_en: "Ban Chan",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410118,
            zip_code: 41000,
            name_th: "หนองขอนกว้าง",
            name_en: "Nong Khon Kwang",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410119,
            zip_code: 41000,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410120,
            zip_code: 41000,
            name_th: "นากว้าง",
            name_en: "Na Kwang",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410121,
            zip_code: 41330,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 4101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4102,
        name_th: "กุดจับ",
        name_en: "Kut Chap",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410201,
            zip_code: 41250,
            name_th: "กุดจับ",
            name_en: "Kut Chap",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410202,
            zip_code: 41250,
            name_th: "ปะโค",
            name_en: "Pakho",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410203,
            zip_code: 41250,
            name_th: "ขอนยูง",
            name_en: "Khon Yung",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410204,
            zip_code: 41250,
            name_th: "เชียงเพ็ง",
            name_en: "Chiang Pheng",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410205,
            zip_code: 41250,
            name_th: "สร้างก่อ",
            name_en: "Sang Ko",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410206,
            zip_code: 41250,
            name_th: "เมืองเพีย",
            name_en: "Mueang Phia",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410207,
            zip_code: 41250,
            name_th: "ตาลเลียน",
            name_en: "Tan Lian",
            amphure_id: 4102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4103,
        name_th: "หนองวัวซอ",
        name_en: "Nong Wua So",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410301,
            zip_code: 41360,
            name_th: "หมากหญ้า",
            name_en: "Mak Ya",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410302,
            zip_code: 41220,
            name_th: "หนองอ้อ",
            name_en: "Nong O",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410303,
            zip_code: 41220,
            name_th: "อูบมุง",
            name_en: "Up Mung",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410304,
            zip_code: 41220,
            name_th: "กุดหมากไฟ",
            name_en: "Kut Mak Fai",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410305,
            zip_code: 41360,
            name_th: "น้ำพ่น",
            name_en: "Nam Phon",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410306,
            zip_code: 41360,
            name_th: "หนองบัวบาน",
            name_en: "Nong Bua Ban",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410307,
            zip_code: 41220,
            name_th: "โนนหวาย",
            name_en: "Non Wai",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410308,
            zip_code: 41360,
            name_th: "หนองวัวซอ",
            name_en: "Nong Wua So",
            amphure_id: 4103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4104,
        name_th: "กุมภวาปี",
        name_en: "Kumphawapi",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410401,
            zip_code: 41110,
            name_th: "ตูมใต้",
            name_en: "Tum Tai",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410402,
            zip_code: 41370,
            name_th: "พันดอน",
            name_en: "Phan Don",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410403,
            zip_code: 41110,
            name_th: "เวียงคำ",
            name_en: "Wiang Kham",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410404,
            zip_code: 41110,
            name_th: "แชแล",
            name_en: "Chaelae",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410406,
            zip_code: 41110,
            name_th: "เชียงแหว",
            name_en: "Chiang Wae",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410407,
            zip_code: 41110,
            name_th: "ห้วยเกิ้ง",
            name_en: "Huai Koeng",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410409,
            zip_code: 41370,
            name_th: "เสอเพลอ",
            name_en: "Soephloe",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410410,
            zip_code: 41110,
            name_th: "สีออ",
            name_en: "Si O",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410411,
            zip_code: 41370,
            name_th: "ปะโค",
            name_en: "Pa Kho",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410413,
            zip_code: 41370,
            name_th: "ผาสุก",
            name_en: "Phasuk",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410414,
            zip_code: 41110,
            name_th: "ท่าลี่",
            name_en: "Tha Li",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410415,
            zip_code: 41110,
            name_th: "กุมภวาปี",
            name_en: "Kumphawapi",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410416,
            zip_code: 41110,
            name_th: "หนองหว้า",
            name_en: "Nong Wa",
            amphure_id: 4104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4105,
        name_th: "โนนสะอาด",
        name_en: "Non Sa-at",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410501,
            zip_code: 41240,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 4105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410502,
            zip_code: 41240,
            name_th: "บุ่งแก้ว",
            name_en: "Bung Kaeo",
            amphure_id: 4105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410503,
            zip_code: 41240,
            name_th: "โพธิ์ศรีสำราญ",
            name_en: "Pho Si Samran",
            amphure_id: 4105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410504,
            zip_code: 41240,
            name_th: "ทมนางาม",
            name_en: "Thom Na Ngam",
            amphure_id: 4105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410505,
            zip_code: 41240,
            name_th: "หนองกุงศรี",
            name_en: "Nong Kung Si",
            amphure_id: 4105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410506,
            zip_code: 41240,
            name_th: "โคกกลาง",
            name_en: "Khok Klang",
            amphure_id: 4105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4106,
        name_th: "หนองหาน",
        name_en: "Nong Han",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410601,
            zip_code: 41130,
            name_th: "หนองหาน",
            name_en: "Nong Han",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410602,
            zip_code: 41130,
            name_th: "หนองเม็ก",
            name_en: "Nong Mek",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410605,
            zip_code: 41130,
            name_th: "พังงู",
            name_en: "Phang Ngu",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410606,
            zip_code: 41130,
            name_th: "สะแบง",
            name_en: "Sabaeng",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410607,
            zip_code: 41130,
            name_th: "สร้อยพร้าว",
            name_en: "Soi Phrao",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410609,
            zip_code: 41320,
            name_th: "บ้านเชียง",
            name_en: "Ban Chiang",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410610,
            zip_code: 41320,
            name_th: "บ้านยา",
            name_en: "Ban Ya",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410611,
            zip_code: 41130,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410612,
            zip_code: 41130,
            name_th: "ผักตบ",
            name_en: "Phak Top",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410614,
            zip_code: 41130,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410617,
            zip_code: 41130,
            name_th: "ดอนหายโศก",
            name_en: "Don Hai Sok",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410618,
            zip_code: 41320,
            name_th: "หนองสระปลา",
            name_en: "Nong Sa Pla)",
            amphure_id: 4106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4107,
        name_th: "ทุ่งฝน",
        name_en: "Thung Fon",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410701,
            zip_code: 41310,
            name_th: "ทุ่งฝน",
            name_en: "Thung Fon",
            amphure_id: 4107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410702,
            zip_code: 41310,
            name_th: "ทุ่งใหญ่",
            name_en: "Thung Yai",
            amphure_id: 4107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410703,
            zip_code: 41310,
            name_th: "นาชุมแสง",
            name_en: "Na Chum Saeng",
            amphure_id: 4107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410704,
            zip_code: 41310,
            name_th: "นาทม",
            name_en: "Na Thom",
            amphure_id: 4107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4108,
        name_th: "ไชยวาน",
        name_en: "Chai Wan",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410801,
            zip_code: 41290,
            name_th: "ไชยวาน",
            name_en: "Chai Wan",
            amphure_id: 4108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410802,
            zip_code: 41290,
            name_th: "หนองหลัก",
            name_en: "Nong Lak",
            amphure_id: 4108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410803,
            zip_code: 41290,
            name_th: "คำเลาะ",
            name_en: "Kham Lo",
            amphure_id: 4108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410804,
            zip_code: 41290,
            name_th: "โพนสูง",
            name_en: "Phon Sung",
            amphure_id: 4108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4109,
        name_th: "ศรีธาตุ",
        name_en: "Si That",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 410901,
            zip_code: 41230,
            name_th: "ศรีธาตุ",
            name_en: "Si That",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410902,
            zip_code: 41230,
            name_th: "จำปี",
            name_en: "Champi",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410903,
            zip_code: 41230,
            name_th: "บ้านโปร่ง",
            name_en: "Ban Prong",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410904,
            zip_code: 41230,
            name_th: "หัวนาคำ",
            name_en: "Hua Na Kham",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410905,
            zip_code: 41230,
            name_th: "หนองนกเขียน",
            name_en: "Nong Nok Khian",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410906,
            zip_code: 41230,
            name_th: "นายูง",
            name_en: "Na Yung",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 410907,
            zip_code: 41230,
            name_th: "ตาดทอง",
            name_en: "Tat Thong",
            amphure_id: 4109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4110,
        name_th: "วังสามหมอ",
        name_en: "Wang Sam Mo",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 411001,
            zip_code: 41280,
            name_th: "หนองกุงทับม้า",
            name_en: "Nong Kung Thap Ma",
            amphure_id: 4110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411002,
            zip_code: 41280,
            name_th: "หนองหญ้าไซ",
            name_en: "Nong Ya Sai",
            amphure_id: 4110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411003,
            zip_code: 41280,
            name_th: "บะยาว",
            name_en: "Ba Yao",
            amphure_id: 4110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411004,
            zip_code: 41280,
            name_th: "ผาสุก",
            name_en: "Phasuk",
            amphure_id: 4110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411005,
            zip_code: 41280,
            name_th: "คำโคกสูง",
            name_en: "Kham Khok Sung",
            amphure_id: 4110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411006,
            zip_code: 41280,
            name_th: "วังสามหมอ",
            name_en: "Wang Sam Mo",
            amphure_id: 4110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4111,
        name_th: "บ้านดุง",
        name_en: "Ban Dung",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 411101,
            zip_code: 41190,
            name_th: "ศรีสุทโธ",
            name_en: "Si Suttho",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411102,
            zip_code: 41190,
            name_th: "บ้านดุง",
            name_en: "Ban Dung",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411103,
            zip_code: 41190,
            name_th: "ดงเย็น",
            name_en: "Dong Yen",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411104,
            zip_code: 41190,
            name_th: "โพนสูง",
            name_en: "Phon Sung",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411105,
            zip_code: 41190,
            name_th: "อ้อมกอ",
            name_en: "Om Ko",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411106,
            zip_code: 41190,
            name_th: "บ้านจันทน์",
            name_en: "Ban Chan",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411107,
            zip_code: 41190,
            name_th: "บ้านชัย",
            name_en: "Ban Chai",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411108,
            zip_code: 41190,
            name_th: "นาไหม",
            name_en: "Na Mai",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411109,
            zip_code: 41190,
            name_th: "ถ่อนนาลับ",
            name_en: "Thon Na Lap",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411110,
            zip_code: 41190,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411111,
            zip_code: 41190,
            name_th: "บ้านม่วง",
            name_en: "Ban Muang",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411112,
            zip_code: 41190,
            name_th: "บ้านตาด",
            name_en: "Ban Tat",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411113,
            zip_code: 41190,
            name_th: "นาคำ",
            name_en: "Na Kham",
            amphure_id: 4111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4117,
        name_th: "บ้านผือ",
        name_en: "Ban Phue",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 411701,
            zip_code: 41160,
            name_th: "บ้านผือ",
            name_en: "Ban Phue",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411702,
            zip_code: 41160,
            name_th: "หายโศก",
            name_en: "Hai Sok",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411703,
            zip_code: 41160,
            name_th: "เขือน้ำ",
            name_en: "Khuea Nam",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411704,
            zip_code: 41160,
            name_th: "คำบง",
            name_en: "Kham Bong",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411705,
            zip_code: 41160,
            name_th: "โนนทอง",
            name_en: "Non Thong",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411706,
            zip_code: 41160,
            name_th: "ข้าวสาร",
            name_en: "Khao San",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411707,
            zip_code: 41160,
            name_th: "จำปาโมง",
            name_en: "Champa Mong",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411708,
            zip_code: 41160,
            name_th: "กลางใหญ่",
            name_en: "Klang Yai",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411709,
            zip_code: 41160,
            name_th: "เมืองพาน",
            name_en: "Mueang Phan",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411710,
            zip_code: 41160,
            name_th: "คำด้วง",
            name_en: "Kham Duang",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411711,
            zip_code: 41160,
            name_th: "หนองหัวคู",
            name_en: "Nong Hua Khu",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411712,
            zip_code: 41160,
            name_th: "บ้านค้อ",
            name_en: "Ban Kho",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411713,
            zip_code: 41160,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4117,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4118,
        name_th: "น้ำโสม",
        name_en: "Nam Som",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 411801,
            zip_code: 41210,
            name_th: "นางัว",
            name_en: "Na Ngua",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411802,
            zip_code: 41210,
            name_th: "น้ำโสม",
            name_en: "Nam Som",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411805,
            zip_code: 41210,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411806,
            zip_code: 41210,
            name_th: "บ้านหยวก",
            name_en: "Ban Yuak",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411807,
            zip_code: 41210,
            name_th: "โสมเยี่ยม",
            name_en: "Som Yiam",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411810,
            zip_code: 41210,
            name_th: "ศรีสำราญ",
            name_en: "Si Samran",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411812,
            zip_code: 41210,
            name_th: "สามัคคี",
            name_en: "Samakkhi",
            amphure_id: 4118,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4119,
        name_th: "เพ็ญ",
        name_en: "Phen",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 411901,
            zip_code: 41150,
            name_th: "เพ็ญ",
            name_en: "Phen",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411902,
            zip_code: 41150,
            name_th: "บ้านธาตุ",
            name_en: "Ban That",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411903,
            zip_code: 41150,
            name_th: "นาพู่",
            name_en: "Na Phu",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411904,
            zip_code: 41150,
            name_th: "เชียงหวาง",
            name_en: "Chiang Wang",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411905,
            zip_code: 41150,
            name_th: "สุมเส้า",
            name_en: "Sum Sao",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411906,
            zip_code: 41150,
            name_th: "นาบัว",
            name_en: "Na Bua",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411907,
            zip_code: 41150,
            name_th: "บ้านเหล่า",
            name_en: "Ban Lao",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411908,
            zip_code: 41150,
            name_th: "จอมศรี",
            name_en: "Chom Si",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411909,
            zip_code: 41150,
            name_th: "เตาไห",
            name_en: "Tao Hai",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411910,
            zip_code: 41150,
            name_th: "โคกกลาง",
            name_en: "Khok Klang",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 411911,
            zip_code: 41150,
            name_th: "สร้างแป้น",
            name_en: "Sang Paen",
            amphure_id: 4119,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4120,
        name_th: "สร้างคอม",
        name_en: "Sang Khom",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 412001,
            zip_code: 41260,
            name_th: "สร้างคอม",
            name_en: "Sang Khom",
            amphure_id: 4120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412002,
            zip_code: 41260,
            name_th: "เชียงดา",
            name_en: "Chiang Da",
            amphure_id: 4120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412003,
            zip_code: 41260,
            name_th: "บ้านยวด",
            name_en: "Ban Yuat",
            amphure_id: 4120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412004,
            zip_code: 41260,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 4120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412005,
            zip_code: 41260,
            name_th: "นาสะอาด",
            name_en: "Na Sa-at",
            amphure_id: 4120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412006,
            zip_code: 41260,
            name_th: "บ้านหินโงม",
            name_en: "Ban Hin Ngom",
            amphure_id: 4120,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4121,
        name_th: "หนองแสง",
        name_en: "Nong Saeng",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 412101,
            zip_code: 41340,
            name_th: "หนองแสง",
            name_en: "Nong Saeng",
            amphure_id: 4121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412102,
            zip_code: 41340,
            name_th: "แสงสว่าง",
            name_en: "Saeng Sawang",
            amphure_id: 4121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412103,
            zip_code: 41340,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 4121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412104,
            zip_code: 41340,
            name_th: "ทับกุง",
            name_en: "Thap Kung",
            amphure_id: 4121,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4122,
        name_th: "นายูง",
        name_en: "Na Yung",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 412201,
            zip_code: 41380,
            name_th: "นายูง",
            name_en: "Na Yung",
            amphure_id: 4122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412202,
            zip_code: 41380,
            name_th: "บ้านก้อง",
            name_en: "Ban Kong",
            amphure_id: 4122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412203,
            zip_code: 41380,
            name_th: "นาแค",
            name_en: "Na Khae",
            amphure_id: 4122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412204,
            zip_code: 41380,
            name_th: "โนนทอง",
            name_en: "Non Thong",
            amphure_id: 4122,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4123,
        name_th: "พิบูลย์รักษ์",
        name_en: "Phibun Rak",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 412301,
            zip_code: 41130,
            name_th: "บ้านแดง",
            name_en: "Ban Daeng",
            amphure_id: 4123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412302,
            zip_code: 41130,
            name_th: "นาทราย",
            name_en: "Na Sai",
            amphure_id: 4123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412303,
            zip_code: 41130,
            name_th: "ดอนกลอย",
            name_en: "Don Kloi",
            amphure_id: 4123,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4124,
        name_th: "กู่แก้ว",
        name_en: "Ku Kaeo",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 412401,
            zip_code: 41130,
            name_th: "บ้านจีต",
            name_en: "Ban Chit",
            amphure_id: 4124,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412402,
            zip_code: 41130,
            name_th: "โนนทองอินทร์",
            name_en: "Non Thong In",
            amphure_id: 4124,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412403,
            zip_code: 41130,
            name_th: "ค้อใหญ่",
            name_en: "Kho Yai",
            amphure_id: 4124,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412404,
            zip_code: 41130,
            name_th: "คอนสาย",
            name_en: "Khon Sai",
            amphure_id: 4124,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4125,
        name_th: "ประจักษ์ศิลปาคม",
        name_en: "rachak-sinlapakhom",
        province_id: 29,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 412501,
            zip_code: 41110,
            name_th: "นาม่วง",
            name_en: "Na Muang",
            amphure_id: 4125,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412502,
            zip_code: 41110,
            name_th: "ห้วยสามพาด",
            name_en: "Huai Sam Phat",
            amphure_id: 4125,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 412503,
            zip_code: 41110,
            name_th: "อุ่มจาน",
            name_en: "Um Chan",
            amphure_id: 4125,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 30,
    name_th: "เลย",
    name_en: "Loei",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4201,
        name_th: "เมืองเลย",
        name_en: "Mueang Loei",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420101,
            zip_code: 42000,
            name_th: "กุดป่อง",
            name_en: "Kut Pong",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420102,
            zip_code: 42000,
            name_th: "เมือง",
            name_en: "Mueang",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420103,
            zip_code: 42100,
            name_th: "นาอ้อ",
            name_en: "Na O",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420104,
            zip_code: 42000,
            name_th: "กกดู่",
            name_en: "Kok Du",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420105,
            zip_code: 42000,
            name_th: "น้ำหมาน",
            name_en: "Nam Man",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420106,
            zip_code: 42000,
            name_th: "เสี้ยว",
            name_en: "Siao",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420107,
            zip_code: 42000,
            name_th: "นาอาน",
            name_en: "Na An",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420108,
            zip_code: 42000,
            name_th: "นาโป่ง",
            name_en: "Na Pong",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420109,
            zip_code: 42000,
            name_th: "นาดินดำ",
            name_en: "Na Din Dam",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420110,
            zip_code: 42000,
            name_th: "น้ำสวย",
            name_en: "Nam Suai",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420111,
            zip_code: 42000,
            name_th: "ชัยพฤกษ์",
            name_en: "Chaiyaphruek",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420112,
            zip_code: 42000,
            name_th: "นาแขม",
            name_en: "Na Khaem",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420113,
            zip_code: 42100,
            name_th: "ศรีสองรัก",
            name_en: "Si Song Rak",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420114,
            zip_code: 42000,
            name_th: "กกทอง",
            name_en: "Kok Thong",
            amphure_id: 4201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4202,
        name_th: "นาด้วง",
        name_en: "Na Duang",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420201,
            zip_code: 42210,
            name_th: "นาด้วง",
            name_en: "Na Duang",
            amphure_id: 4202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420202,
            zip_code: 42210,
            name_th: "นาดอกคำ",
            name_en: "Na Dok Kham",
            amphure_id: 4202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420203,
            zip_code: 42210,
            name_th: "ท่าสะอาด",
            name_en: "Tha Sa-at",
            amphure_id: 4202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420204,
            zip_code: 42210,
            name_th: "ท่าสวรรค์",
            name_en: "Tha Sawan",
            amphure_id: 4202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4203,
        name_th: "เชียงคาน",
        name_en: "Chiang Khan",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420301,
            zip_code: 42110,
            name_th: "เชียงคาน",
            name_en: "Chiang Khan",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420302,
            zip_code: 42110,
            name_th: "ธาตุ",
            name_en: "That",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420303,
            zip_code: 42110,
            name_th: "นาซ่าว",
            name_en: "Na Sao",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420304,
            zip_code: 42110,
            name_th: "เขาแก้ว",
            name_en: "Khao Kaeo",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420305,
            zip_code: 42110,
            name_th: "ปากตม",
            name_en: "Pak Tom",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420306,
            zip_code: 42110,
            name_th: "บุฮม",
            name_en: "Bu Hom",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420307,
            zip_code: 42110,
            name_th: "จอมศรี",
            name_en: "Chom Si",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420308,
            zip_code: 42110,
            name_th: "หาดทรายขาว",
            name_en: "Hat Sai Khao",
            amphure_id: 4203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4204,
        name_th: "ปากชม",
        name_en: "Pak Chom",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420401,
            zip_code: 42150,
            name_th: "ปากชม",
            name_en: "Pak Chom",
            amphure_id: 4204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420402,
            zip_code: 42150,
            name_th: "เชียงกลม",
            name_en: "Chiang Klom",
            amphure_id: 4204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420403,
            zip_code: 42150,
            name_th: "หาดคัมภีร์",
            name_en: "Hat Khamphi",
            amphure_id: 4204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420404,
            zip_code: 42150,
            name_th: "ห้วยบ่อซืน",
            name_en: "Huai Bo Suen",
            amphure_id: 4204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420405,
            zip_code: 42150,
            name_th: "ห้วยพิชัย",
            name_en: "Huai Phichai",
            amphure_id: 4204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420406,
            zip_code: 42150,
            name_th: "ชมเจริญ",
            name_en: "Chom Charoen",
            amphure_id: 4204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4205,
        name_th: "ด่านซ้าย",
        name_en: "Dan Sai",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420501,
            zip_code: 42120,
            name_th: "ด่านซ้าย",
            name_en: "Dan Sai",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420502,
            zip_code: 42120,
            name_th: "ปากหมัน",
            name_en: "Pak Man",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420503,
            zip_code: 42120,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420504,
            zip_code: 42120,
            name_th: "โคกงาม",
            name_en: "Khok Ngam",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420505,
            zip_code: 42120,
            name_th: "โพนสูง",
            name_en: "Phon Sung",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420506,
            zip_code: 42120,
            name_th: "อิปุ่ม",
            name_en: "Ipum",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420507,
            zip_code: 42120,
            name_th: "กกสะทอน",
            name_en: "Kok Sathon",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420508,
            zip_code: 42120,
            name_th: "โป่ง",
            name_en: "Pong",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420509,
            zip_code: 42120,
            name_th: "วังยาว",
            name_en: "Wang Yao",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420510,
            zip_code: 42120,
            name_th: "นาหอ",
            name_en: "Na Ho",
            amphure_id: 4205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4206,
        name_th: "นาแห้ว",
        name_en: "Na Haeo",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420601,
            zip_code: 42170,
            name_th: "นาแห้ว",
            name_en: "Na Haeo",
            amphure_id: 4206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420602,
            zip_code: 42170,
            name_th: "แสงภา",
            name_en: "Saeng Pha",
            amphure_id: 4206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420603,
            zip_code: 42170,
            name_th: "นาพึง",
            name_en: "Na Phueng",
            amphure_id: 4206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420604,
            zip_code: 42170,
            name_th: "นามาลา",
            name_en: "Na Ma La",
            amphure_id: 4206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420605,
            zip_code: 42170,
            name_th: "เหล่ากอหก",
            name_en: "Lao Ko Hok",
            amphure_id: 4206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4207,
        name_th: "ภูเรือ",
        name_en: "Phu Ruea",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420701,
            zip_code: 42160,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420702,
            zip_code: 42160,
            name_th: "ท่าศาลา",
            name_en: "Tha Sala",
            amphure_id: 4207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420703,
            zip_code: 42160,
            name_th: "ร่องจิก",
            name_en: "Rong Chik",
            amphure_id: 4207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420704,
            zip_code: 42160,
            name_th: "ปลาบ่า",
            name_en: "Pla Ba",
            amphure_id: 4207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420705,
            zip_code: 42160,
            name_th: "ลาดค่าง",
            name_en: "Lat Khang",
            amphure_id: 4207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420706,
            zip_code: 42160,
            name_th: "สานตม",
            name_en: "San Tom",
            amphure_id: 4207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4208,
        name_th: "ท่าลี่",
        name_en: "Tha Li",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420801,
            zip_code: 42140,
            name_th: "ท่าลี่",
            name_en: "Tha Li",
            amphure_id: 4208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420802,
            zip_code: 42140,
            name_th: "หนองผือ",
            name_en: "Nong Phue",
            amphure_id: 4208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420803,
            zip_code: 42140,
            name_th: "อาฮี",
            name_en: "A Hi",
            amphure_id: 4208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420804,
            zip_code: 42140,
            name_th: "น้ำแคม",
            name_en: "Nam Khaem",
            amphure_id: 4208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420805,
            zip_code: 42140,
            name_th: "โคกใหญ่",
            name_en: "Khok Yai",
            amphure_id: 4208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420806,
            zip_code: 42140,
            name_th: "น้ำทูน",
            name_en: "Nam Thun",
            amphure_id: 4208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4209,
        name_th: "วังสะพุง",
        name_en: "Wang Saphung",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 420901,
            zip_code: 42130,
            name_th: "วังสะพุง",
            name_en: "Wang Saphung",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420902,
            zip_code: 42130,
            name_th: "ทรายขาว",
            name_en: "Sai Khao",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420903,
            zip_code: 42130,
            name_th: "หนองหญ้าปล้อง",
            name_en: "Nong Ya Plong",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420904,
            zip_code: 42130,
            name_th: "หนองงิ้ว",
            name_en: "Nong Ngio",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420905,
            zip_code: 42130,
            name_th: "ปากปวน",
            name_en: "Pak Puan",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420906,
            zip_code: 42130,
            name_th: "ผาน้อย",
            name_en: "Pha Noi",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420910,
            zip_code: 42130,
            name_th: "ผาบิ้ง",
            name_en: "Pha Bing",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420911,
            zip_code: 42130,
            name_th: "เขาหลวง",
            name_en: "Khao Luang",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420912,
            zip_code: 42130,
            name_th: "โคกขมิ้น",
            name_en: "Khok Khamin",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 420913,
            zip_code: 42130,
            name_th: "ศรีสงคราม",
            name_en: "Si Songkhram",
            amphure_id: 4209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4210,
        name_th: "ภูกระดึง",
        name_en: "Phu Kradueng",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 421001,
            zip_code: 42180,
            name_th: "ศรีฐาน",
            name_en: "Si Than",
            amphure_id: 4210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421005,
            zip_code: 42180,
            name_th: "ผานกเค้า",
            name_en: "Pha Nok Khao",
            amphure_id: 4210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421007,
            zip_code: 42180,
            name_th: "ภูกระดึง",
            name_en: "Phu Kradueng",
            amphure_id: 4210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421010,
            zip_code: 42180,
            name_th: "ห้วยส้ม",
            name_en: "Huai Som",
            amphure_id: 4210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4211,
        name_th: "ภูหลวง",
        name_en: "Phu Luang",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 421101,
            zip_code: 42230,
            name_th: "ภูหอ",
            name_en: "Phu Ho",
            amphure_id: 4211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421102,
            zip_code: 42230,
            name_th: "หนองคัน",
            name_en: "Nong Khan",
            amphure_id: 4211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421104,
            zip_code: 42230,
            name_th: "ห้วยสีเสียด",
            name_en: "Huai Sisiat",
            amphure_id: 4211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421105,
            zip_code: 42230,
            name_th: "เลยวังไสย์",
            name_en: "Loei Wang Sai",
            amphure_id: 4211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421106,
            zip_code: 42230,
            name_th: "แก่งศรีภูมิ",
            name_en: "Kaeng Si Phum",
            amphure_id: 4211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4212,
        name_th: "ผาขาว",
        name_en: "Pha Khao",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 421201,
            zip_code: 42240,
            name_th: "ผาขาว",
            name_en: "Pha Khao",
            amphure_id: 4212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421202,
            zip_code: 42240,
            name_th: "ท่าช้างคล้อง",
            name_en: "Tha Chang Khlong",
            amphure_id: 4212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421203,
            zip_code: 42240,
            name_th: "โนนปอแดง",
            name_en: "Non Po Daeng",
            amphure_id: 4212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421204,
            zip_code: 42240,
            name_th: "โนนป่าซาง",
            name_en: "Non Pa Sang",
            amphure_id: 4212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421205,
            zip_code: 42240,
            name_th: "บ้านเพิ่ม",
            name_en: "Ban Phoem",
            amphure_id: 4212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4213,
        name_th: "เอราวัณ",
        name_en: "Erawan",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 421301,
            zip_code: 42220,
            name_th: "เอราวัณ",
            name_en: "Erawan",
            amphure_id: 4213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421302,
            zip_code: 42220,
            name_th: "ผาอินทร์แปลง",
            name_en: "Pha In Plaeng",
            amphure_id: 4213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421303,
            zip_code: 42220,
            name_th: "ผาสามยอด",
            name_en: "Pha Sam Yot",
            amphure_id: 4213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421304,
            zip_code: 42220,
            name_th: "ทรัพย์ไพวัลย์",
            name_en: "Sap Phaiwan",
            amphure_id: 4213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4214,
        name_th: "หนองหิน",
        name_en: "Nong Hin",
        province_id: 30,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 421401,
            zip_code: 42190,
            name_th: "หนองหิน",
            name_en: "Nong Hin",
            amphure_id: 4214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421402,
            zip_code: 42190,
            name_th: "ตาดข่า",
            name_en: "Tat Kha",
            amphure_id: 4214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 421403,
            zip_code: 42190,
            name_th: "ปวนพุ",
            name_en: "Puan Phu",
            amphure_id: 4214,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 31,
    name_th: "หนองคาย",
    name_en: "Nong Khai",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4301,
        name_th: "เมืองหนองคาย",
        name_en: "Mueang Nong Khai",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 430101,
            zip_code: 43000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430102,
            zip_code: 43000,
            name_th: "มีชัย",
            name_en: "Mi Chai",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430103,
            zip_code: 43000,
            name_th: "โพธิ์ชัย",
            name_en: "Pho Chai",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430104,
            zip_code: 43000,
            name_th: "กวนวัน",
            name_en: "Kuan Wan",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430105,
            zip_code: 43000,
            name_th: "เวียงคุก",
            name_en: "Wiang Khuk",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430106,
            zip_code: 43000,
            name_th: "วัดธาตุ",
            name_en: "Wat That",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430107,
            zip_code: 43000,
            name_th: "หาดคำ",
            name_en: "Hat Kham",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430108,
            zip_code: 43000,
            name_th: "หินโงม",
            name_en: "Hin Ngom",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430109,
            zip_code: 43000,
            name_th: "บ้านเดื่อ",
            name_en: "Ban Duea",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430110,
            zip_code: 43100,
            name_th: "ค่ายบกหวาน",
            name_en: "Khai Bok Wan",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430111,
            zip_code: 43100,
            name_th: "สองห้อง",
            name_en: "Song Hong",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430113,
            zip_code: 43100,
            name_th: "พระธาตุบังพวน",
            name_en: "Phra That Bang Phuan",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430116,
            zip_code: 43000,
            name_th: "หนองกอมเกาะ",
            name_en: "Nong Kom Ko",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430117,
            zip_code: 43000,
            name_th: "ปะโค",
            name_en: "Pa Kho",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430118,
            zip_code: 43000,
            name_th: "เมืองหมี",
            name_en: "Mueang Mi",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430119,
            zip_code: 43000,
            name_th: "สีกาย",
            name_en: "Si Kai",
            amphure_id: 4301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4302,
        name_th: "ท่าบ่อ",
        name_en: "Tha Bo",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 430201,
            zip_code: 43110,
            name_th: "ท่าบ่อ",
            name_en: "Tha Bo",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430202,
            zip_code: 43110,
            name_th: "น้ำโมง",
            name_en: "Nam Mong",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430203,
            zip_code: 43110,
            name_th: "กองนาง",
            name_en: "Kong Nang",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430204,
            zip_code: 43110,
            name_th: "โคกคอน",
            name_en: "Khok Khon",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430205,
            zip_code: 43110,
            name_th: "บ้านเดื่อ",
            name_en: "Ban Duea",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430206,
            zip_code: 43110,
            name_th: "บ้านถ่อน",
            name_en: "Ban Thon",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430207,
            zip_code: 43110,
            name_th: "บ้านว่าน",
            name_en: "Ban Wan",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430208,
            zip_code: 43110,
            name_th: "นาข่า",
            name_en: "Na Kha",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430209,
            zip_code: 43110,
            name_th: "โพนสา",
            name_en: "Phon Sa",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430210,
            zip_code: 43110,
            name_th: "หนองนาง",
            name_en: "Nong Nang",
            amphure_id: 4302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4305,
        name_th: "โพนพิสัย",
        name_en: "Phon Phisai",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 430501,
            zip_code: 43120,
            name_th: "จุมพล",
            name_en: "Chumphon",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430502,
            zip_code: 43120,
            name_th: "วัดหลวง",
            name_en: "Wat Luang",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430503,
            zip_code: 43120,
            name_th: "กุดบง",
            name_en: "Kut Bong",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430504,
            zip_code: 43120,
            name_th: "ชุมช้าง",
            name_en: "Chum Chang",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430506,
            zip_code: 43120,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430507,
            zip_code: 43120,
            name_th: "เหล่าต่างคำ",
            name_en: "Lao Tang Kham",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430508,
            zip_code: 43120,
            name_th: "นาหนัง",
            name_en: "Na Nang",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430509,
            zip_code: 43120,
            name_th: "เซิม",
            name_en: "Soem",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430513,
            zip_code: 43120,
            name_th: "บ้านโพธิ์",
            name_en: "Ban Pho",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430521,
            zip_code: 43120,
            name_th: "บ้านผือ",
            name_en: "Ban Phue",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430522,
            zip_code: 43120,
            name_th: "สร้างนางขาว",
            name_en: "Sang Nang Khao",
            amphure_id: 4305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4307,
        name_th: "ศรีเชียงใหม่",
        name_en: "Si Chiang Mai",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 430701,
            zip_code: 43130,
            name_th: "พานพร้าว",
            name_en: "Phan Phrao",
            amphure_id: 4307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430703,
            zip_code: 43130,
            name_th: "บ้านหม้อ",
            name_en: "Ban Mo",
            amphure_id: 4307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430704,
            zip_code: 43130,
            name_th: "พระพุทธบาท",
            name_en: "Phra Phutthabat",
            amphure_id: 4307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430705,
            zip_code: 43130,
            name_th: "หนองปลาปาก",
            name_en: "Nong Pla Pak",
            amphure_id: 4307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4308,
        name_th: "สังคม",
        name_en: "Sangkhom",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 430801,
            zip_code: 43160,
            name_th: "แก้งไก่",
            name_en: "Kaeng Kai",
            amphure_id: 4308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430802,
            zip_code: 43160,
            name_th: "ผาตั้ง",
            name_en: "Pha Tang",
            amphure_id: 4308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430803,
            zip_code: 43160,
            name_th: "บ้านม่วง",
            name_en: "Ban Muang",
            amphure_id: 4308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430804,
            zip_code: 43160,
            name_th: "นางิ้ว",
            name_en: "Na Ngio",
            amphure_id: 4308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 430805,
            zip_code: 43160,
            name_th: "สังคม",
            name_en: "Sangkhom",
            amphure_id: 4308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4314,
        name_th: "สระใคร",
        name_en: "Sakhrai",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 431401,
            zip_code: 43100,
            name_th: "สระใคร",
            name_en: "Sakhrai",
            amphure_id: 4314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431402,
            zip_code: 43100,
            name_th: "คอกช้าง",
            name_en: "Khok Chang",
            amphure_id: 4314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431403,
            zip_code: 43100,
            name_th: "บ้านฝาง",
            name_en: "Ban Fang",
            amphure_id: 4314,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4315,
        name_th: "เฝ้าไร่",
        name_en: "Fao Rai",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 431501,
            zip_code: 43120,
            name_th: "เฝ้าไร่",
            name_en: "Fao Rai",
            amphure_id: 4315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431502,
            zip_code: 43120,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 4315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431503,
            zip_code: 43120,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 4315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431504,
            zip_code: 43120,
            name_th: "วังหลวง",
            name_en: "Wang Luang",
            amphure_id: 4315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431505,
            zip_code: 43120,
            name_th: "อุดมพร",
            name_en: "Udom Phon",
            amphure_id: 4315,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4316,
        name_th: "รัตนวาปี",
        name_en: "Rattanawapi",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 431601,
            zip_code: 43120,
            name_th: "รัตนวาปี",
            name_en: "Rattanawapi",
            amphure_id: 4316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431602,
            zip_code: 43120,
            name_th: "นาทับไฮ",
            name_en: "Na Thap Hai",
            amphure_id: 4316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431603,
            zip_code: 43120,
            name_th: "บ้านต้อน",
            name_en: "Ban Ton",
            amphure_id: 4316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431604,
            zip_code: 43120,
            name_th: "พระบาทนาสิงห์",
            name_en: "Phra Bat Na Sing",
            amphure_id: 4316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431605,
            zip_code: 43120,
            name_th: "โพนแพง",
            name_en: "Phon Phaeng",
            amphure_id: 4316,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4317,
        name_th: "โพธิ์ตาก",
        name_en: "Pho Tak",
        province_id: 31,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 431701,
            zip_code: 43130,
            name_th: "โพธิ์ตาก",
            name_en: "Pho Tak",
            amphure_id: 4317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431702,
            zip_code: 43130,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 4317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 431703,
            zip_code: 43130,
            name_th: "ด่านศรีสุข",
            name_en: "Dan Si Suk",
            amphure_id: 4317,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 32,
    name_th: "มหาสารคาม",
    name_en: "Maha Sarakham",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4401,
        name_th: "เมืองมหาสารคาม",
        name_en: "Mueang Maha Sarakham",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440101,
            zip_code: 44000,
            name_th: "ตลาด",
            name_en: "Talat",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440102,
            zip_code: 44000,
            name_th: "เขวา",
            name_en: "Khwao",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440103,
            zip_code: 44000,
            name_th: "ท่าตูม",
            name_en: "Tha Tum",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440104,
            zip_code: 44000,
            name_th: "แวงน่าง",
            name_en: "Waeng Nang",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440105,
            zip_code: 44000,
            name_th: "โคกก่อ",
            name_en: "Khok Ko",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440106,
            zip_code: 44000,
            name_th: "ดอนหว่าน",
            name_en: "Don Wan",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440107,
            zip_code: 44000,
            name_th: "เกิ้ง",
            name_en: "Koeng",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440108,
            zip_code: 44000,
            name_th: "แก่งเลิงจาน",
            name_en: "Kaeng Loeng Chan",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440109,
            zip_code: 44000,
            name_th: "ท่าสองคอน",
            name_en: "Tha Song Khon",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440110,
            zip_code: 44000,
            name_th: "ลาดพัฒนา",
            name_en: "Lat Phatthana",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440111,
            zip_code: 44000,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440112,
            zip_code: 44000,
            name_th: "ห้วยแอ่ง",
            name_en: "Huai Aeng",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440113,
            zip_code: 44000,
            name_th: "หนองโน",
            name_en: "Nong No",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440114,
            zip_code: 44000,
            name_th: "บัวค้อ",
            name_en: "Bua Kho",
            amphure_id: 4401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4402,
        name_th: "แกดำ",
        name_en: "Kae Dam",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440201,
            zip_code: 44190,
            name_th: "แกดำ",
            name_en: "Kae Dam",
            amphure_id: 4402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440202,
            zip_code: 44190,
            name_th: "วังแสง",
            name_en: "Wang Saeng",
            amphure_id: 4402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440203,
            zip_code: 44190,
            name_th: "มิตรภาพ",
            name_en: "Mittraphap",
            amphure_id: 4402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440204,
            zip_code: 44190,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 4402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440205,
            zip_code: 44190,
            name_th: "โนนภิบาล",
            name_en: "Non Phiban",
            amphure_id: 4402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4403,
        name_th: "โกสุมพิสัย",
        name_en: "Kosum Phisai",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440301,
            zip_code: 44140,
            name_th: "หัวขวาง",
            name_en: "Hua Khwang",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440302,
            zip_code: 44140,
            name_th: "ยางน้อย",
            name_en: "Yang Noi",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440303,
            zip_code: 44140,
            name_th: "วังยาว",
            name_en: "Wang Yao",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440304,
            zip_code: 44140,
            name_th: "เขวาไร่",
            name_en: "Khwao Rai",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440305,
            zip_code: 44140,
            name_th: "แพง",
            name_en: "Phaeng",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440306,
            zip_code: 44140,
            name_th: "แก้งแก",
            name_en: "Kaeng Kae",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440307,
            zip_code: 44140,
            name_th: "หนองเหล็ก",
            name_en: "Nong Lek",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440308,
            zip_code: 44140,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440309,
            zip_code: 44140,
            name_th: "เหล่า",
            name_en: "Lao",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440310,
            zip_code: 44140,
            name_th: "เขื่อน",
            name_en: "Khuean",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440311,
            zip_code: 44140,
            name_th: "หนองบอน",
            name_en: "Nong Bua",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440312,
            zip_code: 44140,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440313,
            zip_code: 44140,
            name_th: "ยางท่าแจ้ง",
            name_en: "Yang Tha Chaeng",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440314,
            zip_code: 44140,
            name_th: "แห่ใต้",
            name_en: "Hae Tai",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440315,
            zip_code: 44140,
            name_th: "หนองกุงสวรรค์",
            name_en: "Nong Kung Sawan",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440316,
            zip_code: 44140,
            name_th: "เลิงใต้",
            name_en: "Loeng Tai",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440317,
            zip_code: 44140,
            name_th: "ดอนกลาง",
            name_en: "Don Klang",
            amphure_id: 4403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4404,
        name_th: "กันทรวิชัย",
        name_en: "Kantharawichai",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440401,
            zip_code: 44150,
            name_th: "โคกพระ",
            name_en: "Khok Phra",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440402,
            zip_code: 44150,
            name_th: "คันธารราษฎร์",
            name_en: "Khanthararat",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440403,
            zip_code: 44150,
            name_th: "มะค่า",
            name_en: "Makha",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440404,
            zip_code: 44150,
            name_th: "ท่าขอนยาง",
            name_en: "Tha Khon Yang",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440405,
            zip_code: 44150,
            name_th: "นาสีนวน",
            name_en: "Na Si Nuan",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440406,
            zip_code: 44150,
            name_th: "ขามเรียง",
            name_en: "Kham Riang",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440407,
            zip_code: 44150,
            name_th: "เขวาใหญ่",
            name_en: "Khwao Yai",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440408,
            zip_code: 44150,
            name_th: "ศรีสุข",
            name_en: "Si Suk",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440409,
            zip_code: 44150,
            name_th: "กุดใส้จ่อ",
            name_en: "Kut Sai Cho",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440410,
            zip_code: 44150,
            name_th: "ขามเฒ่าพัฒนา",
            name_en: "Kham Thao Phatthana",
            amphure_id: 4404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4405,
        name_th: "เชียงยืน",
        name_en: "Kantharawichai",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440501,
            zip_code: 44160,
            name_th: "เชียงยืน",
            name_en: "Chiang Yuen",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440503,
            zip_code: 44160,
            name_th: "หนองซอน",
            name_en: "Nong Son",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440505,
            zip_code: 44160,
            name_th: "ดอนเงิน",
            name_en: "Don Ngoen",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440506,
            zip_code: 44160,
            name_th: "กู่ทอง",
            name_en: "Ku Thong",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440507,
            zip_code: 44160,
            name_th: "นาทอง",
            name_en: "Na Thong",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440508,
            zip_code: 44160,
            name_th: "เสือเฒ่า",
            name_en: "Suea Thao",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440511,
            zip_code: 44160,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440512,
            zip_code: 44160,
            name_th: "เหล่าบัวบาน",
            name_en: "Lao Bua Ban",
            amphure_id: 4405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4406,
        name_th: "บรบือ",
        name_en: "Borabue",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440601,
            zip_code: 44130,
            name_th: "บรบือ",
            name_en: "Borabue",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440602,
            zip_code: 44130,
            name_th: "บ่อใหญ่",
            name_en: "Bo Yai",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440604,
            zip_code: 44130,
            name_th: "วังไชย",
            name_en: "Wang Chai",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440605,
            zip_code: 44130,
            name_th: "หนองม่วง",
            name_en: "Nong Muang",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440606,
            zip_code: 44130,
            name_th: "กำพี้",
            name_en: "Kamphi",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440607,
            zip_code: 44130,
            name_th: "โนนราษี",
            name_en: "Non Rasi",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440608,
            zip_code: 44130,
            name_th: "โนนแดง",
            name_en: "Non Daeng",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440610,
            zip_code: 44130,
            name_th: "หนองจิก",
            name_en: "Nong Chik",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440611,
            zip_code: 44130,
            name_th: "บัวมาศ",
            name_en: "Bua Mat",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440613,
            zip_code: 44130,
            name_th: "หนองคูขาด",
            name_en: "Nong Khu Khat",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440615,
            zip_code: 44130,
            name_th: "วังใหม่",
            name_en: "Wang Mai",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440616,
            zip_code: 44130,
            name_th: "ยาง",
            name_en: "Yang",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440618,
            zip_code: 44130,
            name_th: "หนองสิม",
            name_en: "Nong Sim",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440619,
            zip_code: 44130,
            name_th: "หนองโก",
            name_en: "Nong Ko",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440620,
            zip_code: 44130,
            name_th: "ดอนงัว",
            name_en: "Don Ngua",
            amphure_id: 4406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4407,
        name_th: "นาเชือก",
        name_en: "Na Chueak",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440701,
            zip_code: 44170,
            name_th: "นาเชือก",
            name_en: "Na Chueak",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440702,
            zip_code: 44170,
            name_th: "สำโรง",
            name_en: "Samrong",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440703,
            zip_code: 44170,
            name_th: "หนองแดง",
            name_en: "Nong Daeng",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440704,
            zip_code: 44170,
            name_th: "เขวาไร่",
            name_en: "Khwao Rai",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440705,
            zip_code: 44170,
            name_th: "หนองโพธิ์",
            name_en: "Nong Pho",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440706,
            zip_code: 44170,
            name_th: "ปอพาน",
            name_en: "Po Phan",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440707,
            zip_code: 44170,
            name_th: "หนองเม็ก",
            name_en: "Nong Mek",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440708,
            zip_code: 44170,
            name_th: "หนองเรือ",
            name_en: "Nong Ruea",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440709,
            zip_code: 44170,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440710,
            zip_code: 44170,
            name_th: "สันป่าตอง",
            name_en: "San Pa Ton",
            amphure_id: 4407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4408,
        name_th: "พยัคฆภูมิพิสัย",
        name_en: "Phayakkhaphum Phisai",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440801,
            zip_code: 44110,
            name_th: "ปะหลาน",
            name_en: "Palan",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440802,
            zip_code: 44110,
            name_th: "ก้ามปู",
            name_en: "Kam Pu",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440803,
            zip_code: 44110,
            name_th: "เวียงสะอาด",
            name_en: "Wiang Sa-at",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440804,
            zip_code: 44110,
            name_th: "เม็กดำ",
            name_en: "Mek Dam",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440805,
            zip_code: 44110,
            name_th: "นาสีนวล",
            name_en: "Na Si Nuan",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440809,
            zip_code: 44110,
            name_th: "ราษฎร์เจริญ",
            name_en: "Rat Charoen",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440810,
            zip_code: 44110,
            name_th: "หนองบัวแก้ว",
            name_en: "Nong Bua Kaeo",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440812,
            zip_code: 44110,
            name_th: "เมืองเตา",
            name_en: "Mueang Tao",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440815,
            zip_code: 44110,
            name_th: "ลานสะแก",
            name_en: "Lan Sakae",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440816,
            zip_code: 44110,
            name_th: "เวียงชัย",
            name_en: "Wiang Chai",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440817,
            zip_code: 44110,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440818,
            zip_code: 44110,
            name_th: "ราษฎร์พัฒนา",
            name_en: "Rat Phatthana",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440819,
            zip_code: 44110,
            name_th: "เมืองเสือ",
            name_en: "Mueang Suea",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440820,
            zip_code: 44110,
            name_th: "ภารแอ่น",
            name_en: "Phan Aen",
            amphure_id: 4408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4409,
        name_th: "วาปีปทุม",
        name_en: "Wapi Pathum",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 440901,
            zip_code: 44120,
            name_th: "หนองแสง",
            name_en: "Nong Saeng",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440902,
            zip_code: 44120,
            name_th: "ขามป้อม",
            name_en: "Kham Pom",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440903,
            zip_code: 44120,
            name_th: "เสือโก้ก",
            name_en: "Suea Kok",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440904,
            zip_code: 44120,
            name_th: "ดงใหญ่",
            name_en: "Dong Yai",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440905,
            zip_code: 44120,
            name_th: "โพธิ์ชัย",
            name_en: "Pho Chai",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440906,
            zip_code: 44120,
            name_th: "หัวเรือ",
            name_en: "Hua Ruea",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440907,
            zip_code: 44120,
            name_th: "แคน",
            name_en: "Khaen",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440908,
            zip_code: 44120,
            name_th: "งัวบา",
            name_en: "Ngua Ba",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440909,
            zip_code: 44120,
            name_th: "นาข่า",
            name_en: "Na Kha",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440910,
            zip_code: 44120,
            name_th: "บ้านหวาย",
            name_en: "Ban Wai",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440911,
            zip_code: 44120,
            name_th: "หนองไฮ",
            name_en: "Nong Hai",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440912,
            zip_code: 44120,
            name_th: "ประชาพัฒนา",
            name_en: "Pracha Phatthana",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440913,
            zip_code: 44120,
            name_th: "หนองทุ่ม",
            name_en: "Nong Thum",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440914,
            zip_code: 44120,
            name_th: "หนองแสน",
            name_en: "Nong Saen",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 440915,
            zip_code: 44120,
            name_th: "โคกสีทองหลาง",
            name_en: "Khok Si Thonglang",
            amphure_id: 4409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4410,
        name_th: "นาดูน",
        name_en: "Na Dun",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 441001,
            zip_code: 44180,
            name_th: "นาดูน",
            name_en: "Na Dun",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441002,
            zip_code: 44180,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441003,
            zip_code: 44180,
            name_th: "หนองคู",
            name_en: "Nong Khu",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441004,
            zip_code: 44180,
            name_th: "ดงบัง",
            name_en: "Dong Bang",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441005,
            zip_code: 44180,
            name_th: "ดงดวน",
            name_en: "Dong Duan",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441006,
            zip_code: 44180,
            name_th: "หัวดง",
            name_en: "Hua Dong",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441007,
            zip_code: 44180,
            name_th: "ดงยาง",
            name_en: "Dong Yang",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441008,
            zip_code: 44180,
            name_th: "กู่สันตรัตน์",
            name_en: "Ku Santarat",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441009,
            zip_code: 44180,
            name_th: "พระธาตุ",
            name_en: "Phra That",
            amphure_id: 4410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4411,
        name_th: "ยางสีสุราช",
        name_en: "Yang Sisurat",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 441101,
            zip_code: 44210,
            name_th: "ยางสีสุราช",
            name_en: "Yang Sisurat",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441102,
            zip_code: 44210,
            name_th: "นาภู",
            name_en: "Na Phu",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441103,
            zip_code: 44210,
            name_th: "แวงดง",
            name_en: "Waeng Dong",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441104,
            zip_code: 44210,
            name_th: "บ้านกู่",
            name_en: "Ban Ku",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441105,
            zip_code: 44210,
            name_th: "ดงเมือง",
            name_en: "Dong Mueang",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441106,
            zip_code: 44210,
            name_th: "ขามเรียน",
            name_en: "Sang Saeng",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441107,
            zip_code: 44210,
            name_th: "หนองบัวสันตุ",
            name_en: "Nong Bua Santu",
            amphure_id: 4411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4412,
        name_th: "กุดรัง",
        name_en: "Kut Rang",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 441201,
            zip_code: 44130,
            name_th: "กุดรัง",
            name_en: "Kud Rang",
            amphure_id: 4412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441202,
            zip_code: 44130,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 4412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441203,
            zip_code: 44130,
            name_th: "เลิงแฝก",
            name_en: "Loeng Faek",
            amphure_id: 4412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441204,
            zip_code: 44130,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441205,
            zip_code: 44130,
            name_th: "ห้วยเตย",
            name_en: "Huai Toei",
            amphure_id: 4412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4413,
        name_th: "ชื่นชม",
        name_en: "Chuen Chom",
        province_id: 32,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 441301,
            zip_code: 44160,
            name_th: "ชื่นชม",
            name_en: "Chuen Chom",
            amphure_id: 4413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441302,
            zip_code: 44160,
            name_th: "กุดปลาดุก",
            name_en: "Kut Pla Duk",
            amphure_id: 4413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441303,
            zip_code: 44160,
            name_th: "เหล่าดอกไม้",
            name_en: "Lao Dok Mai",
            amphure_id: 4413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 441304,
            zip_code: 44160,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 4413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 33,
    name_th: "ร้อยเอ็ด",
    name_en: "Roi Et",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4501,
        name_th: "เมืองร้อยเอ็ด",
        name_en: "Mueang Roi Et",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450101,
            zip_code: 45000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450102,
            zip_code: 45000,
            name_th: "รอบเมือง",
            name_en: "Rop Mueang",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450103,
            zip_code: 45000,
            name_th: "เหนือเมือง",
            name_en: "Nuea Mueang",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450104,
            zip_code: 45000,
            name_th: "ขอนแก่น",
            name_en: "Khon Kaen",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450105,
            zip_code: 45000,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450106,
            zip_code: 45000,
            name_th: "สะอาดสมบูรณ์",
            name_en: "Sa-at Sombun",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450108,
            zip_code: 45000,
            name_th: "สีแก้ว",
            name_en: "Si Kaeo",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450109,
            zip_code: 45000,
            name_th: "ปอภาร  (ปอพาน)",
            name_en: "Po Phan",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450110,
            zip_code: 45000,
            name_th: "โนนรัง",
            name_en: "Non Rang",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450117,
            zip_code: 45000,
            name_th: "หนองแก้ว",
            name_en: "Nong Kaeo",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450118,
            zip_code: 45000,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450120,
            zip_code: 45000,
            name_th: "ดงลาน",
            name_en: "Dong Lan",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450123,
            zip_code: 45000,
            name_th: "แคนใหญ่",
            name_en: "Khaen Yai",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450124,
            zip_code: 45000,
            name_th: "โนนตาล",
            name_en: "Non Tan",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450125,
            zip_code: 45000,
            name_th: "เมืองทอง",
            name_en: "Mueang Thong",
            amphure_id: 4501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4502,
        name_th: "เกษตรวิสัย",
        name_en: "Kaset Wisai",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450201,
            zip_code: 45150,
            name_th: "เกษตรวิสัย",
            name_en: "Kaset Wisai",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450202,
            zip_code: 45150,
            name_th: "เมืองบัว",
            name_en: "Mueang Bua",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450203,
            zip_code: 45150,
            name_th: "เหล่าหลวง",
            name_en: "Lao Luang",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450204,
            zip_code: 45150,
            name_th: "สิงห์โคก",
            name_en: "Sing Khok",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450205,
            zip_code: 45150,
            name_th: "ดงครั่งใหญ่",
            name_en: "Dong Khrang Yai",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450206,
            zip_code: 45150,
            name_th: "บ้านฝาง",
            name_en: "Ban Fang",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450207,
            zip_code: 45150,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450208,
            zip_code: 45150,
            name_th: "กำแพง",
            name_en: "Kamphaeng",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450209,
            zip_code: 45150,
            name_th: "กู่กาสิงห์",
            name_en: "Ku Ka Sing",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450210,
            zip_code: 45150,
            name_th: "น้ำอ้อม",
            name_en: "Nam Om",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450211,
            zip_code: 45150,
            name_th: "โนนสว่าง",
            name_en: "Non Sawang",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450212,
            zip_code: 45150,
            name_th: "ทุ่งทอง",
            name_en: "Thung Thong",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450213,
            zip_code: 45150,
            name_th: "ดงครั่งน้อย",
            name_en: "Dong Khrang Noi",
            amphure_id: 4502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4503,
        name_th: "ปทุมรัตต์",
        name_en: "Pathum Rat",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450301,
            zip_code: 45190,
            name_th: "บัวแดง",
            name_en: "Bua Daeng",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450302,
            zip_code: 45190,
            name_th: "ดอกล้ำ",
            name_en: "Dok Lam",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450303,
            zip_code: 45190,
            name_th: "หนองแคน",
            name_en: "Nong Khaen",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450304,
            zip_code: 45190,
            name_th: "โพนสูง",
            name_en: "Phon Sung",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450305,
            zip_code: 45190,
            name_th: "โนนสวรรค์",
            name_en: "Non Sawan",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450306,
            zip_code: 45190,
            name_th: "สระบัว",
            name_en: "Sa Bua",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450307,
            zip_code: 45190,
            name_th: "โนนสง่า",
            name_en: "Non Sa-nga",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450308,
            zip_code: 45190,
            name_th: "ขี้เหล็ก",
            name_en: "Khilek",
            amphure_id: 4503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4504,
        name_th: "จตุรพักตรพิมาน",
        name_en: "Chaturaphak Phiman",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450401,
            zip_code: 45180,
            name_th: "หัวช้าง",
            name_en: "Hua Chang",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450402,
            zip_code: 45180,
            name_th: "หนองผือ",
            name_en: "Nong Phue",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450403,
            zip_code: 45180,
            name_th: "เมืองหงส์",
            name_en: "Mueang Hong",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450404,
            zip_code: 45180,
            name_th: "โคกล่าม",
            name_en: "Khok Lam",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450405,
            zip_code: 45180,
            name_th: "น้ำใส",
            name_en: "Nam Sai",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450406,
            zip_code: 45180,
            name_th: "ดงแดง",
            name_en: "Dong Daeng",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450407,
            zip_code: 45180,
            name_th: "ดงกลาง",
            name_en: "Dong Klang",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450408,
            zip_code: 45180,
            name_th: "ป่าสังข์",
            name_en: "Pa Sang",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450409,
            zip_code: 45180,
            name_th: "อีง่อง",
            name_en: "I Ngong",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450410,
            zip_code: 45180,
            name_th: "ลิ้นฟ้า",
            name_en: "Lin Fa",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450411,
            zip_code: 45180,
            name_th: "ดู่น้อย",
            name_en: "Du Noi",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450412,
            zip_code: 45180,
            name_th: "ศรีโคตร",
            name_en: "Si Khot",
            amphure_id: 4504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4505,
        name_th: "ธวัชบุรี",
        name_en: "Thawat Buri",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450501,
            zip_code: 45170,
            name_th: "นิเวศน์",
            name_en: "Niwet",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450502,
            zip_code: 45170,
            name_th: "ธงธานี",
            name_en: "Thong Thani",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450503,
            zip_code: 45170,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450504,
            zip_code: 45170,
            name_th: "ธวัชบุรี",
            name_en: "Thawat Buri",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450506,
            zip_code: 45170,
            name_th: "อุ่มเม้า",
            name_en: "Um Mao",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450507,
            zip_code: 45170,
            name_th: "มะอึ",
            name_en: "Ma-ue",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450510,
            zip_code: 45170,
            name_th: "เขวาทุ่ง",
            name_en: "Khwao Thung",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450515,
            zip_code: 45170,
            name_th: "ไพศาล",
            name_en: "Phaisan",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450517,
            zip_code: 45170,
            name_th: "เมืองน้อย",
            name_en: "Mueang Noi",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450520,
            zip_code: 45170,
            name_th: "บึงนคร",
            name_en: "Bueng Nakhon",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450522,
            zip_code: 45170,
            name_th: "ราชธานี",
            name_en: "Ratchathani",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450524,
            zip_code: 45170,
            name_th: "หนองพอก",
            name_en: "Nong Phok",
            amphure_id: 4505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4506,
        name_th: "พนมไพร",
        name_en: "Phanom Phrai",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450601,
            zip_code: 45140,
            name_th: "พนมไพร",
            name_en: "Phanom Phrai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450602,
            zip_code: 45140,
            name_th: "แสนสุข",
            name_en: "Saen Suk",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450603,
            zip_code: 45140,
            name_th: "กุดน้ำใส",
            name_en: "Kut Nam Sai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450604,
            zip_code: 45140,
            name_th: "หนองทัพไทย",
            name_en: "Nong Thap Thai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450605,
            zip_code: 45140,
            name_th: "โพธิ์ใหญ่",
            name_en: "Pho Yai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450606,
            zip_code: 45140,
            name_th: "วารีสวัสดิ์",
            name_en: "Wari Sawat",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450607,
            zip_code: 45140,
            name_th: "โคกสว่าง",
            name_en: "Khok Sawang",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450611,
            zip_code: 45140,
            name_th: "โพธิ์ชัย",
            name_en: "Pho Chai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450612,
            zip_code: 45140,
            name_th: "นานวล",
            name_en: "Na Nuan",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450613,
            zip_code: 45140,
            name_th: "คำไฮ",
            name_en: "Kham Hai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450614,
            zip_code: 45140,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450615,
            zip_code: 45140,
            name_th: "ค้อใหญ่",
            name_en: "Kho Yai",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450617,
            zip_code: 45140,
            name_th: "ชานุวรรณ",
            name_en: "Chanuwan",
            amphure_id: 4506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4507,
        name_th: "โพนทอง",
        name_en: "Phon Thong",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450701,
            zip_code: 45110,
            name_th: "แวง",
            name_en: "Waeng",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450702,
            zip_code: 45110,
            name_th: "โคกกกม่วง",
            name_en: "Khok Kok Muang",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450703,
            zip_code: 45110,
            name_th: "นาอุดม",
            name_en: "Na Udom",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450704,
            zip_code: 45110,
            name_th: "สว่าง",
            name_en: "Sawang",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450705,
            zip_code: 45110,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450706,
            zip_code: 45110,
            name_th: "โพธิ์ทอง",
            name_en: "Pho Thong",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450707,
            zip_code: 45110,
            name_th: "โนนชัยศรี",
            name_en: "Non Chai Si",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450708,
            zip_code: 45110,
            name_th: "โพธิ์ศรีสว่าง",
            name_en: "Pho Si Sawang",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450709,
            zip_code: 45110,
            name_th: "อุ่มเม่า",
            name_en: "Um Mao",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450710,
            zip_code: 45110,
            name_th: "คำนาดี",
            name_en: "Kham Na Di",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450711,
            zip_code: 45110,
            name_th: "พรมสวรรค์",
            name_en: "Phrom Sawan",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450712,
            zip_code: 45110,
            name_th: "สระนกแก้ว",
            name_en: "Sa Nok Kaeo",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450713,
            zip_code: 45110,
            name_th: "วังสามัคคี",
            name_en: "Wang Samakkhi",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450714,
            zip_code: 45110,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 4507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4508,
        name_th: "โพธิ์ชัย",
        name_en: "Pho Chai",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450801,
            zip_code: 45230,
            name_th: "ขามเปี้ย",
            name_en: "Kham Pia",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450802,
            zip_code: 45230,
            name_th: "เชียงใหม่",
            name_en: "Chiang Mai",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450803,
            zip_code: 45230,
            name_th: "บัวคำ",
            name_en: "Bua Kham",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450804,
            zip_code: 45230,
            name_th: "อัคคะคำ",
            name_en: "Akkha Kham",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450805,
            zip_code: 45230,
            name_th: "สะอาด",
            name_en: "Sa-at",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450806,
            zip_code: 45230,
            name_th: "คำพอุง",
            name_en: "Kham Pha-ung",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450807,
            zip_code: 45230,
            name_th: "หนองตาไก้",
            name_en: "Nong Ta Kai",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450808,
            zip_code: 45230,
            name_th: "ดอนโอง",
            name_en: "Don Ong",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450809,
            zip_code: 45230,
            name_th: "โพธิ์ศรี",
            name_en: "Pho Si",
            amphure_id: 4508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4509,
        name_th: "หนองพอก",
        name_en: "Nong Phok",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 450901,
            zip_code: 45210,
            name_th: "หนองพอก",
            name_en: "Nong Phok",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450902,
            zip_code: 45210,
            name_th: "บึงงาม",
            name_en: "Bueng Ngam",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450903,
            zip_code: 45210,
            name_th: "ภูเขาทอง",
            name_en: "Phukhao Thong",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450904,
            zip_code: 45210,
            name_th: "กกโพธิ์",
            name_en: "Kok Pho",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450905,
            zip_code: 45210,
            name_th: "โคกสว่าง",
            name_en: "Khok Sawang",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450906,
            zip_code: 45210,
            name_th: "หนองขุ่นใหญ่",
            name_en: "Nong Khun Yai",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450907,
            zip_code: 45210,
            name_th: "รอบเมือง",
            name_en: "Rop Mueang",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450908,
            zip_code: 45210,
            name_th: "ผาน้ำย้อย",
            name_en: "Pha Nam Yoi",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 450909,
            zip_code: 45210,
            name_th: "ท่าสีดา",
            name_en: "Ta See Da",
            amphure_id: 4509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4510,
        name_th: "เสลภูมิ",
        name_en: "Selaphum",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451001,
            zip_code: 45120,
            name_th: "กลาง",
            name_en: "Klang",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451002,
            zip_code: 45120,
            name_th: "นางาม",
            name_en: "Na Ngam",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451003,
            zip_code: 45120,
            name_th: "เมืองไพร",
            name_en: "Mueang Phrai",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451004,
            zip_code: 45120,
            name_th: "นาแซง",
            name_en: "Na Saeng",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451005,
            zip_code: 45120,
            name_th: "นาเมือง",
            name_en: "Na Mueang",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451006,
            zip_code: 45120,
            name_th: "วังหลวง",
            name_en: "Wang Luang",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451007,
            zip_code: 45120,
            name_th: "ท่าม่วง",
            name_en: "Tha Muang",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451008,
            zip_code: 45120,
            name_th: "ขวาว",
            name_en: "Khwao",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451009,
            zip_code: 45120,
            name_th: "โพธิ์ทอง",
            name_en: "Pho Thong",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451010,
            zip_code: 45120,
            name_th: "ภูเงิน",
            name_en: "Phu Ngoen",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451011,
            zip_code: 45120,
            name_th: "เกาะแก้ว",
            name_en: "Ko Kaeo",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451012,
            zip_code: 45120,
            name_th: "นาเลิง",
            name_en: "Na Loeng",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451013,
            zip_code: 45120,
            name_th: "เหล่าน้อย",
            name_en: "Lao Noi",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451014,
            zip_code: 45120,
            name_th: "ศรีวิลัย",
            name_en: "Si Wilai",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451015,
            zip_code: 45120,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451016,
            zip_code: 45120,
            name_th: "พรสวรรค์",
            name_en: "Phon Sawan",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451017,
            zip_code: 45120,
            name_th: "ขวัญเมือง",
            name_en: "Khwan Mueang",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451018,
            zip_code: 45120,
            name_th: "บึงเกลือ",
            name_en: "Bueng Kluea",
            amphure_id: 4510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4511,
        name_th: "สุวรรณภูมิ",
        name_en: "Suwannaphum",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451101,
            zip_code: 45130,
            name_th: "สระคู",
            name_en: "Sa Khu",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451102,
            zip_code: 45130,
            name_th: "ดอกไม้",
            name_en: "Dok Mai",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451103,
            zip_code: 45130,
            name_th: "นาใหญ่",
            name_en: "Na Yai",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451104,
            zip_code: 45130,
            name_th: "หินกอง",
            name_en: "Hin Kong",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451105,
            zip_code: 45130,
            name_th: "เมืองทุ่ง",
            name_en: "Mueang Thung",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451106,
            zip_code: 45130,
            name_th: "หัวโทน",
            name_en: "Hua Thon",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451107,
            zip_code: 45130,
            name_th: "บ่อพันขัน",
            name_en: "Bo Phan Khan",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451108,
            zip_code: 45130,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451109,
            zip_code: 45130,
            name_th: "หัวช้าง",
            name_en: "Hua Chang",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451110,
            zip_code: 45130,
            name_th: "น้ำคำ",
            name_en: "Nam Kham",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451111,
            zip_code: 45130,
            name_th: "ห้วยหินลาด",
            name_en: "Huai Hin Lat",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451112,
            zip_code: 45130,
            name_th: "ช้างเผือก",
            name_en: "Chang Phueak",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451113,
            zip_code: 45130,
            name_th: "ทุ่งกุลา",
            name_en: "Thung Kula",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451114,
            zip_code: 45130,
            name_th: "ทุ่งศรีเมือง",
            name_en: "Thung Si Mueang",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451115,
            zip_code: 45130,
            name_th: "จำปาขัน",
            name_en: "Champa Khan",
            amphure_id: 4511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4512,
        name_th: "เมืองสรวง",
        name_en: "Mueang Suang",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451201,
            zip_code: 45220,
            name_th: "หนองผือ",
            name_en: "Nong Phue",
            amphure_id: 4512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451202,
            zip_code: 45220,
            name_th: "หนองหิน",
            name_en: "Nong Hin",
            amphure_id: 4512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451203,
            zip_code: 45220,
            name_th: "คูเมือง",
            name_en: "Khu Mueang",
            amphure_id: 4512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451204,
            zip_code: 45220,
            name_th: "กกกุง",
            name_en: "Kok Kung",
            amphure_id: 4512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451205,
            zip_code: 45220,
            name_th: "เมืองสรวง",
            name_en: "Mueang Suang",
            amphure_id: 4512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4513,
        name_th: "โพนทราย",
        name_en: "Phon Sai",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451301,
            zip_code: 45240,
            name_th: "โพนทราย",
            name_en: "Phon Sai",
            amphure_id: 4513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451302,
            zip_code: 45240,
            name_th: "สามขา",
            name_en: "Sam Kha",
            amphure_id: 4513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451303,
            zip_code: 45240,
            name_th: "ศรีสว่าง",
            name_en: "Si Sawang",
            amphure_id: 4513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451304,
            zip_code: 45240,
            name_th: "ยางคำ",
            name_en: "Yang Kham",
            amphure_id: 4513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451305,
            zip_code: 45240,
            name_th: "ท่าหาดยาว",
            name_en: "Tha Hat Yao",
            amphure_id: 4513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4514,
        name_th: "อาจสามารถ",
        name_en: "At Samat",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451401,
            zip_code: 45160,
            name_th: "อาจสามารถ",
            name_en: "At Samat",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451402,
            zip_code: 45160,
            name_th: "โพนเมือง",
            name_en: "Phon Mueang",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451403,
            zip_code: 45160,
            name_th: "บ้านแจ้ง",
            name_en: "Ban Chaeng",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451404,
            zip_code: 45160,
            name_th: "หน่อม",
            name_en: "Nom",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451405,
            zip_code: 45160,
            name_th: "หนองหมื่นถ่าน",
            name_en: "Nong Muen Than",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451406,
            zip_code: 45160,
            name_th: "หนองขาม",
            name_en: "Nong Kham",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451407,
            zip_code: 45160,
            name_th: "โหรา",
            name_en: "Hora",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451408,
            zip_code: 45160,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451409,
            zip_code: 45160,
            name_th: "ขี้เหล็ก",
            name_en: "Khilek",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451410,
            zip_code: 45160,
            name_th: "บ้านดู่",
            name_en: "Ban Du",
            amphure_id: 4514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4515,
        name_th: "เมยวดี",
        name_en: "Moei Wadi",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451501,
            zip_code: 45250,
            name_th: "เมยวดี",
            name_en: "Moei Wadi",
            amphure_id: 4515,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451502,
            zip_code: 45250,
            name_th: "ชุมพร",
            name_en: "Chumphon",
            amphure_id: 4515,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451503,
            zip_code: 45250,
            name_th: "บุ่งเลิศ",
            name_en: "Bung Loet",
            amphure_id: 4515,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451504,
            zip_code: 45250,
            name_th: "ชมสะอาด",
            name_en: "Chom Sa-at",
            amphure_id: 4515,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4516,
        name_th: "ศรีสมเด็จ",
        name_en: "Si Somdet",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451601,
            zip_code: 45000,
            name_th: "โพธิ์ทอง",
            name_en: "Pho Thong",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451602,
            zip_code: 45000,
            name_th: "ศรีสมเด็จ",
            name_en: "Si Somdet",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451603,
            zip_code: 45000,
            name_th: "เมืองเปลือย",
            name_en: "Mueang Plueai",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451604,
            zip_code: 45000,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451605,
            zip_code: 45280,
            name_th: "สวนจิก",
            name_en: "Suan Chik",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451606,
            zip_code: 45280,
            name_th: "โพธิ์สัย",
            name_en: "Pho Sai",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451607,
            zip_code: 45000,
            name_th: "หนองแวงควง",
            name_en: "Nong Waeng Khuang",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451608,
            zip_code: 45000,
            name_th: "บ้านบาก",
            name_en: "Ban Bak",
            amphure_id: 4516,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4517,
        name_th: "จังหาร",
        name_en: "Changhan",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451701,
            zip_code: 45000,
            name_th: "ดินดำ",
            name_en: "Din Dam",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451702,
            zip_code: 45000,
            name_th: "ปาฝา",
            name_en: "Pa Fa",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451703,
            zip_code: 45000,
            name_th: "ม่วงลาด",
            name_en: "Muang Lat",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451704,
            zip_code: 45000,
            name_th: "จังหาร",
            name_en: "Changhan",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451705,
            zip_code: 45000,
            name_th: "ดงสิงห์",
            name_en: "Dong Sing",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451706,
            zip_code: 45000,
            name_th: "ยางใหญ่",
            name_en: "Yang Yai",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451707,
            zip_code: 45000,
            name_th: "ผักแว่น",
            name_en: "Phak Waen",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451708,
            zip_code: 45000,
            name_th: "แสนชาติ",
            name_en: "Saen Chat",
            amphure_id: 4517,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4518,
        name_th: "เชียงขวัญ",
        name_en: "Chiang Khwan",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451801,
            zip_code: 45000,
            name_th: "เชียงขวัญ",
            name_en: "Chiang Khwan",
            amphure_id: 4518,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451802,
            zip_code: 45170,
            name_th: "พลับพลา",
            name_en: "Phlapphla",
            amphure_id: 4518,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451803,
            zip_code: 45000,
            name_th: "พระธาตุ",
            name_en: "Phra That",
            amphure_id: 4518,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451804,
            zip_code: 45000,
            name_th: "พระเจ้า",
            name_en: "Phra Chao",
            amphure_id: 4518,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451805,
            zip_code: 45170,
            name_th: "หมูม้น",
            name_en: "Mu Mon",
            amphure_id: 4518,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451806,
            zip_code: 45000,
            name_th: "บ้านเขือง",
            name_en: "Ban Khueang",
            amphure_id: 4518,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4519,
        name_th: "หนองฮี",
        name_en: "Nong Hi",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 451901,
            zip_code: 45140,
            name_th: "หนองฮี",
            name_en: "Nong Hi",
            amphure_id: 4519,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451902,
            zip_code: 45140,
            name_th: "สาวแห",
            name_en: "Sao Hae",
            amphure_id: 4519,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451903,
            zip_code: 45140,
            name_th: "ดูกอึ่ง",
            name_en: "Duk Ueng",
            amphure_id: 4519,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 451904,
            zip_code: 45140,
            name_th: "เด่นราษฎร์",
            name_en: "Den Rat",
            amphure_id: 4519,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4520,
        name_th: "ทุ่งเขาหลวง",
        name_en: "Thung Khao Luangกิ่",
        province_id: 33,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 452001,
            zip_code: 45170,
            name_th: "ทุ่งเขาหลวง",
            name_en: "Thung Khao Luang",
            amphure_id: 4520,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 452002,
            zip_code: 45170,
            name_th: "เทอดไทย",
            name_en: "Thoet Thai",
            amphure_id: 4520,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 452003,
            zip_code: 45170,
            name_th: "บึงงาม",
            name_en: "Bueng Ngam",
            amphure_id: 4520,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 452004,
            zip_code: 45170,
            name_th: "มะบ้า",
            name_en: "Maba",
            amphure_id: 4520,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 452005,
            zip_code: 45170,
            name_th: "เหล่า",
            name_en: "Lao",
            amphure_id: 4520,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 34,
    name_th: "กาฬสินธุ์",
    name_en: "Kalasin",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4601,
        name_th: "เมืองกาฬสินธุ์",
        name_en: "Mueang Kalasin",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460101,
            zip_code: 46000,
            name_th: "กาฬสินธุ์",
            name_en: "Kalasin",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460102,
            zip_code: 46000,
            name_th: "เหนือ",
            name_en: "Nuea",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460103,
            zip_code: 46000,
            name_th: "หลุบ",
            name_en: "Lup",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460104,
            zip_code: 46000,
            name_th: "ไผ่",
            name_en: "Phai",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460105,
            zip_code: 46000,
            name_th: "ลำปาว",
            name_en: "Lam Pao",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460106,
            zip_code: 46000,
            name_th: "ลำพาน",
            name_en: "Lam Phan",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460107,
            zip_code: 46000,
            name_th: "เชียงเครือ",
            name_en: "Chiang Khruea",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460108,
            zip_code: 46000,
            name_th: "บึงวิชัย",
            name_en: "Bueng Wichai",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460109,
            zip_code: 46000,
            name_th: "ห้วยโพธิ์",
            name_en: "Huai Pho",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460111,
            zip_code: 46000,
            name_th: "ภูปอ",
            name_en: "Phu Po",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460113,
            zip_code: 46000,
            name_th: "ภูดิน",
            name_en: "Phu Din",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460115,
            zip_code: 46000,
            name_th: "หนองกุง",
            name_en: "Nong Kung",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460116,
            zip_code: 46000,
            name_th: "กลางหมื่น",
            name_en: "Klang Muen",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460117,
            zip_code: 46000,
            name_th: "ขมิ้น",
            name_en: "Khamin",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460119,
            zip_code: 46000,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460120,
            zip_code: 46000,
            name_th: "นาจารย์",
            name_en: "Na Chan",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460121,
            zip_code: 46000,
            name_th: "ลำคลอง",
            name_en: "Lam Khlong",
            amphure_id: 4601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4602,
        name_th: "นามน",
        name_en: "Na Mon",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460201,
            zip_code: 46230,
            name_th: "นามน",
            name_en: "Na Mon",
            amphure_id: 4602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460202,
            zip_code: 46230,
            name_th: "ยอดแกง",
            name_en: "Yot Kaeng",
            amphure_id: 4602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460203,
            zip_code: 46230,
            name_th: "สงเปลือย",
            name_en: "Song Plueai",
            amphure_id: 4602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460204,
            zip_code: 46230,
            name_th: "หลักเหลี่ยม",
            name_en: "Lak Liam",
            amphure_id: 4602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460205,
            zip_code: 46230,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4603,
        name_th: "กมลาไสย",
        name_en: "Kamalasai",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460301,
            zip_code: 46130,
            name_th: "กมลาไสย",
            name_en: "Kamalasai",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460302,
            zip_code: 46130,
            name_th: "หลักเมือง",
            name_en: "Lak Mueang",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460303,
            zip_code: 46130,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460304,
            zip_code: 46130,
            name_th: "ดงลิง",
            name_en: "Dong Ling",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460305,
            zip_code: 46130,
            name_th: "ธัญญา",
            name_en: "Thanya",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460308,
            zip_code: 46130,
            name_th: "หนองแปน",
            name_en: "Nong Paen",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460310,
            zip_code: 46130,
            name_th: "เจ้าท่า",
            name_en: "Chao Tha",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460311,
            zip_code: 46130,
            name_th: "โคกสมบูรณ์",
            name_en: "Khok Sombun",
            amphure_id: 4603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4604,
        name_th: "ร่องคำ",
        name_en: "Rong Kham",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460401,
            zip_code: 46210,
            name_th: "ร่องคำ",
            name_en: "Rong Kham",
            amphure_id: 4604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460402,
            zip_code: 46210,
            name_th: "สามัคคี",
            name_en: "Samakkhi",
            amphure_id: 4604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460403,
            zip_code: 46210,
            name_th: "เหล่าอ้อย",
            name_en: "Lao Oi",
            amphure_id: 4604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4605,
        name_th: "กุฉินารายณ์",
        name_en: "Kuchinarai",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460501,
            zip_code: 46110,
            name_th: "บัวขาว",
            name_en: "Bua Khao",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460502,
            zip_code: 46110,
            name_th: "แจนแลน",
            name_en: "Chaen Laen",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460503,
            zip_code: 46110,
            name_th: "เหล่าใหญ่",
            name_en: "Lao Yai",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460504,
            zip_code: 46110,
            name_th: "จุมจัง",
            name_en: "Chum Chang",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460505,
            zip_code: 46110,
            name_th: "เหล่าไฮงาม",
            name_en: "Lao Hai Ngam",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460506,
            zip_code: 46110,
            name_th: "กุดหว้า",
            name_en: "Kut Wa",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460507,
            zip_code: 46110,
            name_th: "สามขา",
            name_en: "Sam Kha",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460508,
            zip_code: 46110,
            name_th: "นาขาม",
            name_en: "Na Kham",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460509,
            zip_code: 46110,
            name_th: "หนองห้าง",
            name_en: "Nong Hang",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460510,
            zip_code: 46110,
            name_th: "นาโก",
            name_en: "Na Ko",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460511,
            zip_code: 46110,
            name_th: "สมสะอาด",
            name_en: "Som Sa-at",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460512,
            zip_code: 46110,
            name_th: "กุดค้าว",
            name_en: "Kut Khao",
            amphure_id: 4605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4606,
        name_th: "เขาวง",
        name_en: "Khao Wong",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460601,
            zip_code: 46160,
            name_th: "คุ้มเก่า",
            name_en: "Khum Kao",
            amphure_id: 4606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460602,
            zip_code: 46160,
            name_th: "สงเปลือย",
            name_en: "Song Plueai",
            amphure_id: 4606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460603,
            zip_code: 46160,
            name_th: "หนองผือ",
            name_en: "Nong Phue",
            amphure_id: 4606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460606,
            zip_code: 46160,
            name_th: "กุดสิมคุ้มใหม่",
            name_en: "Kut Sim Khum Mai",
            amphure_id: 4606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460608,
            zip_code: 46160,
            name_th: "สระพังทอง",
            name_en: "Saphang Thong",
            amphure_id: 4606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460611,
            zip_code: 46160,
            name_th: "กุดปลาค้าว",
            name_en: "Kut Pla Khao",
            amphure_id: 4606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4607,
        name_th: "ยางตลาด",
        name_en: "Yang Talat",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460701,
            zip_code: 46120,
            name_th: "ยางตลาด",
            name_en: "Yang Talat",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460702,
            zip_code: 46120,
            name_th: "หัวงัว",
            name_en: "Hua Ngua",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460703,
            zip_code: 46120,
            name_th: "อุ่มเม่า",
            name_en: "Um Mao",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460704,
            zip_code: 46120,
            name_th: "บัวบาน",
            name_en: "Bua Ban",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460705,
            zip_code: 46120,
            name_th: "เว่อ",
            name_en: "Woe",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460706,
            zip_code: 46120,
            name_th: "อิตื้อ",
            name_en: "Itue",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460707,
            zip_code: 46120,
            name_th: "หัวนาคำ",
            name_en: "Hua Na Kham",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460708,
            zip_code: 46120,
            name_th: "หนองอิเฒ่า",
            name_en: "Nong I Thao",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460709,
            zip_code: 46120,
            name_th: "ดอนสมบูรณ์",
            name_en: "Don Sombun",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460710,
            zip_code: 46120,
            name_th: "นาเชือก",
            name_en: "Na Chueak",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460711,
            zip_code: 46120,
            name_th: "คลองขาม",
            name_en: "Khlong Kham",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460712,
            zip_code: 46120,
            name_th: "เขาพระนอน",
            name_en: "Khao Phra Non",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460713,
            zip_code: 46120,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460714,
            zip_code: 46120,
            name_th: "โนนสูง",
            name_en: "Non Sung",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460715,
            zip_code: 46120,
            name_th: "หนองตอกแป้น",
            name_en: "Nong Tok Paen",
            amphure_id: 4607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4608,
        name_th: "ห้วยเม็ก",
        name_en: "Huai Mek",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460801,
            zip_code: 46170,
            name_th: "ห้วยเม็ก",
            name_en: "Huai Mek",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460802,
            zip_code: 46170,
            name_th: "คำใหญ่",
            name_en: "Kham Yai",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460803,
            zip_code: 46170,
            name_th: "กุดโดน",
            name_en: "Kut Don",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460804,
            zip_code: 46170,
            name_th: "บึงนาเรียง",
            name_en: "Bueng Na Riang",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460805,
            zip_code: 46170,
            name_th: "หัวหิน",
            name_en: "Hua Hin",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460806,
            zip_code: 46170,
            name_th: "พิมูล",
            name_en: "Phimun",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460807,
            zip_code: 46170,
            name_th: "คำเหมือดแก้ว",
            name_en: "Kham Mueat Kaeo",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460808,
            zip_code: 46170,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460809,
            zip_code: 46170,
            name_th: "ทรายทอง",
            name_en: "Sai Thong",
            amphure_id: 4608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4609,
        name_th: "สหัสขันธ์",
        name_en: "Sahatsakhan",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 460901,
            zip_code: 46140,
            name_th: "ภูสิงห์",
            name_en: "Phu Sing",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460902,
            zip_code: 46140,
            name_th: "สหัสขันธ์",
            name_en: "Sahatsakhan",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460903,
            zip_code: 46140,
            name_th: "นามะเขือ",
            name_en: "Na Makhuea",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460904,
            zip_code: 46140,
            name_th: "โนนศิลา",
            name_en: "Non Sila",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460905,
            zip_code: 46140,
            name_th: "นิคม",
            name_en: "Nikhom",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460906,
            zip_code: 46140,
            name_th: "โนนแหลมทอง",
            name_en: "Non Laem Thong",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460907,
            zip_code: 46140,
            name_th: "โนนบุรี",
            name_en: "Non Buri",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 460908,
            zip_code: 46140,
            name_th: "โนนน้ำเกลี้ยง",
            name_en: "Non Nam Kliang",
            amphure_id: 4609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4610,
        name_th: "คำม่วง",
        name_en: "Kham Muang",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461001,
            zip_code: 46180,
            name_th: "ทุ่งคลอง",
            name_en: "Thung Khlong",
            amphure_id: 4610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461002,
            zip_code: 46180,
            name_th: "โพน",
            name_en: "Phon",
            amphure_id: 4610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461005,
            zip_code: 46180,
            name_th: "ดินจี่",
            name_en: "Din Chi",
            amphure_id: 4610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461006,
            zip_code: 46180,
            name_th: "นาบอน",
            name_en: "Na Bon",
            amphure_id: 4610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461007,
            zip_code: 46180,
            name_th: "นาทัน",
            name_en: "Na Than",
            amphure_id: 4610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461009,
            zip_code: 46180,
            name_th: "เนินยาง",
            name_en: "Noen Yang",
            amphure_id: 4610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4611,
        name_th: "ท่าคันโท",
        name_en: "Tha Khantho",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461101,
            zip_code: 46190,
            name_th: "ท่าคันโท",
            name_en: "Tha Khantho",
            amphure_id: 4611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461102,
            zip_code: 46190,
            name_th: "กุงเก่า",
            name_en: "Kung Kao",
            amphure_id: 4611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461103,
            zip_code: 46190,
            name_th: "ยางอู้ม",
            name_en: "Yang Um",
            amphure_id: 4611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461104,
            zip_code: 46190,
            name_th: "กุดจิก",
            name_en: "Kut Chik",
            amphure_id: 4611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461105,
            zip_code: 46190,
            name_th: "นาตาล",
            name_en: "Na Tan",
            amphure_id: 4611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461106,
            zip_code: 46190,
            name_th: "ดงสมบูรณ์",
            name_en: "Dong Sombun",
            amphure_id: 4611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4612,
        name_th: "หนองกุงศรี",
        name_en: "Nong Kung Si",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461201,
            zip_code: 46220,
            name_th: "หนองกุงศรี",
            name_en: "Nong Kung Si",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461202,
            zip_code: 46220,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461203,
            zip_code: 46220,
            name_th: "โคกเครือ",
            name_en: "Khok Khruea",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461204,
            zip_code: 46220,
            name_th: "หนองสรวง",
            name_en: "Nong Suang",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461205,
            zip_code: 46220,
            name_th: "เสาเล้า",
            name_en: "Sao Lao",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461206,
            zip_code: 46220,
            name_th: "หนองใหญ่",
            name_en: "Nong Yai",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461207,
            zip_code: 46220,
            name_th: "ดงมูล",
            name_en: "Dong Mun",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461208,
            zip_code: 46220,
            name_th: "ลำหนองแสน",
            name_en: "Lam Nong Saen",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461209,
            zip_code: 46220,
            name_th: "หนองหิน",
            name_en: "Nong Hin",
            amphure_id: 4612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4613,
        name_th: "สมเด็จ",
        name_en: "Somdet",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461301,
            zip_code: 46150,
            name_th: "สมเด็จ",
            name_en: "Somdet",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461302,
            zip_code: 46150,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461303,
            zip_code: 46150,
            name_th: "แซงบาดาล",
            name_en: "Saeng Badan",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461304,
            zip_code: 46150,
            name_th: "มหาไชย",
            name_en: "Maha Chai",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461305,
            zip_code: 46150,
            name_th: "หมูม่น",
            name_en: "Mu Mon",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461306,
            zip_code: 46150,
            name_th: "ผาเสวย",
            name_en: "Pha Sawoei",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461307,
            zip_code: 46150,
            name_th: "ศรีสมเด็จ",
            name_en: "Si Somdet",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461308,
            zip_code: 46150,
            name_th: "ลำห้วยหลัว",
            name_en: "Lam Huai Lua",
            amphure_id: 4613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4614,
        name_th: "ห้วยผึ้ง",
        name_en: "Huai Phueng",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461401,
            zip_code: 46240,
            name_th: "คำบง",
            name_en: "Kham Bong",
            amphure_id: 4614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461402,
            zip_code: 46240,
            name_th: "ไค้นุ่น",
            name_en: "Khai Nun",
            amphure_id: 4614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461403,
            zip_code: 46240,
            name_th: "นิคมห้วยผึ้ง",
            name_en: "Nikhom Huai Phueng",
            amphure_id: 4614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461404,
            zip_code: 46240,
            name_th: "หนองอีบุตร",
            name_en: "Nong I But",
            amphure_id: 4614,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4615,
        name_th: "สามชัย",
        name_en: "Sam Chai",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461501,
            zip_code: 46180,
            name_th: "สำราญ",
            name_en: "Samran",
            amphure_id: 4615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461502,
            zip_code: 46180,
            name_th: "สำราญใต้",
            name_en: "Samran Tai",
            amphure_id: 4615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461503,
            zip_code: 46180,
            name_th: "คำสร้างเที่ยง",
            name_en: "Kham Sang Thiang",
            amphure_id: 4615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461504,
            zip_code: 46180,
            name_th: "หนองช้าง",
            name_en: "Nong Chang",
            amphure_id: 4615,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4616,
        name_th: "นาคู",
        name_en: "Na Khu",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461601,
            zip_code: 46160,
            name_th: "นาคู",
            name_en: "Na Khu",
            amphure_id: 4616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461602,
            zip_code: 46160,
            name_th: "สายนาวัง",
            name_en: "Sai Na Wang",
            amphure_id: 4616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461603,
            zip_code: 46160,
            name_th: "โนนนาจาน",
            name_en: "Non Na Chan",
            amphure_id: 4616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461604,
            zip_code: 46160,
            name_th: "บ่อแก้ว",
            name_en: "Bo Kaeo",
            amphure_id: 4616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461605,
            zip_code: 46160,
            name_th: "ภูแล่นช้าง",
            name_en: "Phu Laen Chang",
            amphure_id: 4616,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4617,
        name_th: "ดอนจาน",
        name_en: "Don Chan",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461701,
            zip_code: 46000,
            name_th: "ดอนจาน",
            name_en: "Don Chan",
            amphure_id: 4617,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461702,
            zip_code: 46000,
            name_th: "สะอาดไชยศรี",
            name_en: "Sa-at Chai Si",
            amphure_id: 4617,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461703,
            zip_code: 46000,
            name_th: "ดงพยุง",
            name_en: "Dong Phayung",
            amphure_id: 4617,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461704,
            zip_code: 46000,
            name_th: "ม่วงนา",
            name_en: "Muang Na",
            amphure_id: 4617,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461705,
            zip_code: 46000,
            name_th: "นาจำปา",
            name_en: "Na Champa",
            amphure_id: 4617,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4618,
        name_th: "ฆ้องชัย",
        name_en: "Khong Chai",
        province_id: 34,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 461801,
            zip_code: 46130,
            name_th: "ฆ้องชัยพัฒนา",
            name_en: "Khong Chai Phatthana",
            amphure_id: 4618,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461802,
            zip_code: 46130,
            name_th: "เหล่ากลาง",
            name_en: "Lao Klang",
            amphure_id: 4618,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461803,
            zip_code: 46130,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 4618,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461804,
            zip_code: 46130,
            name_th: "โนนศิลาเลิง",
            name_en: "Non Sila Loeng",
            amphure_id: 4618,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 461805,
            zip_code: 46130,
            name_th: "ลำชี",
            name_en: "Lam Chi",
            amphure_id: 4618,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 35,
    name_th: "สกลนคร",
    name_en: "Sakon Nakhon",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4701,
        name_th: "เมืองสกลนคร",
        name_en: "Mueang Sakon Nakhon",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470101,
            zip_code: 47000,
            name_th: "ธาตุเชิงชุม",
            name_en: "That Choeng Chum",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470102,
            zip_code: 47220,
            name_th: "ขมิ้น",
            name_en: "Khamin",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470103,
            zip_code: 47000,
            name_th: "งิ้วด่อน",
            name_en: "Ngio Don",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470104,
            zip_code: 47000,
            name_th: "โนนหอม",
            name_en: "Non Hom",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470106,
            zip_code: 47000,
            name_th: "เชียงเครือ",
            name_en: "Chiang Khruea",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470107,
            zip_code: 47000,
            name_th: "ท่าแร่",
            name_en: "Tha Rae",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470109,
            zip_code: 47000,
            name_th: "ม่วงลาย",
            name_en: "Muang Lai",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470111,
            zip_code: 47000,
            name_th: "ดงชน",
            name_en: "Dong Chon",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470112,
            zip_code: 47000,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470113,
            zip_code: 47000,
            name_th: "พังขว้าง",
            name_en: "Phang Khwang",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470115,
            zip_code: 47000,
            name_th: "ดงมะไฟ",
            name_en: "Dong Mafai",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470116,
            zip_code: 47000,
            name_th: "ธาตุนาเวง",
            name_en: "That Na Weng",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470117,
            zip_code: 47000,
            name_th: "เหล่าปอแดง",
            name_en: "Lao Po Daeng",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470118,
            zip_code: 47220,
            name_th: "หนองลาด",
            name_en: "Nong Lat",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470120,
            zip_code: 47000,
            name_th: "ฮางโฮง",
            name_en: "Hang Hong",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470121,
            zip_code: 47000,
            name_th: "โคกก่อง",
            name_en: "Khok Kong",
            amphure_id: 4701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4702,
        name_th: "กุสุมาลย์",
        name_en: "Kusuman",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470201,
            zip_code: 47210,
            name_th: "กุสุมาลย์",
            name_en: "Kusuman",
            amphure_id: 4702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470202,
            zip_code: 47210,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 4702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470203,
            zip_code: 47230,
            name_th: "นาเพียง",
            name_en: "Na Phiang",
            amphure_id: 4702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470204,
            zip_code: 47210,
            name_th: "โพธิไพศาล",
            name_en: "Phothi Phaisan",
            amphure_id: 4702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470205,
            zip_code: 47230,
            name_th: "อุ่มจาน",
            name_en: "Um Chan",
            amphure_id: 4702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4703,
        name_th: "กุดบาก",
        name_en: "Kut Bak",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470301,
            zip_code: 47180,
            name_th: "กุดบาก",
            name_en: "Kut Bak",
            amphure_id: 4703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470303,
            zip_code: 47180,
            name_th: "นาม่อง",
            name_en: "Na Mong",
            amphure_id: 4703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470305,
            zip_code: 47180,
            name_th: "กุดไห",
            name_en: "Kut Hai",
            amphure_id: 4703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4704,
        name_th: "พรรณานิคม",
        name_en: "Phanna Nikhom",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470401,
            zip_code: 47130,
            name_th: "พรรณา",
            name_en: "Phanna",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470402,
            zip_code: 47130,
            name_th: "วังยาง",
            name_en: "Wang Yang",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470403,
            zip_code: 47220,
            name_th: "พอกน้อย",
            name_en: "Phok Noi",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470404,
            zip_code: 47220,
            name_th: "นาหัวบ่อ",
            name_en: "Na Hua Bo",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470405,
            zip_code: 47130,
            name_th: "ไร่",
            name_en: "Rai",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470406,
            zip_code: 47130,
            name_th: "ช้างมิ่ง",
            name_en: "Chang Ming",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470407,
            zip_code: 47130,
            name_th: "นาใน",
            name_en: "Na Nai",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470408,
            zip_code: 47130,
            name_th: "สว่าง",
            name_en: "Sawang",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470409,
            zip_code: 47130,
            name_th: "บะฮี",
            name_en: "Ba Hi",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470410,
            zip_code: 47130,
            name_th: "เชิงชุม",
            name_en: "Choeng Chum",
            amphure_id: 4704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4705,
        name_th: "พังโคน",
        name_en: "Phang Khon",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470501,
            zip_code: 47160,
            name_th: "พังโคน",
            name_en: "Phang Khon",
            amphure_id: 4705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470502,
            zip_code: 47160,
            name_th: "ม่วงไข่",
            name_en: "Muang Khai",
            amphure_id: 4705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470503,
            zip_code: 47160,
            name_th: "แร่",
            name_en: "Rae",
            amphure_id: 4705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470504,
            zip_code: 47160,
            name_th: "ไฮหย่อง",
            name_en: "Hai Yong",
            amphure_id: 4705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470505,
            zip_code: 47160,
            name_th: "ต้นผึ้ง",
            name_en: "Ton Phueng",
            amphure_id: 4705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4706,
        name_th: "วาริชภูมิ",
        name_en: "Waritchaphum",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470601,
            zip_code: 47150,
            name_th: "วาริชภูมิ",
            name_en: "Waritchaphum",
            amphure_id: 4706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470602,
            zip_code: 47150,
            name_th: "ปลาโหล",
            name_en: "Pla Lo",
            amphure_id: 4706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470603,
            zip_code: 47150,
            name_th: "หนองลาด",
            name_en: "Nong Lat",
            amphure_id: 4706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470604,
            zip_code: 47150,
            name_th: "คำบ่อ",
            name_en: "Kham Bo",
            amphure_id: 4706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470605,
            zip_code: 47150,
            name_th: "ค้อเขียว",
            name_en: "Kho Khiao",
            amphure_id: 4706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4707,
        name_th: "นิคมน้ำอูน",
        name_en: "Nikhom Nam Un",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470701,
            zip_code: 47270,
            name_th: "นิคมน้ำอูน",
            name_en: "Nikhom Nam Un",
            amphure_id: 4707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470702,
            zip_code: 47270,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 4707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470703,
            zip_code: 47270,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470704,
            zip_code: 47270,
            name_th: "สุวรรณคาม",
            name_en: "*Suwannakarm",
            amphure_id: 4707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4708,
        name_th: "วานรนิวาส",
        name_en: "Wanon Niwat",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470801,
            zip_code: 47120,
            name_th: "วานรนิวาส",
            name_en: "Wanon Niwat",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470802,
            zip_code: 47120,
            name_th: "เดื่อศรีคันไชย",
            name_en: "Duea Si Khan Chai",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470803,
            zip_code: 47120,
            name_th: "ขัวก่าย",
            name_en: "Khua Kai",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470804,
            zip_code: 47120,
            name_th: "หนองสนม",
            name_en: "Nong Sanom",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470805,
            zip_code: 47120,
            name_th: "คูสะคาม",
            name_en: "Khu Sakham",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470806,
            zip_code: 47120,
            name_th: "ธาตุ",
            name_en: "That",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470807,
            zip_code: 47120,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470808,
            zip_code: 47120,
            name_th: "ศรีวิชัย",
            name_en: "Si Wichai",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470809,
            zip_code: 47120,
            name_th: "นาซอ",
            name_en: "Na So",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470810,
            zip_code: 47120,
            name_th: "อินทร์แปลง",
            name_en: "In Plaeng",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470811,
            zip_code: 47120,
            name_th: "นาคำ",
            name_en: "Na Kham",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470812,
            zip_code: 47120,
            name_th: "คอนสวรรค์",
            name_en: "Khon Sawan",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470813,
            zip_code: 47120,
            name_th: "กุดเรือคำ",
            name_en: "Kut Ruea Kham",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470814,
            zip_code: 47120,
            name_th: "หนองแวงใต้",
            name_en: "Nong Waeng Tai",
            amphure_id: 4708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4709,
        name_th: "คำตากล้า",
        name_en: "Kham Ta Kla",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 470901,
            zip_code: 47250,
            name_th: "คำตากล้า",
            name_en: "Kham Ta Kla",
            amphure_id: 4709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470902,
            zip_code: 47250,
            name_th: "หนองบัวสิม",
            name_en: "Nong Bua Sim",
            amphure_id: 4709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470903,
            zip_code: 47250,
            name_th: "นาแต้",
            name_en: "Na Tae",
            amphure_id: 4709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 470904,
            zip_code: 47250,
            name_th: "แพด",
            name_en: "Phaet",
            amphure_id: 4709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4710,
        name_th: "บ้านม่วง",
        name_en: "Ban Muang",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471001,
            zip_code: 47140,
            name_th: "ม่วง",
            name_en: "Muang",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471002,
            zip_code: 47140,
            name_th: "มาย",
            name_en: "Mai",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471003,
            zip_code: 47140,
            name_th: "ดงหม้อทอง",
            name_en: "Dong Mo Thong",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471004,
            zip_code: 47140,
            name_th: "ดงเหนือ",
            name_en: "Dong Nuea",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471005,
            zip_code: 47140,
            name_th: "ดงหม้อทองใต้",
            name_en: "Dong Mo Thong Tai",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471006,
            zip_code: 47140,
            name_th: "ห้วยหลัว",
            name_en: "Huai Lua",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471007,
            zip_code: 47140,
            name_th: "โนนสะอาด",
            name_en: "Non Sa-at",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471008,
            zip_code: 47140,
            name_th: "หนองกวั่ง",
            name_en: "Nong Kwang",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471009,
            zip_code: 47140,
            name_th: "บ่อแก้ว",
            name_en: "Bo Kaeo",
            amphure_id: 4710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4711,
        name_th: "อากาศอำนวย",
        name_en: "Akat Amnuai",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471101,
            zip_code: 47170,
            name_th: "อากาศ",
            name_en: "Akat",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471102,
            zip_code: 47170,
            name_th: "โพนแพง",
            name_en: "Phon Phaeng",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471103,
            zip_code: 47170,
            name_th: "วาใหญ่",
            name_en: "Wa Yai",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471104,
            zip_code: 47170,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471105,
            zip_code: 47170,
            name_th: "ท่าก้อน",
            name_en: "Tha Kon",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471106,
            zip_code: 47170,
            name_th: "นาฮี",
            name_en: "Na Hi",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471107,
            zip_code: 47170,
            name_th: "บะหว้า",
            name_en: "Ba Wa",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471108,
            zip_code: 47170,
            name_th: "สามัคคีพัฒนา",
            name_en: "Samakkhi Phatthana",
            amphure_id: 4711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4712,
        name_th: "สว่างแดนดิน",
        name_en: "Sawang Daen Din",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471201,
            zip_code: 47110,
            name_th: "สว่างแดนดิน",
            name_en: "Sawang Daen Din",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471203,
            zip_code: 47110,
            name_th: "คำสะอาด",
            name_en: "Kham Sa-at",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471204,
            zip_code: 47110,
            name_th: "บ้านต้าย",
            name_en: "Ban Tai",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471206,
            zip_code: 47110,
            name_th: "บงเหนือ",
            name_en: "Bong Nuea",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471207,
            zip_code: 47110,
            name_th: "โพนสูง",
            name_en: "Phon Sung",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471208,
            zip_code: 47110,
            name_th: "โคกสี",
            name_en: "Khok Si",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471210,
            zip_code: 47110,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471211,
            zip_code: 47110,
            name_th: "บงใต้",
            name_en: "Bong Tai",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471212,
            zip_code: 47110,
            name_th: "ค้อใต้",
            name_en: "Kho Tai",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471213,
            zip_code: 47240,
            name_th: "พันนา",
            name_en: "Phan Na",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471214,
            zip_code: 47240,
            name_th: "แวง",
            name_en: "Waeng",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471215,
            zip_code: 47110,
            name_th: "ทรายมูล",
            name_en: "Sai Mun",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471216,
            zip_code: 47240,
            name_th: "ตาลโกน",
            name_en: "Tan Kon",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471217,
            zip_code: 47240,
            name_th: "ตาลเนิ้ง",
            name_en: "Tan Noeng",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471220,
            zip_code: 47240,
            name_th: "ธาตุทอง",
            name_en: "That Thong",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471221,
            zip_code: 47110,
            name_th: "บ้านถ่อน",
            name_en: "Ban Thon",
            amphure_id: 4712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4713,
        name_th: "ส่องดาว",
        name_en: "Song Dao",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471301,
            zip_code: 47190,
            name_th: "ส่องดาว",
            name_en: "Song Dao",
            amphure_id: 4713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471302,
            zip_code: 47190,
            name_th: "ท่าศิลา",
            name_en: "Tha Sila",
            amphure_id: 4713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471303,
            zip_code: 47190,
            name_th: "วัฒนา",
            name_en: "Watthana",
            amphure_id: 4713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471304,
            zip_code: 47190,
            name_th: "ปทุมวาปี",
            name_en: "Pathum Wapi",
            amphure_id: 4713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4714,
        name_th: "เต่างอย",
        name_en: "Tao Ngoi",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471401,
            zip_code: 47260,
            name_th: "เต่างอย",
            name_en: "Tao Ngoi",
            amphure_id: 4714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471402,
            zip_code: 47260,
            name_th: "บึงทวาย",
            name_en: "Bueng Thawai",
            amphure_id: 4714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471403,
            zip_code: 47260,
            name_th: "นาตาล",
            name_en: "Na Tan",
            amphure_id: 4714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471404,
            zip_code: 47260,
            name_th: "จันทร์เพ็ญ",
            name_en: "Chan Phen",
            amphure_id: 4714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4715,
        name_th: "โคกศรีสุพรรณ",
        name_en: "Khok Si Suphan",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471501,
            zip_code: 47280,
            name_th: "ตองโขบ",
            name_en: "Tong Khop",
            amphure_id: 4715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471502,
            zip_code: 47280,
            name_th: "เหล่าโพนค้อ",
            name_en: "Lao Phon Kho",
            amphure_id: 4715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471503,
            zip_code: 47280,
            name_th: "ด่านม่วงคำ",
            name_en: "Dan Muang Kham",
            amphure_id: 4715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471504,
            zip_code: 47280,
            name_th: "แมดนาท่ม",
            name_en: "Maet Na Thom",
            amphure_id: 4715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4716,
        name_th: "เจริญศิลป์",
        name_en: "Charoen Sin",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471601,
            zip_code: 47290,
            name_th: "บ้านเหล่า",
            name_en: "Ban Lao",
            amphure_id: 4716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471602,
            zip_code: 47290,
            name_th: "เจริญศิลป์",
            name_en: "Charoen Sin",
            amphure_id: 4716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471603,
            zip_code: 47290,
            name_th: "ทุ่งแก",
            name_en: "Thung Kae",
            amphure_id: 4716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471604,
            zip_code: 47290,
            name_th: "โคกศิลา",
            name_en: "Khok Sila",
            amphure_id: 4716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471605,
            zip_code: 47290,
            name_th: "หนองแปน",
            name_en: "Nong Paen",
            amphure_id: 4716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4717,
        name_th: "โพนนาแก้ว",
        name_en: "Phon Na Kaeo",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471701,
            zip_code: 47230,
            name_th: "บ้านโพน",
            name_en: "Ban Phon",
            amphure_id: 4717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471702,
            zip_code: 47230,
            name_th: "นาแก้ว",
            name_en: "Na Kaeo",
            amphure_id: 4717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471703,
            zip_code: 47230,
            name_th: "นาตงวัฒนา",
            name_en: "Na Tong Watthana",
            amphure_id: 4717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471704,
            zip_code: 47230,
            name_th: "บ้านแป้น",
            name_en: "Ban Paen",
            amphure_id: 4717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471705,
            zip_code: 47230,
            name_th: "เชียงสือ",
            name_en: "Chiang Sue",
            amphure_id: 4717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4718,
        name_th: "ภูพาน",
        name_en: "Phu Phan",
        province_id: 35,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 471801,
            zip_code: 47180,
            name_th: "สร้างค้อ",
            name_en: "Sang Kho",
            amphure_id: 4718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471802,
            zip_code: 47180,
            name_th: "หลุบเลา",
            name_en: "Lup Lao",
            amphure_id: 4718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471803,
            zip_code: 47180,
            name_th: "โคกภู",
            name_en: "Khok Phu",
            amphure_id: 4718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 471804,
            zip_code: 47180,
            name_th: "กกปลาซิว",
            name_en: "Kok Pla Sio",
            amphure_id: 4718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 36,
    name_th: "นครพนม",
    name_en: "Nakhon Phanom",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4801,
        name_th: "เมืองนครพนม",
        name_en: "Mueang Nakhon Phanom",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480101,
            zip_code: 48000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480102,
            zip_code: 48000,
            name_th: "หนองแสง",
            name_en: "Nong Saeng",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480103,
            zip_code: 48000,
            name_th: "นาทราย",
            name_en: "Na Sai",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480104,
            zip_code: 48000,
            name_th: "นาราชควาย",
            name_en: "Na Rat Khwai",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480105,
            zip_code: 48000,
            name_th: "กุรุคุ",
            name_en: "Kurukhu",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480106,
            zip_code: 48000,
            name_th: "บ้านผึ้ง",
            name_en: "Ban Phueng",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480107,
            zip_code: 48000,
            name_th: "อาจสามารถ",
            name_en: "At Samat",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480108,
            zip_code: 48000,
            name_th: "ขามเฒ่า",
            name_en: "Kham Thao",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480109,
            zip_code: 48000,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480110,
            zip_code: 48000,
            name_th: "ท่าค้อ",
            name_en: "Tha Kho",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480111,
            zip_code: 48000,
            name_th: "คำเตย",
            name_en: "Kham Toei",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480112,
            zip_code: 48000,
            name_th: "หนองญาติ",
            name_en: "Nong Yat",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480113,
            zip_code: 48000,
            name_th: "ดงขวาง",
            name_en: "Dong Khwang",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480114,
            zip_code: 48000,
            name_th: "วังตามัว",
            name_en: "Wang Ta Mua",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480115,
            zip_code: 48000,
            name_th: "โพธิ์ตาก",
            name_en: "Pho Tak",
            amphure_id: 4801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4802,
        name_th: "ปลาปาก",
        name_en: "Pla Pak",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480201,
            zip_code: 48160,
            name_th: "ปลาปาก",
            name_en: "Pla Pak",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480202,
            zip_code: 48160,
            name_th: "หนองฮี",
            name_en: "Nong Hi",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480203,
            zip_code: 48160,
            name_th: "กุตาไก้",
            name_en: "Kutakai",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480204,
            zip_code: 48160,
            name_th: "โคกสว่าง",
            name_en: "Khok Sawan",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480205,
            zip_code: 48160,
            name_th: "โคกสูง",
            name_en: "Khok Sung",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480206,
            zip_code: 48160,
            name_th: "มหาชัย",
            name_en: "Maha Chai",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480207,
            zip_code: 48160,
            name_th: "นามะเขือ",
            name_en: "Na Makhuea",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480208,
            zip_code: 48160,
            name_th: "หนองเทาใหญ่",
            name_en: "Nong Thao Yai",
            amphure_id: 4802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4803,
        name_th: "ท่าอุเทน",
        name_en: "Tha Uthen",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480301,
            zip_code: 48120,
            name_th: "ท่าอุเทน",
            name_en: "Tha Uthen",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480302,
            zip_code: 48120,
            name_th: "โนนตาล",
            name_en: "Non Tan",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480303,
            zip_code: 48120,
            name_th: "ท่าจำปา",
            name_en: "Tha Champa",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480304,
            zip_code: 48120,
            name_th: "ไชยบุรี",
            name_en: "Chai Buri",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480305,
            zip_code: 48120,
            name_th: "พนอม",
            name_en: "Phanom",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480306,
            zip_code: 48120,
            name_th: "พะทาย",
            name_en: "Phathai",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480311,
            zip_code: 48120,
            name_th: "เวินพระบาท",
            name_en: "Woen Phra Bat",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480312,
            zip_code: 48120,
            name_th: "รามราช",
            name_en: "Ram Rat",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480314,
            zip_code: 48120,
            name_th: "หนองเทา",
            name_en: "Nong Thao",
            amphure_id: 4803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4804,
        name_th: "บ้านแพง",
        name_en: "Ban Phaeng",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480401,
            zip_code: 48140,
            name_th: "บ้านแพง",
            name_en: "Ban Phaeng",
            amphure_id: 4804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480402,
            zip_code: 48140,
            name_th: "ไผ่ล้อม",
            name_en: "Phai Lom",
            amphure_id: 4804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480403,
            zip_code: 48140,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 4804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480404,
            zip_code: 48140,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480408,
            zip_code: 48140,
            name_th: "นางัว",
            name_en: "Na Ngua",
            amphure_id: 4804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480409,
            zip_code: 48140,
            name_th: "นาเข",
            name_en: "Na Khe",
            amphure_id: 4804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4805,
        name_th: "ธาตุพนม",
        name_en: "That Phanom",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480501,
            zip_code: 48110,
            name_th: "ธาตุพนม",
            name_en: "That Phanom",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480502,
            zip_code: 48110,
            name_th: "ฝั่งแดง",
            name_en: "Fang Daeng",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480503,
            zip_code: 48110,
            name_th: "โพนแพง",
            name_en: "Phon Phaeng",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480504,
            zip_code: 48110,
            name_th: "พระกลางทุ่ง",
            name_en: "Phra Klang Thung",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480505,
            zip_code: 48110,
            name_th: "นาถ่อน",
            name_en: "Na Thon",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480506,
            zip_code: 48110,
            name_th: "แสนพัน",
            name_en: "Saen Phan",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480507,
            zip_code: 48110,
            name_th: "ดอนนางหงส์",
            name_en: "Don Nang Hong",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480508,
            zip_code: 48110,
            name_th: "น้ำก่ำ",
            name_en: "Nam Kam",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480509,
            zip_code: 48110,
            name_th: "อุ่มเหม้า",
            name_en: "Um Mao",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480510,
            zip_code: 48110,
            name_th: "นาหนาด",
            name_en: "Na Nat",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480511,
            zip_code: 48110,
            name_th: "กุดฉิม",
            name_en: "Kut Chim",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480512,
            zip_code: 48110,
            name_th: "ธาตุพนมเหนือ",
            name_en: "That Phanom Nuea",
            amphure_id: 4805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4806,
        name_th: "เรณูนคร",
        name_en: "Renu Nakhon",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480601,
            zip_code: 48170,
            name_th: "เรณู",
            name_en: "Renu",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480602,
            zip_code: 48170,
            name_th: "โพนทอง",
            name_en: "Phon Thong",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480603,
            zip_code: 48170,
            name_th: "ท่าลาด",
            name_en: "Tha Lat",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480604,
            zip_code: 48170,
            name_th: "นางาม",
            name_en: "Na Ngam",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480605,
            zip_code: 48170,
            name_th: "โคกหินแฮ่",
            name_en: "Khok Hin Hae",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480607,
            zip_code: 48170,
            name_th: "หนองย่างชิ้น",
            name_en: "Nong Yang Chin",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480608,
            zip_code: 48170,
            name_th: "เรณูใต้",
            name_en: "Renu Tai",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480609,
            zip_code: 48170,
            name_th: "นาขาม",
            name_en: "Na Kham",
            amphure_id: 4806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4807,
        name_th: "นาแก",
        name_en: "Na Kae",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480701,
            zip_code: 48130,
            name_th: "นาแก",
            name_en: "Na Kae",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480702,
            zip_code: 48130,
            name_th: "พระซอง",
            name_en: "Phra Song",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480703,
            zip_code: 48130,
            name_th: "หนองสังข์",
            name_en: "Nong Sang",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480704,
            zip_code: 48130,
            name_th: "นาคู่",
            name_en: "Na Khu",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480705,
            zip_code: 48130,
            name_th: "พิมาน",
            name_en: "Phiman",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480706,
            zip_code: 48130,
            name_th: "พุ่มแก",
            name_en: "Phum Kae",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480707,
            zip_code: 48130,
            name_th: "ก้านเหลือง",
            name_en: "Kan Lueang",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480708,
            zip_code: 48130,
            name_th: "หนองบ่อ",
            name_en: "Nong Bo",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480709,
            zip_code: 48130,
            name_th: "นาเลียง",
            name_en: "Na Liang",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480712,
            zip_code: 48130,
            name_th: "บ้านแก้ง",
            name_en: "Ban Kaeng",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480713,
            zip_code: 48130,
            name_th: "คำพี้",
            name_en: "Kham Phi",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480715,
            zip_code: 48130,
            name_th: "สีชมพู",
            name_en: "Si Chomphu",
            amphure_id: 4807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4808,
        name_th: "ศรีสงคราม",
        name_en: "Si Songkhram",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480801,
            zip_code: 48150,
            name_th: "ศรีสงคราม",
            name_en: "Si Songkhram",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480802,
            zip_code: 48150,
            name_th: "นาเดื่อ",
            name_en: "Na Duea",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480803,
            zip_code: 48150,
            name_th: "บ้านเอื้อง",
            name_en: "Ban Ueang",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480804,
            zip_code: 48150,
            name_th: "สามผง",
            name_en: "Sam Phong",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480805,
            zip_code: 48150,
            name_th: "ท่าบ่อสงคราม",
            name_en: "Tha Bo Songkhram",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480806,
            zip_code: 48150,
            name_th: "บ้านข่า",
            name_en: "Ban Kha",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480807,
            zip_code: 48150,
            name_th: "นาคำ",
            name_en: "Na Kham",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480808,
            zip_code: 48150,
            name_th: "โพนสว่าง",
            name_en: "Phon Sawang",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480809,
            zip_code: 48150,
            name_th: "หาดแพง",
            name_en: "Hat Phaeng",
            amphure_id: 4808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4809,
        name_th: "นาหว้า",
        name_en: "Na Wa",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 480901,
            zip_code: 48180,
            name_th: "นาหว้า",
            name_en: "Na Wa",
            amphure_id: 4809,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480902,
            zip_code: 48180,
            name_th: "นางัว",
            name_en: "Na Ngua",
            amphure_id: 4809,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480903,
            zip_code: 48180,
            name_th: "บ้านเสียว",
            name_en: "Ban Siao",
            amphure_id: 4809,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480904,
            zip_code: 48180,
            name_th: "นาคูณใหญ่",
            name_en: "Na Khun Yai",
            amphure_id: 4809,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480905,
            zip_code: 48180,
            name_th: "เหล่าพัฒนา",
            name_en: "Lao Phatthana",
            amphure_id: 4809,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 480906,
            zip_code: 48180,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 4809,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4810,
        name_th: "โพนสวรรค์",
        name_en: "Phon Sawan",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 481001,
            zip_code: 48190,
            name_th: "โพนสวรรค์",
            name_en: "Phon Sawan",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481002,
            zip_code: 48190,
            name_th: "นาหัวบ่อ",
            name_en: "Na Hua Bo",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481003,
            zip_code: 48190,
            name_th: "นาขมิ้น",
            name_en: "Na Khamin",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481004,
            zip_code: 48190,
            name_th: "โพนบก",
            name_en: "Phon Bok",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481005,
            zip_code: 48190,
            name_th: "บ้านค้อ",
            name_en: "Ban Kho",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481006,
            zip_code: 48190,
            name_th: "โพนจาน",
            name_en: "Phon Chan",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481007,
            zip_code: 48190,
            name_th: "นาใน",
            name_en: "Na Nai",
            amphure_id: 4810,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4811,
        name_th: "นาทม",
        name_en: "Na Thom",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 481101,
            zip_code: 48140,
            name_th: "นาทม",
            name_en: "Na Thom",
            amphure_id: 4811,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481102,
            zip_code: 48140,
            name_th: "หนองซน",
            name_en: "Nong Son",
            amphure_id: 4811,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481103,
            zip_code: 48140,
            name_th: "ดอนเตย",
            name_en: "Don Toei",
            amphure_id: 4811,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4812,
        name_th: "วังยาง",
        name_en: "Wang Yang",
        province_id: 36,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 481201,
            zip_code: 48130,
            name_th: "วังยาง",
            name_en: "Wang Yang",
            amphure_id: 4812,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481202,
            zip_code: 48130,
            name_th: "โคกสี",
            name_en: "Khok Si",
            amphure_id: 4812,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481203,
            zip_code: 48130,
            name_th: "ยอดชาด",
            name_en: "Yot Chat",
            amphure_id: 4812,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 481204,
            zip_code: 48130,
            name_th: "หนองโพธิ์",
            name_en: "Nong Pho",
            amphure_id: 4812,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 37,
    name_th: "มุกดาหาร",
    name_en: "Mukdahan",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 4901,
        name_th: "เมืองมุกดาหาร",
        name_en: "Mueang Mukdahan",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490101,
            zip_code: 49000,
            name_th: "มุกดาหาร",
            name_en: "Mukdahan",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490102,
            zip_code: 49000,
            name_th: "ศรีบุญเรือง",
            name_en: "Si Bun Rueang",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490103,
            zip_code: 49000,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490104,
            zip_code: 49000,
            name_th: "บางทรายใหญ่",
            name_en: "Bang Sai Yai",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490105,
            zip_code: 49000,
            name_th: "โพนทราย",
            name_en: "Phon Sai",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490106,
            zip_code: 49000,
            name_th: "ผึ่งแดด",
            name_en: "Phueng Daet",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490107,
            zip_code: 49000,
            name_th: "นาโสก",
            name_en: "Na Sok",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490108,
            zip_code: 49000,
            name_th: "นาสีนวน",
            name_en: "Na Si Nuan",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490109,
            zip_code: 49000,
            name_th: "คำป่าหลาย",
            name_en: "Kham Pa Lai",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490110,
            zip_code: 49000,
            name_th: "คำอาฮวน",
            name_en: "Kham Ahuan",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490111,
            zip_code: 49000,
            name_th: "ดงเย็น",
            name_en: "Dong Yen",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490112,
            zip_code: 49000,
            name_th: "ดงมอน",
            name_en: "Dong Mon",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490113,
            zip_code: 49000,
            name_th: "กุดแข้",
            name_en: "Kut Khae",
            amphure_id: 4901,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4902,
        name_th: "นิคมคำสร้อย",
        name_en: "Nikhom Kham Soi",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490201,
            zip_code: 49130,
            name_th: "นิคมคำสร้อย",
            name_en: "Nikhom Kham Soi",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490202,
            zip_code: 49130,
            name_th: "นากอก",
            name_en: "Na Kok",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490203,
            zip_code: 49130,
            name_th: "หนองแวง",
            name_en: "Nong Waeng",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490204,
            zip_code: 49130,
            name_th: "กกแดง",
            name_en: "Kok Daeng",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490205,
            zip_code: 49130,
            name_th: "นาอุดม",
            name_en: "Na Udom",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490206,
            zip_code: 49130,
            name_th: "โชคชัย",
            name_en: "Chok Chai",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490207,
            zip_code: 49130,
            name_th: "ร่มเกล้า",
            name_en: "Rom Klao",
            amphure_id: 4902,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4903,
        name_th: "ดอนตาล",
        name_en: "Don Tan",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490301,
            zip_code: 49120,
            name_th: "ดอนตาล",
            name_en: "Don Tan",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490302,
            zip_code: 49120,
            name_th: "โพธิ์ไทร",
            name_en: "Pho Sai",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490303,
            zip_code: 49120,
            name_th: "ป่าไร่",
            name_en: "Pa Rai",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490304,
            zip_code: 49120,
            name_th: "เหล่าหมี",
            name_en: "Lao Mi",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490305,
            zip_code: 49120,
            name_th: "บ้านบาก",
            name_en: "Ban Bak",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490306,
            zip_code: 49120,
            name_th: "นาสะเม็ง",
            name_en: "Na Sameng",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490307,
            zip_code: 49120,
            name_th: "บ้านแก้ง",
            name_en: "Ban Kaeng",
            amphure_id: 4903,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4904,
        name_th: "ดงหลวง",
        name_en: "Dong Luang",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490401,
            zip_code: 49140,
            name_th: "ดงหลวง",
            name_en: "Dong Luang",
            amphure_id: 4904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490402,
            zip_code: 49140,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 4904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490403,
            zip_code: 49140,
            name_th: "กกตูม",
            name_en: "Kok Tum",
            amphure_id: 4904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490404,
            zip_code: 49140,
            name_th: "หนองแคน",
            name_en: "Nong Khaen",
            amphure_id: 4904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490405,
            zip_code: 49140,
            name_th: "ชะโนดน้อย",
            name_en: "Chanot Noi",
            amphure_id: 4904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490406,
            zip_code: 49140,
            name_th: "พังแดง",
            name_en: "Phang Daeng",
            amphure_id: 4904,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4905,
        name_th: "คำชะอี",
        name_en: "Khamcha-i",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490503,
            zip_code: 49110,
            name_th: "บ้านซ่ง",
            name_en: "Ban Song",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490504,
            zip_code: 49110,
            name_th: "คำชะอี",
            name_en: "Khamcha-i",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490505,
            zip_code: 49110,
            name_th: "หนองเอี่ยน",
            name_en: "(Nong Ian",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490506,
            zip_code: 49110,
            name_th: "บ้านค้อ",
            name_en: "Ban Kho",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490507,
            zip_code: 49110,
            name_th: "บ้านเหล่า",
            name_en: "Ban Lao",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490508,
            zip_code: 49110,
            name_th: "โพนงาม",
            name_en: "Phon Ngam",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490511,
            zip_code: 49110,
            name_th: "เหล่าสร้างถ่อ",
            name_en: "Lao Sang Tho",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490512,
            zip_code: 49110,
            name_th: "คำบก",
            name_en: "Kham Bok",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490514,
            zip_code: 49110,
            name_th: "น้ำเที่ยง",
            name_en: "Nam Thiang",
            amphure_id: 4905,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4906,
        name_th: "หว้านใหญ่",
        name_en: "Wan Yai",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490601,
            zip_code: 49150,
            name_th: "หว้านใหญ่",
            name_en: "Wan Yai",
            amphure_id: 4906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490602,
            zip_code: 49150,
            name_th: "ป่งขาม",
            name_en: "Pong Kham",
            amphure_id: 4906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490603,
            zip_code: 49150,
            name_th: "บางทรายน้อย",
            name_en: "Bang Sai Noi",
            amphure_id: 4906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490604,
            zip_code: 49150,
            name_th: "ชะโนด",
            name_en: "Chanot",
            amphure_id: 4906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490605,
            zip_code: 49150,
            name_th: "ดงหมู",
            name_en: "Dong Mu",
            amphure_id: 4906,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 4907,
        name_th: "หนองสูง",
        name_en: "Nong Sung",
        province_id: 37,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 490701,
            zip_code: 49160,
            name_th: "หนองสูง",
            name_en: "Nong Sung",
            amphure_id: 4907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490702,
            zip_code: 49160,
            name_th: "โนนยาง",
            name_en: "Non Yang",
            amphure_id: 4907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490703,
            zip_code: 49160,
            name_th: "ภูวง",
            name_en: "Phu Wong",
            amphure_id: 4907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490704,
            zip_code: 49160,
            name_th: "บ้านเป้า",
            name_en: "Ban Pao",
            amphure_id: 4907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490705,
            zip_code: 49160,
            name_th: "หนองสูงใต้",
            name_en: "Nong Sung Tai",
            amphure_id: 4907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 490706,
            zip_code: 49160,
            name_th: "หนองสูงเหนือ",
            name_en: "Nong Sung Nuea",
            amphure_id: 4907,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 38,
    name_th: "เชียงใหม่",
    name_en: "Chiang Mai",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5001,
        name_th: "เมืองเชียงใหม่",
        name_en: "Mueang Chiang Mai",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500101,
            zip_code: 50200,
            name_th: "ศรีภูมิ",
            name_en: "Si Phum",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500102,
            zip_code: 50200,
            name_th: "พระสิงห์",
            name_en: "Phra Sing",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500103,
            zip_code: 50100,
            name_th: "หายยา",
            name_en: "Haiya",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500104,
            zip_code: 50300,
            name_th: "ช้างม่อย",
            name_en: "Chang Moi",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500105,
            zip_code: 50100,
            name_th: "ช้างคลาน",
            name_en: "Chang Khlan",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500106,
            zip_code: 50000,
            name_th: "วัดเกต",
            name_en: "Wat Ket",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500107,
            zip_code: 50300,
            name_th: "ช้างเผือก",
            name_en: "Chang Phueak",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500108,
            zip_code: 50200,
            name_th: "สุเทพ",
            name_en: "Suthep",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500109,
            zip_code: 50100,
            name_th: "แม่เหียะ",
            name_en: "Mae Hia",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500110,
            zip_code: 50100,
            name_th: "ป่าแดด",
            name_en: "Pa Daet",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500111,
            zip_code: 50000,
            name_th: "หนองหอย",
            name_en: "Nong Hoi",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500112,
            zip_code: 50000,
            name_th: "ท่าศาลา",
            name_en: "Tha Sala",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500113,
            zip_code: 50000,
            name_th: "หนองป่าครั่ง",
            name_en: "Nong Pa Khrang\t",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500114,
            zip_code: 50000,
            name_th: "ฟ้าฮ่าม",
            name_en: "Fa Ham",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500115,
            zip_code: 50300,
            name_th: "ป่าตัน",
            name_en: "Pa Tan",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500116,
            zip_code: 50300,
            name_th: "สันผีเสื้อ",
            name_en: "San Phi Suea",
            amphure_id: 5001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5002,
        name_th: "จอมทอง",
        name_en: "Chom Thong",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500203,
            zip_code: 50160,
            name_th: "บ้านหลวง",
            name_en: "Ban Luang",
            amphure_id: 5002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500204,
            zip_code: 50160,
            name_th: "ข่วงเปา",
            name_en: "Khuang Pao",
            amphure_id: 5002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500205,
            zip_code: 50160,
            name_th: "สบเตี๊ยะ",
            name_en: "Sop Tia",
            amphure_id: 5002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500206,
            zip_code: 50240,
            name_th: "บ้านแปะ",
            name_en: "Ban Pae",
            amphure_id: 5002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500207,
            zip_code: 50160,
            name_th: "ดอยแก้ว",
            name_en: "Doi Kaeo",
            amphure_id: 5002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500209,
            zip_code: 50240,
            name_th: "แม่สอย",
            name_en: "Mae Soi",
            amphure_id: 5002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5003,
        name_th: "แม่แจ่ม",
        name_en: "Mae Chaem",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500301,
            zip_code: 50270,
            name_th: "ช่างเคิ่ง",
            name_en: "Chang Khoeng",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500302,
            zip_code: 50270,
            name_th: "ท่าผา",
            name_en: "Tha Pha",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500303,
            zip_code: 50270,
            name_th: "บ้านทับ",
            name_en: "Ban Thap",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500304,
            zip_code: 50270,
            name_th: "แม่ศึก",
            name_en: "Mae Suek",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500305,
            zip_code: 50270,
            name_th: "แม่นาจร",
            name_en: "Mae Na Chon",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500306,
            zip_code: 58130,
            name_th: "บ้านจันทร์",
            name_en: "Ban Chan",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500307,
            zip_code: 50270,
            name_th: "ปางหินฝน",
            name_en: "Pang Hin Fon",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500308,
            zip_code: 50270,
            name_th: "กองแขก",
            name_en: "Kong Khaek",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500309,
            zip_code: 58130,
            name_th: "แม่แดด",
            name_en: "Mae Dad",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500310,
            zip_code: 58130,
            name_th: "แจ่มหลวง",
            name_en: "Chaem Luang",
            amphure_id: 5003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5004,
        name_th: "เชียงดาว",
        name_en: "Chiang Dao",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500401,
            zip_code: 50170,
            name_th: "เชียงดาว",
            name_en: "Chiang Dao",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500402,
            zip_code: 50170,
            name_th: "เมืองนะ",
            name_en: "Mueang Na",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500403,
            zip_code: 50170,
            name_th: "เมืองงาย",
            name_en: "Mueang Ngai",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500404,
            zip_code: 50170,
            name_th: "แม่นะ",
            name_en: "Mae Na",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500405,
            zip_code: 50170,
            name_th: "เมืองคอง",
            name_en: "Mueang Khong",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500406,
            zip_code: 50170,
            name_th: "ปิงโค้ง",
            name_en: "Ping Khong",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500407,
            zip_code: 50170,
            name_th: "ทุ่งข้าวพวง",
            name_en: "Thung Khao Phuang",
            amphure_id: 5004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5005,
        name_th: "ดอยสะเก็ด",
        name_en: "Doi Saket",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500501,
            zip_code: 50220,
            name_th: "เชิงดอย",
            name_en: "Choeng Doi",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500502,
            zip_code: 50220,
            name_th: "สันปูเลย",
            name_en: "San Pu Loei",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500503,
            zip_code: 50220,
            name_th: "ลวงเหนือ",
            name_en: "Luang Nuea",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500504,
            zip_code: 50220,
            name_th: "ป่าป้อง",
            name_en: "Pa Pong",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500505,
            zip_code: 50220,
            name_th: "สง่าบ้าน",
            name_en: "Sa-nga Ban",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500506,
            zip_code: 50220,
            name_th: "ป่าลาน",
            name_en: "Pa Lan",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500507,
            zip_code: 50220,
            name_th: "ตลาดขวัญ",
            name_en: "Talat Khwan",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500508,
            zip_code: 50220,
            name_th: "สำราญราษฎร์",
            name_en: "Samran Rat",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500509,
            zip_code: 50220,
            name_th: "แม่คือ",
            name_en: "Mae Khue",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500510,
            zip_code: 50220,
            name_th: "ตลาดใหญ่",
            name_en: "Talat Yai",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500511,
            zip_code: 50220,
            name_th: "แม่ฮ้อยเงิน",
            name_en: "Mae Hoi Ngoen\t",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500512,
            zip_code: 50220,
            name_th: "แม่โป่ง",
            name_en: "Mae Pong",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500513,
            zip_code: 50220,
            name_th: "ป่าเมี่ยง",
            name_en: "Pa Miang",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500514,
            zip_code: 50220,
            name_th: "เทพเสด็จ",
            name_en: "Thep Sadet",
            amphure_id: 5005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5006,
        name_th: "แม่แตง",
        name_en: "Mae Taeng",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500601,
            zip_code: 50150,
            name_th: "สันมหาพน",
            name_en: "San Maha Phon",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500602,
            zip_code: 50150,
            name_th: "แม่แตง",
            name_en: "Mae Taeng",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500603,
            zip_code: 50150,
            name_th: "ขี้เหล็ก",
            name_en: "Khilek",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500604,
            zip_code: 50150,
            name_th: "ช่อแล",
            name_en: "Cho Lae",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500605,
            zip_code: 50150,
            name_th: "แม่หอพระ",
            name_en: "Mae Ho Phra",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500606,
            zip_code: 50150,
            name_th: "สบเปิง",
            name_en: "Sop Poeng",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500607,
            zip_code: 50150,
            name_th: "บ้านเป้า",
            name_en: "Ban Pao",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500608,
            zip_code: 50330,
            name_th: "สันป่ายาง",
            name_en: "San Pa Yang",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500609,
            zip_code: 50150,
            name_th: "ป่าแป๋",
            name_en: "Pa Pae",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500610,
            zip_code: 50150,
            name_th: "เมืองก๋าย",
            name_en: "Mueang Kai",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500611,
            zip_code: 50150,
            name_th: "บ้านช้าง",
            name_en: "Ban Chang",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500612,
            zip_code: 50150,
            name_th: "กื้ดช้าง",
            name_en: "Kuet Chang",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500613,
            zip_code: 50150,
            name_th: "อินทขิล",
            name_en: "Inthakhin",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500614,
            zip_code: 50150,
            name_th: "สมก๋าย",
            name_en: "Som Kai",
            amphure_id: 5006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5007,
        name_th: "แม่ริม",
        name_en: "Mae Rim",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500701,
            zip_code: 50180,
            name_th: "ริมใต้",
            name_en: "Rim Tai",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500702,
            zip_code: 50180,
            name_th: "ริมเหนือ",
            name_en: "Rim Nuea",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500703,
            zip_code: 50180,
            name_th: "สันโป่ง",
            name_en: "San Pong",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500704,
            zip_code: 50180,
            name_th: "ขี้เหล็ก",
            name_en: "Khilek",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500705,
            zip_code: 50330,
            name_th: "สะลวง",
            name_en: "Saluang",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500706,
            zip_code: 50180,
            name_th: "ห้วยทราย",
            name_en: "Huai Sai",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500707,
            zip_code: 50180,
            name_th: "แม่แรม",
            name_en: "Mae Raem",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500708,
            zip_code: 50180,
            name_th: "โป่งแยง",
            name_en: "Pong Yaeng",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500709,
            zip_code: 50180,
            name_th: "แม่สา",
            name_en: "Mae Sa",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500710,
            zip_code: 50180,
            name_th: "ดอนแก้ว",
            name_en: "Don Kaeo",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500711,
            zip_code: 50180,
            name_th: "เหมืองแก้ว",
            name_en: "Mueang Kaeo",
            amphure_id: 5007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5008,
        name_th: "สะเมิง",
        name_en: "Samoeng",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500801,
            zip_code: 50250,
            name_th: "สะเมิงใต้",
            name_en: "Samoeng Tai",
            amphure_id: 5008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500802,
            zip_code: 50250,
            name_th: "สะเมิงเหนือ",
            name_en: "Samoeng Nuea",
            amphure_id: 5008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500803,
            zip_code: 50250,
            name_th: "แม่สาบ",
            name_en: "Mae Sap",
            amphure_id: 5008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500804,
            zip_code: 50250,
            name_th: "บ่อแก้ว",
            name_en: "Bo Kaeo",
            amphure_id: 5008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500805,
            zip_code: 50250,
            name_th: "ยั้งเมิน",
            name_en: "Yang Moen",
            amphure_id: 5008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5009,
        name_th: "ฝาง",
        name_en: "Fang",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 500901,
            zip_code: 50110,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500903,
            zip_code: 50110,
            name_th: "ม่อนปิ่น",
            name_en: "Mon Pin",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500904,
            zip_code: 50320,
            name_th: "แม่งอน",
            name_en: "Mae Ngon",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500905,
            zip_code: 50110,
            name_th: "แม่สูน",
            name_en: "Mae Sun",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500906,
            zip_code: 50110,
            name_th: "สันทราย",
            name_en: "San Sai",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500910,
            zip_code: 50110,
            name_th: "แม่คะ",
            name_en: "Mae Kha",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500911,
            zip_code: 50320,
            name_th: "แม่ข่า",
            name_en: "Mae Kha",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 500912,
            zip_code: 50110,
            name_th: "โป่งน้ำร้อน",
            name_en: "Pong Nam Ron",
            amphure_id: 5009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5010,
        name_th: "แม่อาย",
        name_en: "Mae Ai",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501001,
            zip_code: 50280,
            name_th: "แม่อาย",
            name_en: "Mae Ai",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501002,
            zip_code: 50280,
            name_th: "แม่สาว",
            name_en: "Mae Sao",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501003,
            zip_code: 50280,
            name_th: "สันต้นหมื้อ",
            name_en: "San Ton Mue",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501004,
            zip_code: 50280,
            name_th: "แม่นาวาง",
            name_en: "Mae Na Wang",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501005,
            zip_code: 50280,
            name_th: "ท่าตอน",
            name_en: "Tha Ton",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501006,
            zip_code: 50280,
            name_th: "บ้านหลวง",
            name_en: "Ban Luang",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501007,
            zip_code: 50280,
            name_th: "มะลิกา",
            name_en: "Malika",
            amphure_id: 5010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5011,
        name_th: "พร้าว",
        name_en: "Phrao",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501101,
            zip_code: 50190,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501102,
            zip_code: 50190,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501103,
            zip_code: 50190,
            name_th: "ป่าตุ้ม",
            name_en: "Pa Tum",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501104,
            zip_code: 50190,
            name_th: "ป่าไหน่",
            name_en: "Pa Nai",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501105,
            zip_code: 50190,
            name_th: "สันทราย",
            name_en: "San Sai",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501106,
            zip_code: 50190,
            name_th: "บ้านโป่ง",
            name_en: "Ban Pong",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501107,
            zip_code: 50190,
            name_th: "น้ำแพร่",
            name_en: "Nam Phrae",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501108,
            zip_code: 50190,
            name_th: "เขื่อนผาก",
            name_en: "Khuean Phak",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501109,
            zip_code: 50190,
            name_th: "แม่แวน",
            name_en: "Mae Waen",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501110,
            zip_code: 50190,
            name_th: "แม่ปั๋ง",
            name_en: "Mae Pang",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501111,
            zip_code: 50190,
            name_th: "โหล่งขอด",
            name_en: "Long Khot",
            amphure_id: 5011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5012,
        name_th: "สันป่าตอง",
        name_en: "San Pa Tong",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501201,
            zip_code: 50120,
            name_th: "ยุหว่า",
            name_en: "Yu Wa",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501202,
            zip_code: 50120,
            name_th: "สันกลาง",
            name_en: "San Klang",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501203,
            zip_code: 50120,
            name_th: "ท่าวังพร้าว",
            name_en: "Tha Wang Phrao",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501204,
            zip_code: 50120,
            name_th: "มะขามหลวง",
            name_en: "Makham Luang",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501205,
            zip_code: 50120,
            name_th: "แม่ก๊า",
            name_en: "Mae Ka",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501206,
            zip_code: 50120,
            name_th: "บ้านแม",
            name_en: "Ban Mae",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501207,
            zip_code: 50120,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501208,
            zip_code: 50120,
            name_th: "ทุ่งสะโตก",
            name_en: "Thung Satok",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501210,
            zip_code: 50120,
            name_th: "ทุ่งต้อม",
            name_en: "Thung Tom",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501214,
            zip_code: 50120,
            name_th: "น้ำบ่อหลวง",
            name_en: "Nam Bo Luang",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501215,
            zip_code: 50120,
            name_th: "มะขุนหวาน",
            name_en: "Makhun Wan",
            amphure_id: 5012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5013,
        name_th: "สันกำแพง",
        name_en: "San Kamphaeng",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501301,
            zip_code: 50130,
            name_th: "สันกำแพง",
            name_en: "San Kamphaeng",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501302,
            zip_code: 50130,
            name_th: "ทรายมูล",
            name_en: "Sai Mun",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501303,
            zip_code: 50130,
            name_th: "ร้องวัวแดง",
            name_en: "Rong Wua Daeng",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501304,
            zip_code: 50130,
            name_th: "บวกค้าง",
            name_en: "Buak Khang",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501305,
            zip_code: 50130,
            name_th: "แช่ช้าง",
            name_en: "Chae Chang",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501306,
            zip_code: 50130,
            name_th: "ออนใต้",
            name_en: "On Tai",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501310,
            zip_code: 50130,
            name_th: "แม่ปูคา",
            name_en: "Mae Pu Kha",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501311,
            zip_code: 50130,
            name_th: "ห้วยทราย",
            name_en: "Huai Sai",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501312,
            zip_code: 50130,
            name_th: "ต้นเปา",
            name_en: "Ton Pao",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501313,
            zip_code: 50130,
            name_th: "สันกลาง",
            name_en: "San Klang",
            amphure_id: 5013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5014,
        name_th: "สันทราย",
        name_en: "San Sai",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501401,
            zip_code: 50210,
            name_th: "สันทรายหลวง",
            name_en: "San Sai Luang",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501402,
            zip_code: 50210,
            name_th: "สันทรายน้อย",
            name_en: "San Sai Noi",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501403,
            zip_code: 50210,
            name_th: "สันพระเนตร",
            name_en: "San Phranet",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501404,
            zip_code: 50210,
            name_th: "สันนาเม็ง",
            name_en: "San Na Meng",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501405,
            zip_code: 50210,
            name_th: "สันป่าเปา",
            name_en: "San Pa Pao",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501406,
            zip_code: 50210,
            name_th: "หนองแหย่ง",
            name_en: "Nong Yaeng",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501407,
            zip_code: 50210,
            name_th: "หนองจ๊อม",
            name_en: "Nong Chom",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501408,
            zip_code: 50290,
            name_th: "หนองหาร",
            name_en: "Nong Han",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501409,
            zip_code: 50290,
            name_th: "แม่แฝก",
            name_en: "Mae Faek",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501410,
            zip_code: 50290,
            name_th: "แม่แฝกใหม่",
            name_en: "Mae Faek Mai",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501411,
            zip_code: 50210,
            name_th: "เมืองเล็น",
            name_en: "Mueang Len",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501412,
            zip_code: 50210,
            name_th: "ป่าไผ่",
            name_en: "Pa Phai",
            amphure_id: 5014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5015,
        name_th: "หางดง",
        name_en: "Hang Dong",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501501,
            zip_code: 50230,
            name_th: "หางดง",
            name_en: "Hang Dong",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501502,
            zip_code: 50230,
            name_th: "หนองแก๋ว",
            name_en: "Nong Kaeo",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501503,
            zip_code: 50230,
            name_th: "หารแก้ว",
            name_en: "Han Kaeo",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501504,
            zip_code: 50340,
            name_th: "หนองตอง",
            name_en: "Nong Tong",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501505,
            zip_code: 50230,
            name_th: "ขุนคง",
            name_en: "Khun Khong",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501506,
            zip_code: 50230,
            name_th: "สบแม่ข่า",
            name_en: "Sop Mae Kha",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501507,
            zip_code: 50230,
            name_th: "บ้านแหวน",
            name_en: "Ban Waen",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501508,
            zip_code: 50230,
            name_th: "สันผักหวาน",
            name_en: "San Phak Wan",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501509,
            zip_code: 50230,
            name_th: "หนองควาย",
            name_en: "Nong Khwai",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501510,
            zip_code: 50230,
            name_th: "บ้านปง",
            name_en: "Ban Pong",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501511,
            zip_code: 50230,
            name_th: "น้ำแพร่",
            name_en: "Nam Phrae",
            amphure_id: 5015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5016,
        name_th: "ฮอด",
        name_en: "Hot",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501601,
            zip_code: 50240,
            name_th: "หางดง",
            name_en: "Hang Dong",
            amphure_id: 5016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501602,
            zip_code: 50240,
            name_th: "ฮอด",
            name_en: "Hot",
            amphure_id: 5016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501603,
            zip_code: 50240,
            name_th: "บ้านตาล",
            name_en: "Ban Tan",
            amphure_id: 5016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501604,
            zip_code: 50240,
            name_th: "บ่อหลวง",
            name_en: "Bo Luang",
            amphure_id: 5016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501605,
            zip_code: 50240,
            name_th: "บ่อสลี",
            name_en: "Bo Sali",
            amphure_id: 5016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501606,
            zip_code: 50240,
            name_th: "นาคอเรือ",
            name_en: "Na Kho Ruea",
            amphure_id: 5016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5017,
        name_th: "ดอยเต่า",
        name_en: "Doi Tao",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501701,
            zip_code: 50260,
            name_th: "ดอยเต่า",
            name_en: "Doi Tao",
            amphure_id: 5017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501702,
            zip_code: 50260,
            name_th: "ท่าเดื่อ",
            name_en: "Tha Duea",
            amphure_id: 5017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501703,
            zip_code: 50260,
            name_th: "มืดกา",
            name_en: "Muet Ka",
            amphure_id: 5017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501704,
            zip_code: 50260,
            name_th: "บ้านแอ่น",
            name_en: "Ban Aen",
            amphure_id: 5017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501705,
            zip_code: 50260,
            name_th: "บงตัน",
            name_en: "Bong Tan",
            amphure_id: 5017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501706,
            zip_code: 50260,
            name_th: "โปงทุ่ง",
            name_en: "Pong Thung",
            amphure_id: 5017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5018,
        name_th: "อมก๋อย",
        name_en: "Omkoi",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501801,
            zip_code: 50310,
            name_th: "อมก๋อย",
            name_en: "Omkoi",
            amphure_id: 5018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501802,
            zip_code: 50310,
            name_th: "ยางเปียง",
            name_en: "Yang Piang",
            amphure_id: 5018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501803,
            zip_code: 50310,
            name_th: "แม่ตื่น",
            name_en: "Mae Tuen",
            amphure_id: 5018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501804,
            zip_code: 50310,
            name_th: "ม่อนจอง",
            name_en: "Mon Chong",
            amphure_id: 5018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501805,
            zip_code: 50310,
            name_th: "สบโขง",
            name_en: "Sop Khong",
            amphure_id: 5018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501806,
            zip_code: 50310,
            name_th: "นาเกียน",
            name_en: "Na Kian",
            amphure_id: 5018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5019,
        name_th: "สารภี",
        name_en: "Saraphi",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 501901,
            zip_code: 50140,
            name_th: "ยางเนิ้ง",
            name_en: "Yang Noeng",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501902,
            zip_code: 50140,
            name_th: "สารภี",
            name_en: "Saraphi",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501903,
            zip_code: 50140,
            name_th: "ชมภู",
            name_en: "Chom Phu",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501904,
            zip_code: 50140,
            name_th: "ไชยสถาน",
            name_en: "Chai Sathan",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501905,
            zip_code: 50140,
            name_th: "ขัวมุง",
            name_en: "Khua Mung",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501906,
            zip_code: 50140,
            name_th: "หนองแฝก",
            name_en: "Nong Faek",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501907,
            zip_code: 50140,
            name_th: "หนองผึ้ง",
            name_en: "Nong Phueng",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501908,
            zip_code: 50140,
            name_th: "ท่ากว้าง",
            name_en: "Tha Kwang",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501909,
            zip_code: 50140,
            name_th: "ดอนแก้ว",
            name_en: "Don Kaeo",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501910,
            zip_code: 50140,
            name_th: "ท่าวังตาล",
            name_en: "Tha Wang Tan",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501911,
            zip_code: 50140,
            name_th: "สันทราย",
            name_en: "San Sai",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 501912,
            zip_code: 50140,
            name_th: "ป่าบง",
            name_en: "Pa Bong",
            amphure_id: 5019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5020,
        name_th: "เวียงแหง",
        name_en: "Wiang Haeng",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 502001,
            zip_code: 50350,
            name_th: "เมืองแหง",
            name_en: "Mueang Haeng",
            amphure_id: 5020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502002,
            zip_code: 50350,
            name_th: "เปียงหลวง",
            name_en: "Piang Luang",
            amphure_id: 5020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502003,
            zip_code: 50350,
            name_th: "แสนไห",
            name_en: "Saen Hai",
            amphure_id: 5020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5021,
        name_th: "ไชยปราการ",
        name_en: "Chai Prakan",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 502101,
            zip_code: 50320,
            name_th: "ปงตำ",
            name_en: "Pong Tam",
            amphure_id: 5021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502102,
            zip_code: 50320,
            name_th: "ศรีดงเย็น",
            name_en: "Si Dong Yen",
            amphure_id: 5021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502103,
            zip_code: 50320,
            name_th: "แม่ทะลบ",
            name_en: "Mae Thalop",
            amphure_id: 5021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502104,
            zip_code: 50320,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 5021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5022,
        name_th: "แม่วาง",
        name_en: "Mae Wang",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 502201,
            zip_code: 50360,
            name_th: "บ้านกาด",
            name_en: "Ban Kat",
            amphure_id: 5022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502202,
            zip_code: 50360,
            name_th: "ทุ่งปี้",
            name_en: "Thung Pi",
            amphure_id: 5022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502203,
            zip_code: 50360,
            name_th: "ทุ่งรวงทอง",
            name_en: "Thung Ruang Thong",
            amphure_id: 5022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502204,
            zip_code: 50360,
            name_th: "แม่วิน",
            name_en: "Mae Win",
            amphure_id: 5022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502205,
            zip_code: 50360,
            name_th: "ดอนเปา",
            name_en: "Don Pao",
            amphure_id: 5022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5023,
        name_th: "แม่ออน",
        name_en: "Mae On",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 502301,
            zip_code: 50130,
            name_th: "ออนเหนือ",
            name_en: "On Nuea",
            amphure_id: 5023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502302,
            zip_code: 50130,
            name_th: "ออนกลาง",
            name_en: "On Klang",
            amphure_id: 5023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502303,
            zip_code: 50130,
            name_th: "บ้านสหกรณ์",
            name_en: "Ban Sahakon",
            amphure_id: 5023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502304,
            zip_code: 50130,
            name_th: "ห้วยแก้ว",
            name_en: "Huai Kaeo",
            amphure_id: 5023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502305,
            zip_code: 50130,
            name_th: "แม่ทา",
            name_en: "Mae Tha",
            amphure_id: 5023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502306,
            zip_code: 50130,
            name_th: "ทาเหนือ",
            name_en: "Tha Nuea",
            amphure_id: 5023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5024,
        name_th: "ดอยหล่อ",
        name_en: "Doi Lo",
        province_id: 38,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 502401,
            zip_code: 50160,
            name_th: "ดอยหล่อ",
            name_en: "Doi Lo",
            amphure_id: 5024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502402,
            zip_code: 50160,
            name_th: "สองแคว",
            name_en: "Song Khwae",
            amphure_id: 5024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502403,
            zip_code: 50160,
            name_th: "ยางคราม",
            name_en: "Yang Khram",
            amphure_id: 5024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 502404,
            zip_code: 50160,
            name_th: "สันติสุข",
            name_en: "Santi Suk",
            amphure_id: 5024,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 39,
    name_th: "ลำพูน",
    name_en: "Lamphun",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5101,
        name_th: "เมืองลำพูน",
        name_en: "Mueang Lamphun",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510101,
            zip_code: 51000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510102,
            zip_code: 51000,
            name_th: "เหมืองง่า",
            name_en: "Mueang Nga",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510103,
            zip_code: 51150,
            name_th: "อุโมงค์",
            name_en: "Umong",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510104,
            zip_code: 51150,
            name_th: "หนองช้างคืน",
            name_en: "Nong Chang Khuen",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510105,
            zip_code: 51000,
            name_th: "ประตูป่า",
            name_en: "Pratu Pa",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510106,
            zip_code: 51000,
            name_th: "ริมปิง",
            name_en: "Rim Ping",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510107,
            zip_code: 51000,
            name_th: "ต้นธง",
            name_en: "Ton Thong",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510108,
            zip_code: 51000,
            name_th: "บ้านแป้น",
            name_en: "Ban Paen",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510109,
            zip_code: 51000,
            name_th: "เหมืองจี้",
            name_en: "Mueang Chi",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510110,
            zip_code: 51000,
            name_th: "ป่าสัก",
            name_en: "Pa Sak",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510111,
            zip_code: 51000,
            name_th: "เวียงยอง",
            name_en: "Wiang Yong",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510112,
            zip_code: 51000,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510113,
            zip_code: 51000,
            name_th: "มะเขือแจ้",
            name_en: "Makhuea Chae",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510116,
            zip_code: 51000,
            name_th: "ศรีบัวบาน",
            name_en: "Si Bua Ban",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510117,
            zip_code: 51000,
            name_th: "หนองหนาม",
            name_en: "Nong Nam",
            amphure_id: 5101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5102,
        name_th: "แม่ทา",
        name_en: "Mae Tha",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510201,
            zip_code: 51140,
            name_th: "ทาปลาดุก",
            name_en: "Tha Pla Duk",
            amphure_id: 5102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510202,
            zip_code: 51140,
            name_th: "ทาสบเส้า",
            name_en: "Tha Sop Sao",
            amphure_id: 5102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510203,
            zip_code: 51170,
            name_th: "ทากาศ",
            name_en: "Tha Kat",
            amphure_id: 5102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510204,
            zip_code: 51170,
            name_th: "ทาขุมเงิน",
            name_en: "Tha Khum Ngoen",
            amphure_id: 5102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510205,
            zip_code: 51170,
            name_th: "ทาทุ่งหลวง",
            name_en: "Tha Thung Luang",
            amphure_id: 5102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510206,
            zip_code: 51170,
            name_th: "ทาแม่ลอบ",
            name_en: "Tha Mae Lop",
            amphure_id: 5102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5103,
        name_th: "บ้านโฮ่ง",
        name_en: "Ban Hong",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510301,
            zip_code: 51130,
            name_th: "บ้านโฮ่ง",
            name_en: "Ban Hong",
            amphure_id: 5103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510302,
            zip_code: 51130,
            name_th: "ป่าพลู",
            name_en: "Pa Phlu",
            amphure_id: 5103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510303,
            zip_code: 51130,
            name_th: "เหล่ายาว",
            name_en: "Lao Yao",
            amphure_id: 5103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510304,
            zip_code: 51130,
            name_th: "ศรีเตี้ย",
            name_en: "Si Tia",
            amphure_id: 5103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510305,
            zip_code: 51130,
            name_th: "หนองปลาสะวาย",
            name_en: "Nong Pla Sawai",
            amphure_id: 5103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5104,
        name_th: "ลี้",
        name_en: "Li",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510401,
            zip_code: 51110,
            name_th: "ลี้",
            name_en: "Li",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510402,
            zip_code: 51110,
            name_th: "แม่ตืน",
            name_en: "Mae Tuen",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510403,
            zip_code: 51110,
            name_th: "นาทราย",
            name_en: "Na Sai",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510404,
            zip_code: 51110,
            name_th: "ดงดำ",
            name_en: "Dong Dam",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510405,
            zip_code: 51110,
            name_th: "ก้อ",
            name_en: "Ko",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510406,
            zip_code: 51110,
            name_th: "แม่ลาน",
            name_en: "Mae Lan",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510408,
            zip_code: 51110,
            name_th: "ป่าไผ่",
            name_en: "Pa Phai",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510409,
            zip_code: 51110,
            name_th: "ศรีวิชัย",
            name_en: "Si Wichai",
            amphure_id: 5104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5105,
        name_th: "ทุ่งหัวช้าง",
        name_en: "Thung Hua Chang",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510501,
            zip_code: 51160,
            name_th: "ทุ่งหัวช้าง",
            name_en: "Thung Hua Chang",
            amphure_id: 5105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510502,
            zip_code: 51160,
            name_th: "บ้านปวง",
            name_en: "Ban Puang",
            amphure_id: 5105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510503,
            zip_code: 51160,
            name_th: "ตะเคียนปม",
            name_en: "Takhian Pom",
            amphure_id: 5105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5106,
        name_th: "ป่าซาง",
        name_en: "Pa Sang",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510601,
            zip_code: 51120,
            name_th: "ปากบ่อง",
            name_en: "Pak Bong",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510602,
            zip_code: 51120,
            name_th: "ป่าซาง",
            name_en: "Pa Sang",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510603,
            zip_code: 51120,
            name_th: "แม่แรง",
            name_en: "Mae Raeng",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510604,
            zip_code: 51120,
            name_th: "ม่วงน้อย",
            name_en: "Muang Noi",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510605,
            zip_code: 51120,
            name_th: "บ้านเรือน",
            name_en: "Ban Ruean",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510606,
            zip_code: 51120,
            name_th: "มะกอก",
            name_en: "Makok",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510607,
            zip_code: 51120,
            name_th: "ท่าตุ้ม",
            name_en: "Tha Tum",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510608,
            zip_code: 51120,
            name_th: "น้ำดิบ",
            name_en: "Nam Dip",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510611,
            zip_code: 51120,
            name_th: "นครเจดีย์",
            name_en: "Nakhon Chedi",
            amphure_id: 5106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5107,
        name_th: "บ้านธิ",
        name_en: "Ban Thi",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510701,
            zip_code: 51180,
            name_th: "บ้านธิ",
            name_en: "Ban Thi",
            amphure_id: 5107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510702,
            zip_code: 51180,
            name_th: "ห้วยยาบ",
            name_en: "Huai Yap",
            amphure_id: 5107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5108,
        name_th: "เวียงหนองล่อง",
        name_en: "Wiang Nong Long",
        province_id: 39,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 510801,
            zip_code: 51120,
            name_th: "หนองล่อง",
            name_en: "Nong Long",
            amphure_id: 5108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510802,
            zip_code: 51120,
            name_th: "หนองยวง",
            name_en: "Nong Yuang",
            amphure_id: 5108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 510803,
            zip_code: 51120,
            name_th: "วังผาง",
            name_en: "Wang Phang",
            amphure_id: 5108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 40,
    name_th: "ลำปาง",
    name_en: "Lampang",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5201,
        name_th: "เมืองลำปาง",
        name_en: "Mueang Lampang",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520101,
            zip_code: 52000,
            name_th: "เวียงเหนือ",
            name_en: "Wiang Nuea",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520102,
            zip_code: 52000,
            name_th: "หัวเวียง",
            name_en: "Hua Wiang",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520103,
            zip_code: 52100,
            name_th: "สวนดอก",
            name_en: "Suan Dok",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520104,
            zip_code: 52100,
            name_th: "สบตุ๋ย",
            name_en: "Sop Tui",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520105,
            zip_code: 52000,
            name_th: "พระบาท",
            name_en: "Phra Bat",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520106,
            zip_code: 52100,
            name_th: "ชมพู",
            name_en: "Chomphu",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520107,
            zip_code: 52000,
            name_th: "กล้วยแพะ",
            name_en: "Kluai Phae",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520108,
            zip_code: 52100,
            name_th: "ปงแสนทอง",
            name_en: "Pong Saen Thong",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520109,
            zip_code: 52000,
            name_th: "บ้านแลง",
            name_en: "Ban Laeng",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520110,
            zip_code: 52000,
            name_th: "บ้านเสด็จ",
            name_en: "Ban Sadet",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520111,
            zip_code: 52000,
            name_th: "พิชัย",
            name_en: "Phichai",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520112,
            zip_code: 52000,
            name_th: "ทุ่งฝาย",
            name_en: "Thung Fai",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520113,
            zip_code: 52100,
            name_th: "บ้านเอื้อม",
            name_en: "Ban Ueam",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520114,
            zip_code: 52100,
            name_th: "บ้านเป้า",
            name_en: "Ban Pao",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520115,
            zip_code: 52100,
            name_th: "บ้านค่า",
            name_en: "Ban Kha",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520116,
            zip_code: 52100,
            name_th: "บ่อแฮ้ว",
            name_en: "Bo Haeo",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520117,
            zip_code: 52000,
            name_th: "ต้นธงชัย",
            name_en: "Ton Thong Chai",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520118,
            zip_code: 52000,
            name_th: "นิคมพัฒนา",
            name_en: "Nikhom Phatthana",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520119,
            zip_code: 52000,
            name_th: "บุญนาคพัฒนา",
            name_en: "Bunnak Phatthana",
            amphure_id: 5201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5202,
        name_th: "แม่เมาะ",
        name_en: "Mae Mo",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520201,
            zip_code: 52220,
            name_th: "บ้านดง",
            name_en: "Ban Dong",
            amphure_id: 5202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520202,
            zip_code: 52220,
            name_th: "นาสัก",
            name_en: "Na Sak",
            amphure_id: 5202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520203,
            zip_code: 52220,
            name_th: "จางเหนือ",
            name_en: "Chang Nuea",
            amphure_id: 5202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520204,
            zip_code: 52220,
            name_th: "แม่เมาะ",
            name_en: "Mae Mo",
            amphure_id: 5202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520205,
            zip_code: 52220,
            name_th: "สบป้าด",
            name_en: "Sop Pat",
            amphure_id: 5202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5203,
        name_th: "เกาะคา",
        name_en: "Ko Kha",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520301,
            zip_code: 52130,
            name_th: "ลำปางหลวง",
            name_en: "Lampang Luang",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520302,
            zip_code: 52130,
            name_th: "นาแก้ว",
            name_en: "Na Kaeo",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520303,
            zip_code: 52130,
            name_th: "ไหล่หิน",
            name_en: "Lai Hin",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520304,
            zip_code: 52130,
            name_th: "วังพร้าว",
            name_en: "Wang Phrao",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520305,
            zip_code: 52130,
            name_th: "ศาลา",
            name_en: "Sala",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520306,
            zip_code: 52130,
            name_th: "เกาะคา",
            name_en: "Ko Kha",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520307,
            zip_code: 52130,
            name_th: "นาแส่ง",
            name_en: "Na Saeng",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520308,
            zip_code: 52130,
            name_th: "ท่าผา",
            name_en: "Tha Pha",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520309,
            zip_code: 52130,
            name_th: "ใหม่พัฒนา",
            name_en: "Mai Phatthana",
            amphure_id: 5203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5204,
        name_th: "เสริมงาม",
        name_en: "Soem Ngam",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520401,
            zip_code: 52210,
            name_th: "ทุ่งงาม",
            name_en: "Thung Ngam",
            amphure_id: 5204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520402,
            zip_code: 52210,
            name_th: "เสริมขวา",
            name_en: "Soem Khwa",
            amphure_id: 5204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520403,
            zip_code: 52210,
            name_th: "เสริมซ้าย",
            name_en: "Soem Sai",
            amphure_id: 5204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520404,
            zip_code: 52210,
            name_th: "เสริมกลาง",
            name_en: "Soem Klang",
            amphure_id: 5204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5205,
        name_th: "งาว",
        name_en: "Ngao",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520501,
            zip_code: 52110,
            name_th: "หลวงเหนือ",
            name_en: "Luang Nuea",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520502,
            zip_code: 52110,
            name_th: "หลวงใต้",
            name_en: "Luang Tai",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520503,
            zip_code: 52110,
            name_th: "บ้านโป่ง",
            name_en: "Ban Pong",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520504,
            zip_code: 52110,
            name_th: "บ้านร้อง",
            name_en: "Ban Rong",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520505,
            zip_code: 52110,
            name_th: "ปงเตา",
            name_en: "Pong Tao",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520506,
            zip_code: 52110,
            name_th: "นาแก",
            name_en: "Na Kae",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520507,
            zip_code: 52110,
            name_th: "บ้านอ้อน",
            name_en: "Ban On",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520508,
            zip_code: 52110,
            name_th: "บ้านแหง",
            name_en: "Ban Haeng",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520509,
            zip_code: 52110,
            name_th: "บ้านหวด",
            name_en: "Ban Huat",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520510,
            zip_code: 52110,
            name_th: "แม่ตีบ",
            name_en: "Mae Tip",
            amphure_id: 5205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5206,
        name_th: "แจ้ห่ม",
        name_en: "Chae Hom",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520601,
            zip_code: 52120,
            name_th: "แจ้ห่ม",
            name_en: "Chae Hom",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520602,
            zip_code: 52120,
            name_th: "บ้านสา",
            name_en: "Ban Sa",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520603,
            zip_code: 52120,
            name_th: "ปงดอน",
            name_en: "Pong Don",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520604,
            zip_code: 52120,
            name_th: "แม่สุก",
            name_en: "Mae Suk",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520605,
            zip_code: 52120,
            name_th: "เมืองมาย",
            name_en: "Mueang Mai",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520606,
            zip_code: 52120,
            name_th: "ทุ่งผึ้ง",
            name_en: "Thung Phueng",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520607,
            zip_code: 52120,
            name_th: "วิเชตนคร",
            name_en: "Wichet Nakhon",
            amphure_id: 5206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5207,
        name_th: "วังเหนือ",
        name_en: "Wang Nuea",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520701,
            zip_code: 52140,
            name_th: "ทุ่งฮั้ว",
            name_en: "Thung Hua",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520702,
            zip_code: 52140,
            name_th: "วังเหนือ",
            name_en: "Wang Nuea",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520703,
            zip_code: 52140,
            name_th: "วังใต้",
            name_en: "Wang Tai",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520704,
            zip_code: 52140,
            name_th: "ร่องเคาะ",
            name_en: "Rong Kho",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520705,
            zip_code: 52140,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520706,
            zip_code: 52140,
            name_th: "วังซ้าย",
            name_en: "Wang Sai",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520707,
            zip_code: 52140,
            name_th: "วังแก้ว",
            name_en: "Wang Kaeo",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520708,
            zip_code: 52140,
            name_th: "วังทรายคำ",
            name_en: "Wang Sai Kham",
            amphure_id: 5207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5208,
        name_th: "เถิน",
        name_en: "Thoen",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520801,
            zip_code: 52160,
            name_th: "ล้อมแรด",
            name_en: "Lom Raet",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520802,
            zip_code: 52230,
            name_th: "แม่วะ",
            name_en: "Mae Wa",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520803,
            zip_code: 52160,
            name_th: "แม่ปะ",
            name_en: "Mae Pa",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520804,
            zip_code: 52160,
            name_th: "แม่มอก",
            name_en: "Mae Mok",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520805,
            zip_code: 52160,
            name_th: "เวียงมอก",
            name_en: "Wiang Mok",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520806,
            zip_code: 52160,
            name_th: "นาโป่ง",
            name_en: "Na Pong",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520807,
            zip_code: 52160,
            name_th: "แม่ถอด",
            name_en: "Mae Thot",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520808,
            zip_code: 52160,
            name_th: "เถินบุรี",
            name_en: "Thoen Buri",
            amphure_id: 5208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5209,
        name_th: "แม่พริก",
        name_en: "Mae Phrik",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 520901,
            zip_code: 52180,
            name_th: "แม่พริก",
            name_en: "Mae Phrik",
            amphure_id: 5209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520902,
            zip_code: 52180,
            name_th: "ผาปัง",
            name_en: "Pha Pang",
            amphure_id: 5209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520903,
            zip_code: 52180,
            name_th: "แม่ปุ",
            name_en: "Mae Pu",
            amphure_id: 5209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 520904,
            zip_code: 52180,
            name_th: "พระบาทวังตวง",
            name_en: "Phra Bat Wang Tuang",
            amphure_id: 5209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5210,
        name_th: "แม่ทะ",
        name_en: "Mae Tha",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 521001,
            zip_code: 52150,
            name_th: "แม่ทะ",
            name_en: "Mae Tha",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521002,
            zip_code: 52150,
            name_th: "นาครัว",
            name_en: "Na Khrua",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521003,
            zip_code: 52150,
            name_th: "ป่าตัน",
            name_en: "Pa Tan",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521004,
            zip_code: 52150,
            name_th: "บ้านกิ่ว",
            name_en: "Ban Kio",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521005,
            zip_code: 52150,
            name_th: "บ้านบอม",
            name_en: "Ban Bom",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521006,
            zip_code: 52150,
            name_th: "น้ำโจ้",
            name_en: "Nam Cho",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521007,
            zip_code: 52150,
            name_th: "ดอนไฟ",
            name_en: "Don Fai",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521008,
            zip_code: 52150,
            name_th: "หัวเสือ",
            name_en: "Hua Suea",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521010,
            zip_code: 52150,
            name_th: "วังเงิน",
            name_en: "Wang Ngoen",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521011,
            zip_code: 52150,
            name_th: "สันดอนแก้ว",
            name_en: "San Don Kaeo",
            amphure_id: 5210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5211,
        name_th: "สบปราบ",
        name_en: "Sop Prap",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 521101,
            zip_code: 52170,
            name_th: "สบปราบ",
            name_en: "Sop Prap",
            amphure_id: 5211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521102,
            zip_code: 52170,
            name_th: "สมัย",
            name_en: "Samai",
            amphure_id: 5211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521103,
            zip_code: 52170,
            name_th: "แม่กัวะ",
            name_en: "Mae Kua",
            amphure_id: 5211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521104,
            zip_code: 52170,
            name_th: "นายาง",
            name_en: "Na Yang",
            amphure_id: 5211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5212,
        name_th: "ห้างฉัตร",
        name_en: "Hang Chat",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 521201,
            zip_code: 52190,
            name_th: "ห้างฉัตร",
            name_en: "Hang Chat",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521202,
            zip_code: 52190,
            name_th: "หนองหล่ม",
            name_en: "Nong Lom",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521203,
            zip_code: 52190,
            name_th: "เมืองยาว",
            name_en: "Mueang Yao",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521204,
            zip_code: 52190,
            name_th: "ปงยางคก",
            name_en: "Pong Yang Khok",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521205,
            zip_code: 52190,
            name_th: "เวียงตาล",
            name_en: "Wiang Tan",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521206,
            zip_code: 52190,
            name_th: "แม่สัน",
            name_en: "Mae San",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521207,
            zip_code: 52190,
            name_th: "วอแก้ว",
            name_en: "Wo Kaeo",
            amphure_id: 5212,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5213,
        name_th: "เมืองปาน",
        name_en: "Mueang Pan",
        province_id: 40,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 521301,
            zip_code: 52240,
            name_th: "เมืองปาน",
            name_en: "Mueang Pan",
            amphure_id: 5213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521302,
            zip_code: 52240,
            name_th: "บ้านขอ",
            name_en: "Ban Kho",
            amphure_id: 5213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521303,
            zip_code: 52240,
            name_th: "ทุ่งกว๋าว",
            name_en: "Thung Kwao",
            amphure_id: 5213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521304,
            zip_code: 52240,
            name_th: "แจ้ซ้อน",
            name_en: "Chae Son",
            amphure_id: 5213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 521305,
            zip_code: 52240,
            name_th: "หัวเมือง",
            name_en: "Hua Mueang",
            amphure_id: 5213,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 41,
    name_th: "อุตรดิตถ์",
    name_en: "Uttaradit",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5301,
        name_th: "เมืองอุตรดิตถ์",
        name_en: "Mueang Uttaradit",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530101,
            zip_code: 53000,
            name_th: "ท่าอิฐ",
            name_en: "Tha It",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530102,
            zip_code: 53000,
            name_th: "ท่าเสา",
            name_en: "Tha Sao",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530103,
            zip_code: 53000,
            name_th: "บ้านเกาะ",
            name_en: "Ban Ko",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530104,
            zip_code: 53000,
            name_th: "ป่าเซ่า",
            name_en: "Pa Sao",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530105,
            zip_code: 53000,
            name_th: "คุ้งตะเภา",
            name_en: "Khung Taphao",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530106,
            zip_code: 53170,
            name_th: "วังกะพี้",
            name_en: "Wang Kaphi",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530107,
            zip_code: 53000,
            name_th: "หาดกรวด",
            name_en: "Hat Kruat",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530108,
            zip_code: 53000,
            name_th: "น้ำริด",
            name_en: "Nam Rit",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530109,
            zip_code: 53000,
            name_th: "งิ้วงาม",
            name_en: "Ngio Ngam",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530110,
            zip_code: 53000,
            name_th: "บ้านด่านนาขาม",
            name_en: "Ban Dan Na Kham",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530111,
            zip_code: 53000,
            name_th: "บ้านด่าน",
            name_en: "Ban Dan",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530112,
            zip_code: 53000,
            name_th: "ผาจุก",
            name_en: "Pha Chuk",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530113,
            zip_code: 53000,
            name_th: "วังดิน",
            name_en: "Wang Din",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530114,
            zip_code: 53000,
            name_th: "แสนตอ",
            name_en: "Saen To",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530115,
            zip_code: 53000,
            name_th: "หาดงิ้ว",
            name_en: "Hat Ngio",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530116,
            zip_code: 53000,
            name_th: "ขุนฝาง",
            name_en: "Khun Fang",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530117,
            zip_code: 53000,
            name_th: "ถ้ำฉลอง",
            name_en: "Tham Chalong",
            amphure_id: 5301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5302,
        name_th: "ตรอน",
        name_en: "Tron",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530201,
            zip_code: 53140,
            name_th: "วังแดง",
            name_en: "Wang Daeng",
            amphure_id: 5302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530202,
            zip_code: 53140,
            name_th: "บ้านแก่ง",
            name_en: "Ban Kaeng",
            amphure_id: 5302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530203,
            zip_code: 53140,
            name_th: "หาดสองแคว",
            name_en: "Hat Song Khwae",
            amphure_id: 5302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530204,
            zip_code: 53140,
            name_th: "น้ำอ่าง",
            name_en: "Nam Ang",
            amphure_id: 5302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530205,
            zip_code: 53140,
            name_th: "ข่อยสูง",
            name_en: "Khoi Sung",
            amphure_id: 5302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5303,
        name_th: "ท่าปลา",
        name_en: "Tha Pla",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530301,
            zip_code: 53150,
            name_th: "ท่าปลา",
            name_en: "Tha Pla",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530302,
            zip_code: 53150,
            name_th: "หาดล้า",
            name_en: "Hat La",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530303,
            zip_code: 53190,
            name_th: "ผาเลือด",
            name_en: "Pha Lueat",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530304,
            zip_code: 53150,
            name_th: "จริม",
            name_en: "Charim",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530305,
            zip_code: 53150,
            name_th: "น้ำหมัน",
            name_en: "Nam Man",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530306,
            zip_code: 53110,
            name_th: "ท่าแฝก",
            name_en: "Tha Faek",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530307,
            zip_code: 53150,
            name_th: "นางพญา",
            name_en: "Nang Phaya",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530308,
            zip_code: 53190,
            name_th: "ร่วมจิต",
            name_en: "Ruam Chit",
            amphure_id: 5303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5304,
        name_th: "น้ำปาด",
        name_en: "Nam Pat",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530401,
            zip_code: 53110,
            name_th: "แสนตอ",
            name_en: "Saen To",
            amphure_id: 5304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530402,
            zip_code: 53110,
            name_th: "บ้านฝาย",
            name_en: "Ban Fai",
            amphure_id: 5304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530403,
            zip_code: 53110,
            name_th: "เด่นเหล็ก",
            name_en: "Den Lek",
            amphure_id: 5304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530404,
            zip_code: 53110,
            name_th: "น้ำไคร้",
            name_en: "Nam Khrai",
            amphure_id: 5304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530405,
            zip_code: 53110,
            name_th: "น้ำไผ่",
            name_en: "Nam Phai",
            amphure_id: 5304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530406,
            zip_code: 53110,
            name_th: "ห้วยมุ่น",
            name_en: "Huai Mun",
            amphure_id: 5304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5305,
        name_th: "ฟากท่า",
        name_en: "Fak Tha",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530501,
            zip_code: 53160,
            name_th: "ฟากท่า",
            name_en: "Fak Tha",
            amphure_id: 5305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530502,
            zip_code: 53160,
            name_th: "สองคอน",
            name_en: "Song Khon",
            amphure_id: 5305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530503,
            zip_code: 53160,
            name_th: "บ้านเสี้ยว",
            name_en: "Ban Siao",
            amphure_id: 5305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530504,
            zip_code: 53160,
            name_th: "สองห้อง",
            name_en: "Song Hong",
            amphure_id: 5305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5306,
        name_th: "บ้านโคก",
        name_en: "Ban Khok",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530601,
            zip_code: 53180,
            name_th: "ม่วงเจ็ดต้น",
            name_en: "Muang Chet Ton",
            amphure_id: 5306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530602,
            zip_code: 53180,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 5306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530603,
            zip_code: 53180,
            name_th: "นาขุม",
            name_en: "Na Khum",
            amphure_id: 5306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530604,
            zip_code: 53180,
            name_th: "บ่อเบี้ย",
            name_en: "Bo Bia",
            amphure_id: 5306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5307,
        name_th: "พิชัย",
        name_en: "Phichai",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530701,
            zip_code: 53120,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530702,
            zip_code: 53220,
            name_th: "บ้านดารา",
            name_en: "Ban Dara",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530703,
            zip_code: 53120,
            name_th: "ไร่อ้อย",
            name_en: "Rai Oi",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530704,
            zip_code: 53220,
            name_th: "ท่าสัก",
            name_en: "Tha Sak",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530705,
            zip_code: 53120,
            name_th: "คอรุม",
            name_en: "Kho Rum",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530706,
            zip_code: 53120,
            name_th: "บ้านหม้อ",
            name_en: "Ban Mo",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530707,
            zip_code: 53120,
            name_th: "ท่ามะเฟือง",
            name_en: "Tha Mafueang",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530708,
            zip_code: 53120,
            name_th: "บ้านโคน",
            name_en: "Ban Khon",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530709,
            zip_code: 53120,
            name_th: "พญาแมน",
            name_en: "Phaya Maen",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530710,
            zip_code: 53120,
            name_th: "นาอิน",
            name_en: "Na In",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530711,
            zip_code: 53120,
            name_th: "นายาง",
            name_en: "Na Yang",
            amphure_id: 5307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5308,
        name_th: "ลับแล",
        name_en: "Laplae",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530801,
            zip_code: 53130,
            name_th: "ศรีพนมมาศ",
            name_en: "Si Phanom Mat",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530802,
            zip_code: 53130,
            name_th: "แม่พูล",
            name_en: "Mae Phun",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530803,
            zip_code: 53130,
            name_th: "นานกกก",
            name_en: "Na Nok Kok",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530804,
            zip_code: 53130,
            name_th: "ฝายหลวง",
            name_en: "Fai Luang",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530805,
            zip_code: 53130,
            name_th: "ชัยจุมพล",
            name_en: "Chai Chumphon",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530806,
            zip_code: 53210,
            name_th: "ไผ่ล้อม",
            name_en: "Phai Lom",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530807,
            zip_code: 53210,
            name_th: "ทุ่งยั้ง",
            name_en: "Thung Yang",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530808,
            zip_code: 53210,
            name_th: "ด่านแม่คำมัน",
            name_en: "Dan Mae Kham Man",
            amphure_id: 5308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5309,
        name_th: "ทองแสนขัน",
        name_en: "Thong Saen Khan",
        province_id: 41,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 530901,
            zip_code: 53230,
            name_th: "ผักขวง",
            name_en: "Phak Khuang",
            amphure_id: 5309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530902,
            zip_code: 53230,
            name_th: "บ่อทอง",
            name_en: "Bo Thong",
            amphure_id: 5309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530903,
            zip_code: 53230,
            name_th: "ป่าคาย",
            name_en: "Pa Khai",
            amphure_id: 5309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 530904,
            zip_code: 53230,
            name_th: "น้ำพี้",
            name_en: "Nam Phi",
            amphure_id: 5309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 42,
    name_th: "แพร่",
    name_en: "Phrae",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5401,
        name_th: "เมืองแพร่",
        name_en: "Mueang Phrae",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540101,
            zip_code: 54000,
            name_th: "ในเวียง",
            name_en: "Nai Wiang",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540102,
            zip_code: 54000,
            name_th: "นาจักร",
            name_en: "Na Chak",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540103,
            zip_code: 54000,
            name_th: "น้ำชำ",
            name_en: "Nam Cham",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540104,
            zip_code: 54000,
            name_th: "ป่าแดง",
            name_en: "Pa Daeng",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540105,
            zip_code: 54000,
            name_th: "ทุ่งโฮ้ง",
            name_en: "Thung Hong",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540106,
            zip_code: 54000,
            name_th: "เหมืองหม้อ",
            name_en: "Mueang Mo",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540107,
            zip_code: 54000,
            name_th: "วังธง",
            name_en: "Wang Thong",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540108,
            zip_code: 54000,
            name_th: "แม่หล่าย",
            name_en: "Mae Lai",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540109,
            zip_code: 54000,
            name_th: "ห้วยม้า",
            name_en: "Huai Ma",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540110,
            zip_code: 54000,
            name_th: "ป่าแมต",
            name_en: "Pa Maet",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540111,
            zip_code: 54000,
            name_th: "บ้านถิ่น",
            name_en: "Ban Thin",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540112,
            zip_code: 54000,
            name_th: "สวนเขื่อน",
            name_en: "Suan Khuean",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540113,
            zip_code: 54000,
            name_th: "วังหงส์",
            name_en: "Wang Hong",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540114,
            zip_code: 54000,
            name_th: "แม่คำมี",
            name_en: "Mae Kham Mi",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540115,
            zip_code: 54000,
            name_th: "ทุ่งกวาว",
            name_en: "Thung Kwao",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540116,
            zip_code: 54000,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540117,
            zip_code: 54000,
            name_th: "แม่ยม",
            name_en: "Mae Yom",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540118,
            zip_code: 54000,
            name_th: "ช่อแฮ",
            name_en: "Cho Hae",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540119,
            zip_code: 54000,
            name_th: "ร่องฟอง",
            name_en: "Rong Fong",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540120,
            zip_code: 54000,
            name_th: "กาญจนา",
            name_en: "Kanchana",
            amphure_id: 5401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5402,
        name_th: "ร้องกวาง",
        name_en: "Rong Kwang",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540201,
            zip_code: 54140,
            name_th: "ร้องกวาง",
            name_en: "Rong Kwang",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540204,
            zip_code: 54140,
            name_th: "ร้องเข็ม",
            name_en: "Rong Khem",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540205,
            zip_code: 54140,
            name_th: "น้ำเลา",
            name_en: "Nam Lao",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540206,
            zip_code: 54140,
            name_th: "บ้านเวียง",
            name_en: "Ban Wiang",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540207,
            zip_code: 54140,
            name_th: "ทุ่งศรี",
            name_en: "Thung Si",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540208,
            zip_code: 54140,
            name_th: "แม่ยางตาล",
            name_en: "Mae Yang Tan",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540209,
            zip_code: 54140,
            name_th: "แม่ยางฮ่อ",
            name_en: "Mae Yang Ho",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540210,
            zip_code: 54140,
            name_th: "ไผ่โทน",
            name_en: "Phai Thon",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540213,
            zip_code: 54140,
            name_th: "ห้วยโรง",
            name_en: "Huai Rong",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540214,
            zip_code: 54140,
            name_th: "แม่ทราย",
            name_en: "Mae Sai",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540215,
            zip_code: 54140,
            name_th: "แม่ยางร้อง",
            name_en: "Mae Yang Rong",
            amphure_id: 5402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5403,
        name_th: "ลอง",
        name_en: "Long",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540301,
            zip_code: 54150,
            name_th: "ห้วยอ้อ",
            name_en: "Huai O",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540302,
            zip_code: 54150,
            name_th: "บ้านปิน",
            name_en: "Ban Pin",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540303,
            zip_code: 54150,
            name_th: "ต้าผามอก",
            name_en: "Ta Pha Mok",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540304,
            zip_code: 54150,
            name_th: "เวียงต้า",
            name_en: "Wiang Ta",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540305,
            zip_code: 54150,
            name_th: "ปากกาง",
            name_en: "Pak Kang",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540306,
            zip_code: 54150,
            name_th: "หัวทุ่ง",
            name_en: "Hua Thung",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540307,
            zip_code: 54150,
            name_th: "ทุ่งแล้ง",
            name_en: "Thung Laeng",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540308,
            zip_code: 54150,
            name_th: "บ่อเหล็กลอง",
            name_en: "Bo Lek Long",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540309,
            zip_code: 54150,
            name_th: "แม่ปาน",
            name_en: "Mae Pan",
            amphure_id: 5403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5404,
        name_th: "สูงเม่น",
        name_en: "Sung Men",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540401,
            zip_code: 54130,
            name_th: "สูงเม่น",
            name_en: "Sung Men",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540402,
            zip_code: 54130,
            name_th: "น้ำชำ",
            name_en: "Nam Cham",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540403,
            zip_code: 54130,
            name_th: "หัวฝาย",
            name_en: "Hua Fai",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540404,
            zip_code: 54130,
            name_th: "ดอนมูล",
            name_en: "Don Mun",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540405,
            zip_code: 54130,
            name_th: "บ้านเหล่า",
            name_en: "Ban Lao",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540406,
            zip_code: 54130,
            name_th: "บ้านกวาง",
            name_en: "Ban Kwang",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540407,
            zip_code: 54130,
            name_th: "บ้านปง",
            name_en: "Ban Pong",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540408,
            zip_code: 54130,
            name_th: "บ้านกาศ",
            name_en: "Ban Kat",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540409,
            zip_code: 54130,
            name_th: "ร่องกาศ",
            name_en: "Rong Kat",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540410,
            zip_code: 54130,
            name_th: "สบสาย",
            name_en: "Sop Sai",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540411,
            zip_code: 54000,
            name_th: "เวียงทอง",
            name_en: "Wiang Thong",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540412,
            zip_code: 54130,
            name_th: "พระหลวง",
            name_en: "Phra Luang",
            amphure_id: 5404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5405,
        name_th: "เด่นชัย",
        name_en: "Den Chai",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540501,
            zip_code: 54110,
            name_th: "เด่นชัย",
            name_en: "Den Chai",
            amphure_id: 5405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540502,
            zip_code: 54110,
            name_th: "แม่จั๊วะ",
            name_en: "Mae Chua",
            amphure_id: 5405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540503,
            zip_code: 54110,
            name_th: "ไทรย้อย",
            name_en: "Sai Yoi",
            amphure_id: 5405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540504,
            zip_code: 54110,
            name_th: "ห้วยไร่",
            name_en: "Huai Rai",
            amphure_id: 5405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540505,
            zip_code: 54110,
            name_th: "ปงป่าหวาย",
            name_en: "Pong Pa Wai",
            amphure_id: 5405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5406,
        name_th: "สอง",
        name_en: "Song",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540601,
            zip_code: 54120,
            name_th: "บ้านหนุน",
            name_en: "Ban Nun",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540602,
            zip_code: 54120,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540603,
            zip_code: 54120,
            name_th: "ห้วยหม้าย",
            name_en: "Huai Mai",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540604,
            zip_code: 54120,
            name_th: "เตาปูน",
            name_en: "Tao Pun",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540605,
            zip_code: 54120,
            name_th: "หัวเมือง",
            name_en: "Hua Mueang",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540606,
            zip_code: 54120,
            name_th: "สะเอียบ",
            name_en: "Sa-iap",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540607,
            zip_code: 54120,
            name_th: "แดนชุมพล",
            name_en: "Daen Chumphon",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540608,
            zip_code: 54120,
            name_th: "ทุ่งน้าว",
            name_en: "Thung Nao",
            amphure_id: 5406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5407,
        name_th: "วังชิ้น",
        name_en: "Wang Chin",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540701,
            zip_code: 54160,
            name_th: "วังชิ้น",
            name_en: "Wang Chin",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540702,
            zip_code: 54160,
            name_th: "สรอย",
            name_en: "Saroi",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540703,
            zip_code: 54160,
            name_th: "แม่ป้าก",
            name_en: "Mae Pak",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540704,
            zip_code: 54160,
            name_th: "นาพูน",
            name_en: "Na Phun",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540705,
            zip_code: 54160,
            name_th: "แม่พุง",
            name_en: "Mae Phung",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540706,
            zip_code: 54160,
            name_th: "ป่าสัก",
            name_en: "Pa Sak",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540707,
            zip_code: 54160,
            name_th: "แม่เกิ๋ง",
            name_en: "Mae Koeng",
            amphure_id: 5407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5408,
        name_th: "หนองม่วงไข่",
        name_en: "Nong Muang Khai",
        province_id: 42,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 540801,
            zip_code: 54170,
            name_th: "แม่คำมี",
            name_en: "Mae Kham Mi",
            amphure_id: 5408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540802,
            zip_code: 54170,
            name_th: "หนองม่วงไข่",
            name_en: "Nong Muang Khai",
            amphure_id: 5408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540803,
            zip_code: 54170,
            name_th: "น้ำรัด",
            name_en: "Nam Rat",
            amphure_id: 5408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540804,
            zip_code: 54170,
            name_th: "วังหลวง",
            name_en: "Wang Luang",
            amphure_id: 5408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540805,
            zip_code: 54170,
            name_th: "ตำหนักธรรม",
            name_en: "Tamnak Tham",
            amphure_id: 5408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 540806,
            zip_code: 54170,
            name_th: "ทุ่งแค้ว",
            name_en: "Thung Khaeo",
            amphure_id: 5408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 43,
    name_th: "น่าน",
    name_en: "Nan",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5501,
        name_th: "เมืองน่าน",
        name_en: "Mueang Nan",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550101,
            zip_code: 55000,
            name_th: "ในเวียง",
            name_en: "Nai Wiang",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550102,
            zip_code: 55000,
            name_th: "บ่อ",
            name_en: "Bo",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550103,
            zip_code: 55000,
            name_th: "ผาสิงห์",
            name_en: "Pha Sing",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550104,
            zip_code: 55000,
            name_th: "ไชยสถาน",
            name_en: "Chai Sathan",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550105,
            zip_code: 55000,
            name_th: "ถืมตอง",
            name_en: "Thuem Tong",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550106,
            zip_code: 55000,
            name_th: "เรือง",
            name_en: "Rueang",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550107,
            zip_code: 55000,
            name_th: "นาซาว",
            name_en: "Na Sao",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550108,
            zip_code: 55000,
            name_th: "ดู่ใต้",
            name_en: "Du Tai",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550109,
            zip_code: 55000,
            name_th: "กองควาย",
            name_en: "Kong Khwai",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550116,
            zip_code: 55000,
            name_th: "สวก",
            name_en: "Suak",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550117,
            zip_code: 55000,
            name_th: "สะเนียน",
            name_en: "Sanian",
            amphure_id: 5501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5502,
        name_th: "แม่จริม",
        name_en: "Mae Charim",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550202,
            zip_code: 55170,
            name_th: "หนองแดง",
            name_en: "Nong Daeng",
            amphure_id: 5502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550203,
            zip_code: 55170,
            name_th: "หมอเมือง",
            name_en: "Mo Mueang",
            amphure_id: 5502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550204,
            zip_code: 55170,
            name_th: "น้ำพาง",
            name_en: "Nam Phang",
            amphure_id: 5502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550205,
            zip_code: 55170,
            name_th: "น้ำปาย",
            name_en: "Nam Pai",
            amphure_id: 5502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550206,
            zip_code: 55170,
            name_th: "แม่จริม",
            name_en: "Mae Charim",
            amphure_id: 5502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5503,
        name_th: "บ้านหลวง",
        name_en: "Ban Luang",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550301,
            zip_code: 55190,
            name_th: "บ้านฟ้า",
            name_en: "Ban Fa",
            amphure_id: 5503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550302,
            zip_code: 55190,
            name_th: "ป่าคาหลวง",
            name_en: "Pa Kha Luang",
            amphure_id: 5503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550303,
            zip_code: 55190,
            name_th: "สวด",
            name_en: "Suat",
            amphure_id: 5503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550304,
            zip_code: 55190,
            name_th: "บ้านพี้",
            name_en: "Ban Phi",
            amphure_id: 5503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5504,
        name_th: "นาน้อย",
        name_en: "Na Noi",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550401,
            zip_code: 55150,
            name_th: "นาน้อย",
            name_en: "Na Noi",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550402,
            zip_code: 55150,
            name_th: "เชียงของ",
            name_en: "Chiang Khong",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550403,
            zip_code: 55150,
            name_th: "ศรีษะเกษ",
            name_en: "Sisaket",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550404,
            zip_code: 55150,
            name_th: "สถาน",
            name_en: "Sathan",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550405,
            zip_code: 55150,
            name_th: "สันทะ",
            name_en: "Santha",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550406,
            zip_code: 55150,
            name_th: "บัวใหญ่",
            name_en: "Bua Yai",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550407,
            zip_code: 55150,
            name_th: "น้ำตก",
            name_en: "Nam Tok",
            amphure_id: 5504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5505,
        name_th: "ปัว",
        name_en: "Pua",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550501,
            zip_code: 55120,
            name_th: "ปัว",
            name_en: "Pua",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550502,
            zip_code: 55120,
            name_th: "แงง",
            name_en: "Ngaeng",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550503,
            zip_code: 55120,
            name_th: "สถาน",
            name_en: "Sathan",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550504,
            zip_code: 55120,
            name_th: "ศิลาแลง",
            name_en: "Sila Laeng",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550505,
            zip_code: 55120,
            name_th: "ศิลาเพชร",
            name_en: "Sila Phet",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550506,
            zip_code: 55120,
            name_th: "อวน",
            name_en: "Uan",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550509,
            zip_code: 55120,
            name_th: "ไชยวัฒนา",
            name_en: "Chai Watthana",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550510,
            zip_code: 55120,
            name_th: "เจดีย์ชัย",
            name_en: "Chedi Chai",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550511,
            zip_code: 55120,
            name_th: "ภูคา",
            name_en: "Phu Kha",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550512,
            zip_code: 55120,
            name_th: "สกาด",
            name_en: "Sakat",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550513,
            zip_code: 55120,
            name_th: "ป่ากลาง",
            name_en: "Pa Klang",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550514,
            zip_code: 55120,
            name_th: "วรนคร",
            name_en: "Woranakhon",
            amphure_id: 5505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5506,
        name_th: "ท่าวังผา",
        name_en: "Tha Wang Pha",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550601,
            zip_code: 55140,
            name_th: "ริม",
            name_en: "Rim",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550602,
            zip_code: 55140,
            name_th: "ป่าคา",
            name_en: "Pa Kha",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550603,
            zip_code: 55140,
            name_th: "ผาตอ",
            name_en: "Pha To",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550604,
            zip_code: 55140,
            name_th: "ยม",
            name_en: "Yom",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550605,
            zip_code: 55140,
            name_th: "ตาลชุม",
            name_en: "Tan Chum",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550606,
            zip_code: 55140,
            name_th: "ศรีภูมิ",
            name_en: "Si Phum",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550607,
            zip_code: 55140,
            name_th: "จอมพระ",
            name_en: "Chom Phra",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550608,
            zip_code: 55140,
            name_th: "แสนทอง",
            name_en: "Saen Thong",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550609,
            zip_code: 55140,
            name_th: "ท่าวังผา",
            name_en: "Tha Wang Pha",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550610,
            zip_code: 55140,
            name_th: "ผาทอง",
            name_en: "Pha Thong",
            amphure_id: 5506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5507,
        name_th: "เวียงสา",
        name_en: "Wiang Sa",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550701,
            zip_code: 55110,
            name_th: "กลางเวียง",
            name_en: "Klang Wiang",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550702,
            zip_code: 55110,
            name_th: "ขึ่ง",
            name_en: "Khueng",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550703,
            zip_code: 55110,
            name_th: "ไหล่น่าน",
            name_en: "Lai Nan",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550704,
            zip_code: 55110,
            name_th: "ตาลชุม",
            name_en: "Tan Chum",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550705,
            zip_code: 55110,
            name_th: "นาเหลือง",
            name_en: "Na Lueang",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550706,
            zip_code: 55110,
            name_th: "ส้าน",
            name_en: "San",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550707,
            zip_code: 55110,
            name_th: "น้ำมวบ",
            name_en: "Nam Muap",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550708,
            zip_code: 55110,
            name_th: "น้ำปั้ว",
            name_en: "Nam Pua",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550709,
            zip_code: 55110,
            name_th: "ยาบหัวนา",
            name_en: "Yap Hua Na",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550710,
            zip_code: 55110,
            name_th: "ปงสนุก",
            name_en: "Pong Sanuk",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550711,
            zip_code: 55110,
            name_th: "อ่ายนาไลย",
            name_en: "Ai Na Lai",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550712,
            zip_code: 55110,
            name_th: "ส้านนาหนองใหม่",
            name_en: "San Na Nong Mai",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550713,
            zip_code: 55110,
            name_th: "แม่ขะนิง",
            name_en: "Mae Khaning",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550714,
            zip_code: 55110,
            name_th: "แม่สาคร",
            name_en: "Mae Sakhon",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550715,
            zip_code: 55110,
            name_th: "จอมจันทร์",
            name_en: "Chom Chan",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550716,
            zip_code: 55110,
            name_th: "แม่สา",
            name_en: "Mae Sa",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550717,
            zip_code: 55110,
            name_th: "ทุ่งศรีทอง",
            name_en: "Thung Si Thong",
            amphure_id: 5507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5508,
        name_th: "ทุ่งช้าง",
        name_en: "Thung Chang",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550801,
            zip_code: 55130,
            name_th: "ปอน",
            name_en: "Pon",
            amphure_id: 5508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550802,
            zip_code: 55130,
            name_th: "งอบ",
            name_en: "Ngop",
            amphure_id: 5508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550803,
            zip_code: 55130,
            name_th: "และ",
            name_en: "Lae",
            amphure_id: 5508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550804,
            zip_code: 55130,
            name_th: "ทุ่งช้าง",
            name_en: "Thung Chang",
            amphure_id: 5508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5509,
        name_th: "เชียงกลาง",
        name_en: "Chiang Klang",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 550901,
            zip_code: 55160,
            name_th: "เชียงกลาง",
            name_en: "Chiang Klang",
            amphure_id: 5509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550902,
            zip_code: 55160,
            name_th: "เปือ",
            name_en: "Puea",
            amphure_id: 5509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550903,
            zip_code: 55160,
            name_th: "เชียงคาน",
            name_en: "Chiang Khan",
            amphure_id: 5509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550904,
            zip_code: 55160,
            name_th: "พระธาตุ",
            name_en: "Phra That",
            amphure_id: 5509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550908,
            zip_code: 55160,
            name_th: "พญาแก้ว",
            name_en: "Phaya Kaeo",
            amphure_id: 5509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 550909,
            zip_code: 55160,
            name_th: "พระพุทธบาท",
            name_en: "Phra Phutthabat",
            amphure_id: 5509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5510,
        name_th: "นาหมื่น",
        name_en: "Na Muen",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 551001,
            zip_code: 55180,
            name_th: "นาทะนุง",
            name_en: "Na Thanung",
            amphure_id: 5510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551002,
            zip_code: 55180,
            name_th: "บ่อแก้ว",
            name_en: "Bo Kaeo",
            amphure_id: 5510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551003,
            zip_code: 55180,
            name_th: "เมืองลี",
            name_en: "Mueang Li",
            amphure_id: 5510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551004,
            zip_code: 55180,
            name_th: "ปิงหลวง",
            name_en: "Ping Luang",
            amphure_id: 5510,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5511,
        name_th: "สันติสุข",
        name_en: "Santi Suk",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 551101,
            zip_code: 55210,
            name_th: "ดู่พงษ์",
            name_en: "Du Phong",
            amphure_id: 5511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551102,
            zip_code: 55210,
            name_th: "ป่าแลวหลวง",
            name_en: "Pa Laeo Luang",
            amphure_id: 5511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551103,
            zip_code: 55210,
            name_th: "พงษ์",
            name_en: "Phong",
            amphure_id: 5511,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5512,
        name_th: "บ่อเกลือ",
        name_en: "Bo Kluea",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 551201,
            zip_code: 55220,
            name_th: "บ่อเกลือเหนือ",
            name_en: "Bo Kluea Nuea",
            amphure_id: 5512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551202,
            zip_code: 55220,
            name_th: "บ่อเกลือใต้",
            name_en: "Bo Kluea Tai",
            amphure_id: 5512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551204,
            zip_code: 55220,
            name_th: "ภูฟ้า",
            name_en: "Phu Fa",
            amphure_id: 5512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551205,
            zip_code: 55220,
            name_th: "ดงพญา",
            name_en: "Dong Phaya",
            amphure_id: 5512,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5513,
        name_th: "สองแคว",
        name_en: "Song Khwae",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 551301,
            zip_code: 55160,
            name_th: "นาไร่หลวง",
            name_en: "Na Rai Luang",
            amphure_id: 5513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551302,
            zip_code: 55160,
            name_th: "ชนแดน",
            name_en: "Chon Daen",
            amphure_id: 5513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551303,
            zip_code: 55160,
            name_th: "ยอด",
            name_en: "Yot",
            amphure_id: 5513,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5514,
        name_th: "ภูเพียง",
        name_en: "Phu Phiang",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 551401,
            zip_code: 55000,
            name_th: "ม่วงตึ๊ด",
            name_en: "Muang Tuet",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551402,
            zip_code: 55000,
            name_th: "นาปัง",
            name_en: "Na Pang",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551403,
            zip_code: 55000,
            name_th: "น้ำแก่น",
            name_en: "Nam Kaen",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551404,
            zip_code: 55000,
            name_th: "น้ำเกี๋ยน",
            name_en: "Nam Kian",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551405,
            zip_code: 55000,
            name_th: "เมืองจัง",
            name_en: "Mueang Chang",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551406,
            zip_code: 55000,
            name_th: "ท่าน้าว",
            name_en: "Tha Nao",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551407,
            zip_code: 55000,
            name_th: "ฝายแก้ว",
            name_en: "Fai Kaeo",
            amphure_id: 5514,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5515,
        name_th: "เฉลิมพระเกียรติ",
        name_en: "Chaloem Phra Kiat",
        province_id: 43,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 551501,
            zip_code: 55130,
            name_th: "ห้วยโก๋น",
            name_en: "Huai Kon",
            amphure_id: 5515,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 551502,
            zip_code: 55130,
            name_th: "ขุนน่าน",
            name_en: "Khun Nan",
            amphure_id: 5515,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 44,
    name_th: "พะเยา",
    name_en: "Phayao",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5601,
        name_th: "เมืองพะเยา",
        name_en: "Mueang Phayao",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560101,
            zip_code: 56000,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560102,
            zip_code: 56000,
            name_th: "แม่ต๋ำ",
            name_en: "Mae Tam",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560104,
            zip_code: 56000,
            name_th: "แม่นาเรือ",
            name_en: "Mae Na Ruea",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560105,
            zip_code: 56000,
            name_th: "บ้านตุ่น",
            name_en: "Ban Tun",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560106,
            zip_code: 56000,
            name_th: "บ้านต๊ำ",
            name_en: "Ban Tam",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560107,
            zip_code: 56000,
            name_th: "บ้านต๋อม",
            name_en: "Ban Tom",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560108,
            zip_code: 56000,
            name_th: "แม่ปืม",
            name_en: "Mae Puem",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560110,
            zip_code: 56000,
            name_th: "แม่กา",
            name_en: "Mae Ka",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560111,
            zip_code: 56000,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560112,
            zip_code: 56000,
            name_th: "จำป่าหวาย",
            name_en: "Cham Pa Wai",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560113,
            zip_code: 56000,
            name_th: "ท่าวังทอง",
            name_en: "Tha Wang Thong",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560114,
            zip_code: 56000,
            name_th: "แม่ใส",
            name_en: "Mae Sai",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560115,
            zip_code: 56000,
            name_th: "บ้านสาง",
            name_en: "Ban Sang",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560116,
            zip_code: 56000,
            name_th: "ท่าจำปี",
            name_en: "Tha Champi",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560118,
            zip_code: 56000,
            name_th: "สันป่าม่วง",
            name_en: "San Pa Muang",
            amphure_id: 5601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5602,
        name_th: "จุน",
        name_en: "Chun",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560201,
            zip_code: 56150,
            name_th: "ห้วยข้าวก่ำ",
            name_en: "Huai Khao Kam",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560202,
            zip_code: 56150,
            name_th: "จุน",
            name_en: "Chun",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560203,
            zip_code: 56150,
            name_th: "ลอ",
            name_en: "Lo",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560204,
            zip_code: 56150,
            name_th: "หงส์หิน",
            name_en: "Hong Hin",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560205,
            zip_code: 56150,
            name_th: "ทุ่งรวงทอง",
            name_en: "Thung Ruang Thong",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560206,
            zip_code: 56150,
            name_th: "ห้วยยางขาม",
            name_en: "Huai Yang Kham",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560207,
            zip_code: 56150,
            name_th: "พระธาตุขิงแกง",
            name_en: "Phra That Khing Kaeng",
            amphure_id: 5602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5603,
        name_th: "เชียงคำ",
        name_en: "Chiang Kham",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560301,
            zip_code: 56110,
            name_th: "หย่วน",
            name_en: "Yuan",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560306,
            zip_code: 56110,
            name_th: "น้ำแวน",
            name_en: "Nam Waen",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560307,
            zip_code: 56110,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560308,
            zip_code: 56110,
            name_th: "ฝายกวาง",
            name_en: "Fai Kwang",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560309,
            zip_code: 56110,
            name_th: "เจดีย์คำ",
            name_en: "Chedi Kham",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560310,
            zip_code: 56110,
            name_th: "ร่มเย็น",
            name_en: "Rom Yen",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560311,
            zip_code: 56110,
            name_th: "เชียงบาน",
            name_en: "Chiang Ban",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560312,
            zip_code: 56110,
            name_th: "แม่ลาว",
            name_en: "Mae Lao",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560313,
            zip_code: 56110,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560314,
            zip_code: 56110,
            name_th: "ทุ่งผาสุข",
            name_en: "Thung Pha Suk",
            amphure_id: 5603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5604,
        name_th: "เชียงม่วน",
        name_en: "Chiang Muan",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560401,
            zip_code: 56160,
            name_th: "เชียงม่วน",
            name_en: "Chiang Muan",
            amphure_id: 5604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560402,
            zip_code: 56160,
            name_th: "บ้านมาง",
            name_en: "Ban Mang",
            amphure_id: 5604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560403,
            zip_code: 56160,
            name_th: "สระ",
            name_en: "Sa",
            amphure_id: 5604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5605,
        name_th: "ดอกคำใต้",
        name_en: "Dok Khamtai",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560501,
            zip_code: 56120,
            name_th: "ดอกคำใต้",
            name_en: "Dok Khamtai",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560502,
            zip_code: 56120,
            name_th: "ดอนศรีชุม",
            name_en: "Don Si Chum",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560503,
            zip_code: 56120,
            name_th: "บ้านถ้ำ",
            name_en: "Ban Tham",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560504,
            zip_code: 56120,
            name_th: "บ้านปิน",
            name_en: "Ban Pin",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560505,
            zip_code: 56120,
            name_th: "ห้วยลาน",
            name_en: "Huai Lan",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560506,
            zip_code: 56120,
            name_th: "สันโค้ง",
            name_en: "San Khong",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560507,
            zip_code: 56120,
            name_th: "ป่าซาง",
            name_en: "Pa Sang",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560508,
            zip_code: 56120,
            name_th: "หนองหล่ม",
            name_en: "Nong Lom",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560509,
            zip_code: 56120,
            name_th: "ดงสุวรรณ",
            name_en: "Dong Suwan",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560510,
            zip_code: 56120,
            name_th: "บุญเกิด",
            name_en: "Bun Koet",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560511,
            zip_code: 56120,
            name_th: "สว่างอารมณ์",
            name_en: "Sawang Arom",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560512,
            zip_code: 56120,
            name_th: "คือเวียง",
            name_en: "Khue Wiang",
            amphure_id: 5605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5606,
        name_th: "ปง",
        name_en: "Pong",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560601,
            zip_code: 56140,
            name_th: "ปง",
            name_en: "Pong",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560602,
            zip_code: 56140,
            name_th: "ควร",
            name_en: "Khuan",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560603,
            zip_code: 56140,
            name_th: "ออย",
            name_en: "Oi",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560604,
            zip_code: 56140,
            name_th: "งิม",
            name_en: "Ngim",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560605,
            zip_code: 56140,
            name_th: "ผาช้างน้อย",
            name_en: "Pha Chang Noi",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560606,
            zip_code: 56140,
            name_th: "นาปรัง",
            name_en: "Na Prang",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560607,
            zip_code: 56140,
            name_th: "ขุนควร",
            name_en: "Khun Khuan",
            amphure_id: 5606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5607,
        name_th: "แม่ใจ",
        name_en: "Mae Chai",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560701,
            zip_code: 56130,
            name_th: "แม่ใจ",
            name_en: "Mae Chai",
            amphure_id: 5607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560702,
            zip_code: 56130,
            name_th: "ศรีถ้อย",
            name_en: "Si Thoi",
            amphure_id: 5607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560703,
            zip_code: 56130,
            name_th: "แม่สุก",
            name_en: "Mae Suk",
            amphure_id: 5607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560704,
            zip_code: 56130,
            name_th: "ป่าแฝก",
            name_en: "Pa Faek",
            amphure_id: 5607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560705,
            zip_code: 56130,
            name_th: "บ้านเหล่า",
            name_en: "Ban Lao",
            amphure_id: 5607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560706,
            zip_code: 56130,
            name_th: "เจริญราษฎร์",
            name_en: "Charoen Rat",
            amphure_id: 5607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5608,
        name_th: "ภูซาง",
        name_en: "Phu Sang",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560801,
            zip_code: 56110,
            name_th: "ภูซาง",
            name_en: "Phu Sang",
            amphure_id: 5608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560802,
            zip_code: 56110,
            name_th: "ป่าสัก",
            name_en: "Pa Sak",
            amphure_id: 5608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560803,
            zip_code: 56110,
            name_th: "ทุ่งกล้วย",
            name_en: "Thung Kluai",
            amphure_id: 5608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560804,
            zip_code: 56110,
            name_th: "เชียงแรง",
            name_en: "Chiang Raeng",
            amphure_id: 5608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560805,
            zip_code: 56110,
            name_th: "สบบง",
            name_en: "Sop Bong",
            amphure_id: 5608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5609,
        name_th: "ภูกามยาว",
        name_en: "Phu Kamyao",
        province_id: 44,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 560901,
            zip_code: 56000,
            name_th: "ห้วยแก้ว",
            name_en: "Huai Kaeo",
            amphure_id: 5609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560902,
            zip_code: 56000,
            name_th: "ดงเจน",
            name_en: "Dong Chen",
            amphure_id: 5609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 560903,
            zip_code: 56000,
            name_th: "แม่อิง",
            name_en: "Mae Ing",
            amphure_id: 5609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 45,
    name_th: "เชียงราย",
    name_en: "Chiang Rai",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5701,
        name_th: "เมืองเชียงราย",
        name_en: "Mueang Chiang Rai",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570101,
            zip_code: 57000,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570102,
            zip_code: 57000,
            name_th: "รอบเวียง",
            name_en: "Rop Wiang",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570103,
            zip_code: 57100,
            name_th: "บ้านดู่",
            name_en: "Ban Du",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570104,
            zip_code: 57100,
            name_th: "นางแล",
            name_en: "Nang Lae",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570105,
            zip_code: 57100,
            name_th: "แม่ข้าวต้ม",
            name_en: "Mae Khao Tom",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570106,
            zip_code: 57100,
            name_th: "แม่ยาว",
            name_en: "Mae Yao",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570107,
            zip_code: 57000,
            name_th: "สันทราย",
            name_en: "San Sai",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570111,
            zip_code: 57000,
            name_th: "แม่กรณ์",
            name_en: "Mae Kon",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570112,
            zip_code: 57000,
            name_th: "ห้วยชมภู",
            name_en: "Huai Chomphu",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570113,
            zip_code: 57000,
            name_th: "ห้วยสัก",
            name_en: "Huai Sak",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570114,
            zip_code: 57100,
            name_th: "ริมกก",
            name_en: "Rim Kok",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570115,
            zip_code: 57000,
            name_th: "ดอยลาน",
            name_en: "Doi Lan",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570116,
            zip_code: 57000,
            name_th: "ป่าอ้อดอนชัย",
            name_en: "Pa O Don Chai",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570118,
            zip_code: 57000,
            name_th: "ท่าสาย",
            name_en: "Tha Sai",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570120,
            zip_code: 57000,
            name_th: "ดอยฮาง",
            name_en: "Doi Hang",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570121,
            zip_code: 57100,
            name_th: "ท่าสุด",
            name_en: "Tha Sut",
            amphure_id: 5701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5702,
        name_th: "เวียงชัย",
        name_en: "Wiang Chai",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570202,
            zip_code: 57210,
            name_th: "เวียงชัย",
            name_en: "Wiang Chai",
            amphure_id: 5702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570203,
            zip_code: 57210,
            name_th: "ผางาม",
            name_en: "Pha Ngam",
            amphure_id: 5702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570204,
            zip_code: 57210,
            name_th: "เวียงเหนือ",
            name_en: "Wiang Nuea",
            amphure_id: 5702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570206,
            zip_code: 57210,
            name_th: "ดอนศิลา",
            name_en: "Don Sila",
            amphure_id: 5702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570208,
            zip_code: 57210,
            name_th: "เมืองชุม",
            name_en: "Mueang Chum",
            amphure_id: 5702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5703,
        name_th: "เชียงของ",
        name_en: "Chiang Khong",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570301,
            zip_code: 57140,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570302,
            zip_code: 57140,
            name_th: "สถาน",
            name_en: "Sathan",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570303,
            zip_code: 57140,
            name_th: "ครึ่ง",
            name_en: "Khrueng",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570304,
            zip_code: 57140,
            name_th: "บุญเรือง",
            name_en: "Bun Rueang",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570305,
            zip_code: 57140,
            name_th: "ห้วยซ้อ",
            name_en: "Huai So",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570308,
            zip_code: 57230,
            name_th: "ศรีดอนชัย",
            name_en: "Si Don Chai",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570310,
            zip_code: 57140,
            name_th: "ริมโขง",
            name_en: "Rim Khong",
            amphure_id: 5703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5704,
        name_th: "เทิง",
        name_en: "Thoeng",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570401,
            zip_code: 57160,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570402,
            zip_code: 57160,
            name_th: "งิ้ว",
            name_en: "Ngio",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570403,
            zip_code: 57230,
            name_th: "ปล้อง",
            name_en: "Plong",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570404,
            zip_code: 57230,
            name_th: "แม่ลอย",
            name_en: "Mae Loi",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570405,
            zip_code: 57230,
            name_th: "เชียงเคี่ยน",
            name_en: "Chiang Khian",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570409,
            zip_code: 57160,
            name_th: "ตับเต่า",
            name_en: "Tap Tao",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570410,
            zip_code: 57160,
            name_th: "หงาว",
            name_en: "Ngao",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570411,
            zip_code: 57160,
            name_th: "สันทรายงาม",
            name_en: "San Sai Ngam",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570412,
            zip_code: 57160,
            name_th: "ศรีดอนไชย",
            name_en: "Si Don Chai",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570413,
            zip_code: 57160,
            name_th: "หนองแรด",
            name_en: "Nong Raet",
            amphure_id: 5704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5705,
        name_th: "พาน",
        name_en: "Phan",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570501,
            zip_code: 57120,
            name_th: "สันมะเค็ด",
            name_en: "San Makhet",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570502,
            zip_code: 57120,
            name_th: "แม่อ้อ",
            name_en: "Mae O",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570503,
            zip_code: 57250,
            name_th: "ธารทอง",
            name_en: "Than Thong",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570504,
            zip_code: 57120,
            name_th: "สันติสุข",
            name_en: "Santi Suk",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570505,
            zip_code: 57120,
            name_th: "ดอยงาม",
            name_en: "Doi Ngam",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570506,
            zip_code: 57120,
            name_th: "หัวง้ม",
            name_en: "Hua Ngom",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570507,
            zip_code: 57120,
            name_th: "เจริญเมือง",
            name_en: "Charoen Mueang",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570508,
            zip_code: 57120,
            name_th: "ป่าหุ่ง",
            name_en: "Pa Hung",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570509,
            zip_code: 57120,
            name_th: "ม่วงคำ",
            name_en: "Muang Kham",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570510,
            zip_code: 57120,
            name_th: "ทรายขาว",
            name_en: "Sai Khao",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570511,
            zip_code: 57120,
            name_th: "สันกลาง",
            name_en: "San Klang",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570512,
            zip_code: 57280,
            name_th: "แม่เย็น",
            name_en: "Mae Yen",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570513,
            zip_code: 57120,
            name_th: "เมืองพาน",
            name_en: "Mueang Phan",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570514,
            zip_code: 57280,
            name_th: "ทานตะวัน",
            name_en: "Than Tawan",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570515,
            zip_code: 57120,
            name_th: "เวียงห้าว",
            name_en: "Wiang Hao",
            amphure_id: 5705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5706,
        name_th: "ป่าแดด",
        name_en: "Pa Daet",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570601,
            zip_code: 57190,
            name_th: "ป่าแดด",
            name_en: "Pa Daet",
            amphure_id: 5706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570602,
            zip_code: 57190,
            name_th: "ป่าแงะ",
            name_en: "Pa Ngae",
            amphure_id: 5706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570603,
            zip_code: 57190,
            name_th: "สันมะค่า",
            name_en: "San Makha",
            amphure_id: 5706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570605,
            zip_code: 57190,
            name_th: "โรงช้าง",
            name_en: "Rong Chang",
            amphure_id: 5706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570606,
            zip_code: 57190,
            name_th: "ศรีโพธิ์เงิน",
            name_en: "Si Pho Ngoen",
            amphure_id: 5706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5707,
        name_th: "แม่จัน",
        name_en: "Mae Chan",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570701,
            zip_code: 57110,
            name_th: "แม่จัน",
            name_en: "Mae Chan",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570702,
            zip_code: 57270,
            name_th: "จันจว้า",
            name_en: "Chan Chwa",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570703,
            zip_code: 57240,
            name_th: "แม่คำ",
            name_en: "Mae Kham",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570704,
            zip_code: 57110,
            name_th: "ป่าซาง",
            name_en: "Pa Sang",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570705,
            zip_code: 57110,
            name_th: "สันทราย",
            name_en: "San Sai",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570706,
            zip_code: 57110,
            name_th: "ท่าข้าวเปลือก",
            name_en: "Tha Khao Plueak",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570708,
            zip_code: 57110,
            name_th: "ป่าตึง",
            name_en: "Pa Tueng",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570710,
            zip_code: 57240,
            name_th: "แม่ไร่",
            name_en: "Mae Rai",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570711,
            zip_code: 57110,
            name_th: "ศรีค้ำ",
            name_en: "Si Kham",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570712,
            zip_code: 57270,
            name_th: "จันจว้าใต้",
            name_en: "Chan Chwa Tai",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570713,
            zip_code: 57110,
            name_th: "จอมสวรรค์",
            name_en: "Chom Sawan",
            amphure_id: 5707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5708,
        name_th: "เชียงแสน",
        name_en: "Chiang Saen",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570801,
            zip_code: 57150,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570802,
            zip_code: 57150,
            name_th: "ป่าสัก",
            name_en: "Pa Sak",
            amphure_id: 5708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570803,
            zip_code: 57150,
            name_th: "บ้านแซว",
            name_en: "Ban Saeo",
            amphure_id: 5708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570804,
            zip_code: 57150,
            name_th: "ศรีดอนมูล",
            name_en: "Si Don Mun",
            amphure_id: 5708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570805,
            zip_code: 57150,
            name_th: "แม่เงิน",
            name_en: "Mae Ngoen",
            amphure_id: 5708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570806,
            zip_code: 57150,
            name_th: "โยนก",
            name_en: "Yonok",
            amphure_id: 5708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5709,
        name_th: "แม่สาย",
        name_en: "Mae Sai",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 570901,
            zip_code: 57130,
            name_th: "แม่สาย",
            name_en: "Mae Sai",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570902,
            zip_code: 57220,
            name_th: "ห้วยไคร้",
            name_en: "Huai Khrai",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570903,
            zip_code: 57130,
            name_th: "เกาะช้าง",
            name_en: "Ko Chang",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570904,
            zip_code: 57130,
            name_th: "โป่งผา",
            name_en: "Pong Pha",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570905,
            zip_code: 57130,
            name_th: "ศรีเมืองชุม",
            name_en: "Si Mueang Chum",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570906,
            zip_code: 57130,
            name_th: "เวียงพางคำ",
            name_en: "Wiang Phang Kham",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570908,
            zip_code: 57220,
            name_th: "บ้านด้าย",
            name_en: "Ban Dai",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 570909,
            zip_code: 57130,
            name_th: "โป่งงาม",
            name_en: "Pong Ngam",
            amphure_id: 5709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5710,
        name_th: "แม่สรวย",
        name_en: "Mae Suai",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571001,
            zip_code: 57180,
            name_th: "แม่สรวย",
            name_en: "Mae Suai",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571002,
            zip_code: 57180,
            name_th: "ป่าแดด",
            name_en: "Pa Daet",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571003,
            zip_code: 57180,
            name_th: "แม่พริก",
            name_en: "Mae Phrik",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571004,
            zip_code: 57180,
            name_th: "ศรีถ้อย",
            name_en: "Si Thoi",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571005,
            zip_code: 57180,
            name_th: "ท่าก๊อ",
            name_en: "Tha Ko",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571006,
            zip_code: 57180,
            name_th: "วาวี",
            name_en: "Wawi",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571007,
            zip_code: 57180,
            name_th: "เจดีย์หลวง",
            name_en: "Chedi Luang",
            amphure_id: 5710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5711,
        name_th: "เวียงป่าเป้า",
        name_en: "Wiang Pa Pao",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571101,
            zip_code: 57170,
            name_th: "สันสลี",
            name_en: "San Sali",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571102,
            zip_code: 57170,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571103,
            zip_code: 57170,
            name_th: "บ้านโป่ง",
            name_en: "Ban Pong",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571104,
            zip_code: 57170,
            name_th: "ป่างิ้ว",
            name_en: "Pa Ngio",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571105,
            zip_code: 57260,
            name_th: "เวียงกาหลง",
            name_en: "Wiang Kalong",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571106,
            zip_code: 57260,
            name_th: "แม่เจดีย์",
            name_en: "Mae Chedi",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571107,
            zip_code: 57260,
            name_th: "แม่เจดีย์ใหม่",
            name_en: "Mae Chedi Mai",
            amphure_id: 5711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5712,
        name_th: "พญาเม็งราย",
        name_en: "Phaya Mengrai",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571201,
            zip_code: 57290,
            name_th: "แม่เปา",
            name_en: "Mae Pao",
            amphure_id: 5712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571202,
            zip_code: 57290,
            name_th: "แม่ต๋ำ",
            name_en: "Mae Tam",
            amphure_id: 5712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571203,
            zip_code: 57290,
            name_th: "ไม้ยา",
            name_en: "Mai Ya",
            amphure_id: 5712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571204,
            zip_code: 57290,
            name_th: "เม็งราย",
            name_en: "Mengrai",
            amphure_id: 5712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571205,
            zip_code: 57290,
            name_th: "ตาดควัน",
            name_en: "Tat Khwan",
            amphure_id: 5712,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5713,
        name_th: "เวียงแก่น",
        name_en: "Wiang Kaen",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571301,
            zip_code: 57310,
            name_th: "ม่วงยาย",
            name_en: "Muang Yai",
            amphure_id: 5713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571302,
            zip_code: 57310,
            name_th: "ปอ",
            name_en: "Por",
            amphure_id: 5713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571303,
            zip_code: 57310,
            name_th: "หล่ายงาว",
            name_en: "Lai Ngao",
            amphure_id: 5713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571304,
            zip_code: 57310,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 5713,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5714,
        name_th: "ขุนตาล",
        name_en: "Khun Tan",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571401,
            zip_code: 57340,
            name_th: "ต้า",
            name_en: "Ta",
            amphure_id: 5714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571402,
            zip_code: 57340,
            name_th: "ป่าตาล",
            name_en: "Pa Tan",
            amphure_id: 5714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571403,
            zip_code: 57340,
            name_th: "ยางฮอม",
            name_en: "Yang Hom",
            amphure_id: 5714,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5715,
        name_th: "แม่ฟ้าหลวง",
        name_en: "Mae Fa Luang",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571501,
            zip_code: 57240,
            name_th: "เทอดไทย",
            name_en: "Thoet Thai",
            amphure_id: 5715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571502,
            zip_code: 57110,
            name_th: "แม่สลองใน",
            name_en: "Mae Salong Nai",
            amphure_id: 5715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571503,
            zip_code: 57110,
            name_th: "แม่สลองนอก",
            name_en: "Mae Salong Nok",
            amphure_id: 5715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571504,
            zip_code: 57240,
            name_th: "แม่ฟ้าหลวง",
            name_en: "Mae Fa Luang",
            amphure_id: 5715,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5716,
        name_th: "แม่ลาว",
        name_en: "Mae Lao",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571601,
            zip_code: 57250,
            name_th: "ดงมะดะ",
            name_en: "Dong Mada",
            amphure_id: 5716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571602,
            zip_code: 57250,
            name_th: "จอมหมอกแก้ว",
            name_en: "Chom Mok Kaeo",
            amphure_id: 5716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571603,
            zip_code: 57250,
            name_th: "บัวสลี",
            name_en: "Bua Sali",
            amphure_id: 5716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571604,
            zip_code: 57250,
            name_th: "ป่าก่อดำ",
            name_en: "Pa Ko Dam",
            amphure_id: 5716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571605,
            zip_code: 57000,
            name_th: "โป่งแพร่",
            name_en: "Pong Phrae",
            amphure_id: 5716,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5717,
        name_th: "เวียงเชียงรุ้ง",
        name_en: "Wiang Chiang Rung",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571701,
            zip_code: 57210,
            name_th: "ทุ่งก่อ",
            name_en: "Thung Ko",
            amphure_id: 5717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571702,
            zip_code: 57210,
            name_th: "ดงมหาวัน",
            name_en: "Dong Maha Wan",
            amphure_id: 5717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571703,
            zip_code: 57210,
            name_th: "ป่าซาง",
            name_en: "Pa Sang",
            amphure_id: 5717,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5718,
        name_th: "ดอยหลวง",
        name_en: "Doi Luang",
        province_id: 45,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 571801,
            zip_code: 57110,
            name_th: "ปงน้อย",
            name_en: "Pong Noi",
            amphure_id: 5718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571802,
            zip_code: 57110,
            name_th: "โชคชัย",
            name_en: "Chok Chai",
            amphure_id: 5718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 571803,
            zip_code: 57110,
            name_th: "หนองป่าก่อ",
            name_en: "Nong Pa Ko",
            amphure_id: 5718,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 46,
    name_th: "แม่ฮ่องสอน",
    name_en: "Mae Hong Son",
    geography_id: 1,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 5801,
        name_th: "เมืองแม่ฮ่องสอน",
        name_en: "Mueang Mae Hong Son",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580101,
            zip_code: 58000,
            name_th: "จองคำ",
            name_en: "Chong Kham",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580102,
            zip_code: 58000,
            name_th: "ห้วยโป่ง",
            name_en: "Huai Pong",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580103,
            zip_code: 58000,
            name_th: "ผาบ่อง",
            name_en: "Pha Bong",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580104,
            zip_code: 58000,
            name_th: "ปางหมู",
            name_en: "Pang Mu",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580105,
            zip_code: 58000,
            name_th: "หมอกจำแป่",
            name_en: "Mok Champae",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580106,
            zip_code: 58000,
            name_th: "ห้วยผา",
            name_en: "Huai Pha",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580109,
            zip_code: 58000,
            name_th: "ห้วยปูลิง",
            name_en: "Huai Pu Ling",
            amphure_id: 5801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5802,
        name_th: "ขุนยวม",
        name_en: "Khun Yuam",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580201,
            zip_code: 58140,
            name_th: "ขุนยวม",
            name_en: "Khun Yuam",
            amphure_id: 5802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580202,
            zip_code: 58140,
            name_th: "แม่เงา",
            name_en: "Mae Ngao",
            amphure_id: 5802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580203,
            zip_code: 58140,
            name_th: "เมืองปอน",
            name_en: "Mueang Pon",
            amphure_id: 5802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580204,
            zip_code: 58140,
            name_th: "แม่ยวมน้อย",
            name_en: "Mae Yuam Noi",
            amphure_id: 5802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580205,
            zip_code: 58140,
            name_th: "แม่กิ๊",
            name_en: "Mae Ki",
            amphure_id: 5802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580206,
            zip_code: 58140,
            name_th: "แม่อูคอ",
            name_en: "Mae Uo Kor",
            amphure_id: 5802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5803,
        name_th: "ปาย",
        name_en: "Pai",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580301,
            zip_code: 58130,
            name_th: "เวียงใต้",
            name_en: "Wiang Tai",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580302,
            zip_code: 58130,
            name_th: "เวียงเหนือ",
            name_en: "Wiang Nuea",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580303,
            zip_code: 58130,
            name_th: "แม่นาเติง",
            name_en: "Mae Na Toeng",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580304,
            zip_code: 58130,
            name_th: "แม่ฮี้",
            name_en: "Mae Hi",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580305,
            zip_code: 58130,
            name_th: "ทุ่งยาว",
            name_en: "Thung Yao",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580306,
            zip_code: 58130,
            name_th: "เมืองแปง",
            name_en: "Mueang Paeng",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580307,
            zip_code: 58130,
            name_th: "โป่งสา",
            name_en: "Pong Sa",
            amphure_id: 5803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5804,
        name_th: "แม่สะเรียง",
        name_en: "Mae Sariang",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580401,
            zip_code: 58110,
            name_th: "บ้านกาศ",
            name_en: "Ban Kat",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580402,
            zip_code: 58110,
            name_th: "แม่สะเรียง",
            name_en: "Mae Sariang",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580403,
            zip_code: 58110,
            name_th: "แม่คง",
            name_en: "Mae Khong",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580404,
            zip_code: 58110,
            name_th: "แม่เหาะ",
            name_en: "Mae Ho",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580405,
            zip_code: 58110,
            name_th: "แม่ยวม",
            name_en: "Mae Yuam",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580406,
            zip_code: 58110,
            name_th: "เสาหิน",
            name_en: "Sao Hin",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580408,
            zip_code: 58110,
            name_th: "ป่าแป๋",
            name_en: "Pa Pae",
            amphure_id: 5804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5805,
        name_th: "แม่ลาน้อย",
        name_en: "Mae La Noi",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580501,
            zip_code: 58120,
            name_th: "แม่ลาน้อย",
            name_en: "Mae La Noi",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580502,
            zip_code: 58120,
            name_th: "แม่ลาหลวง",
            name_en: "Mae La Luang",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580503,
            zip_code: 58120,
            name_th: "ท่าผาปุ้ม",
            name_en: "Tha Pha Pum",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580504,
            zip_code: 58120,
            name_th: "แม่โถ",
            name_en: "Mae Tho",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580505,
            zip_code: 58120,
            name_th: "ห้วยห้อม",
            name_en: "Huai Hom",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580506,
            zip_code: 58120,
            name_th: "แม่นาจาง",
            name_en: "Mae Na Chang",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580507,
            zip_code: 58120,
            name_th: "สันติคีรี",
            name_en: "Santi Khiri",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580508,
            zip_code: 58120,
            name_th: "ขุนแม่ลาน้อย",
            name_en: "Khun Mae La Noi",
            amphure_id: 5805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5806,
        name_th: "สบเมย",
        name_en: "Sop Moei",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580601,
            zip_code: 58110,
            name_th: "สบเมย",
            name_en: "Sop Moei",
            amphure_id: 5806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580602,
            zip_code: 58110,
            name_th: "แม่คะตวน",
            name_en: "Mae Khatuan",
            amphure_id: 5806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580603,
            zip_code: 58110,
            name_th: "กองก๋อย",
            name_en: "Kong Koi",
            amphure_id: 5806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580604,
            zip_code: 58110,
            name_th: "แม่สวด",
            name_en: "Mae Suat",
            amphure_id: 5806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580605,
            zip_code: 58110,
            name_th: "ป่าโปง",
            name_en: "Pa Pong",
            amphure_id: 5806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580606,
            zip_code: 58110,
            name_th: "แม่สามแลบ",
            name_en: "Mae Sam Laep",
            amphure_id: 5806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 5807,
        name_th: "ปางมะผ้า",
        name_en: "Pang Mapha",
        province_id: 46,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 580701,
            zip_code: 58150,
            name_th: "สบป่อง",
            name_en: "Sop Pong",
            amphure_id: 5807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580702,
            zip_code: 58150,
            name_th: "ปางมะผ้า",
            name_en: "Pang Mapha",
            amphure_id: 5807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580703,
            zip_code: 58150,
            name_th: "ถ้ำลอด",
            name_en: "Tham Lot",
            amphure_id: 5807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 580704,
            zip_code: 58150,
            name_th: "นาปู่ป้อม",
            name_en: "Na Pu Pom",
            amphure_id: 5807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 47,
    name_th: "นครสวรรค์",
    name_en: "Nakhon Sawan",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6001,
        name_th: "เมืองนครสวรรค์",
        name_en: "Mueang Nakhon Sawan",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600101,
            zip_code: 60000,
            name_th: "ปากน้ำโพ",
            name_en: "Paknam Pho",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600102,
            zip_code: 60000,
            name_th: "กลางแดด",
            name_en: "Klang Daet",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600103,
            zip_code: 60000,
            name_th: "เกรียงไกร",
            name_en: "Kriangkrai",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600104,
            zip_code: 60000,
            name_th: "แควใหญ่",
            name_en: "Khwae Yai",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600105,
            zip_code: 60000,
            name_th: "ตะเคียนเลื่อน",
            name_en: "Takhian Luean",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600106,
            zip_code: 60000,
            name_th: "นครสวรรค์ตก",
            name_en: "Nakhon Sawan Tok",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600107,
            zip_code: 60000,
            name_th: "นครสวรรค์ออก",
            name_en: "Nakhon Sawan Ok",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600108,
            zip_code: 60000,
            name_th: "บางพระหลวง",
            name_en: "Bang Phra Luang",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600109,
            zip_code: 60000,
            name_th: "บางม่วง",
            name_en: "Bang Muang",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600110,
            zip_code: 60000,
            name_th: "บ้านมะเกลือ",
            name_en: "Ban Makluea",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600111,
            zip_code: 60000,
            name_th: "บ้านแก่ง",
            name_en: "Ban Kaeng",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600112,
            zip_code: 60000,
            name_th: "พระนอน",
            name_en: "Phra Non",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600113,
            zip_code: 60000,
            name_th: "วัดไทร",
            name_en: "Wat Sai",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600114,
            zip_code: 60240,
            name_th: "หนองกรด",
            name_en: "Nong Krot",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600115,
            zip_code: 60240,
            name_th: "หนองกระโดน",
            name_en: "Nong Kradon",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600116,
            zip_code: 60000,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600117,
            zip_code: 60000,
            name_th: "บึงเสนาท",
            name_en: "Bueng Senat",
            amphure_id: 6001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6002,
        name_th: "โกรกพระ",
        name_en: "Krok Phra",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600201,
            zip_code: 60170,
            name_th: "โกรกพระ",
            name_en: "Krok Phra",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600202,
            zip_code: 60170,
            name_th: "ยางตาล",
            name_en: "Yang Tan",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600203,
            zip_code: 60170,
            name_th: "บางมะฝ่อ",
            name_en: "Bang Mafo",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600204,
            zip_code: 60170,
            name_th: "บางประมุง",
            name_en: "Bang Pramung",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600205,
            zip_code: 60170,
            name_th: "นากลาง",
            name_en: "Na Klang",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600206,
            zip_code: 60170,
            name_th: "ศาลาแดง",
            name_en: "Sala Daeng",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600207,
            zip_code: 60170,
            name_th: "เนินกว้าว",
            name_en: "Noen Kwao",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600208,
            zip_code: 60170,
            name_th: "เนินศาลา",
            name_en: "Noen Sala",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600209,
            zip_code: 60170,
            name_th: "หาดสูง",
            name_en: "Hat Sung",
            amphure_id: 6002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6003,
        name_th: "ชุมแสง",
        name_en: "Chum Saeng",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600301,
            zip_code: 60120,
            name_th: "ชุมแสง",
            name_en: "Chum Saeng",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600302,
            zip_code: 60250,
            name_th: "ทับกฤช",
            name_en: "Thap Krit",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600303,
            zip_code: 60120,
            name_th: "พิกุล",
            name_en: "Phikun",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600304,
            zip_code: 60120,
            name_th: "เกยไชย",
            name_en: "Koei Chai",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600305,
            zip_code: 60120,
            name_th: "ท่าไม้",
            name_en: "Tha Mai",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600306,
            zip_code: 60120,
            name_th: "บางเคียน",
            name_en: "Bang Khian",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600307,
            zip_code: 60120,
            name_th: "หนองกระเจา",
            name_en: "Nong Krachao",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600308,
            zip_code: 60250,
            name_th: "พันลาน",
            name_en: "Phan Lan",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600309,
            zip_code: 60120,
            name_th: "โคกหม้อ",
            name_en: "Khok Mo",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600310,
            zip_code: 60120,
            name_th: "ไผ่สิงห์",
            name_en: "Phai Sing",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600311,
            zip_code: 60120,
            name_th: "ฆะมัง",
            name_en: "Khamang",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600312,
            zip_code: 60250,
            name_th: "ทับกฤชใต้",
            name_en: "Thap Krit Tai",
            amphure_id: 6003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6004,
        name_th: "หนองบัว",
        name_en: "Nong Bua",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600401,
            zip_code: 60110,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600402,
            zip_code: 60110,
            name_th: "หนองกลับ",
            name_en: "Nong Klap",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600403,
            zip_code: 60110,
            name_th: "ธารทหาร",
            name_en: "Than Thahan",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600404,
            zip_code: 60110,
            name_th: "ห้วยร่วม",
            name_en: "Huai Ruam",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600405,
            zip_code: 60110,
            name_th: "ห้วยถั่วใต้",
            name_en: "Huai Thua Tai",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600406,
            zip_code: 60110,
            name_th: "ห้วยถั่วเหนือ",
            name_en: "Huai Thua Nuea",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600407,
            zip_code: 60110,
            name_th: "ห้วยใหญ่",
            name_en: "Huai Yai",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600408,
            zip_code: 60110,
            name_th: "ทุ่งทอง",
            name_en: "Thung Thong",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600409,
            zip_code: 60110,
            name_th: "วังบ่อ",
            name_en: "Wang Bo",
            amphure_id: 6004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6005,
        name_th: "บรรพตพิสัย",
        name_en: "Banphot Phisai",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600501,
            zip_code: 60180,
            name_th: "ท่างิ้ว",
            name_en: "Tha Ngio",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600502,
            zip_code: 60180,
            name_th: "บางตาหงาย",
            name_en: "Bang Ta Ngai",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600503,
            zip_code: 60180,
            name_th: "หูกวาง",
            name_en: "Hukwang",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600504,
            zip_code: 60180,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600505,
            zip_code: 60180,
            name_th: "บ้านแดน",
            name_en: "Ban Daen",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600506,
            zip_code: 60180,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600507,
            zip_code: 60180,
            name_th: "ตาขีด",
            name_en: "Ta Khit",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600508,
            zip_code: 60180,
            name_th: "ตาสัง",
            name_en: "Ta Sang",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600509,
            zip_code: 60180,
            name_th: "ด่านช้าง",
            name_en: "Dan Chang",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600510,
            zip_code: 60180,
            name_th: "หนองกรด",
            name_en: "Nong Krot",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600511,
            zip_code: 60180,
            name_th: "หนองตางู",
            name_en: "Nong Ta Ngu",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600512,
            zip_code: 60180,
            name_th: "บึงปลาทู",
            name_en: "Bueng Pla Thu",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600513,
            zip_code: 60180,
            name_th: "เจริญผล",
            name_en: "Charoen Phon",
            amphure_id: 6005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6006,
        name_th: "เก้าเลี้ยว",
        name_en: "Kao Liao",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600601,
            zip_code: 60230,
            name_th: "มหาโพธิ",
            name_en: "Maha Phot",
            amphure_id: 6006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600602,
            zip_code: 60230,
            name_th: "เก้าเลี้ยว",
            name_en: "Kao Liao",
            amphure_id: 6006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600603,
            zip_code: 60230,
            name_th: "หนองเต่า",
            name_en: "Nong Tao",
            amphure_id: 6006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600604,
            zip_code: 60230,
            name_th: "เขาดิน",
            name_en: "Khao Din",
            amphure_id: 6006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600605,
            zip_code: 60230,
            name_th: "หัวดง",
            name_en: "Hua Dong",
            amphure_id: 6006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6007,
        name_th: "ตาคลี",
        name_en: "Takhli",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600701,
            zip_code: 60140,
            name_th: "ตาคลี",
            name_en: "Takhli",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600702,
            zip_code: 60210,
            name_th: "ช่องแค",
            name_en: "Chong Khae",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600703,
            zip_code: 60260,
            name_th: "จันเสน",
            name_en: "Chan Sen",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600704,
            zip_code: 60210,
            name_th: "ห้วยหอม",
            name_en: "Huai Hom",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600705,
            zip_code: 60140,
            name_th: "หัวหวาย",
            name_en: "Hua Wai",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600706,
            zip_code: 60140,
            name_th: "หนองโพ",
            name_en: "Nong Pho",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600707,
            zip_code: 60140,
            name_th: "หนองหม้อ",
            name_en: "Nong Mo",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600708,
            zip_code: 60210,
            name_th: "สร้อยทอง",
            name_en: "Soi Thong",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600709,
            zip_code: 60260,
            name_th: "ลาดทิพรส",
            name_en: "Lat Thippharot",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600710,
            zip_code: 60210,
            name_th: "พรหมนิมิต",
            name_en: "Phrom Nimit",
            amphure_id: 6007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6008,
        name_th: "ท่าตะโก",
        name_en: "Takhli",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600801,
            zip_code: 60160,
            name_th: "ท่าตะโก",
            name_en: "Tha Tako",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600802,
            zip_code: 60160,
            name_th: "พนมรอก",
            name_en: "Phanom Rok",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600803,
            zip_code: 60160,
            name_th: "หัวถนน",
            name_en: "Hua Thanon",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600804,
            zip_code: 60160,
            name_th: "สายลำโพง",
            name_en: "Sai Lamphong",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600805,
            zip_code: 60160,
            name_th: "วังมหากร",
            name_en: "Wang Mahakon",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600806,
            zip_code: 60160,
            name_th: "ดอนคา",
            name_en: "Don Kha",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600807,
            zip_code: 60160,
            name_th: "ทำนบ",
            name_en: "Thamnop",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600808,
            zip_code: 60160,
            name_th: "วังใหญ่",
            name_en: "Wang Yai",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600809,
            zip_code: 60160,
            name_th: "พนมเศษ",
            name_en: "Phanom Set",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600810,
            zip_code: 60160,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 6008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6009,
        name_th: "ไพศาลี",
        name_en: "Phaisali",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 600901,
            zip_code: 60220,
            name_th: "โคกเดื่อ",
            name_en: "Khok Duea",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600902,
            zip_code: 60220,
            name_th: "สำโรงชัย",
            name_en: "Samrong Chai",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600903,
            zip_code: 60220,
            name_th: "วังน้ำลัด",
            name_en: "Wang Nam Lat",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600904,
            zip_code: 60220,
            name_th: "ตะคร้อ",
            name_en: "Takhro",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600905,
            zip_code: 60220,
            name_th: "โพธิ์ประสาท",
            name_en: "Pho Prasat",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600906,
            zip_code: 60220,
            name_th: "วังข่อย",
            name_en: "Wang Khoi",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600907,
            zip_code: 60220,
            name_th: "นาขอม",
            name_en: "Na Khom",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 600908,
            zip_code: 60220,
            name_th: "ไพศาลี",
            name_en: "Phaisali",
            amphure_id: 6009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6010,
        name_th: "พยุหะคีรี",
        name_en: "Phayuha Khiri",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 601001,
            zip_code: 60130,
            name_th: "พยุหะ",
            name_en: "Phayuha",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601002,
            zip_code: 60130,
            name_th: "เนินมะกอก",
            name_en: "Noen Makok",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601003,
            zip_code: 60130,
            name_th: "นิคมเขาบ่อแก้ว",
            name_en: "Nikhom Khao Bo Kaeo",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601004,
            zip_code: 60130,
            name_th: "ม่วงหัก",
            name_en: "Muang Hak",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601005,
            zip_code: 60130,
            name_th: "ยางขาว",
            name_en: "Yang Khao",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601006,
            zip_code: 60130,
            name_th: "ย่านมัทรี",
            name_en: "Yan Matsi",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601007,
            zip_code: 60130,
            name_th: "เขาทอง",
            name_en: "Khao Thong",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601008,
            zip_code: 60130,
            name_th: "ท่าน้ำอ้อย",
            name_en: "Tha Nam Oi",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601009,
            zip_code: 60130,
            name_th: "น้ำทรง",
            name_en: "Nam Song",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601010,
            zip_code: 60130,
            name_th: "เขากะลา",
            name_en: "Khao Kala",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601011,
            zip_code: 60130,
            name_th: "สระทะเล",
            name_en: "Sa Thale",
            amphure_id: 6010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6011,
        name_th: "ลาดยาว",
        name_en: "Phayuha Khiri",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 601101,
            zip_code: 60150,
            name_th: "ลาดยาว",
            name_en: "Lat Yao",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601102,
            zip_code: 60150,
            name_th: "ห้วยน้ำหอม",
            name_en: "Huai Nam Hom",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601103,
            zip_code: 60150,
            name_th: "วังม้า",
            name_en: "Wang Ma",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601104,
            zip_code: 60150,
            name_th: "วังเมือง",
            name_en: "Wang Mueang",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601105,
            zip_code: 60150,
            name_th: "สร้อยละคร",
            name_en: "Soi Lakhon",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601106,
            zip_code: 60150,
            name_th: "มาบแก",
            name_en: "Map Kae",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601107,
            zip_code: 60150,
            name_th: "หนองยาว",
            name_en: "Nong Yao",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601108,
            zip_code: 60150,
            name_th: "หนองนมวัว",
            name_en: "Nong Nom Wua",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601109,
            zip_code: 60150,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601110,
            zip_code: 60150,
            name_th: "เนินขี้เหล็ก",
            name_en: "Noen Khilek",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601116,
            zip_code: 60150,
            name_th: "ศาลเจ้าไก่ต่อ",
            name_en: "San Chao Kai To",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601117,
            zip_code: 60150,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 6011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6012,
        name_th: "ตากฟ้า",
        name_en: "Tak Fa",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 601201,
            zip_code: 60190,
            name_th: "ตากฟ้า",
            name_en: "Tak Fa",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601202,
            zip_code: 60190,
            name_th: "ลำพยนต์",
            name_en: "Lam Phayon",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601203,
            zip_code: 60190,
            name_th: "สุขสำราญ",
            name_en: "Suk Samran",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601204,
            zip_code: 60190,
            name_th: "หนองพิกุล",
            name_en: "Nong Phikun",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601205,
            zip_code: 60190,
            name_th: "พุนกยูง",
            name_en: "Phu Nok Yung",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601206,
            zip_code: 60190,
            name_th: "อุดมธัญญา",
            name_en: "Udom Thanya",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601207,
            zip_code: 60190,
            name_th: "เขาชายธง",
            name_en: "Khao Chai Thong",
            amphure_id: 6012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6013,
        name_th: "แม่วงก์",
        name_en: "Mae Wong",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 601301,
            zip_code: 60150,
            name_th: "แม่วงก์",
            name_en: "Mae Wong",
            amphure_id: 6013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601303,
            zip_code: 60150,
            name_th: "แม่เล่ย์",
            name_en: "Mae Le",
            amphure_id: 6013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601304,
            zip_code: 60150,
            name_th: "วังซ่าน",
            name_en: "Wang San",
            amphure_id: 6013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601305,
            zip_code: 60150,
            name_th: "เขาชนกัน",
            name_en: "Khao Chon Kan",
            amphure_id: 6013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6014,
        name_th: "แม่เปิน",
        name_en: "Mae Poen",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 601401,
            zip_code: 60150,
            name_th: "แม่เปิน",
            name_en: "Mae Poen)",
            amphure_id: 6014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6015,
        name_th: "ชุมตาบง",
        name_en: "Chum Ta Bong",
        province_id: 47,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 601501,
            zip_code: 60150,
            name_th: "ชุมตาบง",
            name_en: "Chum Ta Bong",
            amphure_id: 6015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 601502,
            zip_code: 60150,
            name_th: "ปางสวรรค์",
            name_en: "Pang Sawan",
            amphure_id: 6015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 48,
    name_th: "อุทัยธานี",
    name_en: "Uthai Thani",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6101,
        name_th: "เมืองอุทัยธานี",
        name_en: "Mueang Uthai Thani",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610101,
            zip_code: 61000,
            name_th: "อุทัยใหม่",
            name_en: "Uthai Mai",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610102,
            zip_code: 61000,
            name_th: "น้ำซึม",
            name_en: "Nam Suem",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610103,
            zip_code: 61000,
            name_th: "สะแกกรัง",
            name_en: "Sakae Krang",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610104,
            zip_code: 61000,
            name_th: "ดอนขวาง",
            name_en: "Don Khwang",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610105,
            zip_code: 61000,
            name_th: "หาดทนง",
            name_en: "Hat Thanong",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610106,
            zip_code: 61000,
            name_th: "เกาะเทโพ",
            name_en: "Ko Thepho",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610107,
            zip_code: 61000,
            name_th: "ท่าซุง",
            name_en: "Tha Sung",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610108,
            zip_code: 61000,
            name_th: "หนองแก",
            name_en: "Nong Kae",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610109,
            zip_code: 61000,
            name_th: "โนนเหล็ก",
            name_en: "Non Lek",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610110,
            zip_code: 61000,
            name_th: "หนองเต่า",
            name_en: "Nong Tao",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610111,
            zip_code: 61000,
            name_th: "หนองไผ่แบน",
            name_en: "Nong Phai Baen",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610112,
            zip_code: 61000,
            name_th: "หนองพังค่า",
            name_en: "Nong Phang Kha",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610113,
            zip_code: 61000,
            name_th: "ทุ่งใหญ่",
            name_en: "Thung Yai",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610114,
            zip_code: 61000,
            name_th: "เนินแจง",
            name_en: "Noen Chaeng",
            amphure_id: 6101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6102,
        name_th: "ทัพทัน",
        name_en: "Thap Than",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610201,
            zip_code: 61120,
            name_th: "ทัพทัน",
            name_en: "Thap Than",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610202,
            zip_code: 61120,
            name_th: "ทุ่งนาไทย",
            name_en: "Thung Na Thai",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610203,
            zip_code: 61120,
            name_th: "เขาขี้ฝอย",
            name_en: "Khao Khi Foi",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610204,
            zip_code: 61120,
            name_th: "หนองหญ้าปล้อง",
            name_en: "Nong Ya Plong",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610205,
            zip_code: 61120,
            name_th: "โคกหม้อ",
            name_en: "Khok Mo",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610206,
            zip_code: 61120,
            name_th: "หนองยายดา",
            name_en: "Nong Yai Da",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610207,
            zip_code: 61120,
            name_th: "หนองกลางดง",
            name_en: "Nong Klang Dong",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610208,
            zip_code: 61120,
            name_th: "หนองกระทุ่ม",
            name_en: "Nong Krathum",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610209,
            zip_code: 61120,
            name_th: "หนองสระ",
            name_en: "Nong Sa",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610210,
            zip_code: 61120,
            name_th: "ตลุกดู่",
            name_en: "Taluk Du",
            amphure_id: 6102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6103,
        name_th: "สว่างอารมณ์",
        name_en: "Sawang Arom",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610301,
            zip_code: 61150,
            name_th: "สว่างอารมณ์",
            name_en: "Sawang Arom",
            amphure_id: 6103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610302,
            zip_code: 61150,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 6103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610303,
            zip_code: 61150,
            name_th: "พลวงสองนาง",
            name_en: "Phluang Song Nang",
            amphure_id: 6103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610304,
            zip_code: 61150,
            name_th: "ไผ่เขียว",
            name_en: "Phai Khiao",
            amphure_id: 6103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610305,
            zip_code: 61150,
            name_th: "บ่อยาง",
            name_en: "Bor Yang",
            amphure_id: 6103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6104,
        name_th: "หนองฉาง",
        name_en: "Nong Chang",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610401,
            zip_code: 61110,
            name_th: "หนองฉาง",
            name_en: "Nong Chang",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610402,
            zip_code: 61110,
            name_th: "หนองยาง",
            name_en: "Nong Yang",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610403,
            zip_code: 61110,
            name_th: "หนองนางนวล",
            name_en: "Nong Nang Nuan",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610404,
            zip_code: 61110,
            name_th: "หนองสรวง",
            name_en: "Nong Suang",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610405,
            zip_code: 61110,
            name_th: "บ้านเก่า",
            name_en: "Ban Kao",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610406,
            zip_code: 61110,
            name_th: "อุทัยเก่า",
            name_en: "Uthai Kao",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610407,
            zip_code: 61110,
            name_th: "ทุ่งโพ",
            name_en: "Thung Pho",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610408,
            zip_code: 61110,
            name_th: "ทุ่งพง",
            name_en: "Thung Phong",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610409,
            zip_code: 61170,
            name_th: "เขาบางแกรก",
            name_en: "Khao Bang Kraek",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610410,
            zip_code: 61110,
            name_th: "เขากวางทอง",
            name_en: "Khao Kwang Thong",
            amphure_id: 6104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6105,
        name_th: "หนองขาหย่าง",
        name_en: "Nong Khayang",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610501,
            zip_code: 61130,
            name_th: "หนองขาหย่าง",
            name_en: "Nong Khayang",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610502,
            zip_code: 61130,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610503,
            zip_code: 61130,
            name_th: "ดอนกลอย",
            name_en: "Don Kloi",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610504,
            zip_code: 61130,
            name_th: "ห้วยรอบ",
            name_en: "Huai Rop",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610505,
            zip_code: 61130,
            name_th: "ทุ่งพึ่ง",
            name_en: "Thung Phueng",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610506,
            zip_code: 61130,
            name_th: "ท่าโพ",
            name_en: "Tha Pho",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610507,
            zip_code: 61130,
            name_th: "หมกแถว",
            name_en: "Mok Thaeo",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610508,
            zip_code: 61130,
            name_th: "หลุมเข้า",
            name_en: "Lum Khao",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610509,
            zip_code: 61130,
            name_th: "ดงขวาง",
            name_en: "Dong Kwang",
            amphure_id: 6105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6106,
        name_th: "บ้านไร่",
        name_en: "Ban Rai",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610601,
            zip_code: 61140,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610602,
            zip_code: 61140,
            name_th: "ทัพหลวง",
            name_en: "Thap Luang",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610603,
            zip_code: 61140,
            name_th: "ห้วยแห้ง",
            name_en: "Huai Haeng",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610604,
            zip_code: 61140,
            name_th: "คอกควาย",
            name_en: "Khok Khwai",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610605,
            zip_code: 61180,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610606,
            zip_code: 61180,
            name_th: "เมืองการุ้ง",
            name_en: "Mueang Ka Rung",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610607,
            zip_code: 61140,
            name_th: "แก่นมะกรูด",
            name_en: "Kaen Makrut",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610609,
            zip_code: 61180,
            name_th: "หนองจอก",
            name_en: "Nong Chok",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610610,
            zip_code: 61180,
            name_th: "หูช้าง",
            name_en: "Hu Chang",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610611,
            zip_code: 61140,
            name_th: "บ้านบึง",
            name_en: "Ban Bueng",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610612,
            zip_code: 61180,
            name_th: "บ้านใหม่คลองเคียน",
            name_en: "Ban Mai Khlong Khian",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610613,
            zip_code: 61180,
            name_th: "หนองบ่มกล้วย",
            name_en: "Nong Bom Kluai",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610614,
            zip_code: 61140,
            name_th: "เจ้าวัด",
            name_en: "Chao Wat",
            amphure_id: 6106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6107,
        name_th: "ลานสัก",
        name_en: "Lan Sak",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610701,
            zip_code: 61160,
            name_th: "ลานสัก",
            name_en: "Lan Sak",
            amphure_id: 6107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610702,
            zip_code: 61160,
            name_th: "ประดู่ยืน",
            name_en: "Pradu Yuen",
            amphure_id: 6107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610703,
            zip_code: 61160,
            name_th: "ป่าอ้อ",
            name_en: "Pa O",
            amphure_id: 6107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610704,
            zip_code: 61160,
            name_th: "ระบำ",
            name_en: "Rabam",
            amphure_id: 6107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610705,
            zip_code: 61160,
            name_th: "น้ำรอบ",
            name_en: "Nam Rop",
            amphure_id: 6107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610706,
            zip_code: 61160,
            name_th: "ทุ่งนางาม",
            name_en: "Thung Na Ngam",
            amphure_id: 6107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6108,
        name_th: "ห้วยคต",
        name_en: "Huai Khot",
        province_id: 48,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 610801,
            zip_code: 61170,
            name_th: "สุขฤทัย",
            name_en: "Suk Ruethai",
            amphure_id: 6108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610802,
            zip_code: 61170,
            name_th: "ทองหลาง",
            name_en: "Thonglang",
            amphure_id: 6108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 610803,
            zip_code: 61170,
            name_th: "ห้วยคต",
            name_en: "Huai Khot",
            amphure_id: 6108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 49,
    name_th: "กำแพงเพชร",
    name_en: "Kamphaeng Phet",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6201,
        name_th: "เมืองกำแพงเพชร",
        name_en: "Mueang Kamphaeng Phet",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620101,
            zip_code: 62000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620102,
            zip_code: 62160,
            name_th: "ไตรตรึงษ์",
            name_en: "Trai Trueng",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620103,
            zip_code: 62000,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620104,
            zip_code: 62000,
            name_th: "นาบ่อคำ",
            name_en: "Na Bo Kham",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620105,
            zip_code: 62000,
            name_th: "นครชุม",
            name_en: "Nakhon Chum",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620106,
            zip_code: 62000,
            name_th: "ทรงธรรม",
            name_en: "Song Tham",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620107,
            zip_code: 62000,
            name_th: "ลานดอกไม้",
            name_en: "Lan Dokmai",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620110,
            zip_code: 62000,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620111,
            zip_code: 62000,
            name_th: "คณฑี",
            name_en: "Khonthi",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620112,
            zip_code: 62000,
            name_th: "นิคมทุ่งโพธิ์ทะเล",
            name_en: "Nikhom Thung Pho Thale",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620113,
            zip_code: 62000,
            name_th: "เทพนคร",
            name_en: "Thep Nakhon",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620114,
            zip_code: 62000,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620115,
            zip_code: 62000,
            name_th: "ท่าขุนราม",
            name_en: "Tha Khun Ram",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620117,
            zip_code: 62000,
            name_th: "คลองแม่ลาย",
            name_en: "Khlong Mae Lai",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620118,
            zip_code: 62160,
            name_th: "ธำมรงค์",
            name_en: "Thammarong",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620119,
            zip_code: 62000,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 6201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6202,
        name_th: "ไทรงาม",
        name_en: "Sai Ngam",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620201,
            zip_code: 62150,
            name_th: "ไทรงาม",
            name_en: "Sai Ngam",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620202,
            zip_code: 62150,
            name_th: "หนองคล้า",
            name_en: "Nong Khla",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620203,
            zip_code: 62150,
            name_th: "หนองทอง",
            name_en: "Nong Thong",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620204,
            zip_code: 62150,
            name_th: "หนองไม้กอง",
            name_en: "Nong Mai Kong",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620205,
            zip_code: 62150,
            name_th: "มหาชัย",
            name_en: "Maha Chai",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620206,
            zip_code: 62150,
            name_th: "พานทอง",
            name_en: "Phan Thong",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620207,
            zip_code: 62150,
            name_th: "หนองแม่แตง",
            name_en: "Nong Mae Taeng",
            amphure_id: 6202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6203,
        name_th: "คลองลาน",
        name_en: "Khlong Lan",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620301,
            zip_code: 62180,
            name_th: "คลองน้ำไหล",
            name_en: "Khlong Nam Lai",
            amphure_id: 6203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620302,
            zip_code: 62180,
            name_th: "โป่งน้ำร้อน",
            name_en: "Pong Nam Ron",
            amphure_id: 6203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620303,
            zip_code: 62180,
            name_th: "คลองลานพัฒนา",
            name_en: "Khlong Lan Phatthana",
            amphure_id: 6203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620304,
            zip_code: 62180,
            name_th: "สักงาม",
            name_en: "Sak Ngam",
            amphure_id: 6203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6204,
        name_th: "ขาณุวรลักษบุรี",
        name_en: "Khanu Woralaksaburi",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620403,
            zip_code: 62130,
            name_th: "ยางสูง",
            name_en: "Yang Sung",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620404,
            zip_code: 62130,
            name_th: "ป่าพุทรา",
            name_en: "Pa Phutsa",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620405,
            zip_code: 62130,
            name_th: "แสนตอ",
            name_en: "Saen To",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620406,
            zip_code: 62140,
            name_th: "สลกบาตร",
            name_en: "Salok Bat",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620407,
            zip_code: 62140,
            name_th: "บ่อถ้ำ",
            name_en: "Bo Tham",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620408,
            zip_code: 62140,
            name_th: "ดอนแตง",
            name_en: "Don Taeng",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620409,
            zip_code: 62140,
            name_th: "วังชะพลู",
            name_en: "Wang Chaphlu",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620410,
            zip_code: 62140,
            name_th: "โค้งไผ่",
            name_en: "Khong Phai",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620411,
            zip_code: 62140,
            name_th: "ปางมะค่า",
            name_en: "Pang Makha",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620412,
            zip_code: 62140,
            name_th: "วังหามแห",
            name_en: "Wang Ham Hae",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620413,
            zip_code: 62130,
            name_th: "เกาะตาล",
            name_en: "Ko Tan",
            amphure_id: 6204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6205,
        name_th: "คลองขลุง",
        name_en: "Khlong Khlung",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620501,
            zip_code: 62120,
            name_th: "คลองขลุง",
            name_en: "Khlong Khlung",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620502,
            zip_code: 62120,
            name_th: "ท่ามะเขือ",
            name_en: "Tha Makhuea",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620504,
            zip_code: 62120,
            name_th: "ท่าพุทรา",
            name_en: "Tha Phutsa",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620505,
            zip_code: 62120,
            name_th: "แม่ลาด",
            name_en: "Mae Lat",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620506,
            zip_code: 62120,
            name_th: "วังยาง",
            name_en: "Wang Yang",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620507,
            zip_code: 62120,
            name_th: "วังแขม",
            name_en: "Wang Khaem",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620508,
            zip_code: 62120,
            name_th: "หัวถนน",
            name_en: "Hua Thanon",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620509,
            zip_code: 62120,
            name_th: "วังไทร",
            name_en: "Wang Sai",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620513,
            zip_code: 62120,
            name_th: "วังบัว",
            name_en: "Wang Bua",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620516,
            zip_code: 62120,
            name_th: "คลองสมบูรณ์",
            name_en: "Khlong Sombun",
            amphure_id: 6205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6206,
        name_th: "พรานกระต่าย",
        name_en: "Phran Kratai",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620601,
            zip_code: 62110,
            name_th: "พรานกระต่าย",
            name_en: "Phran Kratai",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620602,
            zip_code: 62110,
            name_th: "หนองหัววัว",
            name_en: "Nong Hua Wua",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620603,
            zip_code: 62110,
            name_th: "ท่าไม้",
            name_en: "Tha Mai",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620604,
            zip_code: 62110,
            name_th: "วังควง",
            name_en: "Wang Khuang",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620605,
            zip_code: 62110,
            name_th: "วังตะแบก",
            name_en: "Wang Tabaek",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620606,
            zip_code: 62110,
            name_th: "เขาคีริส",
            name_en: "Khao Khirit",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620607,
            zip_code: 62110,
            name_th: "คุยบ้านโอง",
            name_en: "Khui Ban Ong",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620608,
            zip_code: 62110,
            name_th: "คลองพิไกร",
            name_en: "Khlong Phikrai",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620609,
            zip_code: 62110,
            name_th: "ถ้ำกระต่ายทอง",
            name_en: "Tham Kratai Thong",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620610,
            zip_code: 62110,
            name_th: "ห้วยยั้ง",
            name_en: "Huai Yang",
            amphure_id: 6206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6207,
        name_th: "ลานกระบือ",
        name_en: "Lan Krabue",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620701,
            zip_code: 62170,
            name_th: "ลานกระบือ",
            name_en: "Lan Krabue",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620702,
            zip_code: 62170,
            name_th: "ช่องลม",
            name_en: "Chong Lom",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620703,
            zip_code: 62170,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620704,
            zip_code: 62170,
            name_th: "โนนพลวง",
            name_en: "Non Phluang",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620705,
            zip_code: 62170,
            name_th: "ประชาสุขสันต์",
            name_en: "Pracha Suk San",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620706,
            zip_code: 62170,
            name_th: "บึงทับแรต",
            name_en: "Bueng Thap Raet",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620707,
            zip_code: 62170,
            name_th: "จันทิมา",
            name_en: "Chanthima",
            amphure_id: 6207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6208,
        name_th: "ทรายทองวัฒนา",
        name_en: "Sai Thong Watthana",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620801,
            zip_code: 62190,
            name_th: "ทุ่งทราย",
            name_en: "Thung Sai",
            amphure_id: 6208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620802,
            zip_code: 62190,
            name_th: "ทุ่งทอง",
            name_en: "Thung Thong",
            amphure_id: 6208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620803,
            zip_code: 62190,
            name_th: "ถาวรวัฒนา",
            name_en: "Thawon Watthana",
            amphure_id: 6208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6209,
        name_th: "ปางศิลาทอง",
        name_en: "Pang Sila Thong",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 620901,
            zip_code: 62120,
            name_th: "โพธิ์ทอง",
            name_en: "Pho Thong",
            amphure_id: 6209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620902,
            zip_code: 62120,
            name_th: "หินดาต",
            name_en: "Hin Dat",
            amphure_id: 6209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 620903,
            zip_code: 62120,
            name_th: "ปางตาไว",
            name_en: "Pang Ta Wai",
            amphure_id: 6209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6210,
        name_th: "บึงสามัคคี",
        name_en: "Bueng Samakkhi",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 621001,
            zip_code: 62210,
            name_th: "บึงสามัคคี",
            name_en: "Bueng Samakkhi",
            amphure_id: 6210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 621002,
            zip_code: 62210,
            name_th: "วังชะโอน",
            name_en: "Wang Cha-on",
            amphure_id: 6210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 621003,
            zip_code: 62210,
            name_th: "ระหาน",
            name_en: "Rahan",
            amphure_id: 6210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 621004,
            zip_code: 62210,
            name_th: "เทพนิมิต",
            name_en: "Thep Nimit",
            amphure_id: 6210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6211,
        name_th: "โกสัมพีนคร",
        name_en: "Kosamphi Nakhon",
        province_id: 49,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 621101,
            zip_code: 62000,
            name_th: "โกสัมพี",
            name_en: "Kosamphi",
            amphure_id: 6211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 621102,
            zip_code: 62000,
            name_th: "เพชรชมภู",
            name_en: "Phet Chomphu",
            amphure_id: 6211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 621103,
            zip_code: 62000,
            name_th: "ลานดอกไม้ตก",
            name_en: "Lan Dokmai Tok",
            amphure_id: 6211,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 50,
    name_th: "ตาก",
    name_en: "Tak",
    geography_id: 4,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6301,
        name_th: "เมืองตาก",
        name_en: "Mueang Tak",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630101,
            zip_code: 63000,
            name_th: "ระแหง",
            name_en: "Rahaeng",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630102,
            zip_code: 63000,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630103,
            zip_code: 63000,
            name_th: "เชียงเงิน",
            name_en: "Chiang Ngoen",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630104,
            zip_code: 63000,
            name_th: "หัวเดียด",
            name_en: "Hua Diat",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630105,
            zip_code: 63000,
            name_th: "หนองบัวเหนือ",
            name_en: "Nong Bua Nuea",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630106,
            zip_code: 63000,
            name_th: "ไม้งาม",
            name_en: "Mai Ngam",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630107,
            zip_code: 63000,
            name_th: "โป่งแดง",
            name_en: "Pong Daeng",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630108,
            zip_code: 63000,
            name_th: "น้ำรึม",
            name_en: "Nam Ruem",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630109,
            zip_code: 63000,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630111,
            zip_code: 63000,
            name_th: "แม่ท้อ",
            name_en: "Mae Tho",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630112,
            zip_code: 63000,
            name_th: "ป่ามะม่วง",
            name_en: "Pa Mamuang",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630113,
            zip_code: 63000,
            name_th: "หนองบัวใต้",
            name_en: "Nong Bua Tai",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630114,
            zip_code: 63000,
            name_th: "วังประจบ",
            name_en: "Wang Prachop",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630115,
            zip_code: 63000,
            name_th: "ตลุกกลางทุ่ง",
            name_en: "Taluk Klang Thung",
            amphure_id: 6301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6302,
        name_th: "บ้านตาก",
        name_en: "Ban Tak",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630201,
            zip_code: 63120,
            name_th: "ตากออก",
            name_en: "Tak Ok",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630202,
            zip_code: 63120,
            name_th: "สมอโคน",
            name_en: "Samo Khon",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630203,
            zip_code: 63120,
            name_th: "แม่สลิด",
            name_en: "Mae Salit",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630204,
            zip_code: 63120,
            name_th: "ตากตก",
            name_en: "Tak Tok",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630205,
            zip_code: 63120,
            name_th: "เกาะตะเภา",
            name_en: "Ko Taphao",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630206,
            zip_code: 63120,
            name_th: "ทุ่งกระเชาะ",
            name_en: "Thung Kracho",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630207,
            zip_code: 63120,
            name_th: "ท้องฟ้า",
            name_en: "Thong Fa",
            amphure_id: 6302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6303,
        name_th: "สามเงา",
        name_en: "Sam Ngao",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630301,
            zip_code: 63130,
            name_th: "สามเงา",
            name_en: "Sam Ngao",
            amphure_id: 6303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630302,
            zip_code: 63130,
            name_th: "วังหมัน",
            name_en: "Wang Man",
            amphure_id: 6303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630303,
            zip_code: 63130,
            name_th: "ยกกระบัตร",
            name_en: "Yokkrabat",
            amphure_id: 6303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630304,
            zip_code: 63130,
            name_th: "ย่านรี",
            name_en: "Yan Ri",
            amphure_id: 6303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630305,
            zip_code: 63130,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 6303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630306,
            zip_code: 63130,
            name_th: "วังจันทร์",
            name_en: "Wang Chan",
            amphure_id: 6303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6304,
        name_th: "แม่ระมาด",
        name_en: "Mae Ramat",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630401,
            zip_code: 63140,
            name_th: "แม่ระมาด",
            name_en: "Mae Ramat",
            amphure_id: 6304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630402,
            zip_code: 63140,
            name_th: "แม่จะเรา",
            name_en: "Mae Charao",
            amphure_id: 6304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630403,
            zip_code: 63140,
            name_th: "ขะเนจื้อ",
            name_en: "Khane Chue",
            amphure_id: 6304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630404,
            zip_code: 63140,
            name_th: "แม่ตื่น",
            name_en: "Mae Tuen",
            amphure_id: 6304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630405,
            zip_code: 63140,
            name_th: "สามหมื่น",
            name_en: "Sam Muen",
            amphure_id: 6304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630406,
            zip_code: 63140,
            name_th: "พระธาตุ",
            name_en: "Phra That",
            amphure_id: 6304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6305,
        name_th: "ท่าสองยาง",
        name_en: "Tha Song Yang",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630501,
            zip_code: 63150,
            name_th: "ท่าสองยาง",
            name_en: "Tha Song Yang",
            amphure_id: 6305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630502,
            zip_code: 63150,
            name_th: "แม่ต้าน",
            name_en: "Mae Tan",
            amphure_id: 6305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630503,
            zip_code: 63150,
            name_th: "แม่สอง",
            name_en: "Mae Song",
            amphure_id: 6305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630504,
            zip_code: 63150,
            name_th: "แม่หละ",
            name_en: "Mae La",
            amphure_id: 6305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630505,
            zip_code: 63150,
            name_th: "แม่วะหลวง",
            name_en: "Mae Wa Luang",
            amphure_id: 6305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630506,
            zip_code: 63150,
            name_th: "แม่อุสุ",
            name_en: "Mae Usu",
            amphure_id: 6305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6306,
        name_th: "แม่สอด",
        name_en: "Mae Sot",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630601,
            zip_code: 63110,
            name_th: "แม่สอด",
            name_en: "Mae Sot",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630602,
            zip_code: 63110,
            name_th: "แม่กุ",
            name_en: "Mae Ku",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630603,
            zip_code: 63110,
            name_th: "พะวอ",
            name_en: "Phawo",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630604,
            zip_code: 63110,
            name_th: "แม่ตาว",
            name_en: "Mae Tao",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630605,
            zip_code: 63110,
            name_th: "แม่กาษา",
            name_en: "Mae Kasa",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630606,
            zip_code: 63110,
            name_th: "ท่าสายลวด",
            name_en: "Tha Sai Luat",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630607,
            zip_code: 63110,
            name_th: "แม่ปะ",
            name_en: "Mae Pa",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630608,
            zip_code: 63110,
            name_th: "มหาวัน",
            name_en: "Mahawan",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630609,
            zip_code: 63110,
            name_th: "ด่านแม่ละเมา",
            name_en: "Dan Mae Lamao",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630610,
            zip_code: 63110,
            name_th: "พระธาตุผาแดง",
            name_en: "Phra That Pha Daeng",
            amphure_id: 6306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6307,
        name_th: "พบพระ",
        name_en: "Phop Phra",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630701,
            zip_code: 63160,
            name_th: "พบพระ",
            name_en: "Phop Phra",
            amphure_id: 6307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630702,
            zip_code: 63160,
            name_th: "ช่องแคบ",
            name_en: "Chong Khaep",
            amphure_id: 6307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630703,
            zip_code: 63160,
            name_th: "คีรีราษฎร์",
            name_en: "Khiri Rat",
            amphure_id: 6307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630704,
            zip_code: 63160,
            name_th: "วาเล่ย์",
            name_en: "Wale",
            amphure_id: 6307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630705,
            zip_code: 63160,
            name_th: "รวมไทยพัฒนา",
            name_en: "Ruam Thai Phatthana",
            amphure_id: 6307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6308,
        name_th: "อุ้มผาง",
        name_en: "Umphang",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630801,
            zip_code: 63170,
            name_th: "อุ้มผาง",
            name_en: "Umphang",
            amphure_id: 6308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630802,
            zip_code: 63170,
            name_th: "หนองหลวง",
            name_en: "Nong Luang",
            amphure_id: 6308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630803,
            zip_code: 63170,
            name_th: "โมโกร",
            name_en: "Mokro",
            amphure_id: 6308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630804,
            zip_code: 63170,
            name_th: "แม่จัน",
            name_en: "Mae Chan",
            amphure_id: 6308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630805,
            zip_code: 63170,
            name_th: "แม่ละมุ้ง",
            name_en: "Mae Lamung",
            amphure_id: 6308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630806,
            zip_code: 63170,
            name_th: "แม่กลอง",
            name_en: "Mae Klong",
            amphure_id: 6308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6309,
        name_th: "วังเจ้า",
        name_en: "Wang Chao",
        province_id: 50,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 630901,
            zip_code: 63000,
            name_th: "เชียงทอง",
            name_en: "Chiang Thong",
            amphure_id: 6309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630902,
            zip_code: 63000,
            name_th: "นาโบสถ์",
            name_en: "Na Bot",
            amphure_id: 6309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 630903,
            zip_code: 63000,
            name_th: "ประดาง",
            name_en: "Pradang",
            amphure_id: 6309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 51,
    name_th: "สุโขทัย",
    name_en: "Sukhothai",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6401,
        name_th: "เมืองสุโขทัย",
        name_en: "Mueang Sukhothai",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640101,
            zip_code: 64000,
            name_th: "ธานี",
            name_en: "Thani",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640102,
            zip_code: 64220,
            name_th: "บ้านสวน",
            name_en: "Ban Suan",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640103,
            zip_code: 64210,
            name_th: "เมืองเก่า",
            name_en: "Mueang Kao",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640104,
            zip_code: 64000,
            name_th: "ปากแคว",
            name_en: "Pak Khwae",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640105,
            zip_code: 64000,
            name_th: "ยางซ้าย",
            name_en: "Yang Sai",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640106,
            zip_code: 64000,
            name_th: "บ้านกล้วย",
            name_en: "Ban Kluai",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640107,
            zip_code: 64000,
            name_th: "บ้านหลุม",
            name_en: "Ban Lum",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640108,
            zip_code: 64220,
            name_th: "ตาลเตี้ย",
            name_en: "Tan Tia",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640109,
            zip_code: 64000,
            name_th: "ปากพระ",
            name_en: "Pak Phra",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640110,
            zip_code: 64210,
            name_th: "วังทองแดง",
            name_en: "Wang Thongdaeng",
            amphure_id: 6401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6402,
        name_th: "บ้านด่านลานหอย",
        name_en: "Ban Dan Lan Hoi",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640201,
            zip_code: 64140,
            name_th: "ลานหอย",
            name_en: "Lan Hoi",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640202,
            zip_code: 64140,
            name_th: "บ้านด่าน",
            name_en: "Ban Dan",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640203,
            zip_code: 64140,
            name_th: "วังตะคร้อ",
            name_en: "Wang Takhro",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640204,
            zip_code: 64140,
            name_th: "วังน้ำขาว",
            name_en: "Wang Nam Khao",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640205,
            zip_code: 64140,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640206,
            zip_code: 64140,
            name_th: "หนองหญ้าปล้อง",
            name_en: "Nong Ya Plong",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640207,
            zip_code: 64140,
            name_th: "วังลึก",
            name_en: "Wang Luek",
            amphure_id: 6402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6403,
        name_th: "คีรีมาศ",
        name_en: "Khiri Mat",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640301,
            zip_code: 64160,
            name_th: "โตนด",
            name_en: "Tanot",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640302,
            zip_code: 64160,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640303,
            zip_code: 64160,
            name_th: "บ้านป้อม",
            name_en: "Ban Pom",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640304,
            zip_code: 64160,
            name_th: "สามพวง",
            name_en: "Sam Phuang",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640305,
            zip_code: 64160,
            name_th: "ศรีคีรีมาศ",
            name_en: "Si Khiri Mat",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640306,
            zip_code: 64160,
            name_th: "หนองจิก",
            name_en: "Nong Chik",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640307,
            zip_code: 64160,
            name_th: "นาเชิงคีรี",
            name_en: "Na Choeng Khiri",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640308,
            zip_code: 64160,
            name_th: "หนองกระดิ่ง",
            name_en: "Nong Krading",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640309,
            zip_code: 64160,
            name_th: "บ้านน้ำพุ",
            name_en: "Ban Nam Phu",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640310,
            zip_code: 64160,
            name_th: "ทุ่งยางเมือง",
            name_en: "Thung Yang Mueang",
            amphure_id: 6403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6404,
        name_th: "กงไกรลาศ",
        name_en: "Kong Krailat",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640401,
            zip_code: 64170,
            name_th: "กง",
            name_en: "Kong",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640402,
            zip_code: 64170,
            name_th: "บ้านกร่าง",
            name_en: "Ban Krang",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640403,
            zip_code: 64170,
            name_th: "ไกรนอก",
            name_en: "Krai Nok",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640404,
            zip_code: 64170,
            name_th: "ไกรกลาง",
            name_en: "Krai Klang",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640405,
            zip_code: 64170,
            name_th: "ไกรใน",
            name_en: "Krai Nai",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640406,
            zip_code: 64170,
            name_th: "ดงเดือย",
            name_en: "Dong Dueai",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640407,
            zip_code: 64170,
            name_th: "ป่าแฝก",
            name_en: "Pa Faek",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640408,
            zip_code: 64170,
            name_th: "กกแรต",
            name_en: "Kok Raet",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640409,
            zip_code: 64170,
            name_th: "ท่าฉนวน",
            name_en: "Tha Chanuan",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640410,
            zip_code: 64170,
            name_th: "หนองตูม",
            name_en: "Nong Tum",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640411,
            zip_code: 64170,
            name_th: "บ้านใหม่สุขเกษม",
            name_en: "Ban Mai Suk Kasem",
            amphure_id: 6404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6405,
        name_th: "ศรีสัชนาลัย",
        name_en: "Si Satchanalai",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640501,
            zip_code: 64130,
            name_th: "หาดเสี้ยว",
            name_en: "Hat Siao",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640502,
            zip_code: 64130,
            name_th: "ป่างิ้ว",
            name_en: "Pa Ngio",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640503,
            zip_code: 64130,
            name_th: "แม่สำ",
            name_en: "Mae Sam",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640504,
            zip_code: 64130,
            name_th: "แม่สิน",
            name_en: "Mae Sin",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640505,
            zip_code: 64130,
            name_th: "บ้านตึก",
            name_en: "Ban Tuek",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640506,
            zip_code: 64130,
            name_th: "หนองอ้อ",
            name_en: "Nong O",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640507,
            zip_code: 64190,
            name_th: "ท่าชัย",
            name_en: "Tha Chai",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640508,
            zip_code: 64190,
            name_th: "ศรีสัชนาลัย",
            name_en: "Si Satchanalai",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640509,
            zip_code: 64130,
            name_th: "ดงคู่",
            name_en: "Dong Khu",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640510,
            zip_code: 64130,
            name_th: "บ้านแก่ง",
            name_en: "Ban Kaeng",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640511,
            zip_code: 64130,
            name_th: "สารจิตร",
            name_en: "San Chit",
            amphure_id: 6405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6406,
        name_th: "ศรีสำโรง",
        name_en: "Si Samrong",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640601,
            zip_code: 64120,
            name_th: "คลองตาล",
            name_en: "Khlong Tan",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640602,
            zip_code: 64120,
            name_th: "วังลึก",
            name_en: "Wang Luek",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640603,
            zip_code: 64120,
            name_th: "สามเรือน",
            name_en: "Sam Ruean",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640604,
            zip_code: 64120,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640605,
            zip_code: 64120,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640606,
            zip_code: 64120,
            name_th: "นาขุนไกร",
            name_en: "Na Khun Krai",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640607,
            zip_code: 64120,
            name_th: "เกาะตาเลี้ยง",
            name_en: "Ko Ta Liang",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640608,
            zip_code: 64120,
            name_th: "วัดเกาะ",
            name_en: "Wat Ko",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640609,
            zip_code: 64120,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640610,
            zip_code: 64120,
            name_th: "ทับผึ้ง",
            name_en: "Thap Phueng",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640611,
            zip_code: 64120,
            name_th: "บ้านซ่าน",
            name_en: "Ban San",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640612,
            zip_code: 64120,
            name_th: "วังใหญ่",
            name_en: "Wang Yai",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640613,
            zip_code: 64120,
            name_th: "ราวต้นจันทร์",
            name_en: "Rao Ton Chan",
            amphure_id: 6406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6407,
        name_th: "สวรรคโลก",
        name_en: "Sawankhalok",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640701,
            zip_code: 64110,
            name_th: "เมืองสวรรคโลก",
            name_en: "Mueang Sawankhalok",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640702,
            zip_code: 64110,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640703,
            zip_code: 64110,
            name_th: "คลองกระจง",
            name_en: "Khlong Krachong",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640704,
            zip_code: 64110,
            name_th: "วังพิณพาทย์",
            name_en: "Wang Phinphat",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640705,
            zip_code: 64110,
            name_th: "วังไม้ขอน",
            name_en: "Wang Mai Khon",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640706,
            zip_code: 64110,
            name_th: "ย่านยาว",
            name_en: "Yan Yao",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640707,
            zip_code: 64110,
            name_th: "นาทุ่ง",
            name_en: "Na Thung",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640708,
            zip_code: 64110,
            name_th: "คลองยาง",
            name_en: "Khlong Yao",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640709,
            zip_code: 64110,
            name_th: "เมืองบางยม",
            name_en: "Mueang Bang Yom",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640710,
            zip_code: 64110,
            name_th: "ท่าทอง",
            name_en: "Tha Thong",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640711,
            zip_code: 64110,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640712,
            zip_code: 64110,
            name_th: "ป่ากุมเกาะ",
            name_en: "Pa Kum Ko",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640713,
            zip_code: 64110,
            name_th: "เมืองบางขลัง",
            name_en: "Mueang Bang Khlang",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640714,
            zip_code: 64110,
            name_th: "หนองกลับ",
            name_en: "Nong Klap",
            amphure_id: 6407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6408,
        name_th: "ศรีนคร",
        name_en: "Si Nakhon",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640801,
            zip_code: 64180,
            name_th: "ศรีนคร",
            name_en: "Si Nakhon",
            amphure_id: 6408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640802,
            zip_code: 64180,
            name_th: "นครเดิฐ",
            name_en: "Nakhon Doet",
            amphure_id: 6408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640803,
            zip_code: 64180,
            name_th: "น้ำขุม",
            name_en: "Nam Khum",
            amphure_id: 6408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640804,
            zip_code: 64180,
            name_th: "คลองมะพลับ",
            name_en: "Khlong Maphlap",
            amphure_id: 6408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640805,
            zip_code: 64180,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 6408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6409,
        name_th: "ทุ่งเสลี่ยม",
        name_en: "Thung Saliam",
        province_id: 51,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 640901,
            zip_code: 64230,
            name_th: "บ้านใหม่ไชยมงคล",
            name_en: "Ban Mai Chai Mongkhon",
            amphure_id: 6409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640902,
            zip_code: 64150,
            name_th: "ไทยชนะศึก",
            name_en: "Thai Chana Suek",
            amphure_id: 6409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640903,
            zip_code: 64150,
            name_th: "ทุ่งเสลี่ยม",
            name_en: "Thung Saliam",
            amphure_id: 6409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640904,
            zip_code: 64150,
            name_th: "กลางดง",
            name_en: "Klang Dong",
            amphure_id: 6409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 640905,
            zip_code: 64230,
            name_th: "เขาแก้วศรีสมบูรณ์",
            name_en: "Khaokaw Si Somboon",
            amphure_id: 6409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 52,
    name_th: "พิษณุโลก",
    name_en: "Phitsanulok",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6501,
        name_th: "เมืองพิษณุโลก",
        name_en: "Mueang Phitsanulok",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650101,
            zip_code: 65000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650102,
            zip_code: 65230,
            name_th: "วังน้ำคู้",
            name_en: "Wang Nam Khu",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650103,
            zip_code: 65000,
            name_th: "วัดจันทร์",
            name_en: "Wat Chan",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650104,
            zip_code: 65230,
            name_th: "วัดพริก",
            name_en: "Wat Phrik",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650105,
            zip_code: 65000,
            name_th: "ท่าทอง",
            name_en: "Tha Thong",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650106,
            zip_code: 65000,
            name_th: "ท่าโพธิ์",
            name_en: "Tha Pho",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650107,
            zip_code: 65000,
            name_th: "สมอแข",
            name_en: "Samo Khae",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650108,
            zip_code: 65000,
            name_th: "ดอนทอง",
            name_en: "Don Thong",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650109,
            zip_code: 65000,
            name_th: "บ้านป่า",
            name_en: "Ban Pa",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650110,
            zip_code: 65000,
            name_th: "ปากโทก",
            name_en: "Pak Thok",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650111,
            zip_code: 65000,
            name_th: "หัวรอ",
            name_en: "Hua Ro",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650112,
            zip_code: 65000,
            name_th: "จอมทอง",
            name_en: "Chom Thong",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650113,
            zip_code: 65000,
            name_th: "บ้านกร่าง",
            name_en: "Ban Krang",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650114,
            zip_code: 65000,
            name_th: "บ้านคลอง",
            name_en: "Ban Khlong",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650115,
            zip_code: 65000,
            name_th: "พลายชุมพล",
            name_en: "Phlai Chumphon",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650116,
            zip_code: 65000,
            name_th: "มะขามสูง",
            name_en: "Makham Sung",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650117,
            zip_code: 65000,
            name_th: "อรัญญิก",
            name_en: "Aranyik",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650118,
            zip_code: 65000,
            name_th: "บึงพระ",
            name_en: "Bueng Phra",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650119,
            zip_code: 65000,
            name_th: "ไผ่ขอดอน",
            name_en: "Phai Kho Don",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650120,
            zip_code: 65230,
            name_th: "งิ้วงาม",
            name_en: "Ngio Ngam",
            amphure_id: 6501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6502,
        name_th: "นครไทย",
        name_en: "Nakhon Thai",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650201,
            zip_code: 65120,
            name_th: "นครไทย",
            name_en: "Nakhon Thai",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650202,
            zip_code: 65120,
            name_th: "หนองกะท้าว",
            name_en: "Nong Kathao",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650203,
            zip_code: 65120,
            name_th: "บ้านแยง",
            name_en: "Ban Yaeng",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650204,
            zip_code: 65120,
            name_th: "เนินเพิ่ม",
            name_en: "Noen Phoem",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650205,
            zip_code: 65120,
            name_th: "นาบัว",
            name_en: "Na Bua",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650206,
            zip_code: 65120,
            name_th: "นครชุม",
            name_en: "Nakhon Chum",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650207,
            zip_code: 65120,
            name_th: "น้ำกุ่ม",
            name_en: "Nam Kum",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650208,
            zip_code: 65120,
            name_th: "ยางโกลน",
            name_en: "Yang Klon",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650209,
            zip_code: 65120,
            name_th: "บ่อโพธิ์",
            name_en: "Bo Pho",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650210,
            zip_code: 65120,
            name_th: "บ้านพร้าว",
            name_en: "Ban Phrao",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650211,
            zip_code: 65120,
            name_th: "ห้วยเฮี้ย",
            name_en: "Huai Hia",
            amphure_id: 6502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6503,
        name_th: "ชาติตระการ",
        name_en: "Chat Trakan",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650301,
            zip_code: 65170,
            name_th: "ป่าแดง",
            name_en: "Pa Daeng",
            amphure_id: 6503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650302,
            zip_code: 65170,
            name_th: "ชาติตระการ",
            name_en: "Chat Trakan",
            amphure_id: 6503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650303,
            zip_code: 65170,
            name_th: "สวนเมี่ยง",
            name_en: "Suan Miang",
            amphure_id: 6503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650304,
            zip_code: 65170,
            name_th: "บ้านดง",
            name_en: "Ban Dong",
            amphure_id: 6503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650305,
            zip_code: 65170,
            name_th: "บ่อภาค",
            name_en: "Bo Phak",
            amphure_id: 6503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650306,
            zip_code: 65170,
            name_th: "ท่าสะแก",
            name_en: "Tha Sakae",
            amphure_id: 6503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6504,
        name_th: "บางระกำ",
        name_en: "Bang Rakam",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650401,
            zip_code: 65140,
            name_th: "บางระกำ",
            name_en: "Bang Rakam",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650402,
            zip_code: 65140,
            name_th: "ปลักแรด",
            name_en: "Plak Raet",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650403,
            zip_code: 65140,
            name_th: "พันเสา",
            name_en: "Phan Sao",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650404,
            zip_code: 65140,
            name_th: "วังอิทก",
            name_en: "Wang Ithok",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650405,
            zip_code: 65140,
            name_th: "บึงกอก",
            name_en: "Bueng Kok",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650406,
            zip_code: 65140,
            name_th: "หนองกุลา",
            name_en: "Nong Kula",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650407,
            zip_code: 65240,
            name_th: "ชุมแสงสงคราม",
            name_en: "Chum Saeng Songkhram",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650408,
            zip_code: 65140,
            name_th: "นิคมพัฒนา",
            name_en: "Nikhom Phatthana",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650409,
            zip_code: 65140,
            name_th: "บ่อทอง",
            name_en: "Bo Thong",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650410,
            zip_code: 65140,
            name_th: "ท่านางงาม",
            name_en: "Tha Nang Ngam",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650411,
            zip_code: 65240,
            name_th: "คุยม่วง",
            name_en: "Khui Muang",
            amphure_id: 6504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6505,
        name_th: "บางกระทุ่ม",
        name_en: "Bang Krathum",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650501,
            zip_code: 65110,
            name_th: "บางกระทุ่ม",
            name_en: "Bang Krathum",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650502,
            zip_code: 65110,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650503,
            zip_code: 65110,
            name_th: "โคกสลุด",
            name_en: "Khok Salut",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650504,
            zip_code: 65110,
            name_th: "สนามคลี",
            name_en: "Sanam Khli",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650505,
            zip_code: 65110,
            name_th: "ท่าตาล",
            name_en: "Tha Tan",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650506,
            zip_code: 65110,
            name_th: "ไผ่ล้อม",
            name_en: "Phai Lom",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650507,
            zip_code: 65110,
            name_th: "นครป่าหมาก",
            name_en: "Nakhon Pa Mak",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650508,
            zip_code: 65210,
            name_th: "เนินกุ่ม",
            name_en: "Noen Kum",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650509,
            zip_code: 65210,
            name_th: "วัดตายม",
            name_en: "Wat Ta Yom",
            amphure_id: 6505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6506,
        name_th: "พรหมพิราม",
        name_en: "Phrom Phiram",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650601,
            zip_code: 65150,
            name_th: "พรหมพิราม",
            name_en: "Phrom Phiram",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650602,
            zip_code: 65150,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650603,
            zip_code: 65180,
            name_th: "วงฆ้อง",
            name_en: "Wong Khong",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650604,
            zip_code: 65150,
            name_th: "มะตูม",
            name_en: "Matum",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650605,
            zip_code: 65150,
            name_th: "หอกลอง",
            name_en: "Ho Klong",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650606,
            zip_code: 65180,
            name_th: "ศรีภิรมย์",
            name_en: "Si Phirom",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650607,
            zip_code: 65180,
            name_th: "ตลุกเทียม",
            name_en: "Taluk Thiam",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650608,
            zip_code: 65150,
            name_th: "วังวน",
            name_en: "Wang Won",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650609,
            zip_code: 65150,
            name_th: "หนองแขม",
            name_en: "Nong Khaem",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650610,
            zip_code: 65180,
            name_th: "มะต้อง",
            name_en: "Matong",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650611,
            zip_code: 65150,
            name_th: "ทับยายเชียง",
            name_en: "Thap Yai Chiang",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650612,
            zip_code: 65180,
            name_th: "ดงประคำ",
            name_en: "Dong Prakham",
            amphure_id: 6506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6507,
        name_th: "วัดโบสถ์",
        name_en: "Wat Bot",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650701,
            zip_code: 65160,
            name_th: "วัดโบสถ์",
            name_en: "Wat Bot",
            amphure_id: 6507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650702,
            zip_code: 65160,
            name_th: "ท่างาม",
            name_en: "Tha Ngam",
            amphure_id: 6507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650703,
            zip_code: 65160,
            name_th: "ท้อแท้",
            name_en: "Thothae",
            amphure_id: 6507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650704,
            zip_code: 65160,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 6507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650705,
            zip_code: 65160,
            name_th: "หินลาด",
            name_en: "Hin Lat",
            amphure_id: 6507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650706,
            zip_code: 65160,
            name_th: "คันโช้ง",
            name_en: "Khan Chong",
            amphure_id: 6507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6508,
        name_th: "วังทอง",
        name_en: "Wang Thong",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650801,
            zip_code: 65130,
            name_th: "วังทอง",
            name_en: "Wang Thong",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650802,
            zip_code: 65130,
            name_th: "พันชาลี",
            name_en: "Phan Chali",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650803,
            zip_code: 65130,
            name_th: "แม่ระกา",
            name_en: "Mae Raka",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650804,
            zip_code: 65220,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650805,
            zip_code: 65130,
            name_th: "วังพิกุล",
            name_en: "Wang Phikun",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650806,
            zip_code: 65220,
            name_th: "แก่งโสภา",
            name_en: "Kaeng Sopha",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650807,
            zip_code: 65130,
            name_th: "ท่าหมื่นราม",
            name_en: "Tha Muen Ram",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650808,
            zip_code: 65130,
            name_th: "วังนกแอ่น",
            name_en: "Wang Nok Aen",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650809,
            zip_code: 65130,
            name_th: "หนองพระ",
            name_en: "Nong Phra",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650810,
            zip_code: 65130,
            name_th: "ชัยนาม",
            name_en: "Chaiyanam",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650811,
            zip_code: 65130,
            name_th: "ดินทอง",
            name_en: "Din Thong",
            amphure_id: 6508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6509,
        name_th: "เนินมะปราง",
        name_en: "Noen Maprang",
        province_id: 52,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 650901,
            zip_code: 65190,
            name_th: "ชมพู",
            name_en: "Chomphu",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650902,
            zip_code: 65190,
            name_th: "บ้านมุง",
            name_en: "Ban Mung",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650903,
            zip_code: 65190,
            name_th: "ไทรย้อย",
            name_en: "Sai Yoi",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650904,
            zip_code: 65190,
            name_th: "วังโพรง",
            name_en: "Wang Phrong",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650905,
            zip_code: 65190,
            name_th: "บ้านน้อยซุ้มขี้เหล็ก",
            name_en: "Ban Noi Sum Khilek",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650906,
            zip_code: 65190,
            name_th: "เนินมะปราง",
            name_en: "Noen Maprang",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650907,
            zip_code: 65190,
            name_th: "วังยาง",
            name_en: "Wang Yang",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 650908,
            zip_code: 65190,
            name_th: "โคกแหลม",
            name_en: "Khok Laem",
            amphure_id: 6509,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 53,
    name_th: "พิจิตร",
    name_en: "Phichit",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6601,
        name_th: "เมืองพิจิตร",
        name_en: "Mueang Phichit",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660101,
            zip_code: 66000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660102,
            zip_code: 66000,
            name_th: "ไผ่ขวาง",
            name_en: "Phai Khwang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660103,
            zip_code: 66000,
            name_th: "ย่านยาว",
            name_en: "Yan Yao",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660104,
            zip_code: 66000,
            name_th: "ท่าฬ่อ",
            name_en: "Tha Lo",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660105,
            zip_code: 66000,
            name_th: "ปากทาง",
            name_en: "Pak Thang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660106,
            zip_code: 66000,
            name_th: "คลองคะเชนทร์",
            name_en: "Khlong Khachen",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660107,
            zip_code: 66000,
            name_th: "โรงช้าง",
            name_en: "Rong Chang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660108,
            zip_code: 66000,
            name_th: "เมืองเก่า",
            name_en: "Mueang Kao",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660109,
            zip_code: 66000,
            name_th: "ท่าหลวง",
            name_en: "Tha Luang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660110,
            zip_code: 66000,
            name_th: "บ้านบุ่ง",
            name_en: "Ban Bung",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660111,
            zip_code: 66000,
            name_th: "ฆะมัง",
            name_en: "Khamang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660112,
            zip_code: 66170,
            name_th: "ดงป่าคำ",
            name_en: "Dong Pa Kham",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660113,
            zip_code: 66170,
            name_th: "หัวดง",
            name_en: "Hua Dong",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660115,
            zip_code: 66000,
            name_th: "ป่ามะคาบ",
            name_en: "Pa Makhap",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660119,
            zip_code: 66000,
            name_th: "สายคำโห้",
            name_en: "Sai Kham Ho",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660120,
            zip_code: 66170,
            name_th: "ดงกลาง",
            name_en: "Dong Klang",
            amphure_id: 6601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6602,
        name_th: "วังทรายพูน",
        name_en: "Wang Sai Phun",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660201,
            zip_code: 66180,
            name_th: "วังทรายพูน",
            name_en: "Wang Sai Phun",
            amphure_id: 6602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660202,
            zip_code: 66180,
            name_th: "หนองปลาไหล",
            name_en: "Nong Pla Lai",
            amphure_id: 6602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660203,
            zip_code: 66180,
            name_th: "หนองพระ",
            name_en: "Nong Phra",
            amphure_id: 6602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660204,
            zip_code: 66180,
            name_th: "หนองปล้อง",
            name_en: "Nong Plong",
            amphure_id: 6602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6603,
        name_th: "โพธิ์ประทับช้าง",
        name_en: "Pho Prathap Chang",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660301,
            zip_code: 66190,
            name_th: "โพธิ์ประทับช้าง",
            name_en: "Pho Prathap Chang",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660302,
            zip_code: 66190,
            name_th: "ไผ่ท่าโพ",
            name_en: "Phai Tha Pho",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660303,
            zip_code: 66190,
            name_th: "วังจิก",
            name_en: "Wang Chik",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660304,
            zip_code: 66190,
            name_th: "ไผ่รอบ",
            name_en: "Phai Rop",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660305,
            zip_code: 66190,
            name_th: "ดงเสือเหลือง",
            name_en: "Dong Suea Lueang",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660306,
            zip_code: 66190,
            name_th: "เนินสว่าง",
            name_en: "Noen Sawang",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660307,
            zip_code: 66190,
            name_th: "ทุ่งใหญ่",
            name_en: "Thung Yai",
            amphure_id: 6603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6604,
        name_th: "ตะพานหิน",
        name_en: "Taphan Hin",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660401,
            zip_code: 66110,
            name_th: "ตะพานหิน",
            name_en: "Taphan Hin",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660402,
            zip_code: 66110,
            name_th: "งิ้วราย",
            name_en: "Ngio Rai",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660403,
            zip_code: 66110,
            name_th: "ห้วยเกตุ",
            name_en: "Huai Ket",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660404,
            zip_code: 66110,
            name_th: "ไทรโรงโขน",
            name_en: "Sai Rong Khon",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660405,
            zip_code: 66110,
            name_th: "หนองพยอม",
            name_en: "Nong Phayom",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660406,
            zip_code: 66150,
            name_th: "ทุ่งโพธิ์",
            name_en: "Tung Pho",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660407,
            zip_code: 66110,
            name_th: "ดงตะขบ",
            name_en: "Dong Takhop",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660408,
            zip_code: 66110,
            name_th: "คลองคูณ",
            name_en: "Khlong Khun",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660409,
            zip_code: 66110,
            name_th: "วังสำโรง",
            name_en: "Wang Samrong",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660410,
            zip_code: 66110,
            name_th: "วังหว้า",
            name_en: "Wang Wa",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660411,
            zip_code: 66150,
            name_th: "วังหลุม",
            name_en: "Wang Lum",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660412,
            zip_code: 66110,
            name_th: "ทับหมัน",
            name_en: "Thap Man",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660413,
            zip_code: 66110,
            name_th: "ไผ่หลวง",
            name_en: "Phai Luang",
            amphure_id: 6604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6605,
        name_th: "บางมูลนาก",
        name_en: "Bang Mun Nak",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660501,
            zip_code: 66120,
            name_th: "บางมูลนาก",
            name_en: "Bang Mun Nak",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660502,
            zip_code: 66120,
            name_th: "บางไผ่",
            name_en: "Bang Phai",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660503,
            zip_code: 66120,
            name_th: "หอไกร",
            name_en: "Ho Krai",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660504,
            zip_code: 66120,
            name_th: "เนินมะกอก",
            name_en: "Noen Makok",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660505,
            zip_code: 66120,
            name_th: "วังสำโรง",
            name_en: "Wang Samrong",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660506,
            zip_code: 66120,
            name_th: "ภูมิ",
            name_en: "Phum",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660507,
            zip_code: 66120,
            name_th: "วังกรด",
            name_en: "Wang Krot",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660508,
            zip_code: 66120,
            name_th: "ห้วยเขน",
            name_en: "Huai Khen",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660509,
            zip_code: 66210,
            name_th: "วังตะกู",
            name_en: "Wang Taku",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660514,
            zip_code: 66120,
            name_th: "ลำประดา",
            name_en: "Lam Prad",
            amphure_id: 6605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6606,
        name_th: "โพทะเล",
        name_en: "Pho Thale",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660601,
            zip_code: 66130,
            name_th: "โพทะเล",
            name_en: "Pho Thale",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660602,
            zip_code: 66130,
            name_th: "ท้ายน้ำ",
            name_en: "Thai Nam",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660603,
            zip_code: 66130,
            name_th: "ทะนง",
            name_en: "Thanong",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660604,
            zip_code: 66130,
            name_th: "ท่าบัว",
            name_en: "Tha Bua",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660605,
            zip_code: 66130,
            name_th: "ทุ่งน้อย",
            name_en: "Thung Noi",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660606,
            zip_code: 66130,
            name_th: "ท่าขมิ้น",
            name_en: "Tha Khamin",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660607,
            zip_code: 66130,
            name_th: "ท่าเสา",
            name_en: "Tha Sao",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660608,
            zip_code: 66130,
            name_th: "บางคลาน",
            name_en: "Bang Khlan",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660611,
            zip_code: 66130,
            name_th: "ท่านั่ง",
            name_en: "Tha Nang",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660612,
            zip_code: 66130,
            name_th: "บ้านน้อย",
            name_en: "Ban Noi",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660613,
            zip_code: 66130,
            name_th: "วัดขวาง",
            name_en: "Wat Khwang",
            amphure_id: 6606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6607,
        name_th: "สามง่าม",
        name_en: "Sam Ngam",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660701,
            zip_code: 66140,
            name_th: "สามง่าม",
            name_en: "Sam Ngam",
            amphure_id: 6607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660702,
            zip_code: 66140,
            name_th: "กำแพงดิน",
            name_en: "Kamphaeng Din",
            amphure_id: 6607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660703,
            zip_code: 66140,
            name_th: "รังนก",
            name_en: "Rang Nok",
            amphure_id: 6607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660706,
            zip_code: 66140,
            name_th: "เนินปอ",
            name_en: "Noen Po",
            amphure_id: 6607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660707,
            zip_code: 66140,
            name_th: "หนองโสน",
            name_en: "Nong Sano",
            amphure_id: 6607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6608,
        name_th: "ทับคล้อ",
        name_en: "Tap Khlo",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660801,
            zip_code: 66150,
            name_th: "ทับคล้อ",
            name_en: "Thap Khlo",
            amphure_id: 6608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660802,
            zip_code: 66230,
            name_th: "เขาทราย",
            name_en: "Khao Sai",
            amphure_id: 6608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660803,
            zip_code: 66230,
            name_th: "เขาเจ็ดลูก",
            name_en: "Khao Chet Luk",
            amphure_id: 6608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660804,
            zip_code: 66150,
            name_th: "ท้ายทุ่ง",
            name_en: "Tai Toong",
            amphure_id: 6608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6609,
        name_th: "สากเหล็ก",
        name_en: "Sak Lek",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 660901,
            zip_code: 66160,
            name_th: "สากเหล็ก",
            name_en: "Sak Lek",
            amphure_id: 6609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660902,
            zip_code: 66160,
            name_th: "ท่าเยี่ยม",
            name_en: "Tha Yiam",
            amphure_id: 6609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660903,
            zip_code: 66160,
            name_th: "คลองทราย",
            name_en: "Khlong Sai",
            amphure_id: 6609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660904,
            zip_code: 66160,
            name_th: "หนองหญ้าไทร",
            name_en: "Nong Ya Sai",
            amphure_id: 6609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 660905,
            zip_code: 66160,
            name_th: "วังทับไทร",
            name_en: "Wang Thap Sai",
            amphure_id: 6609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6610,
        name_th: "บึงนาราง",
        name_en: "Bueng Na Rang",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 661001,
            zip_code: 66130,
            name_th: "ห้วยแก้ว",
            name_en: "Huai Kaeo",
            amphure_id: 6610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661002,
            zip_code: 66130,
            name_th: "โพธิ์ไทรงาม",
            name_en: "Pho Sai Ngam",
            amphure_id: 6610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661003,
            zip_code: 66130,
            name_th: "แหลมรัง",
            name_en: "Laem Rang",
            amphure_id: 6610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661004,
            zip_code: 66130,
            name_th: "บางลาย",
            name_en: "Bang Lai",
            amphure_id: 6610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661005,
            zip_code: 66130,
            name_th: "บึงนาราง",
            name_en: "Bueng Na Rang",
            amphure_id: 6610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6611,
        name_th: "ดงเจริญ",
        name_en: "Dong Charoen",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 661101,
            zip_code: 66210,
            name_th: "วังงิ้วใต้",
            name_en: "Wang Ngio Tai",
            amphure_id: 6611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661102,
            zip_code: 66210,
            name_th: "วังงิ้ว",
            name_en: "Wang Ngio",
            amphure_id: 6611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661103,
            zip_code: 66210,
            name_th: "ห้วยร่วม",
            name_en: "Huai Ruam",
            amphure_id: 6611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661104,
            zip_code: 66210,
            name_th: "ห้วยพุก",
            name_en: "Huai Phuk",
            amphure_id: 6611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661105,
            zip_code: 66210,
            name_th: "สำนักขุนเณร",
            name_en: "Samnak Khun Nen",
            amphure_id: 6611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6612,
        name_th: "วชิรบารมี",
        name_en: "Wachirabarami",
        province_id: 53,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 661201,
            zip_code: 66140,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 6612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661202,
            zip_code: 66140,
            name_th: "บึงบัว",
            name_en: "Bueng Bua",
            amphure_id: 6612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661203,
            zip_code: 66140,
            name_th: "วังโมกข์",
            name_en: "Wang Mok",
            amphure_id: 6612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 661204,
            zip_code: 66220,
            name_th: "หนองหลุม",
            name_en: "Nong Lum",
            amphure_id: 6612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 54,
    name_th: "เพชรบูรณ์",
    name_en: "Phetchabun",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 6701,
        name_th: "เมืองเพชรบูรณ์",
        name_en: "Mueang Phetchabun",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670101,
            zip_code: 67000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670102,
            zip_code: 67000,
            name_th: "ตะเบาะ",
            name_en: "Tabo",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670103,
            zip_code: 67000,
            name_th: "บ้านโตก",
            name_en: "Ban Tok",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670104,
            zip_code: 67000,
            name_th: "สะเดียง",
            name_en: "Sadiang",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670105,
            zip_code: 67000,
            name_th: "ป่าเลา",
            name_en: "Pa Lao",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670106,
            zip_code: 67000,
            name_th: "นางั่ว",
            name_en: "Na Ngua",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670107,
            zip_code: 67250,
            name_th: "ท่าพล",
            name_en: "Tha Phon",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670108,
            zip_code: 67000,
            name_th: "ดงมูลเหล็ก",
            name_en: "Dong Mun Lek",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670109,
            zip_code: 67000,
            name_th: "บ้านโคก",
            name_en: "Ban Khok",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670110,
            zip_code: 67000,
            name_th: "ชอนไพร",
            name_en: "Chon Phrai",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670111,
            zip_code: 67000,
            name_th: "นาป่า",
            name_en: "Na Pa",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670112,
            zip_code: 67210,
            name_th: "นายม",
            name_en: "Na Yom",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670113,
            zip_code: 67210,
            name_th: "วังชมภู",
            name_en: "Wang Chomphu",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670114,
            zip_code: 67000,
            name_th: "น้ำร้อน",
            name_en: "Nam Ron",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670115,
            zip_code: 67210,
            name_th: "ห้วยสะแก",
            name_en: "Huai Sakae",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670116,
            zip_code: 67000,
            name_th: "ห้วยใหญ่",
            name_en: "Huai Yai",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670117,
            zip_code: 67210,
            name_th: "ระวิง",
            name_en: "Rawing",
            amphure_id: 6701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6702,
        name_th: "ชนแดน",
        name_en: "Chon Daen",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670201,
            zip_code: 67150,
            name_th: "ชนแดน",
            name_en: "Chon Daen",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670202,
            zip_code: 67190,
            name_th: "ดงขุย",
            name_en: "Dong Khui",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670203,
            zip_code: 67150,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670204,
            zip_code: 67150,
            name_th: "พุทธบาท",
            name_en: "Phutthabat",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670205,
            zip_code: 67150,
            name_th: "ลาดแค",
            name_en: "Lat Khae",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670206,
            zip_code: 67190,
            name_th: "บ้านกล้วย",
            name_en: "Ban Kluai",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670208,
            zip_code: 67150,
            name_th: "ซับพุทรา",
            name_en: "Sap Phutsa",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670209,
            zip_code: 67190,
            name_th: "ตะกุดไร",
            name_en: "Takut Rai",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670210,
            zip_code: 67150,
            name_th: "ศาลาลาย",
            name_en: "Sala Lai",
            amphure_id: 6702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6703,
        name_th: "หล่มสัก",
        name_en: "Lom Sak",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670301,
            zip_code: 67110,
            name_th: "หล่มสัก",
            name_en: "Lom Sak",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670302,
            zip_code: 67110,
            name_th: "วัดป่า",
            name_en: "Wat Pa",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670303,
            zip_code: 67110,
            name_th: "ตาลเดี่ยว",
            name_en: "Tan Diao",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670304,
            zip_code: 67110,
            name_th: "ฝายนาแซง",
            name_en: "Fai Na Saeng",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670305,
            zip_code: 67110,
            name_th: "หนองสว่าง",
            name_en: "Nong Sawang",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670306,
            zip_code: 67110,
            name_th: "น้ำเฮี้ย",
            name_en: "Nam Hia",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670307,
            zip_code: 67110,
            name_th: "สักหลง",
            name_en: "Sak Long",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670308,
            zip_code: 67110,
            name_th: "ท่าอิบุญ",
            name_en: "Tha Ibun",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670309,
            zip_code: 67110,
            name_th: "บ้านโสก",
            name_en: "Ban Sok",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670310,
            zip_code: 67110,
            name_th: "บ้านติ้ว",
            name_en: "Ban Tio",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670311,
            zip_code: 67110,
            name_th: "ห้วยไร่",
            name_en: "Huai Rai",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670312,
            zip_code: 67110,
            name_th: "น้ำก้อ",
            name_en: "Nam Ko",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670313,
            zip_code: 67110,
            name_th: "ปากช่อง",
            name_en: "Pak Chong",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670314,
            zip_code: 67110,
            name_th: "น้ำชุน",
            name_en: "Nam Chun",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670315,
            zip_code: 67110,
            name_th: "หนองไขว่",
            name_en: "Nong Khwai",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670316,
            zip_code: 67110,
            name_th: "ลานบ่า",
            name_en: "Lan Ba",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670317,
            zip_code: 67110,
            name_th: "บุ่งคล้า",
            name_en: "Bung Khla",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670318,
            zip_code: 67110,
            name_th: "บุ่งน้ำเต้า",
            name_en: "Bung Namtao",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670319,
            zip_code: 67110,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670320,
            zip_code: 67110,
            name_th: "ช้างตะลูด",
            name_en: "Chang Talut",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670321,
            zip_code: 67110,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670322,
            zip_code: 67110,
            name_th: "ปากดุก",
            name_en: "Pak Duk",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670323,
            zip_code: 67110,
            name_th: "บ้านหวาย",
            name_en: "Ban Wai",
            amphure_id: 6703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6704,
        name_th: "หล่มเก่า",
        name_en: "Lom Kao",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670401,
            zip_code: 67120,
            name_th: "หล่มเก่า",
            name_en: "Lom Kao",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670402,
            zip_code: 67120,
            name_th: "นาซำ",
            name_en: "Na Sam",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670403,
            zip_code: 67120,
            name_th: "หินฮาว",
            name_en: "Hin Hao",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670404,
            zip_code: 67120,
            name_th: "บ้านเนิน",
            name_en: "Ban Noen",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670405,
            zip_code: 67120,
            name_th: "ศิลา",
            name_en: "Sila",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670406,
            zip_code: 67120,
            name_th: "นาแซง",
            name_en: "Na Saeng",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670407,
            zip_code: 67120,
            name_th: "วังบาล",
            name_en: "Wang Ban",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670408,
            zip_code: 67120,
            name_th: "นาเกาะ",
            name_en: "Na Ko",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670409,
            zip_code: 67120,
            name_th: "ตาดกลอย",
            name_en: "Tat Kloi",
            amphure_id: 6704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6705,
        name_th: "วิเชียรบุรี",
        name_en: "Wichian Buri",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670501,
            zip_code: 67130,
            name_th: "ท่าโรง",
            name_en: "Tha Rong",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670502,
            zip_code: 67130,
            name_th: "สระประดู่",
            name_en: "Sa Pradu",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670503,
            zip_code: 67130,
            name_th: "สามแยก",
            name_en: "Sam Yaek",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670504,
            zip_code: 67130,
            name_th: "โคกปรง",
            name_en: "Khok Prong",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670505,
            zip_code: 67130,
            name_th: "น้ำร้อน",
            name_en: "Nam Ron",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670506,
            zip_code: 67130,
            name_th: "บ่อรัง",
            name_en: "Bo Rang",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670507,
            zip_code: 67180,
            name_th: "พุเตย",
            name_en: "Phu Toei",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670508,
            zip_code: 67180,
            name_th: "พุขาม",
            name_en: "Phu Kham",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670509,
            zip_code: 67180,
            name_th: "ภูน้ำหยด",
            name_en: "Phu Nam Yot",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670510,
            zip_code: 67180,
            name_th: "ซับสมบูรณ์",
            name_en: "Sap Sombun",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670511,
            zip_code: 67130,
            name_th: "บึงกระจับ",
            name_en: "Bueng Krachap",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670512,
            zip_code: 67180,
            name_th: "วังใหญ่",
            name_en: "Wang Yai",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670513,
            zip_code: 67130,
            name_th: "ยางสาว",
            name_en: "Yang Sao",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670514,
            zip_code: 67180,
            name_th: "ซับน้อย",
            name_en: "Sap Noi",
            amphure_id: 6705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6706,
        name_th: "ศรีเทพ",
        name_en: "Si Thep",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670601,
            zip_code: 67170,
            name_th: "ศรีเทพ",
            name_en: "Si Thep",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670602,
            zip_code: 67170,
            name_th: "สระกรวด",
            name_en: "Sa Kruat",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670603,
            zip_code: 67170,
            name_th: "คลองกระจัง",
            name_en: "Khlong Krachang",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670604,
            zip_code: 67170,
            name_th: "นาสนุ่น",
            name_en: "Na Sanun",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670605,
            zip_code: 67170,
            name_th: "โคกสะอาด",
            name_en: "Khok Sa-at",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670606,
            zip_code: 67170,
            name_th: "หนองย่างทอย",
            name_en: "Nong Yang Thoi",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670607,
            zip_code: 67170,
            name_th: "ประดู่งาม",
            name_en: "Pradu Ngam",
            amphure_id: 6706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6707,
        name_th: "หนองไผ่",
        name_en: "Nong Phai",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670701,
            zip_code: 67140,
            name_th: "กองทูล",
            name_en: "Kong Thun",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670702,
            zip_code: 67220,
            name_th: "นาเฉลียง",
            name_en: "Na Chaliang",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670703,
            zip_code: 67140,
            name_th: "บ้านโภชน์",
            name_en: "Ban Phot",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670704,
            zip_code: 67140,
            name_th: "ท่าแดง",
            name_en: "Tha Daeng",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670705,
            zip_code: 67140,
            name_th: "เพชรละคร",
            name_en: "Phet Lakhon",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670706,
            zip_code: 67140,
            name_th: "บ่อไทย",
            name_en: "Bo Thai",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670707,
            zip_code: 67220,
            name_th: "ห้วยโป่ง",
            name_en: "Huai Pong",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670708,
            zip_code: 67140,
            name_th: "วังท่าดี",
            name_en: "Wang Tha Di",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670709,
            zip_code: 67140,
            name_th: "บัววัฒนา",
            name_en: "Bua Watthana",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670710,
            zip_code: 67140,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670711,
            zip_code: 67140,
            name_th: "วังโบสถ์",
            name_en: "Wang Bot",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670712,
            zip_code: 67220,
            name_th: "ยางงาม",
            name_en: "Yang Ngam",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670713,
            zip_code: 67140,
            name_th: "ท่าด้วง",
            name_en: "Tha Duang",
            amphure_id: 6707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6708,
        name_th: "บึงสามพัน",
        name_en: "Bueng Sam Phan",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670801,
            zip_code: 67160,
            name_th: "ซับสมอทอด",
            name_en: "Sap Samo Thot",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670802,
            zip_code: 67160,
            name_th: "ซับไม้แดง",
            name_en: "Sap Mai Daeng",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670803,
            zip_code: 67160,
            name_th: "หนองแจง",
            name_en: "Nong Chaeng",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670804,
            zip_code: 67160,
            name_th: "กันจุ",
            name_en: "Kan Chu",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670805,
            zip_code: 67230,
            name_th: "วังพิกุล",
            name_en: "Wang Phikun",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670806,
            zip_code: 67160,
            name_th: "พญาวัง",
            name_en: "Phaya Wang",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670807,
            zip_code: 67160,
            name_th: "ศรีมงคล",
            name_en: "Si Mongkhon",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670808,
            zip_code: 67160,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670809,
            zip_code: 67160,
            name_th: "บึงสามพัน",
            name_en: "Bueng Sam Phan",
            amphure_id: 6708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6709,
        name_th: "น้ำหนาว",
        name_en: "Nam Nao",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 670901,
            zip_code: 67260,
            name_th: "น้ำหนาว",
            name_en: "Nam Nao",
            amphure_id: 6709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670902,
            zip_code: 67260,
            name_th: "หลักด่าน",
            name_en: "Lak Dan",
            amphure_id: 6709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670903,
            zip_code: 67260,
            name_th: "วังกวาง",
            name_en: "Wang Kwang",
            amphure_id: 6709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 670904,
            zip_code: 67260,
            name_th: "โคกมน",
            name_en: "Khok Mon",
            amphure_id: 6709,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6710,
        name_th: "วังโป่ง",
        name_en: "Wang Pong",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 671001,
            zip_code: 67240,
            name_th: "วังโป่ง",
            name_en: "Wang Pong",
            amphure_id: 6710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671002,
            zip_code: 67240,
            name_th: "ท้ายดง",
            name_en: "Thai Dong",
            amphure_id: 6710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671003,
            zip_code: 67240,
            name_th: "ซับเปิบ",
            name_en: "Sap Poep",
            amphure_id: 6710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671004,
            zip_code: 67240,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 6710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671005,
            zip_code: 67240,
            name_th: "วังศาล",
            name_en: "Wang San",
            amphure_id: 6710,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 6711,
        name_th: "เขาค้อ",
        name_en: "Khao Kho",
        province_id: 54,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 671101,
            zip_code: 67270,
            name_th: "ทุ่งสมอ",
            name_en: "Khao Kho",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671102,
            zip_code: 67280,
            name_th: "แคมป์สน",
            name_en: "Khaem Son",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671103,
            zip_code: 67270,
            name_th: "เขาค้อ",
            name_en: "Thung Samo",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671104,
            zip_code: 67270,
            name_th: "ริมสีม่วง",
            name_en: "Rim Si Muang",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671105,
            zip_code: 67270,
            name_th: "สะเดาะพง",
            name_en: "Sado Phong",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671106,
            zip_code: 67270,
            name_th: "หนองแม่นา",
            name_en: "Nong Mae Na",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 671107,
            zip_code: 67280,
            name_th: "เข็กน้อย",
            name_en: "Khek Noi",
            amphure_id: 6711,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 55,
    name_th: "ราชบุรี",
    name_en: "Ratchaburi",
    geography_id: 4,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7001,
        name_th: "เมืองราชบุรี",
        name_en: "Mueang Ratchaburi",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700101,
            zip_code: 70000,
            name_th: "หน้าเมือง",
            name_en: "Na Mueang",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700102,
            zip_code: 70000,
            name_th: "เจดีย์หัก",
            name_en: "Chedi Hak",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700103,
            zip_code: 70000,
            name_th: "ดอนตะโก",
            name_en: "Don Tako",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700104,
            zip_code: 70000,
            name_th: "หนองกลางนา",
            name_en: "Nong Klang Na",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700105,
            zip_code: 70000,
            name_th: "ห้วยไผ่",
            name_en: "Huai Phai",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700106,
            zip_code: 70000,
            name_th: "คุ้งน้ำวน",
            name_en: "Khung Nam Won",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700107,
            zip_code: 70000,
            name_th: "คุ้งกระถิน",
            name_en: "Khung Krathin",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700108,
            zip_code: 70000,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700109,
            zip_code: 70000,
            name_th: "โคกหม้อ",
            name_en: "Khok Mo",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700110,
            zip_code: 70000,
            name_th: "สามเรือน",
            name_en: "Sam Ruean",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700111,
            zip_code: 70000,
            name_th: "พิกุลทอง",
            name_en: "Phikun Thong",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700112,
            zip_code: 70000,
            name_th: "น้ำพุ",
            name_en: "Nam Phu",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700113,
            zip_code: 70000,
            name_th: "ดอนแร่",
            name_en: "Don Rae",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700114,
            zip_code: 70000,
            name_th: "หินกอง",
            name_en: "Hin Kong",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700115,
            zip_code: 70000,
            name_th: "เขาแร้ง",
            name_en: "Khao Raeng",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700116,
            zip_code: 70000,
            name_th: "เกาะพลับพลา",
            name_en: "Ko Phlapphla",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700117,
            zip_code: 70000,
            name_th: "หลุมดิน",
            name_en: "Lum Din",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700118,
            zip_code: 70000,
            name_th: "บางป่า",
            name_en: "Bang Pa",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700119,
            zip_code: 70000,
            name_th: "พงสวาย",
            name_en: "Phong Sawai",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700120,
            zip_code: 70000,
            name_th: "คูบัว",
            name_en: "Khu Bua",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700121,
            zip_code: 70000,
            name_th: "ท่าราบ",
            name_en: "Tha Rap",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700122,
            zip_code: 70000,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 7001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7002,
        name_th: "จอมบึง",
        name_en: "Chom Bueng",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700201,
            zip_code: 70150,
            name_th: "จอมบึง",
            name_en: "Chom Bueng",
            amphure_id: 7002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700202,
            zip_code: 70150,
            name_th: "ปากช่อง",
            name_en: "Pak Chong",
            amphure_id: 7002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700203,
            zip_code: 70150,
            name_th: "เบิกไพร",
            name_en: "Boek Phrai",
            amphure_id: 7002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700204,
            zip_code: 70150,
            name_th: "ด่านทับตะโก",
            name_en: "Dan Thap Tako",
            amphure_id: 7002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700205,
            zip_code: 70150,
            name_th: "แก้มอ้น",
            name_en: "Kaem On",
            amphure_id: 7002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700206,
            zip_code: 70150,
            name_th: "รางบัว",
            name_en: "Rang Bua",
            amphure_id: 7002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7003,
        name_th: "สวนผึ้ง",
        name_en: "Suan Phueng",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700301,
            zip_code: 70180,
            name_th: "สวนผึ้ง",
            name_en: "Suan Phueng",
            amphure_id: 7003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700302,
            zip_code: 70180,
            name_th: "ป่าหวาย",
            name_en: "Pa Wai",
            amphure_id: 7003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700304,
            zip_code: 70180,
            name_th: "ท่าเคย",
            name_en: "Tha Khoei",
            amphure_id: 7003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700307,
            zip_code: 70180,
            name_th: "ตะนาวศรี",
            name_en: "Tanao Si",
            amphure_id: 7003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7004,
        name_th: "ดำเนินสะดวก",
        name_en: "Damnoen Saduak",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700401,
            zip_code: 70130,
            name_th: "ดำเนินสะดวก",
            name_en: "Damnoen Saduak",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700402,
            zip_code: 70210,
            name_th: "ประสาทสิทธิ์",
            name_en: "Prasat Sit",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700403,
            zip_code: 70130,
            name_th: "ศรีสุราษฎร์",
            name_en: "Si Surat",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700404,
            zip_code: 70130,
            name_th: "ตาหลวง",
            name_en: "Ta Luang",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700405,
            zip_code: 70130,
            name_th: "ดอนกรวย",
            name_en: "Don Kruai",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700406,
            zip_code: 70130,
            name_th: "ดอนคลัง",
            name_en: "Don Khlang",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700407,
            zip_code: 70210,
            name_th: "บัวงาม",
            name_en: "Bua Ngam",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700408,
            zip_code: 70130,
            name_th: "บ้านไร่",
            name_en: "Ban Rai",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700409,
            zip_code: 70130,
            name_th: "แพงพวย",
            name_en: "Phaengphuai",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700410,
            zip_code: 70130,
            name_th: "สี่หมื่น",
            name_en: "Si Muen",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700411,
            zip_code: 70130,
            name_th: "ท่านัด",
            name_en: "Tha Nat",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700412,
            zip_code: 70130,
            name_th: "ขุนพิทักษ์",
            name_en: "Khun Phithak",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700413,
            zip_code: 70130,
            name_th: "ดอนไผ่",
            name_en: "Don Phai",
            amphure_id: 7004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7005,
        name_th: "บ้านโป่ง",
        name_en: "Ban Pong",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700501,
            zip_code: 70110,
            name_th: "บ้านโป่ง",
            name_en: "Ban Pong",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700502,
            zip_code: 70110,
            name_th: "ท่าผา",
            name_en: "Tha Pha",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700503,
            zip_code: 70190,
            name_th: "กรับใหญ่",
            name_en: "Krap Yai",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700504,
            zip_code: 70110,
            name_th: "ปากแรต",
            name_en: "Pak Raet",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700505,
            zip_code: 70110,
            name_th: "หนองกบ",
            name_en: "Nong Kop",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700506,
            zip_code: 70110,
            name_th: "หนองอ้อ",
            name_en: "Nong O",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700507,
            zip_code: 70110,
            name_th: "ดอนกระเบื้อง",
            name_en: "Don Krabueang",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700508,
            zip_code: 70110,
            name_th: "สวนกล้วย",
            name_en: "Suan Kluai",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700509,
            zip_code: 70110,
            name_th: "นครชุมน์",
            name_en: "Nakhon Chum",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700510,
            zip_code: 70110,
            name_th: "บ้านม่วง",
            name_en: "Ban Muang",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700511,
            zip_code: 70110,
            name_th: "คุ้งพยอม",
            name_en: "Khung Phayom",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700512,
            zip_code: 70110,
            name_th: "หนองปลาหมอ",
            name_en: "Nong Pla Mo",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700513,
            zip_code: 70110,
            name_th: "เขาขลุง",
            name_en: "Khao Khlung",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700514,
            zip_code: 70110,
            name_th: "เบิกไพร",
            name_en: "Boek Phrai",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700515,
            zip_code: 70110,
            name_th: "ลาดบัวขาว",
            name_en: "Lat Bua Khao",
            amphure_id: 7005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7006,
        name_th: "บางแพ",
        name_en: "Bang Phae",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700601,
            zip_code: 70160,
            name_th: "บางแพ",
            name_en: "Bang Phae",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700602,
            zip_code: 70160,
            name_th: "วังเย็น",
            name_en: "Wang Yen",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700603,
            zip_code: 70160,
            name_th: "หัวโพ",
            name_en: "Hua Pho",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700604,
            zip_code: 70160,
            name_th: "วัดแก้ว",
            name_en: "Wat Kaeo",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700605,
            zip_code: 70160,
            name_th: "ดอนใหญ่",
            name_en: "Don Yai",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700606,
            zip_code: 70160,
            name_th: "ดอนคา",
            name_en: "Don Kha",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700607,
            zip_code: 70160,
            name_th: "โพหัก",
            name_en: "Pho Hak",
            amphure_id: 7006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7007,
        name_th: "โพธาราม",
        name_en: "Photharam",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700701,
            zip_code: 70120,
            name_th: "โพธาราม",
            name_en: "Photharam",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700702,
            zip_code: 70120,
            name_th: "ดอนกระเบื้อง",
            name_en: "Don Krabueang",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700703,
            zip_code: 70120,
            name_th: "หนองโพ",
            name_en: "Nong Pho",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700704,
            zip_code: 70120,
            name_th: "บ้านเลือก",
            name_en: "Ban Lueak",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700705,
            zip_code: 70120,
            name_th: "คลองตาคต",
            name_en: "Khlong Ta Khot",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700706,
            zip_code: 70120,
            name_th: "บ้านฆ้อง",
            name_en: "Ban Khong",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700707,
            zip_code: 70120,
            name_th: "บ้านสิงห์",
            name_en: "Ban Sing",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700708,
            zip_code: 70120,
            name_th: "ดอนทราย",
            name_en: "Don Sai",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700709,
            zip_code: 70120,
            name_th: "เจ็ดเสมียน",
            name_en: "Chet Samian",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700710,
            zip_code: 70120,
            name_th: "คลองข่อย",
            name_en: "Khlong Khoi",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700711,
            zip_code: 70120,
            name_th: "ชำแระ",
            name_en: "Chamrae",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700712,
            zip_code: 70120,
            name_th: "สร้อยฟ้า",
            name_en: "Soi Fa",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700713,
            zip_code: 70120,
            name_th: "ท่าชุมพล",
            name_en: "Tha Chumphon",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700714,
            zip_code: 70120,
            name_th: "บางโตนด",
            name_en: "Bang Tanot",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700715,
            zip_code: 70120,
            name_th: "เตาปูน",
            name_en: "Tao Pun",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700716,
            zip_code: 70120,
            name_th: "นางแก้ว",
            name_en: "Nang Kaeo",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700717,
            zip_code: 70120,
            name_th: "ธรรมเสน",
            name_en: "Thammasen",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700718,
            zip_code: 70120,
            name_th: "เขาชะงุ้ม",
            name_en: "Khao Cha-ngum",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700719,
            zip_code: 70120,
            name_th: "หนองกวาง",
            name_en: "Nong Kwang",
            amphure_id: 7007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7008,
        name_th: "ปากท่อ",
        name_en: "Pak Tho",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700801,
            zip_code: 70140,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700802,
            zip_code: 70140,
            name_th: "วังมะนาว",
            name_en: "Wang Manao",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700803,
            zip_code: 70140,
            name_th: "ดอนทราย",
            name_en: "Don Sai",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700804,
            zip_code: 70140,
            name_th: "หนองกระทุ่ม",
            name_en: "Nong Krathum",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700805,
            zip_code: 70140,
            name_th: "ปากท่อ",
            name_en: "Pak Tho",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700806,
            zip_code: 70140,
            name_th: "ป่าไก่",
            name_en: "Pa Kai",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700807,
            zip_code: 70140,
            name_th: "วัดยางงาม",
            name_en: "Wat Yang Ngam",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700808,
            zip_code: 70140,
            name_th: "อ่างหิน",
            name_en: "Ang Hin",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700809,
            zip_code: 70140,
            name_th: "บ่อกระดาน",
            name_en: "Bo Kradan",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700810,
            zip_code: 70140,
            name_th: "ยางหัก",
            name_en: "Yang Hak",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700811,
            zip_code: 70140,
            name_th: "วันดาว",
            name_en: "Wan Dao",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700812,
            zip_code: 70140,
            name_th: "ห้วยยางโทน",
            name_en: "Huai Yang Thon",
            amphure_id: 7008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7009,
        name_th: "วัดเพลง",
        name_en: "Wat Phleng",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 700901,
            zip_code: 70170,
            name_th: "เกาะศาลพระ",
            name_en: "Ko San Phra",
            amphure_id: 7009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700902,
            zip_code: 70170,
            name_th: "จอมประทัด",
            name_en: "Chom Prathat",
            amphure_id: 7009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 700903,
            zip_code: 70170,
            name_th: "วัดเพลง",
            name_en: "Wat Pleng",
            amphure_id: 7009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7010,
        name_th: "บ้านคา",
        name_en: "Ban Kha",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 701001,
            zip_code: 70180,
            name_th: "บ้านคา",
            name_en: "Ban Kha",
            amphure_id: 7010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 701002,
            zip_code: 70180,
            name_th: "บ้านบึง",
            name_en: "Ban Bueng",
            amphure_id: 7010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 701003,
            zip_code: 70180,
            name_th: "หนองพันจันทร์",
            name_en: "Nong Phan Chan",
            amphure_id: 7010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7074,
        name_th: "ท้องถิ่นเทศบาลตำบลบ้านฆ้อง",
        name_en: "Tet Saban Ban Kong",
        province_id: 55,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [],
      },
    ],
  },
  {
    id: 56,
    name_th: "กาญจนบุรี",
    name_en: "Kanchanaburi",
    geography_id: 4,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7101,
        name_th: "เมืองกาญจนบุรี",
        name_en: "Mueang Kanchanaburi",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710101,
            zip_code: 71000,
            name_th: "บ้านเหนือ",
            name_en: "Ban Nuea",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710102,
            zip_code: 71000,
            name_th: "บ้านใต้",
            name_en: "Ban Tai",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710103,
            zip_code: 71000,
            name_th: "ปากแพรก",
            name_en: "Pak Phraek",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710104,
            zip_code: 71000,
            name_th: "ท่ามะขาม",
            name_en: "Tha Makham",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710105,
            zip_code: 71000,
            name_th: "แก่งเสี้ยน",
            name_en: "Kaeng Sian",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710106,
            zip_code: 71190,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710107,
            zip_code: 71190,
            name_th: "ลาดหญ้า",
            name_en: "Lat Ya",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710108,
            zip_code: 71190,
            name_th: "วังด้ง",
            name_en: "Wang Dong",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710109,
            zip_code: 71190,
            name_th: "ช่องสะเดา",
            name_en: "Chong Sadao",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710110,
            zip_code: 71000,
            name_th: "หนองหญ้า",
            name_en: "Nong Ya",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710111,
            zip_code: 71000,
            name_th: "เกาะสำโรง",
            name_en: "Ko Samrong",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710113,
            zip_code: 71000,
            name_th: "บ้านเก่า",
            name_en: "Ban Kao",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710116,
            zip_code: 71000,
            name_th: "วังเย็น",
            name_en: "Wang Yen",
            amphure_id: 7101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7102,
        name_th: "ไทรโยค",
        name_en: "Sai Yok",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710201,
            zip_code: 71150,
            name_th: "ลุ่มสุ่ม",
            name_en: "Lum Sum",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710202,
            zip_code: 71150,
            name_th: "ท่าเสา",
            name_en: "Tha Sao",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710203,
            zip_code: 71150,
            name_th: "สิงห์",
            name_en: "Sing",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710204,
            zip_code: 71150,
            name_th: "ไทรโยค",
            name_en: "Sai Yok",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710205,
            zip_code: 71150,
            name_th: "วังกระแจะ",
            name_en: "Wang Krachae",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710206,
            zip_code: 71150,
            name_th: "ศรีมงคล",
            name_en: "Si Mongkhon",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710207,
            zip_code: 71150,
            name_th: "บ้องตี้",
            name_en: "Bongti",
            amphure_id: 7102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7103,
        name_th: "บ่อพลอย",
        name_en: "Bo Phloi",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710301,
            zip_code: 71160,
            name_th: "บ่อพลอย",
            name_en: "Bo Phloi",
            amphure_id: 7103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710302,
            zip_code: 71160,
            name_th: "หนองกุ่ม",
            name_en: "Nong Kum",
            amphure_id: 7103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710303,
            zip_code: 71220,
            name_th: "หนองรี",
            name_en: "Nong Ri",
            amphure_id: 7103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710305,
            zip_code: 71160,
            name_th: "หลุมรัง",
            name_en: "Lum Rang",
            amphure_id: 7103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710308,
            zip_code: 71160,
            name_th: "ช่องด่าน",
            name_en: "Chong Dan",
            amphure_id: 7103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710309,
            zip_code: 71220,
            name_th: "หนองกร่าง",
            name_en: "Nong Krang",
            amphure_id: 7103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7104,
        name_th: "ศรีสวัสดิ์",
        name_en: "Si Sawat",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710401,
            zip_code: 71250,
            name_th: "นาสวน",
            name_en: "Na Suan",
            amphure_id: 7104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710402,
            zip_code: 71250,
            name_th: "ด่านแม่แฉลบ",
            name_en: "Dan Mae Chalaep",
            amphure_id: 7104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710403,
            zip_code: 71250,
            name_th: "หนองเป็ด",
            name_en: "Nong Pet",
            amphure_id: 7104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710404,
            zip_code: 71250,
            name_th: "ท่ากระดาน",
            name_en: "Tha Kradan",
            amphure_id: 7104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710405,
            zip_code: 71220,
            name_th: "เขาโจด",
            name_en: "Khao Chot",
            amphure_id: 7104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710406,
            zip_code: 71250,
            name_th: "แม่กระบุง",
            name_en: "Mae Krabung",
            amphure_id: 7104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7105,
        name_th: "ท่ามะกา",
        name_en: "Tha Maka",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710501,
            zip_code: 71120,
            name_th: "พงตึก",
            name_en: "Phong Tuek",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710502,
            zip_code: 71120,
            name_th: "ยางม่วง",
            name_en: "Yang Muang",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710503,
            zip_code: 71130,
            name_th: "ดอนชะเอม",
            name_en: "Don Cha-em",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710504,
            zip_code: 71120,
            name_th: "ท่าไม้",
            name_en: "Tha Mai",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710505,
            zip_code: 71130,
            name_th: "ตะคร้ำเอน",
            name_en: "Takhram En",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710506,
            zip_code: 71120,
            name_th: "ท่ามะกา",
            name_en: "Tha Maka",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710507,
            zip_code: 71130,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710508,
            zip_code: 71120,
            name_th: "โคกตะบอง",
            name_en: "Khok Tabong",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710509,
            zip_code: 71120,
            name_th: "ดอนขมิ้น",
            name_en: "Don Khamin",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710510,
            zip_code: 71130,
            name_th: "อุโลกสี่หมื่น",
            name_en: "Ulok Si Muen",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710511,
            zip_code: 71120,
            name_th: "เขาสามสิบหาบ",
            name_en: "Khao Samsip Hap",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710512,
            zip_code: 71130,
            name_th: "พระแท่น",
            name_en: "Phra Thaen",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710513,
            zip_code: 71120,
            name_th: "หวายเหนียว",
            name_en: "Wai Niao",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710514,
            zip_code: 71130,
            name_th: "แสนตอ",
            name_en: "Saen To",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710515,
            zip_code: 70190,
            name_th: "สนามแย้",
            name_en: "Sanam Yae",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710516,
            zip_code: 71120,
            name_th: "ท่าเสา",
            name_en: "Tha Sao",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710517,
            zip_code: 71130,
            name_th: "หนองลาน",
            name_en: "Nong Lan",
            amphure_id: 7105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7106,
        name_th: "ท่าม่วง",
        name_en: "Tha Muang",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710601,
            zip_code: 71110,
            name_th: "ท่าม่วง",
            name_en: "Tha Muang",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710602,
            zip_code: 71110,
            name_th: "วังขนาย",
            name_en: "Wang Khanai",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710603,
            zip_code: 71110,
            name_th: "วังศาลา",
            name_en: "Wang Sala",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710604,
            zip_code: 71110,
            name_th: "ท่าล้อ",
            name_en: "Tha Lo",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710605,
            zip_code: 71110,
            name_th: "หนองขาว",
            name_en: "Nong Khao",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710606,
            zip_code: 71110,
            name_th: "ทุ่งทอง",
            name_en: "Thung Thong",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710607,
            zip_code: 71110,
            name_th: "เขาน้อย",
            name_en: "Khao Noi",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710608,
            zip_code: 71110,
            name_th: "ม่วงชุม",
            name_en: "Muang Chum",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710609,
            zip_code: 71110,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710610,
            zip_code: 71110,
            name_th: "พังตรุ",
            name_en: "Phang Tru",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710611,
            zip_code: 71130,
            name_th: "ท่าตะคร้อ",
            name_en: "Tha Takhro",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710612,
            zip_code: 71110,
            name_th: "รางสาลี่",
            name_en: "Rang Sali",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710613,
            zip_code: 71110,
            name_th: "หนองตากยา",
            name_en: "Nong Tak Ya",
            amphure_id: 7106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7107,
        name_th: "ทองผาภูมิ",
        name_en: "Pha Phum",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710701,
            zip_code: 71180,
            name_th: "ท่าขนุน",
            name_en: "Tha Khanun",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710702,
            zip_code: 71180,
            name_th: "ปิล๊อก",
            name_en: "Pilok",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710703,
            zip_code: 71180,
            name_th: "หินดาด",
            name_en: "Hin Dat",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710704,
            zip_code: 71180,
            name_th: "ลิ่นถิ่น",
            name_en: "Linthin",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710705,
            zip_code: 71180,
            name_th: "ชะแล",
            name_en: "Chalae",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710706,
            zip_code: 71180,
            name_th: "ห้วยเขย่ง",
            name_en: "Huai Khayeng",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710707,
            zip_code: 71180,
            name_th: "สหกรณ์นิคม",
            name_en: "Sahakon Nikhom",
            amphure_id: 7107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7108,
        name_th: "สังขละบุรี",
        name_en: "Sangkhla Buri",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710801,
            zip_code: 71240,
            name_th: "หนองลู",
            name_en: "Nong Lu",
            amphure_id: 7108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710802,
            zip_code: 71240,
            name_th: "ปรังเผล",
            name_en: "Prangphle",
            amphure_id: 7108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710803,
            zip_code: 71240,
            name_th: "ไล่โว่",
            name_en: "Lai Wo",
            amphure_id: 7108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7109,
        name_th: "พนมทวน",
        name_en: "Phanom Thuan",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 710901,
            zip_code: 71140,
            name_th: "พนมทวน",
            name_en: "Phanom Thuan",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710902,
            zip_code: 71140,
            name_th: "หนองโรง",
            name_en: "Nong Rong",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710903,
            zip_code: 71140,
            name_th: "ทุ่งสมอ",
            name_en: "Thung Samo",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710904,
            zip_code: 71140,
            name_th: "ดอนเจดีย์",
            name_en: "Don Chedi",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710905,
            zip_code: 71140,
            name_th: "พังตรุ",
            name_en: "Phang Tru",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710906,
            zip_code: 71170,
            name_th: "รางหวาย",
            name_en: "Rang Wai",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710911,
            zip_code: 71140,
            name_th: "หนองสาหร่าย",
            name_en: "Nong Sarai",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 710912,
            zip_code: 71140,
            name_th: "ดอนตาเพชร",
            name_en: "Don Ta Phet",
            amphure_id: 7109,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7110,
        name_th: "เลาขวัญ",
        name_en: "Lao Khwan",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 711001,
            zip_code: 71210,
            name_th: "เลาขวัญ",
            name_en: "Lao Khwan",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711002,
            zip_code: 71210,
            name_th: "หนองโสน",
            name_en: "Nong Sano",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711003,
            zip_code: 71210,
            name_th: "หนองประดู่",
            name_en: "Nong Pradu",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711004,
            zip_code: 71210,
            name_th: "หนองปลิง",
            name_en: "Nong Pling",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711005,
            zip_code: 71210,
            name_th: "หนองนกแก้ว",
            name_en: "Nong Nok Kaeo",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711006,
            zip_code: 71210,
            name_th: "ทุ่งกระบ่ำ",
            name_en: "Thung Krabam",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711007,
            zip_code: 71210,
            name_th: "หนองฝ้าย",
            name_en: "Nong Fai",
            amphure_id: 7110,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7111,
        name_th: "ด่านมะขามเตี้ย",
        name_en: "Dan Makham Tia",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 711101,
            zip_code: 71260,
            name_th: "ด่านมะขามเตี้ย",
            name_en: "Dan Makham Tia",
            amphure_id: 7111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711102,
            zip_code: 71260,
            name_th: "กลอนโด",
            name_en: "Klondo",
            amphure_id: 7111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711103,
            zip_code: 71260,
            name_th: "จรเข้เผือก",
            name_en: "Chorakhe Phueak",
            amphure_id: 7111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711104,
            zip_code: 71260,
            name_th: "หนองไผ่",
            name_en: "Nong Phai",
            amphure_id: 7111,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7112,
        name_th: "หนองปรือ",
        name_en: "Nong Prue",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 711201,
            zip_code: 71220,
            name_th: "หนองปรือ",
            name_en: "Nong Prue",
            amphure_id: 7112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711202,
            zip_code: 71220,
            name_th: "หนองปลาไหล",
            name_en: "Nong Pla Lai",
            amphure_id: 7112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711203,
            zip_code: 71220,
            name_th: "สมเด็จเจริญ",
            name_en: "Somdet Charoen",
            amphure_id: 7112,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7113,
        name_th: "ห้วยกระเจา",
        name_en: "Huai Krachao",
        province_id: 56,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 711301,
            zip_code: 71170,
            name_th: "ห้วยกระเจา",
            name_en: "Huai Krachao",
            amphure_id: 7113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711302,
            zip_code: 71170,
            name_th: "วังไผ่",
            name_en: "Wang Phai",
            amphure_id: 7113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711303,
            zip_code: 71170,
            name_th: "ดอนแสลบ",
            name_en: "Don Salaep",
            amphure_id: 7113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 711304,
            zip_code: 71170,
            name_th: "สระลงเรือ",
            name_en: "Sa Long Ruea",
            amphure_id: 7113,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 57,
    name_th: "สุพรรณบุรี",
    name_en: "Suphan Buri",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7201,
        name_th: "เมืองสุพรรณบุรี",
        name_en: "Mueang Suphan Buri",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720101,
            zip_code: 72000,
            name_th: "ท่าพี่เลี้ยง",
            name_en: "Tha Phi Liang",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720102,
            zip_code: 72000,
            name_th: "รั้วใหญ่",
            name_en: "Rua Yai",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720103,
            zip_code: 72000,
            name_th: "ทับตีเหล็ก",
            name_en: "Thap Ti Lek",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720104,
            zip_code: 72000,
            name_th: "ท่าระหัด",
            name_en: "Tha Rahat",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720105,
            zip_code: 72000,
            name_th: "ไผ่ขวาง",
            name_en: "Phai Kwang",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720106,
            zip_code: 72000,
            name_th: "โคกโคเฒ่า",
            name_en: "Khok Kho Thao",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720107,
            zip_code: 72000,
            name_th: "ดอนตาล",
            name_en: "Don Tan",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720108,
            zip_code: 72000,
            name_th: "ดอนมะสังข์",
            name_en: "Don Masang",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720109,
            zip_code: 72000,
            name_th: "พิหารแดง",
            name_en: "Phihan Daeng",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720110,
            zip_code: 72000,
            name_th: "ดอนกำยาน",
            name_en: "Don Kamyan",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720111,
            zip_code: 72000,
            name_th: "ดอนโพธิ์ทอง",
            name_en: "Don Pho Thong",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720112,
            zip_code: 72000,
            name_th: "บ้านโพธิ์",
            name_en: "Ban Pho",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720113,
            zip_code: 72230,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720114,
            zip_code: 72230,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720115,
            zip_code: 72210,
            name_th: "บางกุ้ง",
            name_en: "Bang Kung",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720116,
            zip_code: 72210,
            name_th: "ศาลาขาว",
            name_en: "Sala Khao",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720117,
            zip_code: 72210,
            name_th: "สวนแตง",
            name_en: "Suan Taeng",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720118,
            zip_code: 72000,
            name_th: "สนามชัย",
            name_en: "Sanam Chai",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720119,
            zip_code: 72000,
            name_th: "โพธิ์พระยา",
            name_en: "Pho Phraya",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720120,
            zip_code: 72230,
            name_th: "สนามคลี",
            name_en: "Sanam Klee",
            amphure_id: 7201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7202,
        name_th: "เดิมบางนางบวช",
        name_en: "Doem Bang Nang Buat",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720201,
            zip_code: 72120,
            name_th: "เขาพระ",
            name_en: "Khao Phra",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720202,
            zip_code: 72120,
            name_th: "เดิมบาง",
            name_en: "Doem Bang",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720203,
            zip_code: 72120,
            name_th: "นางบวช",
            name_en: "Nang Buat",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720204,
            zip_code: 72120,
            name_th: "เขาดิน",
            name_en: "Khao Din",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720205,
            zip_code: 72120,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720206,
            zip_code: 72120,
            name_th: "ทุ่งคลี",
            name_en: "Thung Khli",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720207,
            zip_code: 72120,
            name_th: "โคกช้าง",
            name_en: "Khok Chang",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720208,
            zip_code: 72120,
            name_th: "หัวเขา",
            name_en: "Hua Khao",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720209,
            zip_code: 72120,
            name_th: "หัวนา",
            name_en: "Hua Na",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720210,
            zip_code: 72120,
            name_th: "บ่อกรุ",
            name_en: "Bo Kru",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720211,
            zip_code: 72120,
            name_th: "วังศรีราช",
            name_en: "Wang Si Rat",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720212,
            zip_code: 72120,
            name_th: "ป่าสะแก",
            name_en: "Pa Sakae",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720213,
            zip_code: 72120,
            name_th: "ยางนอน",
            name_en: "Yang Non",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720214,
            zip_code: 72120,
            name_th: "หนองกระทุ่ม",
            name_en: "Nong Krathum",
            amphure_id: 7202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7203,
        name_th: "ด่านช้าง",
        name_en: "Dan Chang",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720301,
            zip_code: 72180,
            name_th: "หนองมะค่าโมง",
            name_en: "Nong Makha Mong",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720302,
            zip_code: 72180,
            name_th: "ด่านช้าง",
            name_en: "Dan Chang",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720303,
            zip_code: 72180,
            name_th: "ห้วยขมิ้น",
            name_en: "Huai Khamin",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720304,
            zip_code: 72180,
            name_th: "องค์พระ",
            name_en: "Ong Phra",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720305,
            zip_code: 72180,
            name_th: "วังคัน",
            name_en: "Wang Khan",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720306,
            zip_code: 72180,
            name_th: "นิคมกระเสียว",
            name_en: "Nikhom Krasiao",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720307,
            zip_code: 72180,
            name_th: "วังยาว",
            name_en: "Wang Yao",
            amphure_id: 7203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7204,
        name_th: "บางปลาม้า",
        name_en: "Bang Pla Ma",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720401,
            zip_code: 72150,
            name_th: "โคกคราม",
            name_en: "Khok Khram",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720402,
            zip_code: 72150,
            name_th: "บางปลาม้า",
            name_en: "Bang Pla Ma",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720403,
            zip_code: 72150,
            name_th: "ตะค่า",
            name_en: "Takha",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720404,
            zip_code: 72150,
            name_th: "บางใหญ่",
            name_en: "Bang Yai",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720405,
            zip_code: 72150,
            name_th: "กฤษณา",
            name_en: "Kritsana",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720406,
            zip_code: 72150,
            name_th: "สาลี",
            name_en: "Sali",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720407,
            zip_code: 72150,
            name_th: "ไผ่กองดิน",
            name_en: "Phai Kong Din",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720408,
            zip_code: 72150,
            name_th: "องครักษ์",
            name_en: "Ongkharak",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720409,
            zip_code: 72150,
            name_th: "จรเข้ใหญ่",
            name_en: "Chorakhe Yai",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720410,
            zip_code: 72150,
            name_th: "บ้านแหลม",
            name_en: "Ban Laem",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720411,
            zip_code: 72150,
            name_th: "มะขามล้ม",
            name_en: "Makham Lom",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720412,
            zip_code: 72150,
            name_th: "วังน้ำเย็น",
            name_en: "Wang Nam Yen",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720413,
            zip_code: 72150,
            name_th: "วัดโบสถ์",
            name_en: "Wat Bot",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720414,
            zip_code: 72150,
            name_th: "วัดดาว",
            name_en: "Wad Daw",
            amphure_id: 7204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7205,
        name_th: "ศรีประจันต์",
        name_en: "Si Prachan",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720501,
            zip_code: 72140,
            name_th: "ศรีประจันต์",
            name_en: "Si Prachan",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720502,
            zip_code: 72140,
            name_th: "บ้านกร่าง",
            name_en: "Ban Krang",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720503,
            zip_code: 72140,
            name_th: "มดแดง",
            name_en: "Mot Daeng",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720504,
            zip_code: 72140,
            name_th: "บางงาม",
            name_en: "Bang Ngam",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720505,
            zip_code: 72140,
            name_th: "ดอนปรู",
            name_en: "Don Pru",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720506,
            zip_code: 72140,
            name_th: "ปลายนา",
            name_en: "Plai Na",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720507,
            zip_code: 72140,
            name_th: "วังหว้า",
            name_en: "Wang Wa",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720508,
            zip_code: 72140,
            name_th: "วังน้ำซับ",
            name_en: "Wang Nam Sap",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720509,
            zip_code: 72140,
            name_th: "วังยาง",
            name_en: "Wang Yang",
            amphure_id: 7205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7206,
        name_th: "ดอนเจดีย์",
        name_en: "Don Chedi",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720601,
            zip_code: 72170,
            name_th: "ดอนเจดีย์",
            name_en: "Don Chedi",
            amphure_id: 7206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720602,
            zip_code: 72170,
            name_th: "หนองสาหร่าย",
            name_en: "Nong Sarai",
            amphure_id: 7206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720603,
            zip_code: 72170,
            name_th: "ไร่รถ",
            name_en: "Rai Rot",
            amphure_id: 7206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720604,
            zip_code: 72250,
            name_th: "สระกระโจม",
            name_en: "Sa Krachom",
            amphure_id: 7206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720605,
            zip_code: 72250,
            name_th: "ทะเลบก",
            name_en: "Talae Bok",
            amphure_id: 7206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7207,
        name_th: "สองพี่น้อง",
        name_en: "Song Phi Nong",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720701,
            zip_code: 72110,
            name_th: "สองพี่น้อง",
            name_en: "Song Phi Nong",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720702,
            zip_code: 72110,
            name_th: "บางเลน",
            name_en: "Bang Len",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720703,
            zip_code: 72110,
            name_th: "บางตาเถร",
            name_en: "Bang Ta Then",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720704,
            zip_code: 72110,
            name_th: "บางตะเคียน",
            name_en: "Bang Takhian",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720705,
            zip_code: 72110,
            name_th: "บ้านกุ่ม",
            name_en: "Ban Kum",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720706,
            zip_code: 72110,
            name_th: "หัวโพธิ์",
            name_en: "Hua Pho",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720707,
            zip_code: 72110,
            name_th: "บางพลับ",
            name_en: "Bang Phlap",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720708,
            zip_code: 72110,
            name_th: "เนินพระปรางค์",
            name_en: "Noen Phra Prang",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720709,
            zip_code: 72110,
            name_th: "บ้านช้าง",
            name_en: "Ban Chang",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720710,
            zip_code: 72110,
            name_th: "ต้นตาล",
            name_en: "Ton Tan",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720711,
            zip_code: 72110,
            name_th: "ศรีสำราญ",
            name_en: "Si Samran",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720712,
            zip_code: 72190,
            name_th: "ทุ่งคอก",
            name_en: "Thung Khok",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720713,
            zip_code: 72110,
            name_th: "หนองบ่อ",
            name_en: "Nong Bo",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720714,
            zip_code: 72190,
            name_th: "บ่อสุพรรณ",
            name_en: "Bo Suphan",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720715,
            zip_code: 72110,
            name_th: "ดอนมะนาว",
            name_en: "Don Manao",
            amphure_id: 7207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7208,
        name_th: "สามชุก",
        name_en: "Sam Chuk",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720801,
            zip_code: 72130,
            name_th: "ย่านยาว",
            name_en: "Yan Yao",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720802,
            zip_code: 72130,
            name_th: "วังลึก",
            name_en: "Wang Luek",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720803,
            zip_code: 72130,
            name_th: "สามชุก",
            name_en: "Sam Chuk",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720804,
            zip_code: 72130,
            name_th: "หนองผักนาก",
            name_en: "Nong Phak Nak",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720805,
            zip_code: 72130,
            name_th: "บ้านสระ",
            name_en: "Ban Sa",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720806,
            zip_code: 72130,
            name_th: "หนองสะเดา",
            name_en: "Nong Sadao",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720807,
            zip_code: 72130,
            name_th: "กระเสียว",
            name_en: "Krasiao",
            amphure_id: 7208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7209,
        name_th: "อู่ทอง",
        name_en: "U Thong",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 720901,
            zip_code: 72160,
            name_th: "อู่ทอง",
            name_en: "U Thong",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720902,
            zip_code: 72220,
            name_th: "สระยายโสม",
            name_en: "Sa Yai Som",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720903,
            zip_code: 72160,
            name_th: "จรเข้สามพัน",
            name_en: "Chorakhe Sam Phan",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720904,
            zip_code: 72160,
            name_th: "บ้านดอน",
            name_en: "Ban Don",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720905,
            zip_code: 72160,
            name_th: "ยุ้งทะลาย",
            name_en: "Yung Thalai",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720906,
            zip_code: 72220,
            name_th: "ดอนมะเกลือ",
            name_en: "Don Makluea",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720907,
            zip_code: 72160,
            name_th: "หนองโอ่ง",
            name_en: "Nong Ong",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720908,
            zip_code: 72160,
            name_th: "ดอนคา",
            name_en: "Don Kha",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720909,
            zip_code: 72160,
            name_th: "พลับพลาไชย",
            name_en: "Phlapphla Chai",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720910,
            zip_code: 72160,
            name_th: "บ้านโข้ง",
            name_en: "Ban Khong",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720911,
            zip_code: 72160,
            name_th: "เจดีย์",
            name_en: "Chedi",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720912,
            zip_code: 72220,
            name_th: "สระพังลาน",
            name_en: "Sa Phang Lan",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 720913,
            zip_code: 72160,
            name_th: "กระจัน",
            name_en: "Krachan",
            amphure_id: 7209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7210,
        name_th: "หนองหญ้าไซ",
        name_en: "Nong Ya Sai",
        province_id: 57,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 721001,
            zip_code: 72240,
            name_th: "หนองหญ้าไซ",
            name_en: "Nong Ya Sai",
            amphure_id: 7210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 721002,
            zip_code: 72240,
            name_th: "หนองราชวัตร",
            name_en: "Nong Ratchawat",
            amphure_id: 7210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 721003,
            zip_code: 72240,
            name_th: "หนองโพธิ์",
            name_en: "Nong Pho",
            amphure_id: 7210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 721004,
            zip_code: 72240,
            name_th: "แจงงาม",
            name_en: "Chaeng Ngam",
            amphure_id: 7210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 721005,
            zip_code: 72240,
            name_th: "หนองขาม",
            name_en: "Nong Kham",
            amphure_id: 7210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 721006,
            zip_code: 72240,
            name_th: "ทัพหลวง",
            name_en: "Thap Luang",
            amphure_id: 7210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 58,
    name_th: "นครปฐม",
    name_en: "Nakhon Pathom",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7301,
        name_th: "เมืองนครปฐม",
        name_en: "Mueang Nakhon Pathom",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730101,
            zip_code: 73000,
            name_th: "พระปฐมเจดีย์",
            name_en: "Phra Pathom Chedi",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730102,
            zip_code: 73000,
            name_th: "บางแขม",
            name_en: "Bang Khaem",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730103,
            zip_code: 73000,
            name_th: "พระประโทน",
            name_en: "Phra Prathon",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730104,
            zip_code: 73000,
            name_th: "ธรรมศาลา",
            name_en: "Thammasala",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730105,
            zip_code: 73000,
            name_th: "ตาก้อง",
            name_en: "Ta Kong",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730106,
            zip_code: 73000,
            name_th: "มาบแค",
            name_en: "Map Khae",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730107,
            zip_code: 73000,
            name_th: "สนามจันทร์",
            name_en: "Sanam Chan",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730108,
            zip_code: 73000,
            name_th: "ดอนยายหอม",
            name_en: "Don Yai Hom",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730109,
            zip_code: 73000,
            name_th: "ถนนขาด",
            name_en: "Thanon Khat",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730110,
            zip_code: 73000,
            name_th: "บ่อพลับ",
            name_en: "Bo Phlap",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730111,
            zip_code: 73000,
            name_th: "นครปฐม",
            name_en: "Nakhon Pathom",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730112,
            zip_code: 73000,
            name_th: "วังตะกู",
            name_en: "Wang Taku",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730113,
            zip_code: 73000,
            name_th: "หนองปากโลง",
            name_en: "Nong Pak Long",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730114,
            zip_code: 73000,
            name_th: "สามควายเผือก",
            name_en: "Sam Khwai Phueak",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730115,
            zip_code: 73000,
            name_th: "ทุ่งน้อย",
            name_en: "Thung Noi",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730116,
            zip_code: 73000,
            name_th: "หนองดินแดง",
            name_en: "Nong Din Daeng",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730117,
            zip_code: 73000,
            name_th: "วังเย็น",
            name_en: "Wang Yen",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730118,
            zip_code: 73000,
            name_th: "โพรงมะเดื่อ",
            name_en: "Phrong Maduea",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730119,
            zip_code: 73000,
            name_th: "ลำพยา",
            name_en: "Lam Phaya",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730120,
            zip_code: 73000,
            name_th: "สระกะเทียม",
            name_en: "Sa Kathiam",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730121,
            zip_code: 73000,
            name_th: "สวนป่าน",
            name_en: "Suan Pan",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730122,
            zip_code: 73000,
            name_th: "ห้วยจรเข้",
            name_en: "Huai Chorakhe",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730123,
            zip_code: 73000,
            name_th: "ทัพหลวง",
            name_en: "Thap Luang",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730124,
            zip_code: 73000,
            name_th: "หนองงูเหลือม",
            name_en: "Nong Ngulueam",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730125,
            zip_code: 73000,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 7301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7302,
        name_th: "กำแพงแสน",
        name_en: "Kamphaeng Saen",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730201,
            zip_code: 73140,
            name_th: "ทุ่งกระพังโหม",
            name_en: "Thung Kraphanghom",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730202,
            zip_code: 73180,
            name_th: "กระตีบ",
            name_en: "Kratip",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730203,
            zip_code: 73140,
            name_th: "ทุ่งลูกนก",
            name_en: "Thung Luk Nok",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730204,
            zip_code: 73140,
            name_th: "ห้วยขวาง",
            name_en: "Huai Khwang",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730205,
            zip_code: 73140,
            name_th: "ทุ่งขวาง",
            name_en: "Thung Khwang",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730206,
            zip_code: 73140,
            name_th: "สระสี่มุม",
            name_en: "Sa Si Mum",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730207,
            zip_code: 73140,
            name_th: "ทุ่งบัว",
            name_en: "Thung Bua",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730208,
            zip_code: 73140,
            name_th: "ดอนข่อย",
            name_en: "Don Khoi",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730209,
            zip_code: 73180,
            name_th: "สระพัฒนา",
            name_en: "Sa Phatthana",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730210,
            zip_code: 73140,
            name_th: "ห้วยหมอนทอง",
            name_en: "Huai Mon Thong",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730211,
            zip_code: 73180,
            name_th: "ห้วยม่วง",
            name_en: "Huai Muang",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730212,
            zip_code: 73140,
            name_th: "กำแพงแสน",
            name_en: "Kamphaeng Saen",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730213,
            zip_code: 73140,
            name_th: "รางพิกุล",
            name_en: "Rang Phikun",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730214,
            zip_code: 73140,
            name_th: "หนองกระทุ่ม",
            name_en: "Nong Krathum",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730215,
            zip_code: 73140,
            name_th: "วังน้ำเขียว",
            name_en: "Wang Nam Khiao",
            amphure_id: 7302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7303,
        name_th: "นครชัยศรี",
        name_en: "Nakhon Chai Si",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730301,
            zip_code: 73120,
            name_th: "นครชัยศรี",
            name_en: "Nakhon Chai Si",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730302,
            zip_code: 73120,
            name_th: "บางกระเบา",
            name_en: "Bang Krabao",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730303,
            zip_code: 73120,
            name_th: "วัดแค",
            name_en: "Wat Khae",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730304,
            zip_code: 73120,
            name_th: "ท่าตำหนัก",
            name_en: "Tha Tamnak",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730305,
            zip_code: 73120,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730306,
            zip_code: 73120,
            name_th: "ท่ากระชับ",
            name_en: "Tha Krachap",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730307,
            zip_code: 73120,
            name_th: "ขุนแก้ว",
            name_en: "Khun Kaeo",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730308,
            zip_code: 73120,
            name_th: "ท่าพระยา",
            name_en: "Tha Phraya",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730309,
            zip_code: 73120,
            name_th: "พะเนียด",
            name_en: "Phaniat",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730310,
            zip_code: 73120,
            name_th: "บางระกำ",
            name_en: "Bang Rakam",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730311,
            zip_code: 73120,
            name_th: "โคกพระเจดีย์",
            name_en: "Khok Phra Chedi",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730312,
            zip_code: 73120,
            name_th: "ศรีษะทอง",
            name_en: "Sisa Thong",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730313,
            zip_code: 73120,
            name_th: "แหลมบัว",
            name_en: "Laem Bua",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730314,
            zip_code: 73120,
            name_th: "ศรีมหาโพธิ์",
            name_en: "Si Maha Pho",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730315,
            zip_code: 73120,
            name_th: "สัมปทวน",
            name_en: "Sampathuan",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730316,
            zip_code: 73120,
            name_th: "วัดสำโรง",
            name_en: "Wat Samrong",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730317,
            zip_code: 73120,
            name_th: "ดอนแฝก",
            name_en: "Don Faek",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730318,
            zip_code: 73120,
            name_th: "ห้วยพลู",
            name_en: "Huai Phlu",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730319,
            zip_code: 73120,
            name_th: "วัดละมุด",
            name_en: "Wat Lamut",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730320,
            zip_code: 73120,
            name_th: "บางพระ",
            name_en: "Bang Phra",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730321,
            zip_code: 73120,
            name_th: "บางแก้วฟ้า",
            name_en: "Bang Kaeo Fa",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730322,
            zip_code: 73120,
            name_th: "ลานตากฟ้า",
            name_en: "Lan Tak Fa",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730323,
            zip_code: 73120,
            name_th: "งิ้วราย",
            name_en: "Ngio Rai",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730324,
            zip_code: 73120,
            name_th: "ไทยาวาส",
            name_en: "Thaiyawat",
            amphure_id: 7303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7304,
        name_th: "ดอนตูม",
        name_en: "Don Tum",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730401,
            zip_code: 73150,
            name_th: "สามง่าม",
            name_en: "Sam Ngam",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730402,
            zip_code: 73150,
            name_th: "ห้วยพระ",
            name_en: "Huai Phra",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730403,
            zip_code: 73150,
            name_th: "ลำเหย",
            name_en: "Lam Hoei",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730404,
            zip_code: 73150,
            name_th: "ดอนพุทรา",
            name_en: "Don Phutsa",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730405,
            zip_code: 73150,
            name_th: "บ้านหลวง",
            name_en: "Ban Luang",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730406,
            zip_code: 73150,
            name_th: "ดอนรวก",
            name_en: "Don Ruak",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730407,
            zip_code: 73150,
            name_th: "ห้วยด้วน",
            name_en: "Huai Duan",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730408,
            zip_code: 73150,
            name_th: "ลำลูกบัว",
            name_en: "Lam Luk Bua",
            amphure_id: 7304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7305,
        name_th: "บางเลน",
        name_en: "Bang Len",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730501,
            zip_code: 73130,
            name_th: "บางเลน",
            name_en: "Bang Len",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730502,
            zip_code: 73130,
            name_th: "บางปลา",
            name_en: "Bang Pla",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730503,
            zip_code: 73190,
            name_th: "บางหลวง",
            name_en: "Bang Luang",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730504,
            zip_code: 73130,
            name_th: "บางภาษี",
            name_en: "Bang Phasi",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730505,
            zip_code: 73130,
            name_th: "บางระกำ",
            name_en: "Bang Rakam",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730506,
            zip_code: 73130,
            name_th: "บางไทรป่า",
            name_en: "Bang Sai Pa",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730507,
            zip_code: 73190,
            name_th: "หินมูล",
            name_en: "Hin Mun",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730508,
            zip_code: 73130,
            name_th: "ไทรงาม",
            name_en: "Sai Ngam",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730509,
            zip_code: 73130,
            name_th: "ดอนตูม",
            name_en: "Don Tum",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730510,
            zip_code: 73130,
            name_th: "นิลเพชร",
            name_en: "Ninlaphet",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730511,
            zip_code: 73130,
            name_th: "บัวปากท่า",
            name_en: "Bua Pak Tha",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730512,
            zip_code: 73130,
            name_th: "คลองนกกระทุง",
            name_en: "Khlong Nok Krathung",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730513,
            zip_code: 73130,
            name_th: "นราภิรมย์",
            name_en: "Naraphirom",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730514,
            zip_code: 73130,
            name_th: "ลำพญา",
            name_en: "Lam Phaya",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730515,
            zip_code: 73130,
            name_th: "ไผ่หูช้าง",
            name_en: "Phai Hu Chang",
            amphure_id: 7305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7306,
        name_th: "สามพราน",
        name_en: "Sam Phran",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730601,
            zip_code: 73110,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730602,
            zip_code: 73210,
            name_th: "ทรงคนอง",
            name_en: "Song Khanong",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730603,
            zip_code: 73110,
            name_th: "หอมเกร็ด",
            name_en: "Hom Kret",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730604,
            zip_code: 73210,
            name_th: "บางกระทึก",
            name_en: "Bang Krathuek",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730605,
            zip_code: 73210,
            name_th: "บางเตย",
            name_en: "Bang Toei",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730606,
            zip_code: 73110,
            name_th: "สามพราน",
            name_en: "Sam Phran",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730607,
            zip_code: 73110,
            name_th: "บางช้าง",
            name_en: "Bang Chang",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730608,
            zip_code: 73210,
            name_th: "ไร่ขิง",
            name_en: "Rai Khing",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730609,
            zip_code: 73110,
            name_th: "ท่าตลาด",
            name_en: "Tha Talat",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730610,
            zip_code: 73220,
            name_th: "กระทุ่มล้ม",
            name_en: "Krathum Lom",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730611,
            zip_code: 73110,
            name_th: "คลองใหม่",
            name_en: "Khlong Mai",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730612,
            zip_code: 73110,
            name_th: "ตลาดจินดา",
            name_en: "Talat Chinda",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730613,
            zip_code: 73110,
            name_th: "คลองจินดา",
            name_en: "Khlong Chinda",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730614,
            zip_code: 73110,
            name_th: "ยายชา",
            name_en: "Yai Cha",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730615,
            zip_code: 73110,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730616,
            zip_code: 73160,
            name_th: "อ้อมใหญ่",
            name_en: "Om Yai",
            amphure_id: 7306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7307,
        name_th: "พุทธมณฑล",
        name_en: "Phutthamonthon",
        province_id: 58,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 730701,
            zip_code: 73170,
            name_th: "ศาลายา",
            name_en: "Sala Ya",
            amphure_id: 7307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730702,
            zip_code: 73170,
            name_th: "คลองโยง",
            name_en: "Khlong Yong",
            amphure_id: 7307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 730703,
            zip_code: 73170,
            name_th: "มหาสวัสดิ์",
            name_en: "Maha Sawat",
            amphure_id: 7307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 59,
    name_th: "สมุทรสาคร",
    name_en: "Samut Sakhon",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7401,
        name_th: "เมืองสมุทรสาคร",
        name_en: "Mueang Samut Sakhon",
        province_id: 59,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 740101,
            zip_code: 74000,
            name_th: "มหาชัย",
            name_en: "Maha Chai",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740102,
            zip_code: 74000,
            name_th: "ท่าฉลอม",
            name_en: "Tha Chalom",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740103,
            zip_code: 74000,
            name_th: "โกรกกราก",
            name_en: "Krok Krak",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740104,
            zip_code: 74000,
            name_th: "บ้านบ่อ",
            name_en: "Ban Bo",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740105,
            zip_code: 74000,
            name_th: "บางโทรัด",
            name_en: "Bang Tho Rat",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740106,
            zip_code: 74000,
            name_th: "กาหลง",
            name_en: "Ka Long",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740107,
            zip_code: 74000,
            name_th: "นาโคก",
            name_en: "Na Khok",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740108,
            zip_code: 74000,
            name_th: "ท่าจีน",
            name_en: "Tha Chin",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740109,
            zip_code: 74000,
            name_th: "นาดี",
            name_en: "Na Di",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740110,
            zip_code: 74000,
            name_th: "ท่าทราย",
            name_en: "Tha Sai",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740111,
            zip_code: 74000,
            name_th: "คอกกระบือ",
            name_en: "Khok Krabue",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740112,
            zip_code: 74000,
            name_th: "บางน้ำจืด",
            name_en: "Bang Nam Chuet",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740113,
            zip_code: 74000,
            name_th: "พันท้ายนรสิงห์",
            name_en: "Phan Thai Norasing",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740114,
            zip_code: 74000,
            name_th: "โคกขาม",
            name_en: "Khok Kham",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740115,
            zip_code: 74000,
            name_th: "บ้านเกาะ",
            name_en: "Ban Ko",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740116,
            zip_code: 74000,
            name_th: "บางกระเจ้า",
            name_en: "Bang Krachao",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740117,
            zip_code: 74000,
            name_th: "บางหญ้าแพรก",
            name_en: "Bang Ya Phraek",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740118,
            zip_code: 74000,
            name_th: "ชัยมงคล",
            name_en: "Chai Mongkon",
            amphure_id: 7401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7402,
        name_th: "กระทุ่มแบน",
        name_en: "Krathum Baen",
        province_id: 59,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 740201,
            zip_code: 74110,
            name_th: "ตลาดกระทุ่มแบน",
            name_en: "Talat Krathum Baen",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740202,
            zip_code: 74130,
            name_th: "อ้อมน้อย",
            name_en: "Om Noi",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740203,
            zip_code: 74110,
            name_th: "ท่าไม้",
            name_en: "Tha Mai",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740204,
            zip_code: 74110,
            name_th: "สวนหลวง",
            name_en: "Suan Luang",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740205,
            zip_code: 74110,
            name_th: "บางยาง",
            name_en: "Bang Yang",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740206,
            zip_code: 74110,
            name_th: "คลองมะเดื่อ",
            name_en: "Khlong Maduea",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740207,
            zip_code: 74110,
            name_th: "หนองนกไข่",
            name_en: "Nong Nok Khai",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740208,
            zip_code: 74110,
            name_th: "ดอนไก่ดี",
            name_en: "Don Kai Di",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740209,
            zip_code: 74110,
            name_th: "แคราย",
            name_en: "Khae Rai",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740210,
            zip_code: 74110,
            name_th: "ท่าเสา",
            name_en: "Tha Sao",
            amphure_id: 7402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7403,
        name_th: "บ้านแพ้ว",
        name_en: "Ban Phaeo",
        province_id: 59,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 740301,
            zip_code: 74120,
            name_th: "บ้านแพ้ว",
            name_en: "Ban Phaeo",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740302,
            zip_code: 74120,
            name_th: "หลักสาม",
            name_en: "Lak Sam",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740303,
            zip_code: 74120,
            name_th: "ยกกระบัตร",
            name_en: "Yokkrabat",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740304,
            zip_code: 74120,
            name_th: "โรงเข้",
            name_en: "Rong Khe",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740305,
            zip_code: 74120,
            name_th: "หนองสองห้อง",
            name_en: "Nong Song Hong",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740306,
            zip_code: 74120,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740307,
            zip_code: 74120,
            name_th: "หลักสอง",
            name_en: "Lak Song",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740308,
            zip_code: 74120,
            name_th: "เจ็ดริ้ว",
            name_en: "Chet Rio",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740309,
            zip_code: 74120,
            name_th: "คลองตัน",
            name_en: "Khlong Tan",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740310,
            zip_code: 74120,
            name_th: "อำแพง",
            name_en: "Amphaeng",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740311,
            zip_code: 74120,
            name_th: "สวนส้ม",
            name_en: "Suan Som",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 740312,
            zip_code: 74120,
            name_th: "เกษตรพัฒนา",
            name_en: "Kaset Phatthana",
            amphure_id: 7403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 60,
    name_th: "สมุทรสงคราม",
    name_en: "Samut Songkhram",
    geography_id: 2,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7501,
        name_th: "เมืองสมุทรสงคราม",
        name_en: "Mueang Samut Songkhram",
        province_id: 60,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 750101,
            zip_code: 75000,
            name_th: "แม่กลอง",
            name_en: "Mae Klong",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750102,
            zip_code: 75000,
            name_th: "บางขันแตก",
            name_en: "Bang Khan Taek",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750103,
            zip_code: 75000,
            name_th: "ลาดใหญ่",
            name_en: "Lat Yai",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750104,
            zip_code: 75000,
            name_th: "บ้านปรก",
            name_en: "Ban Prok",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750105,
            zip_code: 75000,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750106,
            zip_code: 75000,
            name_th: "ท้ายหาด",
            name_en: "Thai Hat",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750107,
            zip_code: 75000,
            name_th: "แหลมใหญ่",
            name_en: "Laem Yai",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750108,
            zip_code: 75000,
            name_th: "คลองเขิน",
            name_en: "Khlong Khoen",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750109,
            zip_code: 75000,
            name_th: "คลองโคน",
            name_en: "Khlong Khon",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750110,
            zip_code: 75000,
            name_th: "นางตะเคียน",
            name_en: "Nang Takhian",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750111,
            zip_code: 75000,
            name_th: "บางจะเกร็ง",
            name_en: "Bang Chakreng",
            amphure_id: 7501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7502,
        name_th: "บางคนที",
        name_en: "Bang Khonthi",
        province_id: 60,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 750201,
            zip_code: 75120,
            name_th: "กระดังงา",
            name_en: "Kradangnga",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750202,
            zip_code: 75120,
            name_th: "บางสะแก",
            name_en: "Bang Sakae",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750203,
            zip_code: 75120,
            name_th: "บางยี่รงค์",
            name_en: "Bang Yi Rong",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750204,
            zip_code: 75120,
            name_th: "โรงหีบ",
            name_en: "Rong Hip",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750205,
            zip_code: 75120,
            name_th: "บางคนที",
            name_en: "Bang Khonthi",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750206,
            zip_code: 75120,
            name_th: "ดอนมะโนรา",
            name_en: "Don Manora",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750207,
            zip_code: 75120,
            name_th: "บางพรม",
            name_en: "Bang Phrom",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750208,
            zip_code: 75120,
            name_th: "บางกุ้ง",
            name_en: "Bang Kung",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750209,
            zip_code: 75120,
            name_th: "จอมปลวก",
            name_en: "Chom Pluak",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750210,
            zip_code: 75120,
            name_th: "บางนกแขวก",
            name_en: "Bang Nok Khwaek",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750211,
            zip_code: 75120,
            name_th: "ยายแพง",
            name_en: "Yai Phaeng",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750212,
            zip_code: 75120,
            name_th: "บางกระบือ",
            name_en: "Bang Krabue",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750213,
            zip_code: 75120,
            name_th: "บ้านปราโมทย์",
            name_en: "Ban Pramot",
            amphure_id: 7502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7503,
        name_th: "อัมพวา",
        name_en: "Amphawa",
        province_id: 60,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 750301,
            zip_code: 75110,
            name_th: "อัมพวา",
            name_en: "Amphawa",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750302,
            zip_code: 75110,
            name_th: "สวนหลวง",
            name_en: "Suan Luang",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750303,
            zip_code: 75110,
            name_th: "ท่าคา",
            name_en: "Tha Kha",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750304,
            zip_code: 75110,
            name_th: "วัดประดู่",
            name_en: "Wat Pradu",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750305,
            zip_code: 75110,
            name_th: "เหมืองใหม่",
            name_en: "Mueang Mai",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750306,
            zip_code: 75110,
            name_th: "บางช้าง",
            name_en: "Bang Chang",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750307,
            zip_code: 75110,
            name_th: "แควอ้อม",
            name_en: "Khwae Om",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750308,
            zip_code: 75110,
            name_th: "ปลายโพงพาง",
            name_en: "Plai Phongphang",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750309,
            zip_code: 75110,
            name_th: "บางแค",
            name_en: "Bang Khae",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750310,
            zip_code: 75110,
            name_th: "แพรกหนามแดง",
            name_en: "Phraek Nam Daeng",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750311,
            zip_code: 75110,
            name_th: "ยี่สาร",
            name_en: "Yi San",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 750312,
            zip_code: 75110,
            name_th: "บางนางลี่",
            name_en: "Bang Nang Lee",
            amphure_id: 7503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 61,
    name_th: "เพชรบุรี",
    name_en: "Phetchaburi",
    geography_id: 4,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7601,
        name_th: "เมืองเพชรบุรี",
        name_en: "Mueang Phetchaburi",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760101,
            zip_code: 76000,
            name_th: "ท่าราบ",
            name_en: "Tha Rap",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760102,
            zip_code: 76000,
            name_th: "คลองกระแชง",
            name_en: "Khlong Krachaeng",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760103,
            zip_code: 76000,
            name_th: "บางจาน",
            name_en: "Bang Chan",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760104,
            zip_code: 76000,
            name_th: "นาพันสาม",
            name_en: "Na Phan Sam",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760105,
            zip_code: 76000,
            name_th: "ธงชัย",
            name_en: "Thong Chai",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760106,
            zip_code: 76000,
            name_th: "บ้านกุ่ม",
            name_en: "Ban Kum",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760107,
            zip_code: 76000,
            name_th: "หนองโสน",
            name_en: "Nong Sano",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760108,
            zip_code: 76000,
            name_th: "ไร่ส้ม",
            name_en: "Rai Som",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760109,
            zip_code: 76000,
            name_th: "เวียงคอย",
            name_en: "Wiang Khoi",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760110,
            zip_code: 76000,
            name_th: "บางจาก",
            name_en: "Bang Chak",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760111,
            zip_code: 76000,
            name_th: "บ้านหม้อ",
            name_en: "Ban Mo",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760112,
            zip_code: 76000,
            name_th: "ต้นมะม่วง",
            name_en: "Ton Mamuang",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760113,
            zip_code: 76000,
            name_th: "ช่องสะแก",
            name_en: "Chong Sakae",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760114,
            zip_code: 76000,
            name_th: "นาวุ้ง",
            name_en: "Na Wung",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760115,
            zip_code: 76000,
            name_th: "สำมะโรง",
            name_en: "Sam Marong",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760116,
            zip_code: 76000,
            name_th: "โพพระ",
            name_en: "Pho Phra",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760117,
            zip_code: 76100,
            name_th: "หาดเจ้าสำราญ",
            name_en: "Hat Chao Samran",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760118,
            zip_code: 76000,
            name_th: "หัวสะพาน",
            name_en: "Hua Saphan",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760119,
            zip_code: 76000,
            name_th: "ต้นมะพร้าว",
            name_en: "Ton Maphrao",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760120,
            zip_code: 76000,
            name_th: "วังตะโก",
            name_en: "Wang Tako",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760121,
            zip_code: 76000,
            name_th: "โพไร่หวาน",
            name_en: "Pho Rai Wan",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760122,
            zip_code: 76000,
            name_th: "ดอนยาง",
            name_en: "Don Yang",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760123,
            zip_code: 76000,
            name_th: "หนองขนาน",
            name_en: "Nong Khanan",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760124,
            zip_code: 76000,
            name_th: "หนองพลับ",
            name_en: "Nong Phlap",
            amphure_id: 7601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7602,
        name_th: "เขาย้อย",
        name_en: "Khao Yoi",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760201,
            zip_code: 76140,
            name_th: "เขาย้อย",
            name_en: "Khao Yoi",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760202,
            zip_code: 76140,
            name_th: "สระพัง",
            name_en: "Sa Phang",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760203,
            zip_code: 76140,
            name_th: "บางเค็ม",
            name_en: "Bang Khem",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760204,
            zip_code: 76140,
            name_th: "ทับคาง",
            name_en: "Thap Khang",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760205,
            zip_code: 76140,
            name_th: "หนองปลาไหล",
            name_en: "Nong Pla Lai",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760206,
            zip_code: 76140,
            name_th: "หนองปรง",
            name_en: "Nong Prong",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760207,
            zip_code: 76140,
            name_th: "หนองชุมพล",
            name_en: "Nong Chumphon",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760208,
            zip_code: 76140,
            name_th: "ห้วยโรง",
            name_en: "Huai Rong",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760209,
            zip_code: 76140,
            name_th: "ห้วยท่าช้าง",
            name_en: "Huai Tha Chang",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760210,
            zip_code: 76140,
            name_th: "หนองชุมพลเหนือ",
            name_en: "Nong Chumphon Nuea",
            amphure_id: 7602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7603,
        name_th: "หนองหญ้าปล้อง",
        name_en: "Nong Ya Plong",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760301,
            zip_code: 76160,
            name_th: "หนองหญ้าปล้อง",
            name_en: "Nong Ya Plong",
            amphure_id: 7603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760302,
            zip_code: 76160,
            name_th: "ยางน้ำกลัดเหนือ",
            name_en: "Yang Nam Klat Nuea",
            amphure_id: 7603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760303,
            zip_code: 76160,
            name_th: "ยางน้ำกลัดใต้",
            name_en: "Yang Nam Klat Tai",
            amphure_id: 7603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760304,
            zip_code: 76160,
            name_th: "ท่าตะคร้อ",
            name_en: "Tha Takror",
            amphure_id: 7603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7604,
        name_th: "ชะอำ",
        name_en: "Cha-am",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760401,
            zip_code: 76120,
            name_th: "ชะอำ",
            name_en: "Cha-am",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760402,
            zip_code: 76120,
            name_th: "บางเก่า",
            name_en: "Bang Kao",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760403,
            zip_code: 76120,
            name_th: "นายาง",
            name_en: "Na Yang",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760404,
            zip_code: 76120,
            name_th: "เขาใหญ่",
            name_en: "Khao Yai",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760405,
            zip_code: 76120,
            name_th: "หนองศาลา",
            name_en: "Nong Sala",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760406,
            zip_code: 76120,
            name_th: "ห้วยทรายเหนือ",
            name_en: "Huai Sai Nuea",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760407,
            zip_code: 76120,
            name_th: "ไร่ใหม่พัฒนา",
            name_en: "Rai Mai Phatthana",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760408,
            zip_code: 76120,
            name_th: "สามพระยา",
            name_en: "Sam Phraya",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760409,
            zip_code: 76120,
            name_th: "ดอนขุนห้วย",
            name_en: "Don Khun Huai",
            amphure_id: 7604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7605,
        name_th: "ท่ายาง",
        name_en: "Tha Yang",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760501,
            zip_code: 76130,
            name_th: "ท่ายาง",
            name_en: "Tha Yang",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760502,
            zip_code: 76130,
            name_th: "ท่าคอย",
            name_en: "Tha Khoi",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760503,
            zip_code: 76130,
            name_th: "ยางหย่อง",
            name_en: "Yang Yong",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760504,
            zip_code: 76130,
            name_th: "หนองจอก",
            name_en: "Nong Chok",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760505,
            zip_code: 76130,
            name_th: "มาบปลาเค้า",
            name_en: "Map Pla Khao",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760506,
            zip_code: 76130,
            name_th: "ท่าไม้รวก",
            name_en: "Tha Mai Ruak",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760507,
            zip_code: 76130,
            name_th: "วังไคร้",
            name_en: "Wang Khrai",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760511,
            zip_code: 76130,
            name_th: "กลัดหลวง",
            name_en: "Klat Luang",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760512,
            zip_code: 76130,
            name_th: "ปึกเตียน",
            name_en: "Puek Tian",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760513,
            zip_code: 76130,
            name_th: "เขากระปุก",
            name_en: "Khao Krapuk",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760514,
            zip_code: 76130,
            name_th: "ท่าแลง",
            name_en: "Tha Laeng",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760515,
            zip_code: 76130,
            name_th: "บ้านในดง",
            name_en: "Ban Nai Dong",
            amphure_id: 7605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7606,
        name_th: "บ้านลาด",
        name_en: "Ban Lat",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760601,
            zip_code: 76150,
            name_th: "บ้านลาด",
            name_en: "Ban Lat",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760602,
            zip_code: 76150,
            name_th: "บ้านหาด",
            name_en: "Ban Hat",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760603,
            zip_code: 76150,
            name_th: "บ้านทาน",
            name_en: "Ban Than",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760604,
            zip_code: 76150,
            name_th: "ตำหรุ",
            name_en: "Tamru",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760605,
            zip_code: 76150,
            name_th: "สมอพลือ",
            name_en: "Samo Phlue",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760606,
            zip_code: 76150,
            name_th: "ไร่มะขาม",
            name_en: "Rai Makham",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760607,
            zip_code: 76150,
            name_th: "ท่าเสน",
            name_en: "Tha Sen",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760608,
            zip_code: 76150,
            name_th: "หนองกระเจ็ด",
            name_en: "Nong Krachet",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760609,
            zip_code: 76150,
            name_th: "หนองกะปุ",
            name_en: "Nong Kapu",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760610,
            zip_code: 76150,
            name_th: "ลาดโพธิ์",
            name_en: "Lat Pho",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760611,
            zip_code: 76150,
            name_th: "สะพานไกร",
            name_en: "Saphan Krai",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760612,
            zip_code: 76150,
            name_th: "ไร่โคก",
            name_en: "Rai Khok",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760613,
            zip_code: 76150,
            name_th: "โรงเข้",
            name_en: "Rong Khe",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760614,
            zip_code: 76150,
            name_th: "ไร่สะท้อน",
            name_en: "Rai Sathon",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760615,
            zip_code: 76150,
            name_th: "ห้วยข้อง",
            name_en: "Huai Khong",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760616,
            zip_code: 76150,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760617,
            zip_code: 76150,
            name_th: "ถ้ำรงค์",
            name_en: "Tham Rong",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760618,
            zip_code: 76150,
            name_th: "ห้วยลึก",
            name_en: "Huay Lueg",
            amphure_id: 7606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7607,
        name_th: "บ้านแหลม",
        name_en: "Ban Laem",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760701,
            zip_code: 76110,
            name_th: "บ้านแหลม",
            name_en: "Ban Laem",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760702,
            zip_code: 76110,
            name_th: "บางขุนไทร",
            name_en: "Bang Khun Sai",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760703,
            zip_code: 76110,
            name_th: "ปากทะเล",
            name_en: "Pak Thale",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760704,
            zip_code: 76110,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760705,
            zip_code: 76100,
            name_th: "แหลมผักเบี้ย",
            name_en: "Laem Phak Bia",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760706,
            zip_code: 76110,
            name_th: "บางตะบูน",
            name_en: "Bang Tabun",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760707,
            zip_code: 76110,
            name_th: "บางตะบูนออก",
            name_en: "Bang Tabun Ok",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760708,
            zip_code: 76110,
            name_th: "บางครก",
            name_en: "Bang Khrok",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760709,
            zip_code: 76110,
            name_th: "ท่าแร้ง",
            name_en: "Tha Raeng",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760710,
            zip_code: 76110,
            name_th: "ท่าแร้งออก",
            name_en: "Tha Raeng Ok",
            amphure_id: 7607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7608,
        name_th: "แก่งกระจาน",
        name_en: "Kaeng Krachan",
        province_id: 61,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 760801,
            zip_code: 76170,
            name_th: "แก่งกระจาน",
            name_en: "Kaeng Krachan",
            amphure_id: 7608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760802,
            zip_code: 76170,
            name_th: "สองพี่น้อง",
            name_en: "Song Phi Nong",
            amphure_id: 7608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760803,
            zip_code: 76170,
            name_th: "วังจันทร์",
            name_en: "Wang Chan",
            amphure_id: 7608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760804,
            zip_code: 76170,
            name_th: "ป่าเด็ง",
            name_en: "Pa Deng",
            amphure_id: 7608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760805,
            zip_code: 76170,
            name_th: "พุสวรรค์",
            name_en: "Phu Sawan",
            amphure_id: 7608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 760806,
            zip_code: 76170,
            name_th: "ห้วยแม่เพรียง",
            name_en: "Huai Mae Phriang",
            amphure_id: 7608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 62,
    name_th: "ประจวบคีรีขันธ์",
    name_en: "Prachuap Khiri Khan",
    geography_id: 4,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 7701,
        name_th: "เมืองประจวบคีรีขันธ์",
        name_en: "Mueang Prachuap Khiri Khan",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770101,
            zip_code: 77000,
            name_th: "ประจวบคีรีขันธ์",
            name_en: "Prachuap Khiri Khan",
            amphure_id: 7701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770102,
            zip_code: 77000,
            name_th: "เกาะหลัก",
            name_en: "Ko Lak",
            amphure_id: 7701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770103,
            zip_code: 77000,
            name_th: "คลองวาฬ",
            name_en: "Khlong Wan",
            amphure_id: 7701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770104,
            zip_code: 77000,
            name_th: "ห้วยทราย",
            name_en: "Huai Sai",
            amphure_id: 7701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770105,
            zip_code: 77000,
            name_th: "อ่าวน้อย",
            name_en: "Ao Noi",
            amphure_id: 7701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770106,
            zip_code: 77210,
            name_th: "บ่อนอก",
            name_en: "Bo Nok",
            amphure_id: 7701,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7702,
        name_th: "กุยบุรี",
        name_en: "Kui Buri",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770201,
            zip_code: 77150,
            name_th: "กุยบุรี",
            name_en: "Kui Buri",
            amphure_id: 7702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770202,
            zip_code: 77150,
            name_th: "กุยเหนือ",
            name_en: "Kui Nuea",
            amphure_id: 7702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770203,
            zip_code: 77150,
            name_th: "เขาแดง",
            name_en: "Khao Daeng",
            amphure_id: 7702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770204,
            zip_code: 77150,
            name_th: "ดอนยายหนู",
            name_en: "Don Yai Nu",
            amphure_id: 7702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770206,
            zip_code: 77150,
            name_th: "สามกระทาย",
            name_en: "Sam Krathai",
            amphure_id: 7702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770207,
            zip_code: 77150,
            name_th: "หาดขาม",
            name_en: "Hat Kham",
            amphure_id: 7702,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7703,
        name_th: "ทับสะแก",
        name_en: "Thap Sakae",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770301,
            zip_code: 77130,
            name_th: "ทับสะแก",
            name_en: "Thap Sakae",
            amphure_id: 7703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770302,
            zip_code: 77130,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 7703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770303,
            zip_code: 77130,
            name_th: "นาหูกวาง",
            name_en: "Na Hukwang",
            amphure_id: 7703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770304,
            zip_code: 77130,
            name_th: "เขาล้าน",
            name_en: "Khao Lan",
            amphure_id: 7703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770305,
            zip_code: 77130,
            name_th: "ห้วยยาง",
            name_en: "Huai Yang",
            amphure_id: 7703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770306,
            zip_code: 77130,
            name_th: "แสงอรุณ",
            name_en: "Saeng Arun",
            amphure_id: 7703,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7704,
        name_th: "บางสะพาน",
        name_en: "Bang Saphan",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770401,
            zip_code: 77140,
            name_th: "กำเนิดนพคุณ",
            name_en: "Kamnoet Nopphakhun",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770402,
            zip_code: 77140,
            name_th: "พงศ์ประศาสน์",
            name_en: "Phong Prasat",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770403,
            zip_code: 77230,
            name_th: "ร่อนทอง",
            name_en: "Ron Thong",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770404,
            zip_code: 77190,
            name_th: "ธงชัย",
            name_en: "Thong Chai",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770405,
            zip_code: 77190,
            name_th: "ชัยเกษม",
            name_en: "Chai Kasem",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770406,
            zip_code: 77230,
            name_th: "ทองมงคล",
            name_en: "Thong Mongkhon",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770407,
            zip_code: 77140,
            name_th: "แม่รำพึง",
            name_en: "Mae Ramphueng",
            amphure_id: 7704,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7705,
        name_th: "บางสะพานน้อย",
        name_en: "Bang Saphan Noi",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770501,
            zip_code: 77170,
            name_th: "ปากแพรก",
            name_en: "Pak Phraek",
            amphure_id: 7705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770502,
            zip_code: 77170,
            name_th: "บางสะพาน",
            name_en: "Bang Saphan",
            amphure_id: 7705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770503,
            zip_code: 77170,
            name_th: "ทรายทอง",
            name_en: "Sai Thong",
            amphure_id: 7705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770504,
            zip_code: 77170,
            name_th: "ช้างแรก",
            name_en: "Chang Raek",
            amphure_id: 7705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770505,
            zip_code: 77170,
            name_th: "ไชยราช",
            name_en: "Chaiyarat",
            amphure_id: 7705,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7706,
        name_th: "ปราณบุรี",
        name_en: "Pran Buri",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770601,
            zip_code: 77120,
            name_th: "ปราณบุรี",
            name_en: "Pran Buri",
            amphure_id: 7706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770602,
            zip_code: 77120,
            name_th: "เขาน้อย",
            name_en: "Khao Noi",
            amphure_id: 7706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770604,
            zip_code: 77220,
            name_th: "ปากน้ำปราณ",
            name_en: "Pak Nam Pran",
            amphure_id: 7706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770607,
            zip_code: 77120,
            name_th: "หนองตาแต้ม",
            name_en: "Nong Ta Taem",
            amphure_id: 7706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770608,
            zip_code: 77120,
            name_th: "วังก์พง",
            name_en: "Wang Phong",
            amphure_id: 7706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770609,
            zip_code: 77120,
            name_th: "เขาจ้าว",
            name_en: "Khao Chao",
            amphure_id: 7706,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7707,
        name_th: "หัวหิน",
        name_en: "Hua Hin",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770701,
            zip_code: 77110,
            name_th: "หัวหิน",
            name_en: "Hua Hin",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770702,
            zip_code: 77110,
            name_th: "หนองแก",
            name_en: "Nong Kae",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770703,
            zip_code: 77110,
            name_th: "หินเหล็กไฟ",
            name_en: "Hin Lek Fai",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770704,
            zip_code: 77110,
            name_th: "หนองพลับ",
            name_en: "Nong Phlap",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770705,
            zip_code: 77110,
            name_th: "ทับใต้",
            name_en: "Thap Tai",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770706,
            zip_code: 77110,
            name_th: "ห้วยสัตว์ใหญ่",
            name_en: "Huai Sat Yai",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770707,
            zip_code: 77110,
            name_th: "บึงนคร",
            name_en: "Bueng Nakhon",
            amphure_id: 7707,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 7708,
        name_th: "สามร้อยยอด",
        name_en: "Sam Roi Yot",
        province_id: 62,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 770801,
            zip_code: 77120,
            name_th: "สามร้อยยอด",
            name_en: "Sam Roi Yot",
            amphure_id: 7708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770802,
            zip_code: 77180,
            name_th: "ศิลาลอย",
            name_en: "Sila Loi",
            amphure_id: 7708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770803,
            zip_code: 77180,
            name_th: "ไร่เก่า",
            name_en: "Rai Kao",
            amphure_id: 7708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770804,
            zip_code: 77180,
            name_th: "ศาลาลัย",
            name_en: "Salalai",
            amphure_id: 7708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 770805,
            zip_code: 77180,
            name_th: "ไร่ใหม่",
            name_en: "Rai Mai",
            amphure_id: 7708,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 63,
    name_th: "นครศรีธรรมราช",
    name_en: "Nakhon Si Thammarat",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8001,
        name_th: "เมืองนครศรีธรรมราช",
        name_en: "Mueang Nakhon Si Thammarat",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800101,
            zip_code: 80000,
            name_th: "ในเมือง",
            name_en: "Nai Mueang",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800102,
            zip_code: 80000,
            name_th: "ท่าวัง",
            name_en: "Tha Wang",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800103,
            zip_code: 80000,
            name_th: "คลัง",
            name_en: "Khlang",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800106,
            zip_code: 80000,
            name_th: "ท่าไร่",
            name_en: "Tha Rai",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800107,
            zip_code: 80000,
            name_th: "ปากนคร",
            name_en: "Pak Nakhon",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800108,
            zip_code: 80280,
            name_th: "นาทราย",
            name_en: "Na Sai",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800112,
            zip_code: 80280,
            name_th: "กำแพงเซา",
            name_en: "Kamphaeng Sao",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800113,
            zip_code: 80000,
            name_th: "ไชยมนตรี",
            name_en: "Chai Montri",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800114,
            zip_code: 80000,
            name_th: "มะม่วงสองต้น",
            name_en: "Mamuang Song Ton",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800115,
            zip_code: 80000,
            name_th: "นาเคียน",
            name_en: "Na Khian",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800116,
            zip_code: 80280,
            name_th: "ท่างิ้ว",
            name_en: "Tha Ngio",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800118,
            zip_code: 80000,
            name_th: "โพธิ์เสด็จ",
            name_en: "Pho Sadet",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800119,
            zip_code: 80330,
            name_th: "บางจาก",
            name_en: "Bang Chak",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800120,
            zip_code: 80000,
            name_th: "ปากพูน",
            name_en: "Pak Phun",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800121,
            zip_code: 80000,
            name_th: "ท่าซัก",
            name_en: "Tha Sak",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800122,
            zip_code: 80290,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 8001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8002,
        name_th: "พรหมคีรี",
        name_en: "Phrom Khiri",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800201,
            zip_code: 80320,
            name_th: "พรหมโลก",
            name_en: "Phrommalok",
            amphure_id: 8002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800202,
            zip_code: 80320,
            name_th: "บ้านเกาะ",
            name_en: "Ban Ko",
            amphure_id: 8002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800203,
            zip_code: 80320,
            name_th: "อินคีรี",
            name_en: "In Khiri",
            amphure_id: 8002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800204,
            zip_code: 80320,
            name_th: "ทอนหงส์",
            name_en: "Thon Hong",
            amphure_id: 8002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800205,
            zip_code: 80320,
            name_th: "นาเรียง",
            name_en: "Na Reang",
            amphure_id: 8002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8003,
        name_th: "ลานสกา",
        name_en: "Lan Saka",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800301,
            zip_code: 80230,
            name_th: "เขาแก้ว",
            name_en: "Khao Kaeo",
            amphure_id: 8003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800302,
            zip_code: 80230,
            name_th: "ลานสกา",
            name_en: "Lan Saka",
            amphure_id: 8003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800303,
            zip_code: 80230,
            name_th: "ท่าดี",
            name_en: "Tha Di",
            amphure_id: 8003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800304,
            zip_code: 80230,
            name_th: "กำโลน",
            name_en: "Kamlon",
            amphure_id: 8003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800305,
            zip_code: 80230,
            name_th: "ขุนทะเล",
            name_en: "Khun Thale",
            amphure_id: 8003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8004,
        name_th: "ฉวาง",
        name_en: "Chawang",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800401,
            zip_code: 80150,
            name_th: "ฉวาง",
            name_en: "Chawang",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800403,
            zip_code: 80250,
            name_th: "ละอาย",
            name_en: "La-ai",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800404,
            zip_code: 80260,
            name_th: "นาแว",
            name_en: "Na Wae",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800405,
            zip_code: 80150,
            name_th: "ไม้เรียง",
            name_en: "Mai Riang",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800406,
            zip_code: 80260,
            name_th: "กะเปียด",
            name_en: "Kapiat",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800407,
            zip_code: 80150,
            name_th: "นากะชะ",
            name_en: "Na Kacha",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800409,
            zip_code: 80260,
            name_th: "ห้วยปริก",
            name_en: "Huai Prik",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800410,
            zip_code: 80150,
            name_th: "ไสหร้า",
            name_en: "Saira",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800415,
            zip_code: 80260,
            name_th: "นาเขลียง",
            name_en: "Na Khliang",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800416,
            zip_code: 80250,
            name_th: "จันดี",
            name_en: "Chan Di",
            amphure_id: 8004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8005,
        name_th: "พิปูน",
        name_en: "Phipun",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800501,
            zip_code: 80270,
            name_th: "พิปูน",
            name_en: "Phipun",
            amphure_id: 8005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800502,
            zip_code: 80270,
            name_th: "กะทูน",
            name_en: "Kathun",
            amphure_id: 8005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800503,
            zip_code: 80270,
            name_th: "เขาพระ",
            name_en: "Khao Phra",
            amphure_id: 8005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800504,
            zip_code: 80270,
            name_th: "ยางค้อม",
            name_en: "Yang Khom",
            amphure_id: 8005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800505,
            zip_code: 80270,
            name_th: "ควนกลาง",
            name_en: "Khuan Klang",
            amphure_id: 8005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8006,
        name_th: "เชียรใหญ่",
        name_en: "Chian Yai",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800601,
            zip_code: 80190,
            name_th: "เชียรใหญ่",
            name_en: "Chian Yai",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800603,
            zip_code: 80190,
            name_th: "ท่าขนาน",
            name_en: "Tha Khanan",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800604,
            zip_code: 80190,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800605,
            zip_code: 80190,
            name_th: "บ้านเนิน",
            name_en: "Ban Noen",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800606,
            zip_code: 80190,
            name_th: "ไสหมาก",
            name_en: "Sai Mak",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800607,
            zip_code: 80190,
            name_th: "ท้องลำเจียก",
            name_en: "Thong Lamchiak",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800610,
            zip_code: 80190,
            name_th: "เสือหึง",
            name_en: "Suea Hueng",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800611,
            zip_code: 80190,
            name_th: "การะเกด",
            name_en: "Karaket",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800612,
            zip_code: 80190,
            name_th: "เขาพระบาท",
            name_en: "Khao Phra Bat",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800613,
            zip_code: 80190,
            name_th: "แม่เจ้าอยู่หัว",
            name_en: "Mae Chao Yu Hua",
            amphure_id: 8006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8007,
        name_th: "ชะอวด",
        name_en: "Cha-uat",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800701,
            zip_code: 80180,
            name_th: "ชะอวด",
            name_en: "Cha-uat",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800702,
            zip_code: 80180,
            name_th: "ท่าเสม็ด",
            name_en: "Tha Samet",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800703,
            zip_code: 80180,
            name_th: "ท่าประจะ",
            name_en: "Tha Pracha",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800704,
            zip_code: 80180,
            name_th: "เคร็ง",
            name_en: "Khreng",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800705,
            zip_code: 80180,
            name_th: "วังอ่าง",
            name_en: "Wang Ang",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800706,
            zip_code: 80180,
            name_th: "บ้านตูล",
            name_en: "Ban Tun",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800707,
            zip_code: 80180,
            name_th: "ขอนหาด",
            name_en: "Khon Hat",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800708,
            zip_code: 80180,
            name_th: "เกาะขันธ์",
            name_en: "Khuan Nong Hong",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800709,
            zip_code: 80180,
            name_th: "ควนหนองหงษ์",
            name_en: "Khao Phra Thong",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800710,
            zip_code: 80180,
            name_th: "เขาพระทอง",
            name_en: "Nang Long",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800711,
            zip_code: 80180,
            name_th: "นางหลง",
            name_en: "Nang Long",
            amphure_id: 8007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8008,
        name_th: "ท่าศาลา",
        name_en: "Tha Sala",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800801,
            zip_code: 80160,
            name_th: "ท่าศาลา",
            name_en: "Tha Sala",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800802,
            zip_code: 80160,
            name_th: "กลาย",
            name_en: "Klai",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800803,
            zip_code: 80160,
            name_th: "ท่าขึ้น",
            name_en: "Tha Khuen",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800804,
            zip_code: 80160,
            name_th: "หัวตะพาน",
            name_en: "Hua Taphan",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800806,
            zip_code: 80160,
            name_th: "สระแก้ว",
            name_en: "Sa Kaeo",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800807,
            zip_code: 80160,
            name_th: "โมคลาน",
            name_en: "Mokkhalan",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800809,
            zip_code: 80160,
            name_th: "ไทยบุรี",
            name_en: "Thai buri",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800810,
            zip_code: 80160,
            name_th: "ดอนตะโก",
            name_en: "Don tako",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800811,
            zip_code: 80160,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800813,
            zip_code: 80160,
            name_th: "โพธิ์ทอง",
            name_en: "Pho Thong",
            amphure_id: 8008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8009,
        name_th: "ทุ่งสง",
        name_en: "Thung Song",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 800901,
            zip_code: 80110,
            name_th: "ปากแพรก",
            name_en: "Pak Phraek",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800902,
            zip_code: 80110,
            name_th: "ชะมาย",
            name_en: "Chamai",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800903,
            zip_code: 80110,
            name_th: "หนองหงส์",
            name_en: "Nong Hong",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800904,
            zip_code: 80110,
            name_th: "ควนกรด",
            name_en: "Khuan Krot",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800905,
            zip_code: 80110,
            name_th: "นาไม้ไผ่",
            name_en: "Na Mai Phai",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800906,
            zip_code: 80110,
            name_th: "นาหลวงเสน",
            name_en: "Na Luang Sen",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800907,
            zip_code: 80110,
            name_th: "เขาโร",
            name_en: "Khao Ro",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800908,
            zip_code: 80310,
            name_th: "กะปาง",
            name_en: "Kapang",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800909,
            zip_code: 80110,
            name_th: "ที่วัง",
            name_en: "Thi Wang",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800910,
            zip_code: 80110,
            name_th: "น้ำตก",
            name_en: "Namtok",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800911,
            zip_code: 80110,
            name_th: "ถ้ำใหญ่",
            name_en: "Tham Yai",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800912,
            zip_code: 80110,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 800913,
            zip_code: 80110,
            name_th: "เขาขาว",
            name_en: "Khao Khao",
            amphure_id: 8009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8010,
        name_th: "นาบอน",
        name_en: "Na Bon",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801001,
            zip_code: 80220,
            name_th: "นาบอน",
            name_en: "Na Bon",
            amphure_id: 8010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801002,
            zip_code: 80220,
            name_th: "ทุ่งสง",
            name_en: "Thung Song",
            amphure_id: 8010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801003,
            zip_code: 80220,
            name_th: "แก้วแสน",
            name_en: "Kaeo Saen",
            amphure_id: 8010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8011,
        name_th: "ทุ่งใหญ่",
        name_en: "Thung Yai",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801101,
            zip_code: 80240,
            name_th: "ท่ายาง",
            name_en: "Tha Yang",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801102,
            zip_code: 80240,
            name_th: "ทุ่งสัง",
            name_en: "Thung Sang",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801103,
            zip_code: 80240,
            name_th: "ทุ่งใหญ่",
            name_en: "Thung Yai",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801104,
            zip_code: 80240,
            name_th: "กุแหระ",
            name_en: "Kurae",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801105,
            zip_code: 80240,
            name_th: "ปริก",
            name_en: "Prik",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801106,
            zip_code: 80240,
            name_th: "บางรูป",
            name_en: "Bang Rup",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801107,
            zip_code: 80240,
            name_th: "กรุงหยัน",
            name_en: "Krung Yan",
            amphure_id: 8011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8012,
        name_th: "ปากพนัง",
        name_en: "Pak Phanang",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801201,
            zip_code: 80140,
            name_th: "ปากพนัง",
            name_en: "Pak Phanang",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801202,
            zip_code: 80330,
            name_th: "คลองน้อย",
            name_en: "Khlong Noi",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801203,
            zip_code: 80140,
            name_th: "ป่าระกำ",
            name_en: "Pa Rakam",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801204,
            zip_code: 80330,
            name_th: "ชะเมา",
            name_en: "Chamao",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801205,
            zip_code: 80140,
            name_th: "คลองกระบือ",
            name_en: "Khlong Krabue",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801206,
            zip_code: 80330,
            name_th: "เกาะทวด",
            name_en: "Ko Thuat",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801207,
            zip_code: 80140,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801208,
            zip_code: 80140,
            name_th: "หูล่อง",
            name_en: "Hu Long",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801209,
            zip_code: 80140,
            name_th: "แหลมตะลุมพุก",
            name_en: "Laem Talumphuk",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801210,
            zip_code: 80140,
            name_th: "ปากพนังฝั่งตะวันตก",
            name_en: "Pak Phanang Fang Tawantok",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801211,
            zip_code: 80140,
            name_th: "บางศาลา",
            name_en: "Bang Sala",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801212,
            zip_code: 80140,
            name_th: "บางพระ",
            name_en: "Bang Phra",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801213,
            zip_code: 80140,
            name_th: "บางตะพง",
            name_en: "Bang Taphong",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801214,
            zip_code: 80140,
            name_th: "ปากพนังฝั่งตะวันออก",
            name_en: "Pak Phanang Fang Tawan-ok",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801215,
            zip_code: 80140,
            name_th: "บ้านเพิง",
            name_en: "Ban Phoeng",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801216,
            zip_code: 80140,
            name_th: "ท่าพยา",
            name_en: "Tha Phaya",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801217,
            zip_code: 80140,
            name_th: "ปากแพรก",
            name_en: "Pak Phraek",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801218,
            zip_code: 80140,
            name_th: "ขนาบนาก",
            name_en: "Khanap Nak",
            amphure_id: 8012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8013,
        name_th: "ร่อนพิบูลย์",
        name_en: "Ron Phibun",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801301,
            zip_code: 80130,
            name_th: "ร่อนพิบูลย์",
            name_en: "Ron Phibun",
            amphure_id: 8013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801302,
            zip_code: 80350,
            name_th: "หินตก",
            name_en: "Hin Tok",
            amphure_id: 8013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801303,
            zip_code: 80350,
            name_th: "เสาธง",
            name_en: "Sao Thong",
            amphure_id: 8013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801304,
            zip_code: 80130,
            name_th: "ควนเกย",
            name_en: "Khuan Koei",
            amphure_id: 8013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801305,
            zip_code: 80130,
            name_th: "ควนพัง",
            name_en: "Khuan Phang",
            amphure_id: 8013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801306,
            zip_code: 80130,
            name_th: "ควนชุม",
            name_en: "Khuan Chum",
            amphure_id: 8013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8014,
        name_th: "สิชล",
        name_en: "Sichon",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801401,
            zip_code: 80120,
            name_th: "สิชล",
            name_en: "Sichon",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801402,
            zip_code: 80120,
            name_th: "ทุ่งปรัง",
            name_en: "Thung Prang",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801403,
            zip_code: 80120,
            name_th: "ฉลอง",
            name_en: "Chalong",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801404,
            zip_code: 80340,
            name_th: "เสาเภา",
            name_en: "Sao Phao",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801405,
            zip_code: 80120,
            name_th: "เปลี่ยน",
            name_en: "Plian",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801406,
            zip_code: 80120,
            name_th: "สี่ขีด",
            name_en: "Si Khit",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801407,
            zip_code: 80340,
            name_th: "เทพราช",
            name_en: "Theppharat",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801408,
            zip_code: 80120,
            name_th: "เขาน้อย",
            name_en: "Khao Noi",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801409,
            zip_code: 80120,
            name_th: "ทุ่งใส",
            name_en: "Thung Sai",
            amphure_id: 8014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8015,
        name_th: "ขนอม",
        name_en: "Khanom",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801501,
            zip_code: 80210,
            name_th: "ขนอม",
            name_en: "Khanom",
            amphure_id: 8015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801502,
            zip_code: 80210,
            name_th: "ควนทอง",
            name_en: "Khuan Thong",
            amphure_id: 8015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801503,
            zip_code: 80210,
            name_th: "ท้องเนียน",
            name_en: "Thong Nian",
            amphure_id: 8015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8016,
        name_th: "หัวไทร",
        name_en: "Hua Sai",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801601,
            zip_code: 80170,
            name_th: "หัวไทร",
            name_en: "Hua Sai",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801602,
            zip_code: 80170,
            name_th: "หน้าสตน",
            name_en: "Na Saton",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801603,
            zip_code: 80170,
            name_th: "ทรายขาว",
            name_en: "Sai Khao",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801604,
            zip_code: 80170,
            name_th: "แหลม",
            name_en: "Laem",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801605,
            zip_code: 80170,
            name_th: "เขาพังไกร",
            name_en: "Khao Phang Krai",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801606,
            zip_code: 80170,
            name_th: "บ้านราม",
            name_en: "Ban Ram",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801607,
            zip_code: 80170,
            name_th: "บางนบ",
            name_en: "Bang Nop",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801608,
            zip_code: 80170,
            name_th: "ท่าซอม",
            name_en: "Tha Som",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801609,
            zip_code: 80170,
            name_th: "ควนชะลิก",
            name_en: "Khuan Chalik",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801610,
            zip_code: 80170,
            name_th: "รามแก้ว",
            name_en: "Ram Kaeo",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801611,
            zip_code: 80170,
            name_th: "เกาะเพชร",
            name_en: "Ko Phet",
            amphure_id: 8016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8017,
        name_th: "บางขัน",
        name_en: "Bang Khan",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801701,
            zip_code: 80360,
            name_th: "บางขัน",
            name_en: "Bang Khan",
            amphure_id: 8017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801702,
            zip_code: 80360,
            name_th: "บ้านลำนาว",
            name_en: "Ban Lamnao",
            amphure_id: 8017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801703,
            zip_code: 80360,
            name_th: "วังหิน",
            name_en: "Wang Hin",
            amphure_id: 8017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801704,
            zip_code: 80360,
            name_th: "บ้านนิคม",
            name_en: "Ban Nikhom",
            amphure_id: 8017,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8018,
        name_th: "ถ้ำพรรณรา",
        name_en: "Tham Phannara",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801801,
            zip_code: 80260,
            name_th: "ถ้ำพรรณรา",
            name_en: "Tham Phannara",
            amphure_id: 8018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801802,
            zip_code: 80260,
            name_th: "คลองเส",
            name_en: "Khlong Se",
            amphure_id: 8018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801803,
            zip_code: 80260,
            name_th: "ดุสิต",
            name_en: "Dusit",
            amphure_id: 8018,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8019,
        name_th: "จุฬาภรณ์",
        name_en: "Chulabhorn",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 801901,
            zip_code: 80180,
            name_th: "บ้านควนมุด",
            name_en: "Ban Khuan Mut",
            amphure_id: 8019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801902,
            zip_code: 80180,
            name_th: "บ้านชะอวด",
            name_en: "Ban Cha-uat",
            amphure_id: 8019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801903,
            zip_code: 80130,
            name_th: "ควนหนองคว้า",
            name_en: "Khuan Nong Khwa",
            amphure_id: 8019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801904,
            zip_code: 80130,
            name_th: "ทุ่งโพธิ์",
            name_en: "Thung Pho",
            amphure_id: 8019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801905,
            zip_code: 80130,
            name_th: "นาหมอบุญ",
            name_en: "Na Mo Bun",
            amphure_id: 8019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 801906,
            zip_code: 80130,
            name_th: "สามตำบล",
            name_en: "Sam Tambon",
            amphure_id: 8019,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8020,
        name_th: "พระพรหม",
        name_en: "Phra Phrom",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 802001,
            zip_code: 80000,
            name_th: "นาพรุ",
            name_en: "Na Phru",
            amphure_id: 8020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802002,
            zip_code: 80000,
            name_th: "นาสาร",
            name_en: "Na San",
            amphure_id: 8020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802003,
            zip_code: 80000,
            name_th: "ท้ายสำเภา",
            name_en: "Thai Samphao",
            amphure_id: 8020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802004,
            zip_code: 80000,
            name_th: "ช้างซ้าย",
            name_en: "Chang Sai",
            amphure_id: 8020,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8021,
        name_th: "นบพิตำ",
        name_en: "Nopphitam",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 802101,
            zip_code: 80160,
            name_th: "นบพิตำ",
            name_en: "Nopphitam",
            amphure_id: 8021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802102,
            zip_code: 80160,
            name_th: "กรุงชิง",
            name_en: "Krung Ching",
            amphure_id: 8021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802103,
            zip_code: 80160,
            name_th: "กะหรอ",
            name_en: "Karo",
            amphure_id: 8021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802104,
            zip_code: 80160,
            name_th: "นาเหรง",
            name_en: "Na Reng",
            amphure_id: 8021,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8022,
        name_th: "ช้างกลาง",
        name_en: "Chang Klang",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 802201,
            zip_code: 80250,
            name_th: "ช้างกลาง",
            name_en: "Chang Klang",
            amphure_id: 8022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802202,
            zip_code: 80250,
            name_th: "หลักช้าง",
            name_en: "Lak Chang",
            amphure_id: 8022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802203,
            zip_code: 80250,
            name_th: "สวนขัน",
            name_en: "Suan Kan",
            amphure_id: 8022,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8023,
        name_th: "เฉลิมพระเกียรติ",
        name_en: "Chaloem Phra Kiat",
        province_id: 63,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 802301,
            zip_code: 80190,
            name_th: "เชียรเขา",
            name_en: "Chian Khao",
            amphure_id: 8023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802302,
            zip_code: 80290,
            name_th: "ดอนตรอ",
            name_en: "Don Tro",
            amphure_id: 8023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802303,
            zip_code: 80190,
            name_th: "สวนหลวง",
            name_en: "Suan Luang",
            amphure_id: 8023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 802304,
            zip_code: 80190,
            name_th: "ทางพูน",
            name_en: "Thang Phun",
            amphure_id: 8023,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 64,
    name_th: "กระบี่",
    name_en: "Krabi",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8101,
        name_th: "เมืองกระบี่",
        name_en: "Mueang Krabi",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810101,
            zip_code: 81000,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810102,
            zip_code: 81000,
            name_th: "กระบี่ใหญ่",
            name_en: "Krabi Yai",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810103,
            zip_code: 81000,
            name_th: "กระบี่น้อย",
            name_en: "Krabi Noi",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810105,
            zip_code: 81000,
            name_th: "เขาคราม",
            name_en: "Khao Khram",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810106,
            zip_code: 81000,
            name_th: "เขาทอง",
            name_en: "Khao Thong",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810111,
            zip_code: 81000,
            name_th: "ทับปริก",
            name_en: "Thap Prik",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810115,
            zip_code: 81000,
            name_th: "ไสไทย",
            name_en: "Sai Thai",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810116,
            zip_code: 81000,
            name_th: "อ่าวนาง",
            name_en: "Ao Nang",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810117,
            zip_code: 81000,
            name_th: "หนองทะเล",
            name_en: "Nong Thale",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810118,
            zip_code: 81000,
            name_th: "คลองประสงค์",
            name_en: "Khlong Prasong",
            amphure_id: 8101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8102,
        name_th: "เขาพนม",
        name_en: "Khao Phanom",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810201,
            zip_code: 81140,
            name_th: "เขาพนม",
            name_en: "Khao Phanom",
            amphure_id: 8102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810202,
            zip_code: 81140,
            name_th: "เขาดิน",
            name_en: "Khao Din",
            amphure_id: 8102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810203,
            zip_code: 80240,
            name_th: "สินปุน",
            name_en: "Sin Pun",
            amphure_id: 8102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810204,
            zip_code: 81140,
            name_th: "พรุเตียว",
            name_en: "Phru Tiao",
            amphure_id: 8102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810205,
            zip_code: 81140,
            name_th: "หน้าเขา",
            name_en: "Na Khao",
            amphure_id: 8102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810206,
            zip_code: 80240,
            name_th: "โคกหาร",
            name_en: "Khok Han",
            amphure_id: 8102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8103,
        name_th: "เกาะลันตา",
        name_en: "Ko Lanta",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810301,
            zip_code: 81150,
            name_th: "เกาะลันตาใหญ่",
            name_en: "Ko Lanta Yai",
            amphure_id: 8103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810302,
            zip_code: 81150,
            name_th: "เกาะลันตาน้อย",
            name_en: "Ko Lanta Noi",
            amphure_id: 8103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810303,
            zip_code: 81120,
            name_th: "เกาะกลาง",
            name_en: "Ko Klang",
            amphure_id: 8103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810304,
            zip_code: 81120,
            name_th: "คลองยาง",
            name_en: "Khlong Yang",
            amphure_id: 8103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810305,
            zip_code: 81150,
            name_th: "ศาลาด่าน",
            name_en: "Sala Dan",
            amphure_id: 8103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8104,
        name_th: "คลองท่อม",
        name_en: "Khlong Thom",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810401,
            zip_code: 81120,
            name_th: "คลองท่อมใต้",
            name_en: "Khlong Thom Tai",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810402,
            zip_code: 81120,
            name_th: "คลองท่อมเหนือ",
            name_en: "Khlong Thom Nuea",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810403,
            zip_code: 81170,
            name_th: "คลองพน",
            name_en: "Khlong Phon",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810404,
            zip_code: 81170,
            name_th: "ทรายขาว",
            name_en: "Sai Khao",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810405,
            zip_code: 81120,
            name_th: "ห้วยน้ำขาว",
            name_en: "Huai Nam Khao",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810406,
            zip_code: 81120,
            name_th: "พรุดินนา",
            name_en: "Phru Din Na",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810407,
            zip_code: 81120,
            name_th: "เพหลา",
            name_en: "Phela",
            amphure_id: 8104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8105,
        name_th: "อ่าวลึก",
        name_en: "Ao Luek",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810501,
            zip_code: 81110,
            name_th: "อ่าวลึกใต้",
            name_en: "Ao Luek Tai",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810502,
            zip_code: 81110,
            name_th: "แหลมสัก",
            name_en: "Laem Sak",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810503,
            zip_code: 81110,
            name_th: "นาเหนือ",
            name_en: "Na Nuea",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810504,
            zip_code: 81110,
            name_th: "คลองหิน",
            name_en: "Khlong Hin",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810505,
            zip_code: 81110,
            name_th: "อ่าวลึกน้อย",
            name_en: "Ao Luek Noi",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810506,
            zip_code: 81110,
            name_th: "อ่าวลึกเหนือ",
            name_en: "Ao Luek Nuea",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810507,
            zip_code: 81110,
            name_th: "เขาใหญ่",
            name_en: "Khao Yai",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810508,
            zip_code: 81110,
            name_th: "คลองยา",
            name_en: "Khlong Ya",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810509,
            zip_code: 81110,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 8105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8106,
        name_th: "ปลายพระยา",
        name_en: "Plai Phraya",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810601,
            zip_code: 81160,
            name_th: "ปลายพระยา",
            name_en: "Plai Phraya",
            amphure_id: 8106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810602,
            zip_code: 81160,
            name_th: "เขาเขน",
            name_en: "Khao Khen",
            amphure_id: 8106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810603,
            zip_code: 81160,
            name_th: "เขาต่อ",
            name_en: "Khao To",
            amphure_id: 8106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810604,
            zip_code: 81160,
            name_th: "คีรีวง",
            name_en: "Khiri Wong",
            amphure_id: 8106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8107,
        name_th: "ลำทับ",
        name_en: "Lam Thap",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810701,
            zip_code: 81120,
            name_th: "ลำทับ",
            name_en: "Lam Thap",
            amphure_id: 8107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810702,
            zip_code: 81120,
            name_th: "ดินอุดม",
            name_en: "Din Udom",
            amphure_id: 8107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810703,
            zip_code: 81120,
            name_th: "ทุ่งไทรทอง",
            name_en: "Thung Sai Thong",
            amphure_id: 8107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810704,
            zip_code: 81120,
            name_th: "ดินแดง",
            name_en: "Din Daeng",
            amphure_id: 8107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8108,
        name_th: "เหนือคลอง",
        name_en: "Nuea Khlong",
        province_id: 64,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 810801,
            zip_code: 81130,
            name_th: "เหนือคลอง",
            name_en: "Nuea Khlong",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810802,
            zip_code: 81130,
            name_th: "เกาะศรีบอยา",
            name_en: "Ko Si Boya",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810803,
            zip_code: 81130,
            name_th: "คลองขนาน",
            name_en: "Khlong Khanan",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810804,
            zip_code: 81130,
            name_th: "คลองเขม้า",
            name_en: "Khlong Khamao",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810805,
            zip_code: 81130,
            name_th: "โคกยาง",
            name_en: "Khok Yang",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810806,
            zip_code: 81130,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810807,
            zip_code: 81130,
            name_th: "ปกาสัย",
            name_en: "Pakasai",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 810808,
            zip_code: 81130,
            name_th: "ห้วยยูง",
            name_en: "Huai Yung",
            amphure_id: 8108,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 65,
    name_th: "พังงา",
    name_en: "Phangnga",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8201,
        name_th: "เมืองพังงา",
        name_en: "Mueang Phang-nga",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820101,
            zip_code: 82000,
            name_th: "ท้ายช้าง",
            name_en: "Thai Chang",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820102,
            zip_code: 82000,
            name_th: "นบปริง",
            name_en: "Nop Pring",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820103,
            zip_code: 82000,
            name_th: "ถ้ำน้ำผุด",
            name_en: "Tham Nam Phut",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820104,
            zip_code: 82000,
            name_th: "บางเตย",
            name_en: "Bang Toei",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820105,
            zip_code: 82000,
            name_th: "ตากแดด",
            name_en: "Tak Daet",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820106,
            zip_code: 82000,
            name_th: "สองแพรก",
            name_en: "Song Phraek",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820107,
            zip_code: 82000,
            name_th: "ทุ่งคาโงก",
            name_en: "Thung Kha Ngok",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820108,
            zip_code: 82000,
            name_th: "เกาะปันหยี",
            name_en: "Ko Panyi",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820109,
            zip_code: 82000,
            name_th: "ป่ากอ",
            name_en: "Pa Ko",
            amphure_id: 8201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8202,
        name_th: "เกาะยาว",
        name_en: "Ko Yao",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820201,
            zip_code: 82160,
            name_th: "เกาะยาวน้อย",
            name_en: "Ko Yao Noi",
            amphure_id: 8202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820202,
            zip_code: 82160,
            name_th: "เกาะยาวใหญ่",
            name_en: "Ko Yao Yai",
            amphure_id: 8202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820203,
            zip_code: 83000,
            name_th: "พรุใน",
            name_en: "Pru Nai",
            amphure_id: 8202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8203,
        name_th: "กะปง",
        name_en: "Kapong",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820301,
            zip_code: 82170,
            name_th: "กะปง",
            name_en: "Kapong",
            amphure_id: 8203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820302,
            zip_code: 82170,
            name_th: "ท่านา",
            name_en: "Tha Na",
            amphure_id: 8203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820303,
            zip_code: 82170,
            name_th: "เหมาะ",
            name_en: "Mo",
            amphure_id: 8203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820304,
            zip_code: 82170,
            name_th: "เหล",
            name_en: "Le",
            amphure_id: 8203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820305,
            zip_code: 82170,
            name_th: "รมณีย์",
            name_en: "Rommani",
            amphure_id: 8203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8204,
        name_th: "ตะกั่วทุ่ง",
        name_en: "Takua Thung",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820401,
            zip_code: 82130,
            name_th: "ถ้ำ",
            name_en: "Tham",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820402,
            zip_code: 82130,
            name_th: "กระโสม",
            name_en: "Krasom",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820403,
            zip_code: 82130,
            name_th: "กะไหล",
            name_en: "Kalai",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820404,
            zip_code: 82130,
            name_th: "ท่าอยู่",
            name_en: "Tha Yu",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820405,
            zip_code: 82140,
            name_th: "หล่อยูง",
            name_en: "Lo Yung",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820406,
            zip_code: 82140,
            name_th: "โคกกลอย",
            name_en: "Khok Kloi",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820407,
            zip_code: 82130,
            name_th: "คลองเคียน",
            name_en: "Khlong Khian",
            amphure_id: 8204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8205,
        name_th: "ตะกั่วป่า",
        name_en: "Takua Pa",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820501,
            zip_code: 82110,
            name_th: "ตะกั่วป่า",
            name_en: "Takua Pa",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820502,
            zip_code: 82110,
            name_th: "บางนายสี",
            name_en: "Bang Nai Si",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820503,
            zip_code: 82110,
            name_th: "บางไทร",
            name_en: "Bang Sai",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820504,
            zip_code: 82110,
            name_th: "บางม่วง",
            name_en: "Bang Muang",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820505,
            zip_code: 82110,
            name_th: "ตำตัว",
            name_en: "Tam Tua",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820506,
            zip_code: 82110,
            name_th: "โคกเคียน",
            name_en: "Khok Khian",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820507,
            zip_code: 82190,
            name_th: "คึกคัก",
            name_en: "Khuekkhak",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820508,
            zip_code: 82190,
            name_th: "เกาะคอเขา",
            name_en: "Ko Kho Khao",
            amphure_id: 8205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8206,
        name_th: "คุระบุรี",
        name_en: "Khura Buri",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820601,
            zip_code: 82150,
            name_th: "คุระ",
            name_en: "Khura",
            amphure_id: 8206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820602,
            zip_code: 82150,
            name_th: "บางวัน",
            name_en: "Bang Wan",
            amphure_id: 8206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820603,
            zip_code: 82150,
            name_th: "เกาะพระทอง",
            name_en: "Ko Phra Thong",
            amphure_id: 8206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820605,
            zip_code: 82150,
            name_th: "แม่นางขาว",
            name_en: "Mae Nang Khao",
            amphure_id: 8206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8207,
        name_th: "ทับปุด",
        name_en: "Thap Put",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820701,
            zip_code: 82180,
            name_th: "ทับปุด",
            name_en: "Thap Put",
            amphure_id: 8207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820702,
            zip_code: 82180,
            name_th: "มะรุ่ย",
            name_en: "Marui",
            amphure_id: 8207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820703,
            zip_code: 82180,
            name_th: "บ่อแสน",
            name_en: "Bo Saen",
            amphure_id: 8207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820704,
            zip_code: 82180,
            name_th: "ถ้ำทองหลาง",
            name_en: "Tham Thonglang",
            amphure_id: 8207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820705,
            zip_code: 82180,
            name_th: "โคกเจริญ",
            name_en: "Khok Charoen",
            amphure_id: 8207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820706,
            zip_code: 82180,
            name_th: "บางเหรียง",
            name_en: "Bang Riang",
            amphure_id: 8207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8208,
        name_th: "ท้ายเหมือง",
        name_en: "Thai Mueang",
        province_id: 65,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 820801,
            zip_code: 82120,
            name_th: "ท้ายเหมือง",
            name_en: "Thai Mueang",
            amphure_id: 8208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820802,
            zip_code: 82120,
            name_th: "นาเตย",
            name_en: "Na Toei",
            amphure_id: 8208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820803,
            zip_code: 82120,
            name_th: "บางทอง",
            name_en: "Bang Thong",
            amphure_id: 8208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820804,
            zip_code: 82120,
            name_th: "ทุ่งมะพร้าว",
            name_en: "Thung Maphrao",
            amphure_id: 8208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820805,
            zip_code: 82120,
            name_th: "ลำภี",
            name_en: "Lam Phi",
            amphure_id: 8208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 820806,
            zip_code: 82120,
            name_th: "ลำแก่น",
            name_en: "Lam Kaen",
            amphure_id: 8208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 66,
    name_th: "ภูเก็ต",
    name_en: "Phuket",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8301,
        name_th: "เมืองภูเก็ต",
        name_en: "Mueang Phuket",
        province_id: 66,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 830101,
            zip_code: 83000,
            name_th: "ตลาดใหญ่",
            name_en: "Talat Yai",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830102,
            zip_code: 83000,
            name_th: "ตลาดเหนือ",
            name_en: "Talat Nuea",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830103,
            zip_code: 83000,
            name_th: "เกาะแก้ว",
            name_en: "Ko Kaeo",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830104,
            zip_code: 83000,
            name_th: "รัษฎา",
            name_en: "Ratsada",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830105,
            zip_code: 83000,
            name_th: "วิชิต",
            name_en: "Wichit",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830106,
            zip_code: 83130,
            name_th: "ฉลอง",
            name_en: "Chalong",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830107,
            zip_code: 83130,
            name_th: "ราไวย์",
            name_en: "Rawai",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830108,
            zip_code: 83100,
            name_th: "กะรน",
            name_en: "Karon",
            amphure_id: 8301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8302,
        name_th: "กะทู้",
        name_en: "Kathu",
        province_id: 66,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 830201,
            zip_code: 83120,
            name_th: "กะทู้",
            name_en: "Kathu",
            amphure_id: 8302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830202,
            zip_code: 83150,
            name_th: "ป่าตอง",
            name_en: "Pa Tong",
            amphure_id: 8302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830203,
            zip_code: 83150,
            name_th: "กมลา",
            name_en: "Kamala",
            amphure_id: 8302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8303,
        name_th: "ถลาง",
        name_en: "Thalang",
        province_id: 66,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 830301,
            zip_code: 83110,
            name_th: "เทพกระษัตรี",
            name_en: "Thep Krasattri",
            amphure_id: 8303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830302,
            zip_code: 83110,
            name_th: "ศรีสุนทร",
            name_en: "Si Sunthon",
            amphure_id: 8303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830303,
            zip_code: 83110,
            name_th: "เชิงทะเล",
            name_en: "Choeng Thale",
            amphure_id: 8303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830304,
            zip_code: 83110,
            name_th: "ป่าคลอก",
            name_en: "Pa Khlok",
            amphure_id: 8303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830305,
            zip_code: 83110,
            name_th: "ไม้ขาว",
            name_en: "Mai Khao",
            amphure_id: 8303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 830306,
            zip_code: 83110,
            name_th: "สาคู",
            name_en: "Sakhu",
            amphure_id: 8303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 67,
    name_th: "สุราษฎร์ธานี",
    name_en: "Surat Thani",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8401,
        name_th: "เมืองสุราษฎร์ธานี",
        name_en: "Mueang Surat Thani",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840101,
            zip_code: 84000,
            name_th: "ตลาด",
            name_en: "Talat",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840102,
            zip_code: 84000,
            name_th: "มะขามเตี้ย",
            name_en: "Makham Tia",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840103,
            zip_code: 84000,
            name_th: "วัดประดู่",
            name_en: "Wat Pradu",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840104,
            zip_code: 84100,
            name_th: "ขุนทะเล",
            name_en: "Khun Thale",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840105,
            zip_code: 84000,
            name_th: "บางใบไม้",
            name_en: "Bang Bai Mai",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840106,
            zip_code: 84000,
            name_th: "บางชนะ",
            name_en: "Bang Chana",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840107,
            zip_code: 84000,
            name_th: "คลองน้อย",
            name_en: "Khlong Noi",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840108,
            zip_code: 84000,
            name_th: "บางไทร",
            name_en: "Bang Sai",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840109,
            zip_code: 84000,
            name_th: "บางโพธิ์",
            name_en: "Bang Pho",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840110,
            zip_code: 84000,
            name_th: "บางกุ้ง",
            name_en: "Bang Kung",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840111,
            zip_code: 84000,
            name_th: "คลองฉนาก",
            name_en: "Khlong Chanak",
            amphure_id: 8401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8402,
        name_th: "กาญจนดิษฐ์",
        name_en: "Kanchanadit",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840201,
            zip_code: 84290,
            name_th: "ท่าทองใหม่",
            name_en: "Tha Thong",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840202,
            zip_code: 84160,
            name_th: "ท่าทอง",
            name_en: "Tha Thong Mai",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840203,
            zip_code: 84160,
            name_th: "กะแดะ",
            name_en: "Kadae",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840204,
            zip_code: 84290,
            name_th: "ทุ่งกง",
            name_en: "Thung Kong",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840205,
            zip_code: 84160,
            name_th: "กรูด",
            name_en: "Krut",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840206,
            zip_code: 84160,
            name_th: "ช้างซ้าย",
            name_en: "Chang Sai",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840207,
            zip_code: 84160,
            name_th: "พลายวาส",
            name_en: "Phlai Wat",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840208,
            zip_code: 84160,
            name_th: "ป่าร่อน",
            name_en: "Pa Ron",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840209,
            zip_code: 84160,
            name_th: "ตะเคียนทอง",
            name_en: "Takhian Thong",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840210,
            zip_code: 84160,
            name_th: "ช้างขวา",
            name_en: "Chang Khwa",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840211,
            zip_code: 84160,
            name_th: "ท่าอุแท",
            name_en: "Tha Uthae",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840212,
            zip_code: 84290,
            name_th: "ทุ่งรัง",
            name_en: "Thung Rung",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840213,
            zip_code: 84160,
            name_th: "คลองสระ",
            name_en: "Khlong Sa",
            amphure_id: 8402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8403,
        name_th: "ดอนสัก",
        name_en: "Don Sak",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840301,
            zip_code: 84220,
            name_th: "ดอนสัก",
            name_en: "Don Sak",
            amphure_id: 8403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840302,
            zip_code: 84160,
            name_th: "ชลคราม",
            name_en: "Chonlakhram",
            amphure_id: 8403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840303,
            zip_code: 84220,
            name_th: "ไชยคราม",
            name_en: "Chaiyakhram",
            amphure_id: 8403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840304,
            zip_code: 84340,
            name_th: "ปากแพรก",
            name_en: "Pak Phraek",
            amphure_id: 8403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8404,
        name_th: "เกาะสมุย",
        name_en: "Ko Samui",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840401,
            zip_code: 84140,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840402,
            zip_code: 84140,
            name_th: "ลิปะน้อย",
            name_en: "Lipa Noi",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840403,
            zip_code: 84140,
            name_th: "ตลิ่งงาม",
            name_en: "Taling Ngam",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840404,
            zip_code: 84140,
            name_th: "หน้าเมือง",
            name_en: "Na Mueang",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840405,
            zip_code: 84310,
            name_th: "มะเร็ต",
            name_en: "Maret",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840406,
            zip_code: 84320,
            name_th: "บ่อผุด",
            name_en: "Bo Phut",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840407,
            zip_code: 84330,
            name_th: "แม่น้ำ",
            name_en: "Mae Nam",
            amphure_id: 8404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8405,
        name_th: "เกาะพะงัน",
        name_en: "Ko Pha-ngan",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840501,
            zip_code: 84280,
            name_th: "เกาะพะงัน",
            name_en: "Ko Pha-ngan",
            amphure_id: 8405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840502,
            zip_code: 84280,
            name_th: "บ้านใต้",
            name_en: "Ban Tai",
            amphure_id: 8405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840503,
            zip_code: 84280,
            name_th: "เกาะเต่า",
            name_en: "Koh Tao",
            amphure_id: 8405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8406,
        name_th: "ไชยา",
        name_en: "Chaiya",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840601,
            zip_code: 84110,
            name_th: "ตลาดไชยา",
            name_en: "Talat Chaiya",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840602,
            zip_code: 84110,
            name_th: "พุมเรียง",
            name_en: "Phumriang",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840603,
            zip_code: 84110,
            name_th: "เลม็ด",
            name_en: "Lamet",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840604,
            zip_code: 84110,
            name_th: "เวียง",
            name_en: "Wiang",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840605,
            zip_code: 84110,
            name_th: "ทุ่ง",
            name_en: "Thung",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840606,
            zip_code: 84110,
            name_th: "ป่าเว",
            name_en: "Pa We",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840607,
            zip_code: 84110,
            name_th: "ตะกรบ",
            name_en: "Takrop",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840608,
            zip_code: 84110,
            name_th: "โมถ่าย",
            name_en: "Mo Thai",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840609,
            zip_code: 84110,
            name_th: "ปากหมาก",
            name_en: "Pak Mak",
            amphure_id: 8406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8407,
        name_th: "ท่าชนะ",
        name_en: "Tha Chana",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840701,
            zip_code: 84170,
            name_th: "ท่าชนะ",
            name_en: "Tha Chana",
            amphure_id: 8407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840702,
            zip_code: 84170,
            name_th: "สมอทอง",
            name_en: "Samo Thong",
            amphure_id: 8407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840703,
            zip_code: 84170,
            name_th: "ประสงค์",
            name_en: "Prasong",
            amphure_id: 8407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840704,
            zip_code: 84170,
            name_th: "คันธุลี",
            name_en: "Khan Thuli",
            amphure_id: 8407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840705,
            zip_code: 84170,
            name_th: "วัง",
            name_en: "Wang",
            amphure_id: 8407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840706,
            zip_code: 84170,
            name_th: "คลองพา",
            name_en: "Khlong Pha",
            amphure_id: 8407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8408,
        name_th: "คีรีรัฐนิคม",
        name_en: "Khiri Rat Nikhom",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840801,
            zip_code: 84180,
            name_th: "ท่าขนอน",
            name_en: "Tha Khanon",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840802,
            zip_code: 84180,
            name_th: "บ้านยาง",
            name_en: "Ban Yang",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840803,
            zip_code: 84180,
            name_th: "น้ำหัก",
            name_en: "Nam Hak",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840806,
            zip_code: 84180,
            name_th: "กะเปา",
            name_en: "Kapao",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840807,
            zip_code: 84180,
            name_th: "ท่ากระดาน",
            name_en: "Tha Kradan",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840808,
            zip_code: 84180,
            name_th: "ย่านยาว",
            name_en: "Yan Yao",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840809,
            zip_code: 84180,
            name_th: "ถ้ำสิงขร",
            name_en: "Tham Singkhon",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840810,
            zip_code: 84180,
            name_th: "บ้านทำเนียบ",
            name_en: "Ban Thamniap",
            amphure_id: 8408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8409,
        name_th: "บ้านตาขุน",
        name_en: "Ban Ta Khun",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 840901,
            zip_code: 84230,
            name_th: "เขาวง",
            name_en: "Khao Wong",
            amphure_id: 8409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840902,
            zip_code: 84230,
            name_th: "พระแสง",
            name_en: "Phasaeng",
            amphure_id: 8409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840903,
            zip_code: 84230,
            name_th: "พรุไทย",
            name_en: "Phru Thai",
            amphure_id: 8409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 840904,
            zip_code: 84230,
            name_th: "เขาพัง",
            name_en: "Khao Phang",
            amphure_id: 8409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8410,
        name_th: "พนม",
        name_en: "Phanom",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841001,
            zip_code: 84250,
            name_th: "พนม",
            name_en: "Phanom",
            amphure_id: 8410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841002,
            zip_code: 84250,
            name_th: "ต้นยวน",
            name_en: "Ton Yuan",
            amphure_id: 8410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841003,
            zip_code: 84250,
            name_th: "คลองศก",
            name_en: "Khlong Sok",
            amphure_id: 8410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841004,
            zip_code: 84250,
            name_th: "พลูเถื่อน",
            name_en: "Phlu Thuean",
            amphure_id: 8410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841005,
            zip_code: 84250,
            name_th: "พังกาญจน์",
            name_en: "Phang Kan",
            amphure_id: 8410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841006,
            zip_code: 84250,
            name_th: "คลองชะอุ่น",
            name_en: "Khlong Cha-un",
            amphure_id: 8410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8411,
        name_th: "ท่าฉาง",
        name_en: "Tha Chang",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841101,
            zip_code: 84150,
            name_th: "ท่าฉาง",
            name_en: "Tha Chang",
            amphure_id: 8411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841102,
            zip_code: 84150,
            name_th: "ท่าเคย",
            name_en: "Tha Khoei",
            amphure_id: 8411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841103,
            zip_code: 84150,
            name_th: "คลองไทร",
            name_en: "Khlong Sai",
            amphure_id: 8411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841104,
            zip_code: 84150,
            name_th: "เขาถ่าน",
            name_en: "Khao Than",
            amphure_id: 8411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841105,
            zip_code: 84150,
            name_th: "เสวียด",
            name_en: "Sawiat",
            amphure_id: 8411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841106,
            zip_code: 84150,
            name_th: "ปากฉลุย",
            name_en: "Pak Chalui",
            amphure_id: 8411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8412,
        name_th: "บ้านนาสาร",
        name_en: "Ban Na San",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841201,
            zip_code: 84120,
            name_th: "นาสาร",
            name_en: "Na San",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841202,
            zip_code: 84270,
            name_th: "พรุพี",
            name_en: "Phru Phi",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841203,
            zip_code: 84120,
            name_th: "ทุ่งเตา",
            name_en: "Thung Tao",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841204,
            zip_code: 84120,
            name_th: "ลำพูน",
            name_en: "Lamphun",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841205,
            zip_code: 84120,
            name_th: "ท่าชี",
            name_en: "Tha Chi",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841206,
            zip_code: 84270,
            name_th: "ควนศรี",
            name_en: "Khuan Si",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841207,
            zip_code: 84120,
            name_th: "ควนสุบรรณ",
            name_en: "Khuan Suban",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841208,
            zip_code: 84120,
            name_th: "คลองปราบ",
            name_en: "Khlong Prap",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841209,
            zip_code: 84120,
            name_th: "น้ำพุ",
            name_en: "Nam Phu",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841210,
            zip_code: 84120,
            name_th: "ทุ่งเตาใหม่",
            name_en: "Thung Tao Mai)",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841211,
            zip_code: 84120,
            name_th: "เพิ่มพูนทรัพย์",
            name_en: "Phoem Phun Sap",
            amphure_id: 8412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8413,
        name_th: "บ้านนาเดิม",
        name_en: "Ban Na Doem",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841301,
            zip_code: 84240,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 8413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841302,
            zip_code: 84240,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 8413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841303,
            zip_code: 84240,
            name_th: "ทรัพย์ทวี",
            name_en: "Sap Thawi",
            amphure_id: 8413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841304,
            zip_code: 84240,
            name_th: "นาใต้",
            name_en: "Na Tai",
            amphure_id: 8413,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8414,
        name_th: "เคียนซา",
        name_en: "Khian Sa",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841401,
            zip_code: 84260,
            name_th: "เคียนซา",
            name_en: "Khian Sa",
            amphure_id: 8414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841402,
            zip_code: 84210,
            name_th: "พ่วงพรมคร",
            name_en: "Phuang Phromkhon",
            amphure_id: 8414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841403,
            zip_code: 84260,
            name_th: "เขาตอก",
            name_en: "Khao Tok",
            amphure_id: 8414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841404,
            zip_code: 84260,
            name_th: "อรัญคามวารี",
            name_en: "Aranyakham Wari",
            amphure_id: 8414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841405,
            zip_code: 84260,
            name_th: "บ้านเสด็จ",
            name_en: "Ban Sadet",
            amphure_id: 8414,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8415,
        name_th: "เวียงสระ",
        name_en: "Wiang Sa",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841501,
            zip_code: 84190,
            name_th: "เวียงสระ",
            name_en: "Wiang Sa",
            amphure_id: 8415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841502,
            zip_code: 84190,
            name_th: "บ้านส้อง",
            name_en: "Ban Song",
            amphure_id: 8415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841503,
            zip_code: 84190,
            name_th: "คลองฉนวน",
            name_en: "Khlong Chanuan",
            amphure_id: 8415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841504,
            zip_code: 84190,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 8415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841505,
            zip_code: 84190,
            name_th: "เขานิพันธ์",
            name_en: "*Khao Niphan",
            amphure_id: 8415,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8416,
        name_th: "พระแสง",
        name_en: "Phrasaeng",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841601,
            zip_code: 84210,
            name_th: "อิปัน",
            name_en: "Ipan",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841602,
            zip_code: 84210,
            name_th: "สินปุน",
            name_en: "Sin Pun",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841603,
            zip_code: 84210,
            name_th: "บางสวรรค์",
            name_en: "Bang Sawan",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841604,
            zip_code: 84210,
            name_th: "ไทรขึง",
            name_en: "Sai Khueng",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841605,
            zip_code: 84210,
            name_th: "สินเจริญ",
            name_en: "Sin Charoen",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841606,
            zip_code: 84210,
            name_th: "ไทรโสภา",
            name_en: "Sai Sopha",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841607,
            zip_code: 84210,
            name_th: "สาคู",
            name_en: "Sakhu",
            amphure_id: 8416,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8417,
        name_th: "พุนพิน",
        name_en: "Phunphin",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841701,
            zip_code: 84130,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841702,
            zip_code: 84130,
            name_th: "ท่าสะท้อน",
            name_en: "Tha Sathon",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841703,
            zip_code: 84130,
            name_th: "ลีเล็ด",
            name_en: "Lilet",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841704,
            zip_code: 84130,
            name_th: "บางมะเดื่อ",
            name_en: "Bang Maduea",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841705,
            zip_code: 84130,
            name_th: "บางเดือน",
            name_en: "Bang Duean)",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841706,
            zip_code: 84130,
            name_th: "ท่าโรงช้าง",
            name_en: "Tha Rong Chang",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841707,
            zip_code: 84130,
            name_th: "กรูด",
            name_en: "Krut",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841708,
            zip_code: 84130,
            name_th: "พุนพิน",
            name_en: "Phunphin",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841709,
            zip_code: 84130,
            name_th: "บางงอน",
            name_en: "Bang Ngon",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841710,
            zip_code: 84130,
            name_th: "ศรีวิชัย",
            name_en: "Si Wichai",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841711,
            zip_code: 84130,
            name_th: "น้ำรอบ",
            name_en: "Nam Rop",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841712,
            zip_code: 84130,
            name_th: "มะลวน",
            name_en: "Maluan",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841713,
            zip_code: 84130,
            name_th: "หัวเตย",
            name_en: "Hua Toei",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841714,
            zip_code: 84130,
            name_th: "หนองไทร",
            name_en: "Nong Sai",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841715,
            zip_code: 84130,
            name_th: "เขาหัวควาย",
            name_en: "Khao Hua Khwai",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841716,
            zip_code: 84130,
            name_th: "ตะปาน",
            name_en: "Tapan",
            amphure_id: 8417,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8418,
        name_th: "ชัยบุรี",
        name_en: "Chai Buri",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841801,
            zip_code: 84350,
            name_th: "สองแพรก",
            name_en: "Song Phraek",
            amphure_id: 8418,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841802,
            zip_code: 84350,
            name_th: "ชัยบุรี",
            name_en: "Chai Buri",
            amphure_id: 8418,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841803,
            zip_code: 84350,
            name_th: "คลองน้อย",
            name_en: "Khlong Noi",
            amphure_id: 8418,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841804,
            zip_code: 84350,
            name_th: "ไทรทอง",
            name_en: "Sai Thong",
            amphure_id: 8418,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8419,
        name_th: "วิภาวดี",
        name_en: "Vibhavadi",
        province_id: 67,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 841901,
            zip_code: 84180,
            name_th: "ตะกุกใต้",
            name_en: "Takuk Tai",
            amphure_id: 8419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 841902,
            zip_code: 84180,
            name_th: "ตะกุกเหนือ",
            name_en: "Takuk Nuea",
            amphure_id: 8419,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 68,
    name_th: "ระนอง",
    name_en: "Ranong",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8501,
        name_th: "เมืองระนอง",
        name_en: "Mueang Ranong",
        province_id: 68,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 850101,
            zip_code: 85000,
            name_th: "เขานิเวศน์",
            name_en: "Khao Niwet",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850102,
            zip_code: 85000,
            name_th: "ราชกรูด",
            name_en: "Ratchakrut",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850103,
            zip_code: 85000,
            name_th: "หงาว",
            name_en: "Ngao",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850104,
            zip_code: 85000,
            name_th: "บางริ้น",
            name_en: "Bang Rin",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850105,
            zip_code: 85000,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850106,
            zip_code: 85000,
            name_th: "บางนอน",
            name_en: "Bang Non",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850107,
            zip_code: 85000,
            name_th: "หาดส้มแป้น",
            name_en: "Hat Som Paen",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850108,
            zip_code: 85130,
            name_th: "ทรายแดง",
            name_en: "Sai Daeng",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850109,
            zip_code: 85000,
            name_th: "เกาะพยาม",
            name_en: "Ko Phayam",
            amphure_id: 8501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8502,
        name_th: "ละอุ่น",
        name_en: "La-un",
        province_id: 68,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 850201,
            zip_code: 85130,
            name_th: "ละอุ่นใต้",
            name_en: "La-un Tai",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850202,
            zip_code: 85130,
            name_th: "ละอุ่นเหนือ",
            name_en: "La-un Nuea",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850203,
            zip_code: 85130,
            name_th: "บางพระใต้",
            name_en: "Bang Phra Tai",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850204,
            zip_code: 85130,
            name_th: "บางพระเหนือ",
            name_en: "Bang Phra Nuea",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850205,
            zip_code: 85130,
            name_th: "บางแก้ว",
            name_en: "Bang Kaeo",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850206,
            zip_code: 85130,
            name_th: "ในวงเหนือ",
            name_en: "Nai Wong Nuea",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850207,
            zip_code: 85130,
            name_th: "ในวงใต้",
            name_en: "Nai Wong Tai",
            amphure_id: 8502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8503,
        name_th: "กะเปอร์",
        name_en: "Kapoe",
        province_id: 68,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 850301,
            zip_code: 85120,
            name_th: "ม่วงกลวง",
            name_en: "Muang Kluang",
            amphure_id: 8503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850302,
            zip_code: 85120,
            name_th: "กะเปอร์",
            name_en: "Kapoe",
            amphure_id: 8503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850303,
            zip_code: 85120,
            name_th: "เชี่ยวเหลียง",
            name_en: "Chiao Liang",
            amphure_id: 8503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850304,
            zip_code: 85120,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 8503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850305,
            zip_code: 85120,
            name_th: "บางหิน",
            name_en: "Bang Hin",
            amphure_id: 8503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8504,
        name_th: "กระบุรี",
        name_en: "Kra Buri",
        province_id: 68,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 850401,
            zip_code: 85110,
            name_th: "น้ำจืด",
            name_en: "Nam Chuet",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850402,
            zip_code: 85110,
            name_th: "น้ำจืดน้อย",
            name_en: "Nam Chuet Noi",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850403,
            zip_code: 85110,
            name_th: "มะมุ",
            name_en: "Mamu",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850404,
            zip_code: 85110,
            name_th: "ปากจั่น",
            name_en: "Pak Chan",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850405,
            zip_code: 85110,
            name_th: "ลำเลียง",
            name_en: "Lamliang",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850406,
            zip_code: 85110,
            name_th: "จ.ป.ร.",
            name_en: "Choporo",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850407,
            zip_code: 85110,
            name_th: "บางใหญ่",
            name_en: "Bang Yai",
            amphure_id: 8504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8505,
        name_th: "สุขสำราญ",
        name_en: "Suk Samran",
        province_id: 68,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 850501,
            zip_code: 85120,
            name_th: "นาคา",
            name_en: "Nakha",
            amphure_id: 8505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 850502,
            zip_code: 85120,
            name_th: "กำพวน",
            name_en: "Kamphuan",
            amphure_id: 8505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 69,
    name_th: "ชุมพร",
    name_en: "Chumphon",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 8601,
        name_th: "เมืองชุมพร",
        name_en: "Mueang Chumphon",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860101,
            zip_code: 86000,
            name_th: "ท่าตะเภา",
            name_en: "Tha Taphao",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860102,
            zip_code: 86120,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860103,
            zip_code: 86000,
            name_th: "ท่ายาง",
            name_en: "Tha Yang",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860104,
            zip_code: 86000,
            name_th: "บางหมาก",
            name_en: "Bang Mak",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860105,
            zip_code: 86000,
            name_th: "นาทุ่ง",
            name_en: "Na Thung",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860106,
            zip_code: 86000,
            name_th: "นาชะอัง",
            name_en: "Na Cha-ang",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860107,
            zip_code: 86000,
            name_th: "ตากแดด",
            name_en: "Tak Daet",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860108,
            zip_code: 86000,
            name_th: "บางลึก",
            name_en: "Bang Luek",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860109,
            zip_code: 86000,
            name_th: "หาดพันไกร",
            name_en: "Hat Phan Krai",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860110,
            zip_code: 86000,
            name_th: "วังไผ่",
            name_en: "Wang Phai",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860111,
            zip_code: 86190,
            name_th: "วังใหม่",
            name_en: "Wang Mai",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860112,
            zip_code: 86190,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860113,
            zip_code: 86000,
            name_th: "ขุนกระทิง",
            name_en: "Khun Krathing",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860114,
            zip_code: 86100,
            name_th: "ทุ่งคา",
            name_en: "Thung Kha",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860115,
            zip_code: 86100,
            name_th: "วิสัยเหนือ",
            name_en: "Wisai Nuea",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860116,
            zip_code: 86120,
            name_th: "หาดทรายรี",
            name_en: "Hat Sai Ri",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860117,
            zip_code: 86100,
            name_th: "ถ้ำสิงห์",
            name_en: "Tham Sing",
            amphure_id: 8601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8602,
        name_th: "ท่าแซะ",
        name_en: "Tha Sae",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860201,
            zip_code: 86140,
            name_th: "ท่าแซะ",
            name_en: "Tha Sae",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860202,
            zip_code: 86140,
            name_th: "คุริง",
            name_en: "Khuring",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860203,
            zip_code: 86140,
            name_th: "สลุย",
            name_en: "Salui",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860204,
            zip_code: 86140,
            name_th: "นากระตาม",
            name_en: "Na Kratam",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860205,
            zip_code: 86190,
            name_th: "รับร่อ",
            name_en: "Rap Ro",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860206,
            zip_code: 86140,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860207,
            zip_code: 86140,
            name_th: "หงษ์เจริญ",
            name_en: "Hong Charoen",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860208,
            zip_code: 86190,
            name_th: "หินแก้ว",
            name_en: "Hin Kaeo",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860209,
            zip_code: 86140,
            name_th: "ทรัพย์อนันต์",
            name_en: "Sap Anan",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860210,
            zip_code: 86140,
            name_th: "สองพี่น้อง",
            name_en: "Song Phi Nong",
            amphure_id: 8602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8603,
        name_th: "ปะทิว",
        name_en: "Pathio",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860301,
            zip_code: 86160,
            name_th: "บางสน",
            name_en: "Bang Song",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860302,
            zip_code: 86160,
            name_th: "ทะเลทรัพย์",
            name_en: "Thale Sap",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860303,
            zip_code: 86230,
            name_th: "สะพลี",
            name_en: "Saphli",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860304,
            zip_code: 86160,
            name_th: "ชุมโค",
            name_en: "Chum Kho",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860305,
            zip_code: 86210,
            name_th: "ดอนยาง",
            name_en: "Don Yang",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860306,
            zip_code: 86210,
            name_th: "ปากคลอง",
            name_en: "Pak Khlong",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860307,
            zip_code: 86210,
            name_th: "เขาไชยราช",
            name_en: "Khao Chai Rat",
            amphure_id: 8603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8604,
        name_th: "หลังสวน",
        name_en: "Lang Suan",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860401,
            zip_code: 86110,
            name_th: "หลังสวน",
            name_en: "Lang Suan",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860402,
            zip_code: 86110,
            name_th: "ขันเงิน",
            name_en: "Khan Ngoen",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860403,
            zip_code: 86110,
            name_th: "ท่ามะพลา",
            name_en: "Tha Maphla",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860404,
            zip_code: 86110,
            name_th: "นาขา",
            name_en: "Na Kha",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860405,
            zip_code: 86110,
            name_th: "นาพญา",
            name_en: "Na Phaya",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860406,
            zip_code: 86110,
            name_th: "บ้านควน",
            name_en: "Ban Khuan",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860407,
            zip_code: 86110,
            name_th: "บางมะพร้าว",
            name_en: "Bang Maphrao",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860408,
            zip_code: 86150,
            name_th: "บางน้ำจืด",
            name_en: "Bang Nam Chuet",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860409,
            zip_code: 86150,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860410,
            zip_code: 86110,
            name_th: "พ้อแดง",
            name_en: "Pho Daeng",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860411,
            zip_code: 86110,
            name_th: "แหลมทราย",
            name_en: "Laem Sai",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860412,
            zip_code: 86110,
            name_th: "วังตะกอ",
            name_en: "Wang Tako",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860413,
            zip_code: 86110,
            name_th: "หาดยาย",
            name_en: "Hat Yai",
            amphure_id: 8604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8605,
        name_th: "ละแม",
        name_en: "Lamae",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860501,
            zip_code: 86170,
            name_th: "ละแม",
            name_en: "Lamae",
            amphure_id: 8605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860502,
            zip_code: 86170,
            name_th: "ทุ่งหลวง",
            name_en: "Thung Luang",
            amphure_id: 8605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860503,
            zip_code: 86170,
            name_th: "สวนแตง",
            name_en: "Suan Taeng",
            amphure_id: 8605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860504,
            zip_code: 86170,
            name_th: "ทุ่งคาวัด",
            name_en: "Thung Kha Wat",
            amphure_id: 8605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8606,
        name_th: "พะโต๊ะ",
        name_en: "Phato",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860601,
            zip_code: 86180,
            name_th: "พะโต๊ะ",
            name_en: "Phato",
            amphure_id: 8606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860602,
            zip_code: 86180,
            name_th: "ปากทรง",
            name_en: "Pak Song",
            amphure_id: 8606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860603,
            zip_code: 86180,
            name_th: "ปังหวาน",
            name_en: "Pang Wan",
            amphure_id: 8606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860604,
            zip_code: 86180,
            name_th: "พระรักษ์",
            name_en: "Phra Rak",
            amphure_id: 8606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8607,
        name_th: "สวี",
        name_en: "Sawi",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860701,
            zip_code: 86130,
            name_th: "นาโพธิ์",
            name_en: "Na Pho",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860702,
            zip_code: 86130,
            name_th: "สวี",
            name_en: "Sawi",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860703,
            zip_code: 86130,
            name_th: "ทุ่งระยะ",
            name_en: "Thung Raya",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860704,
            zip_code: 86130,
            name_th: "ท่าหิน",
            name_en: "Tha Hin",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860705,
            zip_code: 86130,
            name_th: "ปากแพรก",
            name_en: "Pak Phraek",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860706,
            zip_code: 86130,
            name_th: "ด่านสวี",
            name_en: "Dan Sawi",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860707,
            zip_code: 86130,
            name_th: "ครน",
            name_en: "Khron",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860708,
            zip_code: 86130,
            name_th: "วิสัยใต้",
            name_en: "Wisai Tai",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860709,
            zip_code: 86130,
            name_th: "นาสัก",
            name_en: "Na Sak",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860710,
            zip_code: 86130,
            name_th: "เขาทะลุ",
            name_en: "Khao Thalu",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860711,
            zip_code: 86130,
            name_th: "เขาค่าย",
            name_en: "Khao Khai",
            amphure_id: 8607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 8608,
        name_th: "ทุ่งตะโก",
        name_en: "Thung Tako",
        province_id: 69,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 860801,
            zip_code: 86220,
            name_th: "ปากตะโก",
            name_en: "Pak Tako",
            amphure_id: 8608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860802,
            zip_code: 86220,
            name_th: "ทุ่งตะไคร",
            name_en: "Thung Takhrai",
            amphure_id: 8608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860803,
            zip_code: 86220,
            name_th: "ตะโก",
            name_en: "Tako",
            amphure_id: 8608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 860804,
            zip_code: 86220,
            name_th: "ช่องไม้แก้ว",
            name_en: "Chong Mai Kaeo",
            amphure_id: 8608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 70,
    name_th: "สงขลา",
    name_en: "Songkhla",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9001,
        name_th: "เมืองสงขลา",
        name_en: "Mueang Songkhla",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900101,
            zip_code: 90000,
            name_th: "บ่อยาง",
            name_en: "Bo Yang",
            amphure_id: 9001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900102,
            zip_code: 90000,
            name_th: "เขารูปช้าง",
            name_en: "Khao Rup Chang",
            amphure_id: 9001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900103,
            zip_code: 90000,
            name_th: "เกาะแต้ว",
            name_en: "Ko Taeo",
            amphure_id: 9001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900104,
            zip_code: 90100,
            name_th: "พะวง",
            name_en: "Phawong",
            amphure_id: 9001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900105,
            zip_code: 90000,
            name_th: "ทุ่งหวัง",
            name_en: "Thung Wang",
            amphure_id: 9001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900106,
            zip_code: 90100,
            name_th: "เกาะยอ",
            name_en: "Ko Yo",
            amphure_id: 9001,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9002,
        name_th: "สทิงพระ",
        name_en: "Sathing Phra",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900201,
            zip_code: 90190,
            name_th: "จะทิ้งพระ",
            name_en: "Chathing Phra",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900202,
            zip_code: 90190,
            name_th: "กระดังงา",
            name_en: "Kradangnga",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900203,
            zip_code: 90190,
            name_th: "สนามชัย",
            name_en: "Sanam Chai",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900204,
            zip_code: 90190,
            name_th: "ดีหลวง",
            name_en: "Di Luang",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900205,
            zip_code: 90190,
            name_th: "ชุมพล",
            name_en: "Chumphon",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900206,
            zip_code: 90190,
            name_th: "คลองรี",
            name_en: "Khlong Ri",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900207,
            zip_code: 90190,
            name_th: "คูขุด",
            name_en: "Khu Khut",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900208,
            zip_code: 90190,
            name_th: "ท่าหิน",
            name_en: "Tha Hin",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900209,
            zip_code: 90190,
            name_th: "วัดจันทร์",
            name_en: "Wat Chan",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900210,
            zip_code: 90190,
            name_th: "บ่อแดง",
            name_en: "Bo Daeng",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900211,
            zip_code: 90190,
            name_th: "บ่อดาน",
            name_en: "Bor Dan",
            amphure_id: 9002,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9003,
        name_th: "จะนะ",
        name_en: "Chana",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900301,
            zip_code: 90130,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900302,
            zip_code: 90130,
            name_th: "ป่าชิง",
            name_en: "Pa Ching",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900303,
            zip_code: 90130,
            name_th: "สะพานไม้แก่น",
            name_en: "Saphan Mai Kaen",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900304,
            zip_code: 90130,
            name_th: "สะกอม",
            name_en: "Sakom",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900305,
            zip_code: 90130,
            name_th: "นาหว้า",
            name_en: "Na Wa",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900306,
            zip_code: 90130,
            name_th: "นาทับ",
            name_en: "Na Thap",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900307,
            zip_code: 90130,
            name_th: "น้ำขาว",
            name_en: "Nam Khao",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900308,
            zip_code: 90130,
            name_th: "ขุนตัดหวาย",
            name_en: "Khun Tat Wai",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900309,
            zip_code: 90130,
            name_th: "ท่าหมอไทร",
            name_en: "Tha Mo Sai",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900310,
            zip_code: 90130,
            name_th: "จะโหนง",
            name_en: "Chanong",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900311,
            zip_code: 90130,
            name_th: "คู",
            name_en: "Khu",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900312,
            zip_code: 90130,
            name_th: "แค",
            name_en: "Khae",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900313,
            zip_code: 90130,
            name_th: "คลองเปียะ",
            name_en: "Khlong Pia",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900314,
            zip_code: 90130,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 9003,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9004,
        name_th: "นาทวี",
        name_en: "Na Thawi",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900401,
            zip_code: 90160,
            name_th: "นาทวี",
            name_en: "Na Thawi",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900402,
            zip_code: 90160,
            name_th: "ฉาง",
            name_en: "Chang",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900403,
            zip_code: 90160,
            name_th: "นาหมอศรี",
            name_en: "Na Mo Si",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900404,
            zip_code: 90160,
            name_th: "คลองทราย",
            name_en: "Khlong Sai",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900405,
            zip_code: 90160,
            name_th: "ปลักหนู",
            name_en: "Plak Nu",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900406,
            zip_code: 90160,
            name_th: "ท่าประดู่",
            name_en: "Tha Pradu",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900407,
            zip_code: 90160,
            name_th: "สะท้อน",
            name_en: "Sathon",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900408,
            zip_code: 90160,
            name_th: "ทับช้าง",
            name_en: "Thap Chang",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900409,
            zip_code: 90160,
            name_th: "ประกอบ",
            name_en: "Prakop",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900410,
            zip_code: 90160,
            name_th: "คลองกวาง",
            name_en: "Khlong Kwang",
            amphure_id: 9004,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9005,
        name_th: "เทพา",
        name_en: "Thepha",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900501,
            zip_code: 90150,
            name_th: "เทพา",
            name_en: "Thepha",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900502,
            zip_code: 90150,
            name_th: "ปากบาง",
            name_en: "Pak Bang",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900503,
            zip_code: 90150,
            name_th: "เกาะสะบ้า",
            name_en: "Ko Saba",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900504,
            zip_code: 90260,
            name_th: "ลำไพล",
            name_en: "Lam Phlai",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900505,
            zip_code: 90260,
            name_th: "ท่าม่วง",
            name_en: "Tha Muang",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900506,
            zip_code: 90260,
            name_th: "วังใหญ่",
            name_en: "Wang Yai",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900507,
            zip_code: 90150,
            name_th: "สะกอม",
            name_en: "Sakom",
            amphure_id: 9005,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9006,
        name_th: "สะบ้าย้อย",
        name_en: "Saba Yoi",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900601,
            zip_code: 90210,
            name_th: "สะบ้าย้อย",
            name_en: "Saba Yoi",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900602,
            zip_code: 90210,
            name_th: "ทุ่งพอ",
            name_en: "Thung Pho",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900603,
            zip_code: 90210,
            name_th: "เปียน",
            name_en: "Pian",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900604,
            zip_code: 90210,
            name_th: "บ้านโหนด",
            name_en: "Ban Not",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900605,
            zip_code: 90210,
            name_th: "จะแหน",
            name_en: "Chanae",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900606,
            zip_code: 90210,
            name_th: "คูหา",
            name_en: "Khuha",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900607,
            zip_code: 90210,
            name_th: "เขาแดง",
            name_en: "Khao Daeng",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900608,
            zip_code: 90210,
            name_th: "บาโหย",
            name_en: "Ba Hoi",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900609,
            zip_code: 90210,
            name_th: "ธารคีรี",
            name_en: "Than Khiri",
            amphure_id: 9006,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9007,
        name_th: "ระโนด",
        name_en: "Ranot",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900701,
            zip_code: 90140,
            name_th: "ระโนด",
            name_en: "Ranot",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900702,
            zip_code: 90140,
            name_th: "คลองแดน",
            name_en: "Khlong Daen",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900703,
            zip_code: 90140,
            name_th: "ตะเครียะ",
            name_en: "Takhria",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900704,
            zip_code: 90140,
            name_th: "ท่าบอน",
            name_en: "Tha Bon",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900705,
            zip_code: 90140,
            name_th: "บ้านใหม่",
            name_en: "Ban Mai",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900706,
            zip_code: 90140,
            name_th: "บ่อตรุ",
            name_en: "Bo Tru",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900707,
            zip_code: 90140,
            name_th: "ปากแตระ",
            name_en: "Pak Trae",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900708,
            zip_code: 90140,
            name_th: "พังยาง",
            name_en: "Phang Yang",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900709,
            zip_code: 90140,
            name_th: "ระวะ",
            name_en: "Rawa",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900710,
            zip_code: 90140,
            name_th: "วัดสน",
            name_en: "Wat Son",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900711,
            zip_code: 90140,
            name_th: "บ้านขาว",
            name_en: "Ban Khao",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900712,
            zip_code: 90140,
            name_th: "แดนสงวน",
            name_en: "Daen Sa-nguan",
            amphure_id: 9007,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9008,
        name_th: "กระแสสินธุ์",
        name_en: "Krasae Sin",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900801,
            zip_code: 90270,
            name_th: "เกาะใหญ่",
            name_en: "Ko Yai",
            amphure_id: 9008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900802,
            zip_code: 90270,
            name_th: "โรง",
            name_en: "Rong",
            amphure_id: 9008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900803,
            zip_code: 90270,
            name_th: "เชิงแส",
            name_en: "Choeng Sae",
            amphure_id: 9008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900804,
            zip_code: 90270,
            name_th: "กระแสสินธุ์",
            name_en: "Krasae Sin",
            amphure_id: 9008,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9009,
        name_th: "รัตภูมิ",
        name_en: "Rattaphum",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 900901,
            zip_code: 90180,
            name_th: "กำแพงเพชร",
            name_en: "Kamphaeng Phet",
            amphure_id: 9009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900902,
            zip_code: 90180,
            name_th: "ท่าชะมวง",
            name_en: "Tha Chamuang",
            amphure_id: 9009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900903,
            zip_code: 90180,
            name_th: "คูหาใต้",
            name_en: "Khuha Tai",
            amphure_id: 9009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900904,
            zip_code: 90180,
            name_th: "ควนรู",
            name_en: "Khuan Ru",
            amphure_id: 9009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 900909,
            zip_code: 90180,
            name_th: "เขาพระ",
            name_en: "Khao Phra",
            amphure_id: 9009,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9010,
        name_th: "สะเดา",
        name_en: "Sadao",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901001,
            zip_code: 90120,
            name_th: "สะเดา",
            name_en: "Sadao",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901002,
            zip_code: 90120,
            name_th: "ปริก",
            name_en: "Prik",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901003,
            zip_code: 90170,
            name_th: "พังลา",
            name_en: "Phang La",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901004,
            zip_code: 90120,
            name_th: "สำนักแต้ว",
            name_en: "Samnak Taeo",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901005,
            zip_code: 90240,
            name_th: "ทุ่งหมอ",
            name_en: "Thung Mo",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901006,
            zip_code: 90170,
            name_th: "ท่าโพธิ์",
            name_en: "Tha Pho",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901007,
            zip_code: 90240,
            name_th: "ปาดังเบซาร์",
            name_en: "Padang Besa",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901008,
            zip_code: 90320,
            name_th: "สำนักขาม",
            name_en: "Samnak Kham",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901009,
            zip_code: 90170,
            name_th: "เขามีเกียรติ",
            name_en: "Khao Mi Kiat",
            amphure_id: 9010,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9011,
        name_th: "หาดใหญ่",
        name_en: "Hat Yai",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901101,
            zip_code: 90110,
            name_th: "หาดใหญ่",
            name_en: "Hat Yai",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901102,
            zip_code: 90110,
            name_th: "ควนลัง",
            name_en: "Khuan Lang",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901103,
            zip_code: 90110,
            name_th: "คูเต่า",
            name_en: "Khu Tao",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901104,
            zip_code: 90110,
            name_th: "คอหงส์",
            name_en: "Kho Hong",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901105,
            zip_code: 90110,
            name_th: "คลองแห",
            name_en: "Khlong Hae",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901107,
            zip_code: 90110,
            name_th: "คลองอู่ตะเภา",
            name_en: "Khlong U Taphao",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901108,
            zip_code: 90110,
            name_th: "ฉลุง",
            name_en: "Chalung",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901111,
            zip_code: 90110,
            name_th: "ทุ่งใหญ่",
            name_en: "Thung Yai",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901112,
            zip_code: 90110,
            name_th: "ทุ่งตำเสา",
            name_en: "Thung Tamsao",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901113,
            zip_code: 90110,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901114,
            zip_code: 90110,
            name_th: "น้ำน้อย",
            name_en: "Nam Noi",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901116,
            zip_code: 90250,
            name_th: "บ้านพรุ",
            name_en: "Ban Phru",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901118,
            zip_code: 90230,
            name_th: "พะตง",
            name_en: "Phatong",
            amphure_id: 9011,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9012,
        name_th: "นาหม่อม",
        name_en: "Na Mom",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901201,
            zip_code: 90310,
            name_th: "นาหม่อม",
            name_en: "Na Mom",
            amphure_id: 9012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901202,
            zip_code: 90310,
            name_th: "พิจิตร",
            name_en: "Phichit",
            amphure_id: 9012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901203,
            zip_code: 90310,
            name_th: "ทุ่งขมิ้น",
            name_en: "Thung Khamin",
            amphure_id: 9012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901204,
            zip_code: 90310,
            name_th: "คลองหรัง",
            name_en: "Khlong Rhang",
            amphure_id: 9012,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9013,
        name_th: "ควนเนียง",
        name_en: "Khuan Niang",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901301,
            zip_code: 90220,
            name_th: "รัตภูมิ",
            name_en: "Rattaphum",
            amphure_id: 9013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901302,
            zip_code: 90220,
            name_th: "ควนโส",
            name_en: "Khuan So",
            amphure_id: 9013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901303,
            zip_code: 90220,
            name_th: "ห้วยลึก",
            name_en: "Huai Luek",
            amphure_id: 9013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901304,
            zip_code: 90220,
            name_th: "บางเหรียง",
            name_en: "Bang Rieang",
            amphure_id: 9013,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9014,
        name_th: "บางกล่ำ",
        name_en: "Bang Klam",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901401,
            zip_code: 90110,
            name_th: "บางกล่ำ",
            name_en: "Bang Klam",
            amphure_id: 9014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901402,
            zip_code: 90110,
            name_th: "ท่าช้าง",
            name_en: "Tha Chang",
            amphure_id: 9014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901403,
            zip_code: 90110,
            name_th: "แม่ทอม",
            name_en: "Mae Thom",
            amphure_id: 9014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901404,
            zip_code: 90110,
            name_th: "บ้านหาร",
            name_en: "Ban Han",
            amphure_id: 9014,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9015,
        name_th: "สิงหนคร",
        name_en: "Singhanakhon",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901501,
            zip_code: 90280,
            name_th: "ชิงโค",
            name_en: "Ching Kho",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901502,
            zip_code: 90280,
            name_th: "สทิงหม้อ",
            name_en: "Sathing Mo",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901503,
            zip_code: 90280,
            name_th: "ทำนบ",
            name_en: "Thamnop",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901504,
            zip_code: 90330,
            name_th: "รำแดง",
            name_en: "Ram Daeng",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901505,
            zip_code: 90330,
            name_th: "วัดขนุน",
            name_en: "Wat Khanun",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901506,
            zip_code: 90330,
            name_th: "ชะแล้",
            name_en: "Chalae",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901507,
            zip_code: 90330,
            name_th: "ปากรอ",
            name_en: "Pak Ro",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901508,
            zip_code: 90330,
            name_th: "ป่าขาด",
            name_en: "Pa Khat",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901509,
            zip_code: 90280,
            name_th: "หัวเขา",
            name_en: "Hua Khao",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901510,
            zip_code: 90330,
            name_th: "บางเขียด",
            name_en: "Bang Khiat",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901511,
            zip_code: 90330,
            name_th: "ม่วงงาม",
            name_en: "Muang Ngam",
            amphure_id: 9015,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9016,
        name_th: "คลองหอยโข่ง",
        name_en: "Khlong Hoi Khong",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 901601,
            zip_code: 90230,
            name_th: "คลองหอยโข่ง",
            name_en: "Khlong Hoi Khong",
            amphure_id: 9016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901602,
            zip_code: 90230,
            name_th: "ทุ่งลาน",
            name_en: "Thung Lan",
            amphure_id: 9016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901603,
            zip_code: 90230,
            name_th: "โคกม่วง",
            name_en: "Khok Muang",
            amphure_id: 9016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 901604,
            zip_code: 90115,
            name_th: "คลองหลา",
            name_en: "Khlong La",
            amphure_id: 9016,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9077,
        name_th: "ท้องถิ่นเทศบาลตำบลสำนักขาม",
        name_en: "Sum Nung Kam",
        province_id: 70,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [],
      },
    ],
  },
  {
    id: 71,
    name_th: "สตูล",
    name_en: "Satun",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9101,
        name_th: "เมืองสตูล",
        name_en: "Mueang Satun",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910101,
            zip_code: 91000,
            name_th: "พิมาน",
            name_en: "Phiman",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910102,
            zip_code: 91000,
            name_th: "คลองขุด",
            name_en: "Khlong Khut",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910103,
            zip_code: 91000,
            name_th: "ควนขัน",
            name_en: "Khuan Khan",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910104,
            zip_code: 91140,
            name_th: "บ้านควน",
            name_en: "Ban Khuan",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910105,
            zip_code: 91140,
            name_th: "ฉลุง",
            name_en: "Chalung",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910106,
            zip_code: 91000,
            name_th: "เกาะสาหร่าย",
            name_en: "Ko Sarai",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910107,
            zip_code: 91000,
            name_th: "ตันหยงโป",
            name_en: "Tanyong Po",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910108,
            zip_code: 91000,
            name_th: "เจ๊ะบิลัง",
            name_en: "Che Bilang",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910109,
            zip_code: 91000,
            name_th: "ตำมะลัง",
            name_en: "Tam Malang",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910110,
            zip_code: 91000,
            name_th: "ปูยู",
            name_en: "Puyu",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910111,
            zip_code: 91140,
            name_th: "ควนโพธิ์",
            name_en: "Khuan Pho",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910112,
            zip_code: 91140,
            name_th: "เกตรี",
            name_en: "Ketri",
            amphure_id: 9101,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9102,
        name_th: "ควนโดน",
        name_en: "Khuan Don",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910201,
            zip_code: 91160,
            name_th: "ควนโดน",
            name_en: "Khuan Don",
            amphure_id: 9102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910202,
            zip_code: 91160,
            name_th: "ควนสตอ",
            name_en: "Khuan Sato",
            amphure_id: 9102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910203,
            zip_code: 91160,
            name_th: "ย่านซื่อ",
            name_en: "Yan Sue",
            amphure_id: 9102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910204,
            zip_code: 91160,
            name_th: "วังประจัน",
            name_en: "Wang Prachan",
            amphure_id: 9102,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9103,
        name_th: "ควนกาหลง",
        name_en: "Khuan Kalong",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910301,
            zip_code: 91130,
            name_th: "ทุ่งนุ้ย",
            name_en: "Thung Nui",
            amphure_id: 9103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910302,
            zip_code: 91130,
            name_th: "ควนกาหลง",
            name_en: "Khuan Kalong",
            amphure_id: 9103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910303,
            zip_code: 91130,
            name_th: "อุใดเจริญ",
            name_en: "Udai Charoen",
            amphure_id: 9103,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9104,
        name_th: "ท่าแพ",
        name_en: "Tha Phae",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910401,
            zip_code: 91150,
            name_th: "ท่าแพ",
            name_en: "Tha Phae",
            amphure_id: 9104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910402,
            zip_code: 91150,
            name_th: "แป-ระ",
            name_en: "Paera",
            amphure_id: 9104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910403,
            zip_code: 91150,
            name_th: "สาคร",
            name_en: "Sakhon",
            amphure_id: 9104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910404,
            zip_code: 91150,
            name_th: "ท่าเรือ",
            name_en: "Tha Rua",
            amphure_id: 9104,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9105,
        name_th: "ละงู",
        name_en: "La-ngu",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910501,
            zip_code: 91110,
            name_th: "กำแพง",
            name_en: "Kamphaeng",
            amphure_id: 9105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910502,
            zip_code: 91110,
            name_th: "ละงู",
            name_en: "La-ngu",
            amphure_id: 9105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910503,
            zip_code: 91110,
            name_th: "เขาขาว",
            name_en: "Khao Khao",
            amphure_id: 9105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910504,
            zip_code: 91110,
            name_th: "ปากน้ำ",
            name_en: "Pak Nam",
            amphure_id: 9105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910505,
            zip_code: 91110,
            name_th: "น้ำผุด",
            name_en: "Nam Phut",
            amphure_id: 9105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910506,
            zip_code: 91110,
            name_th: "แหลมสน",
            name_en: "Laem Son",
            amphure_id: 9105,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9106,
        name_th: "ทุ่งหว้า",
        name_en: "Thung Wa",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910601,
            zip_code: 91120,
            name_th: "ทุ่งหว้า",
            name_en: "Thung Wa",
            amphure_id: 9106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910602,
            zip_code: 91120,
            name_th: "นาทอน",
            name_en: "Na Thon",
            amphure_id: 9106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910603,
            zip_code: 91120,
            name_th: "ขอนคลาน",
            name_en: "Khon Khlan",
            amphure_id: 9106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910604,
            zip_code: 91120,
            name_th: "ทุ่งบุหลัง",
            name_en: "Thung Bulang",
            amphure_id: 9106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910605,
            zip_code: 91120,
            name_th: "ป่าแก่บ่อหิน",
            name_en: "Pa Kae Bo Hin",
            amphure_id: 9106,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9107,
        name_th: "มะนัง",
        name_en: "Manang",
        province_id: 71,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 910701,
            zip_code: 91130,
            name_th: "ปาล์มพัฒนา",
            name_en: "Palm Phatthana",
            amphure_id: 9107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 910702,
            zip_code: 91130,
            name_th: "นิคมพัฒนา",
            name_en: "Nikhom Phatthana",
            amphure_id: 9107,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 72,
    name_th: "ตรัง",
    name_en: "Trang",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9201,
        name_th: "เมืองตรัง",
        name_en: "Mueang Trang",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920101,
            zip_code: 92000,
            name_th: "ทับเที่ยง",
            name_en: "Thap Thiang",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920104,
            zip_code: 92000,
            name_th: "นาพละ",
            name_en: "Na Phala",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920105,
            zip_code: 92000,
            name_th: "บ้านควน",
            name_en: "Ban Khuan",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920106,
            zip_code: 92000,
            name_th: "นาบินหลา",
            name_en: "Na Bin La",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920107,
            zip_code: 92000,
            name_th: "ควนปริง",
            name_en: "Khuan Pring",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920108,
            zip_code: 92170,
            name_th: "นาโยงใต้",
            name_en: "Na Yong Tai",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920109,
            zip_code: 92000,
            name_th: "บางรัก",
            name_en: "Bang Rak",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920110,
            zip_code: 92000,
            name_th: "โคกหล่อ",
            name_en: "Khok Lo",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920113,
            zip_code: 92000,
            name_th: "นาโต๊ะหมิง",
            name_en: "Na To Ming",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920114,
            zip_code: 92000,
            name_th: "หนองตรุด",
            name_en: "Nong Trut",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920115,
            zip_code: 92000,
            name_th: "น้ำผุด",
            name_en: "Nam Phut",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920117,
            zip_code: 92000,
            name_th: "นาตาล่วง",
            name_en: "Na Ta Luang",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920118,
            zip_code: 92000,
            name_th: "บ้านโพธิ์",
            name_en: "Ban Pho",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920119,
            zip_code: 92190,
            name_th: "นาท่ามเหนือ",
            name_en: "Na Tham Nuea",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920120,
            zip_code: 92190,
            name_th: "นาท่ามใต้",
            name_en: "Na Tham Tai",
            amphure_id: 9201,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9202,
        name_th: "กันตัง",
        name_en: "Kantang",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920201,
            zip_code: 92110,
            name_th: "กันตัง",
            name_en: "Kantang",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920202,
            zip_code: 92110,
            name_th: "ควนธานี",
            name_en: "Khuan Thani",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920203,
            zip_code: 92110,
            name_th: "บางหมาก",
            name_en: "Bang Mak",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920204,
            zip_code: 92110,
            name_th: "บางเป้า",
            name_en: "Bang Pao",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920205,
            zip_code: 92110,
            name_th: "วังวน",
            name_en: "Wang Won",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920206,
            zip_code: 92110,
            name_th: "กันตังใต้",
            name_en: "Kantang Tai",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920207,
            zip_code: 92110,
            name_th: "โคกยาง",
            name_en: "Khok Yang",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920208,
            zip_code: 92110,
            name_th: "คลองลุ",
            name_en: "Khlong Lu",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920209,
            zip_code: 92110,
            name_th: "ย่านซื่อ",
            name_en: "Yan Sue",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920210,
            zip_code: 92110,
            name_th: "บ่อน้ำร้อน",
            name_en: "Bo Nam Ron",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920211,
            zip_code: 92110,
            name_th: "บางสัก",
            name_en: "Bang Sak",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920212,
            zip_code: 92110,
            name_th: "นาเกลือ",
            name_en: "Na Kluea",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920213,
            zip_code: 92110,
            name_th: "เกาะลิบง",
            name_en: "Ko Libong",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920214,
            zip_code: 92110,
            name_th: "คลองชีล้อม",
            name_en: "Khlong Chi Lom",
            amphure_id: 9202,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9203,
        name_th: "ย่านตาขาว",
        name_en: "Yan Ta Khao",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920301,
            zip_code: 92140,
            name_th: "ย่านตาขาว",
            name_en: "Yan Ta Khao",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920302,
            zip_code: 92140,
            name_th: "หนองบ่อ",
            name_en: "Nong Bo",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920303,
            zip_code: 92140,
            name_th: "นาชุมเห็ด",
            name_en: "Na Chum Het",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920304,
            zip_code: 92140,
            name_th: "ในควน",
            name_en: "Nai Khuan",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920305,
            zip_code: 92140,
            name_th: "โพรงจระเข้",
            name_en: "Phrong Chorakhe",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920306,
            zip_code: 92140,
            name_th: "ทุ่งกระบือ",
            name_en: "Thung Krabue",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920307,
            zip_code: 92140,
            name_th: "ทุ่งค่าย",
            name_en: "Thung Khai",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920308,
            zip_code: 92140,
            name_th: "เกาะเปียะ",
            name_en: "Ko Pia",
            amphure_id: 9203,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9204,
        name_th: "ปะเหลียน",
        name_en: "Palian",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920401,
            zip_code: 92120,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920402,
            zip_code: 92180,
            name_th: "ทุ่งยาว",
            name_en: "Thung Yao",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920403,
            zip_code: 92180,
            name_th: "ปะเหลียน",
            name_en: "Palian",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920404,
            zip_code: 92140,
            name_th: "บางด้วน",
            name_en: "Bang Duan",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920407,
            zip_code: 92140,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920409,
            zip_code: 92120,
            name_th: "สุโสะ",
            name_en: "Suso",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920410,
            zip_code: 92180,
            name_th: "ลิพัง",
            name_en: "Liphang",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920411,
            zip_code: 92120,
            name_th: "เกาะสุกร",
            name_en: "Ko Sukon",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920412,
            zip_code: 92140,
            name_th: "ท่าพญา",
            name_en: "Tha Phaya",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920413,
            zip_code: 92180,
            name_th: "แหลมสอม",
            name_en: "Laem Som",
            amphure_id: 9204,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9205,
        name_th: "สิเกา",
        name_en: "Sikao",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920501,
            zip_code: 92150,
            name_th: "บ่อหิน",
            name_en: "Bo Hin",
            amphure_id: 9205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920502,
            zip_code: 92150,
            name_th: "เขาไม้แก้ว",
            name_en: "Khao Mai Kaeo",
            amphure_id: 9205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920503,
            zip_code: 92150,
            name_th: "กะลาเส",
            name_en: "Kalase",
            amphure_id: 9205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920504,
            zip_code: 92150,
            name_th: "ไม้ฝาด",
            name_en: "Mai Fat",
            amphure_id: 9205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920505,
            zip_code: 92000,
            name_th: "นาเมืองเพชร",
            name_en: "Na Mueang Phet",
            amphure_id: 9205,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9206,
        name_th: "ห้วยยอด",
        name_en: "Huai Yot",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920601,
            zip_code: 92130,
            name_th: "ห้วยยอด",
            name_en: "Huai Yot",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920602,
            zip_code: 92130,
            name_th: "หนองช้างแล่น",
            name_en: "Nong Chang Laen",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920605,
            zip_code: 92210,
            name_th: "บางดี",
            name_en: "Bang Di",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920606,
            zip_code: 92210,
            name_th: "บางกุ้ง",
            name_en: "Bang Kung",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920607,
            zip_code: 92130,
            name_th: "เขากอบ",
            name_en: "Khao Kop",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920608,
            zip_code: 92130,
            name_th: "เขาขาว",
            name_en: "Khao Khao",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920609,
            zip_code: 92130,
            name_th: "เขาปูน",
            name_en: "Khao Pun",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920610,
            zip_code: 92190,
            name_th: "ปากแจ่ม",
            name_en: "Pak Chaem",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920611,
            zip_code: 92130,
            name_th: "ปากคม",
            name_en: "Pak Khom",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920614,
            zip_code: 92130,
            name_th: "ท่างิ้ว",
            name_en: "Tha Ngio",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920615,
            zip_code: 92190,
            name_th: "ลำภูรา",
            name_en: "Lamphu Ra",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920616,
            zip_code: 92210,
            name_th: "นาวง",
            name_en: "Na Wong",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920617,
            zip_code: 92130,
            name_th: "ห้วยนาง",
            name_en: "Huai Nang",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920619,
            zip_code: 92130,
            name_th: "ในเตา",
            name_en: "Nai Tao",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920620,
            zip_code: 92130,
            name_th: "ทุ่งต่อ",
            name_en: "Thung To",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920621,
            zip_code: 92210,
            name_th: "วังคีรี",
            name_en: "Wang Khiri",
            amphure_id: 9206,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9207,
        name_th: "วังวิเศษ",
        name_en: "Wang Wiset",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920701,
            zip_code: 92220,
            name_th: "เขาวิเศษ",
            name_en: "Khao Wiset",
            amphure_id: 9207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920702,
            zip_code: 92220,
            name_th: "วังมะปราง",
            name_en: "Wang Maprang",
            amphure_id: 9207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920703,
            zip_code: 92220,
            name_th: "อ่าวตง",
            name_en: "Ao Tong",
            amphure_id: 9207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920704,
            zip_code: 92000,
            name_th: "ท่าสะบ้า",
            name_en: "Tha Saba",
            amphure_id: 9207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920705,
            zip_code: 92220,
            name_th: "วังมะปรางเหนือ",
            name_en: "Wang Maprang Nuea",
            amphure_id: 9207,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9208,
        name_th: "นาโยง",
        name_en: "Na Yong",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920801,
            zip_code: 92170,
            name_th: "นาโยงเหนือ",
            name_en: "Na Yong Nuea",
            amphure_id: 9208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920802,
            zip_code: 92170,
            name_th: "ช่อง",
            name_en: "Chong",
            amphure_id: 9208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920803,
            zip_code: 92170,
            name_th: "ละมอ",
            name_en: "Lamo",
            amphure_id: 9208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920804,
            zip_code: 92170,
            name_th: "โคกสะบ้า",
            name_en: "Khok Saba",
            amphure_id: 9208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920805,
            zip_code: 92170,
            name_th: "นาหมื่นศรี",
            name_en: "Na Muen Si",
            amphure_id: 9208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920806,
            zip_code: 92170,
            name_th: "นาข้าวเสีย",
            name_en: "Na Khao Sia",
            amphure_id: 9208,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9209,
        name_th: "รัษฎา",
        name_en: "Ratsada",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 920901,
            zip_code: 92160,
            name_th: "ควนเมา",
            name_en: "Khuan Mao",
            amphure_id: 9209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920902,
            zip_code: 92160,
            name_th: "คลองปาง",
            name_en: "Khlong Pang",
            amphure_id: 9209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920903,
            zip_code: 92160,
            name_th: "หนองบัว",
            name_en: "Nong Bua",
            amphure_id: 9209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920904,
            zip_code: 92130,
            name_th: "หนองปรือ",
            name_en: "Nong Prue",
            amphure_id: 9209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 920905,
            zip_code: 92160,
            name_th: "เขาไพร",
            name_en: "Khao Phrai",
            amphure_id: 9209,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9210,
        name_th: "หาดสำราญ",
        name_en: "Hat Samran",
        province_id: 72,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 921001,
            zip_code: 92120,
            name_th: "หาดสำราญ",
            name_en: "Hat Samran",
            amphure_id: 9210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 921002,
            zip_code: 92120,
            name_th: "บ้าหวี",
            name_en: "Ba Wi",
            amphure_id: 9210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 921003,
            zip_code: 92120,
            name_th: "ตะเสะ",
            name_en: "Ta Se",
            amphure_id: 9210,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 73,
    name_th: "พัทลุง",
    name_en: "Phatthalung",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9301,
        name_th: "เมืองพัทลุง",
        name_en: "Mueang Phatthalung",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930101,
            zip_code: 93000,
            name_th: "คูหาสวรรค์",
            name_en: "Khuha Sawan",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930103,
            zip_code: 93000,
            name_th: "เขาเจียก",
            name_en: "Khao Chiak",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930104,
            zip_code: 93000,
            name_th: "ท่ามิหรำ",
            name_en: "Tha Miram",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930105,
            zip_code: 93000,
            name_th: "โคกชะงาย",
            name_en: "Khok Cha-ngai",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930106,
            zip_code: 93000,
            name_th: "นาท่อม",
            name_en: "Na Thom",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930107,
            zip_code: 93000,
            name_th: "ปรางหมู่",
            name_en: "Prang Mu",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930108,
            zip_code: 93000,
            name_th: "ท่าแค",
            name_en: "Tha Khae",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930109,
            zip_code: 93000,
            name_th: "ลำปำ",
            name_en: "Lampam",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930110,
            zip_code: 93000,
            name_th: "ตำนาน",
            name_en: "Tamnan",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930111,
            zip_code: 93000,
            name_th: "ควนมะพร้าว",
            name_en: "Khuan Maphrao",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930112,
            zip_code: 93000,
            name_th: "ร่มเมือง",
            name_en: "Rom Mueang",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930113,
            zip_code: 93000,
            name_th: "ชัยบุรี",
            name_en: "Chai Buri",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930114,
            zip_code: 93000,
            name_th: "นาโหนด",
            name_en: "Na Not)",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930115,
            zip_code: 93000,
            name_th: "พญาขัน",
            name_en: "Phaya Khan",
            amphure_id: 9301,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9302,
        name_th: "กงหรา",
        name_en: "Kong Ra",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930201,
            zip_code: 93180,
            name_th: "กงหรา",
            name_en: "Kong Ra",
            amphure_id: 9302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930202,
            zip_code: 93000,
            name_th: "ชะรัด",
            name_en: "Charat",
            amphure_id: 9302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930203,
            zip_code: 93180,
            name_th: "คลองเฉลิม",
            name_en: "Khlong Chaloem",
            amphure_id: 9302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930204,
            zip_code: 93180,
            name_th: "คลองทรายขาว",
            name_en: "Khlong Sai Khao",
            amphure_id: 9302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930205,
            zip_code: 93000,
            name_th: "สมหวัง",
            name_en: "Som Wang",
            amphure_id: 9302,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9303,
        name_th: "เขาชัยสน",
        name_en: "Khao Chaison",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930301,
            zip_code: 93130,
            name_th: "เขาชัยสน",
            name_en: "Khao Chaison",
            amphure_id: 9303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930302,
            zip_code: 93130,
            name_th: "ควนขนุน",
            name_en: "Khuan Khanun",
            amphure_id: 9303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930305,
            zip_code: 93130,
            name_th: "จองถนน",
            name_en: "Chong Thanon",
            amphure_id: 9303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930306,
            zip_code: 93130,
            name_th: "หานโพธิ์",
            name_en: "Han Pho",
            amphure_id: 9303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930307,
            zip_code: 93130,
            name_th: "โคกม่วง",
            name_en: "Khok Muang",
            amphure_id: 9303,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9304,
        name_th: "ตะโหมด",
        name_en: "Tamot",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930401,
            zip_code: 93160,
            name_th: "แม่ขรี",
            name_en: "Mae Khari",
            amphure_id: 9304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930402,
            zip_code: 93160,
            name_th: "ตะโหมด",
            name_en: "Tamod",
            amphure_id: 9304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930403,
            zip_code: 93160,
            name_th: "คลองใหญ่",
            name_en: "Khlong Yai",
            amphure_id: 9304,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9305,
        name_th: "ควนขนุน",
        name_en: "Khuan Khanun",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930501,
            zip_code: 93110,
            name_th: "ควนขนุน",
            name_en: "Khuan Khanun",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930502,
            zip_code: 93150,
            name_th: "ทะเลน้อย",
            name_en: "Thale Noi",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930504,
            zip_code: 93110,
            name_th: "นาขยาด",
            name_en: "Na Khayat",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930505,
            zip_code: 93110,
            name_th: "พนมวังก์",
            name_en: "Phanom Wang",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930506,
            zip_code: 93110,
            name_th: "แหลมโตนด",
            name_en: "Laem Tanot",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930508,
            zip_code: 93110,
            name_th: "ปันแต",
            name_en: "Pan Tae",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930509,
            zip_code: 93110,
            name_th: "โตนดด้วน",
            name_en: "Tanot Duan",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930510,
            zip_code: 93110,
            name_th: "ดอนทราย",
            name_en: "Don Sai",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930511,
            zip_code: 93150,
            name_th: "มะกอกเหนือ",
            name_en: "Makok Nuea",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930512,
            zip_code: 93150,
            name_th: "พนางตุง",
            name_en: "Phanang Tung",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930513,
            zip_code: 93110,
            name_th: "ชะมวง",
            name_en: "Chamuang",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930516,
            zip_code: 93110,
            name_th: "แพรกหา",
            name_en: "Phraek Ha",
            amphure_id: 9305,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9306,
        name_th: "ปากพะยูน",
        name_en: "Pak Phayun",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930601,
            zip_code: 93120,
            name_th: "ปากพะยูน",
            name_en: "Pak Phayun",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930602,
            zip_code: 93120,
            name_th: "ดอนประดู่",
            name_en: "Don Pradu",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930603,
            zip_code: 93120,
            name_th: "เกาะนางคำ",
            name_en: "Ko Nang Kham",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930604,
            zip_code: 93120,
            name_th: "เกาะหมาก",
            name_en: "Ko Mak",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930605,
            zip_code: 93120,
            name_th: "ฝาละมี",
            name_en: "Falami",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930606,
            zip_code: 93120,
            name_th: "หารเทา",
            name_en: "Han Thao",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930607,
            zip_code: 93120,
            name_th: "ดอนทราย",
            name_en: "Don Sai",
            amphure_id: 9306,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9307,
        name_th: "ศรีบรรพต",
        name_en: "Si Banphot",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930701,
            zip_code: 93190,
            name_th: "เขาย่า",
            name_en: "Khao Ya",
            amphure_id: 9307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930702,
            zip_code: 93190,
            name_th: "เขาปู่",
            name_en: "Khao Pu",
            amphure_id: 9307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930703,
            zip_code: 93190,
            name_th: "ตะแพน",
            name_en: "Taphaen",
            amphure_id: 9307,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9308,
        name_th: "ป่าบอน",
        name_en: "Pa Bon",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930801,
            zip_code: 93170,
            name_th: "ป่าบอน",
            name_en: "Pa Bon",
            amphure_id: 9308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930802,
            zip_code: 93170,
            name_th: "โคกทราย",
            name_en: "Khok Sai",
            amphure_id: 9308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930803,
            zip_code: 93170,
            name_th: "หนองธง",
            name_en: "Nong Thong",
            amphure_id: 9308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930804,
            zip_code: 93170,
            name_th: "ทุ่งนารี",
            name_en: "Thung Nari",
            amphure_id: 9308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930806,
            zip_code: 93170,
            name_th: "วังใหม่",
            name_en: "Wang Mai",
            amphure_id: 9308,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9309,
        name_th: "บางแก้ว",
        name_en: "Bang Kaeo",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 930901,
            zip_code: 93140,
            name_th: "ท่ามะเดื่อ",
            name_en: "Tha Maduea",
            amphure_id: 9309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930902,
            zip_code: 93140,
            name_th: "นาปะขอ",
            name_en: "Na Pakho",
            amphure_id: 9309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 930903,
            zip_code: 93140,
            name_th: "โคกสัก",
            name_en: "Khok Sak",
            amphure_id: 9309,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9310,
        name_th: "ป่าพะยอม",
        name_en: "Pa Phayom",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 931001,
            zip_code: 93110,
            name_th: "ป่าพะยอม",
            name_en: "Pa Phayom",
            amphure_id: 9310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 931002,
            zip_code: 93110,
            name_th: "ลานข่อย",
            name_en: "Lan Khoi",
            amphure_id: 9310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 931003,
            zip_code: 93110,
            name_th: "เกาะเต่า",
            name_en: "Ko Tao",
            amphure_id: 9310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 931004,
            zip_code: 93110,
            name_th: "บ้านพร้าว",
            name_en: "Ban Phrao",
            amphure_id: 9310,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9311,
        name_th: "ศรีนครินทร์",
        name_en: "Srinagarindra",
        province_id: 73,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 931101,
            zip_code: 93000,
            name_th: "ชุมพล",
            name_en: "Chumphon",
            amphure_id: 9311,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 931102,
            zip_code: 93000,
            name_th: "บ้านนา",
            name_en: "Ban Na",
            amphure_id: 9311,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 931103,
            zip_code: 93000,
            name_th: "อ่างทอง",
            name_en: "Ang Thong",
            amphure_id: 9311,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 931104,
            zip_code: 93000,
            name_th: "ลำสินธุ์",
            name_en: "Lam Sin",
            amphure_id: 9311,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 74,
    name_th: "ปัตตานี",
    name_en: "Pattani",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9401,
        name_th: "เมืองปัตตานี",
        name_en: "Mueang Pattani",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940101,
            zip_code: 94000,
            name_th: "สะบารัง",
            name_en: "Sabarang",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940102,
            zip_code: 94000,
            name_th: "อาเนาะรู",
            name_en: "Ano Ru",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940103,
            zip_code: 94000,
            name_th: "จะบังติกอ",
            name_en: "Chabang Tiko",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940104,
            zip_code: 94000,
            name_th: "บานา",
            name_en: "Bana",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940105,
            zip_code: 94000,
            name_th: "ตันหยงลุโละ",
            name_en: "Tanyong Lulo",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940106,
            zip_code: 94000,
            name_th: "คลองมานิง",
            name_en: "Khlong Maning",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940107,
            zip_code: 94000,
            name_th: "กะมิยอ",
            name_en: "Kamiyo",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940108,
            zip_code: 94000,
            name_th: "บาราโหม",
            name_en: "Barahom",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940109,
            zip_code: 94000,
            name_th: "ปะกาฮะรัง",
            name_en: "Paka Harang",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940110,
            zip_code: 94000,
            name_th: "รูสะมิแล",
            name_en: "Ru Samilae",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940111,
            zip_code: 94000,
            name_th: "ตะลุโบะ",
            name_en: "Talubo",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940112,
            zip_code: 94000,
            name_th: "บาราเฮาะ",
            name_en: "Baraho",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940113,
            zip_code: 94000,
            name_th: "ปุยุด",
            name_en: "Puyut",
            amphure_id: 9401,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9402,
        name_th: "โคกโพธิ์",
        name_en: "Khok Pho",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940201,
            zip_code: 94120,
            name_th: "โคกโพธิ์",
            name_en: "Khok Pho",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940202,
            zip_code: 94120,
            name_th: "มะกรูด",
            name_en: "Makrut",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940203,
            zip_code: 94120,
            name_th: "บางโกระ",
            name_en: "Bang Kro",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940204,
            zip_code: 94120,
            name_th: "ป่าบอน",
            name_en: "Pa Bon",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940205,
            zip_code: 94120,
            name_th: "ทรายขาว",
            name_en: "Sai Khao",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940206,
            zip_code: 94180,
            name_th: "นาประดู่",
            name_en: "Na Pradu",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940207,
            zip_code: 94180,
            name_th: "ปากล่อ",
            name_en: "Pak Lo",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940208,
            zip_code: 94180,
            name_th: "ทุ่งพลา",
            name_en: "Thung Phala",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940211,
            zip_code: 94120,
            name_th: "ท่าเรือ",
            name_en: "Tha Ruea",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940213,
            zip_code: 94120,
            name_th: "นาเกตุ",
            name_en: "Na Ket",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940214,
            zip_code: 94180,
            name_th: "ควนโนรี",
            name_en: "Khuan Nori",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940215,
            zip_code: 94120,
            name_th: "ช้างให้ตก",
            name_en: "Chang Hai Tok",
            amphure_id: 9402,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9403,
        name_th: "หนองจิก",
        name_en: "Nong Chik",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940301,
            zip_code: 94170,
            name_th: "เกาะเปาะ",
            name_en: "Ko Po",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940302,
            zip_code: 94170,
            name_th: "คอลอตันหยง",
            name_en: "Kholo Tanyong",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940303,
            zip_code: 94170,
            name_th: "ดอนรัก",
            name_en: "Don Rak",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940304,
            zip_code: 94170,
            name_th: "ดาโต๊ะ",
            name_en: "Dato",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940305,
            zip_code: 94170,
            name_th: "ตุยง",
            name_en: "Tuyong",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940306,
            zip_code: 94170,
            name_th: "ท่ากำชำ",
            name_en: "Tha Kamcham",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940307,
            zip_code: 94170,
            name_th: "บ่อทอง",
            name_en: "Bo Thong",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940308,
            zip_code: 94170,
            name_th: "บางเขา",
            name_en: "Bang Khao",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940309,
            zip_code: 94170,
            name_th: "บางตาวา",
            name_en: "Bang Tawa",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940310,
            zip_code: 94170,
            name_th: "ปุโละปุโย",
            name_en: "Pulo Puyo",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940311,
            zip_code: 94170,
            name_th: "ยาบี",
            name_en: "Yabi",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940312,
            zip_code: 94170,
            name_th: "ลิปะสะโง",
            name_en: "Lipa Sa-ngo",
            amphure_id: 9403,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9404,
        name_th: "ปะนาเระ",
        name_en: "Panare",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940401,
            zip_code: 94130,
            name_th: "ปะนาเระ",
            name_en: "Panare",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940402,
            zip_code: 94130,
            name_th: "ท่าข้าม",
            name_en: "Tha Kham",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940403,
            zip_code: 94130,
            name_th: "บ้านนอก",
            name_en: "Ban Nok",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940404,
            zip_code: 94130,
            name_th: "ดอน",
            name_en: "Don",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940405,
            zip_code: 94190,
            name_th: "ควน",
            name_en: "Khuan",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940406,
            zip_code: 94130,
            name_th: "ท่าน้ำ",
            name_en: "Tha Nam",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940407,
            zip_code: 94130,
            name_th: "คอกกระบือ",
            name_en: "Khok Krabue",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940408,
            zip_code: 94130,
            name_th: "พ่อมิ่ง",
            name_en: "Pho Ming",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940409,
            zip_code: 94130,
            name_th: "บ้านกลาง",
            name_en: "Ban Klang",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940410,
            zip_code: 94130,
            name_th: "บ้านน้ำบ่อ",
            name_en: "Ban Nam Bo",
            amphure_id: 9404,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9405,
        name_th: "มายอ",
        name_en: "Mayo",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940501,
            zip_code: 94140,
            name_th: "มายอ",
            name_en: "Mayo",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940502,
            zip_code: 94140,
            name_th: "ถนน",
            name_en: "Thanon",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940503,
            zip_code: 94140,
            name_th: "ตรัง",
            name_en: "Trang",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940504,
            zip_code: 94140,
            name_th: "กระหวะ",
            name_en: "Krawa",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940505,
            zip_code: 94140,
            name_th: "ลุโบะยิไร",
            name_en: "Lubo Yirai",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940506,
            zip_code: 94190,
            name_th: "ลางา",
            name_en: "La-nga",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940507,
            zip_code: 94140,
            name_th: "กระเสาะ",
            name_en: "Kra So",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940508,
            zip_code: 94140,
            name_th: "เกาะจัน",
            name_en: "Ko Chan",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940509,
            zip_code: 94140,
            name_th: "ปะโด",
            name_en: "Pado",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940510,
            zip_code: 94140,
            name_th: "สาคอบน",
            name_en: "Sakho Bon",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940511,
            zip_code: 94140,
            name_th: "สาคอใต้",
            name_en: "Sakho Tai",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940512,
            zip_code: 94140,
            name_th: "สะกำ",
            name_en: "Sakam",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940513,
            zip_code: 94140,
            name_th: "ปานัน",
            name_en: "Panan",
            amphure_id: 9405,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9406,
        name_th: "ทุ่งยางแดง",
        name_en: "Thung Yang Daeng",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940601,
            zip_code: 94140,
            name_th: "ตะโละแมะนา",
            name_en: "Talo Mae Na",
            amphure_id: 9406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940602,
            zip_code: 94140,
            name_th: "พิเทน",
            name_en: "Phithen",
            amphure_id: 9406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940603,
            zip_code: 94140,
            name_th: "น้ำดำ",
            name_en: "Nam Dam",
            amphure_id: 9406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940604,
            zip_code: 94140,
            name_th: "ปากู",
            name_en: "Paku",
            amphure_id: 9406,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9407,
        name_th: "สายบุรี",
        name_en: "Sai Buri",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940701,
            zip_code: 94110,
            name_th: "ตะลุบัน",
            name_en: "Taluban",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940702,
            zip_code: 94110,
            name_th: "ตะบิ้ง",
            name_en: "Tabing",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940703,
            zip_code: 94110,
            name_th: "ปะเสยะวอ",
            name_en: "Pase Yawo",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940704,
            zip_code: 94110,
            name_th: "บางเก่า",
            name_en: "Bang Kao",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940705,
            zip_code: 94110,
            name_th: "บือเระ",
            name_en: "Bue Re",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940706,
            zip_code: 94110,
            name_th: "เตราะบอน",
            name_en: "Tro Bon",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940707,
            zip_code: 94110,
            name_th: "กะดุนง",
            name_en: "Kadunong",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940708,
            zip_code: 94110,
            name_th: "ละหาร",
            name_en: "Lahan",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940709,
            zip_code: 94110,
            name_th: "มะนังดาลำ",
            name_en: "Manang Dalam",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940710,
            zip_code: 94110,
            name_th: "แป้น",
            name_en: "Paen",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940711,
            zip_code: 94190,
            name_th: "ทุ่งคล้า",
            name_en: "Thung Khla",
            amphure_id: 9407,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9408,
        name_th: "ไม้แก่น",
        name_en: "Mai Kaen",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940801,
            zip_code: 94220,
            name_th: "ไทรทอง",
            name_en: "Sai Thong",
            amphure_id: 9408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940802,
            zip_code: 94220,
            name_th: "ไม้แก่น",
            name_en: "Mai Kaen",
            amphure_id: 9408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940803,
            zip_code: 94220,
            name_th: "ตะโละไกรทอง",
            name_en: "Talo Krai Thong",
            amphure_id: 9408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940804,
            zip_code: 94220,
            name_th: "ดอนทราย",
            name_en: "Don Sai",
            amphure_id: 9408,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9409,
        name_th: "ยะหริ่ง",
        name_en: "Yaring",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 940901,
            zip_code: 94150,
            name_th: "ตะโละ",
            name_en: "Talo",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940902,
            zip_code: 94150,
            name_th: "ตะโละกาโปร์",
            name_en: "Talo Kapo",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940903,
            zip_code: 94150,
            name_th: "ตันหยงดาลอ",
            name_en: "Tanyong Dalo",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940904,
            zip_code: 94190,
            name_th: "ตันหยงจึงงา",
            name_en: "Tanyong Chueng-nga",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940905,
            zip_code: 94150,
            name_th: "ตอหลัง",
            name_en: "Tolang",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940906,
            zip_code: 94150,
            name_th: "ตาแกะ",
            name_en: "Ta Kae",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940907,
            zip_code: 94150,
            name_th: "ตาลีอายร์",
            name_en: "Tali-ai",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940908,
            zip_code: 94150,
            name_th: "ยามู",
            name_en: "Yamu",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940909,
            zip_code: 94150,
            name_th: "บางปู",
            name_en: "Bang Pu",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940910,
            zip_code: 94150,
            name_th: "หนองแรต",
            name_en: "Nong Raet",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940911,
            zip_code: 94150,
            name_th: "ปิยามุมัง",
            name_en: "Piya Mumang",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940912,
            zip_code: 94150,
            name_th: "ปุลากง",
            name_en: "Pula Kong",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940913,
            zip_code: 94190,
            name_th: "บาโลย",
            name_en: "Baloi",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940914,
            zip_code: 94150,
            name_th: "สาบัน",
            name_en: "Saban",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940915,
            zip_code: 94150,
            name_th: "มะนังยง",
            name_en: "Manang Yong",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940916,
            zip_code: 94150,
            name_th: "ราตาปันยัง",
            name_en: "Rata Panyang",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940917,
            zip_code: 94150,
            name_th: "จะรัง",
            name_en: "Charang",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 940918,
            zip_code: 94150,
            name_th: "แหลมโพธิ์",
            name_en: "Laem Pho",
            amphure_id: 9409,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9410,
        name_th: "ยะรัง",
        name_en: "Yarang",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 941001,
            zip_code: 94160,
            name_th: "ยะรัง",
            name_en: "Yarang",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941002,
            zip_code: 94160,
            name_th: "สะดาวา",
            name_en: "Sadawa",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941003,
            zip_code: 94160,
            name_th: "ประจัน",
            name_en: "Prachan",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941004,
            zip_code: 94160,
            name_th: "สะนอ",
            name_en: "Sano",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941005,
            zip_code: 94160,
            name_th: "ระแว้ง",
            name_en: "Rawaeng",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941006,
            zip_code: 94160,
            name_th: "ปิตูมุดี",
            name_en: "Pitu Mudi",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941007,
            zip_code: 94160,
            name_th: "วัด",
            name_en: "Wat",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941008,
            zip_code: 94160,
            name_th: "กระโด",
            name_en: "Krado",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941009,
            zip_code: 94160,
            name_th: "คลองใหม่",
            name_en: "Khlong Mai",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941010,
            zip_code: 94160,
            name_th: "เมาะมาวี",
            name_en: "Mo Mawi",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941011,
            zip_code: 94160,
            name_th: "กอลำ",
            name_en: "Kolam",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941012,
            zip_code: 94160,
            name_th: "เขาตูม",
            name_en: "Khao Tum",
            amphure_id: 9410,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9411,
        name_th: "กะพ้อ",
        name_en: "Kapho",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 941101,
            zip_code: 94230,
            name_th: "กะรุบี",
            name_en: "Karubi",
            amphure_id: 9411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941102,
            zip_code: 94230,
            name_th: "ตะโละดือรามัน",
            name_en: "Talo Due Raman",
            amphure_id: 9411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941103,
            zip_code: 94230,
            name_th: "ปล่องหอย",
            name_en: "Plong Hoi",
            amphure_id: 9411,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9412,
        name_th: "แม่ลาน",
        name_en: "Mae Lan",
        province_id: 74,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 941201,
            zip_code: 94180,
            name_th: "แม่ลาน",
            name_en: "Mae Lan",
            amphure_id: 9412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941202,
            zip_code: 94180,
            name_th: "ม่วงเตี้ย",
            name_en: "Muang Tia",
            amphure_id: 9412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 941203,
            zip_code: 94180,
            name_th: "ป่าไร่",
            name_en: "Pa Rai",
            amphure_id: 9412,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 75,
    name_th: "ยะลา",
    name_en: "Yala",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9501,
        name_th: "เมืองยะลา",
        name_en: "Mueang Yala",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950101,
            zip_code: 95000,
            name_th: "สะเตง",
            name_en: "Sateng",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950102,
            zip_code: 95000,
            name_th: "บุดี",
            name_en: "Budi",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950103,
            zip_code: 95000,
            name_th: "ยุโป",
            name_en: "Yopo",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950104,
            zip_code: 95160,
            name_th: "ลิดล",
            name_en: "Lidon",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950106,
            zip_code: 95000,
            name_th: "ยะลา",
            name_en: "Yala",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950108,
            zip_code: 95000,
            name_th: "ท่าสาป",
            name_en: "Tha Sap",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950109,
            zip_code: 95160,
            name_th: "ลำใหม่",
            name_en: "Lam Mai",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950110,
            zip_code: 95000,
            name_th: "หน้าถ้ำ",
            name_en: "Na Tham",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950111,
            zip_code: 95160,
            name_th: "ลำพะยา",
            name_en: "Lam Phaya",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950112,
            zip_code: 95000,
            name_th: "เปาะเส้ง",
            name_en: "Po Seng",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950114,
            zip_code: 95160,
            name_th: "พร่อน",
            name_en: "Phron",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950115,
            zip_code: 95000,
            name_th: "บันนังสาเรง",
            name_en: "Bannang Sareng",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950116,
            zip_code: 95000,
            name_th: "สะเตงนอก",
            name_en: "Sateng Nok",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950118,
            zip_code: 95000,
            name_th: "ตาเซะ",
            name_en: "Ta Se",
            amphure_id: 9501,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9502,
        name_th: "เบตง",
        name_en: "Betong",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950201,
            zip_code: 95110,
            name_th: "เบตง",
            name_en: "Betong",
            amphure_id: 9502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950202,
            zip_code: 95110,
            name_th: "ยะรม",
            name_en: "Yarom",
            amphure_id: 9502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950203,
            zip_code: 95110,
            name_th: "ตาเนาะแมเราะ",
            name_en: "Tano Maero",
            amphure_id: 9502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950204,
            zip_code: 95110,
            name_th: "อัยเยอร์เวง",
            name_en: "Aiyoe Weng",
            amphure_id: 9502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950205,
            zip_code: 95110,
            name_th: "ธารน้ำทิพย์",
            name_en: "Than Nam Thip",
            amphure_id: 9502,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9503,
        name_th: "บันนังสตา",
        name_en: "Bannang Sata",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950301,
            zip_code: 95130,
            name_th: "บันนังสตา",
            name_en: "Bannang Sata",
            amphure_id: 9503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950302,
            zip_code: 95130,
            name_th: "บาเจาะ",
            name_en: "Bacho",
            amphure_id: 9503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950303,
            zip_code: 95130,
            name_th: "ตาเนาะปูเต๊ะ",
            name_en: "Tano Pute",
            amphure_id: 9503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950304,
            zip_code: 95130,
            name_th: "ถ้ำทะลุ",
            name_en: "Tham Thalu",
            amphure_id: 9503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950305,
            zip_code: 95130,
            name_th: "ตลิ่งชัน",
            name_en: "Taling Chan",
            amphure_id: 9503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950306,
            zip_code: 95130,
            name_th: "เขื่อนบางลาง",
            name_en: "Khuean Bang Lang",
            amphure_id: 9503,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9504,
        name_th: "ธารโต",
        name_en: "Than To",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950401,
            zip_code: 95150,
            name_th: "ธารโต",
            name_en: "Than To",
            amphure_id: 9504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950402,
            zip_code: 95150,
            name_th: "บ้านแหร",
            name_en: "Ban Rae",
            amphure_id: 9504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950403,
            zip_code: 95170,
            name_th: "แม่หวาด",
            name_en: "Mae Wat",
            amphure_id: 9504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950404,
            zip_code: 95150,
            name_th: "คีรีเขต",
            name_en: "Khiri Khet",
            amphure_id: 9504,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9505,
        name_th: "ยะหา",
        name_en: "Yaha",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950501,
            zip_code: 95120,
            name_th: "ยะหา",
            name_en: "Yaha",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950502,
            zip_code: 95120,
            name_th: "ละแอ",
            name_en: "La-ae",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950503,
            zip_code: 95120,
            name_th: "ปะแต",
            name_en: "Patae",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950504,
            zip_code: 95120,
            name_th: "บาโร๊ะ",
            name_en: "Baro",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950506,
            zip_code: 95120,
            name_th: "ตาชี",
            name_en: "Ta Chi",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950507,
            zip_code: 95120,
            name_th: "บาโงยซิแน",
            name_en: "Ba-ngoi Sinae",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950508,
            zip_code: 95120,
            name_th: "กาตอง",
            name_en: "Ka Tong",
            amphure_id: 9505,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9506,
        name_th: "รามัน",
        name_en: "Raman",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950601,
            zip_code: 95140,
            name_th: "กายูบอเกาะ",
            name_en: "Kayu Boko",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950602,
            zip_code: 95140,
            name_th: "กาลูปัง",
            name_en: "Kalupang",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950603,
            zip_code: 95140,
            name_th: "กาลอ",
            name_en: "Kalo",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950604,
            zip_code: 95140,
            name_th: "กอตอตือร๊ะ",
            name_en: "Koto Tuera",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950605,
            zip_code: 95140,
            name_th: "โกตาบารู",
            name_en: "Kota Baru",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950606,
            zip_code: 95140,
            name_th: "เกะรอ",
            name_en: "Kero",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950607,
            zip_code: 95140,
            name_th: "จะกว๊ะ",
            name_en: "Cha-kwa",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950608,
            zip_code: 95140,
            name_th: "ท่าธง",
            name_en: "Tha Thong",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950609,
            zip_code: 95140,
            name_th: "เนินงาม",
            name_en: "Noen Ngam",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950610,
            zip_code: 95140,
            name_th: "บาลอ",
            name_en: "Balo",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950611,
            zip_code: 95140,
            name_th: "บาโงย",
            name_en: "Ba-ngoi",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950612,
            zip_code: 95140,
            name_th: "บือมัง",
            name_en: "Buemang",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950613,
            zip_code: 95140,
            name_th: "ยะต๊ะ",
            name_en: "Yata",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950614,
            zip_code: 95140,
            name_th: "วังพญา",
            name_en: "Wang Phaya",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950615,
            zip_code: 95140,
            name_th: "อาซ่อง",
            name_en: "Asong",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950616,
            zip_code: 95140,
            name_th: "ตะโล๊ะหะลอ",
            name_en: "Talo Halo",
            amphure_id: 9506,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9507,
        name_th: "กาบัง",
        name_en: "Kabang",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950701,
            zip_code: 95120,
            name_th: "กาบัง",
            name_en: "Kabang",
            amphure_id: 9507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950702,
            zip_code: 95120,
            name_th: "บาละ",
            name_en: "Bala",
            amphure_id: 9507,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9508,
        name_th: "กรงปินัง",
        name_en: "Krong Pinang",
        province_id: 75,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 950801,
            zip_code: 95000,
            name_th: "กรงปินัง",
            name_en: "Krong Pinang",
            amphure_id: 9508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950802,
            zip_code: 95000,
            name_th: "สะเอะ",
            name_en: "Sa-e",
            amphure_id: 9508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950803,
            zip_code: 95000,
            name_th: "ห้วยกระทิง",
            name_en: "Huai Krathing",
            amphure_id: 9508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 950804,
            zip_code: 95000,
            name_th: "ปุโรง",
            name_en: "Purong",
            amphure_id: 9508,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 76,
    name_th: "นราธิวาส",
    name_en: "Narathiwat",
    geography_id: 6,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 9601,
        name_th: "เมืองนราธิวาส",
        name_en: "Mueang Narathiwat",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960101,
            zip_code: 96000,
            name_th: "บางนาค",
            name_en: "Bang Nak",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960102,
            zip_code: 96000,
            name_th: "ลำภู",
            name_en: "Lam Phu",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960103,
            zip_code: 96000,
            name_th: "มะนังตายอ",
            name_en: "Manang Tayo",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960104,
            zip_code: 96000,
            name_th: "บางปอ",
            name_en: "Bang Po",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960105,
            zip_code: 96000,
            name_th: "กะลุวอ",
            name_en: "Kaluwo",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960106,
            zip_code: 96000,
            name_th: "กะลุวอเหนือ",
            name_en: "Kaluwo Nuea",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960107,
            zip_code: 96000,
            name_th: "โคกเคียน",
            name_en: "Khok Khian",
            amphure_id: 9601,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9602,
        name_th: "ตากใบ",
        name_en: "Tak Bai",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960201,
            zip_code: 96110,
            name_th: "เจ๊ะเห",
            name_en: "Chehe",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960202,
            zip_code: 96110,
            name_th: "ไพรวัน",
            name_en: "Phrai Wan",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960203,
            zip_code: 96110,
            name_th: "พร่อน",
            name_en: "Phron",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960204,
            zip_code: 96110,
            name_th: "ศาลาใหม่",
            name_en: "Sala Mai",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960205,
            zip_code: 96110,
            name_th: "บางขุนทอง",
            name_en: "Bang Khun Thong",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960206,
            zip_code: 96110,
            name_th: "เกาะสะท้อน",
            name_en: "Ko Sathon",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960207,
            zip_code: 96110,
            name_th: "นานาค",
            name_en: "Na Nak",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960208,
            zip_code: 96110,
            name_th: "โฆษิต",
            name_en: "Khosit",
            amphure_id: 9602,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9603,
        name_th: "บาเจาะ",
        name_en: "Bacho",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960301,
            zip_code: 96170,
            name_th: "บาเจาะ",
            name_en: "Bacho",
            amphure_id: 9603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960302,
            zip_code: 96170,
            name_th: "ลุโบะสาวอ",
            name_en: "Lubo Sawo",
            amphure_id: 9603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960303,
            zip_code: 96170,
            name_th: "กาเยาะมาตี",
            name_en: "Kayo Mati",
            amphure_id: 9603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960304,
            zip_code: 96170,
            name_th: "ปะลุกาสาเมาะ",
            name_en: "Paluka Samo",
            amphure_id: 9603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960305,
            zip_code: 96170,
            name_th: "บาเระเหนือ",
            name_en: "Bare Nuea",
            amphure_id: 9603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960306,
            zip_code: 96170,
            name_th: "บาเระใต้",
            name_en: "Ba Re Tai",
            amphure_id: 9603,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9604,
        name_th: "ยี่งอ",
        name_en: "Yi-ngo",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960401,
            zip_code: 96180,
            name_th: "ยี่งอ",
            name_en: "Yi-ngo",
            amphure_id: 9604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960402,
            zip_code: 96180,
            name_th: "ละหาร",
            name_en: "Lahan",
            amphure_id: 9604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960403,
            zip_code: 96180,
            name_th: "จอเบาะ",
            name_en: "Chobo",
            amphure_id: 9604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960404,
            zip_code: 96180,
            name_th: "ลุโบะบายะ",
            name_en: "Lubo Baya",
            amphure_id: 9604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960405,
            zip_code: 96180,
            name_th: "ลุโบะบือซา",
            name_en: "Lubo Buesa",
            amphure_id: 9604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960406,
            zip_code: 96180,
            name_th: "ตะปอเยาะ",
            name_en: "Tapoyo",
            amphure_id: 9604,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9605,
        name_th: "ระแงะ",
        name_en: "Ra-ngae",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960501,
            zip_code: 96130,
            name_th: "ตันหยงมัส",
            name_en: "Tanyong Mat",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960502,
            zip_code: 96130,
            name_th: "ตันหยงลิมอ",
            name_en: "Tanyong Limo",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960506,
            zip_code: 96220,
            name_th: "บองอ",
            name_en: "Bo-ngo",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960507,
            zip_code: 96130,
            name_th: "กาลิซา",
            name_en: "Kalisa",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960508,
            zip_code: 96130,
            name_th: "บาโงสะโต",
            name_en: "Ba-ngo Sato",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960509,
            zip_code: 96130,
            name_th: "เฉลิม",
            name_en: "Chaloem",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960510,
            zip_code: 96130,
            name_th: "มะรือโบตก",
            name_en: "Maruebo Tok",
            amphure_id: 9605,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9606,
        name_th: "รือเสาะ",
        name_en: "Rueso",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960601,
            zip_code: 96150,
            name_th: "รือเสาะ",
            name_en: "Rueso",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960602,
            zip_code: 96150,
            name_th: "สาวอ",
            name_en: "Sawo",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960603,
            zip_code: 96150,
            name_th: "เรียง",
            name_en: "Riang",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960604,
            zip_code: 96150,
            name_th: "สามัคคี",
            name_en: "Samakkhi",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960605,
            zip_code: 96150,
            name_th: "บาตง",
            name_en: "Batong",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960606,
            zip_code: 96150,
            name_th: "ลาโละ",
            name_en: "Lalo",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960607,
            zip_code: 96150,
            name_th: "รือเสาะออก",
            name_en: "Rueso Ok",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960608,
            zip_code: 96150,
            name_th: "โคกสะตอ",
            name_en: "Khok Sato",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960609,
            zip_code: 96150,
            name_th: "สุวารี",
            name_en: "Suwari",
            amphure_id: 9606,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9607,
        name_th: "ศรีสาคร",
        name_en: "Si Sakhon",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960701,
            zip_code: 96210,
            name_th: "ซากอ",
            name_en: "Sako",
            amphure_id: 9607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960702,
            zip_code: 96210,
            name_th: "ตะมะยูง",
            name_en: "Tamayung",
            amphure_id: 9607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960703,
            zip_code: 96210,
            name_th: "ศรีสาคร",
            name_en: "Si Sakhon",
            amphure_id: 9607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960704,
            zip_code: 96210,
            name_th: "เชิงคีรี",
            name_en: "Choeng Khiri",
            amphure_id: 9607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960705,
            zip_code: 96210,
            name_th: "กาหลง",
            name_en: "Kalong",
            amphure_id: 9607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960706,
            zip_code: 96210,
            name_th: "ศรีบรรพต",
            name_en: "Si Banphot",
            amphure_id: 9607,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9608,
        name_th: "แว้ง",
        name_en: "Waeng",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960801,
            zip_code: 96160,
            name_th: "แว้ง",
            name_en: "Waeng",
            amphure_id: 9608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960802,
            zip_code: 96160,
            name_th: "กายูคละ",
            name_en: "Kayu Khla",
            amphure_id: 9608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960803,
            zip_code: 96160,
            name_th: "ฆอเลาะ",
            name_en: "Kholo",
            amphure_id: 9608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960804,
            zip_code: 96160,
            name_th: "โละจูด",
            name_en: "Lochut",
            amphure_id: 9608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960805,
            zip_code: 96160,
            name_th: "แม่ดง",
            name_en: "Mae Dong",
            amphure_id: 9608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960806,
            zip_code: 96160,
            name_th: "เอราวัณ",
            name_en: "Erawan",
            amphure_id: 9608,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9609,
        name_th: "สุคิริน",
        name_en: "Sukhirin",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 960901,
            zip_code: 96190,
            name_th: "มาโมง",
            name_en: "Mamong",
            amphure_id: 9609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960902,
            zip_code: 96190,
            name_th: "สุคิริน",
            name_en: "Sukhirin",
            amphure_id: 9609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960903,
            zip_code: 96190,
            name_th: "เกียร์",
            name_en: "Kia",
            amphure_id: 9609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960904,
            zip_code: 96190,
            name_th: "ภูเขาทอง",
            name_en: "Phukhao Thong",
            amphure_id: 9609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 960905,
            zip_code: 96190,
            name_th: "ร่มไทร",
            name_en: "Rom Sai",
            amphure_id: 9609,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9610,
        name_th: "สุไหงโก-ลก",
        name_en: "Su-ngai Kolok",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 961001,
            zip_code: 96120,
            name_th: "สุไหงโก-ลก",
            name_en: "Su-ngai Kolok",
            amphure_id: 9610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961002,
            zip_code: 96120,
            name_th: "ปาเสมัส",
            name_en: "Pase Mat",
            amphure_id: 9610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961003,
            zip_code: 96120,
            name_th: "มูโนะ",
            name_en: "Muno",
            amphure_id: 9610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961004,
            zip_code: 96120,
            name_th: "ปูโยะ",
            name_en: "Puyo",
            amphure_id: 9610,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9611,
        name_th: "สุไหงปาดี",
        name_en: "Su-ngai Padi",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 961101,
            zip_code: 96140,
            name_th: "ปะลุรู",
            name_en: "Paluru",
            amphure_id: 9611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961102,
            zip_code: 96140,
            name_th: "สุไหงปาดี",
            name_en: "Su-ngai Padi",
            amphure_id: 9611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961103,
            zip_code: 96140,
            name_th: "โต๊ะเด็ง",
            name_en: "To Deng",
            amphure_id: 9611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961104,
            zip_code: 96140,
            name_th: "สากอ",
            name_en: "Sako",
            amphure_id: 9611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961105,
            zip_code: 96140,
            name_th: "ริโก๋",
            name_en: "Riko",
            amphure_id: 9611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961106,
            zip_code: 96140,
            name_th: "กาวะ",
            name_en: "Ka Wa",
            amphure_id: 9611,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9612,
        name_th: "จะแนะ",
        name_en: "Chanae",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 961201,
            zip_code: 96220,
            name_th: "จะแนะ",
            name_en: "Chanae",
            amphure_id: 9612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961202,
            zip_code: 96220,
            name_th: "ดุซงญอ",
            name_en: "Dusong Yo",
            amphure_id: 9612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961203,
            zip_code: 96220,
            name_th: "ผดุงมาตร",
            name_en: "Phadung Mat",
            amphure_id: 9612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961204,
            zip_code: 96220,
            name_th: "ช้างเผือก",
            name_en: "Chang Phueak",
            amphure_id: 9612,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 9613,
        name_th: "เจาะไอร้อง",
        name_en: "Cho-airong",
        province_id: 76,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 961301,
            zip_code: 96130,
            name_th: "จวบ",
            name_en: "Chuap",
            amphure_id: 9613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961302,
            zip_code: 96130,
            name_th: "บูกิต",
            name_en: "Bukit",
            amphure_id: 9613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 961303,
            zip_code: 96130,
            name_th: "มะรือโบออก",
            name_en: "Maruebo Ok",
            amphure_id: 9613,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
  {
    id: 77,
    name_th: "บึงกาฬ",
    name_en: "buogkan",
    geography_id: 3,
    created_at: "2019-08-09T03:33:09.000+07:00",
    updated_at: "2022-05-16T06:31:03.648+07:00",
    deleted_at: null,
    amphure: [
      {
        id: 3801,
        name_th: "เมืองบึงกาฬ",
        name_en: "Mueang Bueng Kan",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380101,
            zip_code: 38000,
            name_th: "คำนาดี",
            name_en: "Kham Na Di",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380102,
            zip_code: 38000,
            name_th: "บึงโขงหลง",
            name_en: "Bueng Khong Long",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380103,
            zip_code: 38000,
            name_th: "ไคสี",
            name_en: "Khai Si",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380104,
            zip_code: 38000,
            name_th: "ชัยพร",
            name_en: "Chaiyaphon",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380105,
            zip_code: 38000,
            name_th: "นาสวรรค์",
            name_en: "Na Sawan",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380106,
            zip_code: 38000,
            name_th: "โนนสมบูรณ์",
            name_en: "Non Sombun",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380107,
            zip_code: 38000,
            name_th: "บึงกาฬ",
            name_en: "Bueng Kan",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380108,
            zip_code: 38000,
            name_th: "โป่งเปื่อย",
            name_en: "Pong Pueai",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380109,
            zip_code: 38000,
            name_th: "วิศิษฐ์",
            name_en: "Wisit",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380110,
            zip_code: 38000,
            name_th: "หนองเข็ง",
            name_en: "Nong Keng",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380111,
            zip_code: 38000,
            name_th: "หนองเลิง",
            name_en: "Nong Loeng",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380112,
            zip_code: 38000,
            name_th: "หอคำ",
            name_en: "Ho Kham",
            amphure_id: 3801,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3802,
        name_th: "เซกา",
        name_en: "Seka",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380201,
            zip_code: 38150,
            name_th: "ซาง",
            name_en: "Sang",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380202,
            zip_code: 38150,
            name_th: "เซกา",
            name_en: "Seka",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380203,
            zip_code: 38150,
            name_th: "ท่ากกแดง",
            name_en: "Tha Kok Daeng",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380204,
            zip_code: 38150,
            name_th: "ท่าสะอาด",
            name_en: "Tha Sa-at",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380205,
            zip_code: 38150,
            name_th: "น้ำจั้น",
            name_en: "Nam Chan",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380206,
            zip_code: 38150,
            name_th: "บ้านต้อง",
            name_en: "Ban Tong",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380207,
            zip_code: 38150,
            name_th: "ป่งไฮ",
            name_en: "Pong Hai",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380208,
            zip_code: 38150,
            name_th: "โสกก่าม",
            name_en: "Sok Kam",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380209,
            zip_code: 38150,
            name_th: "หนองทุ่ม",
            name_en: "Nong Thum",
            amphure_id: 3802,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3803,
        name_th: "โซ่พิสัย",
        name_en: "So Phisai",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380301,
            zip_code: 38170,
            name_th: "คำแก้ว",
            name_en: "Kham Kaeo",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380302,
            zip_code: 38170,
            name_th: "โซ่",
            name_en: "So",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380303,
            zip_code: 38170,
            name_th: "ถ้ำเจริญ",
            name_en: "Tham Charoen",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380304,
            zip_code: 38170,
            name_th: "บัวตูม",
            name_en: "Bua Tum",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380305,
            zip_code: 38170,
            name_th: "ศรีชมภู",
            name_en: "Si Chomphu",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380306,
            zip_code: 38170,
            name_th: "หนองพันทา",
            name_en: "Nong Phan Tha",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380307,
            zip_code: 38170,
            name_th: "เหล่าทอง",
            name_en: "Lao Thong",
            amphure_id: 3803,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3804,
        name_th: "พรเจริญ",
        name_en: "Phon Charoen",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380401,
            zip_code: 38180,
            name_th: "ดอนหญ้านาง",
            name_en: "Don Ya Nang",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380402,
            zip_code: 38180,
            name_th: "ป่าแฝก",
            name_en: "Pa Faek",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380403,
            zip_code: 38180,
            name_th: "พรเจริญ",
            name_en: "Phon Charoen",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380404,
            zip_code: 38180,
            name_th: "วังชมภู",
            name_en: "Wang Chomphu",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380405,
            zip_code: 38180,
            name_th: "ศรีชมภู",
            name_en: "Si Chomphu",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380406,
            zip_code: 38180,
            name_th: "ศรีสำราญ",
            name_en: "Si Samran",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380407,
            zip_code: 38180,
            name_th: "หนองหัวช้าง",
            name_en: "Nong Hua Chang",
            amphure_id: 3804,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3805,
        name_th: "ศรีวิไล",
        name_en: "Si Wilai",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380501,
            zip_code: 38210,
            name_th: "ชุมภูพร",
            name_en: "Chumphu Phon",
            amphure_id: 3805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380502,
            zip_code: 38210,
            name_th: "นาสะแบง",
            name_en: "Na Sabaeng",
            amphure_id: 3805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380503,
            zip_code: 38210,
            name_th: "นาสิงห์",
            name_en: "Na Sing",
            amphure_id: 3805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380504,
            zip_code: 38210,
            name_th: "นาแสง",
            name_en: "Na Saeng",
            amphure_id: 3805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380505,
            zip_code: 38210,
            name_th: "ศรีวิไล",
            name_en: "Si Wilai",
            amphure_id: 3805,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3806,
        name_th: "บึงโขงหลง",
        name_en: "Bueng Khong Long",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380601,
            zip_code: 38220,
            name_th: "ดงบัง",
            name_en: "Dong Bang",
            amphure_id: 3806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380602,
            zip_code: 38220,
            name_th: "ท่าดอกคำ",
            name_en: "Tha Dok Kham",
            amphure_id: 3806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380603,
            zip_code: 38220,
            name_th: "บึงโขงหลง",
            name_en: "Bueng Khong Long",
            amphure_id: 3806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380604,
            zip_code: 38220,
            name_th: "โพธิ์หมากแข้ง",
            name_en: "Pho Mak Khaeng",
            amphure_id: 3806,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3807,
        name_th: "ปากคาด",
        name_en: "Pak Khat",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380701,
            zip_code: 38190,
            name_th: "นากั้ง",
            name_en: "Na Kang",
            amphure_id: 3807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380702,
            zip_code: 38190,
            name_th: "นาดง",
            name_en: "Na Dong",
            amphure_id: 3807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380703,
            zip_code: 38190,
            name_th: "โนนศิลา",
            name_en: "Non Sila",
            amphure_id: 3807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380704,
            zip_code: 38190,
            name_th: "ปากคาด",
            name_en: "Pak Khat",
            amphure_id: 3807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380705,
            zip_code: 38190,
            name_th: "สมสนุก",
            name_en: "Som Sanuk",
            amphure_id: 3807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380706,
            zip_code: 38190,
            name_th: "หนองยอง",
            name_en: "Nong Yong",
            amphure_id: 3807,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
      {
        id: 3808,
        name_th: "บุ่งคล้า",
        name_en: "Bung Khla",
        province_id: 77,
        created_at: "2019-08-09T03:33:09.000+07:00",
        updated_at: "2022-05-16T06:31:26.606+07:00",
        deleted_at: null,
        tambon: [
          {
            id: 380801,
            zip_code: 38000,
            name_th: "โคกกว้าง",
            name_en: "Khok Kwang",
            amphure_id: 3808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380802,
            zip_code: 38000,
            name_th: "บุ่งคล้า",
            name_en: "Bung Khla",
            amphure_id: 3808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
          {
            id: 380803,
            zip_code: 38000,
            name_th: "หนองเดิน",
            name_en: "Nong Doen",
            amphure_id: 3808,
            created_at: "2019-08-09T03:33:09.000+07:00",
            updated_at: "2022-05-16T06:31:31.324+07:00",
            deleted_at: null,
          },
        ],
      },
    ],
  },
];
