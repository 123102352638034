import React from "react";
import { useState } from "react";
import { createContext } from "react";

export const initState = {};

export const AppState = createContext(initState);

export default function AppStateProvider(props) {
  const { children } = props;

  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [chat, setChat] = useState(null);
  const [chatConnecting, setChatConnecting] = useState(false);
  const [chatError, setChatError] = useState(null);
  const [triggerRefreshPawnList, setTriggerRefreshPawnList] = useState(false);
  const [activity, setActivity] = useState({ loading: false, data: [] });

  const store = {
    products,
    setProducts,
    items,
    setItems,
    loading,
    setLoading,
    chat,
    setChat,
    chatConnecting,
    setChatConnecting,
    chatError,
    setChatError,
    triggerRefreshPawnList,
    setTriggerRefreshPawnList,
    activity,
    setActivity,
  };

  return <AppState.Provider value={store}>{children}</AppState.Provider>;
}
