import { useEffect, useState } from "react";
import axios from "../utils/axios";

export const useAuth = () => {
  const [auth, setAuth] = useState({
    gender: "",
    name: "",
    isLoggedIn: false,
    isChecking: true,
  });

  useEffect(() => {
    setAuth((auth) => ({
      ...auth,
      isChecking: true,
      gender: "",
      isLoggedIn: false,
      name: "",
    }));
    axios
      .get("/api/auth/me")
      .then((response) => {
        const {
          data: { gender, name },
        } = response;
        setAuth((user) => ({ ...user, gender, name, isLoggedIn: true }));
      })
      .catch((err) => {
        setAuth((user) => ({
          ...user,
          isLoggedIn: false,
          gender: "",
          name: "",
        }));
      })
      .finally(() => {
        setAuth((user) => ({ ...user, isChecking: false }));
      });
  }, []);

  return auth;
};
