import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Popover,
  Box,
  CircularProgress,
  Button,
  Stack,
  TextField,
  IconButton,
  Typography,
  Paper,
  Chip,
} from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useWindowDimensions from "../../hooks/useWindowDimension.js";
import axios from "../../utils/axios.js";
import Swal from "sweetalert2";
import { Send } from "@mui/icons-material";

export default function Chat(props) {
  const { anchorChat, onClose } = props;
  const openChat = Boolean(anchorChat);

  const auth = useAuth();

  const loading = auth.isChecking;
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isToSmallScreenChat = width <= 480;
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);
  const goToLastMessage = () => {
    if (lastMessageRef) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const [chatState, setChatState] = useState({
    isConnected: false,
    isConnecting: false,
    isError: false,
    isLoading: false,
  });

  // const navigate = useNavigate();
  // const location = useLocation();
  // const { name, isLoggedIn } = useAuthState();
  // console.log({ name });
  // const { width } = useWindowDimensions();
  // const isToSmallScreenChat = width <= 480;

  const [signal, setSignal] = useState(false);
  const triggerRefreshChatMessage = () => setSignal((prev) => !prev);

  const [message, setMessage] = useState("");
  const send = (message) => {
    // if (messageCounting > LIMIT_MESSAGE_STACK) return;

    // setMessageCounting((m) => m + 1);

    // setTimeout(() => {
    //   setMessageCounting((m) => m - 1);
    // }, NEXT_CHAT_MESSAGE_SECONDS * 1000);

    axios.post(`/api/chats/text`, { text: message }).then(() => {
      triggerRefreshChatMessage();
    });
  };

  // const [loadingChat, setLoadingChat] = useState(false);

  const refreshMessage = () => {
    if (chatState.isLoading || !openChat) return;
    setChatState((chat) => ({ ...chat, isLoading: true }));
    axios
      .get(`/api/chats`)
      .then((response) => {
        const serverMessages = response.data || [];
        if (!messages) {
          setMessages(serverMessages);
          goToLastMessage();
        } else {
          if (JSON.stringify(messages) !== JSON.stringify(serverMessages)) {
            setMessages(serverMessages);
            goToLastMessage();
          }
        }
      })
      .catch((err) => {
        // Swal.fire({ title: "ไม่สามารถโหลดข้อมูลได้", icon: "error" });
      })
      .finally(() => {
        setChatState((chat) => ({ ...chat, isLoading: false }));
      });
  };

  useEffect(() => {
    const id = setInterval(() => {
      triggerRefreshChatMessage();
    }, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (openChat) {
      setChatState((chat) => ({
        ...chat,
        isConnecting: true,
        isConnected: false,
        isError: false,
      }));

      axios
        .get("/api/chats/connect")
        .then((response) => {
          setChatState((chat) => ({
            ...chat,
            isConnected: true,
            isError: false,
          }));
        })
        .catch(() => {
          setChatState((chat) => ({
            ...chat,
            isConnected: false,
            isError: true,
          }));
        })
        .finally(() => {
          setChatState((chat) => ({
            ...chat,
            isConnecting: false,
          }));
          refreshMessage();
          goToLastMessage();
        });
    }
  }, [openChat]);

  const connectStatus = useMemo(() => {
    if (chatState.isError) {
      return (
        <Chip size="small" color="error" label="ไม่สามารถเชื่อมต่อแชทได้" />
      );
    }
    if (chatState.isConnecting) {
      return <Chip size="small" color="warning" label="กำลังเชื่อมต่อ..." />;
    }

    if (chatState.isConnected) {
      return <Chip size="small" color="success" label="เชื่อมต่อสำเร็จ" />;
    }
  }, [chatState.isConnected, chatState.isConnecting, chatState.isError]);

  useEffect(() => {
    if (openChat) {
      refreshMessage();
      goToLastMessage();
    }
  }, [signal]);

  // const lastMessageRef = useRef(null);

  // const [messageCounting, setMessageCounting] = useState(0);

  // const [qrCode, setQrCode] = useState("line.jpg");

  // if (!isLoggedIn && location !== "/login") {
  //   Swal.fire({
  //     title: "กรุณาล็อกอินเพื่อทำการแชท",
  //     icon: "success",
  //   }).then(() => {
  //     navigate("/login");
  //   });
  //   return null;
  // }

  // if (open) return null;

  return (
    <Popover
      open={openChat}
      anchorEl={anchorChat}
      onClose={() => {
        onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box
        sx={{ minHeight: 500, maxHeight: 500, minWidth: 450, maxWidth: 450 }}
      >
        {loading && (
          <Box
            height={500}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}

        {!loading && !auth.isLoggedIn && (
          <Box
            height={500}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack direction="column" gap={4}>
              <img width={200} height={150} src="./login.png" alt="login" />
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/login");
                  onClose();
                }}
              >
                โปรดเข้าสู่ระบบก่อนการสนทนา คลิกเลย!!
              </Button>
            </Stack>
          </Box>
        )}
        {auth.isLoggedIn && (
          <Stack height={"100%"} direction={"column"} width={"100%"}>
            <Box width={"100%"} p={2}>
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography>ยินดีต้อนรับคุณ {auth.name}</Typography>
                {connectStatus}
              </Stack>
            </Box>

            <Box width={"100%"}>
              <Box
                sx={{
                  minHeight: 385,
                  p: 2,
                  maxHeight: 385,
                  overflow: "auto",
                  maxWidth: isToSmallScreenChat ? 370 : 500,
                }}
              >
                <Stack spacing={1} justifyItems="flex-end">
                  {messages.map((m, index) => {
                    const firstDateMessageIndex = messages.findIndex((mm) =>
                      moment(mm.sendDate)
                        .startOf("day")
                        .isSame(moment(m.sendDate).startOf("day"))
                    );

                    return (
                      <div key={m.id}>
                        {firstDateMessageIndex === index && (
                          <Box display="flex" justifyContent={"center"}>
                            <Typography
                              sx={{ color: "rgb(179, 179, 179)" }}
                              variant="caption"
                            >
                              {moment(m.sendDate)
                                .locale("th-TH")
                                .utc()
                                .format("DD MMM yyyy")}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          key={index}
                          display="flex"
                          alignItems={"flex-end"}
                          justifyContent={
                            m.sender === "CUSTOMER" ? "flex-end" : "flex-start"
                          }
                        >
                          {m.sender === "CUSTOMER" && (
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems="flex-end"
                            >
                              <Typography
                                sx={{ color: "rgb(179, 179, 179)" }}
                                variant="caption"
                              >
                                {moment(m.sendDate).utc().format("HH:mm:ss น.")}
                              </Typography>
                              <Typography
                                sx={{ color: "rgb(179, 179, 179)" }}
                                variant="caption"
                              >
                                {m.received ? "อ่านแล้ว" : "ส่งแล้ว"}
                              </Typography>
                            </Box>
                          )}
                          <Box mr={1} />
                          <Paper
                            variant="outlined"
                            sx={{
                              maxWidth: "50%",
                              padding: 1,
                              color:
                                m.sender === "CUSTOMER" ? "white" : "black",
                              background:
                                m.sender === "CUSTOMER"
                                  ? "rgb(98, 0, 238)"
                                  : "rgb(224, 224, 224)",
                            }}
                          >
                            <Typography>{m.message}</Typography>
                          </Paper>
                          <Box ml={1} />
                          {m.sender !== "CUSTOMER" && (
                            <Typography
                              sx={{ color: "rgb(179, 179, 179)" }}
                              variant="caption"
                            >
                              {moment(m.sendDate).utc().format("HH:mm:ss น.")}
                            </Typography>
                          )}
                        </Box>
                      </div>
                    );
                  })}
                </Stack>
                <div style={{ marginBottom: 10 }} ref={lastMessageRef} />
              </Box>
            </Box>
            <Stack direction={"row"}>
              <TextField
                autoComplete={"off"}
                inputRef={(el) => el && el.focus()}
                // placeholder="กรุณาติดต่อผ่าน Line ชั่วคราว"
                // disabled={!!chatError || messageCounting > LIMIT_MESSAGE_STACK}
                // placeholder={
                // messageCounting > LIMIT_MESSAGE_STACK
                // ? "คุณพิมพ์เร็วเกินไป"
                // : "พิมพ์สิ่งที่คุณต้องการ"
                // }
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    send(message);
                    setMessage("");
                  }
                }}
              />

              <IconButton
                // disabled
                // disabled={messageCounting > LIMIT_MESSAGE_STACK}
                onClick={() => {
                  send(message);
                  setMessage("");
                }}
              >
                <Send />
              </IconButton>
            </Stack>
          </Stack>
        )}

        {/* {!isLoggedIn && (
          <Box
            minHeight={500}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack direction={"column"} gap={4}>
              <img width={200} height={150} src="./login.png" />
              <Button
                variant="contained"
                startIcon={<OpenInBrowser />}
                onClick={() => {
                  navigate("/login");
                  setForceClose(true);
                }}
              >
                กรุณาเข้าสู่ระบบเพื่อบันทึกการสนทนา
              </Button>
            </Stack>
          </Box>
        )} */}
        {/* <Grid container>
          <Grid item>
            <Box height="100%" backgorund="red">
              xxxx
            </Box>
          </Grid> */}
        {/* <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                minHeight: 60,
                alignItems: "center",
                pl: 4,
              }}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} alignItems="center" mr={2}>
                <Typography>หรือติดต่อผ่าน</Typography>
                <img
                  src="messenger-logo.jpg"
                  width={40}
                  height={40}
                  style={{ cursor: "pointer" }}
                  onClick={() => setQrCode("facebook.jpg")}
                  alt="facebook contact"
                />
                <img
                  src="line-logo.jpg"
                  width={40}
                  height={40}
                  style={{ cursor: "pointer" }}
                  onClick={() => setQrCode("line.jpg")}
                  alt="line contact"
                />
              </Box>
            </Box>
          </Grid> */}
        {/* <Grid item xs={12}>
            <Typography>ยินดีต้อนรับคุณ {name}</Typography>
          </Grid>
          {qrCode && (
            <img
              src={qrCode}
              width={isToSmallScreenChat ? 350 : 620}
              height={350}
              alt="qr code"
              style={{ cursor: "pointer" }}
              onClick={() => setQrCode(null)}
            />
          )}
          {!qrCode && (
            <Grid item xs={12}>
              <Box
                sx={{
                  minHeight: 350,
                  p: 2,
                  maxHeight: 350,
                  overflow: "auto",
                  maxWidth: isToSmallScreenChat ? 370 : 500,
                }}
              >
                <Stack spacing={1} justifyItems="flex-end">
                  {messages.map((m, index) => {
                    const firstDateMessageIndex = messages.findIndex((mm) =>
                      moment(mm.sendDate)
                        .startOf("day")
                        .isSame(moment(m.sendDate).startOf("day"))
                    );

                    return (
                      <div key={m.id}>
                        {firstDateMessageIndex === index && (
                          <Box display="flex" justifyContent={"center"}>
                            <Typography
                              sx={{ color: "rgb(179, 179, 179)" }}
                              variant="caption"
                            >
                              {moment(m.sendDate)
                                .locale("th-TH")
                                .utc()
                                .format("DD MMM yyyy")}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          key={index}
                          display="flex"
                          alignItems={"flex-end"}
                          justifyContent={
                            m.sender === "CUSTOMER" ? "flex-end" : "flex-start"
                          }
                        >
                          {m.sender === "CUSTOMER" && (
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems="flex-end"
                            >
                              <Typography
                                sx={{ color: "rgb(179, 179, 179)" }}
                                variant="caption"
                              >
                                {moment(m.sendDate).utc().format("HH:mm:ss น.")}
                              </Typography>
                              <Typography
                                sx={{ color: "rgb(179, 179, 179)" }}
                                variant="caption"
                              >
                                {m.received ? "อ่านแล้ว" : "ส่งแล้ว"}
                              </Typography>
                            </Box>
                          )}
                          <Box mr={1} />
                          <Paper
                            variant="outlined"
                            sx={{
                              maxWidth: "50%",
                              padding: 1,
                              color:
                                m.sender === "CUSTOMER" ? "white" : "black",
                              background:
                                m.sender === "CUSTOMER"
                                  ? "rgb(98, 0, 238)"
                                  : "rgb(224, 224, 224)",
                            }}
                          >
                            <Typography>{m.message}</Typography>
                          </Paper>
                          <Box ml={1} />
                          {m.sender !== "CUSTOMER" && (
                            <Typography
                              sx={{ color: "rgb(179, 179, 179)" }}
                              variant="caption"
                            >
                              {moment(m.sendDate).utc().format("HH:mm:ss น.")}
                            </Typography>
                          )}
                        </Box>
                      </div>
                    );
                  })}
                </Stack>
                <div ref={lastMessageRef} />
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              p={2}
              sx={{
                minHeight: 80,
                display: "flex",
                maxWidth: isToSmallScreenChat ? width - 30 : 450,
              }}
              alignItems="center"
            >
              <TextField
                inputRef={(el) => el && el.focus()}
                // placeholder="กรุณาติดต่อผ่าน Line ชั่วคราว"
                disabled={!!chatError || messageCounting > LIMIT_MESSAGE_STACK}
                value={message}
                placeholder={
                  messageCounting > LIMIT_MESSAGE_STACK
                    ? "คุณพิมพ์เร็วเกินไป"
                    : "พิมพ์สิ่งที่คุณต้องการ"
                }
                fullWidth
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    send(message);
                    setMessage("");
                  }
                }}
              />

              <IconButton
                // disabled
                disabled={messageCounting > LIMIT_MESSAGE_STACK}
                onClick={() => {
                  send(message);
                  setMessage("");
                }}
              >
                <Mail></Mail>
              </IconButton>

              <IconButton>
                <Mail></Mail>
              </IconButton>
            </Box>
          </Grid>
        </Grid> */}
      </Box>
    </Popover>
  );
}
