import "./App.css";
import Main from "./components/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductWeightPage from "./components/ProductWeightPage";
import HomePage from "./components/HomePage";
import ProductList from "./components/ProductList";
import AboutPage from "./components/AboutPage";
import { v4 } from "uuid";
import AppStateProvider from "./AppStateProvider";
import PawnPage from "./components/PawnPage";
import ActivityPage from "./components/ActivityPage";
import ActivityListPage from "./components/ActivityListPage";
import PointPage from "./components/PointPage";

import RegisterPage from "./components/RegisterPage";
import LoginPage from "./components/LoginPage";
import InstallPage from "./components/InstallPage";
import AuthStateProvider from "./AuthStateProvider";
import PrivateComponent from "./components/PrivateComponent";
import SavingPage from "./components/SavingPage";
import PolicyPage from "./components/Policy";

function App() {
  const guid = sessionStorage.getItem("guid");

  if (guid === null) {
    sessionStorage.setItem("guid", v4());
  }


  return (
    <div className="App">
      <AuthStateProvider>
        <AppStateProvider>
          <Router>
            <Routes>
              <Route exact path="/install" element={<InstallPage />} />
              <Route exact path="/" element={<Main />}>
                <Route index path="/" element={<HomePage />} />
                <Route index path="/login" element={<LoginPage />} />

                <Route
                  path="/pawn"
                  element={
                    <PrivateComponent>
                      <PawnPage />
                    </PrivateComponent>
                  }
                />
                <Route
                  exact
                  path="products/:code"
                  element={<ProductWeightPage />}
                />
                <Route
                  exact
                  path="products/:code/weights/:weightId"
                  element={<ProductList />}
                />
                <Route
                  exact
                  path="products/:code/weights/:weightId/more/:model"
                  element={<ProductList />}
                />
                <Route exact path="activities/:id" element={<ActivityPage />} />
                <Route exact path="about" element={<AboutPage />} />
                <Route exact path="activities" element={<ActivityListPage />} />
                <Route exact path="policy" element={<PolicyPage />} />
                <Route
                  exact
                  path="point"
                  element={
                    <PrivateComponent>
                      <PointPage />
                    </PrivateComponent>
                  }
                />
                <Route exact path="register" element={<RegisterPage />} />
                <Route
                  exact
                  path="saving"
                  element={
                    <PrivateComponent>
                      <SavingPage />
                    </PrivateComponent>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </AppStateProvider>
      </AuthStateProvider>
    </div>
  );
}

export default App;
